<section *ngIf="skeletonLoader">
  <div class="container-fluid">
    <div class="wishlist-head">
      <p class="heading">
        {{"My WishList" | translate}}
      </p>
    </div>
    <section class="wishlist-products-conat">
      <div class="wishlist-products">
        <article *ngFor="let item of [1,2,3,4]" drag-scroll-item class="">
          <!-- <ngx-skeleton-loader [theme]="skeltonTheme"></ngx-skeleton-loader> -->
          <div class="items-height-skeleton">
            <ngx-skeleton-loader class="height-fix w-100" ></ngx-skeleton-loader>
           </div>
           <div class="w-100">
             <div class="d-flex justify-content-center">
               <ngx-skeleton-loader class="w-25"></ngx-skeleton-loader>
             </div>
             <div class="d-flex justify-content-center">
               <ngx-skeleton-loader class="w-50"></ngx-skeleton-loader>
             </div>
             <div class="d-flex justify-content-center">
               <ngx-skeleton-loader class="w-25"></ngx-skeleton-loader>
             </div>
             <div class="d-flex justify-content-center mt-3">
              <ngx-skeleton-loader class="w-75" [theme]="skeltonTheme"></ngx-skeleton-loader>
            </div>
           </div> 
        </article>
      </div>
    </section>
  </div>
</section>

<section *ngIf="dataAvailable && !skeletonLoader">
  <div class="container-fluid" @listStagger>

    <div *ngIf="products.length" class="wishlist-head">
      <!-- <ul class="d-flex flex-wrap justify-content-center align-items-center w-100 listing-page-routing">
        <li *ngFor="let item of breadcumb" class="routing-list">{{item.path | titlecase}}</li>
      </ul> -->
      <p class="heading">
        {{"My Wish List" | translate}}
      </p>
      <!-- <p class="para">
        Keep track of all your favorite items here and add them to your Shopping Bag to purchase later
      </p> -->
    </div>

    <section class="wishlist-products-conat" *ngIf="products.length">
      <!-- <p class="items-length">
        {{products.length}}  {{ "items " | translate}}
      </p> -->
      <div class="wishlist-products" *ngIf="products.length">
        <article *ngFor="let item of products" drag-scroll-item class="">
          <app-product-item [lang]="lang" [item]="item" [currency]="currency" [store]="store" [userId]="userId" 
            [showWishlistIcon]="true" [showCrossIcon]="true">
          </app-product-item>
        </article>
      </div>

    </section>

    <div *ngIf="!products.length" class="empty-cart">
      <div class="empty-cart-content white-container">
        <h4 class="page-title font-bold text-center">{{'Your wishlist is empty!' | translate}}</h4>
        <p class="font-regular text-center">{{"Why not check out what's new?" | translate}}</p>
        <div class="text-center">
          <a href="/home" class="btn btn-dark">{{'ADD PRODUCT' | translate}}</a>
        </div>
      </div>
    </div>

  </div>
</section>


<div *ngIf="products.length" class="d-flex justify-content-center align-items-center p-2 ">
  <button class="scroll-to-top-button" (click)="scrollToTop()">
    <img src="/assets/images/arrow-up.png" class="scroll-to-top-img">
    <span class="scroll-to-top-title">{{'BACK TO TOP' | translate}}</span>
  </button>
</div>

<app-add-to-bag-popup [fromWishlist]="true"></app-add-to-bag-popup>