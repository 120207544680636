<div class="my_account_data" *ngIf="skeletonLoader">
  <div class="container-fluid">
    <div class="order_confirmation">
      <div class="">
        <ngx-skeleton-loader [theme]="skeltonTheme"></ngx-skeleton-loader>
      </div>
    </div>

    <div class="py-2">
      <div class="">
        <ngx-skeleton-loader count="1"[theme]="{'background-color': '#F1F1F1'}" ></ngx-skeleton-loader>
      </div>
    </div>

    <div class="d-grid w-100 main-div-product">
      <div class="" *ngFor="let item of [1,2]">
        <ngx-skeleton-loader [theme]="skeltonTheme"></ngx-skeleton-loader>
      </div>
    </div>
  </div>
</div>
<div class="my_account_data" *ngIf="!skeletonLoader">
  <div class="container-fluid">
    <div class="order_confirmation">
      <!-- <div class="account_heading pt-3 pb-4">
        <ul class="d-flex flex-wrap align-items-center justify-content-center w-100 listing-page-routing" >
          <li class="routing-list">
            <a>
              {{"CHECKOUT" | translate }}
            </a>
          </li>
          <li class="routing-list">{{"Order Confirmation" | translate }}</li>
        </ul>
      </div> -->
      <div class="order_data">
        <div class="order_image">
          <figure>
            <div class="image-wrapper" *ngIf="order.status_id!=6">
              <img src="/assets/icons/tick-circle-black.svg" alt="" />
            </div>
            <div class="image-wrapper" *ngIf="order.status_id==6">
              <img src="/assets/icons/cancel-order-cross.svg" alt="" />
            </div>
          </figure>
        </div>
        <!-- <h1>{{'Thanks,' | translate}} {{userSession.first_name}}!</h1> -->
        <h1 *ngIf="order.status_id!=6">{{'Order Confirmed' | translate}}</h1>
        <h1 *ngIf="order.status_id==6">{{'Payment Failed!' | translate}}</h1>
        <p *ngIf="order.status_id!=6">{{'Thank you for your order. You will receive email confirmation shortly.' | translate}}</p>
        <p *ngIf="order.status_id==6">{{'Order No' | translate}}: #<span>{{order?.order_number}}</span></p>
        <p *ngIf="order.status_id==6" class="order_faild">{{'Please try again later or use another payment method' | translate}}</p>
        <!-- <p>{{'You will receive email confirmation shortly.' | translate}}</p> -->
       
        <div *ngIf="order.status_id==6">
          <button type="button" class="button_hover" (click)="activateHeaderFromBanners()">{{'back to home' | translate}}</button>
        </div>

        <div class="order_prgress" *ngIf="order.status_id!=6">
          <div>
            <span class="box active"><img class="notepad" src="/assets/icons/order_notepad.svg" alt="notepad" /></span>
            <p>{{'Ordered' | translate}}</p>
          </div>
          <div>
            <div class="line"></div>
          </div>
          <div>
            <span class="box"><img src="/assets/icons/order_box.svg" alt="box" /></span>
            <p>{{'Packed' | translate}}</p>
          </div>
          <div>
            <div class="line"></div>
          </div>
          <div>
            <span class="box"><img src="/assets/icons/order_truck.svg" alt="truck" /></span>
            <p>{{'In Transit' | translate}}</p>
          </div>
          <div>
            <div class="line"></div>
          </div>
          <div>
            <span class="box"><img src="/assets/icons/order_home.svg" alt="home" /></span>
            <p>{{'Delivered' | translate}}</p>
          </div>
        </div>


      </div>
    </div>

    <div class="py-2" *ngIf="order.status_id!=6">
      <div class="d-block d-lg-flex align-items-center justify-content-between gap-2 order-number w-100">
        <div class="d-flex gap-2 align-items-center">
        <p>{{'Order No' | translate}}.</p>
        <p>#{{ order?.order_number }}</p>
      </div>
      <div class="d-flex gap23 align-items-center">
        <p>{{'Total' | translate}}.</p>
        <p>{{ currency }} {{ order?.total | number : "1.3-3" }}</p>
      </div>
      </div>
    </div>


    <div class="d-grid w-100 main-div-product" *ngIf="order.status_id!=6">
      <div class="product-box product-box-border" *ngFor="let item of order?.items"
        [ngClass]="{'product-box-border': order?.items?.length >= order?.items?.length - 2}">
        <div class="product-details-box">
          <div class="product_image">
            <figure>
              <div class="image-wrapper">
                <img src="{{ item?.image }}" alt="Product Image" />
              </div>
            </figure>
          </div>
          <div class="product-details">
            <div class="product-name-size">
              <h2>{{ item?.brand_name }}</h2>
              <p>{{ item?.name }}</p>
              <ul class="item-color-size d-flex ">
                <li *ngFor="let option of item?.configurable_option;">
                  <span *ngIf="option" class="">
                    {{option?.attributes[0]?.value}}
                  </span>
                </li>
              </ul>
            </div>
            <div class="price-div">
              <p>
                {{ currency }} {{ item?.final_price | number : "1.3-3"}}
              </p>
            </div>
          </div>
        </div>
        <div class="product-total">
          x {{ item?.quantity }}
        </div>
      </div>

    </div>

    <div class="payment-details" *ngIf="order.status_id!=6">
      <div class="order-date">
        <p class="payment-details-heading"> {{'Order Date' | translate}} </p>
        <p>{{order?.created_date | date : "dd MMM yyyy"}}</p>
      </div>

      <div class="Reference-id" *ngIf="order?.payment_details?.ref?.length">
        <p class="payment-details-heading"> {{'Reference Id' | translate}} </p>
        <p>{{order?.payment_details?.ref}}</p>
      </div>

      <div class="Delivery-address">
        <p class="payment-details-heading"> {{'Delivery Address' | translate}} </p>
        <div class="address_data_added">

          <h2>{{ order?.shipping_address?.first_name }} {{order?.shipping_address?.last_name}}</h2>
          <div class="address_data_filed" >                              
            <p class="user-address-show"> <span *ngIf="order?.shipping_address?.country_name.toLowerCase()!=''">{{ order?.shipping_address?.country_name.toLowerCase() }}, </span> <span *ngIf="order?.shipping_address?.governorate_name"> {{ order?.shipping_address?.governorate_name }}</span></p> 
            <p class="user-address-show">
              <span *ngIf="order?.shipping_address?.area_name!=''">{{ order?.shipping_address?.area_name }}, </span> <span *ngIf="order?.shipping_address?.block_name!=''"> {{ order?.shipping_address?.block_name }}, </span>  <span *ngIf="order?.shipping_address?.block_id!=''"> {{ order?.shipping_address?.id }}</span>                               
            </p>
            <p class="user-address-show">
              <span *ngIf="order?.shipping_address?.street!=''">{{ order?.shipping_address?.street }}, </span> <span *ngIf="order?.shipping_address?.avenue!=''"> {{ order?.shipping_address?.avenue }}, </span>
              <span *ngIf="order?.shipping_address?.addressline_1!=''"> {{ order?.shipping_address?.addressline_1 }}, </span> <span *ngIf="order?.shipping_address?.building!=''"> {{ order?.shipping_address?.building}}, </span> <span *ngIf="order?.shipping_address?.block_name!=''"> {{ order?.shipping_address?.block_name }}</span>  <span *ngIf="order?.shipping_address?.floor!=''"> {{ order?.shipping_address?.floor}}, </span> <span *ngIf="order?.shipping_address?.flat!=''"> {{ order?.shipping_address?.flat }}</span>
            </p>
            <p class="user-address-show p-0">
              <span *ngIf="order?.shipping_address?.landmark!=''">{{ order?.shipping_address?.landmark }}, </span> <span *ngIf="order?.shipping_address?.id_number!=''"> {{ order?.shipping_address?.id_number }} </span>
            </p>
            <p class="user-address-show p-0 my-2">                                  
               <span *ngIf="order?.shipping_address?.notes!=''" class="notes">{{'Note:' | translate}} {{ order?.shipping_address?.notes }}</span>
            </p>  
            <p class="mobile_number p-0" *ngIf="order?.shipping_address?.mobile_number!=''"> +{{order?.shipping_address?.phonecode}} {{ order?.shipping_address?.mobile_number }}</p>                    
          </div>


          <!-- <p class="Delivery_name">{{ order?.shipping_address?.first_name }} {{order?.shipping_address?.last_name}}</p>
        <div class="address">
          <span>{{ order?.shipping_address?.mobile_number }}</span>
          <span>
            {{ order?.shipping_address?.addressline_1 }},
            {{ order?.shipping_address?.street }},
          </span>
          <span>{{ order?.shipping_address?.block_name }}</span>
          <span>
            {{ order?.shipping_address?.area_name }},
            {{ order?.shipping_address?.governorate_name }}
          </span>
          <span>{{ order?.shipping_address?.country_name }}</span>
        </div> -->


        </div>
        
      </div>

      <div class="Payment-method">
        <p class="payment-details-heading"> {{'Payment Method' | translate}}</p>
        <p><span class="d-flex gap-3 justify-content-start">
          <!-- <img src="" /> -->
          {{paymentMethod}}</span></p>
      </div>
    </div>

    <div class="d-flex flex-column gap-3 py-4 mb-4" *ngIf="order.status_id!=6">
      <button type="button" class="continue_shopping" 
        routerLinkActive="router-link-active" (click)="activateHeaderFromBanners()">{{'CONTINUE SHOPPING' | translate}}</button>
      <button type="button" class="order_details" [routerLink]="['/order/detail/',orderId]">{{'VIEW ORDER DETAILS' |
        translate}}
      </button>
    </div>

    <div class="d-flex justify-content-center align-items-center p-2 mt-4 " *ngIf="order.status_id!=6">
      <button class="scroll-to-top-button" (click)="scrollToTop()">
        <img src="/assets/images/arrow-up.png" class="scroll-to-top-img">
        <span class="scroll-to-top-title">{{'BACK TO TOP' | translate}}</span>
      </button>
    </div>
  </div>
</div>