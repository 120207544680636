<div class="container-fluid">
    <div class="login_width mx-auto">
        <!-- <ul class="d-flex flex-wrap justify-content-center align-items-center w-100 listing-page-routing">
            <li *ngFor="let item of breadCrumb" class="routing-list">{{item | uppercase}}</li>
          </ul> -->
        <div class="login">
            <ul class="d-flex w-100">
                <li [ngClass]="login == 'SIG' ? 'active':''" (click)="changeTab('SIG')">{{'SIGN IN' | translate}}</li>
                <li [ngClass]="login == 'REG' ? 'active':''" (click)="changeTab('REG')">{{"I'M A NEW USER" | translate}}</li>
            </ul>

            <div>
                <div class="" *ngIf="login == 'SIG'">
                    <form action="" *ngIf="enableGuestLogin" class="mt-4">
                        <label class="my-label">
                            <input type="radio" name="radio" name="type" [value]="type=='register'?true:false" [checked]="type=='register'?true:false"
                                (click)="type='register'" />
                            <span>{{"I'm a registered customer" | translate}}</span>
                        </label>
                        <label  class="my-label">
                            <input type="radio" name="radio" name="type" [value]="type=='register'?false:true" [checked]="type=='register'?false:true"
                                (click)="type='guest'" id="guest-radio-button" />
                            <span>{{'I want to checkout as a guest' | translate}}</span>
                        </label>
                    </form>

                    <div *ngIf="type == 'register'" class="mt-4">
                        <div class="">
                            <form #loginForm="ngForm" (ngSubmit)="onSubmit(loginForm)">
                                <div class="register_data">
                                    <label>{{'Email' | translate}}</label>
                                    <input type="email" [(ngModel)]="email" name="email" #spy id="inputEmail"
                                        aria-describedby="emailHelp" #nameEmail="ngModel" required
                                        pattern="[-a-zA-Z0-9~!$%^&*_=+}{'?]+(\.[-a-zA-Z0-9~!$%^&*_=+}{'?]+)*@([a-zA-Z0-9_][-a-zA-Z0-9_]*(\.[-a-zA-Z0-9_]+)*\.[a-zA-Z]{2,63})(:[0-9]{1,5})?">
                                    <small id="emailHelp" class="form-text form-validator_error"
                                        *ngIf="nameEmail.touched && !nameEmail.valid">
                                        {{'Please enter your email address' | translate}}
                                    </small>
                                </div>
                                <div class="register_data position-relative">
                                    <label>{{'Password' | translate}}</label>
                                    <div class="position-relative">
                                    <input [type]="isLoginPasswordVisible ? 'text' : 'password'" [(ngModel)]="password"
                                        name="password" #namePassword="ngModel" required>
                                    <span>
                                        <img (click)="toggleLoginPasswordVisibility()" *ngIf="!isLoginPasswordVisible"
                                            src="/assets/icons/visibility-eye.svg" alt="Password">
                                        <img (click)="toggleLoginPasswordVisibility()" *ngIf="isLoginPasswordVisible"
                                            src="/assets/icons/password-visible.svg" alt="Password">
                                    </span>
                                    </div>
                                    <small id="passwordHelp" class="form-text form-validator_error"
                                        *ngIf="namePassword.touched && !namePassword.valid">
                                        {{'Please enter your password' | translate}}
                                    </small>
                                </div>


                                <div class="sign_button">
                                    <button type="submit" [disabled]="!loginForm.form.valid"
                                        [ngClass]="{'disable-button-styles': !loginForm.form.valid}" 
                                        class="button_hover">{{'SIGN IN' | translate}}</button>
                                </div>

                                <div class="forgot_password text-center">
                                    <a [routerLink]="'/forgot-password'" href="">{{'Forgot Password?' | translate}}?</a>
                                </div>


                                <div class="or_text">
                                    <p>{{'OR' | translate}}</p>
                                </div>

                                <div class="social_login">
                                    <div class="w-100">
                                        <div class="d-flex icon_button align-items-center w-100 login_border"
                                            (click)="signInWithApple()">
                                            <img src="/assets/icons/apple-icon.png" alt="Apple">
                                            <p>{{'CONTINUE WITH APPLE' | translate }}</p>
                                        </div>
                                    </div>
                                    <div class="w-100 position-relative">
                                        <div class="icon_button  w-100 login_border googleBtnContainer"  >
                                            <img src="/assets/icons/google-icon.png" alt="Google">
                                            <asl-google-signin-button type='standard' class="googleBtn" id="google-btn" size='medium' text="continue_with" shape="square" width="400">
                                            </asl-google-signin-button>
                                            <p class="google-btn-text">{{'CONTINUE WITH GOOGLE' | translate }}</p>
                                        </div>
                                    </div>
                                    <div class="w-100">
                                        <div class="d-flex icon_button align-items-center w-100 login_border"
                                            (click)="signInWithFB()">
                                            <img src="/assets/icons/facebook-icon.png" alt="facebook">
                                            <p>{{'CONTINUE WITH FACEBOOK' | translate }}</p>
                                        </div>
                                    </div>

                                </div>

                                <div class="new_wishlist">
                                    <p *ngIf="lang == 'en'">{{'New to The Wishlist' | translate}}? <a class="pe-auto" (click)="login='REG'">{{'Register' | translate}}</a></p>
                                    <p *ngIf="lang == 'ar'"><a class="pe-auto" (click)="login='REG'">تسجيل الدخول</a></p>
                                </div>

                            </form>
                        </div>
                    </div>

                    <div *ngIf="type == 'guest'" class="mt-4">
                        <form #guestRegisterForm="ngForm" (ngSubmit)="onGuestRegisterSubmit(guestRegisterForm)">
                            <div class="register_data">
                                <label>{{'Full name' | translate}}</label>
                                <input [(ngModel)]="registerFullname" name="fullname" #spy #nameFullname="ngModel" (keyup)="validateFullNameGuestUser()"
                                    required type="text">
                                <small id="inputFullNameGuest" class="form-text form-validator_error"
                                    *ngIf="nameFullname.touched && !nameFullname.valid || lastNameRequiredGuestUser == true">
                                    {{'Please enter your full name' | translate}}
                                </small>
                            </div>
                            <div class="register_data position-relative">
                                <label>{{'Email' | translate}}</label>
                                <input type="text" [(ngModel)]="registerEmail" name="email" #spy #nameEmail="ngModel"
                                    required (keypress)="onChange($event)">
                                <small id="emailHelpGuest" class="form-text form-validator_error"
                                    *ngIf="nameEmail.touched && !nameEmail.valid || validEmail==false">
                                    {{'Please enter your email address' | translate}}
                                </small>
                            </div>

                            <div class="sign_button">
                                <button [ngClass]="{'disable-button-styles': !guestRegisterForm.form.valid}"
                                    [disabled]="!guestRegisterForm.form.valid" type="submit"
                                    class="button_hover">{{'CONTINUE' | translate}}</button>
                            </div>

                            <div class="new_wishlist mt-3">
                                <p *ngIf="lang == 'en'">{{'New to The Wishlist' | translate}}? <a class="pe-auto" (click)="login='REG'">{{'Register' | translate}}</a></p>
                                <p *ngIf="lang == 'ar'"><a class="pe-auto" (click)="login='REG'">تسجيل الدخول</a></p>
                            </div>

                        </form>
                    </div>
                </div>

                <div *ngIf="login == 'REG'">

                    <div>
                        <form #registerForm="ngForm" (ngSubmit)="onRegisterSubmit(registerForm)">
                            <div class="register_data">
                                <label>{{'Full name' | translate}}</label>
                                <input [(ngModel)]="registerName" name="fullname" #spy #nameFullname="ngModel" required type="text"
                                    (keyup)="validateFullName()">
                                <small id="inputFullNameGuest" class="form-text form-validator_error"
                                    *ngIf="nameFullname.touched && !nameFullname.valid">
                                    {{ 'Please enter your full name' | translate }}
                                </small>
                                <small id="inputFullNameGuest" class="form-text form-validator_error" *ngIf="lastNameRequired">
                                    {{ 'Last name is required' | translate }}
                                </small>
                            </div>
                            <div class="register_data position-relative">
                                <label>{{'Email' | translate}}</label>
                                <input type="text" [(ngModel)]="registerEmail" name="email" #spy #nameEmail="ngModel"
                                    required (blur)="validateEmail($event)">
                                <small id="emailHelpGuest" class="form-text form-validator_error"
                                    *ngIf="(nameEmail.touched || nameEmail.dirty) && !isEmailValid">
                                    {{'Please enter your email address' | translate}}
                                </small>
                            </div>
                            <div class="register_data position-relative">
                                <label>{{'Password' | translate}}</label>
                                <div class="position-relative">
                                <input [type]="isLoginPasswordVisible ? 'text' : 'password'" pattern=".{6,}"
                                    (input)="passwordValidation($event)" [(ngModel)]="registerPassword" name="password"
                                    #namePassword="ngModel" required>
                                <span>
                                    <img (click)="toggleLoginPasswordVisibility()" *ngIf="!isLoginPasswordVisible"
                                        src="/assets/icons/visibility-eye.svg" alt="Password">
                                    <img (click)="toggleLoginPasswordVisibility()" *ngIf="isLoginPasswordVisible"
                                        src="/assets/icons/password-visible.svg" alt="Password">
                                </span>
                            </div>
                                <small id="passwordHelp" class="form-text form-validator_error"
                                    *ngIf="(namePassword.touched && namePassword.dirty) && !isRegisterPasswordValid">
                                    {{'Please enter your password, minimum 6 characters' | translate}}
                                </small>
                            </div>
                            <div class="register_data">
                                <label>{{'Referral Code (Optional)' | translate}}</label>
                                <input type="text" [(ngModel)]="referred_by_code" name="referred_by_code"
                                #nameReferredByCode="ngModel">
                            </div>

                            <div class="login_policy">
                                <p>{{'By registering you are agreeing to our' | translate}}
                                    <a class="cursor-pointer" data-bs-toggle="modal" data-bs-target="#modelterms">{{'Terms & Conditions' | translate}}</a>,
                                    <a class="cursor-pointer" data-bs-toggle="modal"
                                        data-bs-target="#modelterms">{{'Privacy Policy' | translate}}</a> &
                                    <a class="cursor-pointer" data-bs-toggle="modal" data-bs-target="#modelterms">{{'Cookie Policy' | translate}}</a> {{'and accept to Join' | translate}}
                                </p>
                            </div>

                            <div class="sign_note">
                                <div class="d-flex gap-2 align-items-center">
                                    <div>
                                        <input type="checkbox" name="" id="note" (click)="checkMe($event)" checked>
                                    </div>
                                    <label for="note">{{'Sign up for tailored new arrivals, exciting launches and exclusive early sale access. To opt out, click unsubscribe in our emails.' | translate}}.</label>
                                </div>
                            </div>

                            <div class="sign_button">
                                <button
                                    [ngClass]="{'disable-button-styles': !registerForm.form.valid && (!isRegisterPasswordValid && !isEmailValid)}"
                                    [disabled]="!registerForm.form.valid && (!isRegisterPasswordValid && !isEmailValid)"
                                    type="submit" class="button_hover">{{'REGISTER' | translate}}</button>
                            </div>

                            <div class="or_text">
                                <p>{{'OR' | translate}}</p>
                            </div>

                            <div class="social_login">
                                <div class="w-100">
                                    <div class="d-flex icon_button align-items-center w-100 login_border"
                                        (click)="signInWithApple()">
                                        <img src="/assets/icons/apple-icon.png" alt="Apple">
                                        <p>{{'CONTINUE WITH APPLE' | translate}}</p>
                                    </div>
                                </div>
                                <div class="w-100 position-relative">
                                    <div class="icon_button  w-100 login_border googleBtnContainer">
                                        <img src="/assets/icons/google-icon.png" alt="Google">
                                        <asl-google-signin-button type='standard' class="googleBtn" id="google-btn" size='medium' text="continue_with" shape="square" width="400">
                                        </asl-google-signin-button>
                                        <p class="google-btn-text">{{'CONTINUE WITH GOOGLE' | translate }}</p>
                                        <!-- <img src="/assets/icons/google-icon.png" alt="Facebook">
                                        <p>{{'CONTINUE WITH GOOGLE' | translate}}</p> -->
                                    </div>
                                </div>
                                <div class="w-100">
                                    <div class="d-flex icon_button align-items-center w-100 login_border"
                                        (click)="signInWithFB()">
                                        <img src="/assets/icons/facebook-icon.png" alt="Google">
                                        <p>{{'CONTINUE WITH FACEBOOK' | translate}}</p>
                                    </div>
                                </div>

                            </div>

                            <div class="new_wishlist">
                                <p *ngIf="lang == 'ar'"><a class="pe-auto" (click)="login='SIG'">الدخول الى الحساب</a></p>
                                <p *ngIf="lang == 'en'">{{'Already have an account' | translate}}? <a class="pe-auto" (click)="login='SIG'">{{'Sign In' | translate}}</a></p>
                            </div>

                        </form>
                    </div>

                </div>
            </div>

        </div>
    </div>
</div>

<div class="d-flex justify-content-center align-items-center p-2 ">
    <button class="scroll-to-top-button" (click)="scrollToTop()">
        <img src="/assets/images/arrow-up.png" class="scroll-to-top-img">
        <span class="scroll-to-top-title">{{'BACK TO TOP' | translate}}</span>
    </button>
</div>

<div class="" *ngIf="showLoading">
    <app-loading></app-loading>
  </div>

<app-terms-conditions-popup></app-terms-conditions-popup>