import { Component, OnInit } from '@angular/core';
import {CartService} from "../config/cart.service";
import {Cart} from "../models/cart";
import {UserService} from "../config/user.service";
import {Router} from "@angular/router";
import {ConfigService} from "../config/config.service";
import {ConfigSettings} from "../config/config.settings";
import {CheckoutService} from "../config/checkout.service";
import {Store} from '../models/store';
import { WishlistService } from '../config/wishlist.service';
import { listStagger } from '../animations';
import { FooterService } from '../config/footer.service';
import {post} from "selenium-webdriver/http";
import {ConfigurableOptions} from "../models/configurable-options";
import {AddedToCartDialogComponent} from "../added-to-cart-dialog/added-to-cart-dialog.component";
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogComponent } from '../dialog/dialog.component';
import {LocalStorage} from '@ngx-pwa/local-storage';
import {SupportDetails} from '../models/support-details';
import { AnalyticsService } from '../services/analytics.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { CallWishlistService } from '../services/call-wishlist.service';
import { AddToCartModalService } from '../config/add-to-cart-modal.service';
import { UpdateWishlistCountService } from '../services/update-wishlist-count.service';
import { hideCartService } from '../services/hide-cart-service';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
  animations: [ listStagger ]
})
export class CartComponent implements OnInit {
    cart: Cart;
    currency: string = '';
    cartValue: number = 0;
    shippingCost: number = 0;
    vatCharges: number = 0;
    selectedQuantity: Number[] = [];
    selectedAttribute: Array<number[]> = [];
    selectedOption: Array<number[]> = [];
    selectedFilters: Array<ConfigurableOptions[]> = [];
    quantities: Array<any> = [];
    showQuantitySelect: Array<any> = [];
    showOptionSelect: Array<any> = [];
    didLoad: boolean = false;
    lang: string;
    store: Store;
    userSession = false;
    userId: number;
    wishlistCount: number = 0;
    dataAvailable = false;
    dialogRef: MatDialogRef<DialogComponent>;
    support_email = '';
    support_phone = '';
    isMobile:any = false;
    skeltonTheme:any;
    skeltonThemeSide:any;
    langForSlider = this.configSettings.getLang()
    recommendedProd:any[]
    constructor(
      private cartService: CartService,
      private configService: ConfigService,
      private configSettings: ConfigSettings,
      protected userService: UserService,
      private router: Router,
      private checkoutService: CheckoutService,
      private wishlistService: WishlistService,
      protected footerService: FooterService,
      private dialog: MatDialog,
      private analytics: AnalyticsService,
      protected localStorage: LocalStorage,
      private toaster:ToastrService,
      private translate:TranslateService,
      private callWhishList:CallWishlistService,
      private addToCartModalService:AddToCartModalService,
      private updateWishlistCountService : UpdateWishlistCountService,
      private hideCartService:hideCartService

    ) { }

    ngOnInit() {
        this.isMobile = window.innerWidth < 600;
        this.lang = this.configSettings.getLang();
        this.wishlistService.currentWishlistCount.subscribe(data => this.wishlistCount = data);
        this.configSettings.getStoreObject().then((result) => {
            this.store = <Store> result;
            this.currency = result['currency_' + this.lang];
            this.getCartItems();
        });
        this.localStorage.getItem<any>('support_details').subscribe((result) => {
            if (result) {
                const SupportDetails = <SupportDetails> result;
                this.support_email = SupportDetails.support_email;
                this.support_phone = SupportDetails.support_phone;
            }
        });
        this.callWhishList.getCallWishList().subscribe(res => {
            if (res == true) {
                this.getCartItems();
                window.scroll({ top: 0, left: 0, behavior: 'smooth' });
            }
        })
        this.hideCartService.setHideCart(true);
    }
    getCartItems() {
        this.configSettings.toggleLoading(true);
        this.dataAvailable=false;
        this.userService.getUserSession().then(response => {
            if (response === false) {
                try {
                    this.cartService.getCartItems().then(data => {
                        console.log(data)
                        this.cart = data;
                        if (this.cart) {
                            this.cartService.changCartCount(this.cart.items.length);
                            this.configSettings.setCartIdItems(this.cart.items.map(x => x.id));
                            this.cart.items.map((item, index) => {
                                this.selectedQuantity[index] = item.quantity;
                                this.quantities[index] = this.createQuantityOptions(item.remaining_quantity);

                                let tmpAttributes: number[] = [];
                                let tmpOptions: number[] = [];
                                item.configurable_option.map(option => {
                                    if (option) {
                                        tmpAttributes.push(+option.attributes[0].option_id);
                                        tmpOptions.push(+option.attribute_id);
                                    }
                                });
                                this.selectedAttribute[index] = tmpAttributes;
                                this.selectedOption[index] = tmpOptions;

                                this.selectedFilters[index] = item.configurable_option;

                                this.showQuantitySelect[index] = false;
                                this.showOptionSelect[index] = false;
                                this.cartValue += (+item.quantity * +item.final_price);
                            });
                        } else {
                            this.cartService.changCartCount(0);
                        }
                        this.didLoad = true;
                        this.configSettings.toggleLoading(false);
                        this.dataAvailable = true;
                        setTimeout(() => {this.footerService.toggleFooter(true)}, 300);
                    }, error => {
                        this.configSettings.toggleLoading(false);
                    });
                } catch (e) {
                    this.configSettings.toggleLoading(false);
                }
            } else {
                this.userId = response.id;
                this.userSession = true;
                try {
                    this.cartService.readCartItems(response.id, this.lang, this.store.iso_code).then(data => {
                        this.recommendedProd= data?.recommended_products;
                        // console.log(data)
                        this.cart = data.cart;
                        this.cartService.changCartCount(this.cart.items.length);
                        this.configSettings.setCartIdItems(this.cart.items.map(x => x.id));
                        this.cart.items.map((item, index) => {
                            this.selectedQuantity[index] = item.quantity;
                            this.quantities[index] = this.createQuantityOptions(item.remaining_quantity);

                            let tmpAttributes: number[] = [];
                            let tmpOptions: number[] = [];
                            item.configurable_option.map(option => {
                                if (option) {
                                    tmpAttributes.push(+option.attributes[0].option_id);
                                    tmpOptions.push(+option.attribute_id);
                                }
                            });
                            this.selectedAttribute[index] = tmpAttributes;
                            this.selectedOption[index] = tmpOptions;

                            this.selectedFilters[index] = item.configurable_option;

                            this.showQuantitySelect[index] = false;
                            this.showOptionSelect[index] = false;

                            this.cartValue += (+item.quantity * +item.final_price);
                        });

                        this.shippingCost = +data.shipping;
                        this.vatCharges = +data.vatCharges;
                        this.didLoad = true;
                        this.configSettings.toggleLoading(false);
                        this.dataAvailable = true;
                        setTimeout(() => {this.footerService.toggleFooter(true)}, 300);
                    }, error => {
                        this.configSettings.toggleLoading(false);
                    });
                } catch (e) {
                    this.configSettings.toggleLoading(false);
                }
            }
        }, error => { this.configSettings.toggleLoading(false); });
    }

    deleteFromCart(item, index) {
        this.dialogRef = this.dialog.open(DialogComponent, {
            data: {confirmDialog: true},
        });
        this.dialogRef.componentInstance.confirmMessage = (this.lang == 'en') ? "Are you sure you want to delete this item?" : "هل أنت متأكد أنك تريد حذف هذه القطعه؟";
        this.dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.dataAvailable=false;
                this.configSettings.toggleLoading(true);
                this.userService.getUserSession().then(response => {
                    if (response === false) {
                        this.cartService.deleteCartItem(item.id).then(data => {
                            this.cart = data;
                            this.configSettings.toggleLoading(false);
                            this.dataAvailable=true;
                        }, error => { this.configSettings.toggleLoading(false); });
                    } else {
                        const getParams = {
                            lang: this.lang,
                            store: this.store.iso_code
                        };
                        const postParams = {
                            user_id: response.id,
                            order_id: this.configSettings.getCartId(),
                            products: item.id
                        };
                        this.configService.postRequest('delete-from-cart', getParams, postParams)
                            .subscribe(response => {
                                if (response.status === 200) {
                                    const final_price = item.final_price_kwd * 3.30;
                                    this.configService.sendGTagEvent('remove_from_cart', {
                                        items: [{
                                            id: item.SKU,
                                            name: item.name,
                                            list_name: "Product Details",
                                            brand: item.brand_name,
                                            category: item.brand_name,
                                            variant: '',
                                            list_position: 1,
                                            quantity: item.quantity,
                                            price: final_price.toFixed(2)
                                        }]
                                    });

                                    this.cartValue = 0;
                                    this.cart.items.splice(index, 1);
                                    this.selectedQuantity.splice(index, 1);
                                    this.showQuantitySelect.splice(index, 1);
                                    this.showOptionSelect.splice(index, 1);

                                    response.data.items.map(item => {
                                        this.cartValue += (+item.quantity * +item.final_price);
                                    });
                                }
                                this.cartService.changCartCount(response.data.items.length);
                                this.configSettings.toggleLoading(false);
                                this.dataAvailable=true;                                
                            }, error => { this.configSettings.toggleLoading(false); });
                    }
                }, error => { this.configSettings.toggleLoading(false); });
            }
            this.dialogRef = null;
        });
        return false;
    }

    saveForLater(item, index) {
        if(this.userId) {
            this.dialogRef = this.dialog.open(DialogComponent, {
                data: {confirmDialog: true},
            });
            this.dialogRef.componentInstance.confirmMessage = (this.lang == 'en') ? "Are you sure you want to move this item to wishlist?" : "هل تريد بالتأكيد حفظ هذا المنتج في قائمة الأمنيات ؟";
            this.dialogRef.afterClosed().subscribe(result => {
                if(result) {
                    this.configSettings.toggleLoading(true);
                    this.dataAvailable=false;
            const getParams = {
                lang: this.lang,
                store: this.store.iso_code
                };
                const postParams = {
                    user_id: this.userId,
                    order_id: this.configSettings.getCartId(),
                    product_id: item.id
                };
                this.configService.postRequest('save-for-later', getParams, postParams)
                            .subscribe(response => {
                                this.cartValue = 0;
                                this.cart.items.splice(index, 1);
                                this.selectedQuantity.splice(index, 1);
                                this.showQuantitySelect.splice(index, 1);
                                this.showOptionSelect.splice(index, 1);

                                response.data.items.map(item => {
                                    this.cartValue += (+item.quantity * +item.final_price);
                                });
                                /**
                                 * Update cart item count
                                 */
                                this.cartService.changCartCount(response.data.items.length);
                                //update wishlist count..
                                this.updateWishlistCountService.setWishlistCount(response?.wishlist_count)
                                /**
                                 * Update wishlist count
                                 */
                                // const wishlistCount = (this.wishlistCount > 0) ? this.wishlistCount + 1 : 0;
                                // this.wishlistService.changWishlistCount(wishlistCount);
                                this.configSettings.toggleLoading(false);
                                this.dataAvailable=true;
                            }, error => { this.configSettings.toggleLoading(false); });

                }
                this.dialogRef = null;
            });
        }else{
            this.router.navigate(['/login']);
        }
        return false;
        
    }

    createQuantityOptions(quantity) {
        let option: any[] = [];
        for (let i = 1; i <= quantity; i++) {
            option.push({id: i, value: i});
        }

        return option;
    }
    // This code defines a function called 
    // updateCart that takes two parameters: val and index
    // The function updates the quantity of an item in a shopping cart by either incrementing or decrementing the value of the quantity based on the value of val.The updated quantity is then passed to a function called onChangeQuantity along with the index of the item being updated. The purpose of this function is to update the quantity of an item in the cart and trigger any necessary updates to the cart display or checkout process.   
    updateCart(val, index, remaining_quantity) {
        let e = { value: Number(this.selectedQuantity[index]) };
        (val == 1 ? e.value++ : e.value--);
        // (e.value > remaining_quantity ? this.translate.get('product out of stock').subscribe((res: string) => {this.toaster.error(res);}):this.onChangeQuantity(e, index))
        //adding this if condition just so that if someone new is working should be able to read...
        if(e.value > remaining_quantity){
            this.translate.get('Product out of stock').subscribe((res: string) => {this.toaster.error(res);});
            return;
        }
        this.onChangeQuantity(e, index)
    }
    onChangeQuantity($event, index) {
        this.configSettings.toggleLoading(true);
        this.userService.getUserSession().then(response => {
            if(response === false) {
                this.selectedQuantity[index] = $event.value;
                this.cart.items[index].quantity = $event.value;

                this.cartService.updateQuantity(index, $event.value).then((response) => {
                    //this.currency = response.currency;
                    // this.cartValue = response.value;
                    this.cartValue=0;
                    this.cart.items.map(item => {
                        this.cartValue += (+item.quantity * +item.final_price);      
                    });
                    this.showQuantitySelect[index] = false;
                    this.configSettings.toggleLoading(false);

                });
            } else {
                const getParams = {
                    lang: this.configSettings.getLang(),
                    store: this.store.iso_code
                };

                const postParams = {
                    user_id: response.id,
                    order_id: this.configSettings.getCartId(),
                    products: this.cart.items[index].id,
                    quantity: $event.value
                };

                this.configService.postRequest('update-cart', getParams, postParams)
                    .subscribe(response => {
                        this.cartValue = 0;                        
                        this.shippingCost = response.data.shipping_cost;
                        response.data.items.map(item => {
                            this.selectedQuantity[index] = $event.value;
                            this.cart.items[index].quantity = $event.value;
                            this.showQuantitySelect[index] = false;
                            this.cartValue += (+item.quantity * +item.final_price);
                        });
                        this.configSettings.toggleLoading(false);
                    });
            }
        }, error => { this.configSettings.toggleLoading(false); });
    }

    update(index) {
        this.showQuantitySelect[index] = true;
        return false;
    }

    cancelUpdate(index) {
        this.showQuantitySelect[index] = false;
        return false;
    }

    proceedToShipping() {
        this.checkoutService.setCart(this.cart);
        this.checkoutService.setShipping(this.shippingCost);
        this.router.navigate(['addressbook']);

        var ids= [];
        var qtys = 0;
        var gtotal = 0;
        var cartItems = [];
        var productCategories = [];
        var productNames = [];
        this.cart.items.forEach(element => {
            ids.push(element.id);
            cartItems.push(element);
            productNames.push(element.name);
            productCategories.push(element.marketing_category+' - '+element.marketing_subcategory)
            qtys = qtys+element.quantity;
            gtotal = Number(gtotal)+Number(element.final_price);
        });
        //Adding To Event
        this.analytics.initiateCheckout(
            ids,
            productNames,
            cartItems,
            qtys,
            String(gtotal)
        );
    }

    onOptionSelect(option, $event, index) {
        this.clearFilters(option, $event, index).then(() => {
            this.filterOptions(index);
        });
    }

    clearFilters(configurable_option:ConfigurableOptions, $event, index: number): Promise<any> {
        let promise = new Promise<any>((resolve, reject) => {
            this.cart.items[index].configurable_option.map((option, i) => {
                if(option.attribute_id === configurable_option.attribute_id) {
                    if(i == 0) {
                        this.selectedAttribute[index].splice(i);
                        this.selectedOption[index].splice(i);
                        this.selectedFilters[index].splice(i);
                    }

                    this.selectedAttribute[index][i] = $event.option_id;
                    this.selectedOption[index][i] = +configurable_option.attribute_id;
                    this.selectedFilters[index][i] = {
                        type: configurable_option.type,
                        attribute_id: configurable_option.attribute_id,
                        attribute_code: configurable_option.attribute_code,
                        attributes: [$event]
                    };
                }
            });

            resolve(true);
        });

        return promise;

    }

    editOption(index, optionIndex) {
        //if(optionIndex === 0) {
            this.selectedOption[index].splice(optionIndex);
            this.selectedAttribute[index].splice(optionIndex);
        //}

        this.filterOptions(index);
        this.showOptionSelect[index] = true;
        return false;
    }

    cancelEdit(index) {
        this.showOptionSelect[index] = false;
        return false;
    }

    filterOptions(index) {
        this.configSettings.toggleLoading(true);
        const getParams = {
            lang: this.lang,
            store: this.store.iso_code
        };

        const postParams = {
            product_id: this.cart.items[index].parent_id,
            option_id: (this.selectedAttribute[index].length) ? this.selectedAttribute[index].join(',') : '',
            attribute_id: (this.selectedOption[index].length) ? this.selectedOption[index].join(',') : ''
        };

        this.configService.postRequest('configurable-options', getParams, postParams)
            .subscribe(response => {
                if (response.data[0]) {
                    if (response.data[0].attributes.length) {
                        this.cart.items[index].configurable_option.map( option => {
                            if (option.attribute_id === response.data[0].attribute_id) {
                                option.attributes = [];
                                option.attributes = response.data[0].attributes;
                            }
                        });
                    } else {
                        this.updateBag(index, response.data[0].entity_id);
                    }
                }

                this.configSettings.toggleLoading(false);
            }, error => { this.configSettings.toggleLoading(false); });
    }

    updateBag(index, entityId) {
        try{
            if(this.cart.items[index].is_saleable) {
                this.configSettings.toggleLoading(true);
                if(this.cart.items[index].id) {
                    this.userService.getUserSession().then(response => {
                        if (response === false) {
                            this.cartService.updateOfflineCart(entityId, this.cart.items[index], this.selectedFilters[index], this.selectedQuantity[index]).then(response => {
                                this.showOptionSelect[index] = false;
                                this.configSettings.toggleLoading(false);
                            });
                        } else {
                            this.updateOnlineCart(response.id, this.cart.items[index].id, entityId, this.selectedQuantity[index]).then((any) => {
                                this.showOptionSelect[index] = false;
                                this.configSettings.toggleLoading(false);
                            });
                        }
                    }, error => { this.configSettings.toggleLoading(false); });
                } else {
                    this.configSettings.toggleLoading(false);
                    let selects = document.querySelectorAll('ng-select');
                    for(let i = 0; i < selects.length; i++) {
                        selects[i].classList.add('error');
                    }
                }
            }
        } catch(e) {
            this.configSettings.toggleLoading(false);
        }
    }

    private updateOnlineCart(user: string, oldProduct, newProduct, quantity): Promise<any> {
        return new Promise(resolve => {
            const getParams = {
                user: user,
                order: this.cart.id,
                old_product: oldProduct,
                new_product: newProduct,
                quantity: quantity,
                lang: this.lang,
                store: this.store.iso_code
            };

            this.configService.readRequest('update-product-in-cart', getParams)
                .subscribe(response => {
                    //this.cart.items = response.body.data.items;
                    resolve(true);
                });
        });
    }


    slideCustomers: any = {
        variableWidth: false,
        infinite: true,
        speed: 300,
        slidesToShow: 4,
        slidesToScroll: 1,
        rtl: this.langForSlider == 'en' ? false : true,
        dots: false,
        prevArrow: '<span class="slick-prev suggestion" style=""><svg width="16px" height="70px" viewBox="0 0 16 70"><g transform="translate(1 1)" stroke="#444141" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="square"><path transform="matrix(-1 0 0 1 14 0)" d="M0.5 0.5L13.5 33.5"></path><path transform="rotate(180 7 50.5)" d="M0.5 33.5L13.5 67.5"></path></g></svg></span>',
        nextArrow: '<span class="slick-next suggestion" style=""><svg width="16px" height="70px" viewBox="0 0 16 70"><g transform="translate(1 1)" stroke="#444141" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="square"><path d="M0.5 0.5L13.5 33.5"></path><path transform="matrix(1 0 0 -1 0 100)" d="M0.5 33.5L13.5 66.5"></path></g></svg></span>',
        // asNavFor: '.gallery-thumb',
        responsive: [
            {
                breakpoint: 1025,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 1,
                  arrows: true,
                }
              },
            {
                breakpoint: 913,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1,
                  arrows: true,
                }
              },
            {
                breakpoint: 767,
                settings: {
                  slidesToShow: 2,
                 
                }
            }
            
          ]
    };  

    routeTo(){
      
        var ids= [];
        var qtys = 0;
        var gtotal = 0;
        var cartItems = [];
        var productCategories = [];
        var productNames = [];
        this.cart.items.forEach(element => {
            ids.push(element.id);
            cartItems.push(element);
            productNames.push(element.name);
            productCategories.push(element.marketing_category+' - '+element.marketing_subcategory)
            qtys = qtys+element.quantity;
            gtotal = Number(gtotal)+Number(element.final_price);
        });
        //Adding To Event
        this.analytics.initiateCheckout(
            ids,
            productNames,
            cartItems,
            qtys,
            String(gtotal)
        );
        const route = this.userSession ? '/checkout' : '/login/guest';
        this.router.navigate([route]);
    }

    ngOnDestroy(): void {
        console.log('on destroy');
        
        this.addToCartModalService.sendItemOpen('close');
        this.hideCartService.setHideCart(false);

    }
}
