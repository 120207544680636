<div class="product-item" title="{{item.name}}" [ngClass]="{'px-3': (minimal)}">
  <!-- <div class="wishlist-remove-btn" *ngIf="showWishlistIcon">
    <button id="wishBtn{{item.id}}" class="wishlist-button {{in_wishlist ? 'fill' : 'fill'}}" (click)="removeFromWishlist()">
      <img src="/assets/icons/cross_popup.svg" alt="remove" class="remove-wishlist">
    </button>
  </div> -->

<div class="top-text">
  <div class="d-flex justify-content-between justify-content-center align-items-center">
    <div class="">
      <!-- <div class="pre-order-badge" *ngIf="(item.is_preorder == 1 && item.remaining_quantity <= 0)"><span>{{' Pre Order' | translate}}</span></div>
      <div class="pre-order-badge" *ngIf="item.is_preorder == 0 && item.remaining_quantity <= 0"><span>{{'Sold Out' | translate}}</span></div> -->
      <div *ngIf="item.regular_price != item.final_price" class="new_sale">
        <img  src="/assets/icons/SALE-Tag.svg" alt="">
        <span>
          <span>-</span>{{ calculateDiscountAmount(item)}}<span>%</span>
        </span>
        <!-- {{calculateDiscountAmount(item) | }} -->
      </div>
    </div>
  <div *ngIf="hideWishlistIcon == false">
    <div class="heart-container heart-section"  *ngIf="isUserLoggedIn == true " [ngClass]="showWishlistIcon?'':'heart-back'">
      <img class="heart-image add-to-wishlist" (click)="addToWishlist()" *ngIf="!in_wishlist  && !showWishlistIcon" src="/assets/icons/wishlist.svg" appClickToFav alt="">
      <img class="heart-image " (click)="removeFromWishlist()" *ngIf="in_wishlist  && !showWishlistIcon" src="assets/icons/heart-filled.svg" appClickToFav alt="">
      <img class="remove-wishlist" src="/assets/icons/cross_popup.svg" *ngIf="showWishlistIcon && showCrossIcon" (click)="removeFromWishlist()" alt="remove" class="remove-wishlist">
      <div class="big-heart position-absolute" [class.fill-heart]="isHeartFilled">
        <div *ngFor="let subHeart of subHearts; let i = index">
          <svg viewBox="0 0 24 22" xmlns="http://www.w3.org/2000/svg" class="sub-hearts move-heart-{{ i }}"
            [style.top]="subHeart.moveTop + '%'" [style.left]="subHeart.moveLeft + '%'" [style.bottom]="subHeart.moveBottom + '%'" [style.right]="subHeart.moveRight + '%'">
            <path d="M11.0661 2.81728L11.6026 3.45246L12.1391 2.81728C13.2867 1.45859 15.0479 0.600098 16.8697 0.600098C20.0815 0.600098 22.6051 3.13599 22.6051 6.38807C22.6051 8.38789 21.72 10.2564 20.0229 12.3496C18.3175 14.4529 15.8645 16.6977 12.8407 19.4622L12.826 19.4756L12.8249 19.4766L11.6008 20.6001L10.3798 19.4879L10.3783 19.4866L10.3336 19.4457C10.3336 19.4457 10.3336 19.4457 10.3336 19.4457C7.31862 16.6834 4.87347 14.4409 3.17365 12.3404C1.4821 10.25 0.600098 8.38442 0.600098 6.38807C0.600098 3.13599 3.12361 0.600098 6.33544 0.600098C8.15729 0.600098 9.91845 1.45859 11.0661 2.81728Z"
              stroke="#333333" stroke-width="1" [style.fill]="subHeart.color" />
          </svg>
        </div>
      </div>
      
    </div>
    <div class="heart-section heart-back" *ngIf="isUserLoggedIn == false "  (click)="logInWhistList()">
      <img class="heart-image" src="/assets/icons/wishlist.svg"  alt="">
    </div>
  </div>
  </div>
</div>

  <a  (click)="routingToDetails(item.id)">
    <div class="ratio-image-container">
      
      <div class="product-img-dummy"></div>
        <!-- <a href="/product/detail/{{ item?.id }}"  (click)="$event.preventDefault()" class="hover-switch">
          <img [src]="item?.hover_image"  class="toggle-image"
            [alt]="item?.name"> 
            <img [src]="item?.image" class="normal-image" [alt]="item?.name">
        </a> -->
        <a href="/product/detail/{{ item?.id }}" class="" (click)="$event.preventDefault()">
          <img [src]="item.image" class="carousel-imgs1 product-normal-image" [alt]="item?.name">
          <img [src]="item.hover_image" class="carousel-imgs1 product-hovered-image" [alt]="item?.name">
        </a>        
    </div>
   
  </a>
  
  <a class="product-details-wrapper-link" >
    
      <div class="row m-0 w-100" (click)="routingToDetails(item.id)">
        <div class="col-12 col-md-12 p-0 product-details-name">
          <p class="product-brand font-regular">
            {{item.brand}}
          </p>
          <p class="product-name-regular" *ngIf="!minimal">
            {{item.name}}
          </p>
        </div>

        <!-- PRODUCT PRICE FOR ENGLISH -->
        <ng-container >
          <div class="col-12 col-md-12 p-0 product-price-details">
            <div class="product-price main-price" *ngIf="!minimal">
              <p class="d-flex gap-3 justify-content-center align-items-center">
                <span class="final-price" [ngClass]="(item.regular_price != item.final_price) ? '' : 'regular-price'">{{currency}} {{item.final_price | number:'1.3-3'}}</span>
                <span class="crossed crossed-price" *ngIf="(item.regular_price != item.final_price)"><del>{{currency}} {{item.regular_price | number:'1.3-3'}}</del></span>
              </p>
          </div>
          </div>
        </ng-container>
        <!-- PRODUCT PRICE FOR ENGLISH -->

        <!-- PRODUCT PRICE FOR ARABIC -->
        <!-- <ng-container *ngIf="lang == 'ar' && !isMobile">
         
          <div class="col-12 col-md-12 p-0 product-price-details">
            
            <div class="product-price main-price" *ngIf="!minimal">
              <p class="d-flex gap-3 justify-content-center align-items-center">
                <span class="crossed" *ngIf="(item.regular_price != item.final_price)"><del>{{currency}} {{item.regular_price | number:'1.3-3'}}</del></span>
                <span [ngClass]="(item.regular_price != item.final_price) ? 'red' : 'regular-price'">{{currency}} {{item.final_price | number:'1.3-3'}}</span>
              </p>
          </div>
          </div>
        </ng-container> -->
        <!-- PRODUCT PRICE FOR ARABIC -->

        <p class="best-seller">{{setProductBadge(item)}}</p>
      </div>
      <div class="Add-to-bag" *ngIf="showWishlistIcon">
        <button type="button" class="add-to-button white_button_hover" (click)="takeToBag(item)" data-bs-toggle="modal" data-bs-target="#modelAddToBag">
            {{'ADD TO BAG' | translate}}
        </button>
      </div>
  </a>

  
  <span class="close-icon" *ngIf="enableRemoveWishlist" (click)="removeFromWishlist()"><i class="fas fa-times"></i></span>
</div>

<!-- <app-add-to-bag-popup ></app-add-to-bag-popup> -->
