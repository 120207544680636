import { Component, OnInit } from '@angular/core';
import {NavbarService} from "../config/navbar.service";
import { listStagger } from '../animations';
import {User} from "../models/user";
import {UserService} from "../config/user.service";
import {SocialAuthService } from "@abacritt/angularx-social-login";
import {WishlistService} from "../config/wishlist.service";
import {CartService} from "../config/cart.service";
import {LocalStorage} from "@ngx-pwa/local-storage";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { DialogComponent } from '../dialog/dialog.component';
import {NgForm} from "@angular/forms";
import {ConfigSettings} from "../config/config.settings";
import {HttpClient, HttpParams} from "@angular/common/http";
import {FooterService} from "../config/footer.service";
import { callContactNo } from '../services/callContactNo';

interface MailChimpResponse {
    result: string;
    msg: string;
}

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  animations: [ listStagger ]
})
export class FooterComponent implements OnInit {

  footerStatus: boolean = true;
  navigationStatus: boolean = true;
  appStoreLink = '';
  playStoreLink  = '';
  twitterLink = '';
  facebookLink = 'https://m.facebook.com/The-Wish-List-103188571038295/';
  instagramLink = 'https://www.instagram.com/shop.twl/?igshid=1lxvfpr13fm2x';
  snapchatLink = '';
  user: User;
  email: string;
  validEmail: boolean = false;
  lang: string;
  mailChimpEndpoint = 'https://shop-twl.us20.list-manage.com/subscribe/post-json?u=f3074b58b0bb806778ba8ce4b&id=8842f54562&';
  mailchimpSubmitted = false;
  year:any = new Date().getFullYear();
 contactNo:any;
  constructor(
      private navbarService: NavbarService,
      protected localStorage: LocalStorage,
      private userService: UserService,
      private authService: SocialAuthService ,
      private wishlistService: WishlistService,
      private cartService: CartService,
      private dialog: MatDialog,
      private configSettings: ConfigSettings,
      private http: HttpClient,
      private footerService: FooterService,
      private callContactNo:callContactNo
  ) { }

  ngOnInit() {
      this.footerService.footerStatus.subscribe(data => this.footerStatus = data);
      this.lang = this.configSettings.getLang();
      this.navbarService.change.subscribe(status => {
          this.navigationStatus = status;
      });
      this.userService.getUserSession().then(response => {
          if(response !== false) {
              this.user = response;
          }
      });
      this.callContactNo.getContactNo().subscribe(res=>{
        if(res.length!=0){
            this.contactNo=res;            
        }
      })
  }

    showLogoutModal() {
        let dialogRef = this.dialog.open(DialogComponent, {
            data: {logout: true, user: this.user },
        });
    }

    onSubmit(emailForm: NgForm) {
      if(this.email) {
          this.registerForNewsletter();
          emailForm.reset();
      }
    }

    registerForNewsletter() {
        this.configSettings.toggleLoading(true);
        const params = new HttpParams()
            .set('NAME', '')
            .set('EMAIL', this.email)
            .set('b_123abc123abc123abc123abc123abc123abc', '');

        const mailChimpUrl = this.mailChimpEndpoint + params.toString();

        this.http.jsonp<MailChimpResponse>(mailChimpUrl, 'c').subscribe(
            response => {
                this.configSettings.toggleLoading(false);
                this.mailchimpSubmitted = true;
                setTimeout(() => { this.mailchimpSubmitted = false; }, 5000);
            }, error => {
                this.configSettings.toggleLoading(false);
            });
    }

    onChange(newValue) {
        const validEmailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (validEmailRegEx.test(newValue)) {
            this.validEmail = true;
        }else {
            this.validEmail = false;
        }
    }
}
