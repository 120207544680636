<div class="my_account_data" *ngIf="skeletonLoader">
  <div class="container-fluid">
    <section >
      <div class="account_heading pt-3 pb-5">
        <ul class="d-flex flex-wrap align-items-center justify-content-center w-100 listing-page-routing" >
          <ngx-skeleton-loader class="w-25"></ngx-skeleton-loader>   
        </ul>
      </div>

      <section >
        <div class="d-flex w-100 justify-content-center w-100">
          <div class="user-left-menu d-none d-lg-block w-25">
            <div class="w-50">
              <ngx-skeleton-loader count="8"></ngx-skeleton-loader>
            </div>
          </div>
          <div class="user-profile-content w-100 w-lg-75">
            <div class="breadcrumb_heading d-flex flex-column flex-lg-row align-items-center justify-content-between gap-4 gap-lg-3">
              <h3 class="w-25">
                <ngx-skeleton-loader ></ngx-skeleton-loader>   
                </h3>
              <div class="address_data_box add-addres-box p-0" >
                <div class="d-flex align-content-center align-items-center">
                  <div class="add_new_add items-height-skeleton w-100">
                    <ngx-skeleton-loader class="height-fix" ></ngx-skeleton-loader>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div class="address_data">
                <div class="address_data_box position-relative ">
                  <div class="address_data_added position-relative">
                    <h2 class="w-50">
                      <ngx-skeleton-loader ></ngx-skeleton-loader> 
                    </h2>
                    <div class="address_data_filed">

                      <p class="address">
                        <span class="skelton-address"><ngx-skeleton-loader></ngx-skeleton-loader> </span> 
                        <span class="skelton-address"><ngx-skeleton-loader></ngx-skeleton-loader> </span> 
                      </p>
                      <p class="address">
                        <span class="skelton-address"><ngx-skeleton-loader></ngx-skeleton-loader> </span> 
                        <span class="skelton-address"><ngx-skeleton-loader></ngx-skeleton-loader> </span> 
                      </p>
                      <p class="address">
                        <span class="skelton-address"><ngx-skeleton-loader></ngx-skeleton-loader> </span> 
                        <span class="skelton-address"><ngx-skeleton-loader></ngx-skeleton-loader> </span> 
                      </p>  
                      <p class="note_address my-2 p-0">
                        <span class="skelton-address"><ngx-skeleton-loader></ngx-skeleton-loader> </span> 
                      </p>
                      <p class="mobile_number p-0">
                        <!-- <ngx-skeleton-loader></ngx-skeleton-loader> -->
                        <span class="skelton-phone"><ngx-skeleton-loader></ngx-skeleton-loader> </span> 

                      </p>
                    </div>
                    <div class="address_data_button ">
                    
                      <div class="d-flex w-100 align-items-center justify-content-between gap-4 ">
                        <div class="w-25 gap-4">
                          <ngx-skeleton-loader class=""></ngx-skeleton-loader>
                        </div>
                        <div class="d-flex w-25 align-items-center gap-2 ">
                          <span class="w-50"><ngx-skeleton-loader></ngx-skeleton-loader> </span>
                          <span class="w-50"><ngx-skeleton-loader></ngx-skeleton-loader> </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  </div>
</div>

<div class="my_account_data" *ngIf="!skeletonLoader">
  <div class="container-fluid">
    <section @listStagger>
      <div class="account_heading pt-3 pb-5">
        <ul class="d-flex flex-wrap align-items-center justify-content-center w-100 listing-page-routing" >
          <li class="routing-list">
            <a [routerLink]="'/my-account'">
              {{ "my account" | translate }}
            </a>
          </li>
          <li class="routing-list">{{ "ADDRESS BOOK" | translate }}</li>
        </ul>
      </div>

      <section >
        <div class="d-flex w-100 justify-content-center w-100">
          <div class="user-left-menu d-none d-lg-block w-25">
            <app-user-account-menu [page]="4"></app-user-account-menu>
          </div>
          <div class="user-profile-content w-100 w-lg-75">
            <div class="breadcrumb_heading d-flex flex-column flex-lg-row align-items-center justify-content-between gap-4 gap-lg-3">
              <h3><a [routerLink]="'/my-account'"> <img src="/assets/icons/right-arrow.svg" alt="arrow"></a> {{ "My Addresses" | translate }}</h3>
              <div *ngIf="addresses.length > 0" class="address_data_box add-addres-box" (click)="newAddress()">
                <div class="d-flex align-content-center align-items-center w-100 h-100">
                  <div class="add_new_add w-100">
                    <p>{{ "ADD NEW ADDRESS" | translate }}</p>
                    <span><img src="/assets/icons/plus.svg" alt="Plus" /></span>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div class="address_data">
                <div class="address_data_box position-relative " *ngFor="let address of addresses; let i = index" >
                  <div class="address_data_added position-relative">
                    <h2>{{ address?.first_name + " " + address?.last_name }}</h2>
                    <div class="address_data_filed">

                      <p class="address">
                        <span *ngIf="address?.country_name!=''">{{ address?.country_name.toLowerCase() }},</span> <span *ngIf="address?.governorate_name!=''">{{ address?.governorate_name }}</span>
                      </p>
                      <p class="address">
                        <span *ngIf="address?.block_name!=''">{{ address?.block_name}} ,</span> <span *ngIf="address?.block_id!=''">{{ address?.block_id}} ,</span> <span *ngIf="address?.area_name!=''">{{ address?.area_name}} </span>
                      </p>
                      <p class="address">
                        <span *ngIf="address?.street!=''">{{ address?.street }},</span> <span *ngIf="address?.avenue!=''">{{ address?.avenue }},</span> <span *ngIf="address?.flat!=''">{{ address?.flat }},</span> <span *ngIf="address?.building!=''">{{ address?.building}},</span><span *ngIf="address?.floor!=''">{{ address?.floor}}</span>
                      </p>
                      <p class="address p-0">
                           <span *ngIf="address?.landmark!=''">{{ address?.landmark }}</span> <span *ngIf="address?.id_number!=''">{{ address?.id_number }}</span>
                      </p>

              
                      <!-- <p class="mt-1">{{'Landmark' | translate}}: {{ address.landmark }}</p> -->
                      <!-- <p class="mt-1">ID #:{{ address.id_number }}</p> -->
                      <p class="note_address my-2 p-0">{{'Note:' | translate}}  {{ address?.notes | truncate:[140,'...']  }}</p>
                      <p class="mobile_number p-0">+{{address?.phonecode}} {{ address?.mobile_number }}</p>
                    </div>
                    <div class="address_data_button ">
                    
                      <div class="d-flex align-items-center justify-content-between gap-4 ">
                        <a class="order_checkout_method">
                        <input type="radio" [id]="address.address_id" name="radio" [checked]="address.is_default == 'Yes'" (change)="makeDefault(address.address_id)">
                        <label [for]="address.address_id">{{ "Set as default" | translate }}</label>
                        </a>
                      <div class="d-flex align-items-center gap-4">
                        <a type="button"  (click)="editAddress(address)">
                          <span>{{ "Edit" | translate }}</span>
                        </a>
                        <a *ngIf="address?.is_default!='Yes'" (click)="deleteAddress(address.address_id)">
                          <span>{{ "Delete" | translate }}</span>
                        </a>
                      </div>
                       
                      </div>
                    </div>
                  </div>
                </div>

                <!-- data-bs-toggle="modal" data-bs-target="#exampleModal"  -->
                
                
              </div>
            </div>





            <div *ngIf="addresses.length == 0" class="empty-cart">
              <div class="empty-cart-content white-container">
                <h4 class="page-title font-bold text-center">
                  {{ "You Have No Saved Addresses" | translate }}
                </h4>
                <p class="font-regular text-center">
                  {{ "Add an address for a fast checkout." | translate }}
                </p>

                <button type="button" class="button_hover" (click)="newAddress()">
                  {{ "ADD NEW ADDRESS" | translate }}
                </button>
              </div>
            </div>
            
          </div>
        </div>
      </section>
    </section>
  </div>
</div>


<div class="model-background" *ngIf="displayPopup">
  <div (click)="displayPopup = false"  class="background-overly"></div>
  <app-new-address-popup (resetAddressList)="resetAddressListFun()" (displayPopupEvent)="closePopup()"></app-new-address-popup>
</div>