import { Component, ElementRef, Input, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-grid-view-slider',
  templateUrl: './grid-view-slider.component.html',
  styleUrls: ['./grid-view-slider.component.scss']
})
export class GridViewSliderComponent {
  @Input() data : any
  isMobile : boolean = false
  smallImageArr: Array<any> = []

  constructor(
    private router : Router,
    private renderer: Renderer2,
    private el: ElementRef
    ){
    this.isMobile = window.innerWidth < 600;
  }

  ngAfterViewInit(){
    if (this.data) {
    this.smallImageArr = this.chunkArray(this.data,2)
    this.setGridImageSize()
    }
  }

  setGridImageSize(){
    setTimeout(() => {
      const bigImage = this.el.nativeElement.querySelector('.side-big-image');
      const bigImageWidth = bigImage.clientWidth;
      const bigImageHeight = bigImage.clientHeight;
  
      // to set the ratio for the small images with class "side-grid-image"
      const smallImages = this.el.nativeElement.querySelectorAll('.side-grid-image');
      smallImages.forEach((smallImage: HTMLImageElement) => {
        //  width set to auto to maintain aspect ratio
        smallImage.style.width = 'auto';
        //  height to half of the big image width
        smallImage.style.height = (bigImageHeight / 2) - 4 + 'px'; // - 4 is to match margins
      });
      
    }, 1000);
  }

  // to divide array in to chunks of 2
  chunkArray<T>(array: T[], chunkSize: number): T[][] {
    const chunkedArray: T[][] = [];
    for (let i = 1; i < array.length; i += chunkSize) {
      const chunk = array.slice(i, i + chunkSize);
      chunkedArray.push(chunk);
    }
    return chunkedArray;
  }

    // for routing of banners
    routing(value: any) {
      const type = value.link_type;
      if (type === 'B' || type === 'BR') {
        this.router.navigate(['/brand', this.getUrlName(value.name), value.link_id])
        return
      }
      else if (type === 'C' || type === 'FC') {
        this.router.navigate(['/category', this.getUrlName(value.name), value.link_id])
        return
      }
      else if (type === 'P') {
        this.router.navigate(['/product/detail', value.link_id])
        return;
      }
      else if (type === 'FP') {
        this.router.navigate(['/product/detail', value.link_id])
        return;
      }
      else if (type === 'L') {
        this.router.navigateByUrl(value.link)
        return value.link;
      }
      else if (type === 'S') {
        this.router.navigate(['/shop', this.getUrlName(value.name), value.link_id])
        return;
      }
      else if (type === 'F') {
        this.router.navigate(['/search'], { queryParams: this.getCollectionQueryParams(value, 0) })
        return;
      } else if (type === 'N') {
        this.router.navigate(['/shop/new-arrivals'], { queryParams: this.getCollectionQueryParams(value, 1) })
        return;
      }
      return '';
    }
  
    // to remove spaces in url string with "-"
    getUrlName(productName: string): string {
      var prodN = productName ? (productName.toLowerCase().replace(/[^A-Z0-9]+/ig, "-")) : '';
      return prodN;
    }
  
    // get query params based on latestId
    getCollectionQueryParams(collection: any, latestId: any): any {
      if (latestId == 0) {
        return {
          q: '',
          lang: 'en',
          price_range: '',
          in_stock: '',
          typepage: '1',
          per_page: '20',
          is_featured: '',
          latest: latestId,
          best_selling: '',
          sort_by: '1',
          store: '',
          flash_sale_id: collection.link_id,
          shop_type_id: ''
        };
      } else {
        return {
          lang: 'en',
          price_range: '',
          in_stock: '',
          typepage: '1',
          per_page: '20',
          is_featured: '',
          latest: latestId,
          best_selling: '',
          sort_by: '1',
          store: '',
          flash_sale_id: '',
          shop_type_id: ''
        };
      }
  
    }

}
