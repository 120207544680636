<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
  role="document">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="edit_data">
        <!-- <button type="button" class="close"data-bs-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button> -->
        <div class="edit_data_header">
          <div class="d-flex justify-content-between w-100 align-items-center">
            <div>
              <div class="d-flex align-items-center gap-3">
                <div>
                  <img class="edit_profile" src="/assets/icons/Location.svg" alt="Edit Profile" />
                </div>
                <div>
                  <h1>{{ "Add Address" | translate }}</h1>
                </div>
              </div>
            </div>
            <div>
              <img class="cross_popup" src="/assets/icons/cross_popup.svg" alt="Cross" data-bs-dismiss="modal">
            </div>
          </div>
          <!-- <div class="shipping_to">
            <p>Shipping to <img src="../../assets/icons/kuwait_flag.svg" alt="flag"> Kuwait</p>
          </div> -->
        </div>
        <form [formGroup]="shippingAddressForm" (ngSubmit)="saveAddress(shippingAddressForm)">
          <div class="edit_data_body">
            <div>
              <label for="">{{'First Name' | translate}}<span>*</span></label>
              <input type="text" id="first_name" name="address-first_name" aria-describedby="firstNameHelp"
                formControlName="first_name" required>
              <small id="firstNameHelp" class="form-text form-validator_error"
                [hidden]="!shippingAddressForm.controls['first_name'].valid || !shippingAddressForm.controls['first_name'].pristine">
                {{ 'Please enter your Name' | translate }}
              </small>
            </div>
            <div>
              <label for="">{{'Last Name' | translate}}<span>*</span></label>
              <input type="text" id="last_name" name="address-last_name" aria-describedby="lastNameHelp"
                formControlName="last_name" required>
              <small id="lastNameHelp" class="form-text form-validator_error"
                [hidden]="!shippingAddressForm.controls['last_name'].valid || !shippingAddressForm.controls['last_name'].pristine">
                {{'Please enter your Name' | translate}}
              </small>
            </div>
            <div class="edit_data_body-cols">
              <label for="">{{'Mobile' | translate}}<span>*</span></label>
              <div class="d-flex gap-3 w-100 align-items-baseline">
                <div class="w-25">

                  <input disabled placeholder={{countryCode}} (keypress)="key($event)">
                </div>
                <div class="">
                  <input id="mobile_number" name="address-mobile_number"
                    aria-describedby="mobileNumberHelp" formControlName="mobile_number" (keypress)="key($event)"
                    maxlength="10" minlength="5" required>
                </div>
              </div>

              <small id="mobileNumberHelp" class="form-text form-validator_error"
                [hidden]="shippingAddressForm.controls['mobile_number'].valid || shippingAddressForm.controls['mobile_number'].pristine">
                {{'Please enter a valid mobile number' | translate}}
              </small>
            </div>
            <div class="edit_data_body-cols">
              <label>{{'Country' | translate}}<span>*</span></label>
              <ng-select [items]="countries" [addTag]="true" bindLabel="name" bindValue="id" id="country_id"
                name="address-country_id" aria-describedby="countryHelp"formControlName="country_id" [clearable]=false
                [searchable]=false [placeholder]="'Select One' | translate" (change)="getStates($event)"
                class="select-input add-ship" required></ng-select>
              <small id="countryHelp" class="form-text form-validator_error"
                [hidden]="!shippingAddressForm.controls['country_id'].valid || !shippingAddressForm.controls['country_id'].pristine">
                {{'Please select your country' | translate}}
              </small>
            </div>
            <div>
              <label>{{'Governorate' | translate}}<span>*</span></label>
              <ng-select [items]="governorates" [addTag]="true" bindLabel="name" bindValue="id" id="governorate_id"
                name="address-governorate_id" aria-describedby="governorateHelp" formControlName="governorate_id"
                [clearable]=false [searchable]=false [placeholder]="'Select One' | translate"
                (change)="getAreas($event)"  class="select-input add-ship"
                required></ng-select>
              <small id="governorateHelp" class="form-text form-validator_error"
                [hidden]="shippingAddressForm.controls['governorate_id'].valid || shippingAddressForm.controls['governorate_id'].pristine">
                {{'Please select your state/prov./region' | translate}}
              </small>
            </div>
            <div>
              <label>{{'Town / City' | translate}}<span>*</span></label>
              <ng-select [items]="areas" [addTag]="true" bindLabel="name" bindValue="id" id="area_id"
                name="address-area_id" aria-describedby="areaHelp" formControlName="area_id" [clearable]=false
                [searchable]=false [placeholder]="'Select One' | translate" (change)="getBlocks($event)"
                 class="select-input add-ship" required></ng-select>
              <small id="areaHelp" class="form-text form-validator_error"
                [hidden]="shippingAddressForm.controls['area_id'].valid || shippingAddressForm.controls['area_id'].pristine">
                {{'Please select your city/area' | translate}}
              </small>
            </div>
            <div>
              <label>{{'Block' | translate}}<span>*</span></label>
              <ng-select [items]="sectors" [addTag]="true" bindLabel="name" bindValue="id" id="block_id"
                name="address-block_id" formControlName="block_id" [clearable]=false [searchable]=false
                [placeholder]="'Select One' | translate"  class="select-input add-ship">
              </ng-select>
            </div>
            <div>
              <label>{{'Street' | translate}}<span>*</span></label>
              <input  class="form-control text-input" id="street" name="address-street"
                aria-describedby="streetHelp" formControlName="street" maxlength="100" required>
              <small id="streetHelp" class="form-text form-validator_error"
                [hidden]="shippingAddressForm.controls['street'].valid || shippingAddressForm.controls['street'].pristine">
                {{'Please enter street' | translate}}
              </small>
            </div>
            <div>
              <label>{{'Avenue' | translate}}</label>
              <input formControlName="avenue" class="form-control text-input" id="avenue" name="address-avenue">
            </div>

            <div>
              <label>{{'House / Building' | translate}}</label>
              <input formControlName="addressline_1" class="form-control text-input" id="addressline_1"
                name="address-addressline_1">
            </div>
            <div>
              <label>{{'Floor' | translate}}</label>
              <input formControlName="floor" class="form-control text-input" id="floor" name="address-floor">
            </div>
            <div>
              <label>{{'Flat' | translate}}</label>
              <input formControlName="flat" class="form-control text-input" id="flat" name="address-flat"
                aria-describedby="flatHelp">
              <small id="flatHelp" class="form-text form-validator_error"
                [hidden]="shippingAddressForm.controls['flat'].valid || shippingAddressForm.controls['flat'].pristine">
                {{'Please enter your House/Flat' | translate}}
              </small>
            </div>

            <div>
              <label>{{'Landmark' | translate}}<span
                  *ngIf="(selectedCountry?.is_landmark_required == 1)">*</span></label>
              <input formControlName="landmark" class="form-control text-input" id="landmark"
                name="address-landmark" aria-describedby="landmarkHelp" 
                *ngIf="(selectedCountry?.is_landmark_required != 1)">
              <input formControlName="landmark" class="form-control text-input" id="landmark"
                name="address-landmark" aria-describedby="landmarkHelp" 
                *ngIf="(selectedCountry?.is_landmark_required == 1)" required>
              <small id="landmarkHelp" class="form-text form-validator_error"
                [hidden]="shippingAddressForm.controls['landmark'].valid || shippingAddressForm.controls['last_name'].pristine">
                {{'Please enter your landmark' | translate}}
              </small>
            </div>

            <div>
              <label>{{'ID Number' | translate}}<span *ngIf="(selectedCountry?.is_id_mandatory == 1)">*</span></label>
              <input class="form-control text-input" id="id_number"
                name="address-id_number" aria-describedby="idNumberHelp" formControlName="id_number"
                *ngIf="(selectedCountry?.is_id_mandatory != 1)">
              <input class="form-control text-input" id="id_number"
                name="address-id_number" aria-describedby="idNumberHelp" formControlName="id_number" required
                *ngIf="(selectedCountry?.is_id_mandatory == 1)">
            </div>

          </div>

          <div>
            <div class="edit_note">
              <label for="">{{'Notes (Landmark or delivery instructions)' | translate}}</label>
              <textarea formControlName="notes" id="notes" name="address-notes"></textarea>
            </div>
          </div>

          <div class="edit_data_footer text-center">
            <button type="submit" data-bs-dismiss="modal">CONTINUE</button>
          </div>
        </form>
      </div>
    </div>
  </div>

  <!-- <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      
      <div class="container">
        <div class="Main-add">
          <form #shippingAddressForm="ngForm" (ngSubmit)="saveAddress(shippingAddressForm)">
            <div class="row">
              <div>
                <div class="ADD-HEADING">
                  <h3>{{'ADD SHIPPING ADDRESS' | translate}}</h3>
                </div>
                <div class="modal-header close-modal" style="border: 0px;">
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="closeModalButton"
                    #closeModalButton></button>
                </div>
              </div>
              
              <div class="col-md-6">
                <div class="MAIN-FORM">
                  <div>
                    <label for="" class="form-label">{{'Full Name' | translate}}<span>*</span></label>
                    <input type="text" [(ngModel)]="addressf.first_name" class="form-control text-input"
                      id="first_name" name="address-first_name" aria-describedby="firstNameHelp"
                      #paramFirstName="ngModel" required>
                    <small id="firstNameHelp" class="form-text form-validator_error"
                      [hidden]="paramFirstName.valid || paramFirstName.pristine">
                      {{'Please enter your Name' | translate}}
                    </small>
                  </div>
                  <div class="mobile-view">
                    <label for="" class="form-label">{{'Mobile Number' | translate}}<span>*</span></label>
                    <div class="row">
                      <div class="col-4"> <input class="form-control text-input code" disabled
                          placeholder={{countryCode}}></div>
                      <div class="col-8"> <input [(ngModel)]="addressf.mobile_number" class="form-control text-input"
                          id="mobile_number" name="address-mobile_number" aria-describedby="mobileNumberHelp"
                          #paramMobileNumber="ngModel" pattern="[0-9]+" maxlength="10" minlength="5" required></div>
                    </div>

                    <small id="mobileNumberHelp" class="form-text form-validator_error"
                      [hidden]="paramMobileNumber.valid || paramMobileNumber.pristine">
                      {{'Please enter a valid mobile number' | translate}}
                    </small>
                  </div>
                  <div>
                    <label for="" class="form-label">{{'Country' | translate}}</label>
                    <ng-select [items]="countries" [addTag]="true" bindLabel="name" bindValue="id"
                      id="country_id" name="address-country_id" aria-describedby="countryHelp"
                      #paramCountry="ngModel" [clearable]=false [searchable]=false
                      [placeholder]="'Select One' | translate" (change)="getStates($event)"
                      [(ngModel)]="addressf.country_id" class="select-input add-ship" required></ng-select>
                    <small id="countryHelp" class="form-text form-validator_error"
                      [hidden]="paramCountry.valid || paramCountry.pristine">
                      {{'Please select your country' | translate}}
                    </small>
                  </div>

                  <div>
                    <label for="" class="form-label">{{'Governorate' | translate}}<span>*</span></label>
                    <ng-select [items]="governorates" [addTag]="true" bindLabel="name" bindValue="id"
                      id="governorate_id" name="address-governorate_id" aria-describedby="governorateHelp"
                      #paramGovernorate="ngModel" [clearable]=false [searchable]=false
                      [placeholder]="'Select One' | translate" (change)="getAreas($event)"
                      [(ngModel)]="addressf.governorate_id" class="select-input add-ship" required></ng-select>
                    <small id="governorateHelp" class="form-text form-validator_error"
                      [hidden]="paramGovernorate.valid || paramGovernorate.pristine">
                      {{'Please select your state/prov./region' | translate}}
                    </small>
                  </div>
                  <div>
                    <label for="" class="form-label">{{'City' | translate}}</label>
                    <ng-select [items]="areas" [addTag]="true" bindLabel="name" bindValue="id" id="area_id"
                      name="address-area_id" aria-describedby="areaHelp" #paramArea="ngModel" [clearable]=false
                      [searchable]=false [placeholder]="'Select One' | translate" (change)="getBlocks($event)"
                      [(ngModel)]="addressf.area_id" class="select-input add-ship"
                      [required]="(areas.length) > 0 ? true : false"></ng-select>
                    <small id="areaHelp" class="form-text form-validator_error"
                      [hidden]="paramArea.valid || paramArea.pristine">
                      {{'Please select your city/area' | translate}}
                    </small>
                  </div>
                  <div *ngIf="sectors.length > 0">
                    <label for="" class="form-label">{{'Block' | translate}}</label>
                    <ng-select [items]="sectors" [addTag]="true" bindLabel="name" bindValue="id" id="block_id"
                      name="address-block_id" #paramBlock="ngModel" [clearable]=false [searchable]=false
                      [placeholder]="'Select One' | translate" [(ngModel)]="addressf.block_id" class="select-input add-ship">
                    </ng-select>
                  </div>
                  <div>
                    <label for="" class="form-label">{{'Street Name/Number' | translate}}<span>*</span></label>
                    <input [(ngModel)]="addressf.street" class="form-control text-input" id="street"
                      name="address-street" aria-describedby="streetHelp" #paramStreet="ngModel" maxlength="100"
                      required>
                    <small id="streetHelp" class="form-text form-validator_error"
                      [hidden]="paramStreet.valid || paramStreet.pristine">
                      {{'Please enter street' | translate}}
                    </small>
                  </div>
                  <div>
                    <label for="" class="form-label">{{'Avenue' | translate}}</label>
                    <input [(ngModel)]="addressf.avenue" class="form-control text-input" id="avenue"
                      name="address-avenue">
                  </div>
                  <div>
                    <label for="" class="form-label">{{'House No/Building No' | translate}}</label>
                    <input [(ngModel)]="addressf.addressline_1" class="form-control text-input"
                      id="addressline_1" name="address-addressline_1">
                  </div>

                </div>
              </div>
              <div class="col-md-6">
                <div class="MAIN-FORM">
                  <div class=" desktop-view">
                    <label for="" class="form-label">{{'Mobile Number' | translate}}<span>*</span></label>
                    <div class="row">
                      <div class="col-4"> <input class="form-control text-input code" disabled
                          placeholder={{countryCode}}></div>
                      <div class="col-8"> <input [(ngModel)]="addressf.mobile_number" class="form-control text-input"
                          id="mobile_number" name="address-mobile_number" aria-describedby="mobileNumberHelp"
                          #paramMobileNumber="ngModel" pattern="[0-9]+" maxlength="10" minlength="5" required></div>
                    </div>

                    <small id="mobileNumberHelp" class="form-text form-validator_error"
                      [hidden]="paramMobileNumber.valid || paramMobileNumber.pristine">
                      {{'Please enter a valid mobile number' | translate}}
                    </small>
                  </div>

                  <div>
                    <label for="" class="form-label">{{'Floor' | translate}}</label>
                    <input [(ngModel)]="addressf.floor" class="form-control text-input" id="floor"
                      name="address-floor">
                  </div>
                  <div>
                    <label for="" class="form-label">{{'House/Flat' | translate}}</label>
                    <input [(ngModel)]="addressf.flat" class="form-control text-input" id="flat"
                      name="address-flat" aria-describedby="flatHelp" #paramFlat="ngModel">
                    <small id="flatHelp" class="form-text form-validator_error"
                      [hidden]="paramFlat.valid || paramFlat.pristine">
                      {{'Please enter your House/Flat' | translate}}
                    </small>
                  </div>
                  <div>
                    <label for="" class="form-label">{{'Landmark' | translate}}<span
                        *ngIf="(selectedCountry?.is_landmark_required == 1)">*</span></label>
                    <input [(ngModel)]="addressf.landmark" class="form-control text-input" id="landmark"
                      name="address-landmark" aria-describedby="flatHelp" #paramFlat="ngModel"
                      *ngIf="(selectedCountry?.is_landmark_required != 1)">
                    <input [(ngModel)]="addressf.landmark" class="form-control text-input" id="landmark"
                      name="address-landmark" aria-describedby="flatHelp" #paramFlat="ngModel"
                      *ngIf="(selectedCountry?.is_landmark_required == 1)" required>
                    <small id="flatHelp" class="form-text form-validator_error"
                      [hidden]="paramFlat.valid || paramFlat.pristine">
                      {{'Please enter your landmark' | translate}}
                    </small>
                  </div>

                  <div>
                    <label for="address-id_number" class="control-label">{{'ID Number' | translate}}<span
                        *ngIf="(selectedCountry?.is_id_mandatory == 1)">*</span></label>
                    <input [(ngModel)]="address.id_number" class="form-control text-input" id="id_number"
                      name="address-id_number" aria-describedby="idNumberHelp" #idNumberNumber="ngModel"
                      *ngIf="(selectedCountry?.is_id_mandatory != 1)">
                    <input [(ngModel)]="address.id_number" class="form-control text-input" id="id_number"
                      name="address-id_number" aria-describedby="idNumberHelp" #idNumberNumber="ngModel" required
                      *ngIf="(selectedCountry?.is_id_mandatory == 1)">

                  </div>

                  <div>
                    <label for="" class="form-label">{{'Notes (Landmark or delivery instructions)' | translate}}</label>
                    <textarea [(ngModel)]="addressf.notes" class="form-control text-input" id="notes"
                      name="address-notes"></textarea>
                  </div>
                </div>
                
              </div>
              <div class="col-md-12">
                <div class="MAIN-FORM">
                  <button type="button" type="submit" class="btn btn-black font-black btn-block mt-3 position-relative update-button"
                    [disabled]="!shippingAddressForm.form.valid">
                    <span *ngIf="addressf.address_id != null">{{'UPDATE' | translate}}</span>
                    <span *ngIf="!(addressf.address_id != null)">{{'SAVE & CONTINUE' | translate}}</span></button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div> -->
</div>