import { Component, Input , OnInit} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/config/user.service';
import { WishlistService } from 'src/app/config/wishlist.service';

@Component({
  selector: 'app-brand-info',
  templateUrl: './brand-info.component.html',
  styleUrls: ['./brand-info.component.scss']
})
export class BrandInfoComponent implements OnInit {

  constructor(
    private wishlistService : WishlistService,
    private userService : UserService,
    private toastr : ToastrService
    ){
    
  }

  @Input() brandInfo

  userId: any
  isMobile: any = false;
  skeltonTheme:any;
  @Input() skeletonLoader:any;

  ngOnInit(): void {
    this.isMobile = window.innerWidth < 600;
    this.getUserData()
    this.skeltonTheme = this.setThemeLogic();
    // setTimeout(() => {
    //   this.skeletonLoader=false;
    // }, 1000);
  }
  setThemeLogic() {
    let theme = {
      'border-radius': '5px',
      'animation-duration': '2s',
      'background-color': '#F1F1F1',
      'height':''
    };
    theme.height = this.isMobile ? '100px' : '300px';
    return theme;
  }
  toggleBrandAsFavorite(brandId : number) {
    
    const getParams = {

    }

    const postParams = {
      user_id : this.userId,
      brand_id : brandId
    }
    if (brandId == null) {
      this.toastr.warning('Invalid Brand ID')
      return
    }
    this.wishlistService.toggleBrandAsFavorite(getParams, postParams).subscribe(response => {
      console.log(response, 'response');
        if (response.status == 200) {
          this.toastr.success(response.message)
        } else {
          this.toastr.error(response.message)
        }
    })
  }

  getUserData() {
    this.userService.getUserSession().then(user => {
      if (user !== false) {
        this.userId = user.id;
      }
    });
  }
  

}
