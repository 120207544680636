import { EventEmitter, Injectable, Output, Directive } from '@angular/core';
import { ConfigSettings } from "./config.settings";
import { ConfigService } from "./config.service";
import { Address } from "../models/address";
import { Cart } from "../models/cart";
import { Product } from "../models/product";
import { DeliveryOptions } from "../models/delivery-options";
import { PaymentOptions } from "../models/payment-options";
import { Order } from "../models/order";
import { Router } from "@angular/router";
import { map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Directive()
@Injectable({
    providedIn: 'root'
})
export class CheckoutService {
    step: Number = 1;
    address: Address = null;
    cart: Cart = null;
    delivery: DeliveryOptions = null;
    payment: PaymentOptions = null;
    order: Order = null;
    shippingCost: number = 0;
    vatTotal: number = 0;
    @Output() change: EventEmitter<Number> = new EventEmitter<Number>();
    @Output() shipping: EventEmitter<Address> = new EventEmitter<Address>();
    @Output() bag: EventEmitter<Cart> = new EventEmitter<Cart>();
    @Output() deliver: EventEmitter<DeliveryOptions> = new EventEmitter<DeliveryOptions>();
    @Output() pay: EventEmitter<PaymentOptions> = new EventEmitter<PaymentOptions>();
    @Output() confirm: EventEmitter<Order> = new EventEmitter<Order>();
    @Output() ship: EventEmitter<number> = new EventEmitter<number>();
    @Output() vat: EventEmitter<number> = new EventEmitter<number>();

    constructor(
        private configSettings: ConfigSettings,
        private configService: ConfigService,
        private toastr: ToastrService,
        private router: Router
    ) { }

    setStep(step: Number) {
        this.step = step;
        this.change.emit(this.step)
    }

    setOrderDetails(order: Order) {
        this.order = order;
        this.confirm.emit(this.order)
    }

    setAddress(address: Address) {
        this.address = address;
        this.shipping.emit(this.address)
    }

    setCart(cart: Cart) {
        this.cart = cart;
        this.bag.emit(this.cart)
    }

    setDelivery(delivery: DeliveryOptions) {
        this.delivery = delivery;
        this.deliver.emit(this.delivery)
    }

    setPayment(payment: PaymentOptions) {
        this.payment = payment;
        this.pay.emit(this.payment)
    }

    setShipping(shippingCost: number) {
        this.shippingCost = shippingCost;
        this.ship.emit(this.shippingCost)
    }

    setVat(vat: number) {
        this.vatTotal = vat;
        this.vat.emit(this.vatTotal)
    }

    getCountries(): Promise<any> {
        let promise = new Promise<any>((resolve, reject) => {
            const params = {
                lang: this.configSettings.getLang(),
            };
            this.configService.readRequest('country', params)
                .subscribe(response => {
                    resolve(response);
                }, error => { reject(error); });
        });

        return promise;
    }

    getStates(country): Promise<any> {
        let promise = new Promise<any>((resolve, reject) => {
            const params = {
                country_id: country,
                lang: this.configSettings.getLang(),
            };
            this.configService.readRequest('state', params)
                .subscribe(response => {
                    resolve(response);
                }, error => { reject(error); });
        });

        return promise;
    }

    getAreas(state): Promise<any> {
        let promise = new Promise<any>((resolve, reject) => {
            const params = {
                state_id: state,
                lang: this.configSettings.getLang(),
            };
            this.configService.readRequest('area', params)
                .subscribe(response => {
                    resolve(response);
                }, error => { reject(error); });
        });

        return promise;
    }

    getBlocks(area): Promise<any> {
        let promise = new Promise<any>((resolve, reject) => {
            const params = {
                area_id: area,
                lang: this.configSettings.getLang(),
            };
            this.configService.readRequest('sector', params)
                .subscribe(response => {
                    resolve(response);
                });
        });

        return promise;
    }

    addAddress(user: any, address: Address, store): Promise<any> {
        return new Promise(resolve => {
            const getParams = {
                lang: this.configSettings.getLang(),
                store: store
            };

            const postParams = {
                user_id: user,
                first_name: address.first_name,
                last_name: address.last_name,
                country_id: address.country_id,
                state_id: address.governorate_id,
                area_id: address.area_id,
                block_id: address.block_id,
                street: address.street,
                addressline_1: address.addressline_1,
                mobile_number: address.mobile_number,
                alt_phone_number: address.alt_phone_number,
                location_type: address.location_type,
                notes: address.notes,
                avenue: address.avenue,
                flat: address.flat,
                floor: address.floor,
                landmark: address.landmark,
                id_number: address.id_number
            };
            this.configService.postRequest('add-address', getParams, postParams)
                .subscribe(response => {
                    if (response.status != 200) {
                        this.toastr.error(response.message);
                    }
                    resolve(response.data);
                });
        });
    }

    updateAddress(user: any, address: Address, store): Promise<any> {
        return new Promise(resolve => {
            const getParams = {
                lang: this.configSettings.getLang(),
                store: store
            };

            const postParams = {
                user_id: user,
                shipping_address_id: address.address_id,
                first_name: address.first_name,
                last_name: address.last_name,
                country_id: address.country_id,
                state_id: address.governorate_id,
                area_id: address.area_id,
                block_id: address.block_id,
                street: address.street,
                addressline_1: address.addressline_1,
                mobile_number: address.mobile_number,
                alt_phone_number: address.alt_phone_number,
                location_type: address.location_type,
                notes: address.notes,
                avenue: address.avenue,
                flat: address.flat,
                floor: address.floor,
                id_number: address.id_number,
                landmark: address.landmark,
            };
            this.configService.postRequest('update-address', getParams, postParams)
                .subscribe(response => {
                    resolve(response);
                });
        });
    }

    checkItemStock(user, items, store, shipping_address_id): Promise<any> {
        return new Promise<any>(resolve => {
            let products: Product[] = [];
            let quantity: number[] = [];
            if (items && items.length > 0) {
                items.map(item => {
                    products.push(item.id)
                    quantity.push(item.quantity);
                });
                const getParams = {
                    lang: this.configSettings.getLang(),
                    store: store
                };
                const postParams = {
                    user_id: user,
                    order_id: this.configSettings.getCartId(),
                    products: products.join(','),
                    quantity: quantity.join(','),
                    shipping_address_id: shipping_address_id
                };
                this.configService.postRequest('check-item-stock', getParams, postParams).subscribe(response => {
                    resolve(response.data);
                });
            } else {
                resolve(null);
            }
        });
    }

    checkout(user, address, delivery, payment, store, is_gift, receiver_name, receiver_phone, message, hide_invoice,anonymousdelivery, on_package_sticker): Promise<any> {
        return new Promise<any>(resolve => {
            const confirmURL = window.location.origin + this.router.createUrlTree(['/order-confirmation']);

            const getParams = {
                lang: this.configSettings.getLang(),
                store: store
            };

            const device = this.configSettings.getBrowserVersion();
            const postParams = {
                user_id: user,
                order_id: this.configSettings.getCartId(),
                shipping_address_id: address,
                pay_mode: payment,
                delivery_option: delivery,
                redirect_url: confirmURL,
                device_type: 'W',
                device_model: device,
                app_version: device,
                os_version: window.navigator.platform,
                is_gift: is_gift,
                receiver_name: receiver_name,
                receiver_phone: receiver_phone,
                message: message,
                on_package_sticker: on_package_sticker,
                hide_invoice: hide_invoice,
                is_anonymous_delivery:anonymousdelivery
            };
            this.configService.postRequest('checkout', getParams, postParams)
                .subscribe(response => {
                    if (response.status == 200) {
                        resolve(response.data);
                    } else {
                        alert(response.message);
                        resolve(response.data);
                    }
                });
        });
    }

    redeemCoupon(getParams: any, postParams: any) {
        return this.configService.postRequest('redeem-coupon', getParams, postParams)
            .pipe(map(response => {
                return response;
            }));
    }
    redeemWallet(getParams: any, postParams: any) {
        return this.configService.postRequest('apply-wallet', getParams, postParams)
            .pipe(map(response => {
                return response;
            }));
    }
    removeWallet(getParams: any, postParams: any) {
        return this.configService.postRequest('remove-wallet', getParams, postParams)
            .pipe(map(response => {
                return response;
            }));
    }
}
