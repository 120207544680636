<ng-container *ngIf="dataAvailable">
  <section class="" id="brand-list" @listStagger>
    <div class="container">
      <div class="col-md-10 m-auto px-0 px-sm-3">
        <div class="pt-3 pb-1 text-center {{(lang == 'en') ?  'text-md-left': 'text-md-right'}}">
          <h4 class="d-inline-block font-black font-condensed title-bg-black" *ngIf="shops.length">{{'SHOPS' | translate}}</h4>
        </div>
        <div class="row">
          <div class="col-12 col-md-4 d-none d-md-block">
            <img src="assets/images/brands.jpeg" class="img-fluid w-100" />
          </div>
          <div class="col-12 col-md-8">
            <div class="white-background mx-0 mx-sm-3 mb-3 letters">
              <div class="container px-3">
                <div class="row justify-content-center alphabet-row" (clickOutside)="brightAll($event)">
                  <span class="font-bold d-none d-md-block"> {{'GO TO :' | translate}}</span>
                  <div *ngFor="let alphabet of groups" class="col px-2">
                    <div class="letter text-center font-bold font-small" (click)="scrollTo($event, alphabet.toLowerCase())">{{alphabet}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="container px-0 px-sm-3" *ngIf="shops.length">
              <section class="row content-section">
                <div class="col-12">
                  <div class="row">
                    <div class="col-12 col-md-4 ">
                      <ul *ngFor="let alphabet of groups | slice:0:divider;" id="{{alphabet.toLowerCase()}}" class="brand-group mb-2">
                        <li class="">
                          <h2 class="font-xxx-large font-bold mb-0">{{alphabet}}</h2>
                        </li>
                        <ng-container *ngFor="let shop of shops">
                          <li *ngIf="shop.name.charAt(0).toLowerCase() === alphabet.toLowerCase()" class="font-bold font-small">
                            <a [routerLink]="['/shop/', configSettings.cleanUrl(shop.name), shop.id]">{{shop.name}}</a>
                          </li>
                        </ng-container>
                      </ul>
                    </div>
                    <div class="col-12 col-md-4">
                      <ul *ngFor="let alphabet of groups | slice:divider:divider*2;" id="{{alphabet.toLowerCase()}}" class="brand-group mb-2">
                        <li class="">
                          <h2 class="font-xxx-large font-bold mb-0">{{alphabet}}</h2>
                        </li>
                        <ng-container *ngFor="let shop of shops">
                          <li *ngIf="shop.name.charAt(0).toLowerCase() === alphabet.toLowerCase()" class="font-bold font-small">
                            <a [routerLink]="['/shop/', configSettings.cleanUrl(shop.name), shop.id]">{{shop.name}}</a>
                          </li>
                        </ng-container>
                      </ul>
                    </div>
                    <div class="col-12 col-md-4">
                      <ul *ngFor="let alphabet of groups | slice:divider*2:divider*3;" id="{{alphabet.toLowerCase()}}" class="brand-group mb-2">
                        <li class="">
                          <h2 class="font-xxx-large font-bold mb-0">{{alphabet}}</h2>
                        </li>
                        <ng-container *ngFor="let shop of shops">
                          <li *ngIf="shop.name.charAt(0).toLowerCase() === alphabet.toLowerCase()" class="font-bold font-small">
                            <a [routerLink]="['/shop/', configSettings.cleanUrl(shop.name), shop.id]">{{shop.name}}</a>
                          </li>
                        </ng-container>
                      </ul>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</ng-container>
