import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Order} from "../models/order";
import {UserService} from "../config/user.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Store} from "../models/store";
import {ConfigSettings} from "../config/config.settings";
import {ConfigService} from "../config/config.service";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import {DialogComponent} from "../dialog/dialog.component";
import { listStagger } from '../animations';
import { FooterService } from '../config/footer.service';
import {Item} from '../models/item';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.scss'],
  animations: [ listStagger ]
})
export class OrderDetailComponent implements OnInit {
  order: Order = null;
  id: number;
  lang: string;
  userId: string;
  store: Store;
  paymentMethod: string;
  currency: string;
  dataAvailable = false;
  skeltonTheme:any;
  skeletonLoader:boolean=true;
  isMobile: any = false;
  @Output() voted = new EventEmitter<number>();
  orderReturn: any;
  returnItems = [];
  items: Item[] = [];

  constructor(
      private configSettings: ConfigSettings,
      private configService: ConfigService,
      private userService: UserService,
      private route: ActivatedRoute,
      private router: Router,
      private dialog: MatDialog,
      private snackBarRef: MatSnackBar,
      protected footerService: FooterService,
      private toaster:ToastrService
  ) { }

  ngOnInit() {
    this.isMobile = window.innerWidth < 600;
    this.lang = this.configSettings.getLang();
    /* getting store from config*/
    this.configSettings.getStoreObject().then((result) => {
        this.store = <Store> result;
        this.currency = result['currency_' + this.lang];

        // if(this.userService.order) {
        //     this.order = this.userService.order;
        //     this.userId = this.userService.userId;          
        //     if(this.order.payment_mode === 'C') {
        //         this.paymentMethod = this.lang == 'en' ? 'Cash on delivery' : 'الدفع عند الاستلام';
        //     } else if(this.order.payment_mode === 'K') {
        //         this.paymentMethod = this.lang == 'en' ? 'Knet' : 'كي نت';
        //     } else if(this.order.payment_mode === 'CC') {
        //         this.paymentMethod = this.lang == 'en' ? 'Visa/MasterCard' : 'فيزا';
        //     } else if(this.order.payment_mode === 'M') {
        //         this.paymentMethod = this.lang == 'en' ? 'My Fatoorah': 'فتورة بلدي';
        //       } else if(this.order.payment_mode === 'S') {
        //         this.paymentMethod = this.lang == 'en' ? 'Sadad': 'سداد';
        //       } else if(this.order.payment_mode === 'NP') {
        //         this.paymentMethod = this.lang == 'en' ? 'Qatar Debit Cards':'بطاقات الخصم القطرية';
        //       } else if(this.order.payment_mode === 'B') {
        //         this.paymentMethod = this.lang == 'en' ? 'Benefit': 'المنفعة';
        //       } else if(this.order.payment_mode === 'UAECC') {
        //         this.paymentMethod = this.lang == 'en' ? 'Uae Debit Cards':'بطاقات الخصم الإماراتي';
        //       } else if(this.order.payment_mode === 'W') {
        //         this.paymentMethod = this.lang == 'en' ? '':'';
        //       } else if(this.order.payment_mode === 'TP1') {
        //         if(this.store.id == 1){
        //           this.paymentMethod = this.lang == 'en' ? 'Buy Now & Pay within 14 Days: No Fees ':'اشتري الان و الدفع خلال ١٤ يوم من غير رسوم إضافية';
        //         }else{
        //           this.paymentMethod = this.lang == 'en' ? 'Buy Now & Pay within 14 Days: No Fees':'اشتري الان و الدفع خلال ١٤ يوم من غير رسوم إضافية';
        //         }                
        //       } else if(this.order.payment_mode === 'TP2') {
        //         if(this.store.id == 1){
        //           this.paymentMethod = this.lang == 'en' ? 'Pay in 2 Installments: No Fees ':'الدفع على قسطين من غير رسوم إضافية';
        //         }else{
        //           this.paymentMethod = this.lang == 'en' ? 'Pay in 2 Installments: No Fees':'الدفع على قسطين من غير رسوم إضافية';
        //         }
        //       } else if(this.order.payment_mode === 'TP3') {
        //         if(this.store.id == 1){
        //           this.paymentMethod = this.lang == 'en' ? 'Pay in 3 Installments: No Fees ':'الدفع على ٣ أقساط من غير رسوم إضافية';
        //         }else{
        //           this.paymentMethod = this.lang == 'en' ? 'Pay in 3 Installments: No Fees':'الدفع على ٣ أقساط من غير رسوم إضافية';
        //         }
        //       } else if(this.order.payment_mode === 'TP4') {
        //         if(this.store.id == 1){
        //           this.paymentMethod = this.lang == 'en' ? 'Pay in 4 Installments: No Fees ': 'الدفع على ٤ أقساط من غير رسوم إضافية';
        //         }else{
        //           this.paymentMethod = this.lang == 'en' ? 'Pay in 4 Installments: No Fees':'الدفع على ٤ أقساط من غير رسوم إضافية';
        //         }
        //       }

        //     this.dataAvailable = true;
        //     setTimeout(() => {
        //       this.footerService.toggleFooter(true)
        //       this.skeletonLoader=false;  
        //     }, 1000);
        // } else {
            this.configSettings.toggleLoading(true);
            this.id = +this.route.snapshot.paramMap.get('id');
            this.userService.getUserSession().then(user => {
                if(user !== false) {
                    this.userId = user.id;
                }
                this.getOrder();
            }, error => { this.configSettings.toggleLoading(false); });
        // }
    });
    this.skeltonTheme = this.setThemeLogic();

  }
  setThemeLogic() {
    let mobTheme = {
        'border-radius': '5px',
        'height': '100px',
        'animation-duration': '2s'
    };
    let deskTheme = {
        'border-radius': '5px',
        'height': '500px',
        'animation-duration': '2s'
    };
    if (this.isMobile) {
        return mobTheme;
    } else {
        return deskTheme;
    }
}
 

  showCancelModal() {
      let dialogRef = this.dialog.open(DialogComponent, {
          data: {cancelOrder: true, user: this.userId, id: this.order.id },
      });
  }

  requestReturn() {
      window.location.href = 'request-return/' + this.order.id;

      /*if(confirm("Are you sure you want to return all items of this order?")) {
          this.configSettings.toggleLoading(true);
          const params = {
              id: this.order.id,
              user: this.userId
          };

          this.configService.readRequest('request-return', params)
              .subscribe(response => {
                  this.configSettings.toggleLoading(false);
                  if(response.body.status !== 200) {
                      let snackBarRef = this.snackBarRef.open(response.body.message, null, {
                          duration: 5000
                      });
                  } else {
                      this.router.navigate(['my-orders']);
                  }
              });
      }*/
  }
   getOrder() {
      const params = {
          id: this.id,
          user_id: this.userId,
          lang: this.lang,
          store: this.store.iso_code
      };

      this.configService.readRequest('order-details', params)
          .subscribe(response => {
              this.order = <Order>response.body.data;   
              this.currency=response.body.data.address_currency_code;                                 
              if(this.order.payment_mode === 'C') {
                  this.paymentMethod = this.lang == 'en' ? 'Cash on delivery' : 'الدفع عند الاستلام';
              } else if(this.order.payment_mode === 'K') {
                  this.paymentMethod = this.lang == 'en' ? 'Knet' : 'كي نت';
              } else if(this.order.payment_mode === 'CC') {
                  this.paymentMethod = this.lang == 'en' ? 'Visa/MasterCard' : 'فيزا';
              } else if(this.order.payment_mode === 'M') {
                this.paymentMethod = this.lang == 'en' ? 'My Fatoorah': 'فتورة بلدي';
              } else if(this.order.payment_mode === 'S') {
                this.paymentMethod = this.lang == 'en' ? 'Sadad': 'سداد';
              } else if(this.order.payment_mode === 'NP') {
                this.paymentMethod = this.lang == 'en' ? 'Qatar Debit Cards':'بطاقات الخصم القطرية';
              } else if(this.order.payment_mode === 'B') {
                this.paymentMethod = this.lang == 'en' ? 'Benefit': 'المنفعة';
              } else if(this.order.payment_mode === 'UAECC') {
                this.paymentMethod = this.lang == 'en' ? 'Uae Debit Cards':'بطاقات الخصم الإماراتي';
              } else if(this.order.payment_mode === 'W') {
                this.paymentMethod = this.lang == 'en' ? 'Wallet':'محفظة';
              } else if(this.order.payment_mode === 'TP1') {
                if(this.store.id == 1){
                  this.paymentMethod = this.lang == 'en' ? 'Buy Now & Pay within 14 Days: No Fees ':'اشتري الان و الدفع خلال ١٤ يوم من غير رسوم إضافية';
                }else{
                  this.paymentMethod = this.lang == 'en' ? 'Buy Now & Pay within 14 Days: No Fees':'اشتري الان و الدفع خلال ١٤ يوم من غير رسوم إضافية';
                }                
              } else if(this.order.payment_mode === 'TP2') {
                if(this.store.id == 1){
                  this.paymentMethod = this.lang == 'en' ? 'Pay in 2 Installments: No Fees ':'الدفع على قسطين من غير رسوم إضافية';
                }else{
                  this.paymentMethod = this.lang == 'en' ? 'Pay in 2 Installments: No Fees':'الدفع على قسطين من غير رسوم إضافية';
                }
              } else if(this.order.payment_mode === 'TP3') {
                if(this.store.id == 1){
                  this.paymentMethod = this.lang == 'en' ? 'Pay in 3 Installments: No Fees ':'الدفع على ٣ أقساط من غير رسوم إضافية';
                }else{
                  this.paymentMethod = this.lang == 'en' ? 'Pay in 3 Installments: No Fees':'الدفع على ٣ أقساط من غير رسوم إضافية';
                }
              } else if(this.order.payment_mode === 'TP4') {
                if(this.store.id == 1){
                  this.paymentMethod = this.lang == 'en' ? 'Pay in 4 Installments: No Fees ': 'الدفع على ٤ أقساط من غير رسوم إضافية';
                }else{
                  this.paymentMethod = this.lang == 'en' ? 'Pay in 4 Installments: No Fees':'الدفع على ٤ أقساط من غير رسوم إضافية';
                }
              }
              this.configSettings.toggleLoading(false);
              this.dataAvailable = true;
              this.skeletonLoader=false;
              setTimeout(() => {this.footerService.toggleFooter(true)}, 300);
          });
  }


  ReadMore:boolean = true
  visible:boolean = false

  onreturn(){
    this.ReadMore = !this.ReadMore; //not equal to condition
    this.visible = !this.visible;
    this.getAvailableItems();
  }

  getAvailableItems() {
    this.configSettings.toggleLoading(true);
    const params = {
      order_id: this.order.id,
      user_id: this.userId,
      lang: this.lang,
      store: this.store.iso_code
    };

    this.configService.readRequest('available-return-items', params)
      .subscribe(response => {
        if (response.status === 200) {
          this.items = <Item[]>response.body.data.items;
        }
        this.configSettings.toggleLoading(false);
        this.dataAvailable = true;
        setTimeout(() => { this.footerService.toggleFooter(true); }, 300);
      }, error => { this.configSettings.toggleLoading(false); });
  }



  selectItemForReturn(item: any) {
    this.orderReturn = item;
    this.returnItems = [];
    this.orderReturn.returnReason=''
    // this.items.map(obj => {
        // if (obj.requestReturn === true) {
            this.returnItems.push({
                id: this.orderReturn.id,
                quantity: this.orderReturn.quantity,
                reason: this.orderReturn.returnReason
            });
        // }
    // });
  }
  updateCart(val) {
    if (val == 1 && this.orderReturn.quantity > 1) {
      this.orderReturn.quantity += val;
    } else if (val != 1 && this.orderReturn.quantity > 1) {
      this.orderReturn.quantity -= 1;
    }
  }
  selectReason(e: any) {
    const paragraphElement = document.getElementById(e);
    const paragraphContent = paragraphElement.textContent;
    this.orderReturn.returnReason=paragraphContent
    this.returnItems = [];
    this.returnItems.push({
      id: this.orderReturn.id,
      quantity: this.orderReturn.quantity,
      reason: this.orderReturn.returnReason
  });  
  }
  textReason:any;
  returnCount=0;
  submitReturn(){
    if(this.orderReturn.returnReason==undefined && this.textReason!=undefined){
      this.orderReturn.returnReason=this.textReason;   
      this.onSubmit();
    }else if(this.orderReturn.returnReason==undefined && this.textReason==undefined){
      let msg=this.lang=='en'?'please Select a reason for return':'الرجاء تحديد سبب للعودة';
      this.toaster.error(msg);
      return;
    }else{
      this.onSubmit();
    }
  }
  orderReturned:boolean=false
  onSubmit() { 
    try {
      this.returnCount++;
      if (this.returnItems.length > 0 && this.returnCount==1) {
        this.configSettings.toggleLoading(true);
        const getParams = {
          lang: this.lang,
          store: this.store.iso_code
        };
        const postParams = {
          order_id: this.order.id,
          user_id: this.userId,
          use_order_delivery_address: 1,
          items: this.returnItems
        };
        this.configService.postRequest('add-return-request', getParams, postParams).subscribe(response => {
            if (response.status === 200) {
              this.orderReturned=true;
              this.toaster.success(response.message)
              let btn=document.getElementById('closeCanvas');
              btn.click();
              this.returnCount=0;
            } else {
              this.orderReturned=false;
            }
          }, error => {
          });
      }
    } catch (e) {
      console.log(e);
      this.configSettings.toggleLoading(false);
    }
  }


}
