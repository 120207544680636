import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ShareHomeInfoService {
  private homeInfoSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor() { }

  // Function to set homeInfo
  setHomeInfo(homeInfo: any) {
    this.homeInfoSubject.next(homeInfo);
  }

  // Function to get homeInfo as an observable
  getHomeInfo(): Observable<any> {
    return this.homeInfoSubject.asObservable();
  }
}
