import { Injectable } from '@angular/core';
import * as mixpanel from 'mixpanel-browser';
// import {StoreService} from './store.service';

declare const fbq: any;
declare const snaptr: any;
declare const gtag: any;
declare const ttq: any;
declare const Insider:any;

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  storeName = 'The Wish List';
  iso_2:any;
  storeCode = {
    'id': 1,
    'name_en': 'Kuwait',
    'name_ar': 'الكويت',
    'currency_en': 'KD',
    'currency_ar': 'د.ك',
    'flag': 'https://d2aevxzf0n8ovr.cloudfront.net/images/15690401795d85a73342fc37.49011157.png',
    'phonecode': '965',
    'iso_code': 'KW',
    'iso': 'KW'
  };

  liveWebsite: boolean = false
  constructor(
    // private storeService: StoreService
  ) {
    let hostName = window.location.hostname;
    if (hostName == 'thewishlist.com' || hostName =='www.thewishlist.com') {
      console.log(hostName,'hostName');
      console.log(this.liveWebsite,'liveWebsite');
        
      this.liveWebsite = true
      this.init();
    }  
   }

  init(): void {
    mixpanel.init('3cb6e41d881d437528faa7f2c9c794fd');
  }

  getCurrency(iso: string): string {
    if(localStorage.getItem('selectedStore') == '' || localStorage.getItem('selectedStore') == null || localStorage.getItem('selectedStore') == undefined)
    {
      this.iso_2 = this.storeCode.iso;
    }else{
      this.iso_2 = JSON.parse(localStorage.getItem('selectedStore')).iso_code;
    }
    let currencyCode = 'KWD';
    switch (this.iso_2) {
      case 'KW':
        currencyCode = 'KWD';
        break;
      case 'QA':
        currencyCode = 'QAR';
        break;
      case 'BH':
        currencyCode = 'BHD';
        break;
      case 'SA':
        currencyCode = 'SAR';
        break;
      case 'OM':
        currencyCode = 'OMR';
        break;
      case 'AE':
        currencyCode = 'AED';
        break;
      case 'US':
        currencyCode = 'USD';
        break;
      default:
        currencyCode = 'KWD';
        break;
    }
    return currencyCode;
  }

  addToCart(
    productID: string,
    productName: string,
    productCategory: string,
    brandName: string,
    finalPrice?: string,
    regularPrice?: string,
    quantity?: number,
    configOptions?:any
  ): void {    
    if ( this.liveWebsite) {

    const store = this.storeCode;
    fbq('track', 'AddToCart', {
      content_ids: productID,
      content_name: productName,
      content_type: 'product',
      currency: this.getCurrency(store.iso),
      value: +finalPrice,
    });
    snaptr('track', 'ADD_CART', {
      item_ids: productID,
      item_category:productCategory,
      //product_name: productName,
      currency: this.getCurrency(store.iso),
      price: +finalPrice,
    });
    ttq.track('AddToCart', {
      content_id: productID,
      content_category:productCategory,
      currency: this.getCurrency(store.iso),
      price: +finalPrice,
    });
    // gtag('event', 'add_to_cart', {
    //   currency: this.getCurrency(store.iso),
    //   items: [{
    //     item_id: productID,
    //     item_name: productName,
    //     // coupon: 'SUMMER_FUN',
    //     // discount: 2.22,
    //     affiliation: this.storeName,
    //     item_brand: brandName,
    //     // item_category: 'pants',
    //     // item_variant: 'black',
    //     price: +finalPrice,
    //     currency: this.getCurrency(store.iso),
    //     quantity: 1
    //   }],
    //   value: +finalPrice
    // });
    mixpanel.track('AddToCart', {
      EntryPoint: 'Product Detail',
      ItemName: productName,
      ItemCategory: productCategory,
      ItemCost: +finalPrice,
      SuggestedItem: false,
      Discount: ((Number(regularPrice)-Number(finalPrice))/(Number(regularPrice)))*100,
      SaleItem: (((Number(regularPrice)-Number(finalPrice))/(Number(regularPrice))) == 0)?false:true,
      Brand: brandName,
      ItemID: productID,
      ItemSize:configOptions[1].attributes[0].value,
      ItemQuantity:quantity
    });
          
  }
  }

  addToWishlist(
    entryPoint: string,
    productID: string,
    productName: string,
    productCategory: string,
    brandName: string,
    finalPrice: string,
    regularPrice: string
  ): void {
    if ( this.liveWebsite) {

    const store = this.storeCode;
    fbq('track', 'AddToWishlist', {
      content_name: productName,
      content_category: productCategory,
      content_ids: productID,
      //product_name: productName,
      content_type: 'product',
      currency: this.getCurrency(store.iso),
      value: +finalPrice,
    });
    gtag('event', 'add_to_wishlist', {
      currency: this.getCurrency(store.iso),
      items: [{
        item_id: productID,
        item_name: productName,
        // coupon: 'SUMMER_FUN',
        // discount: 2.22,
        affiliation: this.storeName,
        item_brand: brandName,
        // item_category: 'pants',
        // item_variant: 'black',
        price: +finalPrice,
        currency: this.getCurrency(store.iso),
        quantity: 1
      }],
      value: +finalPrice
    });
    mixpanel.track('AddToWishlist', {
      EntryPoint: entryPoint,
      ItemName: productName,
      ItemCategory: productCategory,
      ItemCost: +finalPrice,
      ItemRating:'',
      SuggestedItem: false,
      SaleItem: (((Number(regularPrice)-Number(finalPrice))/(Number(regularPrice))) == 0)?false:true,
      Brand: brandName,
      ItemID: productID,
    });
          
  }
  }

  completeRegistration(
    userID?: number,
    name?: string,
    email?: string,
    userType?: string
  ): void {
    if ( this.liveWebsite) {

    fbq('track', 'CompleteRegistration', {
      status: true,
      fb_registration_method: userType,
      id: userID,
      name,
      email
    });
    snaptr('track', 'SIGN_UP', {
      id: userID,
      name,
      email
    });
    gtag('event', 'sign_up', {
      sign_up_method: userType,
      id: userID,
      name,
      email
    });
    mixpanel.track('SignUp', {
      name: name,
      email: email,
      phone: "",
      RegistrationDate: new Date().getDate()+'-'+new Date().getMonth()+'-'+new Date().getFullYear(),
      RegistrationMethod: userType,
      EnabledPush: "",
      EnabledLocation: "",
      Referred: "",
      CommunicationPreferences: ""
    });
          
  }
  }

  viewContent(
    productID: string,
    productName: string,
    categoryName: string,
    finalPrice: string,
    regularPrice: string,
    brandName: string
  ): void {
    if ( this.liveWebsite) {

    const store = this.storeCode;
    fbq('track', 'ViewContent', {
      content_ids: productID,
      // content_category: categoryName,
      content_name: productName,
      content_type: 'product',
      currency: this.getCurrency(store.iso),
      value: +finalPrice,
      // product_name: productName,
    });
    snaptr('track', 'VIEW_CONTENT', {
      price: +finalPrice,
      currency: this.getCurrency(store.iso),
      item_ids: productID,
      //product_name: productName,
      item_category:'NA',
    });
    ttq.track('ViewContent', {
      price: +finalPrice,
      currency: this.getCurrency(store.iso),
      content_id: productID,
      content_category:'NA',
    });
    // gtag('event', 'view_item', {
    //   currency: this.getCurrency(store.iso),
    //   items: [{
    //     item_id: productID,
    //     item_name: productName,
    //     // coupon: 'SUMMER_FUN',
    //     // discount: 2.22,
    //     affiliation: this.storeName,
    //     item_brand: productName,
    //     // item_category: 'pants',
    //     // item_variant: 'black',
    //     price: +finalPrice,
    //     currency: this.getCurrency(store.iso),
    //     quantity: 1
    //   }],
    //   value: +finalPrice
    // });
    // track an event with optional properties
    mixpanel.track('Visit', {
      Platform: 'Web',
      PageName: 'Product Detail',
      PageVariant: productID
    });
    mixpanel.track('ViewItem', {
      EntryPoint: 'Product Detail',
      ItemName: productName,
      ItemCategory: categoryName,
      ItemCost: +finalPrice,
      SuggestedItem: false,
      Discount: ((Number(regularPrice)-Number(finalPrice))/(Number(regularPrice)))*100,
      SaleItem: (((Number(regularPrice)-Number(finalPrice))/(Number(regularPrice))) == 0)?false:true,
      Brand: brandName,
      ItemID: productID,
      
    });

    Insider.track('view_item', [{
      "product_price": +finalPrice,
      "currency": this.getCurrency(store.iso),
      "product_name":productName,
      "session_id":"",
      "product_id":productID,
      "product_category":categoryName,
      "product_subcategory":categoryName
    }]);
          
  }
  }

  search(
    productID: any,
    searchString: string,
    resultCount: number
  ): void {
    fbq('track', 'Search', {
      content_ids: productID,
      search_string: searchString,
      content_type: 'product_group'
    });
    // gtag('event', 'search', {
    //   item_id: productID,
    //   search_term: searchString,
    // });
    mixpanel.track('Search', {
      SearchTerm: searchString,
      SearchCharacterLength:searchString.length,
      NumberOfResultReturned:resultCount
    });

  }

  login(
    loginMethod: string
  ): void {
    mixpanel.track('Login', {
      name: loginMethod
    });
  }

  pageView(
    pageV: string
  ): void {
    mixpanel.track('Visit', {
      Platform: 'Web',
      PageName: pageV,
    });
  }

  initiateCheckout(
    productID: any,
    productNames:any,
    cartItems: any,
    quantity: number,
    grandTotal: string,
  ): void {
    if ( this.liveWebsite) {

    const store = this.storeCode;
    fbq('track', 'InitiateCheckout', {
      content_type: 'product',
      //content_category: 'Checkout',
      content_ids: productID,
      // contents: '',
      num_items: quantity,
      value: +grandTotal,
      currency: this.getCurrency(store.iso),
    });
    snaptr('track', 'START_CHECKOUT', {
      currency: this.getCurrency(store.iso),
      price: +grandTotal,
      item_ids: productID,
      item_category:'NA',
    });
    ttq.track('InitiateCheckout', {
      currency: this.getCurrency(store.iso),
      price: +grandTotal,
      content_id: String(productID),
      content_category:'NA',
    });
    // gtag('event', 'begin_checkout', {
    //   // coupon: 'SUMMER_FUN',
    //   item_id: productID,
    //   content_type: 'Checkout',
    //   price: +grandTotal,
    //   quantity,
    //   currency: this.getCurrency(store.iso),
    //   // items: [{
    //   //   item_id: 'SKU_12345',
    //   //   item_name: 'jeggings',
    //   //   coupon: 'SUMMER_FUN',
    //   //   discount: 2.22,
    //   //   affiliation: 'Google Store',
    //   //   item_brand: 'Gucci',
    //   //   item_category: 'pants',
    //   //   item_variant: 'black',
    //   //   price: 9.99,
    //   //   currency: 'USD',
    //   //   quantity: 1
    //   // }],
    //   value: +grandTotal
    // });
    mixpanel.track('InititateCheckout', {
      CheckoutCartSize: cartItems.length,
      CheckoutCartValue: +grandTotal,
      CheckoutCartItemIDs: productID,
      CheckoutCartItemCategories: 'NA',
      CheckoutCartItemNames: productNames,
      CheckoutEstimatedTotalAmount: +grandTotal,
      TaxAmount: 0,
      CartDetails: cartItems,
    });
          
  }
  }
  

  purchase(
    productIds: string[],
    orderDate: string,
    orderId: string,
    quantity: number,
    paymentMethod: string,
    googleItem:string,
    transactionId:string,
    grandTotal: any,
    CPShippingCharges: string,
    CPCartSize: number,
    itemIds: any,
    itemNames:any,
    itemCatgeories:any,
    brandNames:any,
    paymentmode:string,
    order:any
  ): void {
    if ( this.liveWebsite) {

    if(paymentmode === 'C') {
      paymentmode = 'Cash on delivery';
    } else if(paymentmode === 'K') {
        paymentmode = 'Knet';
    } else if(paymentmode === 'CC') {
        paymentmode = 'Visa/MasterCard';
    } else if(paymentmode === 'M') {
      paymentmode = 'My Fatoorah';
    } else if(paymentmode === 'S') {
      paymentmode = 'Sadad';
    } else if(paymentmode === 'NP') {
      paymentmode = 'Qatar Debit Cards';
    } else if(paymentmode === 'B') {
      paymentmode = 'Benefit';
    } else if(paymentmode === 'UAECC') {
      paymentmode = 'Uae Debit Cards';
    } else if(paymentmode === 'W') {
      paymentmode = 'Wallet/Promo';
    } else if(paymentmode === 'TP1' || paymentmode === 'TP2' || paymentmode === 'TP3' || paymentmode === 'TP4') {
      paymentmode = 'Tabby';
    } else{
      paymentmode = 'Error Occurred';
    }

    const store = this.storeCode;
    fbq('track', 'Purchase', {
      content_ids: productIds,
      //item_category:'NA',
      content_type: 'product',
      content_name: 'purchase',
      value: grandTotal,
      contents:paymentMethod,
      currency: this.getCurrency(store.iso),
      num_items: quantity,
      transaction_id: orderId
      //order_date: orderDate,
      //order_id: orderId,
    });
    snaptr('track', 'PURCHASE', {
      currency: this.getCurrency(store.iso),
      item_ids:productIds,
      item_category:itemCatgeories,
      price: grandTotal,
      quantity:quantity,
      transaction_id: orderId
    });
    ttq.track('PlaceAnOrder', {
      currency: this.getCurrency(store.iso),
      content_id:productIds,
      content_category:itemCatgeories,
      price: grandTotal
      //quantity:quantity,
      //transaction_id: orderId
    });
    gtag('event', 'conversion', {
      send_to: 'AW-10792317884/WGUlCJbl2_wCELznlpoo',
      value:grandTotal.replace(/,/g, ''),
      currency:this.getCurrency(store.iso),
      transaction_id: orderId
    });

    // gtag("event", "purchase", {
    //   currency: this.getCurrency(store.iso),
    //   transaction_id: orderId,
    //   value: grandTotal.replace(/,/g, ''),
    //   affiliation: "NA",
    //   coupon: "NA",
    //   shipping: 0,
    //   tax: 0,
    //   items: googleItem,
    // });
    
    mixpanel.track('Purchase', {
      CPShippingCharges: CPShippingCharges,
      CPCartSize: CPCartSize,
      CPCartValue: grandTotal,
      CPCartItemIDs: itemIds,
      CPCartItemCategories: itemCatgeories,
      CPCartItemNames: itemNames,
      CPCartItemBrandNames: brandNames,
      CPCartDetails: googleItem,
      CPTotalAmount: grandTotal,
      CPCurrency: this.getCurrency(store.iso),
      TaxAmount: '',
      CPPaymentType: paymentmode,
      CPCreditCardType: '',
      CouponCode: String(order.coupon?order.coupon.code:''),
      CouponUsed: order.coupon?((order.coupon.discount > 0)?true:false):false,
      CouponDiscount: order.coupon?order.coupon.discount:0,
      NumberofOrders: '',
      LifetimeValue: '',
    });

    Insider.track('purchase', [{
      "product_price": grandTotal,
      "currency": this.getCurrency(store.iso),
      "order_id":orderId,
      "product_image_url":"",
      "taxonomy":itemCatgeories,
      "product_name":itemNames,
      "session_id":"",
      "product_id":productIds,
      "product_category":itemCatgeories,
      "product_subcategory":itemCatgeories
    }]);
          
  }
  }


}
