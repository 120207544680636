<div class="my_account_data">

<div class="container-fluid" @listStagger>
  
    <div class="account_heading pt-3 pb-5">
      <ul
        class="d-flex flex-wrap align-items-center justify-content-center w-100 listing-page-routing">
        <li class="routing-list">
          <a [routerLink]="'/my-account'">
            {{ "my account" | translate }}
          </a>
        </li>
      </ul>
    </div>
    <div class="account_data">
    <div class="account_user w-25" *ngIf="skeletonLoader">
      <ngx-skeleton-loader count="1" [theme]="skeltonTheme"></ngx-skeleton-loader>
    </div>
    <div class="account_user" *ngIf="!skeletonLoader">
      <h5>{{'WELCOME' | translate }} {{user.first_name}},
        <!-- , {{'this is your The Wishlist Account.' | translate}} -->
      </h5>
      <!-- <p>{{'Via the different options we will show you, you can modify your personal details, view your orders or manage
        your Wishlist.' | translate }}</p> -->
    </div>
    <div class="user_data" *ngIf="skeletonLoader">
      <div class="user_data_box">
        <div class="account_box" *ngFor="let item of counter(8);">
          <ngx-skeleton-loader count="2" [theme]="skeltonTheme"></ngx-skeleton-loader>
        </div>
      </div>
    </div>
    <div class="user_data" *ngIf="!skeletonLoader">
      <div class="user_data_box">

        <div class="account_box"  [routerLink]="'/my-details'" >
          <h3>{{'My Profile' | translate }}</h3>
          <p>{{'Review your personal information' | translate }}</p>
        </div>

        <div class="account_box" [routerLink]="'/my-orders'">
          <h3>{{'Track An Order' | translate }}</h3>
          <p>{{'Check your orders/status and history' | translate }}.</p>
        </div>

        <div class="account_box" [routerLink]="'/return-request-list'">
          <h3>{{'My Returns' | translate}}</h3>
          <p>{{'Query the different options for returning your items.' | translate }}</p>
        </div>

        <div class="account_box" [routerLink]="'/my-address'">
          <h3>{{'My Addresses'| translate }}</h3>
          <p>{{'Check the options to manage your shipping addresses.' | translate }}</p>
        </div>

        <div class="account_box" [routerLink]="'/my-wishlist'">
          <h3>{{'Wishlist' | translate }}</h3>
          <p>{{'Access your Wishlist so you don/t lose sight of the articles you like most.' | translate }}</p>
        </div>

        <div class="account_box" [routerLink]="'/my-brand-list'">
          <h3>{{'My Favourite Boutiques/Brands' | translate }}</h3>
          <p>{{"Access your favourite boutique’s/brands so you don't lose sight of the articles you like most" |
            translate }}.</p>
        </div>

        <div class="account_box" [routerLink]="'/wallet-credit'">
          <h3>{{'Store Credit' | translate }}</h3>
          <p>{{'Top up your account credit with money and spend it when placing orders.' | translate }}</p>
        </div>

        <div class="account_box"  [routerLink]="'/refer-a-friend'">
          <h3>{{'Refer A Friend' | translate }}</h3>
          <p>{{'Refer and earn on every referral.' | translate }}</p>
        </div>

        <div class="account_box"  [routerLink]="'/loyalty-point'">
          <h3>{{'Loyalty Points' | translate }}</h3>
          <p>{{'Earn Stars, get Rewards' | translate }}.</p>
        </div>

        <div class="account_box logout-box d-block d-lg-none"  href="javascript:;"  (click)="showLogoutModal()">
          <h3>{{'Logout' | translate }}</h3>

        </div>

      </div>
    </div>
  </div>
</div>

</div>


<div class="d-flex justify-content-center align-items-center p-2 mt-5">
  <button class="scroll-to-top-button" (click)="scrollToTop()">
    <img src="/assets/images/arrow-up.png" class="scroll-to-top-img">
    <span class="scroll-to-top-title">{{'BACK TO TOP' | translate}}</span>
  </button>
</div>

<!-- Edit Profile -->

<!-- Modal -->

<!-- Refer-friend-modal -->
<!-- <div class="modal fade" id="exampleModalCenter" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered ">
    <div class="modal-content">
      <div class="main">
      

        <div class="content">
          <h1>{{'Refer your friends and Earn'|translate}}</h1>
          <img src="/assets/images/gift.svg" alt="" class="image1">
          <p class="pad">{{'Your friend gets 2 KD when they sign up and,'|translate}}<br> {{'you get KD 5 when they place their first order'|translate}}</p>
    
          <div class="flex flex1">
            <div class="content1">
              <p>{{'Your Referral Code'|translate}}</p>
              <h4>TWL12345</h4>
            </div>
            <div class="flex refer">
              <div>
                <img src="/assets/images/copy2.svg" alt="" (click)="copyCode()" id="ref">

              </div>

              <div class="copy">
                <p>{{'Copy'|translate}}<br>{{'Code'|translate}}</p>
              </div>
            </div>
          </div>
          <div class="content">
            <h2 class="share"></h2>
          </div>
          <div class="shareicon">
            <a id="shareFB"
              href="https://www.facebook.com/sharer/sharer.php?u=https://www.shop-twl.com/home&t=TheWishlist https://app.shop-twl.com/kLPyzGB0Ajb"
              onclick="javascript:window.open(this.href, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600');return false;"
              target="_blank" title="The Wish List"><img src="/assets/images/Facebookicon.png" alt=""></a>
            <a href="https://wa.me/?text=Install The Wish List Official App Now to enjoy the best offers. Sign up with {{referralCode}} code and get 5.000 KD https://app.shop-twl.com/kLPyzGB0Ajb"
              target="_blank"><img src="/assets/images/Whatsapp.png" alt=""></a>
            <img src="/assets/images/Group 107603.png" alt="">
            <img src="/assets/images/Snapchat.svg" alt="" class="snapchat">
            <img src="/assets/images/copy.svg" alt="" (click)="copyCode()" id="ref">
          </div>
          <h2 class="step">{{'Steps to earn rewards'|translate}}</h2>
          <div class="stepcontent">
            <ul>
              <li><span><img src="/assets/images/dot-small-svgrepo-com.svg" alt="" ></span>
                <span class="dot">{{'Share your referral code with your friends/family & ask them '|translate}} </span> 
                  <span class="padding-left">{{'to register with The Wishlist.'|translate}}</span> </li>
                <li class="relative"><span><img src="/assets/images/dot-small-svgrepo-com.svg" alt="" ></span>
                  <span class="dot ">{{'Once they register they get rewarded with KD 2 reward.'|translate}}</span> </li>
                  <li class="relative1"> <span><img src="/assets/images/dot-small-svgrepo-com.svg" alt="" ></span>
                    <span class="dot">{{'After they place their first order, you get Rewarded with KD 2'|translate}}</span> </li>
            

            </ul>
          </div>
          <div class="pop">
            <img class="cross" src="/assets/icons/cross_popup.svg" alt="Cross" data-bs-dismiss="modal">
          </div>
        </div>




      </div>
    </div>
  </div>
</div> -->