import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FooterService {
  private footerSource = new BehaviorSubject(false);
  footerStatus = this.footerSource.asObservable();  

  constructor() { }

  toggleFooter(status: boolean) {
    this.footerSource.next(status);
  }
}
