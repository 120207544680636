import { ChangeDetectionStrategy, Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import {Product} from "../models/product";
import {ConfigSettings} from "../config/config.settings";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-cart-summary-v2',
  templateUrl: './cart-summary-v2.component.html',
  styleUrls: ['./cart-summary-v2.component.css']
})
export class CartSummaryV2Component implements OnInit {

  @Input() currency: string;
  @Input() cartValue: number;
  @Input() shippingCost: number;
  @Input() vatCharges: number = 0;
  @Input() deliveryText: string = '';
  @Input() is_free_delivery = false;
  @Input() codCost: number = 0;
  @Input() discount: number = 0;
  @Input() checkItemStockData:any = {
    cart: {
      id: "",
      items: [
      ]
    },
    default_address: {
      address_id: "",
      first_name: "",
      last_name: "",
      area_name: "",
      governorate_name: "",
      country_id: "",
      country_name: "",
      phonecode: "",
      block_id: "",
      block_name: "",
      street: "",
      avenue: "",
      landmark: "",
      flat: "",
      floor: "",
      building: "",
      addressline_1: "",
      mobile_number: "",
      alt_phone_number: "",
      location_type: "",
      notes: "",
      id_number: "",
      shipping_cost: "0",
      express_shipping_cost: "0",
      shipping_cost_kw: "0",
      express_shipping_cost_kw: "0",
      cod_cost: "0",
      cod_cost_kw: "0",
      is_cod_enable: 0,
      vat: "0",
      custom_duty: "0",
      min_customs_amount: "0",
      min_vat_amount: "0",
      custom_admin_fee: "0",
      min_custom_admin_amount: "0"
    },
    total_addresses: "1",
    payment_types: [],
    baseCurrencyName: "KD",
    sub_total: "0",
    total: "0",
    delivery_charges: "0",
    delivery_note: "",
    cod_cost: "0",
    delivery_options: [
    ],
    vat_pct: 0,
    vat_charges: "0.00",
    is_coupon_applied: 0,
    coupon: {},
    discount_price: "0",
    wallet: {
      wallet_amount_total: "0",
      wallet_amount_applied: "0",
      wallet_remaining_amount: "0"
    },
    custom_duty: "0",
    custom_duty_charges: "0.00",
    custom_admin_charges: "0.00"
  };

  @Input() wallet:any = {
    wallet_amount_applied:  "0",
    wallet_amount_total: "0",
    wallet_remaining_amount: "0"
  };
  @Output() proceedToCheckoutTrigger: EventEmitter<Number> = new EventEmitter<Number>();
  country_code:any;
  inclusive_of_taxes:any = "";

  constructor(
    private configSettings: ConfigSettings,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    if(this.deliveryText == ''){
      this.translateService.get('Delivery').subscribe((res: string) => {
        this.deliveryText = res;
      });
    }

    this.configSettings.getStoreObject().then((result) => {
      this.country_code = result.iso_code;
      if(result.iso_code == 'AE' || result.iso_code == 'SA' || result.iso_code == 'BH')
      {
        this.inclusive_of_taxes = " (Inclusive of taxes)";
      }
    });

    if(!this.discount){ this.discount = 0; }
  }

  ngOnChanges() {
 } 

 placeOrder(){
  this.proceedToCheckoutTrigger.emit(0)
}

}
