<div class="pt-md-4 brad-comp-sticky">
  <ul class="d-flex flex-wrap justify-content-center align-items-center w-100 listing-page-routing" [ngClass]="breadcrumb?.length < 3 ? 'routing-listing':''">
    <li [ngClass]="{'d-none': isBrandListingPage}" *ngFor="let item of breadcrumb| slice:1" class="routing-list">
      {{item.path | titlecase}}</li>
    <li [ngClass]="{'d-none': !isBrandListingPage}" *ngIf="brandInfo?.name" class="routing-list"><a
        routerLink="/brands">Boutique’s / Brands</a> / {{brandInfo?.name | titlecase}}</li>
  </ul>
</div>
<section class="container-fluid">

  <div class=" section-div" @listStagger>
    <!-- 
    <section class="container white-container mobile-sort-filter d-md-none py-3">
      <div class="row">
        <div class="col-6 {{lang == 'en' ? 'pr-1' : 'pl-1'}}">
          <button class="btn btn-block btn-white btn_black_sort font-black" (click)="toggleFilter()">{{'Refine' |
            uppercase |translate}}</button>
        </div>
        <div class="col-6 {{lang == 'en' ? 'pl-1' : 'pr-1'}}">
          <ng-select [items]="sortOptions" class="btn btn-block btn_black_sort d-flex font-black our-picks"
            [searchable]=false [placeholder]="'Choose your option'" [addTag]="true" bindLabel="label" bindValue="value"
            [clearable]="false" [searchable]=false [(ngModel)]="sortBy" (change)="doSort()">
          </ng-select>
        </div>
      </div>

    </section> -->

    <!-- <section class="container-fluid ">

      <div class="row m-sm-0">
        <div id="product-filter"
          class="col-12 col-sm-12 col-md-2 filter-container {{fixedFilter ? 'fixed-product-filter' : ''}}">
          <div class="d-md-none col-12 refine-close {{lang == 'ar' ? 'text-right': ''}}">
            <a href="javascript:;" (click)="toggleFilter()" class="d-inline-block font-black">
              <i class="fas fa-angle-left"></i>
              {{'Refine' | translate}}
            </a>
          </div>
          <div class="FilterHeadTop mx-3 mx-md-0">
            <div class="listing-sort">
              <span class="listing-sort-label font-bold">{{'Filter By' | translate}}</span>
            </div>
          </div>
          <ul class="filters mx-3 mx-md-0">

            <li *ngFor="let filter of filters; index as i" class="white-container"
              id="{{filter.name.toLowerCase()+'-filter'}}">

              <div class="accordion accordion-flush" id="accordionFlushExample">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                    <button class="accordion-button collapsed filter-title" type="button" data-bs-toggle="collapse"
                      [attr.data-bs-target]="'#collapse' + i" aria-expanded="true" aria-controls="flush-collapseOne">
                      {{filter.name}}
                    </button>
                  </h2>
                  <div [id]="'collapse' + i" class="accordion-collapse collapse show"
                    data-bs-parent="#accordionFlushExample">
                    <a href="javascript:;"
                      (click)="clearFilter('cls_'+filter.name.toLowerCase()+i, filter.name.toLowerCase())">
                      <h4 class="clear-btn">{{'Clear' | translate}}</h4>
                    </a>
                    <ul [id]="filter.name.toLowerCase()">
                      <li class="customcheckbox" *ngFor="let value of filter.values">
                        <label class="custom-checkbox" for="{{filter.name}}+{{value.id}}">
                          <input class="filter-checkbox" [class]="'cls_'+filter.name.toLowerCase()+i" type="checkbox"
                            name="sortBy" id="{{filter.name}}+{{value.id}}"
                            (change)="doFilter($event, value.id, filter.name.toLowerCase())" />
                          <b></b>
                          <span>{{value.value |titlecase }}</span>
                        </label>
                      </li>
                    </ul>
                  </div>
                </div>

              </div>
            </li>
            <li class="white-container mb-5 mb-md-0">

              <div class="accordion" id="accordionPanelsStayOpenExample">
                <div class="accordion-item">
                  <h2 class="accordion-header">
                    <button class="accordion-button collapsed filter-title" type="button" data-bs-toggle="collapse"
                      data-bs-target="#panelsStay" aria-expanded="false"
                      aria-controls="panelsStay">
                      {{'Price' | translate}}
                    </button>
                  </h2>
                  <div id="panelsStay" class="accordion-collapse collapse">
                    <a href="javascript:;" (click)="clearPriceFilter()">
                      <h4 class="clear-btn">{{'Clear' | translate}}</h4>
                    </a>
                    <ul class="price-filter">
                      <li>
                        <div class="price-button">
                          <span
                            class="d-flex justify-content-between align-items-center listingfilterdiv {{(lang == 'en') ? 'pr-0':'pl-0'}}"
                            style="margin-bottom: 10px;">
                            <span
                              class="col-3 price-filter-currency {{(lang == 'en') ? 'pl-0 pr-1':'pr-0 pl-1'}}">{{currency}}</span>
                            <span class="col-9 {{(lang == 'en') ? 'pl-0 pr-2':'pr-0 pl-2'}}">
                              <input type="text" class="form-control placeholder-text text-input" [(ngModel)]="minPrice"
                                placeholder="{{'Min' | translate}}">
                            </span>
                          </span>
                          <span
                            class="d-flex justify-content-between align-items-center listingfilterdiv {{(lang == 'en') ? 'pl-0':'pr-0'}}"
                            style="margin-bottom: 10px;">
                            <span
                              class="col-3 price-filter-currency {{(lang == 'en') ? 'pl-0 pr-1':'pr-0 pl-1'}}">{{currency}}</span>
                            <span class="col-9 px-0">
                              <input type="text" class="form-control placeholder-text text-input" [(ngModel)]="maxPrice"
                                (keyup)="applyButton()" placeholder="{{'Max' | translate}}">
                            </span>
                          </span>
                        </div>
                        <span class="col-12 d-none d-md-block">
                          <button class="btn btn-offWhite btn-block font-black position-relative"
                            [class.btnBg]="applyBtnEnabled" [disabled]="!applyBtnEnabled"
                            (click)="updatePriceFilter($event)">
                            {{'Apply' | translate}}
                            <i class="fas fa-angle-right absoluteBtn"></i>
                          </button>
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>

              </div>

            </li>
          </ul>

          <button class="btn btn-dark btn-block position-relative d-md-none filter-fixed-submit"
            (click)="toggleFilter()">
            {{'Apply' | translate}}
            <i class="fas fa-angle-right absoluteBtn"></i>
          </button>
        </div>
        <div class="col-12 col-sm-12 col-md-10 p-0">

          <div class="col-12 col-sm-12 col-md-12 p-0 sort-pagination-container d-none d-md-flex">


            <div class="text-center breadcrumbProdList">
              <ul class="breadcrumb">
                <li *ngFor="let item of breadcrumb | slice:1">{{item.path | titlecase}}</li>
              </ul>
            </div>
            <div class="listing-sort listing-sort-Div d-flex mt-3">
              <img src="../../assets/ArtWork/Sort@2x.png" class="sortImg" />
              <ng-select id="product-sort" [items]="sortOptions" [placeholder]="'Choose your option'" [searchable]=false
                [addTag]="true" bindLabel="label" bindValue="value" [clearable]="false" [(ngModel)]="sortBy"
                (change)="doSort()" #paramSortSelect>
              </ng-select>
            </div>

          </div>
          <div class="TopTitleBanner" *ngIf="products.length">
            <h1 class="page-title font-black vertical-center-sm" [class.text-right]="lang === 'ar'" *ngIf="q==''">
              {{title}}</h1>
            <h1 class="page-title font-black vertical-center-sm" [class.text-right]="lang === 'ar'" *ngIf="q!=''">
              {{this.q}}
            </h1>
          </div>

          <div class="products product-listing-grid row m-0" *ngIf="products.length"
            [ngClass]="{'disableScroll': fixedFilter}">
            <article *ngFor="let item of products" drag-scroll-item
              class="product col-6 col-sm-4 col-md-3 white-container">
              <app-product-item [lang]="lang" [item]="item" [currency]="currency" [store]="store" [userId]="user.id"
                [returnUrl]="currentRoute" (reloadProductList)="getProductList()"></app-product-item>
            </article>
          </div>

          <div *ngIf="!products.length" class="empty-cart">
            <div class="empty-cart-content white-container">
              <h4 class="page-title font-bold text-center mb-0 mb-md-2">{{'NO PRODUCTS FOUND' | translate}}</h4>
              <p class="font-regular text-center ">
                {{"UNFORTUNATELY THERE WERE NO ITEMS MATCHING YOUR REQUEST." | translate}}
              </p>

            </div>
          </div>
        </div>
      </div>

      <div class="row d-md-none py-3 justify-content-center" *ngIf="products.length">
        <ul class="pagination">
          <li class="pagination-item">
            <a href="" (click)="goToPage(1)" (click)="gotoTop()" class="js-lp-pagination-first generic"
              [ngClass]="{'pagination-disabled': (page === 1)}">
              <span class="fas fa-step-backward"></span>
            </a>
          </li>
          <li class="pagination-item">
            <a href="" (click)="goToPage(page-1)" (click)="gotoTop()" class="js-lp-pagination-previous generic"
              [ngClass]="{'pagination-disabled': (page === 1)}">
              <span class="fas fa-caret-left"></span>
            </a>
          </li>
          <li class="pagination-label">
            <span class="js-lp-pagination-current">{{'Page' | translate}} {{page}}</span>
            {{'of ' | translate}}
            <span class="js-lp-pagination-all">
              {{totalPages}}
            </span>
          </li>
          <li class="pagination-item">
            <a href="" (click)="goToPage(page+1)" (click)="gotoTop()" class=""
              [ngClass]="{'pagination-disabled': (page == totalPages)}">
              <span class="fas fa-caret-right"></span>
            </a>
          </li>
          <li class="pagination-item">
            <a href="" (click)="goToPage(totalPages)" (click)="gotoTop()" class="js-lp-pagination-last generic"
              [ngClass]="{'pagination-disabled': (page == totalPages)}">
              <span class="fas fa-step-forward"></span>
            </a>
          </li>
        </ul>
      </div>
    </section> -->



    <!-- routing -->
  

    <!-- if tierId is 2 this section gets activated -->
    <div class="carousel-container" *ngIf="isBrandListingPage && (tierId == 2 || this.tierId == 1)">
      <app-brand-info [brandInfo]="brandInfo" [skeletonLoader]="skeletonLoader"></app-brand-info>
    </div>

     <!-- if tierId is 3 this section gets activated -->
    <div class="brand-comp" *ngIf="isBrandListingPage && tierId == 3">
      <p class="brand-comp-name">
        {{brandInfo?.name}}
      </p>
      <div class="add-to-fav-section" routerLink="/login"  *ngIf="!this.user?.id">
        <img id="fav-star" src="assets/icons/fav-brand-star.svg" alt="fav-star" >
        <p class="add-to-fav" id="toggle-fav" *ngIf="brandInfo?.is_in_wishlist == 0">{{'Add to Favourites' | translate}}</p>
      </div>
      <div class="add-to-fav-section" appClickToFavBrands (click)="toggleBrandAsFavorite(brandInfo?.id)" *ngIf="this.user?.id">
        <img id="fav-star" src="assets/icons/fav-brand-star.svg" alt="fav-star" *ngIf="brandInfo?.is_in_wishlist == 0">
        <p class="add-to-fav" id="toggle-fav" *ngIf="brandInfo?.is_in_wishlist == 0">{{'Add to Favourites' | translate}}</p>
        <img id="fav-star" src="assets/icons/fav-brand-star-filled.svg" alt="fav-star" *ngIf="brandInfo?.is_in_wishlist == 1">
        <p class="add-to-fav" id="toggle-fav" *ngIf="brandInfo?.is_in_wishlist == 1">{{'Remove from Favourites' | translate}}</p>
      </div>
    </div>

    <ul *ngIf="filters.length> 0 && (!skeletonLoader || fromFilter)" class="d-flex justify-content-between align-items-center filter-recom-ul">
      <li *ngIf="filterShow == false" (click)="filterToggle()"
        class="d-flex align-items-center justify-content-center gap-2 show-filter">
        <span>{{'SHOW FILTERS'|translate}}</span>
        <img src="../../assets/icons/plus.svg" alt="plus" class="plus-min">
      </li>
      <li *ngIf="filterShow == true" (click)="filterToggle()"
        class="d-flex align-items-center justify-content-center gap-2 show-filter">
        <span>{{'HIDE FILTERS'|translate}}</span>
        <img src="../../assets/icons/minus.svg" alt="minus" class="plus-min">
      </li>
      <li class="position-relative">
        <div class="d-flex gap-2">
          <span class="recommended">{{'Sort By:'|translate}}</span>
          <span (click)="toggleSortFun($event)" class="selected-sort-option">{{selectedSortOption}}</span>
          <img class="down-arrow rotate-down" *ngIf="!toggleSort" src="assets/icons/down-arrow.svg" alt="" srcset="">

          <img class="up-arrow rotate-upside-down" *ngIf="toggleSort" src="assets/icons/down-arrow.svg" alt=""
            srcset="">
        </div>

      </li>

    </ul>
    <ul *ngIf="toggleSort" class="sort-dropdown fadeIntop">
      <li class="sort-options" *ngFor="let option of sortOptions; let i = index" (click)="doSort(option, i)"
        [ngClass]="selectedSortIndex === i?'bold-option':''">
        <p> {{option?.label}}</p>
      </li>
    </ul>

    <div *ngIf="skeletonLoader && !fromFilter" class="d-flex gap-2 justify-content-between align-items-center">
      <div class="filter-skeleton-width">
        <ngx-skeleton-loader></ngx-skeleton-loader>
      </div>
     <div class="position-relative filter-skeleton-width">
        <ngx-skeleton-loader></ngx-skeleton-loader>
     </div>
    </div>


    <div class="d-lg-flex w-100" [ngClass]="filterShow == true?'gap-filter-items':''">
      <div *ngIf="skeletonLoader && !fromFilter" [ngClass]="filterShow == true?'filter-section':''" class="postion-filter-section">
        <div class="d-flex w-100 gap-4  justify-content-between align-items-center filter-by designer-image">
          <ngx-skeleton-loader class="w-50 designer-image-img"></ngx-skeleton-loader>
          <div class="skelton-circle-main">
            <ngx-skeleton-loader class="skelton-circle designer-image-img"></ngx-skeleton-loader>
          </div>
        </div>
        <div class="d-flex w-100 gap-4  justify-content-between align-items-center filter-by designer-image" *ngFor="let item of [1,2,3,4,5]">
          <ngx-skeleton-loader class="w-50 designer-image-img"></ngx-skeleton-loader>
          <div class="skelton-circle-main">
          <ngx-skeleton-loader class="skelton-circle designer-image-img"></ngx-skeleton-loader>
          </div>
        </div>
        
      </div>

      <div *ngIf="filters.length> 0 && (!skeletonLoader || fromFilter)" [ngClass]="filterShow == true?'filter-section':''" class="postion-filter-section">
        <div (click)="filterToggle()" class="d-flex justify-content-between align-items-center filter-by">
          <p>{{'Filter By'|translate}}: </p>
          <img src="/assets/icons/close.svg" alt="close">
        </div>

        <ul class="" id="accordion1">

          <li class="list-border" *ngFor="let filter of filters;let i= index;"
            id="{{filter.name.toLowerCase()+'-filter'}}">
            <div [id]="'headingOne'+i">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                [attr.data-bs-target]="'#collapseOne'+i" aria-expanded="false" [attr.aria-controls]="'collapseOne'+i">
                <span>{{filter.name}}</span>
              </button>
              <a class="clear-mange"
                (click)="clearFilter('cls_'+filter.name.toLowerCase()+i, filter.name.toLowerCase())">
                <p class="clear-button">{{'Clear All' | translate}}</p>
              </a>
            </div>
            <div [id]="'collapseOne'+i" class="accordion-collapse collapse" [attr.aria-labelledby]="'headingOne'+i"
              data-bs-parent="#accordion1">

              <ul [id]="filter.name.toLowerCase()">
                <li class="customcheckbox-filter" *ngFor="let value of filter.values">
                  <label class="custom-checkbox-filter" for="{{filter.name}}+{{value.id}}">
                    <input class="filter-checkbox-input" [class]="'cls_'+filter.name.toLowerCase()+i" type="checkbox"
                      name="sortBy" id="{{filter.name}}+{{value.id}}"
                      (change)="doFilter($event, value.id, filter.name.toLowerCase())" />
                    <b></b>
                    <!-- <span [style.background]="value?.value?.toLowerCase()" class="circle"></span> -->
                    <span [style.background]="value?.color?.toLowerCase()" class="circle"></span>
                    <span>{{value.value |titlecase }}</span>
                  </label>
                </li>
              </ul>
            </div>

          </li>


          <li class="mb-lg-5">

            <div class="list-border" id="accordionPanels">

              <div class="accordion-header">
                <button class="accordion-button collapsed filter-title" type="button" data-bs-toggle="collapse"
                  data-bs-target="#panelsStay" aria-expanded="false" aria-controls="panelsStay">
                  {{'Price' | translate}}
                </button>
                <a class="clear-mange" (click)="clearPriceFilter()">
                  <p class="clear-button">{{'Clear All' | translate}}</p>
                </a>
              </div>
              <div id="panelsStay" class="accordion-collapse collapse" data-bs-parent="#accordion1">

                <ul class="price-filter">
                  <!-- <li>
                    <div class="price-main-filter">
                      <span class="d-flex justify-content-between align-items-center listingfilter-price ">
                        <span class="price-filter-currency">{{currency}}</span>
                        <input type="text" class="form-control placeholder-text text-input" [(ngModel)]="minPrice"
                          placeholder="{{'Min' | translate}}">
                      </span>
                      <span class="d-flex justify-content-between align-items-center listingfilter-price">
                        <span class="price-filter-currency">{{currency}}</span>
                        <input type="text" class="form-control placeholder-text text-input" [(ngModel)]="maxPrice"
                          (keyup)="applyButton()" placeholder="{{'Max' | translate}}">
                      </span>
                    </div>

                    <button class="btn button-style btn-offWhite btn-block font-black position-relative"
                      [class.btnBg]="applyBtnEnabled" [disabled]="!applyBtnEnabled" (click)="updatePriceFilter($event)">
                      {{'Apply' | translate}}
                      <i class="fas fa-angle-right absoluteBtn"></i>
                    </button>

                  </li> -->
                  <li class="w-100">
                    <div class="price-main-filter ">
                      <div class="range-container w-100">
                        <div class="w-100">
                          <div class="">
                            <div class="range">
                              <input min="{{minPrice}}" max="{{maxPriceShow}}" type="range" [(ngModel)]="maxPrice" id="myinput"
                                class="slider-range w-100" (change)="updatePrice()" />
                            </div>
                          </div>

                          <div class="">
                            <div class="d-flex justify-content-between align-items-center w-100">
                                <div class="w-fit">
                                    <div class="KDlow">{{currency}} {{minPrice}}</div>
                                </div>
                                <div class="w-fit">
                                    <div class="KDhig">{{currency}} {{maxPriceShow}}</div>
                                </div>
                            </div>

                            <p class="Upto text-right">{{'Upto' | translate}}
                                <span class="Upt1">
                                    <span> {{currency}} <span class="upto-value">{{price}}</span></span>
                                </span>
                            </p>

                        </div>
                        </div>
                      </div>
                    </div>
                    <div class="price-main-filter d-none">
                      <div class="range-container">
                        <div class="d-flex justify-content-between">
                          <label for="customRange1" class="form-label">0 {{currency}}</label>
                          <label for="customRange1" class="form-label">{{maxProductPrice}} {{currency}}</label>
                        </div>
                        <div class='range-slider position-relative'>
                          <input type="range" min="0" max="180" step="1" v-model="sliderMin">
                          <!-- <input type="number" min="0" max="180" step="1" v-model="sliderMin"> -->
                          <input type="range" [max]="maxProductPrice" (change)="updateTooltip($event)">
                          <!-- <input type="number" min="0" max="180" step="1" v-model="sliderMax"> -->
                        </div>
                        <!-- <input type="range" class="form-range" id="customRange1" min="0" [max]="maxProductPrice"
                          (change)="updateTooltip($event)"> -->
                        <p *ngIf="tooltipValue != null">Price : {{ tooltipValue }}</p>
                        <!-- <div class="range-tooltip" *ngIf="tooltipValue !== null">{{ tooltipValue }}</div> -->
                      </div>
                    </div>
                  </li>
                </ul>
              </div>

            </div>

          </li>

          <!-- <li class="customcheckbox-filter list-border last-filter">
            <label class="custom-checkbox-filter" for="just-in">
              <input class="filter-checkbox-input" type="checkbox" name="sortBy" id="just-in" />
              <b></b>
              <span>{{'Just In'|translate}}</span>
            </label>
          </li>

          <li class="customcheckbox-filter list-border last-filter">
            <label class="custom-checkbox-filter" for="sale-only">
              <input class="filter-checkbox-input" type="checkbox" name="sortBy" id="sale-only" />
              <b></b>
              <span>{{'Sale Only'|translate}}</span>
            </label>
          </li> -->
          <li class="apply-button d-flex d-lg-none">
            <button [disabled]="brands.length == 0 && attributes.length == 0 && price.length == 0" [ngClass]="{'disable-button-styles': brands.length == 0 && attributes.length == 0 && price.length == 0}" (click)="filterToggle()">
              {{'Apply' |translate}}
            </button>
          </li>
        </ul>

      </div>
      <div *ngIf="skeletonLoader" [ngClass]="filterShow == true?'item-section-After':''" class="items-section">
        <div class="item-section-page">
          <article *ngFor="let item of counter(24)" drag-scroll-item [ngClass]="filterShow == true?'items-box-after':''"
            class="items-box ">
            <div class="items-height-skeleton">
             <ngx-skeleton-loader class="height-fix" [theme]="skeltonTheme"></ngx-skeleton-loader>
            </div>
            <div class="w-100">
              <div class="d-flex justify-content-center">
                <ngx-skeleton-loader class="w-25"></ngx-skeleton-loader>
              </div>
              <div class="d-flex justify-content-center">
                <ngx-skeleton-loader class="w-50"></ngx-skeleton-loader>
              </div>
              <div class="d-flex justify-content-center">
                <ngx-skeleton-loader class="w-25"></ngx-skeleton-loader>
              </div>
            </div>         
          </article>
        </div>
      </div>
      <div *ngIf="products.length> 0 && !skeletonLoader" [ngClass]="filterShow == true?'item-section-After':''"
        class="items-section">
        <div class="item-section-page">
          <article class="items-box" *ngFor="let item of products" drag-scroll-item [ngClass]="filterShow == true?'items-box-after':''">
            <app-product-item [lang]="lang" [item]="item" [currency]="currency" [store]="store" [userId]="user.id"
              [returnUrl]="currentRoute" (reloadProductList)="getProductList()">
            </app-product-item>
          </article>
        </div>
        <div class="listing-lower-sec">
          <ul *ngIf="totalProducts > this.pageSize "
            class="d-flex justify-content-between py-2 align-items-center pagination-section">
            <li class="d-flex justify-content-center gap-4  align-items-center"
              [ngClass]="{'disable-pagination': page == 1}">
              <div class="pagination-arrow">
                <a (click)="goToPage(1)" (click)="gotoTop()" class="">
                  <img *ngIf="page == 1" src="../../assets/icons/step-backward.png" alt=""
                    class="pagination-image pagination-cursor">
                  <img *ngIf="page > 1" src="../../assets/icons/step-forward-dark.png" alt=""
                    class="pagination-image pagination-left pagination-cursor-pointer">
                </a>
              </div>
              <div class="pagination-arrow">
                <a (click)="goToPage(page-1)" (click)="gotoTop()" class="">
                  <img *ngIf="page == 1" src="../../assets/icons/right-arrow-gray.svg" alt=""
                    class="pagination-image pagination-left pagination-cursor">
                  <img *ngIf="page > 1" src="../../assets/icons/right-arrow.svg" alt=""
                    class="pagination-image pagination-left pagination-cursor-pointer">
                  <span class="prev-text">{{'Previous'|translate}}</span>
                </a>
              </div>
            </li>

            <li class="page-number">
              <span class="js-lp-pagination-current">{{'Page' | translate}} {{page}}</span>
              {{'of ' | translate}}
              <span class="js-lp-pagination-all">
                {{totalPages}}
              </span>
            </li>

            <li class="d-flex justify-content-center gap-4 align-items-center"
              [ngClass]="{'disable-pagination': page == totalPages}">
              <div class="pagination-arrow">
                <a (click)="goToPage(page+1)" (click)="gotoTop()" class="">
                  <span class="prev-text">{{'Next'|translate}}</span>
                  <!-- <span class="fas fa-caret-right"></span> -->
                  <img *ngIf="page <= totalPages-1" src="../../assets/icons/right-arrow.svg" alt=""
                    class="pagination-image pagination-cursor-pointer">
                  <img *ngIf="page == totalPages" src="../../assets/icons/right-arrow-gray.svg" alt=""
                    class="pagination-image pagination-cursor">
                </a>
              </div>
              <div class="pagination-arrow">
                <a (click)="goToPage(totalPages)" (click)="gotoTop()" class="">
                  <img *ngIf="page <= totalPages-1" src="../../assets/icons/step-forward-dark.png" alt=""
                    class="pagination-image pagination-cursor-pointer">
                  <img *ngIf="page == totalPages" src="../../assets/icons/step-backward.png" alt=""
                    class="pagination-image pagination-left pagination-cursor">
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div *ngIf="products.length==0 && !skeletonLoader" class="text-center empty-product-list">
        <div class="">
          <h4 class="">
            {{ "No Product match with your search criteria" | translate }}
          </h4>
          <p class="">
            {{ "please try by another keyword" | translate }}
          </p>
        </div>
      </div>

    </div>
  </div>

</section>

<div class="d-flex justify-content-center align-items-center p-2 ">
  <button class="scroll-to-top-button" (click)="gotoTop()" *ngIf="products.length>0">
    <img src="../../assets/images/arrow-up.png" class="scroll-to-top-img">
    <span class="scroll-to-top-title">{{'BACK TO TOP' | translate}}</span>
  </button>
</div>

<div (click)="filterToggle()" *ngIf="filterShow == true" class="overly--cart-div"></div>

<!-- <button class="scroll-to-top-button" (click)="gotoTop()" *ngIf="products.length>0">
  <img src="../../assets/images/arrow-up.png" class="scroll-to-top-img">
  <span class="scroll-to-top-title">{{'BACK TO TOP' | translate}}</span>
</button> -->