<section id="login-page" @listStagger>
  <div class="container container-width-60">
    <h4 class="page-title font-black">{{'REGISTER NOW' | translate}}</h4>
    <h6 class="box-heading font-regular text-center">{{'Set up an account an enjoy these benefits and more' | translate}}</h6>
    <div class="row m-0 justify-content-center">
      <div class="col-12 col-sm-12 col-md-5 register-container mb-2">
        <div class="white-container inner-content">

          <div *ngIf="registerSubmitted || hasError">
            <p class="alert alert-success" [ngClass]="[registerHasError ? 'alert alert-danger' : 'alert alert-success']">
              {{registerMessage}}
            </p>
          </div>

          <form #registerForm="ngForm" autocomplete="off" (ngSubmit)="onSubmit(registerForm)">
            <div class="form-group required">
              <label class="control-label">{{'First name' | translate}}</label>
              <input type="text" class="form-control text-input" name="first_name" [(ngModel)]="first_name" (focus)="animateLabel($event)" (blur)="animateLabel($event)" required>
              <small class="error-message text-danger" *ngIf="registerSubmitted && first_name == ''">{{'Please enter your name' | translate}}</small>
            </div>

            <div class="form-group required">
              <label class="control-label">{{'Last name' | translate}}</label>
              <input type="text" class="form-control text-input" name="last_name" [(ngModel)]="last_name" (focus)="animateLabel($event)" (blur)="animateLabel($event)" required>
              <small class="error-message text-danger" *ngIf="registerSubmitted && last_name == ''">{{'Please enter your last name' | translate}}</small>
            </div>

            <div class="form-group required">
              <label class="control-label">{{'Email' | translate}}</label>
              <input type="text" class="form-control text-input" name="email" [(ngModel)]="email" autocomplete="nope" (focus)="animateLabel($event)" (blur)="animateLabel($event)" required email>
              <small class="error-message text-danger" *ngIf="registerSubmitted && email == ''">{{'Please enter your email' | translate}}</small>
              <small class="error-message text-danger" *ngIf="registerHasError && (!isEmailValid && email != '')">{{'Please enter a valid email' | translate}}</small>
              <small class="error-message text-danger" *ngIf="false">{{'Email id already exists' | translate}}</small>
            </div>

            <div class="form-group required">
              <label class="control-label">{{'Mobile' | translate}}</label>
              <input type="text" class="form-control text-input" name="phone" [(ngModel)]="phone" autocomplete="nope" 
                [pattern]="phoneValidationPattern" (focus)="animateLabel($event)" (blur)="animateLabel($event)" 
                maxlength="10" minlength="5" required
              >
              <small class="error-message text-danger" *ngIf="registerSubmitted && phone == ''">{{'Please enter your phone number' | translate}}</small>
              <small class="error-message text-danger" *ngIf="registerSubmitted && (phone != '' && phone.length < 8)">{{'Please enter a valid phone number' | translate}}</small>
            </div>

            <div class="form-group required">
              <label class="control-label">{{'Password' | translate}}</label>
              <input type="password" class="form-control text-input" name="password" [minLength]="6" [(ngModel)]="password" autocomplete="new-password" (focus)="animateLabel($event)" (blur)="animateLabel($event)" required>
              <small class="error-message text-danger" *ngIf="registerSubmitted && password == ''">{{'Please enter your password' | translate}}</small>
              <small class="error-message text-danger" *ngIf="registerSubmitted && (password != '' && password.length < 6)">{{'Password must be at least 6 characters' | translate}}</small>
            </div>

            <div class="form-group required">
              <label class="control-label">{{'Confirm Password' | translate}}</label>
              <input type="password" class="form-control text-input" name="confirm-password" [(ngModel)]="confirm_password" [minlength]="6" #uname="ngModel" autocomplete="new-password" (focus)="animateLabel($event)" (blur)="animateLabel($event)" appConfirmPasswordValidator="password" required>
              <small class="error-message text-danger" *ngIf="registerSubmitted && confirm_password == ''">{{'Please re-confirm your password' | translate}}</small>
            </div>

            <div class="form-group required">
              <label class="">{{'Referral Code' | translate}}</label>
              <input type="text" class="form-control text-input" name="referred_by_code" [(ngModel)]="referred_by_code" (focus)="animateLabel($event)" (blur)="animateLabel($event)">
              <small class="error-message text-danger" *ngIf="registerSubmitted && referred_by_code == 'havetochangethelogichere'">{{'Please enter your referral code' | translate}}</small>
            </div>

            <p class="font-black font-small font-medium-regular mt-3 mb-3">{{'Find out more' | translate}} <a href="mailto:{{support_phone}}" class=""><ins>{{'about our emails' | translate}}</ins></a> & <a [routerLink]="'/privacy-policy'"><ins>{{'Privacy Policy' | translate}}</ins></a> </p>

            <button type="submit" class="btn btn-black btn-submit btn-block">
              {{'REGISTER' | translate}}
            </button>

            <p class="font-black font-small font-medium-regular mt-3 mb-1">{{'By registering you agree to our' | translate}} <a [routerLink]="'/terms-and-conditions'" class=""><ins>{{'Terms and Conditions' | translate}}</ins></a> </p>
            <p class="font-black font-small font-medium-regular mb-3">{{'Already registered?' | translate}} <a [routerLink]="'/login'" class=""><ins>{{'Sign in' | translate}}</ins></a> </p>
          </form>

        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-5 register-container mb-2">
        <div class="white-container inner-content">
          <img src="assets/images/emailSignup.jpg" class="img-fluid" />
          <ul class="font-small font-medium-regular font-regular">
            <li>{{'Fast sign in and checkout plus delivery updates by email' | translate}}</li>
            <li>{{'Fashion , beauty and fitness emails from our Editors' | translate}}</li>
            <li>{{'Create and save wish lists of pieces you love' | translate}}</li>
            <li>{{'Receive invites to sales, offers and more' | translate}}</li>
            <li>{{'Alerts when itms arrive from your favorite designers' | translate}}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>
