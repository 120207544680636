import { HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable, Output, Directive } from "@angular/core";
import { LocalStorage } from '@ngx-pwa/local-storage';
import { Store } from '../models/store';
import { catchError, map, mergeMap, retry } from "rxjs/operators";
import { of, throwError } from "rxjs";
import { environment } from 'src/environments/environment';

@Directive()
@Injectable({
    providedIn: "root"
})
export class ConfigSettings {

    // public static API_URL = 'https://dev.shop-twl.com/api/ver4api/';
    public static API_URL = environment.apiUrl;
    // public static API_URL = 'https://admin.shop-twl.com/api/v1/';
    // public static API_URL = 'http://websites.lezasolutions.com/wishlist/api/v1/';

    public static HTTP_OPTIONS = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'authtoken': '89c7c430241a4b0a6583b5558caf12cd399006617031690838de23ad78a91326',
        })
    };

    public static ENCRYPT_KEY = 'Leza2018';
    loading = false;
    currency: string;
    store: Store;
    disableLoader=1;
    @Output() load: EventEmitter<boolean> = new EventEmitter<boolean>();

    defaultCategory = 1;

    constructor(
        protected localStorage: LocalStorage
    ) { }

    cleanUrl(url: string) {
        return url.trim().toLowerCase().replace(/[^a-zA-Z\d\s]/, '').replace(' ', '-');
    }

    getLang() {
        let lang = 'en';

        if (window && window.localStorage && window.localStorage.getItem('lang')) {
            lang = window.localStorage.getItem('lang');
        }

        if (window.localStorage.getItem('lang') != 'en' && window.localStorage.getItem('lang') != 'ar') {
            lang = 'en';
        }

        return lang;
    }

    setLang(lang) {
        window.localStorage.setItem('lang', lang);
    }

    getLandingAds() {
        return window.localStorage.getItem('adClosed');
    }
    setLandingBanner(val){
        window.sessionStorage.setItem('fromLandingUrl',val);
    }
    removeLandingBanner(){
        window.sessionStorage.removeItem('fromLandingUrl');
    }
    getLandingBanner(){
      return  window.sessionStorage.getItem('fromLandingUrl');
    }
    setLandingBannerCount(val:any){
        window.sessionStorage.setItem('bannerCount',val);
    }
    getLandingBannerCount(){
      return  window.sessionStorage.getItem('bannerCount');
    }

    setLandingAds() {
        window.localStorage.setItem('adClosed', '1');
    }

    getStore() {
        return JSON.parse(window.localStorage.getItem('store'));
    }

    setStore(store) {
        window.localStorage.setItem('store', JSON.stringify(store));
    }

    getStoreObject(): Promise<any> {
        let promise = new Promise<any>((resolve, reject) => {
            const storeObj = this.localStorage.getItem<any>('store_object').pipe(
                mergeMap(result => {
                    if (!result) {
                        return throwError('Error');
                    }

                    return of(result);
                }),
                retry(200)
            );
            storeObj.subscribe({
                next(obj) {
                    this.store = <Store>obj;
                    resolve(this.store);
                },
                error(error) {
                    console.log(error);
                }
            });
            /*this.localStorage.getItem<any>('store_object').subscribe((store_object) => { console.log(store_object);
                if (store_object) {
                    this.store = <Store> store_object;
                    resolve(this.store);
                }
            });*/
        });
        return promise;
    }

    getCurrency() {
        return 'KD';
    }
    setPreviousUrl(url){
        window.sessionStorage.setItem('previousUrl',JSON.stringify(url))
    }
    getPreviousUrl(){
        return JSON.parse(window.sessionStorage.getItem('previousUrl'));
    }

    setCurrency(currency) {
        window.localStorage.setItem('currency', currency);
    }

    getCartId() {
        return window.localStorage.getItem('cart-id');
    }

    setCartId(cart_id) {
        window.localStorage.setItem('cart-id', cart_id);
    }

    setCartIdItems(cart_items) {
        window.localStorage.setItem('cart-items', JSON.stringify(cart_items));
    }

    getCartItems() {
        return window.localStorage.getItem('cart-items');
    }

    toggleLoading(key: boolean) {
        // this.loading = key;
        // this.load.emit(this.loading)
    }

    startLoading() {
        this.loading = true;
        this.load.emit(this.loading);
    }

    stopLoading() {
        this.loading = false;
        this.load.emit(this.loading);
    }

    getCartCount() {
        if (window.localStorage.getItem('cart-count') === null) {
            this.setCartCount(0);
        }
        return window.localStorage.getItem('cart-count');
    }

    setCartCount(cart_count) {
        window.localStorage.setItem('cart-count', cart_count);
    }

    getWishlistCount() {
        if (window.localStorage.getItem('wishlist-count') === null) {
            this.setWishlistCount(0);
        }
        return window.localStorage.getItem('wishlist-count');
    }

    setWishlistCount(cart_count) {
        window.localStorage.setItem('wishlist-count', cart_count);
    }
    getBrowserVersion() {
        let ua = navigator.userAgent, tem, M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
        if (/trident/i.test(M[1])) {
            tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
            return 'IE ' + (tem[1] || '');
        }
        if (M[1] === 'Chrome') {
            tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
            if (tem != null) { return tem.slice(1).join(' ').replace('OPR', 'Opera'); }
        }
        M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
        if ((tem = ua.match(/version\/(\d+)/i)) != null) { M.splice(1, 1, tem[1]); }
        return M.join(' ');
    }

    roundUp(num, precision) {
        precision = Math.pow(10, precision)
        return Math.ceil(num * precision) / precision;
    }

    formatAMPM(date) {
        let hours = date.getHours();
        let minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        return hours + ':' + minutes + ' ' + ampm;
    }

    setIsVip(info: any) {
        localStorage.setItem('is_vip', info);
    }

    getisVip(): any {
        return localStorage.getItem('is_vip');
    }

    setIsReferralCode(info: any) {
        localStorage.setItem('referral_code', JSON.stringify(info));
    }

    getisReferralCode(): any {
        return JSON.parse(localStorage.getItem('referral_code'));
    }

    getShippingAddress() {
        return JSON.parse(localStorage.getItem('selected_shipping_address'));
    }

    setShippingAddress(selected_shipping_address) {
        window.localStorage.setItem('selected_shipping_address', JSON.stringify(selected_shipping_address));
    }

    setNavBarStatus(data: any) {
        localStorage.setItem('showNavBar', data);
    }

    getNavBarStatus() {
        let showNavBar = localStorage.getItem('showNavBar');
        if (showNavBar == null || showNavBar == undefined) {
            localStorage.setItem('showNavBar', 'false');
            return false;
        } else {
            return JSON.parse(showNavBar);
        }
    }

    setLoginUserData(user: any) {
        localStorage.setItem('userDetails', JSON.stringify(user));
    }

    getLogInUserData(): any {
        return JSON.parse( localStorage.getItem('userDetails'));
    }

    removeLoginUserData(): any {
        localStorage.removeItem('userDetails')
    }

    setBrandInfo(data: any) {
        localStorage.setItem('brandInfo', JSON.stringify(data));
    }

    getBrandInfo(): any {
        return JSON.parse( localStorage.getItem('brandInfo'));
    }

    removeBrandInfo(): any {
        localStorage.removeItem('brandInfo')
    }

   
}
