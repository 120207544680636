import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ConfigService} from "../config/config.service";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(
      private http: HttpClient,
      private configService: ConfigService
  ) { }

  getHome(getParams?: any) {
    return this.configService.readRequest('home', getParams)
        .pipe(map(response => {
          return response;
        }));
  }

  landingPageAds(getParams?: any) {
    return this.configService.readRequest('landing-page-ads', getParams)
        .pipe(map(response => {
          return response;
        }));
  }

}
