<!-- <div class="account_heading pt-4">
  <ul class="d-flex flex-wrap align-items-center justify-content-center w-100 listing-page-routing">
    <li class="routing-list">
      <a>
        {{ "Boutique’s/Brands" | translate }}
      </a>
    </li>
  </ul>
</div> -->

<!-- skeletonLoader -->
<div class="my_account_data"  *ngIf="skeletonLoader && type == 'D' ">
  <div class="container-fluid">
    <section>
      <div class="account_heading pt-3 pb-4">
        <ul class="d-flex flex-wrap align-items-center justify-content-center w-100 listing-page-routing" >
          <ngx-skeleton-loader class="w-25"></ngx-skeleton-loader>   
        </ul>
      </div>
    </section>
    <section>
      <div class="d-flex w-100 justify-content-center w-100">
        <div class="user-left-menu d-none d-lg-block w-25">
          <div class="w-50">
            <ngx-skeleton-loader count="8"></ngx-skeleton-loader>
          </div>
        </div>
        <div class="user-profile-content w-100 w-lg-75">
        <section class="main-profile" id="brand-list">
          <div class="container-fluid">
            <!-- tabs skeleton -->
            <div class="w-25 mx-auto items-height-skeleton">
              <ngx-skeleton-loader class="height-fix"></ngx-skeleton-loader>
          </div>
            <div class="w-100 mx-auto items-height-skeleton">
              <ngx-skeleton-loader class="height-fix"></ngx-skeleton-loader>
            </div>
            <div class="designer-name-group" >
              <div class="designer-sec">
                <div
                  class="d-flex image-box-designer"
                  *ngFor="let item of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]" >
                  <div class="designer-image box-image">
                    <div class="fav-star-secs">
                    </div>
                    <ngx-skeleton-loader class="skeleton-image" count="" [theme]="setThemeLogic"></ngx-skeleton-loader>
                    <ngx-skeleton-loader class="designer-image-img" count="1"></ngx-skeleton-loader>
                  </div>
                  <p></p>
                </div>
              </div>
            </div>
          </div>
        </section>
        </div>
      </div>
    </section>
  </div>
</div>


<div class="my_account_data"  *ngIf="skeletonLoader && type == 'B' ">
  <div class="container-fluid">
    <section>
      <div class="account_heading pt-3 pb-4">
        <ul class="d-flex flex-wrap align-items-center justify-content-center w-100 listing-page-routing" >
          <ngx-skeleton-loader class="w-25"></ngx-skeleton-loader>   
        </ul>
      </div>
    </section>
    <section>
      <div class="d-flex w-100 justify-content-center w-100">
        <div class="user-left-menu d-none d-lg-block w-25">
          <div class="w-50">
            <ngx-skeleton-loader count="8"></ngx-skeleton-loader>
          </div>
        </div>
        <div class="user-profile-content w-100 w-lg-75">
        <section class="main-profile" id="brand-list">
          <div class="container-fluid">
            <!-- tabs skeleton -->
            <div class="w-25 mx-auto items-height-skeleton">
              <ngx-skeleton-loader class="height-fix"></ngx-skeleton-loader>
          </div>
            <div class="w-100 mx-auto items-height-skeleton">
              <ngx-skeleton-loader class="height-fix"></ngx-skeleton-loader>
            </div>
            <div class="brand-box mt-5" >
              <div *ngFor="let item of [1,2,3,4,5,6,7,8,9,10,11,12]"  class="brand-name-group">
                <div class="brand-name-letter">
                  <div class="skelton-circle-main">
                    <ngx-skeleton-loader class="skelton-circle"  count="1"></ngx-skeleton-loader>
                  </div>
                </div>
        
                <div class="brand-company-name">
                  <div *ngFor="let item of [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15]">
                    <div class="add-to-fav-section">
                      <ngx-skeleton-loader class="mange-star"  count="1"></ngx-skeleton-loader>
                      <ngx-skeleton-loader class="w-50"  count="1"></ngx-skeleton-loader>
                   </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        </div>
      </div>
    </section>
  </div>
</div>



<div class="my_account_data  " *ngIf="!skeletonLoader">
  <div class="container-fluid ">
    <section @listStagger>
      <div class="account_heading pt-3 pb-5">
        <ul
          class="d-flex flex-wrap align-items-center justify-content-center w-100 listing-page-routing"
        >
          <li class="routing-list">
            <a [routerLink]="'/my-account'">
              {{ "my account" | translate }}
            </a>
          </li>
          <li class="routing-list">
            {{ "My Favourite Boutique's/Brands" | translate }}
          </li>
        </ul>
      </div>

      <div class="d-flex w-100 justify-content-center w-100">
        <div class="user-left-menu d-none d-lg-block w-25">
          <app-user-account-menu [page]="9"></app-user-account-menu>
        </div>
        <div class="user-profile-content w-100 w-lg-75 ">
          <section
            *ngIf="dataAvailable && !skeletonLoader" class="main-profile " id="brand-list" @listStagger >
            <div class="container-fluid">
              <div class="mx-auto px-0">
                <ul
                  class="d-flex justify-content-center tab-main align-items-center nav nav-tabs"
                  role="tablist">
                  <li
                    class="tabs nav-item"
                    (click)="brandType('D')"
                    [ngClass]="{ active: type == 'D' }">
                    <a class="px-4 py-2 tab-item" id="home-tab-designer">{{"BOUTIQUE’S" | translate}}</a>
                  </li>
                  <li
                    class="tabs nav-item"
                    (click)="brandType('B')" [ngClass]="{ active: type == 'B' }">
                    <a class="px-4 py-2 tab-item brand-item" id="profile-tab">{{"BRANDS" | translate }}</a>
                  </li>
                </ul>
                <!-- <ul
                  class="d-flex justify-content-center tab-main-mid align-items-center nav nav-tabs mt-2 mb-2"
                >
                  <li class="tabs nav-item" (click)="subCategory(1)">
                    <a
                      class="px-2 py-2 tab-item"
                      *ngIf="brands.length"
                      id="home-tab-alphabet"
                      [ngClass]="{ active: subCategoryType == 1 }"
                    >
                      <span class="d-none d-md-block">
                        {{ "Find By A-Z" | translate }}
                      </span>
                      <span class="d-block d-md-none">
                        {{ "A-Z" | translate }}
                      </span>
                    </a>
                  </li>
                  <li
                    class="tabs nav-item"
                    (click)="subCategory(2)"
                    id="categoryTab"
                  >
                    <a
                      class="px-2 py-2 tab-item"
                      aria-controls="brand"
                      [ngClass]="{ active: subCategoryType == 2 }"
                    >
                      <span class="d-none d-md-block">
                        {{ "Filter by Category" | translate }}
                      </span>
                      <span class="d-block d-md-none">
                        {{ "Category" | translate }}
                      </span>
                    </a>
                  </li>
                  <li class="tabs nav-item" (click)="subCategory(3)">
                    <a
                      class="px-2 py-2 tab-item"
                      aria-controls="brand"
                      [ngClass]="{ active: subCategoryType == 3 }"
                    >
                      <span class="d-none d-md-block">
                        {{ "Search for a Brand" | translate }}
                      </span>
                      <span class="d-block d-md-none">
                        {{ "Search" | translate }}
                      </span>
                    </a>
                  </li>
                </ul> -->

                <!-- search by alphabets section -->
                <ul
                  class="d-flex flex-wrap align-items-center justify-content-center brand-alphabet"
                  *ngIf="subCategoryType == 1"
                >
                  <!-- Alphabets -->
                  <li *ngFor="let alphabet of englishAlphabetArr" class="col">
                    <div
                      [ngClass]="{
                        'letter-active': alpha == alphabet.toLowerCase()
                      }"
                      appSetActiveAlphabets
                      [words]="alphabetFirstChars"
                      class="text-center py-2 brand-letter"
                      (click)="scrollTo(alphabet.toLowerCase())"
                    >
                      <a>{{ alphabet }}</a>
                    </div>
                  </li>

                  <li
                    *ngIf="numberFirstChars.length > 0"
                    [ngClass]="{ hidden: toggleAlphabet }"
                  >
                    <div
                      class="text-center py-2 brand-letter brand-number"
                      (click)="changeChar()"
                    >
                      <a>0-9</a>
                    </div>
                  </li>
                  <!-- Numbers -->
                  <li
                    *ngFor="let number of numbersCount(9); let i = index"
                    class="col"
                    [ngClass]="{ hidden: !toggleAlphabet }"
                  >
                    <div
                      [ngClass]="{ 'letter-active': alpha == i }"
                      appSetActiveAlphabets
                      [words]="numberFirstChars"
                      class="text-center py-2 brand-letter"
                      (click)="scrollTo($event, i)"
                    >
                      <a>{{ i }}</a>
                    </div>
                    <div class="text-center py-2 brand-letter" *ngIf=""></div>
                  </li>
                  <li
                    *ngIf="alphabetFirstChars.length > 0"
                    [ngClass]="{ hidden: !toggleAlphabet }"
                  >
                    <div
                      class="text-center py-2 brand-letter brand-number"
                      (click)="changeChar()"
                    >
                      <a>A-Z</a>
                    </div>
                  </li>
                </ul>
                <!-- search by alphabets section -->

                <!-- search by categories section -->
                <div>
                  <ul
                    class="d-flex flex-wrap align-items-center justify-content-between justify-content-md-center categries-alphabet"
                    *ngIf="subCategoryType == 2"
                  >
                    <li class="" (click)="searchByCategory(category)">
                      <div
                        [ngClass]="{
                          'letter-active': selectedCategory?.name == 'All'
                        }"
                        class="text-center tabs-color-categries py-2 cursor-pointer"
                      >
                        <a (click)="clearCategories()">{{
                          "All" | translate
                        }}</a>
                      </div>
                    </li>
                    <li
                      class=""
                      *ngFor="let category of categories"
                      (click)="searchByCategory(category)"
                    >
                      <div
                        [ngClass]="{
                          'letter-active':
                            selectedCategory?.name == category.name
                        }"
                        class="text-center tabs-color-categries py-2 cursor-pointer"
                      >
                        <a> {{ category.name }}</a>
                      </div>
                    </li>
                  </ul>
                </div>
                <!-- search by categories section -->

                <!-- search by brand name section -->
                <div
                  class="d-flex flex-wrap align-items-center justify-content-start justify-content-md-center search-box gap-2 py-2 position-relative"
                  *ngIf="subCategoryType == 3"
                >
                  <img
                    src="../../assets/icons/new-search-icon.svg"
                    alt=""
                    class="search-icon"
                  />
                  <input
                    type="text"
                    [placeholder]="'Search by Boutique' | translate"
                    type="text" [(ngModel)]="name" (input)="searchByBrandName(name)"/>
                  <span
                    class="position-absolute close-search"
                    (click)="clearSearch()" >
                    <img src="../../assets/icons/cross_popup.svg" alt="" />
                  </span>
                </div>
                <!-- search by brand name section -->
              </div>
            </div>
            <div
              class="brand-company-name searchGrid brand-box container-fluid"
              *ngIf="!toSearch" >
              <div *ngFor="let brand of searchData" class="mt-4">
                <p class="brand-name" (click)="routeAsPerTier(brand)">
                  {{ brand.name }}
                </p>
                <!-- {{brand.tier_id}},{{brand.tier_name}} -->
              </div>
            </div>
            <!-- {{groups | json}}, {{brands?.length | json}} {{brands | json}} -->
            <div *ngIf="brands?.length > 0" class="tab-content" id="myTabContent" >
              <div class="tab-pane" id="brand" [ngClass]="{ 'd-block': type == 'B' }">
                <section
                  class="container-fluid brand-group-main my-div-height"
                  *ngIf="brands.length">
                  <div class="brand-box">
                    <div
                      *ngFor="let alphabet of groups"
                      id="{{ alphabet.toLowerCase() }}"
                      class="brand-name-group mb-2">
                      <div class="brand-name-letter">
                        <a name="{{ alphabet }}">{{ alphabet }}</a>
                      </div>

                      <!-- don't remove the class 'brand-list-name' it is used in typeScript -->
                      <div class="brand-company-name">
                        <div
                          *ngFor="let brand of brands"
                          [ngClass]="{
                            hidden:
                              brand?.name?.charAt(0).toLowerCase() !=
                              alphabet.toLowerCase()}">
                          <div class="add-to-fav-section">
                            <img
                              id="fav-star"
                              (click)="toggleBrandAsFavorite(brand.id)"
                              src="assets/icons/fav-brand-star-filled.svg"
                              alt="fav-star"/>
                            <!-- <img id="fav-star" src="assets/icons/fav-brand-star-filled.svg" alt="fav-star"  > -->
                            <a
                               [routerLink]="url? url : brand.tier_id == 1 ? ['/home', configSettings.cleanUrl(brand.name), brand.id, brand.tier_id] : ['/brands', configSettings.cleanUrl(brand.name), brand.id, brand.tier_id]" target="_blank" class="brand-name brand-list-name" [id]="'list-name'+alphabet.toLowerCase()" (click)="routeAsPerTier($event, brand)">
                              {{ brand.name | titlecase }}
                            </a>
                          </div>

                          <!-- {{brand.tier_id}},{{brand.tier_name}} -->
                        </div>
                      </div>                      
                    </div>
                  </div>
                  <div class="edit_data_footer text-center">
                    <button class="button_hover" type="button" (click)="navigateToFav('B')">{{'Shop your favourite'|translate}}</button>
                  </div>
                </section>
              </div>
              <div  class="tab-pane" id="designer" [ngClass]="{ 'd-block': type == 'D' }">
                <section class="container-fluid my-div-height">
                  <div class="designer-name-group">
                    <div
                      class="designer-sec"
                      id="designerSection"
                      *ngIf="brands?.length > 0">
                      <div
                        class="d-flex image-box-designer box-image"
                        *ngFor="let design of brands"
                        [attr.data-letter]="design.name[0].toLowerCase()">
                        <div class="fav-star-sec">
                      <img
                        id="fav-star"
                        (click)="toggleBrandAsFavorite(design.id)"
                        src="assets/icons/fav-brand-star-filled.svg"
                        alt="fav-star"/>
                      </div>
                        <div class="designer-image"  (click)="routeAsPerTier(design)">
                          <img src="{{ design.logo }}" alt="" />
                        </div>
                        <p  (click)="routeAsPerTier(design)">{{ design.name }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="edit_data_footer text-center">
                    <button class="button_hover" type="button" (click)="navigateToFav('D')" >{{'Shop your favourite'|translate}}</button>
                  </div>
                </section>
              </div>
            </div>
          </section>


          <section class="h-75 d-flex justify-content-center align-items-center my-div-height" *ngIf="brands?.length == 0" >
            <div class="Emptybag" *ngIf="brands?.length == 0">
              <p class="no-item-text">{{'Your Favourite Boutique’s/Brands is Empty' | translate}} </p>
            </div>
          </section>
        </div>
      </div>
    </section>
  </div>
</div>

