<!-- skeleton loader starts-->
<div class="checkout_background" *ngIf="skeletonLoader && checkItemStockData==undefined">
  <div class="my_account_data">
    <div class="container-fluid">
      <div class="checkout_box">
        <div class="d-flex checkout-box-main gap-3 w-100">
          <div class="product_data_details">
            <div class="my_bag_box">
              <!-- <div class="d-flex justify-content-between"> -->
              <ngx-skeleton-loader class="main-hero-card-img" [theme]="{
                'border-radius': '5px',
                height: '100px',
                'animation-duration': '2s'}"></ngx-skeleton-loader>
              <!-- </div> -->

              <div class="step_box mt-3" *ngFor="let item of counter(3)">
                <ngx-skeleton-loader class="main-hero-card-img" [theme]="{
              'border-radius': '5px',
              height: '700px',
              'animation-duration': '2s'}"></ngx-skeleton-loader>
              </div>
            </div>
          </div>
          <div class="payment_box">
            <div class="checkout_payment_box">
              <ngx-skeleton-loader class="main-hero-card-img" [theme]="{
            'border-radius': '5px',
            height: '250px',
            'animation-duration': '2s'}"></ngx-skeleton-loader>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- skeleton loader ends-->
<div class="checkout_background" *ngIf="!skeletonLoader && checkItemStockData!=undefined">
  <div class="my_account_data">
    <div class="container-fluid" *ngIf="checkItemStockData!=undefined">
      <div class="checkout_box">
        <div class="d-flex checkout-box-main  gap-3 w-100">
          <div class="product_data_details">
            <div class="my_bag_box">
              <div class="d-flex justify-content-between align-items-center">
                <div>
                  <div class="d-flex gap-4">
                    <div>
                      <img class="my_bag" src="/assets/icons/bag-my.svg" alt="bag">
                    </div>
                    <div>
                      <h2>{{'My Bag' | translate | uppercase }} [ {{checkItemStockData?.cart.items.length}} {{'item' |
                        translate }} ]</h2>
                      <p>{{currency}} {{cartValue | number:'1.3-3'}}</p>
                    </div>
                  </div>
                </div>
                <div data-toggle="modal" data-target="#reviewModal">
                  <span>{{'Review' | translate}}</span>
                </div>
              </div>
            </div>

        <div class="step_box mt-3" >
          <div class="form_padding">
            <div class="step_box_header py-3">
              <div class="d-flex justify-content-between align-items-center">
                <div class="d-flex gap-3 align-items-center">
                  <div>
                    <div class="number_count" *ngIf="!showTick1">
                    <span >1</span>
                  </div>
                    <img *ngIf="showTick1" src="/assets/images/greenTick.svg" alt="" class="green-image">
                  </div>
                  <div>
                    <h2>{{'SHIPPING ADDRESS' | translate }}</h2>
                  </div>
                </div>
                <div *ngIf="!showAddressForm && showTick1"  (click)="editData('add')">
                  <p>{{'Edit' | translate }}</p>
                </div>
              </div>
            </div>    

            <div class="address_data_added-details-sec" *ngIf="showTick1">
              <h2 class="address_data-name">{{selectedAddress.first_name}} {{selectedAddress.last_name}}</h2>
              <div class="address_data_filed" >

                <p class="user-address-show">
                  <span *ngIf="selectedAddress?.country_name?.toLowerCase()!=''">{{ selectedAddress?.country_name?.toLowerCase() }},</span><span *ngIf="selectedAddress.governorate_name!=''">{{ selectedAddress.governorate_name }}</span>
                </p>

                <p class="user-address-show">
                  <span *ngIf="selectedAddress.area_name!=''">{{ selectedAddress.area_name }}, </span> <span *ngIf="selectedAddress.block_name!=''"> {{ selectedAddress.block_name }}, </span>  <span *ngIf="selectedAddress.block_id!=''"> {{ selectedAddress.block_id }}</span>
                </p>

                <p class="user-address-show">
                  <span *ngIf="selectedAddress.street!=''">{{ selectedAddress.street }}, </span> <span *ngIf="selectedAddress.avenue!=''"> {{ selectedAddress.avenue }}, </span>
                  <span *ngIf="selectedAddress.addressline_1!=''"> {{ selectedAddress.addressline_1 }}, </span> <span *ngIf="selectedAddress.building!=''"> {{ selectedAddress.building}}, </span> <span *ngIf="selectedAddress.floor!=''"> {{ selectedAddress.floor}}, </span> <span *ngIf="selectedAddress.flat!=''"> {{ selectedAddress.flat }}</span>
                </p>

                <p class="user-address-show p-0">
                  <span *ngIf="selectedAddress.landmark!=''">{{ selectedAddress.landmark }}, </span> <span *ngIf="selectedAddress.id_number!=''"> {{ selectedAddress.id_number }} </span>
                </p>

                <h6 *ngIf="selectedAddress.notes!=''" class="notes p-0 my-2">{{'Note:' | translate}}{{ selectedAddress.notes }}</h6>
                <p class="mobilenumber p-0" *ngIf="selectedAddress.mobile_number!=''">+{{selectedAddress.phonecode}} {{ selectedAddress.mobile_number }}</p>                          
              </div>            
            </div>   

            <div *ngIf="!hide1"> 
              <div class="checkout_add_address" *ngIf="addresses.length>0">
                <div class="checkout_address">
                  <div class="address_button">
                    <button type="button" id="addDelivery"  (click)="addDeliveryAddress()"><img src="/assets/icons/plus.svg" alt=""> {{'ADD NEW ADDRESS' | translate }}</button>
                  </div>
                  <div class="address_main">
                    <div class="address_main_list" *ngFor="let address of addresses;index as i">
                      <div class="d-flex justify-content-between gap-2 w-100" >
                        <label for="{{address.address_id}}" >
                          <div class="address_data_added">
                            <h2>{{address.first_name}} {{address.last_name}}</h2>
                            <div class="address_data_filed" >                              
                              <p class="user-address-show"> <span *ngIf="address.country_name.toLowerCase()!=''">{{ address.country_name.toLowerCase() }}, </span> <span *ngIf="address.governorate_name"> {{ address.governorate_name }}</span></p> 
                              <p class="user-address-show">
                                <span *ngIf="address.area_name!=''">{{ address.area_name }}, </span> <span *ngIf="address.block_name!=''"> {{ address.block_name }}, </span>  <span *ngIf="address.block_id!=''"> {{ address.id }}</span>                               
                              </p>
                              <p class="user-address-show">
                                <span *ngIf="address.street!=''">{{ address.street }}, </span> <span *ngIf="address.avenue!=''"> {{ address.avenue }}, </span>
                                <span *ngIf="address.addressline_1!=''"> {{ address.addressline_1 }}, </span> <span *ngIf="address.building!=''"> {{ address.building}}, </span> <span *ngIf="address.floor!=''"> {{ address.floor}}, </span> <span *ngIf="address.flat!=''"> {{ address.flat }}</span>
                              </p>
                              <p class="user-address-show p-0">
                                <span *ngIf="address.landmark!=''">{{ address.landmark }}, </span> <span *ngIf="address.id_number!=''"> {{ address.id_number }} </span>
                              </p>
                              <p class="user-address-show p-0 my-2">                                  
                                 <span *ngIf="address.notes!=''" class="notes">{{'Note:' | translate}} {{ address.notes }}</span>
                              </p>  
                              <p class="mobile_number p-0" *ngIf="address.mobile_number!=''"> +{{address.phonecode}} {{ address.mobile_number }}</p>                    
                            </div>
                            <div class="address_data_button">
                              <button type="button"  (click)="editAddress(address)">{{ "Edit" | translate }}</button>
                            </div>
                          </div>
                        </label>
                        <input type="radio" id="{{address.address_id}}" name="featured"  [checked]="address.is_default == 'Yes' || selectedAddress.address_id==address.address_id" (click)="selectAddress('address_'+i, i)" >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            
              <div class="edit_data" *ngIf="showAddressForm" >
                <div class="edit_data_header">
            
                  <!-- <div class="shipping_to" >
                    <p>
                      {{ "Shipping from " | translate }}
                      <img src="../../assets/icons/kuwait_flag.svg" alt="flag" />
                         {{ "kuwait" | translate }}
                    </p>
                  </div> -->
                    </div>
                    <div *ngIf="showAddressForm">
                      <app-new-address-popup (resetAddressList)="getUserAddress()"
                        (displayPopupEvent)="closePopup()"></app-new-address-popup>
                    </div>
                  </div>
                  <div class="edit_data_footer text-center" *ngIf="addresses.length>0">
                    <button type="submit" (click)="showTabs(1)">
                      {{ "CONTINUE" | translate }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div [ngClass]="!showTick1 && !orderReview ?'disableTabs' : ''">
            <div class="step_box mt-3" >
              <div class="form_padding">
                <div class="step_box_header py-3">
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="d-flex gap-3 align-items-center">
                      <div>
                        <div class="number_count" *ngIf="!showTick2">
                          <span >2</span>
                        </div>
                        <img *ngIf="showTick2" src="/assets/images/greenTick.svg" alt="" class="green-image">
                      </div>
                      <div>
                        <h2>{{'ORDER REVIEW' | translate }}</h2>
                      </div>
                    </div>
                    <div *ngIf="showTick2" (click)="editData('review')">
                      <p>{{'Edit' | translate }}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="!hide2">
                <div class="order_review">
                  <div class="order_review_data">
                    <div class="order_gift" style="cursor: pointer;" (click)="sendGift()">
                      <div class="d-flex justify-content-between align-items-center gap-3">
                        <div>
                          <div class="d-flex align-items-start gap-3">
                            <img src="/assets/icons/gift.svg" class="gift_icon" alt="gift">
                            <div>
                              <h2 *ngIf="!showGiftDetails">{{'Send as a gift' | translate }} ({{currency}} {{checkItemStockData.gift_charges | number:'1.3-3'}})</h2>
                              <h2 *ngIf="showGiftDetails">{{'Gifting Details' | translate }}</h2>

                              
                            </div>
                            <!-- will data after submit here -->
                            
                          </div>

                        </div>
                        <div>
                          <img *ngIf="!is_gift && !showGiftDetails" src="/assets/icons/plus.svg" class="plus_icon"
                            alt="plus">
                          <img *ngIf="is_gift" src="/assets/icons/minus.svg" class="plus_icon" alt="plus">
                          <p *ngIf="showGiftDetails" class="gift-edit" (click)="editGift()">Edit</p>
                        </div>

                        
                      </div>
                      <div *ngIf="showGiftDetails" class="showgift">
                        <div class="d-flex gap-2 align-items-center ">
                          <h4 class="gift-name">{{form.controls.receiverName.value}}</h4>
                          <p>{{form.controls.receiverPhone.value}}</p>
                        </div>
                        <div class="d-flex gap-2 align-items-center">
                          <h4>{{'Gift Card Message' | translate}}</h4>
                          <p>{{form.controls.receiverGift.value}}</p>
                        </div>
                        <div class="d-flex gap-2 align-items-center">
                          <h4>{{'Package Sticker' | translate}}</h4>
                          <p>{{form.controls.receiverSticker.value}}</p>
                        </div>
                        <div class="d-flex gap-2 align-items-center">
                          <h4>{{'Hide Invoice' | translate}}</h4>
                          <p>{{hide_invoice?'Yes':'No'}}</p>
                        </div>
                        <div class="d-flex gap-2 align-items-center">
                          <h4>{{'Anonymous Delivery' | translate}}</h4>
                          <p>{{hide_invoice?'Yes':'No'}}</p>
                        </div>
                      </div>

                    </div>

                    <div class="" *ngIf="is_gift">
                      <form [formGroup]="form" (ngSubmit)="submitGift()">
                        <div class="form_padding p-0 pb-4">
                          <div class="edit_data">
                            <div class="edit_data_body mt-0">
                              <div class="edit_data_body-cols">
                                <label for="" class="d-flex">{{'Receivers Name' | translate}}<span>*</span></label>
                                <input type="text" formControlName="receiverName">
                                <small class="text-danger" *ngIf="form.controls.receiverName.touched && form.controls.receiverName.invalid">
                                  {{ 'Please enter name' | translate }}
                                </small>
                              </div>
                              <div class="edit_data_body-cols">
                                <label for="" class="d-flex">{{'Receivers Mobile Number' | translate}}<span>*</span></label>
                                <input type="text" formControlName="receiverPhone" [maxlength]="10">
                                <small class="text-danger" *ngIf="form.controls.receiverPhone.touched && form.controls.receiverPhone.errors?.numberOnly">
                                  {{ 'Please enter a valid mobile number' | translate }}
                                </small>
                              </div>
                              <div class="edit_data_body-cols">
                                <label for="" class="d-flex">{{'Package Sticker' | translate}}<span>*</span></label>
                                <input type="text" formControlName="receiverSticker">
                                <small class="text-danger" *ngIf="form.controls.receiverSticker.touched && form.controls.receiverSticker.invalid">
                                  {{ 'Please enter a sticker' | translate }}
                                </small>
                              </div>
                            </div>
                            <div class="edit_note">
                              <div>
                                <label for="" class="d-flex">{{'Gift Card Message' | translate}}<span>*</span></label>
                                <textarea type="text" formControlName="receiverGift"></textarea>
                                <div class="small text-danger" *ngIf="form.controls.receiverGift.touched && form.controls.receiverGift.invalid || form.controls.length > 140">
                                  {{'Message cannot be more than 140 characters' | translate}}</div>
                                <small class="text-danger" *ngIf="form.controls.receiverGift.touched && form.controls.receiverGift.invalid && form.controls.length == 0">
                                  {{ 'Please enter a gift' | translate }}
                                </small>
                              </div>
                            </div>
                            <div class="SEND-GIFT displyInlineFlex gap-2 mt-2 round">
                              <input type="checkbox" id="html" formControlName="hideInvoice" (click)="hideInvoice()">
                              <label for="html" class="giftLabel">{{'Hide Invoice' | translate}}</label>
                            </div>
                            <div class="SEND-GIFT displyInlineFlex gap-2 mt-2 round">
                              <input type="checkbox" id="hideGiftPhone" formControlName="hideGiftPhone" (click)="isAnonymousdelivery()">
                              <label for="hideGiftPhone" class="giftLabel">{{"I don't know the address, contact the receiver" | translate}}</label>
                            </div>
                            <div class="edit_data_footer text-center pt-4 edit_footer-manage">
                              <button type="submit" [disabled]="!form.valid" [ngClass]="{'disable-button-styles': !form.valid}">{{'Submit'|translate}}</button>
                            </div>
                          </div>
                        </div>
                      </form>                      
                    </div>

                    <div class="order_promo_code">
                      <div class="d-flex gap-3">
                        <img src="/assets/icons/offer.svg" class="gift_icon" alt="gift">
                        <h2>{{'Do you have a promo code?'|translate}}</h2>
                      </div>
                      <div class="d-flex justify-content-between align-items-center py-3">
                        <input type="text" placeholder="{{'Promo Code' | translate}}"
                          class="form-control text-input placeholder-sm" [(ngModel)]="promo_code" name="promoCode"
                          id="promoCodeName" aria-describedby="promoCodeHelp" #promoCodeParam="ngModel" required>
                        <button type="button" *ngIf="!promotionApplied" (click)="applyPromotion()">{{'APPLY'|translate}}</button>
                        <button type="button" *ngIf="promotionApplied" (click)="removePromotion()">{{'REMOVE'|translate}}</button>
                      </div>
                    </div>

                    <div class="order_promo_code" *ngIf="(wallet_balance>0) || (wallet?.wallet_amount_total > 0)">
                      <div class="d-flex gap-3">
                        <img src="/assets/icons/wallet.svg" class="gift_icon" alt="gift">
                        <h2>{{'My Wallet' | translate}}</h2>
                      </div>
                      <div class="d-flex justify-content-between align-items-center py-3">
                        <p class="d-flex gap-1 align-items-baseline"> {{'My Balance' | translate}}: <span
                            *ngIf="wallet_balance">{{wallet_balance}}</span><span
                            *ngIf="!wallet_balance">{{wallet.wallet_amount_total}}</span> <span>{{currency}}</span> </p>

                        <button type="button" (click)="applyWallet()" *ngIf="!walletUsed">{{'USE'|translate}}</button>
                        <button type="button" (click)="removeWallet()" *ngIf="walletUsed">{{'REMOVE'|translate}}</button>
                      </div>
                    </div>

                  </div>
                </div>

                <div class="edit_data_footer text-center">
                  <button type="submit" (click)="showTabs(2)">
                    {{ "CONTINUE" | translate }}
                  </button>
                </div>
              </div>


            </div>
          </div>

            <div class="step_box mt-3" [ngClass]="!showTick2 && !orderReview ? 'disableTabs' : ''">
              <div class="form_padding">
                <div class="step_box_header py-3">
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="d-flex gap-3 align-items-center">
                      <div>
                        <div class="number_count" *ngIf="!showTick3">
                          <span>3</span>
                        </div>
                        <img *ngIf="showTick3" src="/assets/images/greenTick.svg" alt="" class="green-image">
                      </div>
                      <div>
                        <h2>{{'PAYMENT' | translate }}</h2>
                      </div>
                    </div>
                    <div *ngIf="showTick3" (click)="editData('pay')">
                      <p>{{'Edit' | translate }}</p>
                    </div>
                  </div>
                </div>
                <div class="order_checkout_method" *ngIf="orderReview && hide3 && checkItemStockData.total !=0">
                  <div class="d-flex gap-3 align-items-center">
                    <img [src]="selectedPayment?.icon" alt="">
                    <p class="payment-mode">{{selectedPayment?.type}}</p>

                  </div>
                </div>
              </div>

              <div class="order_review">
                <div class="order_review_data">

                  <div [hidden]="hide3">
                    <div class="order_gift payment_order">
                      <div class="d-flex justify-content-between align-items-center gap-3">
                        <div class="w-100" style="cursor: pointer;">
                          <div class="d-flex gap-3">
                            <img src="/assets/icons/payment_checkout.svg" class="gift_icon" alt="gift">
                            <h2>{{'Payment methods'|translate}}</h2>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div *ngFor="let payment of paymentOptions; let i = index;">

                      <div class="order_checkout_method" *ngIf="payment.is_enable == 1">
                        <label for="paymentm-{{payment.code}}" (click)="selectpayment('payment_'+i, i)"
                          class="d-flex justify-content-between align-items-center w-100">
                          <div class="w-100" style="cursor: pointer;">
                            <div class="d-flex gap-3 align-items-center">
                              <input type="radio" id="paymentm-{{payment.code}}" name="featured-payment"
                                value="{{payment.code}}" (click)="selectpayment('payment_'+i, i)" />
                              <span>{{payment.type}}</span>
                            </div>
                          </div>
                          <img [src]="payment.icon" alt="">
                        </label>
                      </div>

                      <!-- <div class="order_checkout_method">
                         <label class="d-flex justify-content-between align-items-center w-100">
                           <div class="w-100" style="cursor: pointer;">
                             <div class="d-flex gap-3 align-items-center">
                           <input type="radio" name="radio" />
                           <span>Cash On Delivery</span>
                         </div>
                         </div>
                           <img src="/assets/icons/kent_checkout.svg" alt="">
                         </label>
                        </div>
             
                        <div class="order_checkout_method">
                         <label class="d-flex justify-content-between align-items-center w-100">
                           <div class="w-100" style="cursor: pointer;">
                             <div class="d-flex gap-3 align-items-center">
                           <input type="radio" name="radio" />
                           <span>Visa / Mastercard</span>
                         </div>
                         </div>
                           <img src="/assets/icons/master_checkout.svg" alt="">
                         </label>
                        </div>
             
                        <div class="order_checkout_method">
                         <label class="d-flex justify-content-between align-items-center w-100">
                           <div class="w-100" style="cursor: pointer;">
                             <div class="d-flex gap-3 align-items-center">
                           <input type="radio" name="radio" />
                           <span>Other Payment Methods</span>
                         </div>
                         </div>
                           <img src="/assets/icons/payment_checkout.svg" alt="">
                         </label>
                        </div>
             
                        <div class="order_checkout_method">
                         <label class="d-flex justify-content-between align-items-center w-100">
                           <div class="w-100" style="cursor: pointer;">
                             <div class="d-flex gap-3 align-items-center">
                           <input type="radio" name="radio" />
                           <span>Pay in 3 Instalments: No Fees</span>
                         </div>
                         </div>
                           <img src="/assets/icons/tabby_checkout.svg" alt="">
                         </label>
                        </div>
             
                        <div class="order_checkout_method">
                         <label class="d-flex justify-content-between align-items-center w-100">
                           <div class="w-100" style="cursor: pointer;">
                             <div class="d-flex gap-3 align-items-center">
                           <input type="radio" name="radio" />
                           <span>Pay in 4 Instalments: No Fees</span>
                         </div>
                         </div>
                           <img src="/assets/icons/tabby_checkout.svg" alt="">
                         </label>
                        </div>
             
                        <div class="order_checkout_method">
                         <label class="d-flex justify-content-between align-items-center w-100">
                           <div class="w-100" style="cursor: pointer;">
                             <div class="d-flex gap-3 align-items-center">
                           <input type="radio" name="radio" />
                           <span>Pay in 5 Instalments: No Fees</span>
                         </div>
                         </div>
                           <img src="/assets/icons/tabby_checkout.svg" alt="">
                         </label>
                        </div> -->

                    </div>
                    <div class="edit_data_footer text-center edit_footer-manage">
                      <button type="submit" (click)="reviewOrder()">
                        {{ "REVIEW ORDER" | translate }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div class="payment_box">
            <div class="checkout_payment_box">
              <h2>{{'ORDER SUMMARY' | translate }}</h2>
              <div class="payment_data">
                <div class="d-flex justify-content-between align-items-center ">
                  <p>{{'Sub - Total' | translate }}</p>
                  <p>{{currency}} {{cartValue | number:'1.3-3'}}</p>
                </div>
                <div class="d-flex justify-content-between align-items-center mt-2 green-color" *ngIf="discount > 0">
                  <p>{{'Discount' | translate }}</p>
                  <p>{{currency}} {{discount | number:'1.3-3'}}</p>
                </div>
                <div class="d-flex justify-content-between align-items-center mt-2 green-color" *ngIf="walletUsed">
                  <p>{{'Wallet' | translate }}</p>
                  <p>{{currency}} {{wallet.wallet_amount_applied | number:'1.3-3'}}</p>
                </div>
                <div class="d-flex justify-content-between align-items-center mt-2 green-color" *ngIf="checkItemStockData?.loyalty_saving > 0">
                  <p>{{'Special Savings' | translate }}</p>
                  <p>-{{currency}} {{checkItemStockData?.loyalty_saving | number:'1.3-3'}}</p>
                </div>
                <div class="d-flex justify-content-between align-items-center mt-2" *ngIf="codCost!=0">
                  <p>{{'Cod' | translate }}</p>
                  <p>{{currency}} {{codCost | number:'1.3-3'}}</p>
                </div>
                <div class="d-flex justify-content-between align-items-center mt-2 deliverly" *ngIf="shippingCost > 0">
                  <p>{{'Delivery' | translate }}</p>
                  <p>{{currency}} {{shippingCost | number:'1.3-3'}}</p>
                </div>
                <div class="d-flex justify-content-between align-items-center mt-2" *ngIf="showGiftDetails">
                  <p>{{'Gift Charges' | translate }}</p>
                  <p>{{currency}} {{checkItemStockData.gift_charges | number:'1.3-3'}}</p>
                </div>
                <div class="d-flex justify-content-between align-items-center checkout_border mt-3">
                  <h2>{{'Total' | translate }}</h2>
                  <h2 *ngIf="showGiftDetails">{{currency}} {{(total) | number:'1.3-3'}}</h2>
                  <h2 *ngIf="!showGiftDetails">{{currency}} {{total| number:'1.3-3'}}</h2>
                </div>
                <p *ngIf="(discount > 0)">{{'You are saving' | translate }} <span>- {{currency}} {{discount | number:'1.3-3'}}</span></p>
                <button type="button" (click)="proceedToCheckout()" id="BUYNOW" [ngClass]="orderReview?'':'dontBuyNow'">
                  <span class="lock-image"><img src="../../assets/icons/gray-lock.svg" alt=""></span>
                  {{'BUY NOW' | translate }}
                </button>
                <!-- <span>Our checkout is safe and secure. Your personal and payment information is securely transmitted via 128-bit encryption. We do not store any payment card information on our system.</span> -->
              </div>
            </div>
          </div>

        </div>

        <div class="d-flex justify-content-center align-items-center p-2 scroll-to-top-div " hidden>
          <button class="scroll-to-top-button" (click)="scrollToTop()" hidden>
            <img src="/assets/images/arrow-up.png" class="scroll-to-top-img">
            <span class="scroll-to-top-title">{{'BACK TO TOP' | translate}}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="" *ngIf="showLoading">
  <app-loading></app-loading>
</div>
<app-review-popup [products]="checkItemStockData?.cart.items"></app-review-popup>
<div class="model-background" *ngIf="displayPopup">
  <div class="background-overly"  (click)="closePopup()"></div>
  <app-new-address-popup (resetAddressList)="getUserAddress()"
    (displayPopupEvent)="closePopup()"></app-new-address-popup>
</div>