import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appClickToFavBrandsListing]'
})
export class ClickToFavBrandsListingDirective {

  @Input() appClickToFavBrandsListing: number = 0;

  constructor(private el: ElementRef) {}

  @HostListener('click') onClick() {
    const imageElement = this.el.nativeElement as HTMLImageElement;
    if (this.appClickToFavBrandsListing === 0) {
      imageElement.src = 'assets/icons/fav-brand-star-filled.svg';
      this.appClickToFavBrandsListing = 1;
    } else {
      imageElement.src = 'assets/icons/fav-brand-star.svg';
      this.appClickToFavBrandsListing = 0;
    }
  }
}
