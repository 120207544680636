import { Injectable } from '@angular/core';
import { ConfigService } from '../config/config.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AddressApiService {

  baseRoute: string = ''

  constructor(
    private configServices:ConfigService
  ) { }
  postAddAddress(getParams?:any,postParams?:any){
    return this.configServices.postRequest('add-address',getParams,postParams).pipe(map(response=>{
      return response;
    }))
  }
  getListAddress(getParams?:any){
    return this.configServices.readRequest('list-address',getParams).pipe(map(response=>{
      return response;
    }))
  }
  getAddressDetails(getParams?:any){
    return this.configServices.readRequest('address-detail',getParams).pipe(map(response=>{
      return response;
    }))
  }
  postEditAddress(getParams?:any,postParams?:any){
    return this.configServices.postRequest('update-address',getParams,postParams).pipe(map(response=>{
      return response;
    }))
  }
  postDeleteAddress(getParams?:any,postParams?:any){
    return this.configServices.postRequest('delete-address',getParams,postParams).pipe(map(response=>{
      return response;
    }))
  }
  postSetDefaultAddress(getParams?:any,postParams?:any){
    return this.configServices.postRequest('set-default-address',getParams,postParams).pipe(map(response=>{
      return response;
    }))
  }
}
