import {Component, Input, OnInit} from '@angular/core';
import {LocalStorage} from '@ngx-pwa/local-storage';
import { User } from '../models/user';
import {UserService} from "../config/user.service";
import { SocialAuthService , SocialUser } from "@abacritt/angularx-social-login";
import { WishlistService } from '../config/wishlist.service';
import { CartService } from '../config/cart.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../dialog/dialog.component';
import { ConfigSettings } from '../config/config.settings';
import { UpdateWishlistCountService } from '../services/update-wishlist-count.service';

@Component({
  selector: 'app-user-account-menu',
  templateUrl: './user-account-menu.component.html',
  styleUrls: ['./user-account-menu.component.scss']
})
export class UserAccountMenuComponent implements OnInit {
  @Input() page: number;
  user: User;
  constructor(
    protected localStorage: LocalStorage,
    private userService: UserService,
    private authService: SocialAuthService ,
    private wishlistService: WishlistService,
    private cartService: CartService,
    private dialog: MatDialog,
    public configSettings: ConfigSettings,
    private updateWishlistCountService : UpdateWishlistCountService
  ) { }

  ngOnInit() {
    this.userService.getUserSession().then(response => {
        if(response !== false) {
            this.user = response;
        }
    });
  }

  logout() {
    if(this.user.is_social_register){
      this.authService.signOut();
    }
    this.configSettings.removeLoginUserData();
        this.localStorage.removeItem('userInfo').subscribe(() => {});
    this.wishlistService.changWishlistCount(0);
    this.cartService.changCartCount(0); 
    this.updateWishlistCountService.removeFromWishlist()

     window.location.href = '/';  
  }

  showLogoutModal() {
    let dialogRef = this.dialog.open(DialogComponent, {
        data: {logout: true, user: this.user },
    });
  }
}
