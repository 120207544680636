import { Component, OnInit } from '@angular/core';
import {FooterService} from "../config/footer.service";
import {FormBuilder, FormGroup, NgForm} from "@angular/forms";
import {ConfigSettings} from "../config/config.settings";
import {first} from "rxjs/operators";
import {AuthenticationService} from "../config/authentication.service";
import {CryptService} from "../config/crypt.service";
import {LocalStorage} from "@ngx-pwa/local-storage";
import {CartService} from "../config/cart.service";
import {ActivatedRoute} from "@angular/router";
import { listStagger } from '../animations';
import {SupportDetails} from '../models/support-details';
import {ConfigService} from '../config/config.service';
import {AnalyticsService} from '../services/analytics.service';
import {User} from '../models/user';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css'],
  animations: [ listStagger ]
})
export class RegisterComponent implements OnInit {

  lang: string;
  first_name: string = '';
  last_name: string = '';
  email: string = '';
  phone: string = '';
  password: string = '';
  support_email = '';
  support_phone = '';
  confirm_password: string;
  referred_by_code: string = '';
  registerSubmitted = false;
  registerHasError = false;
  registerMessage: string;
  device: string;
  store_code: string;
  returnUrl: string;
  guest_register: boolean = false;
  passwordValid :boolean = true;
  btnDisabled :boolean = false;
  phoneValidationPattern = "\\d{8,}";
  hasError = false;
  isEmailValid = true;

  countryCode = '+965'

  constructor(
      private route: ActivatedRoute,
      protected footerService: FooterService,
      private configSettings: ConfigSettings,
      private authenticationService: AuthenticationService,
      private cryptService: CryptService,
      protected localStorage: LocalStorage,
      private cartService: CartService,
      private configService: ConfigService,
      private analytics: AnalyticsService
  ) { }

  ngOnInit() {
      this.lang = this.configSettings.getLang();
      this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
      if (this.returnUrl !== '/') {
          this.guest_register = true;
      }
      this.configSettings.getStoreObject().then((result) => {
            this.store_code = result.iso_code;
        });
      this.device = this.configSettings.getBrowserVersion();
      setTimeout(() => {this.footerService.toggleFooter(true)}, 300);
      this.localStorage.getItem<any>('support_details').subscribe((result) => {
        if (result) {
          const SupportDetails = <SupportDetails> result;
          this.support_email = SupportDetails.support_email;
          this.support_phone = SupportDetails.support_phone;
        }
      });

      this.localStorage.getItem<any>('store_object').subscribe((result) => {
        if (result) {
            this.countryCode = '+' + (result as any).phonecode;
        }
      });
  }

    onSubmit(registerForm: NgForm) {
      if (registerForm.form.valid) {
          this.configSettings.toggleLoading(true);
          this.doRegister();
          registerForm.reset();
      } else {
          this.registerSubmitted = true;
          this.registerHasError = true;
          this.registerMessage = (this.lang === 'en') ? 'Please fill all the required fields!' : 'يرجى ملء جميع الحقول المطلوبة!';
          if (this.first_name == undefined || this.last_name == undefined || this.email == undefined) {
              this.registerMessage = (this.lang === 'en') ? 'Please fill all the required fields!' : 'يرجى ملء جميع الحقول المطلوبة!';
          }
          else if (this.password.length < 6) {
              this.registerMessage = (this.lang === 'en') ? 'Password must be at least 6 characters' : 'يجب أن تتكون كلمة المرور من 6 أحرف على الأقل';
          }
          else if (this.password != this.confirm_password) {
              this.registerMessage = (this.lang === 'en') ? 'Your password and confirmation password do not match.' : 'كلمة المرور وتأكيد كلمة المرور غير متطابقين.';
          }
          if (this.email && this.email != '') {
              let emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$/;
              this.isEmailValid = emailPattern.test(this.email);
          }
          else {
              this.isEmailValid = true;
          }
          setTimeout(() => {
              this.registerSubmitted = false;
              this.registerHasError = false;
              this.registerMessage = '';
              this.hasError = false;
              this.isEmailValid = true;
          }, 3000);
      }
    }

    doRegister() {
        try {
            const param = {
                first_name: this.first_name,
                last_name: this.last_name,
                email: this.email,
                phone: this.phone,
                password: this.password,
                referred_by_code:this.referred_by_code,
                newsletter_subscribed: "0",
                device_type: 'W',
                store: this.store_code,
                device_model: this.device,
                app_version: this.device,
                os_version: window.navigator.platform
            };
            this.authenticationService.register(param)
                .pipe(first())
                .subscribe(
                    async response => {
                        if (response.status === 200) {
                            const user = response.data as User;
                            this.analytics.completeRegistration(
                                +user.id,
                                (user.first_name + ' ' + user.last_name).trim(),
                                user.email,
                                'Email',
                            );
                            this.cryptService.doEncrypt(JSON.stringify(response.data)).then(encryptedString => {
                                this.localStorage.setItem('userInfo', encryptedString).subscribe(() => {
                                    this.configService.sendGTagEvent('register', {
                                      user_id: response.data.id,
                                      user_firstname: response.data.first_name,
                                      user_lastname: response.data.last_name,
                                      email: response.data.email
                                    });
                                    this.cartService.uploadOfflineCart(response.data.id).then(() => {
                                        this.cartService.clearStorage('cart');
                                        this.redirectTo();
                                    });
                                });
                            });
                        } else {
                            this.registerSubmitted = true;
                            this.registerHasError = true;
                            if (response.status === 406){
                                this.hasError = true;
                                this.registerMessage = (this.lang === 'en') ? 'User with same email already exists.' :'المستخدم بنفس البريد الإلكتروني موجود بالفعل.';
                            } else if(response.status === 500){
                                this.hasError = true;
                                this.registerMessage = (this.lang === 'en') ? 'There was an error processing the request. Please try again later.' : 'كان هناك خطأ في معالجة الطلب. الرجاء معاودة المحاولة في وقت لاحق.';
                            }
                            await setTimeout(() => {
                                this.registerSubmitted = false;
                                // this.registerHasError = false;
                                // this.registerMessage = '';
                            }, 3000);
                            /*this.registerSubmitted = true;
                            this.registerHasError = true;
                            this.registerMessage = response.message;*/
                            this.configSettings.toggleLoading(false);
                        }
                    }, error => { this.configSettings.toggleLoading(false); }
                );
        } catch (e) {
            console.log(e);
            this.configSettings.toggleLoading(false);
        }
    }

    redirectTo() {
        //setInterval(function() {
            //this.router.navigate([this.returnUrl]);
            window.location.href = this.returnUrl;
        //
        // }, 500);
    }

    animateLabel($event) {
    
      if($event.type === 'focus') {
          $event.target.parentElement.classList.add("focused");
      } else {
        if($event.target.value === "") {
            $event.target.parentElement.classList.remove("focused");
        }
      }
    }

    checkPasswordLength($event){
        if($event.target.value.length>=6){
            this.passwordValid = true;
            this.btnDisabled = true;
        }else{
            this.passwordValid = false;
        }
    }
}
