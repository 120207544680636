import { Component, OnInit } from '@angular/core';
import {Address} from "../models/address";
import {Country} from "../models/country";
import {State} from "../models/state";
import {Area} from "../models/area";
import {Sector} from "../models/sector";
import {ConfigSettings} from "../config/config.settings";
import {CheckoutService} from "../config/checkout.service";
import {NgForm} from "@angular/forms";
import {UserService} from "../config/user.service";
import {ActivatedRoute, Router} from "@angular/router";
import { Store } from '../models/store';
import { listStagger } from '../animations';
import { FooterService } from '../config/footer.service';
import {AnalyticsService} from '../services/analytics.service';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
  animations: [ listStagger ]
})
export class AddressComponent implements OnInit {
  address: Address;
  countries: Country[] = [];
  governorates: State[] = [];
  areas: Area[] = [];
  sectors: Sector[] = [];
  submitted = false;
  lang: string;
  store: Store;
  currency: string;
  dataAvailable = false;

  countryCode = '+965';

  constructor(
      private configSettings: ConfigSettings,
      private checkoutService: CheckoutService,
      private userService: UserService,
      private router: Router,
      private route: ActivatedRoute,
      protected footerService: FooterService,
      private analytics: AnalyticsService
  ) { }

  ngOnInit() {
      this.getCountries();
      this.loadStates(114); // default country Kuwait
      this.lang = this.configSettings.getLang();
      this.address = {
          address_id: null,
          first_name: null,
          last_name: null,
          area_id: null,
          area_name: null,
          governorate_id: null,
          governorate_name: null,
          address_store_code:null,
          iso:null,
          country_id: 114, // default country Kuwait
          country_name: null,
          vat_pct: null,
          vat_charges: null,
          block_id: null,
          block_name: null,
          shipping_cost: null,
          express_shipping_cost: null,
          cod_cost: null,
          is_cod_enable: null,
          phonecode: null,
          street: null,
          addressline_1: null,
          mobile_number: null,
          alt_phone_number: null,
          location_type: null,
          notes: null,
          is_default: null,
          delivery_options: null,
          avenue: null,
          floor: null,
          flat: null,
          landmark: null,
          id_number: null
      };

      this.configSettings.getStoreObject().then((result) => {
        this.store = <Store> result;
        this.currency = result['currency_' + this.lang];
        this.getAddress();
      });
    //   this.analytics.pageView(
    //     String("Add Address")
    //   );
  }


  getAddress() {
    this.configSettings.toggleLoading(true);
    const id = this.route.snapshot.paramMap.get('id');
    if (id) {
        this.userService.getUserSession().then(response => {
            if (response !== false) {
                this.userService.getUserAddressDetails(id, response.id, this.store.iso_code).then(data => {
                    try {
                        this.address = data.body.data;
                        this.address.area_id = +data.body.data.area_id;
                        this.address.block_id = +data.body.data.block_id;

                        if (this.address.country_id) {
                            this.loadStates(this.address.country_id);
                        }

                        if (this.address.governorate_id) {
                            this.loadAreas(this.address.governorate_id);
                        }

                        if (this.address.area_id) {
                            this.loadBlocks(this.address.area_id);
                        }
                        this.configSettings.toggleLoading(false);
                        this.dataAvailable = true;
                        setTimeout(() => {this.footerService.toggleFooter(true)}, 300);
                    } catch (e) {
                        this.configSettings.toggleLoading(false);
                    }
                });
            }
        },
error => {
            this.configSettings.toggleLoading(false);
        });
    } else {
        this.dataAvailable = true;
        setTimeout(() => {this.footerService.toggleFooter(true)}, 300);
        this.configSettings.toggleLoading(false);
    }
  }

  getCountries() {
      this.configSettings.toggleLoading(true);
      this.checkoutService.getCountries().then(data => {
          this.countries = data.body.data;
          this.configSettings.toggleLoading(false);
      }, error => { this.configSettings.toggleLoading(false); });
  }

  getStates($country) {
      if($country.has_states === '1') {
          this.loadStates($country.id)
          this.countryCode = '+' + $country.phonecode;
      } else {
          this.governorates = [];
      }
      this.address.governorate_id = null;
      this.address.area_id = null;
      this.address.block_id = null;
  }

  loadStates($country) {
      this.configSettings.toggleLoading(true);
      this.checkoutService.getStates($country).then(data => {
          this.governorates = data.body.data;
          this.configSettings.toggleLoading(false);
      }, error => { this.configSettings.toggleLoading(false); } );
  }

  getAreas($state) {
      if($state.has_areas === '1') {
          this.loadAreas($state.id)
      } else {
          this.areas = [];
      }
      this.address.area_id = null;
      this.address.block_id = null;
  }

  loadAreas($state) {
      this.configSettings.toggleLoading(true);
      this.checkoutService.getAreas($state).then(data => {
          this.areas = data.body.data;
          this.configSettings.toggleLoading(false);
      }, error => { this.configSettings.toggleLoading(false); });
  }

  getBlocks($city) {
      if($city.has_blocks === '1') {
          this.loadBlocks($city.id)
      } else {
          this.sectors = [];
      }
      this.address.block_id = null;
  }

  loadBlocks($city) {
      this.configSettings.toggleLoading(true);
      this.checkoutService.getBlocks($city).then(data => {
          this.sectors = data.body.data;
          this.configSettings.toggleLoading(false);
      }, error => { this.configSettings.toggleLoading(false); });
  }

  saveAddress(shippingAddressForm: NgForm) {
      this.submitted = true;
      this.configSettings.toggleLoading(true);
      this.userService.getUserSession().then(response => {
          if (response !== false) {
              const id = this.route.snapshot.paramMap.get('id');
              if (this.address.block_id === 0) {
                  this.address.block_id = null;
              }
              if (id) {
                  this.checkoutService.updateAddress(response.id, this.address, this.store.iso_code).then(response => {
                      if (response.status == 200) {
                          this.router.navigate(['/my-address']);
                      }
                      this.configSettings.toggleLoading(false);
                  });
              } else {
                  this.checkoutService.addAddress(response.id, this.address, this.store.iso_code).then(data => {
                      this.configSettings.toggleLoading(false);
                      this.router.navigate(['/my-address']);
                  });
              }
          }
      }, error => { this.configSettings.toggleLoading(false); });
  }

}
