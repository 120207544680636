import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { User } from '../models/user';
import { CryptService } from '../config/crypt.service';
import { CartService } from "../config/cart.service";
import { UserService } from "../config/user.service";
import { ConfigSettings } from "../config/config.settings";
import { WishlistService } from '../config/wishlist.service';
import { ConfigService } from '../config/config.service';
// import { Router } from 'express';
import { Router } from "@angular/router";
import { CheckoutService } from '../config/checkout.service';
import { FooterService } from '../config/footer.service';
// import { MatDialog, MatDialogRef } from '@angular/material';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AnalyticsService } from '../services/analytics.service';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { Cart } from '../models/cart';
import { ConfigurableOptions } from '../models/configurable-options';
import { Store } from '../models/store';
import { DialogComponent } from '../dialog/dialog.component';
import { SupportDetails } from '../models/support-details';
import { NavbarService } from '../config/navbar.service';
import { Subscription } from 'rxjs';
import { AddToCartModalService } from '../config/add-to-cart-modal.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { UpdateWishlistCountService } from '../services/update-wishlist-count.service';
import { hideCartService } from '../services/hide-cart-service';
import { take } from 'rxjs/operators';
@Component({
    selector: 'app-header-user-actions',
    templateUrl: './header-user-actions.component.html',
    styleUrls: ['./header-user-actions.component.scss']
})
export class HeaderUserActionsComponent implements OnInit {
    userSession = new User();
    cartCount: number = 0;
    // cartValue: string = '';
    wishlistCount: number = 0;
    userStatus = false;

    cart: Cart;
    currency: string = '';
    cartValue: number = 0;
    shippingCost: number = 0;
    vatCharges: number = 0;
    selectedQuantity: any = [];
    selectedAttribute: Array<number[]> = [];
    selectedOption: Array<number[]> = [];
    selectedFilters: Array<ConfigurableOptions[]> = [];
    quantities: Array<any> = [];
    showQuantitySelect: Array<any> = [];
    showOptionSelect: Array<any> = [];
    didLoad: boolean = false;
    lang: string;
    store: Store;
    userId: any;
    dataAvailable = false;
    dialogRef: MatDialogRef<DialogComponent>;
    support_email = '';
    support_phone = '';
    userSessions = false;
    $subs: Subscription;
    isMobile: any;
    wishlist_count: number = 0
    skeletonLoader:boolean=true
    hideCart=false;
    constructor(
        private cartService: CartService,
        private wishlistService: WishlistService,
        private userService: UserService,
        private configSettings: ConfigSettings,
        private navbarService: NavbarService,
        private configService: ConfigService,
        private router: Router,
        private checkoutService: CheckoutService,
        protected footerService: FooterService,
        private dialog: MatDialog,
        private addToCartModalService: AddToCartModalService,
        private analytics: AnalyticsService,
        protected localStorage: LocalStorage,
        private toaster:ToastrService,
        private translate:TranslateService,
        private updateWishlistCountService : UpdateWishlistCountService,
        private hideCartService:hideCartService
    ) { }

    ngOnInit() {
        this.configSettings.toggleLoading(true);
        this.checkUserSession();
        // this.cartCount = parseInt(this.configSettings.getCartCount());
        this.cartService.currentcartCount.subscribe(data => this.cartCount = data);
        this.wishlistService.currentWishlistCount.subscribe(data => this.wishlistCount = data);
        this.updateWishlistCountService.getWishlistCount().subscribe(response => this.wishlist_count = response)

        this.lang = this.configSettings.getLang();
        this.configSettings.getStoreObject().then((result) => {
            this.store = <Store>result;
            this.currency = result['currency_' + this.lang];
        });
        this.localStorage.getItem<any>('support_details').subscribe((result) => {
            if (result) {
                const SupportDetails = <SupportDetails>result;
                this.support_email = SupportDetails.support_email;
                this.support_phone = SupportDetails.support_phone;
            }
        });
        this.$subs = this.addToCartModalService.receivedItemOpen().subscribe((d) => {
            if (d == 'open') {
                // this.sidenavCLosed = true;
                this.toggleSidenav();
            }
        });
        // tthis is hide cart popup when we are in the cart page....
        this.hideCartService.getHideCart().subscribe((item)=>{
            if(item==true){
                this.hideCart=true;
            }else{
                this.hideCart=false;
            }
            
        })
    }

    checkUserSession() {
        this.userService.getUserSession().then(response => {
            if (response !== false) {
                this.userId=response.id;
                this.userSession.id = response.id;
                this.userSession.first_name = response.first_name;
                this.userSession.last_name = response.last_name;
                this.userSession.email = response.email;
                // this.wishlist_count = response.wishlist_count                
                // this.cartService.getCartObservable().subscribe((cart) => {
                //     //console.log(cart);
                //     if (cart && cart.hasOwnProperty('id')) {
                //         this.cart = cart;
                //         this.populateCartItems();
                //     } else {
                //         this.getCartItems();
                //     }
                // })

            }

            this.userStatus = true;
        });
        this.configSettings.toggleLoading(false);
    }

    loginFromCheckout() {
        this.navbarService.triggerChangeShowGuestLogin(true);
        this.router.navigate(['/login','guest']);
        this.toggleSidenav();
    }


    sidenavCLosed: any = false;
    toggleSidenav() {
        if (this.hideCart) return;
    
        this.sidenavCLosed = !this.sidenavCLosed;
        document.body.style.overflowY = this.sidenavCLosed ? "hidden" : "unset";
    
        if (this.sidenavCLosed) {
            this.cartService.getCartObservable().pipe(take(1)).subscribe((cart) => {
                if (cart.items?.length) {
                    this.cart = cart;
                    this.populateCartItems();
                } else {
                    this.getCartItems();
                }
            });
        }
        // if (this.hideCart) {
        //     return;
        // } else {
        //     let count = 0;
        //     if (this.sidenavCLosed) {
        //         this.sidenavCLosed = false;
        //         document.body.style.overflowY = "unset";
        //     } else {
        //         this.sidenavCLosed = true;
        //         document.body.style.overflowY = "hidden";
        //         this.cartService.getCartObservable().subscribe((cart) => {
        //             //console.log(cart);
        //             count++;
        //             if (count == 1) {
        //                 if (cart.items?.length != 0 && cart.items?.length != undefined) {
        //                     this.cart = cart;
        //                     this.populateCartItems();
        //                 } else {
        //                     this.getCartItems();
        //                 }
        //             }

        //         });
        //     }
        // }
    }

    ngOnDestroy(): void {
        document.body.style.overflowY = "unset";
        this.addToCartModalService.sendItemOpen('close');
    }

    populateCartItems() {
        if (this.cart) {
            this.cartService.changCartCount(this.cart.items.length);
            this.configSettings.setCartIdItems(this.cart.items.map(x => x.id));
            // if(this.cart.items.length == 0){
            //     this.toggleSidenav();
            // }
            this.cartValue = 0;
            this.cart.items.map((item, index) => {
                this.selectedQuantity[index] = item.quantity;
                this.quantities[index] = this.createQuantityOptions(item.remaining_quantity);

                let tmpAttributes: number[] = [];
                let tmpOptions: number[] = [];
                item.configurable_option.map(option => {
                    if (option) {
                        tmpAttributes.push(+option.attributes[0].option_id);
                        tmpOptions.push(+option.attribute_id);
                    }
                });
                this.selectedAttribute[index] = tmpAttributes;
                this.selectedOption[index] = tmpOptions;

                this.selectedFilters[index] = item.configurable_option;

                this.showQuantitySelect[index] = false;
                this.showOptionSelect[index] = false;
                this.cartValue += (+item.quantity * +item.final_price);
            });
            this.skeletonLoader=false;
        }
    }

    getCartItems() {
        this.configSettings.toggleLoading(true);
        this.userService.getUserSession().then(response => {
            if (response === false) {
                try {
                    this.cartService.getCartItems().then(data => {
                        this.cart = data;
                        if (this.cart) {
                            this.cartService.changCartCount(this.cart.items.length);
                            this.configSettings.setCartIdItems(this.cart.items.map(x => x.id));
                            // if(this.cart.items.length == 0){
                            //     this.toggleSidenav();
                            // }
                            this.cartValue = 0;
                            this.cart.items.map((item, index) => {
                                this.selectedQuantity[index] = item.quantity;
                                this.quantities[index] = this.createQuantityOptions(item.remaining_quantity);
                                 let tmpAttributes: number[] = [];
                                let tmpOptions: number[] = [];
                                item.configurable_option.map(option => {
                                    if (option) {
                                        tmpAttributes.push(+option.attributes[0].option_id);
                                        tmpOptions.push(+option.attribute_id);
                                    }
                                });
                                this.selectedAttribute[index] = tmpAttributes;
                                this.selectedOption[index] = tmpOptions;

                                this.selectedFilters[index] = item.configurable_option;

                                this.showQuantitySelect[index] = false;
                                this.showOptionSelect[index] = false;
                                this.cartValue += (+item.quantity * +item.final_price);
                            });
                        } else {
                            this.cartService.changCartCount(0);
                            // this.toggleSidenav();
                        }
                        this.didLoad = true;
                        this.configSettings.toggleLoading(false);
                        this.dataAvailable = true;
                        this.skeletonLoader=false;
                        setTimeout(() => { this.footerService.toggleFooter(true) }, 300);
                    }, error => {
                        this.configSettings.toggleLoading(false);
                    });
                } catch (e) {
                    this.configSettings.toggleLoading(false);
                }
            } else {
                this.userId = response.id;
                this.userSessions = true;
                try {
                    this.cartService.readCartItems(response.id, this.lang, this.store.iso_code).then(data => {
                        this.cart = data.cart;
                        this.cartService.changCartCount(this.cart.items.length);
                        this.configSettings.setCartIdItems(this.cart.items.map(x => x.id));
                        // if(this.cart.items.length == 0){
                        //     this.toggleSidenav();
                        // }
                        this.cartValue = 0;
                        this.cart.items.map((item, index) => {
                            this.selectedQuantity[index] = item.quantity;
                            this.quantities[index] = this.createQuantityOptions(item.remaining_quantity);

                            let tmpAttributes: number[] = [];
                            let tmpOptions: number[] = [];
                            item.configurable_option.map(option => {
                                if (option) {
                                    tmpAttributes.push(+option.attributes[0].option_id);
                                    tmpOptions.push(+option.attribute_id);
                                }
                            });
                            this.selectedAttribute[index] = tmpAttributes;
                            this.selectedOption[index] = tmpOptions;

                            this.selectedFilters[index] = item.configurable_option;

                            this.showQuantitySelect[index] = false;
                            this.showOptionSelect[index] = false;

                            this.cartValue += (+item.quantity * +item.final_price);
                        });

                        this.shippingCost = +data.shipping;
                        this.vatCharges = +data.vatCharges;
                        this.didLoad = true;
                        this.dataAvailable = true;
                        this.skeletonLoader=false;
                        setTimeout(() => {
                            this.footerService.toggleFooter(true);
                            this.configSettings.toggleLoading(false);
                        }, 300);
                    }, error => {
                        this.configSettings.toggleLoading(false);
                    });
                } catch (e) {
                    this.configSettings.toggleLoading(false);
                }
            }
        }, error => { this.configSettings.toggleLoading(false); });
    }

    // @Output() openSearch: EventEmitter<Number> = new EventEmitter<Number>();
    // openSearchClick() {
    //     this.openSearch.emit(0)
    // }

    deleteFromCart(item, index) {         
        this.dialogRef = this.dialog.open(DialogComponent, {
            data: { confirmDialog: true },
        });
        this.dialogRef.componentInstance.confirmMessage = (this.lang == 'en') ? "Are you sure you want to delete this item?" : "هل أنت متأكد أنك تريد حذف هذه القطعه؟";
        this.dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.configSettings.toggleLoading(true);
                this.userService.getUserSession().then(response => {
                    if (response === false) {
                        this.cartService.deleteCartItem(item.id).then(data => {
                            this.cart = data;
                            this.configSettings.toggleLoading(false);
                            if (data.items.length == 0) {
                                this.toggleSidenav();
                            }
                            this.cartService.setCartObservable(data.items)
                        }, error => { this.configSettings.toggleLoading(false); });
                    } else {
                        const getParams = {
                            lang: this.lang,
                            store: this.store.iso_code
                        };
                        const postParams = {
                            user_id: response.id,
                            order_id: this.configSettings.getCartId(),
                            products: item.id
                        };
                        this.configService.postRequest('delete-from-cart', getParams, postParams)
                            .subscribe(response => {
                                if (response.status === 200) {
                                    const final_price = item.final_price_kwd * 3.30;
                                    this.configService.sendGTagEvent('remove_from_cart', {
                                        items: [{
                                            id: item.SKU,
                                            name: item.name,
                                            list_name: "Product Details",
                                            brand: item.brand_name,
                                            category: item.brand_name,
                                            variant: '',
                                            list_position: 1,
                                            quantity: item.quantity,
                                            price: final_price.toFixed(2)
                                        }]
                                    });

                                    this.cartValue = 0;
                                    this.cart.items.splice(index, 1);
                                    this.selectedQuantity.splice(index, 1);
                                    this.showQuantitySelect.splice(index, 1);
                                    this.showOptionSelect.splice(index, 1);

                                    response.data.items.map((item, i) => {

                                        this.selectedQuantity[i] = item.quantity;
                                        this.quantities[i] = this.createQuantityOptions(item.remaining_quantity);

                                        let tmpAttributes: number[] = [];
                                        let tmpOptions: number[] = [];
                                        item.configurable_option.map(option => {
                                            if (option) {
                                                tmpAttributes.push(+option.attributes[0].option_id);
                                                tmpOptions.push(+option.attribute_id);
                                            }
                                        });
                                        this.selectedAttribute[i] = tmpAttributes;
                                        this.selectedOption[i] = tmpOptions;

                                        this.selectedFilters[i] = item.configurable_option;

                                        this.showQuantitySelect[i] = false;
                                        this.showOptionSelect[i] = false;

                                        this.cartValue += (+item.quantity * +item.final_price);
                                    });
                                }
                                this.cartService.changCartCount(response.data.items.length);
                                this.cartService.setCartObservable(response.data.items)
                                // if(response.data.items.length == 0){
                                //     this.toggleSidenav();
                                // }
                                this.configSettings.toggleLoading(false);
                            }, error => { this.configSettings.toggleLoading(false); });
                    }
                }, error => { this.configSettings.toggleLoading(false); });
            }
            this.dialogRef = null;
        });
        return false;
    }

    saveForLater(item, index) {
        if (this.userSession.id) {
            this.dialogRef = this.dialog.open(DialogComponent, {
                data: { confirmDialog: true },
            });
            this.dialogRef.componentInstance.confirmMessage = (this.lang == 'en') ? "Are you sure you want to move this item to wishlist?" : "هل تريد بالتأكيد حفظ هذا المنتج في قائمة الأمنيات ؟";
            this.dialogRef.afterClosed().subscribe(result => {
                if (result) {
                    this.configSettings.toggleLoading(true);
                    const getParams = {
                        lang: this.lang,
                        store: this.store.iso_code
                    };
                    const postParams = {
                        user_id: this.userId,
                        order_id: this.configSettings.getCartId(),
                        product_id: item.id
                    };
                    this.configService.postRequest('save-for-later', getParams, postParams)
                        .subscribe(response => {
                            this.cartValue = 0;
                            this.cart.items.splice(index, 1);
                            this.selectedQuantity.splice(index, 1);
                            this.showQuantitySelect.splice(index, 1);
                            this.showOptionSelect.splice(index, 1);

                            response.data.items.map(item => {
                                this.cartValue += (+item.quantity * +item.final_price);
                            });
                            /**
                             * Update cart item count
                             */
                            this.cartService.changCartCount(response.data.items.length);
                            //update wishlist count..
                            this.updateWishlistCountService.setWishlistCount(response?.wishlist_count)
                            // if(this.cart.items.length == 0){
                            //     this.toggleSidenav();
                            // }
                            /**
                             * Update wishlist count
                             */
                            // const wishlistCount = (this.wishlistCount > 0) ? this.wishlistCount + 1 : 0;
                            // this.wishlistService.changWishlistCount(wishlistCount);

                            this.configSettings.toggleLoading(false);
                        }, error => { this.configSettings.toggleLoading(false); });

                }
                this.dialogRef = null;
            });
        }else{
            const route = this.userSessions ? '/checkout' : '/login';
            this.router.navigate([route]);
            this.sidenavCLosed=false
        }
        return false;
    }

    createQuantityOptions(quantity) {
        let option: any[] = [];
        for (let i = 1; i <= quantity; i++) {
            option.push({ id: i, value: i });
        }

        return option;
    }

    onChangeQuantity($event, index) {
        this.configSettings.toggleLoading(true);
        this.userService.getUserSession().then(response => {
            if(response === false) {
                this.selectedQuantity[index] = $event.value;
                this.cart.items[index].quantity = $event.value;

                this.cartService.updateQuantity(index, $event.value).then((response) => {
                    //this.currency = response.currency;
                    this.cartValue = response.value;
                    this.cartValue=0;
                    this.cart.items.map(item => {
                        this.cartValue += (+item.quantity * +item.final_price);      
                    });
                    this.showQuantitySelect[index] = false;
                    this.configSettings.toggleLoading(false);
                });
            } else {
                const getParams = {
                    lang: this.configSettings.getLang(),
                    store: this.store.iso_code
                };

                const postParams = {
                    user_id: response.id,
                    order_id: this.configSettings.getCartId(),
                    products: this.cart.items[index].id,
                    quantity: $event.value
                };

                this.configService.postRequest('update-cart', getParams, postParams)
                    .subscribe(response => {
                        this.cartValue = 0;                        
                        this.shippingCost = response.data.shipping_cost;
                        response.data.items.map(item => {
                            this.selectedQuantity[index] = $event.value;
                            this.cart.items[index].quantity = $event.value;
                            this.showQuantitySelect[index] = false;
                            this.cartValue += (+item.quantity * +item.final_price);
                        });
                        this.configSettings.toggleLoading(false);
                    });
            }
        }, error => { this.configSettings.toggleLoading(false); });
    }

    update(index) {
        this.showQuantitySelect[index] = true;
        return false;
    }

    cancelUpdate(index) {
        this.showQuantitySelect[index] = false;
        return false;
    }

    proceedToShipping() {
        // this.checkoutService.setCart(this.cart);
        // this.checkoutService.setShipping(this.shippingCost);
        this.router.navigate(['/checkout']);

        var ids = [];
        var qtys = 0;
        var gtotal = 0;
        var cartItems = [];
        var productCategories = [];
        var productNames = [];
        this.cart.items.forEach(element => {
            ids.push(element.id);
            cartItems.push(element);
            productNames.push(element.name);
            productCategories.push(element.marketing_category + ' - ' + element.marketing_subcategory)
            qtys = qtys + element.quantity;
            gtotal = Number(gtotal) + Number(element.final_price);
        });
        //Adding To Event
        this.analytics.initiateCheckout(
            ids,
            productNames,
            cartItems,
            qtys,
            String(gtotal)
        );
    }

    onOptionSelect(option, $event, index) {
        this.clearFilters(option, $event, index).then(() => {
            this.filterOptions(index);
        });
    }

    clearFilters(configurable_option: ConfigurableOptions, $event, index: number): Promise<any> {
        let promise = new Promise<any>((resolve, reject) => {
            this.cart.items[index].configurable_option.map((option, i) => {
                if (option.attribute_id === configurable_option.attribute_id) {
                    if (i == 0) {
                        this.selectedAttribute[index].splice(i);
                        this.selectedOption[index].splice(i);
                        this.selectedFilters[index].splice(i);
                    }

                    this.selectedAttribute[index][i] = $event.option_id;
                    this.selectedOption[index][i] = +configurable_option.attribute_id;
                    this.selectedFilters[index][i] = {
                        type: configurable_option.type,
                        attribute_id: configurable_option.attribute_id,
                        attribute_code: configurable_option.attribute_code,
                        attributes: [$event]
                    };
                }
            });

            resolve(true);
        });

        return promise;

    }

    editOption(index, optionIndex) {
        //if(optionIndex === 0) {
        this.selectedOption[index].splice(optionIndex);
        this.selectedAttribute[index].splice(optionIndex);
        //}

        this.filterOptions(index);
        this.showOptionSelect[index] = true;
        return false;
    }

    cancelEdit(index) {
        this.showOptionSelect[index] = false;
        return false;
    }

    filterOptions(index) {
        this.configSettings.toggleLoading(true);
        const getParams = {
            lang: this.lang,
            store: this.store.iso_code
        };

        const postParams = {
            product_id: this.cart.items[index].parent_id,
            option_id: (this.selectedAttribute[index].length) ? this.selectedAttribute[index].join(',') : '',
            attribute_id: (this.selectedOption[index].length) ? this.selectedOption[index].join(',') : ''
        };

        this.configService.postRequest('configurable-options', getParams, postParams)
            .subscribe(response => {
                if (response.data[0]) {
                    if (response.data[0].attributes.length) {
                        this.cart.items[index].configurable_option.map(option => {
                            if (option.attribute_id === response.data[0].attribute_id) {
                                option.attributes = [];
                                option.attributes = response.data[0].attributes;
                            }
                        });
                    } else {
                        this.updateBag(index, response.data[0].entity_id);
                    }
                }

                this.configSettings.toggleLoading(false);
            }, error => { this.configSettings.toggleLoading(false); });
    }

    updateBag(index, entityId) {        
        try {
            if (this.cart.items[index].is_saleable) {
                this.configSettings.toggleLoading(true);
                if (this.cart.items[index].id) {
                    this.userService.getUserSession().then(response => {
                        if (response === false) {
                            this.cartService.updateOfflineCart(entityId, this.cart.items[index], this.selectedFilters[index], this.selectedQuantity[index]).then(response => {
                                this.showOptionSelect[index] = false;
                                this.configSettings.toggleLoading(false);
                            });
                        } else {
                            this.updateOnlineCart(response.id, this.cart.items[index].id, entityId, this.selectedQuantity[index]).then((any) => {
                                this.showOptionSelect[index] = false;
                                this.configSettings.toggleLoading(false);
                            });
                        }
                    }, error => { this.configSettings.toggleLoading(false); });
                } else {
                    this.configSettings.toggleLoading(false);
                    let selects = document.querySelectorAll('ng-select');
                    for (let i = 0; i < selects.length; i++) {
                        selects[i].classList.add('error');
                    }
                }
            }
        } catch (e) {
            this.configSettings.toggleLoading(false);
        }
    }

    private updateOnlineCart(user: string, oldProduct, newProduct, quantity): Promise<any> {
        return new Promise(resolve => {
            const getParams = {
                user: user,
                order: this.cart.id,
                old_product: oldProduct,
                new_product: newProduct,
                quantity: quantity,
                lang: this.lang,
                store: this.store.iso_code
            };

            this.configService.readRequest('update-product-in-cart', getParams)
                .subscribe(response => {
                    //this.cart.items = response.body.data.items;
                    resolve(true);
                });
        });
    }
    updateCart(val, index, remaining_quantity) {
        let e = { value: Number(this.selectedQuantity[index]) };
        (val == 1 ? e.value++ : e.value--);
        // (e.value > remaining_quantity ? this.translate.get('product out of stock').subscribe((res: string) => {this.toaster.error(res);}):this.onChangeQuantity(e, index))
        //adding this if condition just so that if someone new is working should be able to read...
      
        if(e.value > remaining_quantity){
            this.translate.get('Product out of stock').subscribe((res: string) => {this.toaster.error(res);});
            return;
        }
        this.onChangeQuantity(e, index)
    }

}
