<div class="my_account_data" *ngIf="skeletonLoader">
  <div class="container-fluid">
    <section class="main-profile">
      <div class="account_heading pt-3 pb-5">
        <ul class="d-flex flex-wrap align-items-center justify-content-center w-100 listing-page-routing">
          <li class="routing-list w-25">
            <ngx-skeleton-loader></ngx-skeleton-loader>
          </li>
        </ul>
      </div>
      <div>
        <div class="d-flex w-100 justify-content-center w-100">
          <div class="user-left-menu d-none d-lg-block w-25">
            <div class="w-50">
              <ngx-skeleton-loader count="8"></ngx-skeleton-loader>
            </div>
          </div>
          <div class="user-profile-content w-100 w-lg-75">
            <div class="breadcrumb_heading">
              <h3 class="w-25">
                <ngx-skeleton-loader></ngx-skeleton-loader>
              </h3>
            </div>
            <div class="">
              <div class="d-block d-lg-flex gap-0 gap-lg-4 w-full">
                <div class="w-100 w-lg-50">
                  <div class="loyalty_box">
                    <div class="points_box">
                      <ngx-skeleton-loader class="w-50"></ngx-skeleton-loader>
                      <ngx-skeleton-loader class="w-25"></ngx-skeleton-loader>
                    </div>
                    <div class="loyalty-history">
                      <h1 class="w-50">
                        <ngx-skeleton-loader></ngx-skeleton-loader>
                      </h1>
                      <div class="d-flex justify-content-between loyalty_border w-100" *ngFor="let history of [1,2]">
                        <div class="w-75 gap-4">
                          <h4 class="w-50">
                            <ngx-skeleton-loader></ngx-skeleton-loader>
                          </h4>
                          <p class="w-50">
                            <ngx-skeleton-loader></ngx-skeleton-loader>
                          </p>
                        </div>
                        <div class="w-25">
                          <h4 class="w-50">
                            <ngx-skeleton-loader></ngx-skeleton-loader>
                          </h4>
                          <p class="w-50">
                            <ngx-skeleton-loader></ngx-skeleton-loader>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="w-100 w-lg-50">
                  <div class="loyalty_tier">
                    <div class="current_tier w-50">
                      <ngx-skeleton-loader></ngx-skeleton-loader>
                    </div>
                    <div class="loyalty_tier_progress w-100">
                      <div class="skelton-circle-main gap-4" *ngFor="let item of [1,2,3,4]">
                        <ngx-skeleton-loader class="skelton-circle gap-4" count="1"></ngx-skeleton-loader>
                      </div>
                    </div>
                    <ngx-skeleton-loader class="w-25"></ngx-skeleton-loader>

                    <div class="loyalty_tier_works">
                      <h4 class="w-25">
                        <ngx-skeleton-loader></ngx-skeleton-loader>
                      </h4>
                      <ul class="d-flex justify-content-between text-center mb-3">
                        <li class="w-100">
                          <ngx-skeleton-loader></ngx-skeleton-loader>
                        </li>
                        <li class="w-100">
                          <ngx-skeleton-loader></ngx-skeleton-loader>
                        </li>
                        <li class="w-100">
                          <ngx-skeleton-loader></ngx-skeleton-loader>
                        </li>
                        <li class="w-100">
                          <ngx-skeleton-loader></ngx-skeleton-loader>
                        </li>
                      </ul>
                      <div>
                        <div class="tier_works_data">
                          <div class="point_data w-75">
                            <ngx-skeleton-loader></ngx-skeleton-loader>
                          </div>
                          <h5 class="w-50">
                            <ngx-skeleton-loader></ngx-skeleton-loader>
                          </h5>
                          <div class="w-50">
                            <ngx-skeleton-loader></ngx-skeleton-loader>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>
<ng-container *ngIf="!skeletonLoader">
    <div class="my_account_data">
      <div class="container-fluid">
        <section class="main-profile">
          <div class="account_heading pt-3 pb-5">
            <ul class="d-flex flex-wrap align-items-center justify-content-center w-100 listing-page-routing">
              <li class="routing-list">
                <a [routerLink]="'/my-account'">
                  {{ "my account" | translate }}
                </a>
              </li>
              <li class="routing-list">{{ "Loyalty Points" | translate }}</li>
            </ul>
          </div>
  
          <div>
            <div class="d-flex w-100 justify-content-center w-100">
              <div class="user-left-menu d-none d-lg-block w-25">
                <app-user-account-menu [page]="8"></app-user-account-menu>
              </div>
              <div class="user-profile-content w-100 w-lg-75">
                <div class="breadcrumb_heading">
                  <h3><a [routerLink]="'/my-account'"> <img src="/assets/icons/right-arrow.svg" alt="arrow"></a> {{'Loyalty Points' | translate}}</h3>
                </div>
                
               <div class="">
                
                <div class="d-block d-lg-flex gap-0 gap-lg-4 w-full">
                    <div class="w-100 w-lg-50">
                        <div class="loyalty_box">
                            <div class="points_box">
                                <p>{{'Available Points' | translate}}</p>
                                <h2>{{loyaltyPoint?.total_points}}</h2>
                            </div>
                            <div class="loyalty-history" *ngIf="loyaltyPoint?.order_points?.length > 0">
                                <h1>{{'Loyalty Points History' | translate}}</h1>
                                <div class="d-flex justify-content-between loyalty_border" *ngFor="let history of loyaltyPoint?.order_points">
                                    <div>
                                        <h4>{{history?.redeem_type}} {{'Order' | translate}} #{{history?.order_number}}</h4>
                                        <p>{{history?.created_at}}</p>
                                    </div>
                                    <div>
                                        <h4>{{history?.points}}</h4>
                                        <p>{{history?.tier_name}}</p>
                                    </div>
                                </div>
                                <!-- <div class="d-flex justify-content-between loyalty_border">
                                    <div>
                                        <h4>Remove Order #1000332324</h4>
                                        <p>July 18th, 2023</p>
                                    </div>
                                    <div>
                                        <h4>+ 150</h4>
                                        <p>Tier I</p>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                    <div class="w-100 w-lg-50">
                        <div class="loyalty_tier">
                            <div class="current_tier">
                                <p>{{'CURRENT TIER' | translate}}</p>
                                <span>{{'You are now in TIer' | translate}} {{loyaltyPoint?.user_tier_id}}</span>
                            </div>
                            <div class="loyalty_tier_progress">
                                <div *ngFor="let tier of loyaltyPoint?.loyalty_tier; let i = index" class="tier_progress">
                                  <p>{{tier?.name}}</p>
                                  <span [ngClass]="{'active': loyaltyPoint?.total_points >= tier?.range?.from_points }" ></span><p class="point">{{tier?.range?.from_points}}-{{tier?.range?.to_points}} {{'Pts' | translate}}</p>
                                  <div [ngClass]="{'first-line': i === 0, 'last-line': i === (loyaltyPoint?.loyalty_tier.length - 1)}"  class="line"></div>
                                </div>
                                
                            </div>
                            <p class="unlock_tire" *ngIf="loyaltyPoint?.points_for_next_tier > 0">
                              {{'Get more' | translate}} {{loyaltyPoint?.points_for_next_tier}} {{'points to unlock Tier' | translate}} II' <img src="/assets/icons/crown.png"></p>
                            <div class="loyalty_tier_works">
                                <h4>{{'HOW IT WORKS' | translate}}</h4>
                                <ul class="d-flex justify-content-between text-center mb-3">
                                    <li class="w-100" *ngFor="let tier of loyaltyPoint?.loyalty_tier">
                                        <a href="javascript:void(0);" (click)="onTierId(tier?.tier_id)" [ngClass]="selectedTier?.tier_id == tier?.tier_id ? 'active': '' ">
                                          {{tier?.name}} 
                                          </a>
                                    </li>
                                    <!-- <li class="w-100">
                                        <a href="javascript:void(0); " (click)="onTierId(2)" [ngClass]="tierTab == 2 ? 'active': '' ">
                                          {{loyaltyPoint?.loyalty_tier?.[1]?.name}}
                                         </a>
                                    </li>
                                    <li class="w-100">
                                        <a href="javascript:void(0);" (click)="onTierId(3)" [ngClass]="tierTab == 3  ? 'active': '' ">
                                          {{loyaltyPoint?.loyalty_tier?.[2]?.name}}
                                        </a>
                                    </li>
                                    <li class="w-100">
                                      <a href="javascript:void(0);" (click)="onTierId(4)" [ngClass]="tierTab == 4  ? 'active': '' ">
                                        {{loyaltyPoint?.loyalty_tier?.[3]?.name}}
                                      </a>
                                  </li> -->
                                </ul>

                                <div>
                                    <div class="tier_works_data">
                                        <div class="point_data">
                                            <span><img src="/assets/icons/timg.png" alt=""> {{'Spend 1 KD and earn' | translate}} {{selectedTier?.earning_rule}} {{'points' | translate}}</span>
                                        </div>
                                        <h5>{{'Your Benefits' | translate}}</h5>
                                        <div>
                                            <p class="d-flex gap-3 align-items-center mt-2" [innerHTML]="selectedTier?.benefits"> </p>
                                            <!-- <p class="d-flex gap-3 align-items-center mt-2"><img src="/assets/icons/round-tick.png" alt=""> 10% off on listed brand</p>
                                            <p class="d-flex gap-3 align-items-center mt-2"><img src="/assets/icons/round-tick.png" alt=""> Exclusive offers &products</p>
                                            <p class="d-flex gap-3 align-items-center mt-2"><img src="/assets/icons/round-tick.png" alt=""> 20% off on shoes</p> -->
                                        </div>
                                    </div>
                                </div>
                                <!-- <div *ngIf="tierTab == 2">
                                    <div class="tier_works_data">
                                        <div class="point_data">
                                            <span><img src="/assets/icons/timg.png" alt=""> Spend 1 KD and earn 10 points</span>
                                        </div>
                                        <h5>Your Benefits</h5>
                                        <div>
                                            <p class="d-flex gap-3 align-items-center mt-2"><img src="/assets/icons/round-tick.png" alt=""> Early access to sales</p>
                                            <p class="d-flex gap-3 align-items-center mt-2"><img src="/assets/icons/round-tick.png" alt=""> 10% off on listed brand</p>
                                            <p class="d-flex gap-3 align-items-center mt-2"><img src="/assets/icons/round-tick.png" alt=""> Exclusive offers &products</p>
                                            <p class="d-flex gap-3 align-items-center mt-2"><img src="/assets/icons/round-tick.png" alt=""> 40% off on shoes</p>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="tierTab == 3">
                                    <div class="tier_works_data">
                                        <div class="point_data">
                                            <span><img src="/assets/icons/timg.png" alt=""> Spend 1 KD and earn 10 points</span>
                                        </div>
                                        <h5>Your Benefits</h5>
                                        <div>
                                            <p class="d-flex gap-3 align-items-center mt-2"><img src="/assets/icons/round-tick.png" alt=""> Early access to sales</p>
                                            <p class="d-flex gap-3 align-items-center mt-2"><img src="/assets/icons/round-tick.png" alt=""> 10% off on listed brand</p>
                                            <p class="d-flex gap-3 align-items-center mt-2"><img src="/assets/icons/round-tick.png" alt=""> Exclusive offers &products</p>
                                            <p class="d-flex gap-3 align-items-center mt-2"><img src="/assets/icons/round-tick.png" alt=""> 80% off on shoes</p>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="tierTab == 4">
                                  <div class="tier_works_data">
                                      <div class="point_data">
                                          <span><img src="/assets/icons/timg.png" alt=""> Spend 1 KD and earn 10 points</span>
                                      </div>
                                      <h5>Your Benefits</h5>
                                      <div>
                                          <p class="d-flex gap-3 align-items-center mt-2"><img src="/assets/icons/round-tick.png" alt=""> Early access to sales</p>
                                          <p class="d-flex gap-3 align-items-center mt-2"><img src="/assets/icons/round-tick.png" alt=""> 10% off on listed brand</p>
                                          <p class="d-flex gap-3 align-items-center mt-2"><img src="/assets/icons/round-tick.png" alt=""> Exclusive offers &products</p>
                                          <p class="d-flex gap-3 align-items-center mt-2"><img src="/assets/icons/round-tick.png" alt=""> 80% off on shoes</p>
                                      </div>
                                  </div>
                              </div> -->
                            </div>
                        </div>
                    </div>
                </div>
               </div>

  
                <!-- <div class="empty-cart">
                  <div class="empty-cart-content white-container">
                    <h4 class="page-title font-bold text-center">
                      {{ "You currently have no Wishlist items" | translate }}
                    </h4>
                    <p class="font-regular text-center">
                      {{
                        "Here you'll find information related to your Wishlist items."
                          | translate
                      }}
                    </p>
                    <div class="text-center">
                      <a [routerLink]="'/home'" class="btn btn-dark">{{
                        "CONTINUE SHOPPING" | translate
                      }}</a>
                    </div>
                  </div>
                </div> -->

              </div>
            </div>
          </div>
        </section>
      </div>
    </div>

    <!-- <button *ngIf="products?.length > 0" class="scroll-to-top-button pb-2" (click)="scrollTop()">
      <img src="/assets/images/arrow-up.png" class="scroll-to-top-img">
      <span class="scroll-to-top-title">{{'BACK TO TOP' | translate}}</span>
  </button> -->
</ng-container>