<div class="my_account_data" *ngIf="skeletonLoader">
  <div class="container-fluid">
    <section class="main-profile">
      <div class="account_heading pt-3 pb-5">
        <ul class="d-flex flex-wrap align-items-center justify-content-center w-100 listing-page-routing">
          <li class="routing-list w-25">
            <ngx-skeleton-loader></ngx-skeleton-loader>
          </li>
        </ul>
      </div>

      <div>
        <div class="d-flex w-100 justify-content-center w-100 gap-4">
          <div class="user-left-menu d-none d-lg-block w-25">
            <div class="w-50">
              <ngx-skeleton-loader count="8"></ngx-skeleton-loader>
            </div>
          </div>
          <div class="user-profile-content w-100 w-lg-75">
            <div class="breadcrumb_heading">
              <h3 class="w-25">
                <ngx-skeleton-loader></ngx-skeleton-loader>
              </h3>
            </div>

            <section class="wishlist-products-conat">
              <div class="wishlist-products">
                <article *ngFor="let item of [1,2,3,4]" drag-scroll-item class="">
                  <!-- <ngx-skeleton-loader [theme]="skeltonTheme"></ngx-skeleton-loader> -->
                  <div class="items-height-skeleton">
                    <ngx-skeleton-loader class="height-fix w-100"></ngx-skeleton-loader>
                  </div>
                  <div class="w-100">
                    <div class="d-flex justify-content-center">
                      <ngx-skeleton-loader class="w-25"></ngx-skeleton-loader>
                    </div>
                    <div class="d-flex justify-content-center">
                      <ngx-skeleton-loader class="w-50"></ngx-skeleton-loader>
                    </div>
                    <div class="d-flex justify-content-center">
                      <ngx-skeleton-loader class="w-25"></ngx-skeleton-loader>
                    </div>
                    <div class="d-flex justify-content-center mt-3">
                      <ngx-skeleton-loader class="w-75" [theme]="skeltonTheme"></ngx-skeleton-loader>
                    </div>
                  </div>
                </article>
              </div>
            </section>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>
<ng-container *ngIf="!skeletonLoader">
  <div class="my_account_data">
    <div class="container-fluid">
      <section class="main-profile">
        <div class="account_heading pt-3 pb-5">
          <ul class="d-flex flex-wrap align-items-center justify-content-center w-100 listing-page-routing">
            <li class="routing-list">
              <a [routerLink]="'/my-account'">
                {{ "my account" | translate }}
              </a>
            </li>
            <li class="routing-list">{{ "Wishlist" | translate }}</li>
          </ul>
        </div>

        <div>
          <div class="d-flex w-100 justify-content-center w-100">
            <div class="user-left-menu d-none d-lg-block w-25">
              <app-user-account-menu [page]="5"></app-user-account-menu>
            </div>
            <div class="user-profile-content w-100 w-lg-75">
              <div class="breadcrumb_heading">
                <h3><a [routerLink]="'/my-account'"> <img src="/assets/icons/right-arrow.svg" alt="arrow"></a> {{'My Wish List' | translate}}</h3>
              </div>

              <section class="wishlist-products-conat" *ngIf="products.length">
                <div class="wishlist-products" *ngIf="products.length">
                  <article *ngFor="let item of products" drag-scroll-item class="">
                    <app-product-item [lang]="lang" [item]="item" [currency]="currency" [store]="store"
                      [userId]="userId" [showWishlistIcon]="true">
                    </app-product-item>
                  </article>
                </div>

              </section>



              <div *ngIf="!products.length" class="empty-cart">
                <div class="empty-cart-content white-container">
                  <h4 class="page-title font-bold text-center">
                    {{ "You currently have no Wishlist items" | translate }}
                  </h4>
                  <p class="font-regular text-center">
                    {{ "Here you'll find information related to your Wishlist items." | translate }}
                  </p>

                  <button type="button" class="button_hover" [routerLink]="'/home'">
                    {{ "CONTINUE SHOPPING" | translate }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>

  <button *ngIf="products?.length > 0" class="scroll-to-top-button pb-2" (click)="scrollTop()">
    <img src="../../assets/images/arrow-up.png" class="scroll-to-top-img">
    <span class="scroll-to-top-title">{{'BACK TO TOP' | translate}}</span>
  </button>
</ng-container>

<app-add-to-bag-popup [fromWishlist]="true"></app-add-to-bag-popup>

  <!-- <app-add-to-bag-popup></app-add-to-bag-popup> -->