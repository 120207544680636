import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import {AuthenticationService} from "../config/authentication.service";
import {filter, first, pairwise} from "rxjs/operators";
import {ActivatedRoute, NavigationEnd, NavigationStart, Router, RoutesRecognized} from "@angular/router";
import {CryptService} from "../config/crypt.service";
import {LocalStorage} from "@ngx-pwa/local-storage";
import {CartService} from "../config/cart.service";
import {ConfigSettings} from '../config/config.settings';
import { SocialAuthService } from '@abacritt/angularx-social-login';
import { FacebookLoginProvider, GoogleLoginProvider } from '@abacritt/angularx-social-login';
import { User } from '../models/user';
import { listStagger } from '../animations';
import { FooterService } from '../config/footer.service';
import {ConfigService} from '../config/config.service';
import {AnalyticsService} from '../services/analytics.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from 'src/environments/environment.prod';
import { ToastrService } from 'ngx-toastr';
import { CheckUserLoggedInService } from '../services/check-user-loggedin.service';
import { Location } from '@angular/common';
import { UpdateWishlistCountService } from '../services/update-wishlist-count.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    animations: [ listStagger ]
})
export class LoginComponent implements OnInit {
    email: string;
    password: string;
    rememberMe: boolean = false;
    submitted: boolean = false;
    hasError: boolean = false;
    message: string;
    returnUrl: string;
    isLoginPasswordVisible: boolean = false
    breadCrumb: any[] = [];
    lang: string


    /*Fields for Register form*/
    registerName: string;
    registerFullname: string;
    registerEmail: any;
    registerPassword: string;
    registerConfirmPassword: string;
    registerSubmitted: boolean = false;
    registerHasError: boolean = false;
    registerMessage: string;
    store_code: string;
    newsletterSubscribed = 0;
    device: string;
    user: User;
    is
    registerUrl: string = "/register";
    referred_by_code: string = ''

    /* Fields for Facebook login*/
    fbHasError: boolean = false;
    guest_register: boolean = false;

    jwtHelper = new JwtHelperService();
    enableGuestLogin: boolean = false;

    type:'register'|'guest' = 'register';
    login: 'SIG'|'REG'='SIG';
    validEmail: boolean;
    isEmailValid: boolean = false
    showLoading:boolean=false;
    googleData:any;
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private cryptService: CryptService,
        protected localStorage: LocalStorage,
        private cartService: CartService,
        private configSettings: ConfigSettings,
        private authService: SocialAuthService,
        protected footerService: FooterService,
        private configService: ConfigService,
        private analytics: AnalyticsService,
        private toaster:ToastrService,
        private checkUserLoggedInService: CheckUserLoggedInService,
        private location: Location,
        private updateWishlistCountService : UpdateWishlistCountService
    ) {}
    ngOnInit() {
        this.showLoading=true;
        // this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
        // if (this.returnUrl !== '/') {
        //     this.guest_register = true;
        //     this.registerUrl += "?returnUrl=" + this.returnUrl;
        // }
        this.lang = this.configSettings.getLang();
      
        this.device = this.configSettings.getBrowserVersion();
        this.configSettings.getStoreObject().then((result) => {
            this.store_code = result.iso_code;
        });
        setTimeout(() => {this.footerService.toggleFooter(true),this.showLoading=false}, 300);

        this.authService.authState.subscribe((user) => {
            this.googleData=user;
            this.signInWithGoogle();
          });
    }

    ngAfterViewInit() {
        this.updateBreadCrumb()
        this.setGoogleButtonStyles()
    }


    // to add class = googleIframe to iframe in google login button 
    setGoogleButtonStyles() {
        const googleBtn = document.getElementById('google-btn') as HTMLElement;
        const googleIframe = googleBtn.querySelector('iframe') as HTMLIFrameElement;

        const waitForIframeLoad = new Promise<void>((resolve) => {
            googleIframe.onload = () => {
                resolve();
            };
        });

        waitForIframeLoad.then(() => {
            googleIframe.classList.add('googleIframe');
        });
    }
        

    changeTab(input:string){        
        let e={srcElement:{checked:true}}
        if(input=='REG'){
            this.login='REG';
            this.checkMe(e);
        }else{
            this.login='SIG';
            this.updateBreadCrumb()
        }
    }
    checkMe(e){
        console.log(e.srcElement.checked,'event');
        this.newsletterSubscribed = e.srcElement.checked ? 1 : 0;  
    }

    /**
     * After user submit login form
     * @param loginForm
     */
    onSubmit(loginForm: NgForm) {
        this.configSettings.toggleLoading(true);
        this.doLogin(loginForm);
        // loginForm.reset();
    }

    /**
     * Login user
     */
    doLogin(loginForm: NgForm) {
        this.showLoading=true;
        this.authenticationService.login(this.email, this.password)
            .pipe(first())
            .subscribe(
                response => {
                    if (response.status === 200) {
                        this.updateWishlistCountService.setWishlistCount(response?.wishlist_count)                        
                        this.configSettings.setLoginUserData(response.data)
                        this.cryptService.doEncrypt(JSON.stringify(response.data)).then(encryptedString => {
                            this.localStorage.setItem('userInfo', encryptedString).subscribe(() => {
                                this.configService.sendGTagEvent('login', {
                                    user_id: response.data.id,
                                    user_firstname: response.data.first_name,
                                    user_lastname: response.data.last_name,
                                    email: response.data.email
                                });
                                this.cartService.uploadOfflineCart(response.data.id).then(() => {
                                    this.cartService.clearStorage('cart');
                                    this.toaster.success("User has logged in successfully");
                                    loginForm.reset();
                                    this.configSettings.setIsReferralCode(response.data.referral_code)
                                    this.checkUserLoggedInService.setUserLoginStatus(true)
                                    this.showLoading=false;
                                    this.redirectTo();
                                });
                            });

                            this.cartService.readCartItems(response.data.id, this.lang, this.store_code)
                        });
                        this.analytics.login(
                            String("Email")
                        );
                    } else {
                        this.submitted = true;
                        this.hasError = true;
                        // this.message = response.message;
                        this.configSettings.toggleLoading(false);
                        this.toaster.error(response.message);
                    }
                }, error => { this.configSettings.toggleLoading(false); }
            );
    }

    /**
     * After user submit register form
     * @param registerForm
     */   
    onRegisterSubmit(registerForm: NgForm) {  
        if (this.lastNameRequired) {
            let warningMsg = this.lang == 'en' ? 'Please enter full name' : 'الرجاء إدخال الاسم الكامل'
            this.toaster.warning(warningMsg)
            return
        }         
        this.configSettings.toggleLoading(true);
         this.doRegister(registerForm);
        // registerForm.reset();
    }

    /**
     * Register user
     */
    doRegister(registerForm: NgForm) {
        this.showLoading=true;
        if (!this.newsletterSubscribed) {
            this.newsletterSubscribed = 0;
        }
        try {
            const param = {
                first_name: this.registerName.split(" ")[0],
                last_name: this.registerName.split(" ")[1],
                email: this.registerEmail,
                password: this.registerPassword,
                newsletter_subscribed: this.newsletterSubscribed,
                device_type: 'W',
                store: this.store_code,
                device_model: this.device,
                app_version: this.device,
                os_version: window.navigator.platform,
                referred_by_code: this.referred_by_code
            };
            console.log(param,"1");
            this.authenticationService.register(param)
                .pipe(first())
                .subscribe(
                    response => {                        
                        if (response.status == 200) {
                            const user = response.data as User;
                            this.analytics.completeRegistration(
                                +user.id,
                                (user.first_name + ' ' + user.last_name).trim(),
                                user.email,
                                'Email',
                            );
                            this.configSettings.setLoginUserData(response.data)
                            // window.location.href = "/my-account"
                            this.cryptService.doEncrypt(JSON.stringify(response.data)).then(encryptedString => {
                                this.localStorage.setItem('userInfo', encryptedString).subscribe(() => {
                                    this.cartService.uploadOfflineCart(response.data.id).then(() => {
                                        this.cartService.clearStorage('cart');
                                        this.toaster.success(response.message);
                                        registerForm.reset();
                                        this.configSettings.setIsReferralCode(response.data.referral_code);
                                        this.checkUserLoggedInService.setUserLoginStatus(true)
                                        this.showLoading=false;
                                        this.redirectTo();                                       
                                    });
                                });
                            });
                        } else {
                            this.registerSubmitted = true;
                            this.registerHasError = true;
                            this.registerMessage = response.message;
                            this.configSettings.toggleLoading(false);
                            this.toaster.error(response.message)
                        }
                    }, error => { this.configSettings.toggleLoading(false); }
                );
        } catch (e) {
            console.log(e);
            this.configSettings.toggleLoading(false);
        }
    }

    /**
     * After guest user submit register form
     * @param guestRegisterForm
     */
    onGuestRegisterSubmit(guestRegisterForm: NgForm) {    
        if (this.lastNameRequiredGuestUser) {
            let warningMsg = this.lang == 'en' ? 'Please enter full name' : 'الرجاء إدخال الاسم الكامل'
            this.toaster.warning(warningMsg)
            return
        } 
        if (this.validEmail == false) {
            let warningMsg = this.lang == 'en' ? 'Please enter valid email' : 'الرجاء إدخال بريد إلكتروني صحيح'
            this.toaster.warning(warningMsg)
            return
        }     
        this.configSettings.toggleLoading(true);
        this.doGuestRegister(guestRegisterForm);
        // guestRegisterForm.reset();
    }

    /**
     * Register guest user
     */
    doGuestRegister(guestRegisterForm: NgForm) {
        var fullNameArray = this.registerFullname.split(' ');
        var firstName = fullNameArray[0];
        var lastName = fullNameArray[1]?fullNameArray[1]:'';
        this.showLoading=true;
        try {
            const param = {
                first_name:firstName,
                last_name:lastName,
                email: this.registerEmail,
                device_type: 'W',
                device_model: this.device,
                app_version: this.device,
                store: this.store_code,
                os_version: window.navigator.platform
            };
            this.authenticationService.guestRegister(param)
                .pipe(first())
                .subscribe(
                    response => {
                        if (response.status === 200) {
                            this.configSettings.setLoginUserData(response.data)
                            this.cryptService.doEncrypt(JSON.stringify(response.data)).then(encryptedString => {
                                this.localStorage.setItem('userInfo', encryptedString).subscribe(() => {
                                    this.cartService.uploadOfflineCart(response.data.id).then(() => {
                                        this.cartService.clearStorage('cart');
                                        this.checkUserLoggedInService.setUserLoginStatus(true)
                                        this.redirectTo();
                                        this.showLoading=false;
                                        guestRegisterForm.reset();
                                    });
                                });
                            });
                            this.analytics.login(
                                String("Guest")
                            );
                        } else {
                            this.registerSubmitted = true;
                            this.registerHasError = true;
                            this.registerMessage = response.message;
                            this.configSettings.toggleLoading(false);
                            this.toaster.error(response.message)
                            setTimeout(function () {
                                this.registerSubmitted = false;
                                this.registerHasError = false;
                                this.registerMessage = '';
                            }, 5000);
                        }
                    }, error => { this.configSettings.toggleLoading(false); }
                );
        } catch (e) {
            console.log(e);
            this.configSettings.toggleLoading(false);
        }
    }
     
    contiueWithGoogle(){
        console.log('in ');        
       let btn=document.getElementById('googleBtn');
       btn.addEventListener('click', function(event) {
        console.log('Button clicked!', event);
    });
       console.log(btn.click());       
    }
//Google login
isGoogleVerify:boolean=false;  
    signInWithGoogle() {
        try {
            if (!this.googleData.email) {
                this.message = 'You have problem in your facebook account. Please try again later.';
                this.isGoogleVerify = true;
                return false;
            }
            const param = {
                google_id: this.googleData.id,
                first_name: this.googleData.firstName,
                last_name: this.googleData.lastName,
                gender: '',
                dob: '',
                email: this.googleData.email,
                social_register_type: 'G',
                device_token: '',
                store: this.store_code,
                device_type: 'W',
                device_model: (this.device) ? this.device : '',
                app_version: (this.device) ? this.device : '',
                os_version: (window.navigator.platform) ? window.navigator.platform : ''
            };
            this.authenticationService.socialRegister(param)
                .pipe(first())
                .subscribe(
                    response => {
                        if (response.status === 200) {
                            this.configSettings.setLoginUserData(response.data);
                            const user = response.data as User;
                            this.analytics.completeRegistration(
                                +user.id,
                                (user.first_name + ' ' + user.last_name).trim(),
                                user.email,
                                'Social Login',
                            );
                            this.cryptService.doEncrypt(JSON.stringify(response.data)).then(encryptedString => {
                                this.localStorage.setItem('userInfo', encryptedString).subscribe(() => {
                                    this.cartService.uploadOfflineCart(response.data.id).then(() => {
                                        this.cartService.clearStorage('cart');
                                        this.toaster.success(response.message);
                                        this.configSettings.setIsReferralCode(response.data.referral_code);
                                        this.checkUserLoggedInService.setUserLoginStatus(true);
                                        this.showLoading = false;
                                        this.redirectTo();
                                    });
                                });
                            });
                            this.analytics.login(
                                String("Google")
                            );
                        } else {
                            this.fbHasError = true;
                            this.message = response.message;
                            this.toaster.error(response.message);
                        }
                    }, error => { }
                );
        } catch (e) {
            console.log(e);
        }
    }
    /**
     * Sign in using facebook
     */
    signInWithFB(): void {
        this.showLoading=true;
        this.configSettings.toggleLoading(true);
        try {
            this.authService.signIn(FacebookLoginProvider.PROVIDER_ID).then(
                (userData) => {
                    if (!userData.email) {
                        this.message = 'You have problem in your facebook account. Please try again later.';
                        this.fbHasError = true;
                        this.configSettings.toggleLoading(false);
                        return false;
                    }

                    const param = {
                        facebook_id: userData.id,
                        first_name: userData.firstName,
                        last_name: userData.lastName,
                        gender: '',
                        dob: '',
                        email: userData.email,
                        social_register_type: 'F',
                        device_token: '',
                        store: this.store_code,
                        device_type: 'W',
                        device_model: (this.device)? this.device : '',
                        app_version: (this.device)? this.device : '',
                        os_version: (window.navigator.platform)? window.navigator.platform : ''
                    };
                    this.configSettings.toggleLoading(true);
                    this.authenticationService.socialRegister(param)
                    .pipe(first())
                    .subscribe(
                        response => {
                            if (response.status === 200) {
                                this.configSettings.setLoginUserData(response.data)
                                const user = response.data as User;
                                this.analytics.completeRegistration(
                                    +user.id,
                                    (user.first_name + ' ' + user.last_name).trim(),
                                    user.email,
                                    'Social Login',
                                );
                                this.cryptService.doEncrypt(JSON.stringify(response.data)).then(encryptedString => {
                                    this.localStorage.setItem('userInfo', encryptedString).subscribe(() => {
                                        this.cartService.uploadOfflineCart(response.data.id).then(() => {
                                            this.cartService.clearStorage('cart');
                                            this.toaster.success(response.message);
                                            this.configSettings.setIsReferralCode(response.data.referral_code);
                                            this.checkUserLoggedInService.setUserLoginStatus(true)
                                            this.showLoading=false;
                                            this.redirectTo();
                                        });
                                    });
                                });
                                this.analytics.login(
                                    String("Facebook")
                                );
                            } else {
                                this.fbHasError = true;
                                this.message = response.message;
                                this.configSettings.toggleLoading(false);
                                this.toaster.error(response.message)
                            }
                        }, error => { this.configSettings.toggleLoading(false); }
                );
                }, error => { this.configSettings.toggleLoading(false); }
            );
        } catch(e) {
            console.log(e);
            this.configSettings.toggleLoading(false);
        }
    }

    redirectTo() {
        let href=window.location.pathname;
        // this if is added to ensure that if user is comming from cart or bag then it will redirect user to cart page
        if (href.includes('/login/guest')) {
            this.router.navigate(['/cart']);
        } else {
            let url = this.configSettings.getPreviousUrl();
            this.router.navigate([url]);
        }
        return;
        let previousUrl : string = '';
        let currentUrl : string = '';    
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
              // Get the previous URL
              previousUrl = event.url;
              console.log(previousUrl,'previousUrl');
              
            }
          });
        this.router.events
            .pipe(filter((evt: any) => evt instanceof RoutesRecognized), pairwise())
            .subscribe((events: RoutesRecognized[]) => {
                console.log(events,'events');
                
                previousUrl = events[0].urlAfterRedirects;
                currentUrl =  events[1].urlAfterRedirects
                console.log('previous url',previousUrl);
                console.log('current url', currentUrl);
            });
        // this.location.back()
    }

    signInWithApple() {
        // const CLIENT_ID = "com.leza.Wishlist.backend"
        const CLIENT_ID = "web.com.staging.shop-twl"
        const REDIRECT_API_URL = environment.apiUrl+'apple-social-auth'
        window.open(
            `https://appleid.apple.com/auth/authorize?client_id=${CLIENT_ID}&redirect_uri=${encodeURIComponent(REDIRECT_API_URL)}&response_type=code id_token&scope=name email&response_mode=form_post`,
            '_blank'
        );

        window.addEventListener('message', async event => {
            // console.log(event);
            const decodedToken = this.jwtHelper.decodeToken(event.data.id_token);
            // console.log(decodedToken);

            let requestData: any = {}
            if (event.data.user) {
                const userName = JSON.parse(event.data.user);
                requestData = {
                    email: decodedToken.email,
                    name: `${userName.name.firstName} ${userName.name.lastName}`,
                    socialId: decodedToken.sub,
                };
            } else {
                requestData = {
                    email: decodedToken.email,
                    socialId: decodedToken.sub,
                };
            }
            // console.log(requestData);
            let firstName = undefined;
            let lastName = undefined;
            if (requestData.name) {
                const name = requestData.name;
                if (name.indexOf(' ') != -1) {
                    firstName = name.substring(0, name.indexOf(' '));
                    lastName = name.substring(name.indexOf(' ') + 1);
                } else {
                    firstName = name;
                }
            }
            const param = {
                apple_id: requestData.socialId,
                first_name: firstName,
                last_name: lastName,
                email: requestData.email,
                social_register_type: 'A',
                device_token: '',
                device_type: 'W',
                store: this.store_code,
                device_model: this.device,
                app_version: this.device,
                os_version: window.navigator.platform
            };

            // console.log(param);
            

            this.authenticationService.socialRegister(param)
            .pipe(first())
            .subscribe(
                response => {
                    if (response.status === 200) {
                        this.cryptService.doEncrypt(JSON.stringify(response.data)).then(encryptedString => {
                            this.localStorage.setItem('userInfo', encryptedString).subscribe(() => {
                                this.cartService.uploadOfflineCart(response.data.id).then(() => {
                                    this.cartService.clearStorage('cart');
                                    this.redirectTo();
                                });
                            });
                        });
                    } else {
                        this.fbHasError = true;
                        this.message = response.message;
                        this.configSettings.toggleLoading(false);
                    }
                }, error => { this.configSettings.toggleLoading(false); }
            );
        });
    };

    toggleLoginPasswordVisibility(){
        this.isLoginPasswordVisible = !this.isLoginPasswordVisible
    }
    onChange(newValue:any) {  
        const validEmailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (validEmailRegEx.test(newValue.srcElement.value)) {
            this.validEmail = true;
        }else {
            this.validEmail = false;
        }
    }


    // update breadcrumb based on router url
    updateBreadCrumb() {
        this.route.url.subscribe(urlSegments => {

            // console.log(urlSegments, 'urlSegments');

            // Check if "guest" is present in the urlSegments array
            const hasGuestPath = urlSegments.some(segment => segment.path === 'guest');

            // Set enableGuestLogin to true if "guest" is found, otherwise set it to false
            this.enableGuestLogin = hasGuestPath;

            if (this.enableGuestLogin) {
                this.applyGuestLoginChanges()
            } else {
                this.breadCrumb = ['SIGN IN']
            }
        });
    }

    // change form layout if guest user
    applyGuestLoginChanges() {
        this.type = 'guest'
        this.breadCrumb = ['MY BAG', 'SIGN IN']
        // setTimeout(() => {
        //     let guestRadioBtn = document.getElementById('guest-radio-button') as HTMLInputElement;
        //     guestRadioBtn.checked = true
        // }, 500);

    }

    validateEmail() {
        const validEmailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        this.isEmailValid = validEmailRegEx.test(this.registerEmail);
    }

    isRegisterPasswordValid: boolean = false;
    passwordValidation(event) {
        const password = event.target.value;
        this.isRegisterPasswordValid = password.length >= 6;
    }

    lastNameRequired: boolean = false; 
    lastNameRequiredGuestUser: boolean = false; 
    // to validate full name that is : first name is entered, but last name is missing after a space
    validateFullName() {
        const names = this.registerName.split(' ');
        this.lastNameRequired = names.length > 0 && names[0] !== '' && names[names.length - 1] === '';
    }
    validateFullNameGuestUser() {
        const names = this.registerFullname.split(' ');
        this.lastNameRequiredGuestUser = names.length > 0 && names[0] !== '' && names[names.length - 1] === '';
    }
      


    scrollToTop() {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }
    
}
