import { Component, OnInit } from '@angular/core';
import {HttpParams} from "@angular/common/http";
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ConfigSettings } from '../config/config.settings';

declare var branch: any;

@Component({
  selector: 'app-deep-link',
  template: '',
  styleUrls: ['./deep-link.component.css']
})
export class DeepLinkComponent implements OnInit {
  liveKey = environment?.branchIoLive
  devKey =  environment?.branchIoTest
  urlHost = window.location.hostname;
  branchKey: string='';
  lang: any;

  constructor(
    private router: Router,
    private configSettings:ConfigSettings

  ) { 
    this.lang = this.configSettings.getLang()
    // if (this.urlHost.includes('staging') || this.urlHost.includes('web') || this.urlHost.includes('localhost')) {
    //   this.branchKey = this.devKey
    // } else {
      this.branchKey = this.liveKey
    // }
  }

  ngOnInit() {
    this.registerBranch();
      branch.init(this.branchKey, function(err, data) { // key_test_ndzZ2665S95j2JJpWDtCAhikurjUHvrz
          const target = data.data_parsed['target'];
          const id = data.data_parsed['target_id'];
          console.log(data.data_parsed['tier_id']);          
          let tierId = data.data_parsed['tier_id'] ? data.data_parsed['tier_id'] : 3;
          console.log(tierId,'tierId');
          const name = data.data_parsed['$og_title'].trim().toLowerCase().replace(/[^a-zA-Z\d\s]/,'').replace(' ','-');        
          if(target === 'P') {
            window.location.href = "/product/detail/"+id;
          } else if(target === 'CL') {
            window.location.href = "/category/" + name + "/" + id;
          } else if(target === 'B' || target === 'BR') {
            window.location.href = "/brands/" + name + "/" + id + "/" + tierId
          } else if (target === 'C' || target === 'FC') {
            this.router.navigate(['/category', name, id])
            return
          } else if (target === 'FP') {
            this.router.navigate(['/product/detail', id])
            return;
          } else if (target === 'S') {
            this.router.navigate(['/shop', name, id])
            return;
          } else if (target === 'F') {
            this.router.navigate(['/search'], { queryParams: this.getCollectionQueryParams(data, 0) })
            return;
          }else if (target === 'N') {
            this.router.navigate(['/shop/new-arrivals'], { queryParams: this.getCollectionQueryParams(data, 1) })
            return;
          }
      });
  }

  registerBranch() {
      this.registerBranchSource(window, document, "script", "branch", function (b, r) { b[r] = function () { b._q.push([r, arguments]); }; }, { _q: [], _v: 1 }, "addListener applyCode autoAppIndex banner closeBanner closeJourney creditHistory credits data deepview deepviewCta first getCode init link logout redeem referrals removeListener sendSMS setBranchViewData setIdentity track validateCode trackCommerceEvent logEvent disableTracking".split(" "), 0);
  }

  registerBranchSource(b, r, a, n, c, h, _, s) {
      if (!b[n] || !b[n]._q) {
          for (; s < _.length;)
              c(h, _[s++]);
          let d = r.createElement(a);
          d.async = 1;
          d.src = "https://cdn.branch.io/branch-latest.min.js";
          let k = r.getElementsByTagName(a)[0];
          k.parentNode.insertBefore(d, k);
          b[n] = h;
      }
  }
    // get query params based on latestId
    getCollectionQueryParams(collection: any, latestId: any): any {
      if (latestId == 0) {
        return {
          q: '',
          lang: this.lang,
          price_range: '',
          in_stock: '',
          typepage: '1',
          per_page: '20',
          is_featured: '',
          latest: latestId,
          best_selling: '',
          sort_by: '1',
          store: '',
          flash_sale_id: collection.link_id,
          shop_type_id: ''
        };
      } else {
        return {
          lang: this.lang,
          price_range: '',
          in_stock: '',
          typepage: '1',
          per_page: '20',
          is_featured: '',
          latest: latestId,
          best_selling: '',
          sort_by: '1',
          store: '',
          flash_sale_id: '',
          shop_type_id: ''
        };
      }
  
    }

}
