import { Component, OnInit } from '@angular/core';
import { Store } from "../models/store";
import { UserService } from "../config/user.service";
import { ConfigSettings } from "../config/config.settings";
import { Product } from "../models/product";
import { ConfigService } from "../config/config.service";
import { Router } from "@angular/router";
import { WishlistService } from '../config/wishlist.service';
import { listStagger } from '../animations';
import { FooterService } from '../config/footer.service';
import { CallWishlistService } from '../services/call-wishlist.service';
import { UpdateWishlistCountService } from '../services/update-wishlist-count.service';

@Component({
    selector: 'app-wishlist',
    templateUrl: './wishlist.component.html',
    styleUrls: ['./wishlist.component.scss'],
    animations: [listStagger]
})
export class WishlistComponent implements OnInit {
    lang: string;
    store: Store;
    userId: string;
    didLoad: boolean = false;
    products: Product[] = [];
    currency: string;
    dataAvailable = false;
    skeletonLoader:boolean=true;
    isMobile: boolean = false;
    skeltonTheme:any;
    removeSubscriber$:any;

    constructor(
        private userService: UserService,
        private configSettings: ConfigSettings,
        private configService: ConfigService,
        private router: Router,
        private wishlistService: WishlistService,
        protected footerService: FooterService,
        private callWishListService:CallWishlistService,
        private updateWishlistCountService: UpdateWishlistCountService

    ) {
        this.configSettings.toggleLoading(true);
    }

    ngOnInit() {
        this.isMobile = window.innerWidth < 600;
        this.skeletonLoader=true;          
        this.skeltonTheme=this.setThemeLogic();
        this.lang = this.configSettings.getLang();
        this.currency = this.configSettings.getCurrency();
        this.userService.getUserSession().then(user => {
            if (user !== false) {
                this.userId = user.id;
            }

            /* getting store from config*/
            this.configSettings.getStoreObject().then((result) => {
                this.store = <Store>result;
                this.currency = result['currency_' + this.lang];
                this.getWishlist();
            });
        }, error => { this.configSettings.toggleLoading(false); });

        this.userService.wishlistOutput.subscribe(items => { this.products = items; });      
        this.removeSubscriber$=this.callWishListService.getLoader().subscribe((res)=>{
            if (res.length != 0) {
                this.skeletonLoader = true;
                setTimeout(() => {
                    this.skeletonLoader = false;
                }, 2000);
            }
        })
    }
      // logic for skelton loader theme for big and small device
  setThemeLogic() {
    return {
      'border-radius': '5px',
    //   'height': this.isMobile ? '120px' : '500px',
        'height': '40px',
      'animation-duration': '2s',
    //   'background-color': '#F1F1F1',
    };
  }

    getWishlist() {
        this.skeletonLoader=true;
        const params = {
            user_id: this.userId,
            lang: this.lang,
            store: this.store.iso_code
        };

        this.configService.readRequest('user-wishlist', params)
            .subscribe(response => {
                this.products = response.body.data;
                this.didLoad = true;
                this.skeletonLoader=false;
                this.configSettings.toggleLoading(false);
                this.wishlistService.changWishlistCount(response.body.data.length);
                this.updateWishlistCountService.setWishlistCount(response.body.data.length)
                this.dataAvailable = true;
                setTimeout(() => { this.footerService.toggleFooter(true) }, 300);
            }, error => { this.configSettings.toggleLoading(false); });
    }

    scrollToTop() {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }

}
