import { Injectable } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";

@Injectable({
    providedIn: 'root'
})
export class ChangeMetaService {

    constructor(
        private meta: Meta,
        private title: Title
    ) { }

    updateMetaTags(title: any, description: any) {
      let extraTitle='';
        if(title=='The Wishlist | Discover fashion online!'){
          extraTitle='';
        }else{
          extraTitle=' | The Wishlist';
        }
        this.title.setTitle(title.toUpperCase() + extraTitle);
        this.meta.updateTag({ name: 'title', content: title.toUpperCase() + extraTitle });
        this.meta.updateTag({ name: 'description', content: description });
        this.meta.updateTag({ property: 'og:title', content: title.toUpperCase() + extraTitle });
        this.meta.updateTag({ property: 'og:description', content: description });
        this.meta.updateTag({ name: 'twitter:title', content: title.toUpperCase() + extraTitle });
        this.meta.updateTag({ name: 'twitter:description', content: description });
    }

}