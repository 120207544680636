
  <div class="MAIN-PRODUCT product-item">
    <div class="PRODUCT ratio-image-container">
        <div class="product-img-dummy">
            <div>
            <img [routerLink]="['/product/detail/', item.id]" [src]="imgSrc"  (mouseover)="onMouseOver($event)" (mouseout)="onMouseOut($event)" class="mouseHover">
            <button class="btn pointer">
                <ng-container>
                  <img src="../../assets/ArtWork/ic_wish_list_selected.png" class="ProdAddWishlist" alt="item.name" (click)="toggleWishlist()" *ngIf="in_wishlist">
                  <img src="../../assets/ArtWork/Wishlist@2x.png" class="ProdAddWishlist" alt="item.name" (click)="toggleWishlist()" *ngIf="!in_wishlist">
                </ng-container>
              </button>
            </div>
        </div>
    </div>
    <div class="">
        <a class="product-details-wrapper-link" [routerLink]="'product/detail/'+id">
              <div class="row m-0 w-100 pl-1">
        
        
                <div class="col-12 col-md-12 p-0 product-details-name">
                  <h4 class="product-brand font-black">
                    {{item.name}}
                  </h4>
                  <h5 class="product-name" *ngIf="!minimal">
                    {{item.brand_name}}
                  </h5>
                </div>
        
                <ng-container >
                  <div class="col-12 col-md-12 p-0 product-price-details">
                    <div class="product-price main-price">
                        <p>{{currency}} {{item.final_price | number:'1.3-3'}} <span *ngIf="(item.regular_price != item.final_price)"><del>{{currency}} {{item.regular_price | number:'1.3-3'}}</del></span></p>
                    </div>
                    
                  </div>
                </ng-container>
        
              </div>
          </a>
      </div>
</div>