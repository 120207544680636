import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UpdateWishlistCountService {

  private wishlistCount: BehaviorSubject<number>;

  constructor() {
    // to set the wishlistCount with count obtained from session storage.
    this.wishlistCount = new BehaviorSubject<number>(this.obtainWishlistCount());
  }

  // to set the wishlist count and save it to session storage.
  setWishlistCount(count: number) {
    this.saveWishListCount(count); 
    this.wishlistCount.next(count); 
  }

  // to get an observable with current wishlist count.
  getWishlistCount(): Observable<number> {
    return this.wishlistCount.asObservable();
  }

  // to remove an item from the wishlist by setting the count to 0.
  removeFromWishlist() {
    this.setWishlistCount(0); 
  }

  // to save the wishlist count to session storage.
  saveWishListCount(count: number) {
    window.sessionStorage.setItem('wishlist-count', JSON.stringify(count));
  }

  // to obtain the wishlist count from session storage or return 0 if it's not available.
  obtainWishlistCount(): number {
    const count = JSON.parse(window.sessionStorage.getItem('wishlist-count'));
    return count !== null ? count : 0; 
  }
}
