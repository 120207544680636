<ng-container *ngIf="dataAvailable">
  <section class="page-wrapper main-profile" id="my-profile" @listStagger>
    <h4 class="page-title text-center font-condensed">{{'MY ACCOUNT' | translate}}</h4>
    <p class="text-center font-small mb-0">{{'Hello User' | translate:param}}</p>
    <p class="text-center font-small mb-2">{{'Via the different options we will show you, you can modify your personal details, view your orders or manage your Wishlist.' | translate}}</p>
    <section class="container">
      <div class="row m-sm-0">
        <div class="col-12 col-sm-12 col-md-6 user-left-menu d-none d-lg-block">
          <app-user-account-menu [page]="1"></app-user-account-menu>
        </div>
        <div class="col-12 col-sm-12 col-md-6 p-2 p-md-3 ">
          <img src="assets/images/my-account.jpg" class="img-fluid" />
        </div>
      </div>
    </section>
  </section>
</ng-container>
