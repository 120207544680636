<div class="my_account_data" *ngIf="!dataAvailable">
    <div class="container-fluid " id="cart-list">
        <div class="cart_data">
            <div class="cart_product  my-4">
                <div class="width-heading mb-2 mt-5">
                    <ngx-skeleton-loader  count="1"></ngx-skeleton-loader>
                </div>
                <div class="d-flex flex-column flex-lg-row align-items-center align-items-lg-start gap-3 gap-md-5">
                    <div class="product-colum">
                        <div class="cart_product_data border_cart" *ngFor="let item of [1,2,3]; let i = index;"
                            id="cart-{{item.id}}">
                            <div class="d-flex  gap-3">
                                <div>
                                    <div class="product_image items-height-skeleton">
                                        <ngx-skeleton-loader class="height-fix"></ngx-skeleton-loader>
                                    </div>
                                </div>
                                <div class="w-100 position-relative">
                                    <div class="box-data d-flex flex-column justify-content-between h-100">
                                        <div
                                            class="d-flex product-colum-div my-product justify-content-between w-100 gap-2 gap-md-4">
                                            <div class="width-heading">
                                                <div class="product_details">
                                                    <ngx-skeleton-loader count="3"></ngx-skeleton-loader>
                                                </div>
                                            </div>
                                            <div class="width-heading text-start text-md-center product_qty">
                                                <div class="qty_data">
                                                    <div class="">
                                                        <ngx-skeleton-loader></ngx-skeleton-loader>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="width-heading text-md-end product_price py-2 py-md-0 ">
                                                <ngx-skeleton-loader></ngx-skeleton-loader>
                                            </div>
                                        </div>
                                        <div class="w-100">
                                            <div class="move_delete">
                                                <div class="d-flex gap-3 gap-md-4 align-items-center">
                                                    <p class="skeleton-delet">
                                                        <ngx-skeleton-loader></ngx-skeleton-loader>
                                                    </p>
                                                    <p class="skeleton-delet">
                                                        <ngx-skeleton-loader></ngx-skeleton-loader>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="amount-colum sticky">
                        <div class="payment_box">
                            <h2 class="">
                                <ngx-skeleton-loader></ngx-skeleton-loader>
                            </h2>
                            <div class="payment-amount-div" *ngFor="let item of [1,2]">
                                <div class="d-flex justify-content-between align-items-center gap-3">
                                    <p class="w-50">
                                        <ngx-skeleton-loader></ngx-skeleton-loader>
                                    </p>
                                    <p class="w-50">
                                        <ngx-skeleton-loader></ngx-skeleton-loader>
                                    </p>
                                </div>
                            </div>
                            <p class="w-50 mx-auto">
                                <ngx-skeleton-loader></ngx-skeleton-loader>
                            </p>
                            <ngx-skeleton-loader></ngx-skeleton-loader>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="my_account_data" *ngIf="dataAvailable">
<div class="container-fluid " id="cart-list" @listStagger>    
        <div class="cart_data" *ngIf="cart && cart.items.length > 0">
             <h4>{{"You have"| translate}} {{cart?.items.length}} <span *ngIf="cart?.items.length < 2 ">{{'item' | translate}}</span> <span *ngIf="cart?.items.length > 1">{{'items' | translate}}</span> {{"in your Shopping Bag"| translate}}</h4>
            <div class="cart_product  my-4">
                <div class="d-flex flex-column flex-lg-row align-items-center align-items-lg-start gap-3 gap-md-5">
                    <div class="product-colum">
                        <div class="cart_product_data border_cart" *ngFor="let item of cart.items; let i = index;"
                            id="cart-{{item.id}}">
                            <div class="d-flex  gap-3">
                                <div>
                                    <div class="product_image">
                                        <figure>
                                            <div class="image-wrapper">
                                                <img src="{{item.image}}" class="product-img">
                                            </div>
                                        </figure>
                                    </div>
                                </div>
                                <div class="w-100 position-relative">
                                    <div class="box-data">
                                        <div class="d-flex product-colum-div justify-content-between gap-2 gap-md-4">
                                            <div class="w-100">
                                                <div class="product_details">
                                                    <h1>{{item.brand_name | uppercase}}</h1>
                                                    <h5>{{item.name}}</h5>
                                                    <!-- <p *ngIf="item.configurable_option"><span
                                                            *ngFor="let option of item.configurable_option; let j = index;">
                                                            <ng-container *ngIf="option">
                                                               
                                                                <span class=""
                                                                    [hidden]="showOptionSelect[i] && j === (item.configurable_option.length - 1)">{{option.attributes[0].value}}</span>

                                                            </ng-container>
                                                        </span>
                                                    </p> -->
                                                    
                                                     <p class="item-color-size d-flex gap-2 align-items-center" *ngIf="userId!=undefined">
                                                        <span>{{item?.configurable_option[1]?.attributes[0]?.value}} </span>
                                                        <span *ngIf="item?.configurable_option[0]?.attributes[0]?.value"> | </span>
                                                        <span>{{ item?.configurable_option[0]?.attributes[0]?.value }}</span>
                                                     </p>
                                                     <p class="item-color-size d-flex gap-2 align-items-center" *ngIf="userId==undefined">
                                                        <span>{{item?.configurable_option[1]?.attributes[0]?.value}} </span>
                                                        <span *ngIf="item?.configurable_option[0]?.attributes[0]?.value"> | </span>
                                                        <span>{{ item?.configurable_option[0]?.attributes[0]?.value }}</span>
                                                     </p>
                                                    <span class="d-none d-md-block text-red"
                                                        *ngIf="item?.remaining_quantity?.length <=4">{{'Low Stock, only' | translate}} {{
                                                        item?.remaining_quantity }} {{'left' | translate}}.</span>
                                                </div>
                                            </div>
                                            <div class="w-100 text-start text-md-center product_qty">
                                                <div class="qty_data">
                                                    <div class="d-flex justify-content-md-center align-items-center gap-4 qty-data-box ">
                                                        <img src="/assets/icons/Minus-new.svg" alt="" [ngClass]="selectedQuantity[i]>1 ? ' ':'disableClick' " (click)="updateCart(-1,i,item?.remaining_quantity)">
                                                        <p>{{selectedQuantity[i]}}</p>
                                                        <img src="/assets/icons/Plus-new.svg" alt="" (click)="updateCart(1,i,item?.remaining_quantity)">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="w-100 text-md-end product_price py-2 py-md-0">
                                                <h3>{{currency}} {{item.final_price | number:'1.3-3'}}</h3>
                                            </div>
                                        </div>
                                        <span class="d-block d-md-none text-red pb-2"
                                            *ngIf="remaining_quantity?.length <=4">{{'Low Stock, only' | translate}} {{
                                            item?.remaining_quantity }} {{'left' | translate}}.</span>
                                        <div class="wish-delete-text bottom-0 ">
                                            <div class="move_delete" >
                                                <div class="d-flex gap-3 gap-md-4 align-items-center">
                                                    <p (click)="saveForLater(item, i)">{{'MOVE TO WISHLIST' |
                                                        translate}}</p>
                                                    <p class="delet" (click)="deleteFromCart(item, i)">{{'DELETE' | translate}}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                    <div class="amount-colum sticky">
                        <div class="payment_box">
                            <h2 class="mb-4">{{'Your Shopping Bag' | translate}}</h2>

                            <div class="payment-amount-div">
                                <div class="d-flex justify-content-between align-items-center gap-3">
                                    <p>{{'Sub - Total' | translate}}</p>
                                    <p>{{currency}} {{cartValue | number:'1.3-3'}}</p>
                                </div>
                            </div>
                            <div class="mt-2 payment-amount-div" *ngIf="shippingCost>0">
                                <div class="d-flex justify-content-between align-items-center gap-3">
                                    <p>{{'Delivery' | translate}}</p>
                                    <p>{{currency}} {{shippingCost| number:'1.3-3'}}</p>
                                </div>
                            </div>
                            <div class="mt-2 payment-amount-div">
                                <div class="d-flex justify-content-between align-items-center gap-3">
                                    <h3>{{'Total' | translate}}</h3>
                                    <h3>{{currency}} {{((+cartValue) + (+shippingCost)) | number:'1.3-3'}}</h3>
                                </div>
                            </div>
                            <span class="d-block mt-4">{{'VAT INCLUDED' | translate}}</span>
                            <button type="button" (click)="routeTo()">{{'CONTINUE' | translate}}</button>
                        </div>
                    </div>
                </div>
            </div>
            
             <div class="also_bought" *ngIf="recommendedProd?.length">
                <h1>{{'CUSTOMERS ALSO BOUGHT' | translate}}</h1>

                <div class="also_bought_slider">
                    <ngx-slick-carousel class="carousel" [config]="slideCustomers">
                        <div ngxSlickItem *ngFor="let item of recommendedProd" class="slide mx-2  you-may-like">
                            <!-- {{item | json}} -->
                          <app-product-item [lang]="lang" [item]="item" [currency]="currency" [store]="store" [userId]="userId"
                            [returnUrl]="'home'" [minimal]="false"  [showWishlistIcon]="true">
                          </app-product-item>
                        </div>
                      </ngx-slick-carousel>
                </div>

             </div>

        </div>

        <div class="empty-cart" *ngIf="didLoad && (!cart || !cart.items.length)">
            <div class="empty-cart-content white-container">
                <h4 class="page-title font-bold text-center">{{'SHOPPING BAG IS EMPTY' | translate}}</h4>
                <p class="font-regular text-center"> {{'You have no items in your shopping bag yet.' | translate}} <i class="fas fa-shopping-bag"></i></p>
                <div class="text-center">
                    <a [routerLink]="['/home']" class="custom-button btn btn-bg btn-block">{{'CONTINUE SHOPPING' | translate}}</a>
                </div>
            </div>
        </div>

</div>
</div>
<app-add-to-bag-popup></app-add-to-bag-popup>
