import { Component, OnInit } from '@angular/core';
import {User} from "../models/user";
import {UserService} from "../config/user.service";
import { ConfigSettings } from '../config/config.settings';
import { listStagger } from '../animations';
import { FooterService } from '../config/footer.service';
import { FormBuilder, NgForm } from '@angular/forms';
import { AuthenticationService } from '../config/authentication.service';
import { first } from 'rxjs/operators';
import { CartService } from '../config/cart.service';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { CryptService } from '../config/crypt.service';
import { NavbarService } from '../config/navbar.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-my-details',
  templateUrl: './my-details.component.html',
  styleUrls: ['./my-details.component.scss'],
  animations: [ listStagger ]
})
export class MyDetailsComponent implements OnInit {

  user: User;
  isLoginPasswordVisible: boolean = false
  isLoginPasswordVisible1: boolean = false
  isLoginPasswordVisible2:boolean=false
  newPassword:string=''
  currentPassword:string=''
  confirmPassword:string=''
  lang: string;
  dataAvailable = false;
  submitted = false;
  hasError = false;
  message: string;
  allow_password_change = false;
  today:any="";
  day: any;
  month: any;
  year: string;
  years:number[];
  days: number[] = Array.from({ length: 31 }, (_, i) => i + 1);
  months: number[] = Array.from({ length: 12 }, (_, i) => i + 1);
  monthsArr: Array<string> = []

  constructor(
    private userService: UserService,
    private configSettings: ConfigSettings,
    protected footerService: FooterService,
    protected authenticationService: AuthenticationService,
    private cryptService: CryptService,
    protected localStorage: LocalStorage,
    private cartService: CartService,
    private navbarService:NavbarService,
    private toastr: ToastrService,
    private fb:FormBuilder,
    private translateService:TranslateService
  ) { 
    this.day = '';
    this.month = '';
    this.year = '';
    
    
    const currentYear = new Date().getFullYear();
    this.years = Array.from({ length: currentYear - 1949 }, (_, i) => currentYear - i);
    
  }

  ngOnInit() {
    this.navbarService.toggleMainNavigation(true);
    this.navbarService.isEnableHeaderBottomAction(true);
    this.footerService.toggleFooter(true);
    this.today = new Date();
    this.lang = this.configSettings.getLang();
    // this.doUpdateProfile()
    
   this.monthNumToName();
  
    this.userService.getUserSession().then(response => {
        if (response !== false) {
            this.user = response;
            console.log(this.user,'user');
            
            if (!this.user.is_social_register) {
                this.allow_password_change = true;
            }
            this.dataAvailable = true;
            setTimeout(() => {this.footerService.toggleFooter(true)}, 300);
            this.getDate()
        }
    });
  }
  togglePasswordVisibility(){
    this.isLoginPasswordVisible = !this.isLoginPasswordVisible
  }
  togglePasswordVisibility1(){
    this.isLoginPasswordVisible1 = !this.isLoginPasswordVisible1
  }
  togglePasswordVisibility2(){
    this.isLoginPasswordVisible2 = !this.isLoginPasswordVisible2
  }
  /**
   * After user submit form
   * @param updateProfileForm
   */
onSubmit(updateProfileForm: NgForm) {
  this.configSettings.toggleLoading(true);
  // this.validateConfirmPassword();
// console.log(this.newPassword.length,'len');

  if (this.newPassword.length > 0) {
  this.validateConfirmPassword();
  } else if (this.user.first_name != '' && this.user.first_name != undefined && this.user.last_name != '' && this.user.last_name != undefined && this.user.email != '' && this.user.email != undefined && this.day!='' && this.day!=undefined && this.month!='' && this.month!=undefined && this.year!='' && this.year!=undefined) {
    this.configSettings.toggleLoading(true);
    this.doUpdateProfile();
  }else{
    this.translateService.get("Please enter all required fields").subscribe(res => {
      this.toastr.error('', res);
    })
  }

    // updateProfileForm.reset();
}


  validateConfirmPassword(){
      if (this.newPassword != this.confirmPassword) {
        let warningMsg = this.lang == 'en' ? 'Confirm password does not match new password' : 'تأكيد كلمة المرور لا يطابق كلمة المرور الجديدة'
        this.toastr.warning(warningMsg)
        return
      }else{
        this.doChangePassword();
      }
  }

  /**
   * Change user password
   */
  addDays() {
    if (!this.year || !this.month || !this.day) {
      return ""; // Return an empty string if any of the date components is not selected
    }
    const date = `${this.year}-${this.month}-${this.day}`;
  this.year=date.split("-")[0]
  this.month=date.split("-")[1]
  this.day=date.split("-")[2]
return date
    
    
  }

  // to handel profile update
  doUpdateProfile() {
  
    try {
      // let dob = this.addDays( this.user.dob,1)
      this.configSettings.toggleLoading(true);
      const params = {
        user_id: this.user.id,
        first_name: this.user.first_name,
        last_name:this.user.last_name,
        dob: this.addDays()
      };     
       
      this.authenticationService.editProfile(params)
            .pipe(first())
            .subscribe(
                response => {
                    this.message = response.message;
                    if (response.status === 200) {
                      this.toastr.success(response.message)
                      this.hasError = false;
                      this.cryptService.doEncrypt(JSON.stringify(response.data)).then(encryptedString => {
                        this.localStorage.setItem('userInfo', encryptedString).subscribe(() => {
                            this.cartService.uploadOfflineCart(response.data.id).then(() => {});
                        });
                    });
                    } else {
                      this.hasError = true;
                      this.toastr.error(response.message)
                    }
                    this.submitted = true;
                    this.configSettings.toggleLoading(false);
                }, error => { this.configSettings.toggleLoading(false); }
            );
    } catch (e) {
      this.configSettings.toggleLoading(false);
      console.log(e);
    }
  }

  doChangePassword() {
    try {
      this.configSettings.toggleLoading(true);
      const params = {
        user_id: this.user.id,
        old_password: this.currentPassword,
        new_password: this.newPassword
      };
      this.authenticationService.changePassword(params)
            .pipe(first())
            .subscribe(
                response => {
                    this.message = response.message;
                    if (response.status === 200) {
                      this.hasError = false;
                      this.toastr.success(response.message)
                    } else {
                      this.hasError = true;
                      if(response.status === 201){
                        // this.message = 'Current password did not match';
                        this.toastr.error(response.message)
                        return
                      }
                    }
                    this.submitted = true;
                    this.configSettings.toggleLoading(false);
                }, error => { this.configSettings.toggleLoading(false); }
            );
    } catch (e) {
      this.configSettings.toggleLoading(false);
      console.log(e);
    }
  }
  // to get dob from user and split it into day month and year
  getDate() {
    let date = this.user.dob.split('-')
    this.day = parseInt(date[2]);
    this.month = parseInt(date[1]);
    this.year = date[0];

  }
  //To get months name

  monthNumToName() {
    const enMonths = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];

    const arMonths = [
      "يناير", "فبراير", "مارس", "أبريل", "مايو", "يونيو",
      "يوليو", "أغسطس", "سبتمبر", "أكتوبر", "نوفمبر", "ديسمبر"
    ];

    this.monthsArr = this.lang == 'en' ? enMonths : arMonths

    
  }
  
}
