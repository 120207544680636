import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import {UserService} from "../config/user.service";
import {CartService} from "../config/cart.service";
import {ConfigSettings} from "../config/config.settings";
import {Address} from "../models/address";
import {Store} from "../models/store";
import {ConfigService} from "../config/config.service";
import { listStagger } from '../animations';
import { FooterService } from '../config/footer.service';
import { NavbarService } from '../config/navbar.service';
import { AddressHelpersService } from '../services/address-helpers.service';

@Component({
  selector: 'app-my-address',
  templateUrl: './my-address.component.html',
  styleUrls: ['./my-address.component.scss'],
  animations: [ listStagger ]
})
export class MyAddressComponent implements OnInit {
  addresses: Address[] = [];
  userId: string;
  lang: string;
  didLoad: boolean = false;
  store: Store;
  currency: string = '';
  dataAvailable = false;
  isMobile: any = false;
  skeltonTheme:any;
  skeletonLoader=true;
  constructor(
      private userService: UserService,
      private configSettings: ConfigSettings,
      private configService: ConfigService,
      protected footerService: FooterService,
      private navbarService: NavbarService,
      private addressHelpersService : AddressHelpersService,
      private cdr : ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.isMobile = window.innerWidth < 600;
      this.lang = this.configSettings.getLang();
      this.configSettings.getStoreObject().then((result) => {
        this.store = <Store> result;
        this.currency = result['currency_' + this.lang];
        this.userService.getUserSession().then(user => {
            if(user !== false) {
                this.userId = user.id;
            }

            this.getUserAddress();
        });
      });
      this.skeltonTheme = this.setThemeLogic();

  
  }
  setThemeLogic() {
    let mobTheme = {
      'border-radius': '5px',
      'height': '100px',
      'animation-duration': '2s',
      'background-color': '#F1F1F1'
    };
    let deskTheme = {
      'border-radius': '5px',
      'height': '300px',
      'animation-duration': '2s',
      'background-color': '#F1F1F1'
    };
    if (this.isMobile) {
      return mobTheme;
    } else {
      return deskTheme;
    }
  }

  getUserAddress() {
      this.configSettings.toggleLoading(true);
      this.userService.getUserAddress(this.userId, this.lang, this.store.iso_code).then( data => {
          this.addresses = data;                    
          this.configSettings.toggleLoading(false);
          this.didLoad = true;
          this.dataAvailable = true;
          this.skeletonLoader=false;
          setTimeout(() => {this.footerService.toggleFooter(true)}, 300);
      }, error => { this.configSettings.toggleLoading(false); });
  }
  editAddress(address: any) {
    this.navbarService.triggerAddress(address, false);
    this.displayPopup = true
    const addressInfo = {
      displayPopup: this.displayPopup,
      isEditAddress: true,
      addressInfo : address,
      isPopup: true
    }
    this.addressHelpersService.setAddressInfo(addressInfo)
  }

  displayPopup: boolean = false
  newAddress(){
    this.navbarService.triggerAddress('',true);
    this.displayPopup = true
    const addressInfo = {
      displayPopup: this.displayPopup,
      isEditAddress: false,
      addressInfo : undefined,
      isPopup: true
    }
    this.addressHelpersService.setAddressInfo(addressInfo)
  }

  deleteAddress(id) {
      this.configSettings.toggleLoading(true);
      this.addresses = []
      const getParams = {
          lang: this.lang
      };

      const postParams = {
          id: id,
          user_id: this.userId
      };

      this.configService.postRequest('delete-address', getParams, postParams)
          .subscribe(response => {
              if (response.status === 200) {
                  this.addresses = response.data;
                  this.ngOnInit()
              }
              this.configSettings.toggleLoading(false);
          }, error => { this.configSettings.toggleLoading(false); });

      return false;
  }

  makeDefault(id) {
      this.configSettings.toggleLoading(true);
      const getParams = {
          lang: this.lang
      };

      const postParams = {
          id: id,
          user_id: this.userId
      };

      this.configService.postRequest('make-default-address', getParams, postParams)
          .subscribe(response => {
              if (response.status === 200) {
                  // this.addresses = response.data;
              }
              this.configSettings.toggleLoading(false);
          }, error => { this.configSettings.toggleLoading(false); });

      return false;
  }

  closePopup(value : boolean){
   this.displayPopup = value
  }

  resetAddressListFun(){   
    this.getUserAddress()
    // this.cdr.detectChanges()
  }

}
