import { Directive, ElementRef, EventEmitter, HostListener, Input, Output, Renderer2 } from '@angular/core';
import { ConfigSettings } from '../config/config.settings';

@Directive({
  selector: '[appClickToFavBrands]'
})
export class ClickToFavBrandsDirective {

  lang:any;
  @Input() isFav : boolean = false
  private renderer: Renderer2;
  constructor(private el: ElementRef,
    private configSettings: ConfigSettings,) {}
    ngOnInit() {
      this.lang = this.configSettings.getLang();
    }

  @HostListener('click', ['$event']) onClick(e: any) {
   let star = document.getElementById('fav-star') as HTMLImageElement
   let starTxt = document.getElementById('toggle-fav') as HTMLElement
   
    var index = star.src.lastIndexOf('/');
    var first = star.src.substr(0, index);
    var last = star.src.substr(index + 1);

    if (last == 'fav-brand-star.svg') {
      star.src = 'assets/icons/fav-brand-star-filled.svg';
      starTxt.innerHTML = this.lang === 'en' ? 'Remove from Favourites' : 'إزالة من المفضلة';
     
    }
    if (last == 'fav-brand-star-filled.svg') {
      star.src = 'assets/icons/fav-brand-star.svg';
      starTxt.innerHTML = this.lang === 'en' ? 'Add to Favourites':'أضف إلى المفضلة'
    }
  }

}
