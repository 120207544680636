import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ConfigSettings} from '../config/config.settings';
import {listStagger} from '../animations';
import {FooterService} from '../config/footer.service';
import {Store} from '../models/store';
import {User} from '../models/user';
import {UserService} from '../config/user.service';
import {Item} from '../models/item';
import {ConfigService} from '../config/config.service';
import {ReturnRequest} from '../models/return-request';
import {RequestReturnsService} from '../config/request-returns.service';

@Component({
  selector: 'app-return-request-list',
  templateUrl: './return-request-list.component.html',
  styleUrls: ['./return-request-list.component.scss'],
  animations: [ listStagger ]
})
export class ReturnRequestListComponent implements OnInit {
  returns: any;
  orderId: number;
  dataAvailable = false;
  lang: string;
  store: Store;
  currency: string;
  user: User;
  requests: ReturnRequest[];
  skeletonLoader:boolean=true;
  constructor(
      private route: ActivatedRoute,
      private configSettings: ConfigSettings,
      private configService: ConfigService,
      protected footerService: FooterService,
      private userService: UserService,
      private requestReturnsService: RequestReturnsService,
      private router: Router
  ) { }

  ngOnInit() {
      this.configSettings.toggleLoading(true);
      this.lang = this.configSettings.getLang();
      /* getting store from config*/
      this.configSettings.getStoreObject().then((result) => {
          this.store = <Store> result;
          this.currency = result['currency_' + this.lang];

          this.userService.getUserSession().then(user => {
              if (user !== false) {
                  this.user = <User> user;
                  this.getRequestItem();
              }
          }, error => { this.configSettings.toggleLoading(false); });
      });
  }
  setReturnRequest(returnRequest: ReturnRequest) {
      this.requestReturnsService.setReturnRequest(returnRequest);
      this.router.navigate(['return-request/detail/', returnRequest.id]);
  }
  getRequestItem() {
    const params = {
        user_id: this.user.id,
        lang: this.lang,
        store: this.store.iso_code
    };

    this.configService.readRequest('return-request-list', params)
        .subscribe(response => {
            if (response.status === 200) {
                this.requests = <ReturnRequest[]> response.body.data;
            }
            this.configSettings.toggleLoading(false);
            this.dataAvailable = true;
            this.skeletonLoader=false;
            setTimeout(() => { this.footerService.toggleFooter(true); }, 300);
        }, error => { this.configSettings.toggleLoading(false); });
}

}
