import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ShareBrandInfoService {
  private brandInfoSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor() { }

  // Function to set brandInfo
  setBrandInfo(brandInfo: any) {
    this.brandInfoSubject.next(brandInfo);
  }

  // Function to get brandInfo as an observable
  getBrandInfo(): Observable<any> {
    return this.brandInfoSubject.asObservable();
  }
}
