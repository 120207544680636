import { Component, Input, Renderer2, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-shop-look',
  templateUrl: './shop-look.component.html',
  styleUrls: ['./shop-look.component.scss']
})
export class ShopLookComponent {

  panelX: number; // Store the x-coordinate of the clicked tag
  panelY: number; // Store the y-coordinate of the clicked tag
  selectedProduct: any
  selectedPanel: HTMLElement
  panelRefs: Map<string, HTMLElement> = new Map<string, HTMLElement>();
  isMobile: boolean =  window.innerWidth < 767;

  @Input() product: any
  @ViewChild('canvasElement', { static: false }) canvasRef: ElementRef<HTMLCanvasElement>;

  constructor(
    private renderer: Renderer2,
    private router: Router
  ) { }

  ngAfterViewInit() {
    //  to draw the image on the canvas after the view is initialized
    this.drawImageOnCanvas();
  }

  // to separate coordinates string by , in to x and y coordinates
  separateString(str: string, type: 'x' | 'y'): string {
    const commaIndex = str.indexOf(',');

    if (commaIndex === -1) {
      return type === 'x' ? str : '';
    }

    if (type === 'x') {
      return str.slice(0, commaIndex);
    } else {
      return str.slice(commaIndex + 1);
    }
  }

  //  to draw the image on the canvas after the view is initialized
 drawImageOnCanvas() {
  const canvas = this.canvasRef.nativeElement;
  const ctx = canvas.getContext('2d');
  const image = new Image();
  image.src = this.product.image;

  image.onload = () => {
    const imageWidth = this.isMobile == false ? this.product.image_width : (2/3) * this.product.image_width;
    const imageHeight = this.isMobile == false ? this.product.image_height : (2/3) * this.product.image_height;

    canvas.width = imageWidth;
    canvas.height = imageHeight;
    canvas.id = this.product.id;

    ctx.drawImage(image, 0, 0, imageWidth, imageHeight);

    this.product.shop_look_product.forEach((tag: any) => {
      const x = +this.separateString(tag.coordinates, 'x');
      const y = +this.separateString(tag.coordinates, 'y');
      const circleRadius = 12;

      // Shadow properties
      ctx.shadowColor = 'rgba(0, 0, 0, 0.9)';
      ctx.shadowBlur = 5;
      ctx.shadowOffsetX = 2;
      ctx.shadowOffsetY = 2;

      // Adjust tag positions based on the scaling factor if isMobile is true
      const adjustedX = this.isMobile == false ? x : (x * 2/3);
      const adjustedY = this.isMobile == false ? y : (y * 2/3);

      // Draw the white circle at the adjusted coordinates
      ctx.beginPath();
      ctx.arc(adjustedX, adjustedY, circleRadius, 0, 2 * Math.PI);
      ctx.fillStyle = 'white';
      ctx.fill();
      ctx.closePath();

      const panel = document.getElementById('tag' + this.product.id) as HTMLElement;
      this.panelRefs.set(this.product.id, panel);

      // Draw the plus icon in the center of the circle
      const plusIcon = new Image();
      plusIcon.src = '../../assets/icons/plus.svg';
      plusIcon.onload = () => {
        const iconWidth = 16;
        const iconHeight = 16;

        ctx.drawImage(plusIcon, adjustedX - iconWidth / 2, adjustedY - iconHeight / 2, iconWidth, iconHeight);
      };

      // Click event listener to handle tag expansion
      canvas.addEventListener('click', (event) => {
        const rect = canvas.getBoundingClientRect();
        const clickX = event.clientX - rect.left;
        const clickY = event.clientY - rect.top ;

        // Check if the click is inside the circle
        if (Math.sqrt((adjustedX - clickX) ** 2 + (adjustedY - clickY) ** 2) <= circleRadius) {
          this.expand(tag);
        }
      });
    });
  };
}


  // to navigate to product details 
  routeToDetails(productId) {
    this.router.navigate(['/product/detail/', productId])

  }

  // expand(event: any, x: any, y: any) {
  //   this.selectedProduct = event;
  //   this.panelX = x; // Set the x-coordinate for the panel
  //   this.panelY = y; // Set the y-coordinate for the panel

  //   setTimeout(() => {
  //     this.selectedPanel = document.getElementById('tag'+this.selectedProduct.id) as HTMLElement

  //     // Show the panel by removing the "main-div-hidden" class
  //     this.renderer.removeClass(this.selectedPanel, 'main-div-hidden');
  //   }, 500);
  // }


  //  // Add a method to hide the panel
  //  hidePanel() {
  //   // Hide the panel by adding the "main-div-hidden" class
  //   setTimeout(() => {
  //     this.renderer.addClass(this.selectedPanel, 'main-div-hidden');
  //   }, 500);
  // }


  // expand(event: any, canvasId: string, x: any, y: any) {
  //   this.selectedProduct = event;
  //   this.panelX = x; // Set the x-coordinate for the panel
  //   this.panelY = y; // Set the y-coordinate for the panel

  //   setTimeout(() => {
  //     const panel = document.getElementById('tag' + canvasId) as HTMLElement;
  //     if (panel) {
  //       // Show the panel by removing the "main-div-hidden" class
  //       this.renderer.removeClass(panel, 'main-div-hidden');
  //     }
  //   }, 500);
  // }

  expand(tag: any) {
    // To check if the clicked tag is the same as the currently expanded tag
    if (this.selectedProduct === tag) {
      this.hidePanel(this.product.id); // Close the panel
      this.selectedProduct = null; // Clear the selectedProduct
    } else {
      this.selectedProduct = tag;
      const { x, y } = this.extractCoordinates(tag);
  
      // Adjust panel position based on scaling factor if isMobile is false
      this.panelX = this.isMobile == false ? x : (x * 2/3)-5;
      this.panelY = this.isMobile == false ? y : (y * 2/3)-5;
  
      // Show the panel by removing the "main-div-hidden" class
      const panel = this.panelRefs.get(this.product.id);
      if (panel) {
        this.renderer.removeClass(panel, 'main-div-hidden');
      }
    }
  }
  


  // to set position of panel , -10 is given to accurately place panel on tag
  private extractCoordinates(tag: any): { x: number; y: number } {
    const coordinates = tag.coordinates.split(',');
    const x = +coordinates[0].trim() - 10;
    const y = +coordinates[1].trim() - 10;
    return { x, y };
  }


  // hidePanel(canvasId: string) {
  //   setTimeout(() => {
  //     const panel = document.getElementById('tag' + canvasId) as HTMLElement;
  //     if (panel) {
  //       // Hide the panel by adding the "main-div-hidden" class
  //       this.renderer.addClass(panel, 'main-div-hidden');
  //     }
  //   }, 500);
  // }
  hidePanel(canvasId: string) {
    setTimeout(() => {
      const panel = this.panelRefs.get(canvasId);
      if (panel) {
        // Hide the panel by adding the "main-div-hidden" class
        this.renderer.addClass(panel, 'main-div-hidden');
      }
    }, 500);
  }




}
