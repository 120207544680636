import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { CartService } from "../config/cart.service";
import { Cart } from "../models/cart";
import { UserService } from "../config/user.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ConfigService } from "../config/config.service";
import { ConfigSettings } from "../config/config.settings";
import { CheckoutService } from "../config/checkout.service";
import { Store } from '../models/store';
import { WishlistService } from '../config/wishlist.service';
import { listStagger } from '../animations';
import { FooterService } from '../config/footer.service';
import { post } from "selenium-webdriver/http";
import { ConfigurableOptions } from "../models/configurable-options";
import { AddedToCartDialogComponent } from "../added-to-cart-dialog/added-to-cart-dialog.component";
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogComponent } from '../dialog/dialog.component';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { SupportDetails } from '../models/support-details';
import { AnalyticsService } from '../services/analytics.service';


import { Address } from "../models/address";
import { first } from "rxjs/operators";
import { NavbarService } from "../config/navbar.service";
import { Product } from "../models/product";
import { DeliveryOptions } from "../models/delivery-options";
import { PaymentOptions } from "../models/payment-options";
import { Order } from "../models/order";
import { User } from '../models/user';
import { Country } from '../models/country';
import { State } from '../models/state';
import { Area } from '../models/area';
import { Sector } from '../models/sector';
import { NgForm , FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-add-shipping-address',
    templateUrl: './add-shipping-address.component.html',
    styleUrls: ['./add-shipping-address.component.scss']
})
export class AddShippingAddressComponent implements OnInit {

    cart: Cart;
    currency: string = '';
    cartValue: number = 0;
    shippingCost: any = 0;
    vatCharges: number = 0;
    selectedQuantity: Number[] = [];
    selectedAttribute: Array<number[]> = [];
    selectedOption: Array<number[]> = [];
    selectedFilters: Array<ConfigurableOptions[]> = [];
    quantities: Array<any> = [];
    showQuantitySelect: Array<any> = [];
    showOptionSelect: Array<any> = [];
    didLoad: boolean = false;
    lang: string;
    store: Store;
    userSession = false;
    userId: number;
    wishlistCount: number = 0;
    dataAvailable = false;
    dialogRef: MatDialogRef<DialogComponent>;
    support_email = '';
    support_phone = '';


    @Input() address: Address = {
        address_id: null,
        first_name: null,
        last_name: null,
        area_id: null,
        area_name: null,
        governorate_id: null,
        governorate_name: null,
        country_id: 114, // default country Kuwait
        country_name: null,
        vat_pct: null,
        vat_charges: null,
        block_id: null,
        block_name: null,
        shipping_cost: null,
        express_shipping_cost: null,
        address_store_code: null,
        iso: null,
        cod_cost: null,
        is_cod_enable: null,
        phonecode: null,
        street: null,
        addressline_1: null,
        mobile_number: null,
        alt_phone_number: null,
        location_type: null,
        notes: null,
        is_default: null,
        delivery_options: null,
        avenue: null,
        flat: null,
        floor: null,
        id_number: null,
        landmark: null
    };
    blankAddress: any = this.address;
    addressf: any = this.address;
    discount: number = 0;
    selectedDelivery: DeliveryOptions;
    selectedPayment: PaymentOptions;
    deliveryOptions: DeliveryOptions[] = [];
    paymentOptions: PaymentOptions[] = [];
    order: Order;
    codCost: number = 0;
    promo_code: string = '';
    user: User;
    promoValidationMessage: string;
    promotionApplied: boolean = false;
    coupon: any;
    showPaymentError = false;
    is_free_delivery = false;
    deliveryText = '';
    is_gift: boolean = false;
    receiver_name: string = "";
    receiver_phone: any = "";
    message: string = "";
    hide_invoice: boolean = false;
    walletUsed: boolean = false;
    wallet_balance: number = 0;

    selected_shipping_address: any;
    isAddAddress: any = true;
    formSubmitAttempt:boolean=false;

    @Output() triggerChangeAddress: EventEmitter<Number> = new EventEmitter<Number>();

    constructor(
        private cartService: CartService,
        private configService: ConfigService,
        private configSettings: ConfigSettings,
        protected userService: UserService,
        private router: Router,
        private route: ActivatedRoute,
        private checkoutService: CheckoutService,
        private wishlistService: WishlistService,
        protected footerService: FooterService,
        private dialog: MatDialog,
        private analytics: AnalyticsService,
        protected localStorage: LocalStorage,
        private toastr: ToastrService,
        private navbarService: NavbarService,
        private formBuilder: FormBuilder,
        private cdRef: ChangeDetectorRef
    ) { }

    ngOnInit() {
        this.selected_shipping_address = this.configSettings.getShippingAddress();
        this.lang = this.configSettings.getLang();
        this.wishlistService.currentWishlistCount.subscribe(data => this.wishlistCount = data);
        this.configSettings.getStoreObject().then((result) => {
            this.store = <Store>result;
            this.currency = result['currency_' + this.lang];
        });
        this.localStorage.getItem<any>('support_details').subscribe((result) => {
            if (result) {
                const SupportDetails = <SupportDetails>result;
                this.support_email = SupportDetails.support_email;
                this.support_phone = SupportDetails.support_phone;
            }
        });
        this.lang = this.configSettings.getLang();
        this.initForm();
        this.navbarService.isAddAddress.subscribe(address => {            
            if (address == null) {
                this.addressf = this.blankAddress;
            } else {
                this.addressf = address;
                this.loadAreas(this.addressf.governorate_id)
                this.patchFormValues(address)
            }
        });
        
        // if (this.address) {
        //     this.patchFormValues();
        // }else {
        // }

        if (this.checkoutService.address && this.checkoutService.cart) {
            this.address = this.checkoutService.address;
            this.deliveryText = this.address.delivery_options[0].name;
            this.vatCharges = +this.address.vat_charges;
            this.cart = this.checkoutService.cart;
            if (this.checkoutService.address.delivery_options.length) {
                this.selectedDelivery = this.checkoutService.address.delivery_options[0];
            }
            this.deliveryOptions = this.address.delivery_options;
        } else {
            //this.router.navigate(['shipping-address']);
        }

        this.configSettings.getStoreObject().then((result) => {
            this.store = <Store>result;
            this.currency = result['currency_' + this.lang];
            this.getCountries();
            this.loadStates(114); // default country Kuwait
            
            
            setTimeout(() => { this.footerService.toggleFooter(true) }, 300);
        });
    }

    shippingAddressForm: FormGroup;
    initForm(): void {
        this.shippingAddressForm = this.formBuilder.group({
          first_name: ['', [Validators.required]],
          last_name: ['', [Validators.required]],
          mobile_number: ['', [Validators.required, Validators.pattern(/^\d{5,10}$/)]],
          country_id: ['', [Validators.required]],
          governorate_id: ['', [Validators.required]],
          area_id: ['', [Validators.required]],
          block_id: [''],
          street: ['', [Validators.required]],
          avenue: [''],
          addressline_1: [''],
          floor: [''],
          flat: ['', [Validators.required]],
          landmark: ['', [Validators.required]],
          id_number: ['', [Validators.required]],
          notes: ['']
        });
      }
    
    patchFormValues(address): void {
       
        this.shippingAddressForm.patchValue({
            first_name: address.first_name,
            last_name: address.last_name,
            mobile_number: address.mobile_number,
            country_id: address.country_id,
            governorate_id: address.governorate_id,
            area_id: address.area_id,
            block_id: address.block_id,
            street: address.street,
            avenue: address.avenue,
            addressline_1: address.addressline_1,
            floor: address.floor,
            flat: address.flat,
            landmark: address.landmark,
            id_number: address.id_number,
            notes: address.notes
        });
        // this.cdRef.detectChanges();
    }
    
    saveForLater(item, index) {
        if (this.userId) {
            this.dialogRef = this.dialog.open(DialogComponent, {
                data: { confirmDialog: true },
            });
            this.dialogRef.componentInstance.confirmMessage = (this.lang == 'en') ? "Are you sure you want to move this item to wishlist?" : "هل تريد بالتأكيد حفظ هذا المنتج في قائمة الأمنيات ؟";
            this.dialogRef.afterClosed().subscribe(result => {
                if (result) {
                    this.configSettings.toggleLoading(true);
                    const getParams = {
                        lang: this.lang,
                        store: this.store.iso_code
                    };
                    const postParams = {
                        user_id: this.userId,
                        order_id: this.configSettings.getCartId(),
                        product_id: item.id
                    };
                    this.configService.postRequest('save-for-later', getParams, postParams)
                        .subscribe(response => {
                            this.cartValue = 0;
                            this.cart.items.splice(index, 1);
                            this.selectedQuantity.splice(index, 1);
                            this.showQuantitySelect.splice(index, 1);
                            this.showOptionSelect.splice(index, 1);

                            response.data.items.map(item => {
                                this.cartValue += (+item.quantity * +item.final_price);
                            });
                            /**
                             * Update cart item count
                             */
                            this.cartService.changCartCount(response.data.items.length);

                            /**
                             * Update wishlist count
                             */
                            // const wishlistCount = (this.wishlistCount > 0) ? this.wishlistCount + 1 : 0;
                            // this.wishlistService.changWishlistCount(wishlistCount);

                            this.configSettings.toggleLoading(false);
                        }, error => { this.configSettings.toggleLoading(false); });

                }
                this.dialogRef = null;
            });
        }
        return false;
    }

    createQuantityOptions(quantity) {
        let option: any[] = [];
        for (let i = 1; i <= quantity; i++) {
            option.push({ id: i, value: i });
        }

        return option;
    }

    onChangeQuantity($event, index) {
        this.configSettings.toggleLoading(true);
        this.userService.getUserSession().then(response => {
            if (response === false) {
                this.selectedQuantity[index] = $event.value;
                this.cart.items[index].quantity = $event.value;

                this.cartService.updateQuantity(index, $event.value).then((response) => {
                    //this.currency = response.currency;
                    this.cartValue = response.value;

                    this.showQuantitySelect[index] = false;
                    this.configSettings.toggleLoading(false);
                });
            } else {
                const getParams = {
                    lang: this.configSettings.getLang(),
                    store: this.store.iso_code
                };

                const postParams = {
                    user_id: response.id,
                    order_id: this.configSettings.getCartId(),
                    products: this.cart.items[index].id,
                    quantity: $event.value
                };

                this.configService.postRequest('update-cart', getParams, postParams)
                    .subscribe(response => {
                        this.cartValue = 0;
                        this.shippingCost = response.data.shipping_cost;
                        response.data.items.map(item => {
                            this.selectedQuantity[index] = $event.value;
                            this.cart.items[index].quantity = $event.value;
                            this.showQuantitySelect[index] = false;
                            this.cartValue += (+item.quantity * +item.final_price);
                        });
                        this.configSettings.toggleLoading(false);
                    });
            }
        }, error => { this.configSettings.toggleLoading(false); });
    }

    update(index) {
        this.showQuantitySelect[index] = true;
        return false;
    }

    cancelUpdate(index) {
        this.showQuantitySelect[index] = false;
        return false;
    }
    key(event:any){
    
        
        const pattern = /[0-9\+\-\ ]/;
              let inputChar = String.fromCharCode(event?.charCode);
              if ( !pattern.test(inputChar)) {
                  
                   event.preventDefault();
              } else {
                
              }
      }
    proceedToShipping() {
        this.checkoutService.setCart(this.cart);
        this.checkoutService.setShipping(this.shippingCost);
        this.router.navigate(['shipping-address']);

        var ids = [];
        var qtys = 0;
        var gtotal = 0;
        var cartItems = [];
        var productCategories = [];
        var productNames = [];
        this.cart.items.forEach(element => {
            ids.push(element.id);
            cartItems.push(element);
            productNames.push(element.name);
            productCategories.push(element.marketing_category + ' - ' + element.marketing_subcategory)
            qtys = qtys + element.quantity;
            gtotal = Number(gtotal) + Number(element.final_price);
        });
        //Adding To Event
        this.analytics.initiateCheckout(
            ids,
            productNames,
            cartItems,
            qtys,
            String(gtotal)
        );
    }

    onOptionSelect(option, $event, index) {
        this.clearFilters(option, $event, index).then(() => {
            this.filterOptions(index);
        });
    }

    clearFilters(configurable_option: ConfigurableOptions, $event, index: number): Promise<any> {
        let promise = new Promise<any>((resolve, reject) => {
            this.cart.items[index].configurable_option.map((option, i) => {
                if (option.attribute_id === configurable_option.attribute_id) {
                    if (i == 0) {
                        this.selectedAttribute[index].splice(i);
                        this.selectedOption[index].splice(i);
                        this.selectedFilters[index].splice(i);
                    }

                    this.selectedAttribute[index][i] = $event.option_id;
                    this.selectedOption[index][i] = +configurable_option.attribute_id;
                    this.selectedFilters[index][i] = {
                        type: configurable_option.type,
                        attribute_id: configurable_option.attribute_id,
                        attribute_code: configurable_option.attribute_code,
                        attributes: [$event]
                    };
                }
            });

            resolve(true);
        });

        return promise;

    }

    editOption(index, optionIndex) {
        //if(optionIndex === 0) {
        this.selectedOption[index].splice(optionIndex);
        this.selectedAttribute[index].splice(optionIndex);
        //}

        this.filterOptions(index);
        this.showOptionSelect[index] = true;
        return false;
    }

    cancelEdit(index) {
        this.showOptionSelect[index] = false;
        return false;
    }

    filterOptions(index) {
        this.configSettings.toggleLoading(true);
        const getParams = {
            lang: this.lang,
            store: this.store.iso_code
        };

        const postParams = {
            product_id: this.cart.items[index].parent_id,
            option_id: (this.selectedAttribute[index].length) ? this.selectedAttribute[index].join(',') : '',
            attribute_id: (this.selectedOption[index].length) ? this.selectedOption[index].join(',') : ''
        };

        this.configService.postRequest('configurable-options', getParams, postParams)
            .subscribe(response => {
                if (response.data[0]) {
                    if (response.data[0].attributes.length) {
                        this.cart.items[index].configurable_option.map(option => {
                            if (option.attribute_id === response.data[0].attribute_id) {
                                option.attributes = [];
                                option.attributes = response.data[0].attributes;
                            }
                        });
                    } else {
                        this.updateBag(index, response.data[0].entity_id);
                    }
                }

                this.configSettings.toggleLoading(false);
            }, error => { this.configSettings.toggleLoading(false); });
    }

    updateBag(index, entityId) {
        try {
            if (this.cart.items[index].is_saleable) {
                this.configSettings.toggleLoading(true);
                if (this.cart.items[index].id) {
                    this.userService.getUserSession().then(response => {
                        if (response === false) {
                            this.cartService.updateOfflineCart(entityId, this.cart.items[index], this.selectedFilters[index], this.selectedQuantity[index]).then(response => {
                                this.showOptionSelect[index] = false;
                                this.configSettings.toggleLoading(false);
                            });
                        } else {
                            this.updateOnlineCart(response.id, this.cart.items[index].id, entityId, this.selectedQuantity[index]).then((any) => {
                                this.showOptionSelect[index] = false;
                                this.configSettings.toggleLoading(false);
                            });
                        }
                    }, error => { this.configSettings.toggleLoading(false); });
                } else {
                    this.configSettings.toggleLoading(false);
                    let selects = document.querySelectorAll('ng-select');
                    for (let i = 0; i < selects.length; i++) {
                        selects[i].classList.add('error');
                    }
                }
            }
        } catch (e) {
            this.configSettings.toggleLoading(false);
        }
    }

    private updateOnlineCart(user: string, oldProduct, newProduct, quantity): Promise<any> {
        return new Promise(resolve => {
            const getParams = {
                user: user,
                order: this.cart.id,
                old_product: oldProduct,
                new_product: newProduct,
                quantity: quantity,
                lang: this.lang,
                store: this.store.iso_code
            };

            this.configService.readRequest('update-product-in-cart', getParams)
                .subscribe(response => {
                    //this.cart.items = response.body.data.items;
                    resolve(true);
                });
        });
    }

    sendAsGift: any;
    showSendAsGift: any = false;
    onClickSendAsGift() {
        if (this.showSendAsGift) {
            this.showSendAsGift = false;
        } else {
            this.showSendAsGift = true;
        }
    }





    //PAYMENT FUNCTIONS

    checkItemStock() {
        this.configSettings.toggleLoading(true);
        this.userService.getUserSession().then(response => {

            if (response != false) {
                this.user = <User>response;
                this.getAddress();
                this.getAllAddress();
                this.wallet_balance = response.wallet_balance;
                this.checkoutService.checkItemStock(response.id, (this.cart) ? this.cart.items : '', this.store.iso_code, this.address.address_id).then(data => {
                    if (data.is_coupon_applied === 1) {
                        this.coupon = data.coupon;
                        this.promotionApplied = true;
                        this.discount = data.discount_price;
                    } else {
                        this.promotionApplied = false;
                    }
                    this.cart = data.cart;
                    this.cartValue = data.sub_total;
                    this.deliveryOptions = <DeliveryOptions[]>data.delivery_options;
                    this.shippingCost = data.delivery_charges;
                    if (this.shippingCost == '0') {
                        this.is_free_delivery = true;
                    } else {
                        this.is_free_delivery = false;
                    }
                    //
                    this.paymentOptions = data.payment_types;
                    this.paymentOptions.map(payment => {
                        if (payment.code === 'C') {
                            payment.is_enable = (this.address?.is_cod_enable) ? this.address?.is_cod_enable.toString() : '0';
                        }
                    });

                    this.sendGTagEvent('begin_checkout', this.cart.items, null);

                    this.configSettings.toggleLoading(false);
                    setTimeout(() => { this.footerService.toggleFooter(true) }, 300);
                }, error => { this.configSettings.toggleLoading(false); });
            } else {
                this.configSettings.toggleLoading(false);
                setTimeout(() => { this.footerService.toggleFooter(true) }, 300);
            }
        }, error => { this.configSettings.toggleLoading(false); });
    }

    doPromotion(): Promise<any> {
        const promise = new Promise<any>((resolve, reject) => {
            const getParams = {
                lang: this.lang,
                store: this.store.iso_code
            };
            const postParams = {
                user_id: this.user.id,
                order_id: this.configSettings.getCartId(),
                coupon_code: this.promo_code,
                shipping_address_id: this.address.address_id,
                device: 'W',
            };
            this.checkoutService.redeemCoupon(getParams, postParams)
                .pipe(first())
                .subscribe(
                    response => {
                        if (response.status === 200) {
                            const responseData = response.data;
                            this.coupon = responseData.coupon;
                            this.discount = responseData.discount_price;
                            this.shippingCost = responseData.delivery_charges;
                            if (this.shippingCost == '0') {
                                this.is_free_delivery = true;
                            } else {
                                this.is_free_delivery = false;
                            }
                            this.cartValue = responseData.sub_total;
                            this.deliveryOptions = <DeliveryOptions[]>responseData.delivery_options;
                            resolve(true);
                        } else {
                            this.promoValidationMessage = (this.lang === 'en') ? 'UNFORTUNATELY THIS PROMO CODE IS INVALID. PLEASE CHECK AND TRY AGAIN.' : 'لا شك أن هذا الرمز الترويجي غير صالح. يرجى مراجعة وحاول مرة أخرى.';
                            setTimeout(() => { this.promoValidationMessage = ''; }, 5000);
                            this.configSettings.toggleLoading(false);
                            resolve(false);
                        }
                    }, error => { this.configSettings.toggleLoading(false); }
                );

        });
        return promise;
    }

    applyPromotion() {
        this.doPromotion().then((result) => {
            if (result) {
                this.promotionApplied = true;
            }
        });
    }

    removePromotion() {
        this.promo_code = '';
        this.doPromotion().then((result) => {
            if (result) {
                this.promotionApplied = false;
            }
        });
    }


    selectDelivery(delivery, index) {
        // document.querySelector('.address-select.active').classList.remove('active');
        // document.querySelector('#'+delivery).classList.add('active');
        this.selectedDelivery = this.deliveryOptions[index];
        this.shippingCost = this.deliveryOptions[index].price;
        this.deliveryText = this.address.delivery_options[index].name;
    }

    selectpayment($payment, index) {
        this.selectedPayment = this.paymentOptions[index];

        if (this.selectedPayment.code === "C") {
            this.codCost = +this.address.cod_cost;
        } else {
            this.codCost = 0;
        }

        this.showPaymentError = false;
    }

    getValue(val, field_name) {
        if (field_name == 'receiver_name') {
            this.receiver_name = val;
        }
        else if (field_name == 'receiver_phone') {
            this.receiver_phone = val;
        } else if (field_name == 'on_package_sticker') {
            this.on_package_sticker = val;
        }
        else if (field_name == 'message') {
            this.message = val;
        }
    }
    proceedToCheckout() {
        if (!this.selectedPayment) {
            this.showPaymentError = true;
            return false;
        }

        this.checkoutService.setAddress(this.address);
        this.checkoutService.setCart(this.cart);
        this.checkoutService.setDelivery(this.selectedDelivery);
        this.checkoutService.setPayment(this.selectedPayment);

        this.configService.sendGTagEvent('set_checkout_option', {
            "checkout_step": 2,
            "checkout_option": "delivery method",
            "value": this.selectedDelivery.name
        });

        this.configService.sendGTagEvent('set_checkout_option', {
            "checkout_step": 2,
            "checkout_option": "payment method",
            "value": this.selectedPayment.type
        });
        this.sendGTagEvent('checkout_progress', this.cart.items, null);
        this.doCheckout();
    }
    on_package_sticker: string = '';
    doCheckout() {
        this.configSettings.toggleLoading(true);
        this.userService.getUserSession().then(response => {
            if (response !== false) {
                this.checkoutService.checkout(response.id, this.address.address_id, this.selectedDelivery.id, this.selectedPayment.code, this.store.iso_code, this.is_gift, this.receiver_name, this.receiver_phone, this.message,  this.hide_invoice, this.anonymousdelivery,this.on_package_sticker).then(data => {
                    if (data.payment_url) {
                        // this.configSettings.toggleLoading(false);
                        window.location.href = data.payment_url;
                    } else {
                        this.order = {
                            items: data.cart.items,
                            cod_cost: data.cod_cost,
                            baseCurrencyName: data.baseCurrencyName,
                            delivery_charges: data.delivery_charges,
                            created_date: data.order_details.order_date,
                            order_number: data.order_details.order_number,
                            order_id: (data.order_details) ? (data.order_details.order_id) : '',
                            status: data.order_details.order_status,
                            shipping_address: data.shipping_address,
                            status_color: data.order_details.status_color,
                            sub_total: data.sub_total,
                            total: data.total,
                            vat_charges: data.vat_charges,
                            vat_pct: data.vat_pct,
                            discount_price: (data.discount_price > 0) ? data.discount_price : 0,
                            grand_total_kwd: data.grand_total_kwd,
                            payment_details: []
                        };

                        this.checkoutService.setOrderDetails(this.order);
                        this.checkoutService.setPayment(this.selectedPayment);
                        this.configSettings.toggleLoading(false);
                        this.router.navigate(['order-confirmation']);
                        // window.location.href = '/order-confirmation?UDF1='+data.order_details.order_id;
                    }
                });
            } else {
                this.configSettings.toggleLoading(false);
            }
        }, error => { this.configSettings.toggleLoading(false); });
    }

    sendGTagEvent(event, items: any, additional_params: any) {
        const tmp = [];
        items.forEach(function (item, key) {
            const final_price = item.final_price_kwd * 3.30;
            tmp.push({
                id: item.SKU,
                name: item.name,
                list_name: "Product Details",
                brand: item.brand_name,
                category: item.brand_name,
                variant: "",
                list_position: 1,
                quantity: item.quantity,
                price: final_price.toFixed(2)
            });
        });

        this.configService.sendGTagEvent(event, {
            // additional_params,
            items: tmp,
            coupon: ''
        });
    }

    sendGift() {
        this.is_gift = !this.is_gift;
    }
    hideInvoice() {
        this.hide_invoice = !this.hide_invoice;
    }
    anonymousdelivery:boolean=false;
  isAnonymousdelivery(){
    this.anonymousdelivery=!this.anonymousdelivery
  }

    applyWallet() {
        this.useWallet().then((result) => {
            if (result) {
                this.walletUsed = true;
            }
        });
    }
    removeWallet() {
        this.useWallet().then((result) => {
            if (result) {
                this.walletUsed = false;
            }
        });
    }
    useWallet(): Promise<any> {
        const promise = new Promise<any>((resolve, reject) => {
            const getParams = {
                lang: this.lang,
                store: this.store.iso_code
            };
            const postParams = {
                user_id: this.user.id,
                order_id: this.configSettings.getCartId(),
            };
            this.checkoutService.redeemWallet(getParams, postParams)
                .pipe(first())
                .subscribe(
                    response => {
                        if (response.status === 200) {
                            const responseData = response.data;
                            this.coupon = responseData.coupon;
                            this.discount = responseData.discount_price;
                            this.shippingCost = responseData.delivery_charges;
                            if (this.shippingCost == '0') {
                                this.is_free_delivery = true;
                            } else {
                                this.is_free_delivery = false;
                            }
                            this.cartValue = responseData.sub_total;
                            this.deliveryOptions = <DeliveryOptions[]>responseData.delivery_options;
                            resolve(true);
                        } else {
                            this.promoValidationMessage = (this.lang === 'en') ? 'UNFORTUNATELY THIS PROMO CODE IS INVALID. PLEASE CHECK AND TRY AGAIN.' : 'لا شك أن هذا الرمز الترويجي غير صالح. يرجى مراجعة وحاول مرة أخرى.';
                            setTimeout(() => { this.promoValidationMessage = ''; }, 5000);
                            this.configSettings.toggleLoading(false);
                            resolve(false);
                        }
                    }, error => { this.configSettings.toggleLoading(false); }
                );

        });
        return promise;
    }
    defaultAddress: any;
    getAllAddress() {
        this.configSettings.toggleLoading(true);
        this.userService.getUserSession().then(response => {
            if (response !== false) {
                this.userService.getUserAddress(this.user.id, this.lang, this.store.iso_code).then(data => {
                    this.selectDefault(data);
                    this.configSettings.toggleLoading(false);
                });
            } else {
                this.configSettings.toggleLoading(false);
            }
        }, error => { this.configSettings.toggleLoading(false); });
    }

    selectDefault(addresses: any) {
        addresses.forEach(address => {
            if (address.is_default == 'Yes') {
                this.defaultAddress = address;
            }
        });
    }

    removeWalletCode(): Promise<any> {
        const promise = new Promise<any>((resolve, reject) => {
            const getParams = {
                lang: this.lang,
                store: this.store.iso_code
            };
            const postParams = {
                user_id: this.user.id,
                order_id: this.configSettings.getCartId(),
            };
            this.checkoutService.removeWallet(getParams, postParams)
                .pipe(first())
                .subscribe(
                    response => {
                        if (response.status === 200) {
                            const responseData = response.data;
                            this.coupon = responseData.coupon;
                            this.discount = responseData.discount_price;
                            this.shippingCost = responseData.delivery_charges;
                            if (this.shippingCost == '0') {
                                this.is_free_delivery = true;
                            } else {
                                this.is_free_delivery = false;
                            }
                            this.cartValue = responseData.sub_total;
                            this.deliveryOptions = <DeliveryOptions[]>responseData.delivery_options;
                            resolve(true);
                        } else {
                            this.promoValidationMessage = (this.lang === 'en') ? 'UNFORTUNATELY THIS PROMO CODE IS INVALID. PLEASE CHECK AND TRY AGAIN.' : 'لا شك أن هذا الرمز الترويجي غير صالح. يرجى مراجعة وحاول مرة أخرى.';
                            setTimeout(() => { this.promoValidationMessage = ''; }, 5000);
                            this.configSettings.toggleLoading(false);
                            resolve(false);
                        }
                    }, error => { this.configSettings.toggleLoading(false); }
                );

        });
        return promise;
    }

    //ADDRESS
    submitted = false;
    hasError = false;
    countries: Country[] = [];
    governorates: State[] = [];
    areas: Area[] = [];
    sectors: Sector[] = [];
    countryCode: string = '+965';

    getAddress() { 
        this.configSettings.toggleLoading(true);
        const id = this.route.snapshot.paramMap.get('id');
        if (id) {
            this.userService.getUserSession().then(response => {
                if (response !== false) {
                    this.userService.getUserAddressDetails(id, response.id, this.store.iso_code).then(data => {

                        this.address = data.body.data;
                        this.address.area_id = +data.body.data.area_id;
                        this.address.block_id = +data.body.data.block_id;

                        if (this.address.country_id) {
                            this.loadStates(this.address.country_id);
                        }

                        if (this.address.governorate_id) {
                            this.loadAreas(this.address.governorate_id);
                        }

                        if (this.address.area_id) {
                            this.loadBlocks(this.address.area_id);
                        }
                        this.configSettings.toggleLoading(false);
                    });
                } else {
                    this.configSettings.toggleLoading(false);
                }
            }, error => { this.configSettings.toggleLoading(false); });
        } else {
            this.configSettings.toggleLoading(false);
        }
    }

    getCountries() {
        this.configSettings.toggleLoading(true);
        this.checkoutService.getCountries().then(data => {
            this.countries = data.body.data;
            this.selectedCountry = this.countries[1];
            this.configSettings.toggleLoading(false);
        }, error => { this.configSettings.toggleLoading(false); });

    }
    selectedCountry: any;

    getStates($country) {
        this.selectedCountry = $country;
        console.log($country);
        if ($country.has_states === '1') {
            this.loadStates($country.id)
            this.countryCode = '+' + $country.phonecode;
        } else {
            this.governorates = [];
        }
        this.address.governorate_id = null;
        this.address.area_id = null;
        this.address.block_id = null;
    }

    loadStates($country) {
        this.configSettings.toggleLoading(true);
        this.checkoutService.getStates($country).then(data => {
            this.governorates = data.body.data;            
            this.configSettings.toggleLoading(false);
        }, error => { this.configSettings.toggleLoading(false); });
    }

    getAreas($state) {
        console.log($state.has_areas,'$state.id');
        if ($state.has_areas === '1') {
            
            this.loadAreas($state.id)
        } else {
            this.areas = [];
        }
        this.address.area_id = null;
        this.address.block_id = null;
    }

    loadAreas($state) {
        this.configSettings.toggleLoading(true);
        this.checkoutService.getAreas($state).then(data => {
            this.areas = data.body.data;   
            this.configSettings.toggleLoading(false);
        }, error => { this.configSettings.toggleLoading(false); });
    }

    getBlocks($city) {
        if ($city.has_blocks === '1') {
            this.loadBlocks($city.id)
        } else {
            this.sectors = [];
        }
        this.address.block_id = null;
    }

    loadBlocks($city) {
        this.configSettings.toggleLoading(true);
        this.checkoutService.getBlocks($city).then(data => {
            this.sectors = data.body.data;
            this.configSettings.toggleLoading(false);
        }, error => { this.configSettings.toggleLoading(false); });
    }


    saveAddress(shippingAddressForm: FormGroup) { 
        console.log(this.shippingAddressForm.value,'shippingAddressForm');
        
        // while add address this.addressf is of type string and while edit is of type object
        // currently while fixing website we patch the code of edit and add address for it to work
        // todo: replace the entire code with reactive forms (after confirmation)
        // if (typeof this.addressf == 'string') {
            // this.addressf =this.removePrefixAndQuotesFromKeys(shippingAddressForm.value)
            this.addressf = shippingAddressForm.value
        // } else {
        //     this.mergeObjects(this.addressf,this.removePrefixAndQuotesFromKeys(shippingAddressForm.value))
        // }
        
        if (this.sectors.length > 0) {
            if (this.addressf.block_id == null) {
                this.toastr.error('Please select block first');
                return;
            }
        }
        
        this.submitted = true;
        this.configSettings.toggleLoading(true);
        this.userService.getUserSession().then(response => {
            if (response !== false) {
                const id = this.addressf.address_id;
                if (this.addressf.block_id === 0) {
                    this.addressf.block_id = null;
                }
                if (this.addressf.landmark == null) {
                    this.addressf.landmark = '';
                }
                if (id) {
                    this.checkoutService.updateAddress(response.id, this.addressf, this.store.iso_code).then(response => {
                        if (response.status == 200) {
                            this.address = response.data;
                            // this.router.navigate(['/shipping-address']);
                            this.checkoutService.setAddress(this.address);
                            this.checkoutService.setCart(this.cart);
                            document.getElementById('closeModalButton').click();
                            // this.router.navigate(['checkout']);
                        }
                        this.configSettings.toggleLoading(false);
                    });
                } else {
                    this.formSubmitAttempt=true
                  if(shippingAddressForm.valid){ this.checkoutService.addAddress(response.id, this.addressf, this.store.iso_code).then(data => {
                        this.address = data;
                        this.configSettings.toggleLoading(false);
                        // this.router.navigate(['/shipping-address']);
                        this.checkoutService.setAddress(this.address);
                        this.checkoutService.setCart(this.cart);
                        // document.getElementById('closeModalButton').click();
                        this.triggerChangeAddress.emit(data.id);
                        shippingAddressForm.reset();
                        this.getAddress()
                    });}
                }
            }
        }, error => { this.configSettings.toggleLoading(false); });
    }

    // this code is use to remove doubleQuotes and 'address-' from shippingAddressForm object
    removePrefixAndQuotesFromKeys(obj) {
        const result = {};
      
        for (const key in obj) {
          if (obj.hasOwnProperty(key)) {
            const newKey = key.replace('address-', '');
            result[newKey] = obj[key];
          }
        }
      
        return result;
      }

      // this is used to replace this.addressf value with shippingAddressForm.value
      // note : don't try this.addressf = shippingAddressForm.value because it will delete 'address_id' key
      mergeObjects(object1, object2) {
        console.log('object1',object1,'object2',object2);
        
        if (object1) {
            // Iterate through the keys of object2
            for (const key in object2) {
              // Check if the key exists in object1
              if (object1.hasOwnProperty(key)) {
                // Skip extra keys in object1
                continue;
              }
              // Copy the value from object2 to object1
             return object1[key] = object2[key];
            }
        } else {
            return object1 = object2
        }
      }
}


