<div
  class="offcanvas offcanvas-start"
  tabindex="-1"
  id="offcanvasExample"
  aria-labelledby="offcanvasExampleLabel"
>
  <!-- <div class="offcanvas-header">
      <h5 class="offcanvas-title" id="offcanvasExampleLabel">Offcanvas</h5>
      <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div> -->

  <div class="shop_look_data">
    <div class="shop_look_header">
      <div class="d-flex justify-content-between align-items-center">
        <h2>Shop The Look</h2>
        <img
          src="/assets/icons/cross_popup.svg"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          alt=""
        />
      </div>
    </div>

    <div class="shop_look_scroll">
      <div class="shop_look_body">
        <div class="d-flex gap-4">
          <div>
            <div class="shop_look_product">
              <div class="product_image">
                <figure>
                  <div class="image-wrapper">
                    <img
                      src="https://dev.shop-twl.com/uploads/168102628464326cec687551.50791675.jpg"
                      alt="Product Image"
                    />
                  </div>
                </figure>
              </div>
            </div>
          </div>
          <div>
            <div class="shop_look_details">
              <div class="shop_look_product_details">
                <h3>NA-KD</h3>
                <h6>Seam Detail Suit Trousers-Greige</h6>
                <p>KD 24.000 <span>KD 20.000</span></p>
              </div>

              <div class="size-sec">
                <div class="size-box active-box">
                  <p>34</p>
                </div>
                <div class="size-box">
                  <p>34</p>
                </div>
                <div class="size-box">
                  <p>34</p>
                </div>
                <div class="size-box">
                  <p>1</p>
                </div>
                <div class="size-box">
                  <p>2</p>
                </div>
                <div class="size-box">
                  <p>3</p>
                </div>
              </div>

              <div class="shop_look_product_button">
                <button type="button">{{ "ADD TO BAG" | translate }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="shop_look_body">
        <div class="d-flex gap-4">
          <div>
            <div class="shop_look_product">
              <div class="product_image">
                <figure>
                  <div class="image-wrapper">
                    <img
                      src="https://dev.shop-twl.com/uploads/168102628464326cec687551.50791675.jpg"
                      alt="Product Image"
                    />
                  </div>
                </figure>
              </div>
            </div>
          </div>
          <div>
            <div class="shop_look_details">
              <div class="shop_look_product_details">
                <h3>NA-KD</h3>
                <h6>Seam Detail Suit Trousers-Greige</h6>
                <p>KD 24.000</p>
              </div>

              <div class="size-sec">
                <div class="size-box active-box">
                  <p>34</p>
                </div>
                <div class="size-box">
                  <p>34</p>
                </div>
                <div class="size-box">
                  <p>34</p>
                </div>
                <div class="size-box">
                  <p>1</p>
                </div>
                <div class="size-box">
                  <p>2</p>
                </div>
                <div class="size-box">
                  <p>3</p>
                </div>
              </div>

              <div class="shop_look_product_button">
                <button type="button">{{ "ADD TO BAG" | translate }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="shop_look_body">
        <div class="d-flex gap-4">
          <div>
            <div class="shop_look_product">
              <div class="product_image">
                <figure>
                  <div class="image-wrapper">
                    <img
                      src="https://dev.shop-twl.com/uploads/168102628464326cec687551.50791675.jpg"
                      alt="Product Image"
                    />
                  </div>
                </figure>
              </div>
            </div>
          </div>
          <div>
            <div class="shop_look_details">
              <div class="shop_look_product_details">
                <h3>NA-KD</h3>
                <h6>Seam Detail Suit Trousers-Greige</h6>
                <p>KD 24.000 </p>
              </div>

              <div class="size-sec">
                <div class="size-box active-box">
                  <p>34</p>
                </div>
                <div class="size-box">
                  <p>34</p>
                </div>
                <div class="size-box">
                  <p>34</p>
                </div>
                <div class="size-box">
                  <p>1</p>
                </div>
                <div class="size-box">
                  <p>2</p>
                </div>
                <div class="size-box">
                  <p>3</p>
                </div>
              </div>

              <div class="shop_look_product_button">
                <button type="button">{{ "ADD TO BAG" | translate }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
