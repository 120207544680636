import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {Product} from "../models/product";
import {ConfigSettings} from "../config/config.settings";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-cart-summary',
  templateUrl: './cart-summary.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./cart-summary.component.css']
})
export class CartSummaryComponent implements OnInit {

  @Input() currency: string;
  @Input() cartValue: number;
  @Input() shippingCost: number;
  @Input() vatCharges: number = 0;
  @Input() deliveryText: string = '';
  @Input() is_free_delivery = false;
  @Input() codCost: number = 0;
  @Input() discount: number = 0;
  @Input() wallet: number = 0;
  @Input() total: number = 0;
  @Input() custom_duty_charges: number = 0;
  country_code:any;
  inclusive_of_taxes:any = "";

  constructor(
      private configSettings: ConfigSettings,
      private translateService: TranslateService
  ) { }

  ngOnInit() {
    if(this.deliveryText == ''){
      this.translateService.get('Delivery').subscribe((res: string) => {
        this.deliveryText = res;
      });
    }

    this.configSettings.getStoreObject().then((result) => {
      this.country_code = result.iso_code;
      if(result.iso_code == 'AE' || result.iso_code == 'SA' || result.iso_code == 'BH')
      {
        this.inclusive_of_taxes = " (Inclusive of taxes)";
      }
    });

    if(!this.discount){ this.discount = 0; }
  }

  ngOnChanges() {
  }   

}
