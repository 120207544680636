import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-event-edit',
  templateUrl: './event-edit.component.html',
  styleUrls: ['./event-edit.component.scss']
})
export class EventEditComponent {

  @ViewChild('eventMainImage') eventMainImageRef: ElementRef;
  @ViewChild('rangeBar') rangeBarRef: ElementRef;
  @Input() eventData : any 

  isMobile: boolean =  window.innerWidth < 600;
  isRangeBarVisible: boolean = false

  slideCounter = Array

  constructor(
    private router: Router,
  ){
    
  }

  ngOnInit() {

    // to display progress bar based on length of data
    const minEventDataLength = this.isMobile ? 2 : 5;
    this.isRangeBarVisible = this.eventData.length > minEventDataLength;

  }

  // to update the rangeBar value based on the scroll position 
  onEventMainImageScroll(event: Event) {
    const scrollAmount = (event.target as HTMLElement).scrollLeft;
    const totalScrollableWidth = (event.target as HTMLElement).scrollWidth - (event.target as HTMLElement).clientWidth;
    const rangeValue = (scrollAmount / totalScrollableWidth) * 100;
    this.rangeBarRef.nativeElement.value = rangeValue;
  }

  // to update the scroll position based on rangeBar value
  onRangeChange(event: Event) {
    const rangeValue = (event.target as HTMLInputElement).valueAsNumber;
    const scrollAmount = (this.eventMainImageRef.nativeElement.scrollWidth - this.eventMainImageRef.nativeElement.clientWidth) * (rangeValue / 100);
    this.eventMainImageRef.nativeElement.scrollLeft = scrollAmount;
  }

   // to navigate to product details 
   routeToDetails(productId) {
    this.router.navigate(['/product/detail/', productId])
  }

  // for routing of banners
  routing(value: any) {

    const type = value.link_type;
    if (type === 'B' || type === 'BR') {
      this.router.navigate(['/brand', this.getUrlName(value.name), value.link_id])
      return
    }
    else if (type === 'C' || type === 'FC') {
      this.router.navigate(['/category', this.getUrlName(value.name), value.link_id])
      return
    }
    else if (type === 'P') {
      this.router.navigate(['/product/detail', value.link_id])
      return;
    }
    else if (type === 'FP') {
      this.router.navigate(['/product/detail', value.link_id])
      return;
    }
    else if (type === 'L') {
      this.router.navigateByUrl(value.link)
      return value.link;
    }
    else if (type === 'S') {
      this.router.navigate(['/shop', this.getUrlName(value.name), value.link_id])
      return;
    }
    else if (type === 'F') {
      this.router.navigate(['/search'], { queryParams: this.getCollectionQueryParams(value, 0) })
      return;
    } else if (type === 'N') {
      this.router.navigate(['/shop/new-arrivals'], { queryParams: this.getCollectionQueryParams(value, 1) })
      return;
    }
    return '';
  }

  // to remove spaces in url string with "-"
  getUrlName(productName: string): string {
    var prodN = productName ? (productName.toLowerCase().replace(/[^A-Z0-9]+/ig, "-")) : '';
    return prodN;
  }

  // get query params based on latestId
  getCollectionQueryParams(collection: any, latestId: any): any {
    if (latestId == 0) {
      return {
        q: '',
        lang: 'en',
        price_range: '',
        in_stock: '',
        typepage: '1',
        per_page: '20',
        is_featured: '',
        latest: latestId,
        best_selling: '',
        sort_by: '1',
        store: '',
        flash_sale_id: collection.link_id,
        shop_type_id: ''
      };
    } else {
      return {
        lang: 'en',
        price_range: '',
        in_stock: '',
        typepage: '1',
        per_page: '20',
        is_featured: '',
        latest: latestId,
        best_selling: '',
        sort_by: '1',
        store: '',
        flash_sale_id: '',
        shop_type_id: ''
      };
    }

  }


}
