import { Injectable } from '@angular/core';
import { BehaviorSubject,Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CallWishlistService {

  private signal: Subject<any> = new BehaviorSubject([]);
  private entityId:Subject<any> =new BehaviorSubject([]);
  private loader:Subject<any> =new BehaviorSubject([]);

  constructor() { }
  setCallWishList(item:any){
    this.signal.next(item);
  }
  getCallWishList(){
    return this.signal.asObservable();
  }
  setEntityId(item:any){
    this.entityId.next(item);
  }
  getEntityId(){
    return this.entityId;
  }
  setLoder(value:any){
    this.loader.next(value);
  }
  getLoader(){
    return this.loader.asObservable();
  }

}
