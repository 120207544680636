<div class="my_account_data" *ngIf="skeletonLoader">
    <div class="container-fluid">
        <section class="user-account main-profile">
            <div class="account_heading pt-3 pb-5">
                <ul class="d-flex flex-wrap align-items-center justify-content-center w-100 listing-page-routing">
                    <li class="routing-list w-25">
                        <ngx-skeleton-loader></ngx-skeleton-loader>
                    </li>
                </ul>
            </div>
            <div class="pb-5">
                <div class="d-flex w-100 justify-content-center w-100">
                    <div class="user-left-menu d-none d-lg-block w-25">
                        <div class="w-50">
                            <ngx-skeleton-loader count="8"></ngx-skeleton-loader>
                        </div>
                    </div>
                    <div class="white-background user-profile-content w-100 w-lg-75">
                        <div class="breadcrumb_heading">
                            <h3 class="w-25">
                                <ngx-skeleton-loader></ngx-skeleton-loader>
                            </h3>
                        </div>
                        <div class="content skelton-circle-box">
                            <ngx-skeleton-loader class="skelton-box"></ngx-skeleton-loader>
                            <p class="pad w-25">
                                <ngx-skeleton-loader count="2"></ngx-skeleton-loader>
                            </p>
                            <div class="flex flex1 flex-skeleton items-height-skeleton">
                                <ngx-skeleton-loader class="w-100 height-fix"></ngx-skeleton-loader>
                            </div>
                            <div class="content">
                                <h2 class="share"></h2>
                            </div>
                            <div class="shareicon">
                                <div class="skelton-circle-main" *ngFor="let item of [1,2,3,4]">
                                    <ngx-skeleton-loader class="skelton-circle" count="1"></ngx-skeleton-loader>
                                </div>
                            </div>
                            <h2 class="step w-25">
                                <ngx-skeleton-loader></ngx-skeleton-loader>
                            </h2>
                            <div class="stepcontent">
                                <div class="d-flex gap-3 align-items-lg-start align-items-lg-center w-100" >
                                    <ngx-skeleton-loader class="w-50" count="3"></ngx-skeleton-loader>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>

<ng-container *ngIf="dataAvailable && !skeletonLoader">
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">

    <div class="my_account_data">
        <div class="container-fluid">
            
            <section class="user-account main-profile" id="" @listStagger>

                <!-- <div class="account_heading pt-3 pb-5">
                    <ul class="d-flex flex-wrap align-items-center justify-content-center w-100 listing-page-routing">
                        <li class="routing-list">
                            <a [routerLink]="'/my-account'">
                                {{ "my account" | translate }}
                            </a>
                        </li>
                        <li class="routing-list">{{ "Store Credit" | translate }}</li>
                    </ul>
                </div> -->
        

                <div class="account_heading pt-3 pb-5">
                    <ul class="d-flex flex-wrap align-items-center justify-content-center w-100 listing-page-routing">
                        <li class="routing-list">
                            <a [routerLink]="'/my-account'">
                                {{ "my account" | translate }}
                            </a>
                        </li>
                        <li class="routing-list">{{ "Refer a Friend" | translate }}</li>
                    </ul>
                </div>

        
                        <div class="pb-5">
                            <div class="d-flex w-100 justify-content-center w-100">
                                <div class="user-left-menu d-none d-lg-block w-25">
                                    <app-user-account-menu [page]="7"></app-user-account-menu>
                                </div>
                                <div class="white-background user-profile-content w-100 w-lg-75">

                                    <div class="breadcrumb_heading">
                                        <h3><a [routerLink]="'/my-account'"> <img src="/assets/icons/right-arrow.svg" alt="arrow"></a>{{'Refer your friends and Earn' | translate}}</h3>
                                    </div>

                                    <ng-container *ngIf="paymentRespose.Result">
                                        <ng-container *ngIf="paymentRespose.Result === 'CAPTURED'">
                                            <h5 class="text-success">{{'THANK YOU, YOUR PAYMENT WAS SUCCESSFUL!' | translate}}
                                            </h5>
                                            <p class="content-description page-subtitle font-regular">
                                                {{"You will receive an e-mail shortly with full details of your purchase. Hope you enjoyed the shopping experience with Wishlist. For any queries please email us on support_email" | translate:{'support_email':'support@furni-shop.com'} }}
                                            </p>
                                        </ng-container>
                                        <ng-container *ngIf="paymentRespose.Result !== 'CAPTURED'">
                                            <h5 class="text-danger">{{'THERE WAS AN ERROR PROCESSING YOUR PAYMENT!' | translate}}</h5>
                                        </ng-container>
                                        <div class="row">
                                            <div class="col-12 col-md-6">
                                                <table class="table table-bordered table-striped payment-table">
                                                    <tr>
                                                        <th>{{'Result' | translate}}</th>
                                                        <td>{{paymentRespose.Result}}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>{{'Auth' | translate}}</th>
                                                        <td>{{paymentRespose.Auth}}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>{{'Payment ID' | translate}}</th>
                                                        <td>{{paymentRespose.PaymentID}}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>{{'Reference Id' | translate}}</th>
                                                        <td>{{paymentRespose.Ref}}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>{{'Track ID' | translate}}</th>
                                                        <td>{{paymentRespose.TrackID}}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>{{'Transaction ID' | translate}}</th>
                                                        <td>{{paymentRespose.TranID}}</td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                        <hr>
                                    </ng-container>
        
                               
                                    <div class="content">
                                        <img src="/assets/images/gift.svg" alt="" class="image1">
                                        <p class="pad">{{'Your friend gets 2 KD when they sign up and,'|translate}}<br> {{'you get KD 5 when they place their first order'|translate}}</p>
                                      
                                        <div class="flex flex1">
                                            <div class="content1">
                                                <p>{{'Your Referral Code'|translate}}</p>
                                                <h4 id="referralCode">{{referralCode}}</h4>
                                            </div>
                                            <div class="flex refer cursor-pointer" (click)="copyCode()">
                                                <div>
                                                    <img src="/assets/images/copy2.svg" alt=""  id="ref">
        
                                                </div>
        
                                                <div class="copy">
                                                    <p>{{'Copy'|translate}}<br>{{'Code'|translate}}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="content">
                                            <h2 class="share"></h2>
                                        </div>
                                        <div class="shareicon">
                                            <a href="{{facebookLink}}" target="_blank"><img src="/assets/images/facebook-new.png"
                                                    alt=""></a>
                                            <a href="https://wa.me/?text=Install The Wish List Official App Now to enjoy the best offers. Sign up with {{referralCode}} code and get 5.000 KD https://app.shop-twl.com/kLPyzGB0Ajb"
                                                target="_blank"><img src="/assets/images/whatsapp-new.png" alt=""></a>
                                                <a href="{{instagramLink}}" target="_blank"> <img src="/assets/images/instagram-new.png" alt=""></a>
                                            <div class="radius1">
                                                 <img src="../../assets/images/snapchat-outline-svgrepo-com.svg" alt="" class="adjust1">
                                            </div>
                                            <!-- <img src="/assets/images/snapchat-new.png" alt="" class=""> -->
                                            <div class="radius">
        
                                                <img src="/assets/images/copy-svgrepo-com.svg" alt="" (click)="copyCode()" id="ref" class="adjust">
                                                <!-- <img src="/assets/images/Ellipse 598.png" alt="" (click)="copyCode()" id="ref"> -->
                                            </div>
                                          
                                        </div>
                                        <h2 class="step">{{'Steps to earn rewards'|translate}}</h2>
                                        <div class="stepcontent">
                                            
                                                <div class="d-flex gap-3 align-items-lg-start align-items-lg-center"><span><img src="/assets/icons/dot.svg" alt="dot"></span>
                                                    <p>{{'Share your referral code with your friends/family & ask  them to register with The Wishlist.'|translate}} </p>
                                                </div>
                                                <div class="d-flex gap-3 align-items-lg-start align-items-lg-center"><span><img src="/assets/icons/dot.svg" alt="dot"></span>
                                                    <p>{{'Once they register they get rewarded with KD 2 reward.'|translate}}</p>
                                                </div>
                                                <div class="d-flex gap-3 align-items-lg-start align-items-lg-center"> <span><img src="/assets/icons/dot.svg" alt="dot"></span>
                                                    <p>{{'After they place their first order, you get Rewarded with KD 2'|translate}}</p>
                                                </div>
        
                                        </div>
                                    </div>
        
                                </div>
                            </div>
                        </div>
                        
            </section>

        </div>
    </div>

   

</ng-container>