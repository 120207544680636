<ng-container *ngIf="skeletonLoader">
    <section id="cms-page" class="{{lang == 'ar' ? 'text-right' : ''}}" >
        <div class="container-fluid">
            <div class="cms-content">
                <h4 class="cms-title font-bold w-25 mx-auto">
                    <ngx-skeleton-loader count="1" [theme]="{'background-color': '#F1F1F1','height':'35px'}" ></ngx-skeleton-loader>
                </h4>
                <div class="content-body">
                    <ngx-skeleton-loader count="10"[theme]="{'background-color': '#F1F1F1'}" ></ngx-skeleton-loader>
                </div>
            </div>
        </div>
    </section>
</ng-container>
<ng-container *ngIf="dataAvailable && !skeletonLoader">
    <section id="cms-page" class="{{lang == 'ar' ? 'text-right' : ''}}" >
        <div class="container-fluid">
            <div class="cms-content">
                <h4 class="cms-title font-bold w-100">{{page}}</h4>
                <div class="content-body" [innerHTML]="content | safeHtml"></div>
            </div>
        </div>
    </section>
</ng-container>