import {Component, Input, OnInit,Output,EventEmitter} from '@angular/core';
import {Product} from '../models/product';
import {Store} from '../models/store';
import {ConfigSettings} from '../config/config.settings';
import {ConfigService} from '../config/config.service';
import {UserService} from '../config/user.service';
import {WishlistService} from '../config/wishlist.service';
import {first} from 'rxjs/operators';
import {AnalyticsService} from '../services/analytics.service';
import { CheckUserLoggedInService } from '../services/check-user-loggedin.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NavbarService } from '../config/navbar.service';
import { ToastrService } from 'ngx-toastr';
import { AddToBagService } from '../services/add-to-bag.service';
import { UpdateWishlistCountService } from '../services/update-wishlist-count.service';
import { CallWishlistService } from '../services/call-wishlist.service';
@Component({
  selector: 'app-product-item',
  templateUrl: './product-item.component.html',
  styleUrls: ['./product-item.component.scss']
})
export class ProductItemComponent implements OnInit {
  @Input() item: Product;
  @Input() currency: string;
  @Input() enableRemoveWishlist: boolean = false;
  @Input() lang: string;
  @Input() store: Store;
  @Input() userId: number;
  @Input() returnUrl: string;
  @Input() minimal = false;
  @Input() hideWishlistIcon : boolean = false
  @Input() showWishlistIcon = false;
  @Input() showCrossIcon = false;

  @Output('reloadProductList') reloadProductList: EventEmitter<any> = new EventEmitter();

  in_wishlist = false;
  imgSrc: string;
  isMobile = false;
  isUserLoggedIn: boolean = false
  isCurrentPageWishlist: boolean = false 
  user: any
  isFlipped: any = false;
  removeSubscriber$:any;
  constructor(
    private configSettings: ConfigSettings,
    private configService: ConfigService,
    private userService: UserService,
    private wishlistService: WishlistService,
    private analytics: AnalyticsService,
    private router: Router,
    private checkUserLoggedInService: CheckUserLoggedInService,
    private route : ActivatedRoute,
    private navbarService:NavbarService,
    private toastr : ToastrService,
    private addToBagSer: AddToBagService,
    private updateWishlistCountService : UpdateWishlistCountService,
    private callWishListService:CallWishlistService
  ) {
  }

  ngOnInit() {
    this.isMobile = window.innerWidth < 600;
    this.in_wishlist = (this.item.item_in_wishlist === 1);
    this.imgSrc = this.item.image;
    if (this.item.hover_image === undefined) {
      this.item.hover_image = this.item.image;
    }  

    this.lang = this.configSettings.getLang();
    this.isUserLoggedIn = this.userId != undefined ?  true : false

    this.checkUserLoggedInService.isUserLoggedIn.subscribe((response : boolean) => {   
      this.user = JSON.parse(this.configSettings.getLogInUserData()) 
      if (this.user) {
        this.userId = Number(this.user.id); 
      }
      this.isUserLoggedIn = response
    })

    // to check if url includes 'wishlist'
    this.isCurrentPageWishlist = location.href.includes('/wishlist');

    this.item.brand = this.item.brand_name? this.item.brand_name : this.item.brand; 

    this.removeSubscriber$=this.callWishListService.getEntityId().subscribe((res)=>{
      if(res.length!=0){
        this.item.id=res;
        this.removeFromWishlist();
      }
    })

  }

  // if user not logged in rout to login page, and if user is logged in initiate toggle wishlist functionally
  toggleWishlist() {
    if (!this.userId) {
      const loginUrl = this.returnUrl ? '/login?returnUrl=' + this.returnUrl : '/login';
      window.location.href = loginUrl;
      return false;
    }
  
    if (this.in_wishlist) {
      this.removeFromWishlist();
    } else {
      this.addToWishlist();
    }
  }
  

  // to remove item from wishlist
  removeFromWishlist() {
    this.callWishListService.setLoder(true);
    const getParams = {
      lang: this.lang,
      store: this.store.iso_code
    };

    const postParams = {
      product_id: this.item.id,
      user_id: this.userId
    };

    this.wishlistService.removeFromWishlist(getParams, postParams)
      .pipe(first())
      .subscribe(
        response => {
          if (response.status === 200) {
            let items = response.data;
            this.userService.setWishlist(items);
            this.in_wishlist = !this.in_wishlist;
            this.toastr.error(response.message)
            this.updateWishlistCountService.setWishlistCount(response?.wishlist_count)
          }
        }
      );
      // add HeartAnimation
      this.removeMiniHearts();
      const styleTags = document.querySelectorAll('.style-move-heart');
      styleTags.forEach((el) => {
        const animationSetId = el.getAttribute('data-animation-set-id');
        if (animationSetId === this.subHearts[0].animationSetId) {
          el.remove();
        }
      });

    return false;
  }

  // to add item to wishlist
  addToWishlist() {
    const getParams = {
      lang: this.lang,
      store: this.store.iso_code
    };
    const postParams = {
      product_id: this.item.id,
      user_id: this.userId
    };
    this.wishlistService.addToWishlist(getParams, postParams)
      .pipe(first())
      .subscribe(
        response => {
          if (response.status === 200) {            
            this.analytics.addToWishlist(
                'Browse',
                this.item.id,
                this.item.name,
                this.item.marketing_category+' - '+this.item.marketing_subcategory,
                this.item.brand_name,
                this.item.final_price,
                this.item.regular_price,
            );
            let items = response.data;
            this.userService.setWishlist(items);
            this.in_wishlist = !this.in_wishlist;
            this.toastr.success(response.message)            
            this.updateWishlistCountService.setWishlistCount(response?.wishlist_count)
          }
        }
      );
      // add HeartAnimation
      this.isHeartFilled = true;
      this.generateHearts();

    return false;
  }

  routingToDetails(productId){
    // if (this.isMobile) {
    //   this.routeToDetailsSmallDevice(productId)
    // } else {
      this.routeToDetails(productId)
    // }

  }


  // to navigate to product details
  routeToDetailsSmallDevice(productId) {    
    this.router.navigate(['/product/detail/',productId])
  }

  // to navigate to product details in new tab
  routeToDetails(productId) {
    // const url = `/product/detail/${productId}`;
    // window.open(url, '_blank');
    this.router.navigate(['/product/detail/',productId]);

  }

  // onMouseOver(event){
  //   this.imgSrc = (this.item.hover_image)?this.item.hover_image:this.item.image;
  // }

  // onMouseOut(event){
  //   this.imgSrc = this.item.image;
  // }
  redirectToUrl(id){
    window.location.href="/product/detail/"+id;
  }

  flipTheBox(){
    this.isFlipped = !this.isFlipped;
  }

  logInWhistList(){
    this.navbarService.triggerChangeShowGuestLogin(false);
    this.router.navigate(['/login'])
  }

  takeToBag(item:any){
    this.addToBagSer.setBag(item);
  }

  // to set product badge based upon condition
  setProductBadge(productObj: any): string {    
    let txt: string = '';    
    // this console log is for testing purpose
    // console.log(
    //   `productObj.name : ${productObj.name}, `,
    //   `productObj.is_saleable : ${productObj.is_saleable}, `,
    //   `productObj.is_preorder : ${productObj.is_preorder}, `,
    //   `productObj.is_trending : ${productObj.is_trending}, `,
    //   `productObj.is_featured : ${productObj.is_featured}, `
    // );
    
    if (productObj.is_saleable == 0 ) {
      txt = this.lang == 'en' ? 'SOLD OUT' : 'تم البيع';
    } else if (productObj.is_preorder == 1 && productObj.remaining_quantity > 0) {  
      txt = this.lang == 'en' ? 'PRE ORDER' : 'الطلب المسبق';
    } else if (productObj.is_trending == 1) {
      txt = this.lang == 'en' ? 'NEW' : 'جديد';
    } else if (productObj.is_featured == 1) {
      txt = this.lang == 'en' ? 'BEST SELLER' : 'الأكثر مبيعًا';
    }
    
    return txt;
  }
  ngOnDestroy(){
    this.removeSubscriber$.unsubscribe();
  }

  // DiscountAmount calculate
  calculateDiscountAmount(item): number {

    let discountPercentage = ((item.regular_price - item.final_price) / item.regular_price) * 100;
    let discountPercentageValue=Math.round(discountPercentage)
    return discountPercentageValue;
  }

// heart animation 
  isHeartFilled = false;
  subHearts = [];
  
  getRandomInt(min, max) {
    return Math.random() * (max - min) + min;
  }
  
  keyframeRandomMove() {
    return Math.floor(this.getRandomInt(-100, 100));
  }
  
  keyframesMove(index) {
    return `
      @keyframes move-heart-${index} {
        from { transform: translate(-50%, -50%); opacity: 1; }
        to { transform: translate(${this.keyframeRandomMove()}%, ${this.keyframeRandomMove()}%); opacity: 0; }
      }
      .move-heart-${index} {
        animation: move-heart-${index} 1.3s 0s ease-in-out forwards;
      }
    `;
  }
  generateHearts() {
    if (!this.isHeartFilled) {
      return;
    }
  
    const numberOfHearts = Math.floor(this.getRandomInt(12, 20));
    this.subHearts = Array.from({ length: numberOfHearts }, (_, i) => ({
      color: '#000',
      moveSideWise: this.keyframeRandomMove(),
      moveUpOrDown: this.keyframeRandomMove()
    }));
  
    for (let i = 1; i < numberOfHearts; i++) {
      const style = document.createElement('style');
      style.setAttribute('class', 'style-move-heart');
      style.innerHTML = this.keyframesMove(i);
      document.head.appendChild(style);
    }

    
  }
  removeMiniHearts() {
    this.subHearts = [];
  }
  // HeartAnimation
  // toggleHeartAnimation() {
  //   this.isHeartFilled = !this.isHeartFilled;
  //   if (!this.isHeartFilled) {
  //     this.removeMiniHearts();
  //     const styleTags = document.querySelectorAll('.style-move-heart');
  //     styleTags.forEach((el) => {
  //       const animationSetId = el.getAttribute('data-animation-set-id');
  //       if (animationSetId === this.subHearts[0].animationSetId) {
  //         el.remove();
  //       }
  //     });
  //   } else {
  //     this.generateHearts();
  //   }
  // }
  
}
