import { Injectable } from '@angular/core';
import { BehaviorSubject,Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class callContactNo {
    private phone: Subject<any> = new BehaviorSubject([]);

    constructor() { }
    setContactNo(item:any){
      this.phone.next(item);
    }
    getContactNo(){
      return this.phone.asObservable();
    }
}