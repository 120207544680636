import {Injectable} from '@angular/core';
import {ConfigService} from "./config.service";
import {CryptService} from "./crypt.service";
import {LocalStorage} from "@ngx-pwa/local-storage";
import {map} from 'rxjs/operators';
import {ConfigSettings} from './config.settings';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {

    store_code: string;

    constructor(
        private configService: ConfigService,
        private configSettings: ConfigSettings
    ) { }
    
    ngOnInit() {
        this.configSettings.getStoreObject().then((result) => {
            this.store_code = result.iso_code;
        });
    }

    login(username: string, password: string) {
        var country_iso = this.store_code;
        if(country_iso == undefined || country_iso=='')
        {
            country_iso = 'KW';
        }
        const device = this.configSettings.getBrowserVersion();
        const params = {
            email: username,
            password: password,
            device_type: 'W',
            device_model: device,
            app_version: device,
            store: country_iso,
            phone: '',
            os_version: window.navigator.platform
        };
        return this.configService.postRequest('login', {}, params)
            .pipe(map(response => {
                return response;
            }));
    }

    socialRegister(params: any) {
        return this.configService.postRequest('social-register', {}, params)
            .pipe(map(response => {
                return response;
            }));
    }

    register(params: any) {
        return this.configService.postRequest('register', {}, params)
            .pipe(map(response => {
                return response;
            }));
    }

    guestRegister(params: any) {
        return this.configService.postRequest('guest-register', {}, params)
            .pipe(map(response => {
                return response;
            }));
    }

    forgotPassword(email: string) {
        const params = {
            email: email,
        };
        return this.configService.postRequest('forgot-password', {}, params)
            .pipe(map(response => {
                return response;
            }));
    }

    editProfile(params: any) {
        return this.configService.postRequest('edit-profile', {}, params)
            .pipe(map(response => {
                return response;
            }));
    }

    changePassword(params: any) {
        return this.configService.postRequest('change-password', {}, params)
            .pipe(map(response => {
                return response;
            }));
    }

    addWalletCredit(params: any) {
        return this.configService.postRequest('wallet-recharge', {}, params)
            .pipe(map(response => {
                return response;
            }));
    }

    redeemVoucher(getParam: any, postParam: any) {
        return this.configService.postRequest('redeem-voucher', getParam, postParam)
            .pipe(map(response => {
                return response;
            }));
    }

    purchaseGift(params: any) {
        return this.configService.postRequest('purchase-gift-card', {}, params)
            .pipe(map(response => {
                return response;
            }));
    }

}
