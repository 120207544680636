import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, HostListener  } from '@angular/core';
import { ConfigService } from '../config/config.service';
import { Product } from '../models/product';
import { ActivatedRoute } from '@angular/router';
import { Filters } from "../models/filters";
import { FilterValues } from "../models/filter-values";
import { ConfigSettings } from "../config/config.settings";
import { Category } from "../models/category";
import { LocalStorage } from '@ngx-pwa/local-storage';
import { Store } from '../models/store';
import { FooterService } from "../config/footer.service";
import { listStagger } from '../animations';
import { User } from '../models/user';
import { UserService } from '../config/user.service';
import { Router } from '@angular/router';
import { AnalyticsService } from '../services/analytics.service';
import { NavbarService } from '../config/navbar.service';
import { TranslateService } from '@ngx-translate/core';
import { IsFilterOnService } from '../services/is-filter-on.service';
import { CheckSearchBoxActiveService } from '../services/check-search-box-active.service';
import { ViewportScroller } from '@angular/common';
import { ChangeMetaService } from '../services/change-meta-service.service';
import { HeaderToggleService } from '../services/header-toggle.service';
import { ToastrService } from 'ngx-toastr';
import { WishlistService } from '../config/wishlist.service';



@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss'],
  animations: [listStagger]
})
export class ProductListComponent implements OnInit, AfterViewInit {
  products: Product[] = [];
  page: number = 1;
  pageSize: number = 21;
  totalPages: number;
  totalProducts: number;
  isFeatured: number;
  latest: number;
  bestSelling: number;
  sortBy: number = 1;
  store: Store;
  lang: string;
  title: string;
  brands: number[] = [];
  collection: number = 0;
  attributes: number[] = [];
  filters: Filters[] = [];
  filterValues: FilterValues[];
  params: Object;
  sortOptions: any[] = [];
  categories: Category[] = [];
  category: any;
  selectedCategory: number;
  id: number;
  shop_id: number;
  q: string = '';
  currency: string;
  dataAvailable = false;
  user: User = new User();
  fixedFilter = false;
  currentRoute = '';
  breadcrumb: any[] = [];
  applyBtnEnabled: boolean = false;
  isFilterLoading = false;
  flash_sale_id = '';
  categoriesArr: any = [];
  categoriesInParams: any = [];
  isSearchBoxActive: boolean = false
  isFistTimeLoad = true;
  // deval sir need this 
  filterShow:boolean = false;
  selectedSortOption: string = ''; 
  toggleSort: boolean = false;
  skeletonLoader:boolean=true;
  isMobile: boolean = false;
  skeltonTheme:any;
  counter = Array;
  maxProductPrice: number = 0
  isBrandListingPage: boolean = false
  brandInfo : any
  tierId : number
  parentBrandId: any = []

  minPrice: number = 0;
  maxPrice: number;
  maxPriceShow:any;
  price :any='';
  fromPriceRange:boolean=false;
  fromFilter:boolean=false;
  type='';
  constructor(
    private wishlistService : WishlistService,
    private configService: ConfigService,
    private route: ActivatedRoute,
    private configSettings: ConfigSettings,
    protected localStorage: LocalStorage,
    private router: Router,
    private navbarService: NavbarService,
    private analytics: AnalyticsService,
    protected footerService: FooterService,
    private userService: UserService,
    private translateService: TranslateService,
    private isFilterOnService: IsFilterOnService,
    private scroller: ViewportScroller,
    private checkSearchBoxActiveService: CheckSearchBoxActiveService,
    private changeMeta: ChangeMetaService,
    private headerToggleService: HeaderToggleService,
    private toastr: ToastrService

  ) {
    this.route.queryParams.subscribe(params => {      
      this.page=params.pageNo ? params.pageNo : 1;
      this.type=String(params.pt?params.pt:'');
      this.q = (params['q']) ? params['q'] : '';
      this.flash_sale_id = (params['flash_sale_id']) ? params['flash_sale_id'] : '';
      if (this.q != '') {
        if (!this.isFistTimeLoad) {
          this.getProductList();
        }
      }
    });
    this.scrollToTop()
    
  }
  ngOnInit() {
    this.isMobile = window.innerWidth < 600;
    this.pageSize = (window.innerWidth < 769) ? 22 : 24;
    this.updateBreadCrumb();
    this.getRouterData()
    this.getRouterQueryData()
    this.currentRoute = this.route.snapshot.routeConfig.path;
    this.categoriesInParams = this.route.snapshot.params.id

    // this.page = 1;
    this.isFeatured = 0;
    this.latest = 0;
    this.bestSelling = 0;
    this.sortBy = 1;
    this.id = this.route.snapshot.params.id;
    if (this.route.snapshot.params.shop_id) {
      this.shop_id = this.route.snapshot.params.shop_id;
      this.title = this.route.snapshot.params.name.toUpperCase();
      // this.savePatentBrandId()
    }

    this.lang = this.configSettings.getLang();
    this.navbarService.isEnableHeaderBottomMobileAction(false);
    this.configSettings.getStoreObject().then((result) => {
      this.store = <Store>result;
      this.currency = result['currency_' + this.lang];
      /**
      * Getting user session data
      */
      this.userService.getUserSession().then(response => {
        if (response !== false) {
          this.user = response;
        }

        this.route.params.subscribe((params) => {
          if (this.id && this.id.toString() !== params['id']) {
            this.updateBreadCrumb();
            this.id = +params['id'];
            this.getProductList();
          }
        });
        this.getProductList();
        this.getSortOptions();
       
      });
    });

    this.checkSearchBoxActiveService.isSearchBoxActive.subscribe(res => {
      this.isSearchBoxActive = res as boolean
    })
    this.skeletonLoader=true;          
    this.skeltonTheme=this.setThemeLogic();
  }

  // logic for skelton loader theme for big and small device
  setThemeLogic() {
    return {
      'border-radius': '5px',
      // 'height': this.isMobile ? '248px' : '536px',
      'animation-duration': '2s'
    };
  }
  
  
  ngAfterViewInit() {
    this.configSettings.load.subscribe(loading => {
      if (loading == false) {
          setTimeout(() => {
            this.skeletonLoader=false;          
            }, 800);
        let typeOfPage = location.pathname;
      
        this.route.params.subscribe(res => {

          const metaTitle = res.name_3 || res.name_2 || res.name;
          //might change the description in future based on category and brands hence it is kept..
          if (typeOfPage.includes('/category')) {
            this.changeMeta.updateMetaTags(metaTitle, "Everything on your Wish List!' Discover and shop a variety of pieces ranging from clothes, bags, shoes, to accessories and more.")
          } else if (typeOfPage.includes('/brands')) {
            this.changeMeta.updateMetaTags(metaTitle, "Everything on your Wish List!' Discover and shop a variety of pieces ranging from clothes, bags, shoes, to accessories and more.")
          }
        });
      }
    });
    this.activateHeader();
  }

    // update breadcurmb based on router url
    updateBreadCrumb() {
      this.breadcrumb = this.removeDuplicates(this.removeNumbersFromArray(this.route.snapshot.url));
      this.breadcrumb.pop();
    }
    
    // to remove numbers from breadcrumb
    removeNumbersFromArray(inputArray: any[]): any[] {
      return inputArray.filter((item) => typeof item !== 'number');
    }
    
    // to remove duplicates from breadcrumb
     removeDuplicates(inputArray: any[]): any[] {
      const uniqueItems = [];
      const uniquePaths = new Set();
    
      for (const item of inputArray) {
        const currentPath = item.path;
    
        if (!uniquePaths.has(currentPath)) {
          uniqueItems.push(item);
          uniquePaths.add(currentPath);
        }
      }
    
      return uniqueItems;
    }
       

    // to get router > params data
    getRouterData() {
      this.route.params.subscribe(params => {
        if (params.tierId) {
          this.isBrandListingPage = true // if params contain tierId then the listing page is brand listing page
          this.tierId = Number(params.tierId)
        }
      })
    }

    getRouterQueryData(){
      this.route.queryParamMap.subscribe((queryParams : any) => {        
        if (queryParams.params.tierId) {
          this.isBrandListingPage = true // if queryParams.params contain tierId then the listing page is brand listing page
          this.tierId = Number(queryParams.params.tierId)
          this.parentBrandId.push(queryParams.params.brandId)
          
        }
      })
    }


  // set default sort options
 getSortOptions() {
   const options = [
     { value: 1, enLabel: 'Our Picks', arLabel: 'اختياراتنا' },
     { value: 2, enLabel: 'New Items', arLabel: 'منتجات جديدة' },
     { value: 3, enLabel: 'Price (high first)', arLabel: 'السعر (الأعلى أولاً)' },
     { value: 4, enLabel: 'Price (low first)', arLabel: 'السعر (الأقل أولاً)' },
     { value: 5, enLabel: 'Sale Products', arLabel: 'تخفيضات فقط' }
    ];
    
    this.sortOptions = (this.lang === 'en') 
    ? options.map(option => ({ value: option.value, label: option.enLabel }))
    : options.map(option => ({ value: option.value, label: option.arLabel }));

    this.selectedSortOption = this.sortOptions[0].label;

  }

  toggleSortFun(event: Event){
    event.stopPropagation(); // to prevent event propagation to document click listener when clicked outside
    this.toggleSort = !this.toggleSort
  }

  // to get categories from local storage
  getCategoryLocalStorage(): number {
    return +localStorage.getItem('top_selected_category');
  }

  // to get product list based on router params
  getProductList() {
    this.skeletonLoader=true;
    this.isFistTimeLoad = false;
    this.configSettings.toggleLoading(true);
    const path = this.route.snapshot.url;    
    let type = '';
    this.route.params.subscribe((params) => {
      type = params.type;
    });

    if (type === 'featured') {
      this.isFeatured = 1;
      this.title = 'Featured';
      this.category = this.getCategoryLocalStorage();
      this.doSearch();
    } else if (type === 'best-sellers') {
      this.bestSelling = 1;
      this.title = 'Best Sellers';
      this.category = this.getCategoryLocalStorage();
      this.doSearch();
    } else if (type === 'new-arrivals') {
      // this.savePatentBrandId()
      this.category = this.getCategoryLocalStorage();
      this.latest = 1;
      this.title = 'New Arrivals';
      this.doSearch();
    } else if (type === 'new-arrival') {
      this.category = '';
      this.latest = 1;
      this.title = 'New Arrivals';
      this.doSearch();
    } else if (path.some(e => e.path === 'category')) {
      // this.savePatentBrandId()
      const categoryId = +this.route.snapshot.paramMap.get('id');
      this.category = categoryId;
      this.doSearch();
    } else if (path.some(e => e.path === 'brand')) {
      this.brands = [];
      this.parentBrandId = []
      const brandId = +this.route.snapshot.paramMap.get('id');
      this.isBrandListingPage ? this.parentBrandId.push(brandId) : this.brands.push(brandId);
      this.doSearch();
    } else if (path.some(e => e.path === 'brands')) {
      this.brands = [];
      this.parentBrandId = []
      const brandId = +this.route.snapshot.paramMap.get('id');
      this.isBrandListingPage ? this.parentBrandId.push(brandId) : this.brands.push(brandId);
      this.doSearch();
    } else if (path.some(e => e.path === 'sale')) {
      this.flash_sale_id = this.route.snapshot.paramMap.get('id');
      this.doSearch();
    } else if (path.some(e => e.path === 'collection')) {
      this.collection = +this.route.snapshot.paramMap.get('id');
      this.doSearch();
    } else {
      this.doSearch();
    }
  }

  
  // to handel search
  doSearch() {
    const params = {
      q: (this.q) ? this.q : '',
      lang: this.lang,
      //   category_id: (this.category) ? this.category : '',
      category_id: (this.category != undefined) ? this.category : '',
      attribute_id: (this.attributes.length === 1) ? this.attributes : '',
      //   brand_id: (this.brands.length === 1) ? this.brands : '',
      brand_id: (this.parentBrandId.length > 0) ? this.parentBrandId.join(',') : '',
      in_stock: '',
      page: this.page,
      per_page: this.pageSize,
      is_featured: this.isFeatured,
      latest: this.latest,
      best_selling: this.bestSelling,
      sort_by: this.sortBy,
      store: this.store.iso_code,
      flash_sale_id: this.flash_sale_id,
      // price_range: (this.minPrice >= 0 && this.maxPrice > 0) ? this.minPrice + '-' + this.maxPrice : '',
      price_range: this.price ? this.price : '',
      user_id: this.user.id ? this.user.id : '',
      collection_id: (this.collection > 0) ? this.collection : '',
      is_favourite:this.type ? 1 : '',
      type:this.type ? this.type : '',
    };
    this.params = {
      brand_id: (this.brands.length > 0) ? this.brands.join(',') : '',
      shop_id: (this.shop_id) ? this.shop_id : '',
      attribute_id: (this.attributes.length > 1) ? this.attributes.join(',') : '',
      category_id: (this.categoriesArr != undefined) ? this.categoriesArr.join(',') : '',
    };

    this.configService.postRequest('search', params, this.params)
      .subscribe(response => {
        this.products = [];
        let tmp: Product;

        if (response.status == 200) {

          this.isFilterLoading = false;

          const path = this.route.snapshot.url;

          if (response.data.page_title && path.some(e => e.path === 'category')) {
            this.title = response.data.page_title;
          }
          if(this.fromPriceRange==false){
            this.minPrice=0;
            this.maxPrice=response.data.max_product_price;
            this.maxPriceShow=response.data.max_product_price;
          } 
          response.data.products.map(product => {
            tmp = product;
            var discount = this.currency + ' ' + parseFloat(tmp.final_price).toFixed(2);

            if (tmp.regular_price != tmp.final_price) {
              discount = this.currency + ' ' + parseFloat(tmp.regular_price).toFixed(2) + ' - ' + this.configSettings.roundUp((100 - (parseFloat(tmp.final_price) / parseFloat(tmp.regular_price) * 100)), 2) + "% = <br /><span style='color: red;'>" + this.currency + ' ' + parseFloat(tmp.final_price).toFixed(2) + "</span>";
            }

            tmp.display_price = discount;
            tmp.sizeString = (tmp.sizes.length > 0) ? tmp.sizes.join(' | ') : '';

            this.products.push(product);
          });
          this.totalPages = response.data.total_pages;
          this.brandInfo = response.data.brand
          this.totalProducts = response.data.total_products;

          if (this.filters.length === 0 &&  response.data.filter.length > 0) {
            response.data.filter.map(filter => {
              if ((path.some(e => e.path === 'brand') || path.some(e => e.path === 'brands'))
                && filter.filter_name === 'Brand') {
                this.title = filter.filter_values[0].value;
              }

              this.filterValues = filter.filter_values;

              if (filter.filter_values.length > 1) {
                this.filters.push({
                  name: filter.filter_name,
                  values: this.filterValues
                });
              }
            });

            this.filters.map(filter => {
              filter.values.sort((a, b) => a.value.toLowerCase().localeCompare(b.value.toLowerCase()));
            });
          }

          this.analytics.search(
            '',
            this.q,
            this.products.length
          );
        } else {
          this.toastr.warning(response.message)          
        }

        this.configSettings.toggleLoading(false);
        this.skeletonLoader=false;
        this.scrollToTop()
        this.dataAvailable = true;
        setTimeout(() => { this.footerService.toggleFooter(true) }, 300);
      }, error => { this.configSettings.toggleLoading(false); });
  }

  // to get listing based on filter selections
  doFilter($event, value, filter) {
    //this is to remove the pageNo params from the 
    var href = new URL(document.URL);
    href.searchParams.delete('pageNo')
    history.replaceState('', '', href.toString());
    this.fromFilter=true;
    this.configSettings.toggleLoading(true);
    this.skeletonLoader=true;
    if (this.isFilterLoading) {
      return false;
    }
  
    this.page = 1;
  
    switch (filter) {
      case 'categories':
        this.selectedCategory = value;
        this.toggleArrayItem(this.categoriesArr, value);
        break;
  
      case 'brand':
      case 'ماركة':
        this.toggleArrayItem(this.brands, value);
        break;
  
      default:
        this.toggleArrayItem(this.attributes, value);
        break;
    }
    
      // wip temporarily commented code 
      // this.setValuesToUrl(value,filter)
      // this.setFilterValuesAsActive(this.filters,value,filter) 
    this.doSearch();
    this.isFilterLoading = false;
    return false;
  }

  toggleArrayItem(arr, value) {
    const index = arr.indexOf(value);
    (index > -1) ? arr.splice(index, 1) : arr.push(value);
  }

  setFilterValuesAsActive(arr: Array<any>, id: number, filterName: string) {
    for (let index = 0; index < arr.length; index++) {
      const element = arr[index];
      if (element.name.toLocaleLowerCase().toString() == filterName.toLocaleLowerCase().toString()) {
        element.values.map((e:any) => {
          if (e.id == id) {
            e['isActive'] = true
          }
        })
      }
    }
  }

  urlObject: Record<string, string[]> = {}
  
  // to create urlObject which will have selected filter values on key value pair
  setValuesToUrl(id: string, filterName: string) {

    if (!this.urlObject[filterName]) {
      this.urlObject[filterName] = [];
    }

    if (this.urlObject[filterName].includes(id)) {
      // if urlObject has selected filter id it removes the id from urlObject
      let indexOfId = this.urlObject[filterName].indexOf(id)
      this.urlObject[filterName].splice(indexOfId, 1)
    } else {
      // if urlObject dose not have selected filter id it adds the id from urlObject
      this.urlObject[filterName].push(id);
    }
  }
  

  // clear filter options
  clearFilter(filterClass, filter, filterVal, filterName) {
    this.configSettings.toggleLoading(true);
    this.skeletonLoader=true;
    if (filter === 'brand') {
      this.brands = [];
      let collection = document.getElementsByClassName(filterClass);

      for (let i = 0; i < collection.length; i++) {
        document.getElementById(collection[i].id) as HTMLInputElement;
        let idVal = document.getElementById(
          collection[i].id
        ) as HTMLInputElement;

        idVal.checked = false;
      }
    }
    if (filter === 'categories') {
      this.categoriesArr = []
      let collection = document.getElementsByClassName(filterClass);

      for (let i = 0; i < collection.length; i++) {
        document.getElementById(collection[i].id) as HTMLInputElement;
        let idVal = document.getElementById(
          collection[i].id
        ) as HTMLInputElement;

        idVal.checked = false;
      }


    }
    if (filter !== 'brand' && filter !== 'categories') {
      this.attributes = []
      let collection = document.getElementsByClassName(filterClass);

      for (let i = 0; i < collection.length; i++) {
        document.getElementById(collection[i].id) as HTMLInputElement;
        let idVal = document.getElementById(
          collection[i].id
        ) as HTMLInputElement;

        idVal.checked = false;
      }
    }
    // else {
    //     this.attributes = [];
    //     this.category = null;
    //     this.selectedCategory = null;
    // }

    // this.minPrice = 0;
    // this.maxPrice = 0;
    // this.category = null;
    // this.selectedCategory = null;

    this.doSearch();

    const selector = '.' + filterClass + ' ' + '.filter-input.selected';
    let elements = document.querySelectorAll(selector);
    for (let i = 0; i < elements.length; i++) {
      elements[i].classList.remove('selected');
    }

    // let elements = document.querySelectorAll('.filter-input.selected');
    // for(let i = 0; i < elements.length; i++) {
    //     elements[i].classList.remove('selected');
    // }

    return false;
  }

  // clear price filter
  clearPriceFilter() {
    this.fromPriceRange=false;
    this.minPrice = 0;
    this.maxPrice = null;
    this.price='';
    this.applyBtnEnabled = false;
    this.doSearch();
    return false;
  }

  // to update price filter
  updatePriceFilter($event) {
    if (this.page > 1) {
      this.page = 1;
    }
    this.getProductList();
    return false;
  }

  // go to selected page based on page number
  goToPage($event) {
    this.configSettings.toggleLoading(true);
    this.page = $event;
    this.skeletonLoader=true;
    this.scrollToTop()
    this.doSearch();
    var href = new URL(document.URL);
    href.searchParams.set('pageNo', String(this.page));
    // Replace current querystring with the new one.
    history.replaceState('', '', href.toString());
    return false;
  }

  // to toggle filter display
  toggleFilter() {
    this.fixedFilter = !this.fixedFilter;
    this.isFilterOnService.setFilterActive(this.fixedFilter)
  }

  // get product list based on sort value
  selectedSortIndex: number = 0;
  doSort(selectedValue: any, selectedIndex: number) {
    this.sortBy = selectedValue.value;
    this.selectedSortOption = selectedValue.label
    this.page = 1;
    this.toggleSort = false;
    this.selectedSortIndex = selectedIndex;
    this.getProductList();
  }

  // to toggle apply button based on max and min price in filter
  applyButton() {
    if (this.maxPrice > 0 && this.minPrice >= 0) {
      this.applyBtnEnabled = true;
    }
    else {
      this.applyBtnEnabled = false;
    }
  }

  // scroll to top of page
  scrollToTop() {
    this.scroller.scrollToPosition([0, 0]);
  }

  // to toggle header and navbar
  activateHeader() {
    this.navbarService.isEnableHeaderBottomAction(true);
    this.configSettings.setNavBarStatus(true)
    this.headerToggleService.setNavItems(true)
  }

  // to scroll to top of page
  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  filterToggle(){
    this.filterShow = !this.filterShow
  }


  tooltipValue: number | null = null;

  updateTooltip(event: any) {
    const rangeValue = event.target.value;
    this.tooltipValue = +rangeValue;
    this.maxPrice = this.tooltipValue
    if (this.page > 1) {
      this.page = 1;
    }
    setTimeout(() => {
      this.getProductList();
    });
    return false;
  }

  updatePrice(){
    this.fromPriceRange=true;
    this.fromFilter=true;
    this.price = this.minPrice+'-'+this.maxPrice.toFixed(2);
    this.getProductList();
  }

  // used to close sort drop down when clicked outside 
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: Event) {
    // Check if the clicked element is not inside the dropdown
    const target = event.target as HTMLElement;
    const dropdown = document.querySelector('.sort-dropdown');

    if (dropdown && !dropdown.contains(target)) {
      if (this.toggleSort == true) {
        this.toggleSort = false;
      }
    }
  }

  //this method is called after the component is being destroyed..
  ngOnDestroy() {
    this.changeMeta.updateMetaTags('The Wishlist | Discover fashion online!', "Everything on your Wish List!' Discover and shop a variety of pieces ranging from clothes, bags, shoes, to accessories and more.");
    this.skeletonLoader = true;
    this.fromPriceRange = false;
    this.fromFilter=false;
  }

  // brand

  toggleBrandAsFavorite(brandId : number) {
    const getParams = {

    }
    const postParams = {
      user_id : this.user.id,
      brand_id : brandId
    }
    if (brandId == null) {
      this.toastr.warning('Invalid Brand ID')
      return
    }
    this.wishlistService.toggleBrandAsFavorite(getParams, postParams).subscribe(response => {
        if (response.status == 200) {
          this.toastr.success(response.message)
        } else {
          this.toastr.error(response.message)
        }
    })
  }





}
