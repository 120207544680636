<ng-container *ngIf="dataAvailable">
    <div class="my_account_data">
        <div class="container-fluid">
            <section class="user-account main-profile" id="my-profile" @listStagger>
                <div class="account_heading pt-3 pb-5">
                    <ul class="d-flex flex-wrap align-items-center justify-content-center w-100 listing-page-routing">
                      <li class="routing-list">
                        <a [routerLink]="'/my-account'">
                          {{ "My account" | translate }}
                        </a>
                      </li>
                      <li class="routing-list">{{ "My Profile" | translate }}</li>
                    </ul>
                  </div>
                <section>
                    <div class="d-flex w-100 justify-content-center">
                        <div class="user-left-menu d-none d-lg-block w-25">
                            <app-user-account-menu [page]="1"></app-user-account-menu>
                        </div>
                        <div class="white-background user-profile-content w-100 w-lg-75">
                            <div class="breadcrumb_heading">
                                <h3> <a [routerLink]="'/my-account'"> <img src="/assets/icons/right-arrow.svg" alt="arrow"></a> {{'My Profile' | translate}}</h3>
                              </div>
                            <!-- <p class="required border-bottom-2">{{'My Profile' | translate}}</p> -->
                            <!-- <div [hidden]="!submitted">
                                <p class="alert alert-success"
                                    [ngClass]="[hasError ? 'alert alert-danger' : 'alert alert-success']">
                                    {{message}}
                                </p>
                            </div> -->
                            <form #updateProfileForm="ngForm" (ngSubmit)="onSubmit(updateProfileForm)" *ngIf="user">
                                <div class="update-profile-box">
                                    <div class="d-grid my-profile-box">
                                            <div class="form-group required">
                                                <label for="userInputName">{{'First Name' |
                                                    translate}}</label>
                                                <input type="text" class="form-control text-input"
                                                [(ngModel)]="user.first_name" name="userName" #spy
                                                id="userInputName" aria-describedby="userNameHelp"
                                                #userNameParam="ngModel" required>
                                                <small id="userNameHelp" class="form-text form-validator_error"
                                                    *ngIf="userFirstNameParam.touched && !userFirstNameParam.valid || user.first_name==''">
                                                    {{'Please enter first name' | translate}}
                                                </small>
                                            </div>

                                            <div class="form-group required">
                                                <label for="userInputName">{{'Last Name' |
                                                    translate}}</label>
                                                <input type="text" class="form-control text-input"
                                                    [(ngModel)]="user.last_name" name="userLastName" #spy
                                                    id="userLastName" aria-describedby="userNameHelp"
                                                    #userLastNameParam="ngModel" required>
                                                <small id="userNameHelp" class="form-text form-validator_error"
                                                    *ngIf="userLastNameParam.touched && !userLastNameParam.valid">
                                                    {{'Please enter  last name' | translate}}
                                                </small>
                                            </div>

                                            <div class="form-group required">
                                                <label for="inputEmail">{{'Email Address' |
                                                    translate}}</label>
                                                <input type="email" class="form-control text-input"
                                                    [(ngModel)]="user.email" name="email" #spy id="inputEmail"
                                                    aria-describedby="emailHelp" #nameEmail="ngModel" required email
                                                    readonly>
                                                <small id="emailHelp" class="form-text form-validator_error"
                                                    *ngIf="nameEmail.touched && !nameEmail.valid || user.email==''">
                                                    {{'Please enter your email address' | translate}}
                                                </small>
                                            </div>


                                            

                                            <!-- </div> -->
                                            <!-- <div class=" field-user-dob"> -->
                                            <div class="form-group required">
                                                <label for="user-dob">{{'Birthday' | translate}}</label>
                                                <!-- <input type="text"> -->
                                                <!-- <label for="birthdayDay">Day:</label> -->
                                                <div class="flex">
                                                <div class="day position-relative">
                                                    <select id="birthdayDay" class="form-control" [(ngModel)]="day" name="birthdayDay" aria-placeholder="Day" class="form-control text-input">
                                                        <!-- Options for days (1 to 31) -->
                                                        <option value="" disabled class="text-input">{{ 'Day' |translate}}</option>
                                                        <option class="text-input" *ngFor="let dayOption of days" [value]="dayOption" [selected]="day === dayOption">
                                                          {{ dayOption }}
                                                        </option>
                                                      </select>
                                                      <span class="day-arrow">
                                                <img src="/assets/images/arrow-left-5-svgrepo-com.svg" alt="" [value]="dayOption">  
                                            </span>
                                                </div>
                                                <!-- <label for="birthdayMonth">Month:</label> -->
                                               
                                                <div class="day position-relative">
                                                <select id="birthdayMonth" class="form-control"  [(ngModel)]="month" name="birthdayMonth"class="form-control text-input ">
                                                  <!-- Options for months (1 to 12) -->
                                                  <option value="" selected>{{ 'Month'|translate}}</option>
                                                  <!-- Options for months name -->
                                                  <option *ngFor="let monthOption of months" [value]="monthOption">{{ monthsArr[monthOption - 1] }}</option>
                                                </select>
                                                <span class="day-arrow">
                                                <img src="/assets/images/arrow-left-5-svgrepo-com.svg" alt="" [value]="monthOption">  
                                            </span>
                                                </div>
                                                {{yearOption | json}}
                                                <div class="day position-relative">
                                                    <select id="birthdayYear" class="form-control"   [(ngModel)]="year" name="birthdayYear"class="form-control text-input ">
                                                        <!-- Options for months (1 to 12) -->
                                                        <option value="" selected>{{ 'Year'|translate}}</option>
                                                        <option *ngFor="let yearOption of years" [value]="yearOption">{{ yearOption }}</option>
                                                      </select>
                                                      <span class="day-arrow">
                                                      <img src="/assets/images/arrow-left-5-svgrepo-com.svg" alt="" class=""[value]="yearOption">
                                                    </span>
                                                      <!-- <p>{{generateFullDate()}}</p> -->
                                                </div>
                                                </div>
                                                <!-- <input matInput name="dob" class="form-control text-input"
                                                    [(ngModel)]="user.dob" [matDatepicker]="user_dob" id="user_dob"
                                                    [max]="today">
                                                <mat-datepicker-toggle matSuffix [for]="user_dob"
                                                    [ngClass]="(lang == 'ar')?'invert':''"></mat-datepicker-toggle>
                                                <mat-datepicker #user_dob></mat-datepicker> -->
                                            </div>
                                    
                                    <!-- <button class="btn btn-submit btn-black button-large btn-sm-block position-relative"
                                        [disabled]="!updateProfileForm.form.valid">
                                        {{'UPDATE PROFILE' | translate}}
                                        <i class="fas fa-lock icon btn-lock-icon"></i>
                                    </button> -->
                                    <!-- <div [hidden]="!submitted">
                                        <p class="alert alert-success" [ngClass]="[hasError ? 'alert alert-danger' : 'alert alert-success']">
                                            {{message}}
                                        </p>
                                    </div> -->

                                        <div class="form-group required">
                                            <label for="currentInputPassword" class="">{{'Current Password' | translate}}</label>
                                            <!-- <span>
                                                <img (click)="toggleLoginPasswordVisibility()" *ngIf="!isLoginPasswordVisible" src="/assets/icons/password.svg" alt="Password">
                                                <img (click)="toggleLoginPasswordVisibility()" *ngIf="isLoginPasswordVisible" src="/assets/icons/password-visible.svg" alt="Password">
                                            </span> -->
                                            <div class="input-with-icon">
                                            <input [type]="isLoginPasswordVisible ? 'text' : 'password'" class="form-control text-input" [(ngModel)]="currentPassword" name="currentPassword" #currentPasswordParam="ngModel" id="currentInputPassword" required>
                                            <span class="eyes-loc">
                                                <img src="/assets/icons/password.svg" alt=""  (click)="togglePasswordVisibility()" *ngIf="!isLoginPasswordVisible">
                                                <img src="/assets/icons/password-visible.svg" alt=""  (click)="togglePasswordVisibility()"  *ngIf="isLoginPasswordVisible">
                                            </span>
                                            </div>
                                            
                                            <small id="currentPasswordHelp" class="form-text form-validator_error" *ngIf="currentPasswordParam.touched && !currentPasswordParam.valid">
                                                {{'Please enter your current password' | translate}}
                                            </small>
                                        </div>
                                        <div class="form-group required input-with-icon">
                                         
                                            <label for="newInputPassword">{{'New Password' | translate}}</label>
                                            <div class="input-with-icon">
                                                <input [type]="isLoginPasswordVisible1 ? 'text' : 'password'" class="form-control text-input" [(ngModel)]="newPassword" name="newPassword" #newPasswordParam="ngModel" id="newInputPassword" required>
                                            <!-- <img src="/assets/images/view.svg" alt=""  (click)="togglePasswordVisibility()"> -->

                                                <span class="eyes-loc">
                                                    <img src="/assets/icons/password.svg" alt=""   (click)="togglePasswordVisibility1()" *ngIf="!isLoginPasswordVisible1">
                                                    <img src="/assets/icons/password-visible.svg" alt=""    (click)="togglePasswordVisibility1()" *ngIf="isLoginPasswordVisible1">
                                                </span>
                                            </div>
                                            <small id="newPasswordHelp" class="form-text form-validator_error" *ngIf="newPasswordParam.touched && !newPasswordParam.valid">
                                                {{'Please enter new password' | translate}}
                                            </small>
                                        </div>
                                        
                                        <div class="form-group required input-with-icon">
                                            <label for="confirmInputPassword">{{'Confirm New Password' | translate}}</label>
                                            <div class="input-with-icon">
                                                <input [type]="isLoginPasswordVisible2 ? 'text' : 'password'" class="form-control text-input" [(ngModel)]="confirmPassword" name="confirmPassword" #confirmPasswordParam="ngModel" id="confirmInputPassword" appConfirmPasswordValidator="newPassword" required>
                                                <span class="eyes-loc">
                                                    <img src="/assets/icons/password.svg" alt=""  (click)="togglePasswordVisibility2()" *ngIf="!isLoginPasswordVisible2">
                                                    <img src="/assets/icons/password-visible.svg" alt=""   (click)="togglePasswordVisibility2()"  *ngIf="isLoginPasswordVisible2">
                                                </span>
                                            </div>
                                            <!-- <img src="/assets/images/view.svg" alt=""   (click)="togglePasswordVisibility()"> -->
                                            <small id="confirmPasswordHelp" class="form-text form-validator_error" *ngIf="confirmPasswordParam.touched && !confirmPasswordParam.valid">
                                                {{'Confirm password does not match new password' | translate}}
                                            </small>
                                            <small id="confirmPasswordHelp" class="form-text form-validator_error" *ngIf="confirmPasswordParam==''">
                                              {{'Confirm password cannot be blank' | translate}}
                                          </small>
                                        </div>

                                        
                                        <div class="form-group required">
                                            <label for="userInputName">{{'Referal Code' |
                                                translate}}</label>
                                            <input type="text" class="form-control text-input"
                                                [(ngModel)]="user.code" name="userFirstName" #spy
                                                id="userFirstName" aria-describedby="userNameHelp"
                                                #userFirstNameParam="ngModel" required>

                                            <!-- <small id="userNameHelp" class="form-text form-validator_error"
                                                *ngIf="userFirstNameParam.touched && !userFirstNameParam.valid">
                                                {{'Please enter first name' | translate}}
                                            </small> -->
                                        </div>

                                    
                                    </div>
                                    <div class="flex padding-top">
                                        <div class="flex">
                                            <label class="switch">
                                                <input type="checkbox" checked>
                                                <span class="slider round"></span>
                                                </label>
                                            </div>

                                    <div class="flex align-items-center subscribe">
                                        <label *ngIf="lang == 'en'" for="userInputName" class="sub">Subscribe here to receive newsletter, latest updates & offers for The Wishlist.
                                        </label>
                                        <label *ngIf="lang == 'ar'" for="userInputName" class="sub">سجل للحصول على احدث العروض
                                        </label>
                                        </div>
                                      </div>
                                      <div class="edit_data_footer text-center">
                                        <button type="button"  (click)="onSubmit(updateProfileForm)" >{{'SAVE'|translate}}</button>
                                      </div>
                                </div>
                            </form>
                            
                        </div>
                    </div>
                </section>
            </section>
        </div>
    </div>
</ng-container>