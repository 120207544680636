import { Component, OnInit } from '@angular/core';
import {Store} from "../models/store";
import {User} from "../models/user";
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {WalletTransaction} from "../models/wallet-transaction";
import {UserService} from "../config/user.service";
import {ConfigSettings} from "../config/config.settings";
import {ConfigService} from "../config/config.service";
import {FooterService} from "../config/footer.service";
import {AuthenticationService} from "../config/authentication.service";
import {ActivatedRoute, Router} from "@angular/router";
import {first} from "rxjs/operators";
import {PaymentResponse} from "../models/payment-response";
import {AnalyticsService} from '../services/analytics.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-refer-a-friend',
  templateUrl: './refer-a-friend.component.html',
  styleUrls: ['./refer-a-friend.component.scss']
})
export class ReferAFriendComponent implements OnInit {

  lang: string;
  didLoad = false;
  store: Store;
  currency = '';
  dataAvailable = false;
  user: User;
  addCreditForm: UntypedFormGroup;
  redeemVoucherForm: UntypedFormGroup;
  walletTransactions: WalletTransaction[] = [];
  submitted = false;
  hasError = false;
  message: string;
  wallet_amount: string;
  paymentRespose: PaymentResponse;
  facebookLink = 'https://m.facebook.com/The-Wish-List-103188571038295/';
  instagramLink = 'https://www.instagram.com/shop.twl/?igshid=1lxvfpr13fm2x';
  referralCode:any;
  paymentModeList = [
    {label: 'Knet', value: 'K'},
    {label: 'Visa/Mastercard', value: 'CC'}
  ];
  isMobile: any = false;
  skeltonTheme:any;
  skeletonLoader=true;
  constructor(
      private userService: UserService,
      private configSettings: ConfigSettings,
      private configService: ConfigService,
      protected footerService: FooterService,
      private authenticationService: AuthenticationService,
      private router: Router,
      private route: ActivatedRoute,      
      private analytics: AnalyticsService,
      private toastr : ToastrService
  ) { }

  ngOnInit() {

    this.configSettings.toggleLoading(true);
    this.lang = this.configSettings.getLang();
    this.referralCode = this.configSettings.getisReferralCode();
    
    this.configSettings.getStoreObject().then((result) => {
      this.store = <Store>result;
      this.currency = result['currency_' + this.lang];
      this.userService.getUserSession().then(user => {
        if (user !== false) {
          this.user = <User>user;
          this.route.queryParams.subscribe(params => {
            this.paymentRespose = <PaymentResponse>params;
            if (this.paymentRespose.Result === 'CAPTURED') {
              this.getWalletTransactions();
            }
          });
          this.dataAvailable = true;
          this.configSettings.toggleLoading(false);
          this.getWalletTransactions();
        }
      });
    });


    this.addCreditForm = new UntypedFormGroup({
      amount: new UntypedFormControl('', [
        Validators.required
      ]),
      payMode: new UntypedFormControl('', [Validators.required]),
    });

    this.redeemVoucherForm = new UntypedFormGroup({
      voucherCode: new UntypedFormControl('', [
        Validators.required
      ])
    });

    setTimeout(() => {
      this.footerService.toggleFooter(true);
    }, 300);

    // this.analytics.pageView(
    //   String("Refer a Friend")
    // );
    this.skeltonTheme = this.setThemeLogic();
  }
  setThemeLogic() {
    let mobTheme = {
      'border-radius': '5px',
      'height': '100px',
      'animation-duration': '2s',
      'background-color': '#F1F1F1'
    };
    let deskTheme = {
      'border-radius': '5px',
      'height': '500px',
      'animation-duration': '2s',
      'background-color': '#F1F1F1'
    };
    if (this.isMobile) {
      return mobTheme;
    } else {
      return deskTheme;
    }
  }
   copyCode() {
    try {
       navigator.clipboard.writeText(this.referralCode);
  
      const successMessage = this.lang == 'en' ? 'Copied to Clipboard' : 'نسخ إلى الحافظة';
      this.toastr.success(successMessage);
  
      console.log('Content copied to clipboard');
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  }
  
  

  onAddCreditSubmit(event: Event) {
    if (this.addCreditForm.valid) {
      try {
        const _form = this.addCreditForm.value;
        if (_form.amount <= 0) {
          return false;
        }
        this.configSettings.toggleLoading(true);
        const confirmURL = window.location.origin + this.router.createUrlTree(['/account/wallet-credit']);
        const params = {
          user_id: this.user.id,
          amount: _form.amount,
          pay_mode: _form.payMode,
          redirect_url: confirmURL,
        };
        this.authenticationService.addWalletCredit(params)
            .pipe(first())
            .subscribe(
                response => {
                  if (response.data.payment_url) {
                    window.location.href = response.data.payment_url;
                  }
                  this.configSettings.toggleLoading(false);
                }, error => {
                  this.configSettings.toggleLoading(false);
                }
            );
      } catch (e) {
        console.log(e);
        this.configSettings.toggleLoading(false);
      }
    }
  }

  onRedeemVoucherSubmit(event: Event) {
    if (this.redeemVoucherForm.valid) {
      this.configSettings.toggleLoading(true);
      try {
        const _form = this.redeemVoucherForm.value;
        const postParam = {
          user_id: this.user.id,
          code: _form.voucherCode
          // amount: '',
          // pay_mode: ''
        };
        this.authenticationService.redeemVoucher({lang: this.lang, store: this.store.iso_code}, postParam)
            .pipe(first())
            .subscribe(
                response => {
                  this.submitted = true;
                  if (response.status === 200) {
                    this.redeemVoucherForm.reset();
                    this.hasError = false;
                    this.user.wallet_amount = response.data.total_wallet_amount;
                    this.userService.updateWalletAmount(this.user.id, response.data.total_wallet_amount).then((updateresult) => {
                      if (updateresult) {
                        this.message = (this.lang === 'en') ? 'Voucher code successfully redeemed and added to your wallet.' : 'تم استرداد رمز القسيمة بنجاح وإضافته إلى محفظتك';
                        this.configSettings.toggleLoading(false);
                      }
                    });
                  } else if (response.status === 404) {
                    this.hasError = true;
                    this.message = (this.lang === 'en') ? 'Invalid voucher code.' : 'رمز قسيمة غير صالح.';
                    this.configSettings.toggleLoading(false);
                  } else if (response.status === 201) {
                    this.hasError = true;
                    this.message = (this.lang === 'en') ? 'Code has already been redeemed.' : 'تم بالفعل استرداد الرمز.';
                    this.configSettings.toggleLoading(false);
                  } else if (response.status === 412) {
                    this.hasError = true;
                    this.message = (this.lang === 'en') ? 'Code has already been redeemed.' : 'تم بالفعل استرداد الرمز.';
                    this.configSettings.toggleLoading(false);
                  } else {
                    this.configSettings.toggleLoading(false);
                  }

                  setTimeout(() => {
                    this.submitted = false;
                    this.hasError = false;
                    this.message = '';
                  }, 5000);

                }, error => {
                  this.configSettings.toggleLoading(false);
                }
            );
      } catch (e) {
        console.log(e);
        this.configSettings.toggleLoading(false);
      }
    }
  }

  getWalletTransactions() {
    try {
      this.configSettings.toggleLoading(true);
      const params = {
        'user_id': this.user.id,
        'lang': this.lang,
        'store': this.store.iso_code
      };

      this.configService.readRequest('wallet-transactions', params).subscribe(response => {
        this.walletTransactions = [];
        const _responseData = response.body.data;
        if (_responseData.transaction_list) {
          _responseData.transaction_list.map(transaction => {
            this.walletTransactions.push(transaction);
          });
        }
        if (_responseData.total_wallet_amount) {
          this.user.wallet_amount = _responseData.total_wallet_amount;
          /* Updating user wallet in local storage */
          this.userService.updateWalletAmount(this.user.id, this.user.wallet_amount).then((updateresult) => {
          });
        }
        this.dataAvailable = true;
        this.skeletonLoader=false;
        this.configSettings.toggleLoading(false);
      }, error => {
        this.configSettings.toggleLoading(false);
      });
    } catch (e) {
      console.error(e);
      this.configSettings.toggleLoading(false);
    }
  }

  formatTransactionDate(str: any) {
    const date = new Date(str);
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();
    return day + '/' + month + '/' + year;
  }

  formatTransactionTime(str: any) {
    const date = new Date(str);
    return this.configSettings.formatAMPM(date);
  }

}
