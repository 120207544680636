import { AfterViewInit, ChangeDetectorRef, Component, DoCheck, HostListener, OnInit,ViewChild } from '@angular/core';
import { ConfigService } from '../config/config.service';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { Product } from '../models/product';
import { CryptService } from "../config/crypt.service";
import { CartService } from "../config/cart.service";
import { LocalStorage } from "@ngx-pwa/local-storage";
import { ConfigSettings } from "../config/config.settings";
import { MatDialog } from "@angular/material/dialog";
import { Store } from "../models/store";
import { ConfigurableOptions } from "../models/configurable-options";
import { DialogComponent } from "../dialog/dialog.component";
import { UserService } from "../config/user.service";
import { WishlistService } from '../config/wishlist.service';
import { listStagger, fade } from '../animations';
import { FooterService } from '../config/footer.service';
import { reject } from 'q';
import { SupportDetails } from '../models/support-details';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { CreateVipService } from "../services/create-vip.service";
import { ToastrService } from "ngx-toastr";
import { AnalyticsService } from '../services/analytics.service';
import { NavbarService } from '../config/navbar.service';
import { HandleCartService } from '../services/handle-cart.service';
import { AddToCartModalService } from '../config/add-to-cart-modal.service';
import { Title } from '@angular/platform-browser';
import { ChangeMetaService } from '../services/change-meta-service.service';
import { HeaderToggleService } from '../services/header-toggle.service';
import { UpdateWishlistCountService } from '../services/update-wishlist-count.service';
import { SharePopupComponent } from '../share-popup/share-popup.component';

declare var branch: any;
declare var $: any;

@Component({
    selector: 'app-product-detail',
    templateUrl: './product-detail.component.html',
    styleUrls: [ './product-detail.component.scss'],
    animations: [listStagger, fade]
})
export class ProductDetailComponent implements OnInit, AfterViewInit {


    product: Product;
    id: number;
    breadcumb: any[] = [];
    selectedAttribute: any[] = [];
    selectedOption: Number[] = [];
    selectedFilters: ConfigurableOptions[] = [];
    entityId: string;
    quantity: number;
    quantityArr: any[] = [];
    lang: any;
    langForSlider = this.configSettings.getLang()
    store: Store;
    currency: string;
    userId: string;
    dataAvailable = false;
    page: any = 'product-details'
    support_email = '';
    support_phone = '';
    english: boolean = true;
    isVip: any = this.configSettings.getisVip();
    sizeGuide_txt: string = "Size Guide";

    branchIoLink: string;
    showAttribute = false;
    notifyMeForm: UntypedFormGroup;
    notifyMeSubmitted = false;
    emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
    phonePatters = "^[0-9]+$";
    isNotifyMeLoading = true;
    countryCode = '+965';
    addToCartText: any = 'ADD TO BAG';
    isMobile: any = false;
    backPressed: any = false;
    skeltonTheme:any;
    skeltonThemeSide:any;
    skeletonLoader:boolean=true;

    currentImageIndex: number = 0
    
    selectedColorArr: Array<number> = []
    selectedSizeArr: Array<number> = []
    selectedAgeArr: Array<number> = []
    selectedColorText: string = ''

    // carousel configurations
    slideConfig: any = {
        variableWidth: false,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        rtl: this.langForSlider == 'en' ? false : true,
        dots: false,
        prevArrow: '<span  class="slick-arrow slick-prev custom-container-carousal-img product-slider-left"><div data-testid="left-arrow" (click)="setImageVal(i) class="css-qpfnax"><svg width="16px" height="70px" viewBox="0 0 16 70"><g transform="translate(1 1)" stroke="#444141" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="square"><path transform="matrix(-1 0 0 1 14 0)" d="M0.5 0.5L13.5 33.5"></path><path transform="rotate(180 7 50.5)" d="M0.5 33.5L13.5 67.5"></path></g></svg></div></span>',
        nextArrow: '<span class="slick-arrow slick-next custom-container-carousal-img product-slider-right"><div data-testid="right-arrow" (click)="setImageVal(i) class="css-kzjqza"><svg width="16px" height="70px" viewBox="0 0 16 70"><g transform="translate(1 1)" stroke="#444141" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="square"><path d="M0.5 0.5L13.5 33.5"></path><path transform="matrix(1 0 0 -1 0 100)" d="M0.5 33.5L13.5 66.5"></path></g></svg></div></span>',
        asNavFor: '.gallery-thumb',
        // responsive: [
        //     {
        //         breakpoint: 912,
        //         settings: {
        //           dots:true,
        //         }
        //       },
        //   ]
    };
    // product main slider (thumbnail)
    thumbSlideConfig: any = {
        variableWidth: false,
        infinite: false,
        speed: 300,
        slidesToShow: 6,
        slidesToScroll: 6,
        rtl: this.langForSlider == 'en' ? false : true,
        dots: false,
        arrows: false,
        asNavFor: '.gallery-main',
        horizontal:true,
        focusOnSelect: true,
    };

    // you may also like - big device
    slideConfigYouMAyLike: any = {
        variableWidth: false,
        infinite: true,
        speed: 300,
        slidesToShow: 4,
        slidesToScroll: 1,
        rtl: this.langForSlider == 'en' ? false : true,
        dots: false,
        prevArrow: '<span class="slick-prev suggestion" style=""><svg width="16px" height="70px" viewBox="0 0 16 70"><g transform="translate(1 1)" stroke="#444141" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="square"><path transform="matrix(-1 0 0 1 14 0)" d="M0.5 0.5L13.5 33.5"></path><path transform="rotate(180 7 50.5)" d="M0.5 33.5L13.5 67.5"></path></g></svg></span>',
        nextArrow: '<span class="slick-next suggestion" style=""><svg width="16px" height="70px" viewBox="0 0 16 70"><g transform="translate(1 1)" stroke="#444141" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="square"><path d="M0.5 0.5L13.5 33.5"></path><path transform="matrix(1 0 0 -1 0 100)" d="M0.5 33.5L13.5 66.5"></path></g></svg></span>',
        asNavFor: '.gallery-thumb',
        responsive: [
            {
                breakpoint: 1025,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 1,
                  arrows: true,
                }
              },
            {
                breakpoint: 913,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1,
                  arrows: true,
                }
              },
            {
                breakpoint: 767,
                settings: {
                  slidesToShow: 2,
                 
                }
            }
            
          ]
    };

    // you may also like slider - small device
    // slideConfigYouMAyLikeM: any = {
    //     variableWidth: false,
    //     infinite: true,
    //     speed: 300,
    //     slidesToShow: 2.1,
    //     slidesToScroll: 1,
    //     rtl: this.langForSlider == 'en' ? false : true,
    //     dots: true,
    //     prevArrow: '<span class="slick-prev" style=""><span class="slick-prev-icon"></span></span>',
    //     nextArrow: '<span class="slick-next" style=""><span class="slick-next-icon"></span></span>',
    //     asNavFor: '.gallery-thumb'
    // };

    

    // match with section - big device
    slideConfigMatchWith: any = {
        variableWidth: false,
        infinite: true,
        speed: 300,
        slidesToShow: 4,
        slidesToScroll: 1,
        rtl: this.langForSlider == 'en' ? false : true,
        dots: true,
        prevArrow: '<span class="slick-prev"  style=""><svg width="16px" height="70px" viewBox="0 0 16 70"><g transform="translate(1 1)" stroke="#444141" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="square"><path transform="matrix(-1 0 0 1 14 0)" d="M0.5 0.5L13.5 33.5"></path><path transform="rotate(180 7 50.5)" d="M0.5 33.5L13.5 67.5"></path></g></svg></span>',
        nextArrow: '<span class="slick-next" style=""><svg width="16px" height="70px" viewBox="0 0 16 70"><g transform="translate(1 1)" stroke="#444141" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="square"><path d="M0.5 0.5L13.5 33.5"></path><path transform="matrix(1 0 0 -1 0 100)" d="M0.5 33.5L13.5 66.5"></path></g></svg></span>',
        asNavFor: '.gallery-thumb'
    };

    // match with section - small device
    // slideConfigMatchWithM: any = {
    //     variableWidth: false,
    //     infinite: true,
    //     speed: 300,
    //     slidesToShow: 2.1,
    //     slidesToScroll: 1,
    //     rtl: this.langForSlider == 'en' ? false : true,
    //     dots: true,
    //     prevArrow: '<span class="" style=""><span class="slick-prev-icon"></span></span>',
    //     nextArrow: '<span class="" style=""><span class="slick-next-icon"></span></span>',
    //     asNavFor: '.gallery-thumb'
    // };
  

    constructor(
        private configService: ConfigService,
        public configSettings: ConfigSettings,
        private route: ActivatedRoute,
        private cryptService: CryptService,
        private cartService: CartService,
        public createVipService: CreateVipService,
        protected localStorage: LocalStorage,
        private dialog: MatDialog,
        private userService: UserService,
        private wishlistService: WishlistService,
        protected footerService: FooterService,
        private fb: UntypedFormBuilder,
        private addToCartModalService: AddToCartModalService,
        private handleCartService: HandleCartService,
        private toastr: ToastrService,
        private router: Router,
        private navbarService: NavbarService,
        private analytics: AnalyticsService,
        private titleService: Title,
        private changeMeta: ChangeMetaService,
        private headerToggleService: HeaderToggleService,
        private cdr : ChangeDetectorRef,
        private updateWishlistCountService: UpdateWishlistCountService
    ) {
        this.quantity = 1;
        this.router.events.subscribe((event: NavigationStart) => {
            if (event.navigationTrigger === 'popstate') {
                this.backPressed = true;
            }
        });
    }

   
    ngOnInit() {
        this.isMobile = window.innerWidth < 600;
        this.navbarService.toggleMainNavigation(true);
        this.navbarService.isEnableHeaderBottomAction(true);
        this.navbarService.isEnableHeaderBottomMobileAction(false);
        this.footerService.toggleFooter(true);

        this.route.params.subscribe((params) => {
            this.id = params.id;
            this.skeletonLoader=true;
            this.lang = this.configSettings.getLang();
            if (this.lang != 'en') {
                this.english = false;
                this.sizeGuide_txt = "دليل المقاسات";
            }
            this.breadcumb = this.route.snapshot.url;
            this.configSettings.toggleLoading(true);

            // notify me form group
            this.notifyMeForm = this.fb.group({
                user_id: [''],
                product_id: [''],
                name: ['', Validators.required],
                email: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
                phone: ['', [Validators.required, Validators.minLength(8), Validators.pattern(this.phonePatters)]]
            });

            // get user data
            this.userService.getUserSession().then(user => {
                if (user !== false) {
                    this.userId = user.id;
                    this.notifyMeForm.patchValue({
                        name: user.first_name + ' ' + user.last_name,
                        email: user.email,
                        mobile: user.phone
                    });
                }

                /* getting store from config*/
                this.configSettings.getStoreObject().then((result) => {
                    this.store = <Store>result;
                    this.currency = result['currency_' + this.lang];
                    this.getProductDetails();
                });
            }, error => { this.configSettings.toggleLoading(false); });

            // to get support data from local storage
            this.localStorage.getItem<any>('support_details').subscribe((result) => {
                if (result) {
                    const SupportDetails = <SupportDetails>result;
                    this.support_email = SupportDetails.support_email;
                    this.support_phone = SupportDetails.support_phone;
                }
            });

            // to get phone code
            this.localStorage.getItem<any>('store_object').subscribe((result) => {
                if (result) {
                    this.countryCode = '+' + (result as any).phonecode;
                }
            });
        });

        // to handel routing to vip register
        this.route.queryParams.subscribe(params => {
            if (params['_branch_match_id'] && params['utm_source'] == 'The Wish List' && params['utm_campaign'] == 'New Product') {
                this.vipRegister();
            }
        });

        this.skeltonTheme = this.setThemeLogic();
        this.skeltonThemeSide = this.setThemeLogicSide();

    }
    setTheme(heightMobile, heightDesktop) {
        const commonTheme = {
            'border-radius': '5px',
            'animation-duration': '2s'
        };
        const specificTheme = {
            // 'height': this.isMobile ? heightMobile : heightDesktop
        };
        return {...commonTheme, ...specificTheme};
    }
    
    setThemeLogic() {
        return this.setTheme('400px', '800px');
    }
    
    setThemeLogicSide() {
        return this.setTheme('40px', '40px');
    }

    ngAfterViewInit() {
        this.activateHeader();       
    }

    productSizeObj: any
    productColorObj: any
    productAgeObj: any
    // to get product details
    getProductDetails() {
        if (!this.backPressed) {
            this.scrollToTop();
        }
        const params = {
            product_id: this.id,
            lang: this.lang,
            user_id: this.userId,
            store: this.store.iso_code
        };
        this.configService.readRequest('product-details', params)
            .subscribe(response => {
                if (response.status === 200) {
                    this.product = response.body.data;
                    if (this.product) {
                        // console.log(this.product.configurable_option[0], this.product.configurable_option[0].attributes[0],'this.product.configurable_option[0], this.product.configurable_option[0].attributes[0]');
                        
                        this.onOptionSelectV2(this.product.configurable_option[0], this.product.configurable_option[0].attributes[0], 0, null)
                    }
                    // Adding To Event
                    try {
                        this.analytics.viewContent(
                            this.product.id,
                            this.product.name,
                            this.product.marketing_category + ' - ' + this.product.marketing_subcategory,
                            this.product.final_price,
                            this.product.regular_price,
                            this.product.brand_name,
                        );
                    } catch (e) {
                        console.info('error in view content analytics', e);
                    }


                    const innerWidth = window.innerWidth;

                    let pathName = this.product.name;
                    if (pathName.toString().length >= 32 && (innerWidth < 600)) {
                        pathName = pathName.substr(0, 32) + '...';
                    }
                    this.breadcumb[this.breadcumb.length - 1] = { path: pathName }

                    this.product.parent_id = this.product.id;
                    this.entityId = (this.product.configurable_option.length === 0) ? this.product.id : null;

                    var discount = parseFloat(this.product.final_price).toFixed(2);

                    if (this.product.regular_price != this.product.final_price) {
                        discount = this.currency + ' ' + parseFloat(this.product.regular_price).toFixed(2) + ' - ' + this.configSettings.roundUp((100 - (parseFloat(this.product.final_price) / parseFloat(this.product.regular_price) * 100)), 2) + '% = <span style="color: red;">' + this.currency + ' ' + parseFloat(this.product.final_price).toFixed(2) + '</span>';
                    }

                    this.product.display_price = discount.toString();

                    /*
                    * Auto selecting dropdown if there is only one option
                    * */
                    // if (this.product.configurable_option.length > 0) {
                    //     let singleOption = true;
                    //     for (let i = 0; i < this.product.configurable_option.length; i++) {
                    //         if (this.product.configurable_option[i].attributes.length === 1) {
                    //             this.selectedAttribute[i] = this.product.configurable_option[i].attributes[0].option_id;
                    //         } else {
                    //             singleOption = false;
                    //             break;
                    //         }
                    //     }
                    //     if (singleOption) {
                    //         this.entityId = this.product.id;
                    //     } else {
                    //         this.selectedAttribute = [];
                    //     }
                    // }

                    // NEW CODE

                    // get configurable product options
                    // if (this.product.configurable_option.length) {
                    //     let i = 0;
                    //     for (const configurable_option of this.product.configurable_option) {

                    //         if (configurable_option.attributes.length == 1) {
                    //             this.selectedAttribute[i] = configurable_option.attributes[0].option_id;
                    //             this.selectedOption[i] = +configurable_option.attribute_id;
                    //             if (this.product.configurable_option.length == 1) {
                    //                 this.entityId = this.product.id;
                    //             }
                    //         }
                    //         i += 1;
                    //     }
                    //     if (this.selectedAttribute.length == this.product.configurable_option.length) {
                    //         this.entityId = this.product.id;
                    //     }
                    // }


                    // LATEST CODE

                    if (this.product.configurable_option.length) {
                        for (let index = 0; index < this.product.configurable_option.length; index++) {
                            const option = this.product.configurable_option[index];
                            switch (option.attribute_code) {
                                case 'Color':
                                    this.productColorObj = option
                                    this.selectedColorText = this.productColorObj.attributes[0].value
                                    // console.log(this.productColorObj.attributes[0].option_id);
                                    setTimeout(() => {
                                        const colorBoxes =  document.getElementById('color'+this.productColorObj.attributes[0].option_id) as HTMLElement;
                                        colorBoxes.classList.add('circle-active')
                                    }, 1000);
                                    break;
                                case 'Size':
                                    this.productSizeObj = option
                                    break;
                                case 'Age':
                                    this.productAgeObj = option
                                    break;
                                default:
                                    break;
                            }
                        }
                    }
                    // this.entityId = response.data[0].entity_id;

                    //Getting the filter options
                    //this.filterOptions();

                    /*
                    * Adding quantity options in dropdown if quantity is available and product is simple
                    * */
                    if (this.product.product_type === 'Simple' && this.product.remaining_quantity > 0) {
                        this.setQuantityDropdown(this.product.remaining_quantity);
                    } else {
                        this.quantityArr = [];
                    }

                    const final_price = this.product.final_price_kwd * 3.30;

                    // google analytics
                    this.sendGTagEvent('view_item', {
                        event_category: 'ecommerce',
                        items: [{
                            id: this.product.SKU,
                            name: this.product.name,
                            list_name: "Product Details",
                            brand: this.product.brand_name,
                            category: this.product.brand_name,
                            variant: (this.product.configurable_option.length > 0) ? this.product.configurable_option[0].attributes[0].value : '',
                            list_position: 1,
                            quantity: this.product.remaining_quantity,
                            price: final_price.toFixed(2)
                        }]
                    });

                    this.configSettings.toggleLoading(false);
                    this.dataAvailable = true;
                    setTimeout(() => {
                        this.footerService.toggleFooter(true)
                    }, 300);

                    // branch io analytics
                    this.registerBranch();
                    var options = { no_journeys: true };
                    branch.init('key_test_ndxZ2965S95i2JJpWDtCAhikurjUHvrz', options, function (err, data) {
                    });

                    var linkData = {
                        campaign: 'content 123',
                        channel: 'facebook',
                        feature: 'share',
                        stage: 'new user',
                        tags: ['tag1', 'tag2', 'tag3'],
                        alias: '',
                        data: {
                            'custom_bool': true,
                            'custom_int': Date.now(),
                            'custom_string': 'hello',
                            '$og_title': 'Shop ' + this.product.name,
                            '$og_description': this.product.description,
                            '$og_image_url': this.product.image,
                            'target': 'P',
                            'target_id': this.product.id
                        }
                    };

                    branch.link(linkData, (err, link) => {
                        this.branchIoLink = link;
                    });
                 
                    this.titleService.setTitle(pathName);
                    this.changeMeta.updateMetaTags(this.product.name, this.product.description)
                    this.skeletonLoader=false;
                    this.ogProductAttrValues= this.product.configurable_option[1].attributes
                } else {
                    this.configSettings.toggleLoading(false);
                }
            }, error => { this.configSettings.toggleLoading(false); });
    }

    // to configuration option - size
    selectedSize: any
    ogProductAttrValues:any;
    setSize(size: any, sizeObj: any) {
        let selectedSizeId: any
        this.clearSizeFilter()
        const sizeBoxes = document.getElementById('size' + size.option_id) as HTMLElement;
        sizeBoxes.classList.add('active-size')
        selectedSizeId = `S${size.option_id}`;
        this.selectedSize = size
        if (!this.selectedAttribute.includes(sizeObj.attribute_id)) {
            this.selectedAttribute.push(sizeObj.attribute_id);
        }
        this.setOptionValues(selectedSizeId);    
        // if(this.userId!=''){
        //     if (this.product.configurable_option[1].attributes.length > 0) {          
        //         if (this.selectedFilters[1] == undefined) {
        //             this.selectedFilters.push(this.product.configurable_option[0]);
        //             this.selectedFilters.push(this.product.configurable_option[1]);
        //         }            
        //         this.ogProductAttrValues.forEach(element => {
        //             if (element.option_id == size.option_id) {
        //                 console.log('dd');                    
        //                 if(this.ogProductAttrValues.length===this.selectedFilters[1].attributes.length){
        //                     this.selectedFilters[1].attributes=[]
        //                 }
        //                 this.selectedFilters[1].attributes.push(element);
        //             }
        //         });
        //         console.log(this.selectedFilters[1], 'selectd f  ');
        //     }
        // }
    }

    clearSizeFilter() {
        const sizeBoxes = document.getElementsByClassName("size-box");
        for (let i = 0; i < sizeBoxes.length; i++) {
            sizeBoxes[i].classList.remove("active-size");
        }
        this.selectedSizeArr = []
    }
    key(event:any){
        const pattern = /[0-9\+\-\ ]/;
              let inputChar = String.fromCharCode(event?.charCode);
              if ( !pattern.test(inputChar)) {
                  
                   event.preventDefault();
              } else {
                
              }
      }
    
    // to configuration option - age
    selectedAge: any
    setAge(age: any, ageObj: any) {
        let selectedAgeId: any;
        this.clearAgeFilter();
        const ageBoxes = document.getElementById('age' + age.option_id) as HTMLElement;
        ageBoxes.classList.add('active-age');
        selectedAgeId = `A${age.option_id}`;
        this.selectedAge = age
        if (!this.selectedAttribute.includes(ageObj.attribute_id)) {
            this.selectedAttribute.push(ageObj.attribute_id);
        }
        this.setOptionValues(selectedAgeId);
    }

    clearAgeFilter() {
        const ageBoxes = document.getElementsByClassName("age-box");
        for (let i = 0; i < ageBoxes.length; i++) {
            ageBoxes[i].classList.remove("active-age");
        }
        this.selectedAgeArr = [];
    }


    // to configuration option - color
    setColor(color: any,colorObj: any){
        let selectedColorId: any
        this.clearColorFilter()
        const colorBoxes =  document.getElementById('color'+color.option_id) as HTMLElement;
        colorBoxes.classList.add('circle-active')
        selectedColorId = `C${color.option_id}`
        this.selectedColorText = color.value;
        if (!this.selectedAttribute.includes(colorObj.attribute_id)) {
            this.selectedAttribute.push(colorObj.attribute_id);
        }
        this.setOptionValues(selectedColorId);
    }

    clearColorFilter() {
        const colorBoxes = document.getElementsByClassName("circle-main");
        for (let i = 0; i < colorBoxes.length; i++) {
            colorBoxes[i].classList.remove("circle-active");
        }
        this.selectedColorArr = []
    }

    tempOptionArray: Array<any> = []
    setOptionValues(optionId: number) {
        this.updateArrayValue(optionId)
    }

    updateArrayValue(newValue) {
        const newFirstCharacter = newValue.charAt(0); // Get the first character of the new value
      
        let updated = false; // Flag to check if the update has been made
      
        // Loop through the array to check for a match
        for (let i = 0; i < this.tempOptionArray.length; i++) {
          const existingValue = this.tempOptionArray[i];
          const existingFirstCharacter = existingValue.charAt(0); // Get the first character of the existing value
      
          if (existingFirstCharacter === newFirstCharacter) {
            // Replace the existing value with the new value
            this.tempOptionArray[i] = newValue;
            updated = true;
            break; // No need to continue searching since we already updated
          }
        }
      
        if (!updated) {
          // If no match was found, add the new value to the array
          this.tempOptionArray.push(newValue);
        }
        this.selectedOption = this.removeFirstCharacterFromArray(this.tempOptionArray)        
        this.filterOptionsV2()
      }
      

      removeFirstCharacterFromArray(arr: string[]) : any[] {
        return arr.map((str) => str.slice(1));
      }

    clearArr(){
        this.tempOptionArray = []
        this.selectedAttribute = []
        this.selectedOption = []
    }

    // to get data based on selected configurable options
    onOptionSelect(option, $event) {
        
        if (this.selectedAttribute[0] == undefined || this.selectedAttribute[0] == '') {
            this.toastr.warning('Please select color first');
            return;
        }
        document.getElementById('option-' + option.attribute_id).classList.remove('error');
        this.clearFilters(option, $event).then(() => {
            this.filterOptionsV2();

        });
        this.addToCartText = 'ADD TO BAG';
    }



    // to clear old configurable options 
    clearFilters(configurable_option: ConfigurableOptions, $event): Promise<any> {
        let promise = new Promise<any>((resolve, reject) => {
            this.product.configurable_option.map((option, index) => {
                if (option.attribute_id === configurable_option.attribute_id) {
                    if (index == 0) {
                        this.selectedAttribute.splice(1);
                        this.selectedOption.splice(1);
                        this.selectedFilters.splice(1);
                    }

                    this.selectedAttribute[index] = $event.option_id;
                    this.selectedOption[index] = +configurable_option.attribute_id;
                    this.selectedFilters[index] = {
                        type: configurable_option.type,
                        attribute_id: configurable_option.attribute_id,
                        attribute_code: configurable_option.attribute_code,
                        attributes: [$event]
                    };
                }
            });

            resolve(true);
        });
        return promise;
    }

    // to get data based on selected configurable options
    onOptionSelectV2(attribute, option, i, $event) {
        this.clearColorFilter()
        this.clearSizeFilter()
        this.selectedAttribute[i]
        this.selectedAttribute[i] = attribute.attribute_id
        this.selectedOption[i] = option.option_id
        this.filterOptionsV2();
        // this.selectedAttribute[i] = attribute.option_id;
        // this.clearFiltersV2(option, attribute, i, $event).then(() => {
        //     this.filterOptionsV2();
        // });
        this.addToCartText = 'ADD TO BAG';
    }

    // to clear old configurable options 
    clearFiltersV2(configurable_option: ConfigurableOptions, attribute: any, i, $event): Promise<any> {
        let promise = new Promise<any>((resolve, reject) => {
            this.product.configurable_option.map((option, index) => {
                if (option.attribute_id === configurable_option.attribute_id) {
                    if (index == 0) {
                        this.selectedAttribute.splice(1);
                        this.selectedOption.splice(1);
                        this.selectedFilters.splice(1);
                    }

                    this.selectedAttribute[index] = attribute.option_id;
                    this.selectedOption[index] = +configurable_option.attribute_id;
                    this.selectedFilters[index] = {
                        type: configurable_option.type,
                        attribute_id: configurable_option.attribute_id,
                        attribute_code: configurable_option.attribute_code,
                        attributes: [attribute]
                    };
                }
            });

            resolve(true);
        });
        return promise;
    }

    // set default configuration when add to bag
    setDefaultConfiguration() {
        console.log(this.userId,'userId');
        
        const checkTypeExistence = roleParam => this.selectedFilters.some(({ type }) => type == roleParam);        
        if (this.selectedFilters.length != this.product.configurable_option.length) {
            if (this.product.configurable_option.length == 1) {
                if (this.product.configurable_option[0].attributes.length > 1) {
                    if (!checkTypeExistence("Color")) {
                        this.selectedFilters.push(this.product.configurable_option[0]);
                    }
                }
            }
            if (this.product.configurable_option.length == 2) {
                if (this.product.configurable_option[0].attributes.length > 0) {
                    if (!checkTypeExistence("Color")) {
                        this.selectedFilters.push(this.product.configurable_option[0]);
                    }
                }
                if(this.userId!=undefined){
                    if (this.product.configurable_option[1].attributes.length > 0) {
                        if (!checkTypeExistence("Size")) {
                            this.selectedFilters.push(this.product.configurable_option[1]);
                        }
                    }
                }
            }
        }   
        
        if(this.userId==undefined){
            if (this.product.configurable_option[1].attributes.length > 0) {          
                if (this.selectedFilters[1] == undefined) {
                //     this.selectedFilters.push(this.product.configurable_option[0]);
                    this.selectedFilters.push(this.product.configurable_option[1]);
                }            
                this.ogProductAttrValues.forEach(element => {
                    if (element.option_id == this.selectedSize.option_id) {
                        console.log('dd');                    
                        if(this.ogProductAttrValues.length===this.selectedFilters[1].attributes.length){
                            this.selectedFilters[1].attributes=[]
                        }
                        this.selectedFilters[1].attributes.push(element);
                    }
                });
                // console.log(this.selectedFilters[1], 'selectd f  ');
            }
        }
    }

    // to trigger add to bag api, add to bag analytic event and handel offline cart
    addToBag() {

        // to ensure any one option is selected before add to cart 
        const { activeSizeFound, activeAgeFound } = this.checkForSelectedConfiguration();
        
        if (activeSizeFound == false && (this.productSizeObj && this.productSizeObj.attributes && this.productSizeObj.attributes.length > 0)) {
            let message = this.lang == 'en' ? 'Please select a size for '+this.product.name : this.product.name+'يرجى اختيار حجم ل'
            this.toastr.warning(message)            
            return
        } 
        if (activeAgeFound == false && (this.productAgeObj && this.productAgeObj.attributes && this.productAgeObj.attributes.length > 0)) {
            let message = this.lang == 'en' ? 'Please select a age for '+this.product.name : this.product.name+'يرجى اختيار حجم ل'
            this.toastr.warning(message)            
            return
        } 
        this.setDefaultConfiguration();
        this.addToCartText = '✓';

        try {
            if (this.product.is_saleable || this.product.is_preorder) {
                // console.log(1);
                
                // Adding event  
                try {
                    this.analytics.addToCart(
                        this.product.id,
                        this.product.name,
                        this.product.marketing_category + ' - ' + this.product.marketing_subcategory,
                        this.product.brand_name,
                        this.product.final_price,
                        this.product.regular_price,
                        this.quantity,
                        this.product.configurable_option
                    );
                } catch (e) {
                }
                const final_price = this.product.final_price_kwd * 3.30;
                try {
                    // google analytics
                    this.sendGTagEvent('add_to_cart', {
                        items: [{
                            id: this.product.SKU,
                            name: this.product.name,
                            list_name: 'Product Details',
                            brand: this.product.brand_name,
                            category: this.product.brand_name,
                            variant: (this.product.configurable_option.length > 0) ?
                                this.product.configurable_option[0].attributes[0].value : '',
                            list_position: 1,
                            quantity: this.quantity,
                            price: final_price.toFixed(2)
                        }]
                    });
                } catch (e) {
                }

                if ((this.product.is_vip_product == 1) ? ((this.product.is_vip_product == this.isVip) ? true : false) : true) {
                    // console.log(2);
                    // this.configSettings.toggleLoading(true);
                    // console.log(this.entityId,'this.entityId');
                    
                    if (this.entityId) {
                        // console.log(3);
                        this.localStorage.getItem<any>('userInfo').subscribe((userInfo: any) => {
                            if (userInfo) {
                                // console.log(4);
                                this.addToOnlineCart(userInfo).then((any) => {
                                    this.configSettings.toggleLoading(false);
                                    // const dialogRef = this.dialog.open(AddedToCartDialogComponent);
                                    var itemAddedMsg = "Item added to your bag";
                                    setTimeout(() => {

                                        if (this.lang == 'ar') {
                                            itemAddedMsg = 'العنصر يضاف إلى حقيبتك';
                                        }
                                        if (this.isMobile == false) {
                                            this.addToCartText = 'PROCEED TO BAG';

                                        }
                                        if (this.isMobile == true) {
                                            this.addToCartText = 'PROCEED TO PURCHASE';

                                        }
                                    }, 1500);
                                    setTimeout(() => {
                                        // delay of 5 seconds are given to set addToCartText back to default
                                        this.addToCartText = 'ADD TO BAG';
                                    }, 5000);
                                    // this.toastr.success(itemAddedMsg);
                                    this.handleCartService.sendItem(this.product);
                                }, error => {

                                    this.configSettings.toggleLoading(false);
                                    this.addToCartText = 'ADD TO BAG';
                                });
                            } else {
                                // console.log(5);
                                // to handel offline cart
                                this.cartService.addToOfflineCart(this.entityId, this.product, this.selectedFilters, 1).then(response => {
                                    this.configSettings.toggleLoading(false);
                                    // const dialogRef = this.dialog.open(AddedToCartDialogComponent);
                                    var itemAddedMsg = "Item added to your bag";
                                    if (this.isMobile == false) {
                                        this.addToCartText = 'PROCEED TO BAG';

                                    }
                                    if (this.isMobile == true) {
                                        this.addToCartText = 'PROCEED TO PURCHASE';

                                    }
                                    if (this.lang == 'ar') {
                                        itemAddedMsg = 'العنصر يضاف إلى حقيبتك';
                                    }
                                    // this.toastr.success(itemAddedMsg);
                                    this.handleCartService.sendItem(this.product);

                                    setTimeout(() => {
                                        // delay of 5 seconds are given to set addToCartText back to default
                                        this.addToCartText = 'ADD TO BAG';
                                    }, 5000);
                                });
                            }
                        }, error => {
                            this.configSettings.toggleLoading(false);
                            this.addToCartText = 'ADD TO BAG';
                        });
                    } else {
                        // console.log(6);
                        // error handling
                        this.configSettings.toggleLoading(false);
                        let selects = document.querySelectorAll('ng-select');
                        for (let i = 0; i < selects.length; i++) {
                            if (this.selectedAttribute[i]) {
                                selects[i].classList.remove('error');
                            }
                            else {
                                selects[i].classList.add('error');
                                this.addToCartText = 'ADD TO BAG';
                            }
                        }
                    }
                } else {
                    // console.log(7);
                    var msg = "Unfortunately, this product can't be added to your cart as it's exclusive for our Gold Members";
                    if (this.lang == 'ar') {
                        msg = 'ناسف، المنتج حصري للعملاء الذهبيين';
                    }
                    this.toastr.error(msg);
                    this.addToCartText = 'ADD TO BAG';
                }
            }
        } catch (e) {
            // console.log(8);
            this.configSettings.toggleLoading(false);
            this.addToCartText = 'ADD TO BAG';
        }
    }

    // to handel offline cart functionality
    private addToOnlineCart(user: string): Promise<any> {
        return new Promise(resolve => {
            this.cryptService.doDecrypt(user).then(decryptedString => {
                const user = JSON.parse(decryptedString);

                const getParams = {
                    lang: this.lang,
                    store: this.store.iso_code
                };
                const postParams = {
                    user_id: user.id,
                    products: this.entityId,
                    quantity: this.quantity
                };
                this.configService.postRequest('add-to-cart', getParams, postParams)
                    .subscribe(response => {
                        if (response.status === 200) {
                            this.configSettings.setCartId(response.data.id);
                            this.cartService.changCartCount(response.data.items.length);
                            this.cartService.setCartObservable(response.data);
                            resolve(true);
                        }
                        if (response.status === 412) {
                            this.product.remaining_quantity = 0;
                            this.configSettings.toggleLoading(false);
                            this.toastr.error(response.message);
                            this.addToCartText = 'NOTIFY ME';
                        }
                        else {
                            this.configSettings.toggleLoading(false);
                            reject(response);
                        }
                    }, error => { reject(error); });
            });
        });
    }

    // to handel add to wishlist event
    toggleWishList($event) {
        if (this.userId) {
            this.configSettings.toggleLoading(true);
            const getParams = {
                lang: this.lang,
                store: this.store.iso_code
            };

            const postParams = {
                product_id: this.id,
                user_id: this.userId
            };

            if (this.product.item_in_wishlist) {
                // to remove from wishlist
                this.configService.postRequest('remove-wishlist', getParams, postParams)
                    .subscribe(response => {
                        if (response.status.toString() === "200") {
                            this.product.item_in_wishlist = 0;
                            
                            this.updateWishlistCountService.setWishlistCount(response?.wishlist_count)
                            this.configSettings.toggleLoading(false);
                            this.wishlistService.changWishlistCount(response.data.length);
                            this.toastr.error(response.message)
                        } else {
                            this.configSettings.toggleLoading(false);
                        }
                    }, error => { this.configSettings.toggleLoading(false); });
                    // add HeartAnimation
                    this.removeMiniHearts();
                    const styleTags = document.querySelectorAll('.style-move-heart');
                    styleTags.forEach((el) => {
                    const animationSetId = el.getAttribute('data-animation-set-id');
                    if (animationSetId === this.subHearts[0].animationSetId) {
                    el.remove();}});   
            } else {
                // to add to wishlist
                this.configService.postRequest('add-wishlist', getParams, postParams)
                    .subscribe(response => {
                        if (response.status.toString() === "200") {
                            
                            this.toastr.success(response.message)
                            this.analytics.addToWishlist(
                                'Browse',
                                this.product.id,
                                this.product.name,
                                this.product.marketing_category + ' - ' + this.product.marketing_subcategory,
                                this.product.brand_name,
                                this.product.final_price,
                                this.product.regular_price,
                            );
                            this.product.item_in_wishlist = 1;
                            this.updateWishlistCountService.setWishlistCount(response?.wishlist_count)
                            this.configSettings.toggleLoading(false);
                            this.wishlistService.changWishlistCount(response.data.length);
                            // add HeartAnimation
                            this.isHeartFilled = true;
                            this.generateHearts();
                        } else {
                            this.configSettings.toggleLoading(false);
                        }
                    }, error => { this.configSettings.toggleLoading(false); });
                    
            }
        } else {
            window.location.href = '/login?returnUrl=product/detail/' + this.id;
        }
    }

    // to get configurable options
    filterOptionsV2() {
        this.configSettings.toggleLoading(true);
        const getParams = {
            lang: this.lang,
            store: this.store.iso_code
        };

        const postParams = {
            product_id: Number(this.id),
            attribute_id: (this.selectedAttribute.length) ? this.selectedAttribute.join(',') : '',
            option_id: (this.selectedOption.length) ? this.selectedOption.join(',') : ''
        };

        this.addToCartText = 'ADD TO BAG'

        this.configService.postRequest('configurable-options', getParams, postParams)
            .subscribe(response => {
                if (response.data[0].images.length == 1) {
                    this.product.images = response.data[0].images;
                    this.product.images.unshift(this.product.images[0]);
                } else {
                    this.product.images = response.data[0].images;
                }
                this.entityId = response.data[0].entity_id;

                // this.updateStockForAttribute(response.data);

                // if (response.data[0].attribute_id == '2') {
                //     response.data[0].attributes.forEach(attr => {
                //         attr.value = (attr.is_available == '1') ? attr.value : ((this.product.is_preorder == 1) ? (attr.value + ' (PRE-ORDER)') : (attr.value + ' (OUT OF STOCK)'));
                //     });
                //     this.product.configurable_option[1] = response.data[0];
                // }
                
                if (response.data[0].attributes.length) {
                    switch (response.data[0].type) {
                        case 'Color':
                        case 'اللون':
                            this.productColorObj = response.data[0]
                            this.selectedColorText = this.productColorObj.attributes[0].value
                            setTimeout(() => {
                                const colorBoxes =  document.getElementById('color'+this.productColorObj.attributes[0].option_id) as HTMLElement;
                                colorBoxes.classList.add('circle-active')
                            }, 1000);
                            break;
                        case 'Size':
                        case 'القياس':
                            this.productSizeObj = response.data[0]
                            // setTimeout(() => {
                                this.cdr.detectChanges()
                                if (this.productSizeObj.attributes[0].is_available != 0 ) {
                                    const sizeBoxes = document.getElementById('size' + this.productSizeObj.attributes[0].option_id) as HTMLElement;
                                    sizeBoxes.classList.add('active-size')
                                    this.selectedSize = this.productSizeObj.attributes[0]
                                }
                            // }, 1000);
                            break;
                        case 'Age':
                        case 'العمر':
                            this.productAgeObj =  response.data[0]
                            setTimeout(() => {   
                                if (this.productAgeObj.attributes[0].is_available != 0 ) {                            
                                const ageBoxes = document.getElementById('age' + this.productAgeObj.attributes[0].option_id) as HTMLElement;
                                ageBoxes.classList.add('active-age');
                                this.selectedAge = this.productAgeObj.attributes[0]
                                }
                            }, 1000);
                            break;
                        default:
                            break;
                    }
                    

                    this.quantityArr = [];
                } else {
                    this.product.final_price = response.data[0].final_price;
                    this.product.regular_price = response.data[0].regular_price;
                    this.product.SKU = response.data[0].sku;
                    this.product.remaining_quantity = (response.data[0].remaining_quantity) ? response.data[0].remaining_quantity : 0;                    
                    this.entityId = response.data[0].entity_id;

                    if (this.product.remaining_quantity > 0) {
                        this.setQuantityDropdown(this.product.remaining_quantity);
                    } else {
                        this.quantityArr = [];
                    }
                }

                this.configSettings.toggleLoading(false);
            }, error => { this.configSettings.toggleLoading(false); });
    }

    // to get configurable options
    filterOptions() {
        this.configSettings.toggleLoading(true);

        const getParams = {
            lang: this.lang,
            store: this.store.iso_code
        };

        const postParams = {
            product_id: this.product.id,
            option_id: (this.selectedAttribute.length) ? this.selectedAttribute.join(',') : '',
            attribute_id: (this.selectedOption.length) ? this.selectedOption.join(',') : ''
        };

        this.configService.postRequest('configurable-options', getParams, postParams)
            .subscribe(response => {
                if (response.data[0].attribute_id == '2') {
                    response.data[0].attributes.forEach(attr => {
                        attr.value = (attr.is_available == '1') ? attr.value : (attr.value + ' (OUT OF STOCK)');
                    });
                    this.product.configurable_option[1] = response.data[0];
                }
            

                if (response.data[0].attributes.length) {
               
                    this.quantityArr = [];
                } else {
                    this.product.final_price = response.data[0].final_price;
                    this.product.regular_price = response.data[0].regular_price;
                    this.product.SKU = response.data[0].sku;
                    this.product.remaining_quantity = (response.data[0].remaining_quantity) ? response.data[0].remaining_quantity : 0;
                    this.entityId = response.data[0].entity_id;

                    if (this.product.remaining_quantity > 0) {
                        this.setQuantityDropdown(this.product.remaining_quantity);
                    } else {
                        this.quantityArr = [];
                    }
                }

                this.configSettings.toggleLoading(false);
            }, error => { this.configSettings.toggleLoading(false); });
    }

    updateStockForAttribute(data: any) {
        for (let configOption of data) {
            for (let productConfigOption of this.product.configurable_option) {
                if (productConfigOption.type === configOption.type) {
                    for (let productAttr of productConfigOption.attributes) {
                        const productOptionId = productAttr.option_id;
                        let optionFound = false;
                        for (let configAttribute of configOption.attributes) {
                            if (configAttribute.option_id == productOptionId) {
                                optionFound = true;
                                break;
                            }
                        }
                        if (!optionFound) {                            
                            productAttr.value = (this.lang === 'en') ?
                                productAttr.value + " (OUT OF STOCK)"
                                : "(إنتهى من المخزن) " + productAttr.value;
                        }
                    }
                }
            }
        }
        this.showAttribute = true;
    }


    // open selected image in popup
    showGallery(index) {
        let dialogRef = this.dialog.open(DialogComponent, {
            data: { images: this.product.images, gallery: true },
            height: '95vh',
            width: '80vw',
        });        
    }

    // to set available quantity
    setQuantityDropdown(remaining_quantity: number) {
        this.quantityArr = [];
        const maxDropdownQuantity = (this.product.remaining_quantity > 10) ? 10 : this.product.remaining_quantity;
        for (let i = 1; i <= maxDropdownQuantity; i++) {
            this.quantityArr.push({ value: i, label: i });
        }
        this.quantity = 1;
    }

    // branch io section - STARTS
    registerBranch() {
        this.registerBranchSource(window, document, "script", "branch", function (b, r) { b[r] = function () { b._q.push([r, arguments]); }; }, { _q: [], _v: 1 }, "addListener applyCode autoAppIndex banner closeBanner closeJourney creditHistory credits data deepview deepviewCta first getCode init link logout redeem referrals removeListener sendSMS setBranchViewData setIdentity track validateCode trackCommerceEvent logEvent disableTracking".split(" "), 0);
    }

    registerBranchSource(b, r, a, n, c, h, _, s) {
        if (!b[n] || !b[n]._q) {
            for (; s < _.length;)
                c(h, _[s++]);
            let d = r.createElement(a);
            d.async = 1;
            d.src = "https://cdn.branch.io/branch-latest.min.js";
            let k = r.getElementsByTagName(a)[0];
            k.parentNode.insertBefore(d, k);
            b[n] = h;
        }
    }
    // branch io section - ENDS

    // to google analytics events
    private sendGTagEvent(event, params) {
        this.configService.sendGTagEvent(event, params);
    }

    // notify me section - STARTS
    // to handel notify me form submit
    submitNotifyMe() {
        this.notifyMeSubmitted = true;
        Object.keys(this.notifyMeForm.controls).forEach(field => {
            const control = this.notifyMeForm.get(field);
            control.markAsTouched({ onlySelf: true });
        });
        if (this.notifyMeForm.dirty && this.notifyMeForm.valid) {
            const _form = this.notifyMeForm.value;
            this.notifyMe(_form);
        }
    }

    notifyMe(data) {
        data.user_id = this.userId;
        data.product_id = this.product.id;
        this.isNotifyMeLoading = true;
        this.cartService.notifyMe({}, data).subscribe((response) => {
            this.isNotifyMeLoading = false;
            if (response.status === 200) {
                this.notifyMeSubmitted = false;
                this.notifyMeForm.patchValue({
                    phone: '' // Resetting the phone field
                  });
                const message = (this.lang == 'ar') ?
                    'لقد قمنا بإستلام طلبك وسنقوم بإبلاغك في حالة توفر هذا المنتج مجدادا'
                    : 'We have received your request and will notify you once the product is back in stock';
                this.toastr.success(message);

                setTimeout(() => {
                  let dismissNotifyModalBtn = document.getElementById('dismissNotifyModal') as HTMLButtonElement
                //   console.log(dismissNotifyModalBtn,'dismissNotifyModalBtn');
                  dismissNotifyModalBtn.click()
                }, 100);
                // $('#notifyMeModal').modal('hide');
            }
        });
    }

    // notify me section - ENDS

    // to handel vip user registrations
    vipRegister() {
        if (this.id) {
            this.userService.getUserSession().then(response => {
                if (response !== false) {
                    if (response.id) {
                        const getParams = {
                            lang: this.configSettings.getLang()
                        }

                        const postParams = {
                            user_id: response.id,
                            vip_type_id: this.id
                        }

                        this.createVipService.createVip(getParams, postParams).subscribe(response => {
                        });
                    }
                }
            });
        }
    }

    // to check if an active size and/or age has been selected
    checkForSelectedConfiguration() {
        let activeSizeFound = false;
        let activeAgeFound = false;
    
        // to loop through size boxes to find active-size class
        if (this.productSizeObj?.attributes.length > 0) {
            let sizeBoxes = document.getElementsByClassName('size-box');
    
            for (let i = 0; i < sizeBoxes.length; i++) {
                if (sizeBoxes[i].classList.contains('active-size')) {
                    console.log('Size box with active-size found.');
                    activeSizeFound = true;
                    break;
                }
            }
        }

        // to loop through age boxes to find active-age class
        if (this.productAgeObj?.attributes.length > 0) {
            let ageBoxes = document.getElementsByClassName('age-box');
            for (let i = 0; i < ageBoxes.length; i++) {
                if (ageBoxes[i].classList.contains('active-age')) {
                    console.log('Age box with active-age found.');
                    activeAgeFound = true;
                    break;
                }
            }
        }   

        // to return the status of active-size and active-age presence
        return { activeSizeFound, activeAgeFound };
    }

    

    // handel add to cart click
    openCart() {
        this.addToCartModalService.sendItemOpen('open');
        return  
        var items = [];
        this.cartService.getCartObservable().subscribe(response => {
            items = response.items;
            var checkItem = items.find(ele => ele.id == this.product.id)
            if (items.length > 0 && items.includes(checkItem) == true) {
                this.addToCartModalService.sendItemOpen('open');
            } else if (items.includes(checkItem) == false) {
                this.addToCartText = 'ADD TO BAG';
            } else {
                this.addToCartText = 'ADD TO BAG';
            }
        })
    }
    
    // to get product based on selected product index
    setImageVal(index: number) {
        this.currentImageIndex = index
        this.product[index]
    }

    // toggle header and navbar
    activateHeader() {
        this.navbarService.isEnableHeaderBottomAction(true);
        this.configSettings.setNavBarStatus(true)
        this.headerToggleService.setNavItems(true)
    }

    // to set product badge based upon condition
  setProductBadge(productObj: any): string {
    let txt: string = '';
    // this console log is for testing purpose
    // console.log(
    //   `productObj.name : ${productObj.name}, `,
    //   `productObj.is_saleable : ${productObj.is_saleable}, `,
    //   `productObj.is_preorder : ${productObj.is_preorder}, `,
    //   `productObj.is_trending : ${productObj.is_trending}, `,
    //   `productObj.is_featured : ${productObj.is_featured}, `
    // );
    
    if (productObj.is_saleable == 0) {
      txt = this.lang == 'en' ? 'SOLD OUT' : 'تم البيع';
    } else if (productObj.is_preorder == 1) {
      txt = this.lang == 'en' ? 'PRE ORDER' : 'الطلب المسبق';
    } else if (productObj.is_trending == 1) {
      txt = this.lang == 'en' ? 'NEW' : 'جديد';
    } else if (productObj.is_featured == 1) {
      txt = this.lang == 'en' ? 'BEST SELLER' : 'الأكثر مبيعًا';
    }
    
    return txt;
  }

    // to handel scroll to top
    scrollToTop() {
        // window.scroll({
        //     top: 0,
        //     left: 0,
        //     behavior: 'smooth'
        // });
        setTimeout(() => {
            
            const element = document.getElementById("topOfPage");
            // console.log(element,'element');
            
            if (element) {
              element.scrollIntoView({ behavior: "smooth" });
            }
        }, 1500);
    }
  @ViewChild(SharePopupComponent) SharePopupComponent: SharePopupComponent;

    share(){
     this.SharePopupComponent.share();       
    }

    //this method is called after the component is being destroyed..
    ngOnDestroy() {
        this.changeMeta.updateMetaTags('The Wishlist | Discover fashion online!', "Everything on your Wish List!' Discover and shop a variety of pieces ranging from clothes, bags, shoes, to accessories and more.")
        //close auto add-to-cart popup 
        this.addToCartModalService.sendItemOpen('close');
    }

      // to scroll to top of page
//   gotoTop() {
//     window.scroll({
//       top: 0,
//       left: 0,
//       behavior: 'smooth'
//     });
//   }

calculateDiscountAmount(product): number {
    let discountPercentage = ((product.regular_price - product.final_price) / product.regular_price) * 100;
    let discountPercentageValue=Math.round(discountPercentage)
    return discountPercentageValue;
  }



  isHeartFilled = false;
  subHearts = [];
  
  getRandomInt(min, max) {
    return Math.random() * (max - min) + min;
  }
  
  keyframeRandomMove() {
    return Math.floor(this.getRandomInt(-100, 100));
  }
  
  keyframesMove(index) {
    return `
      @keyframes move-heart-${index} {
        from { transform: translate(-50%, -50%); opacity: 1; }
        to { transform: translate(${this.keyframeRandomMove()}%, ${this.keyframeRandomMove()}%); opacity: 0; }
      }
      .move-heart-${index} {
        animation: move-heart-${index} 1.3s 0s ease-in-out forwards;
      }
    `;
  }
  generateHearts() {
    if (!this.isHeartFilled) {
      return;
    }
  
    const numberOfHearts = Math.floor(this.getRandomInt(6, 10));
    this.subHearts = Array.from({ length: numberOfHearts }, (_, i) => ({
      color: '#000',
      moveSideWise: this.keyframeRandomMove(),
      moveUpOrDown: this.keyframeRandomMove()
    }));
  
    for (let i = 1; i < numberOfHearts; i++) {
      const style = document.createElement('style');
      style.setAttribute('class', 'style-move-heart');
      style.innerHTML = this.keyframesMove(i);
      document.head.appendChild(style);
    }
  }
  removeMiniHearts() {
    this.subHearts = [];
  }
}
