<nav class="w-100 header">
    <!-- language and location  -->
    <div class="top-header">
        <div class="container-fluid">
            <div class="w-100 d-none d-lg-flex justify-content-between  align-items-center gap-4">
            <ul class="w-50 d-none d-lg-flex justify-content-start  align-items-center">
                <li class="top-header-menu " *ngIf="!userSession?.id">
                    <a [routerLink]="['/login']" routerLinkActive="router-link-active">{{'Track Your Order' | translate}}</a>
                </li>
                <li class="top-header-menu " *ngIf="userSession?.id">
                    <a [routerLink]="['/my-orders']" routerLinkActive="router-link-active">{{'Track Your Order' | translate}}</a>
                </li>
                <li class="top-header-menu " >
                    <a [routerLink]="['/contact-us']" routerLinkActive="router-link-active">{{'Contact Us' | translate}}</a>
                </li>
                <li class="top-header-menu " >
                    <a [routerLink]="['/shipping-information']" routerLinkActive="router-link-active">{{'Delivery' | translate}}</a>
                </li>
                <li class="top-header-menu " >
                    <a [routerLink]="['/return-policy']" routerLinkActive="router-link-active">{{'Returns' | translate}}</a>
                </li>
            </ul>
            <ul class="w-50 d-none d-lg-flex justify-content-end  align-items-center">

                <li class="top-header-menu " *ngIf="!userSession?.id">
                    <a [routerLink]="['/login']" routerLinkActive="router-link-active">{{'Sign in' | translate}}</a>
                </li>
                <li class="top-header-menu mega-drop-down1" *ngIf="userSession?.id">
                    <a>
                        {{userSession.first_name + ' ' +userSession.last_name}}
                    </a>
                    <div class="dropdown-menu1">
                        <ul class="">
                            <li>
                                <a [routerLink]="['/my-account']" routerLinkActive="router-link-actives"
                                    class="store-name">
                                    {{'My Account'|translate}}
                                </a>
                            </li>
                            <li>
                                <a href="javascript:;" class="" (click)="showLogoutModal()">
                                    {{'Logout' | translate}}
                                </a>
                            </li>
                        </ul>
                    </div>

                </li>





                <li class="top-header-menu  dropdown store-dropdown">
                    <a href="/" id="storeDropDown" class="d-flex justify-content-center gap-2" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                        <img *ngIf="store" src="{{store.flag}}" alt="flag">
                        <span *ngIf="lang=='en'" class="store-name">{{store?.name_en}}</span>
                        <span *ngIf="lang=='ar'" class="store-name">{{store?.name_ar}}</span>
                        <span class="expand-toggle"></span>
                    </a>
                    <div class="dropdown-menu" aria-labelledby="storeDropDown">
                        <ul class="container-fluid p-0">
                            <li *ngIf="store">
                                <a *ngFor="let store of stores" [attr.data-currency]="store.currency_en"
                                    (click)="changeStore($event, store.id)">
                                    <img class="FlagTop" [src]="store.flag" alt="flag">
                                    <span class="store-name font-small">
                                        <span *ngIf="lang=='en'" class="store-name">{{store?.name_en}}</span>
                                        <span *ngIf="lang=='ar'" class="store-name">{{store?.name_ar}}</span> </span>
                                </a>
                            </li>
                        </ul>
                    </div>

                </li>
                <li class="top-header-menu">
                    <a class="d-flex justify-content-center align-content-center gap-2 language-change"
                        href="javascript:;" (click)="changeLanguage($event)">
                        <!-- <img alt="language" src="/assets/images/language.png"> -->
                        <span>
                            {{(lang == 'ar') ? 'English' : 'العربية'}}
                        </span>
                    </a>
                </li>
            </ul>
            </div>
        </div>
    </div>

    <!-- main header -->
    <div class="wishlist-header">
        <div class="container-fluid">
            <ul class="d-flex justify-content-center logo-header align-content-center"
                [ngClass]="navigationStatus && showNavitems ?'':'border-bottom-header'">
                <li class="logo-header-section tabs-sec d-flex">
                    <ul *ngIf="navigationStatus && showNavitems"
                        class="header-tabs d-none d-sm-none  d-md-none  d-lg-flex justify-content-start align-content-center"
                        id="myTab" role="tablist">
                        <li class="header-tabs-items" *ngFor="let category of categories">
                            <span *ngIf="allCatData.apply_default_category==1">
                                <button class="border-0 tabs-button"
                                    [ngClass]="category.id == allCatData.default_root_category?'active':''"
                                    (click)="setCategory(category)">
                                    {{category.name}}
                                </button>
                            </span>
                            <span *ngIf="allCatData.apply_default_category!=1">
                                <button class="border-0 tabs-button"
                                [ngClass]="(category.id == top_selected_category)?'active':''"
                                (click)="setCategory(category)">
                                {{category.name}}
                            </button>
                            </span>
                        </li>
                    </ul>
                    <div
                        class="header-tabs d-flex d-sm-flex  d-md-flex  d-lg-none justify-content-start align-content-center">
                        <a (click)="openNav()">
                            <img src="/assets/icons/menu-hamburger.svg" alt="menu" class="hamburger">
                        </a>
                    </div>
                </li>
                <li class="logo-header-section header-logo-sec">
                    <!-- this code is to show logo of selected brand (tier 1) -->
                    <a *ngIf="!isBrandHomePage" (click)="showHomePageWithNoNav()"
                        class="d-flex justify-content-center ailgn-content-center">
                        <img id="wishlistImg" #wishlistImg src="/assets/images/wishlist-new-logo.svg" alt="Logo"
                            class="logo-image">
                    </a>
                    <a *ngIf="isBrandHomePage" class="d-flex justify-content-center align-content-center img-logos">
                        <div class="logo-image-mange">
                        <img id="wishlistImg" #wishlistImg [src]="brandInfo?.navbar_image" alt="Logo" class="logo-image">
                        </div>
                    </a>
                    <!-- 
                    <a   (click)="showHomePageWithNoNav()" class="d-flex justify-content-center align-content-center">
                        <img id="wishlistImg" #wishlistImg src="/assets/images/wishlist-new-logo.png" alt="Logo"
                            class="logo-image">
                    </a> -->

                </li>
                <li class="logo-header-section d-flex justify-content-end align-items-center">
                    <span class="header-icon-sec" *ngIf="navigationStatus && showNavitems">
                        <div class="header-search align-items-center justify-content-center ">
                            <img (click)="searchBoxShow()" class="search-header-icon"
                                src="../../assets/icons/search.svg" alt="search" />
                            <p (click)="searchBoxShow()" class="d-none d-lg-block">{{'Search' | translate}}</p>
                            <!-- <form (ngSubmit)="onSubmit()" class="d-flex align-items-center" [formGroup]="searchForm"
                                [ngClass]="(lang == 'ar')?'ltr':''">
                                <img class="search-header-icon" src="/assets/icons/new-search-icon.svg"
                                    alt="search" />
                                <input matInput [placeholder]="'Search' | translate" [matAutocomplete]="auto"
                                    formControlName="q" class="search-input d-none d-lg-block" #searchInput
                                    [ngClass]="searchInput.value?'':'search-size'">

                                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn"
                                    (optionSelected)="optionSelected($event.option.value)">
                                    <mat-option *ngIf="isLoading" class="is-loading">
                                        <mat-spinner [diameter]="20"></mat-spinner>
                                    </mat-option>
                                    <ng-container class="search-menu"
                                        *ngIf="productSuggestions.length && !isLoading && searchInput.value">
                                        <div
                                            class="search-result-header sub-heading-search  d-flex m-0 align-items-center">
                                            <span class="recent-search-title  ">{{'Products' | translate}}</span>
                                            <mat-option>
                                                <a (click)="onSubmit()" class=" text-red  ">{{'View All' |
                                                    translate}}</a>
                                            </mat-option>
                                        </div>
                                        <div class="product-sect">
                                            <div class="search-product">
                                                <mat-option [value]="suggestion" class="search-product-card"
                                                    *ngFor="let suggestion of productSuggestions">

                                                    <a class="mobile-data-style">

                                                        <figure>

                                                            <div class="testnew">
                                                                <img *ngIf="suggestion?.image" [src]="suggestion?.image"
                                                                    alt="" class="carousel-imgs1">
                                                            </div>
                                                            <figcaption>
                                                                <div class="product-name uppercase">
                                                                    {{suggestion.brand}}</div>
                                                                <p class="product-name search-product-name">
                                                                    {{suggestion?.name}}</p>
                                                                <p class="product-price">
                                                                    <span class="crossed"
                                                                        *ngIf="(suggestion.regular_price != suggestion.final_price)"><del>
                                                                            {{currency}} {{suggestion.regular_price |
                                                                            number:'1.3-3'}}</del></span>
                                                                    <span class="font-light"
                                                                        [ngClass]="(suggestion.regular_price != suggestion.final_price) ? 'red' : 'regular-price'">
                                                                        {{currency}} {{suggestion.final_price |
                                                                        number:'1.3-3'}} </span>
                                                                </p>
                                                            </figcaption>
                                                        </figure>
                                                    </a>
                                                </mat-option>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="suggestions.length && !isLoading && searchInput.value">

                                        <mat-option class="mat-option-styles option-brand"
                                            *ngFor="let suggestion of suggestions" [value]="suggestion">
                                            <i class="fas fa-search"></i>
                                            <span class="">{{suggestion.name}}</span>
                                        </mat-option>
                                    </ng-container>
                                </mat-autocomplete>
                            </form> -->

                            <div [ngClass]="searchBox == true?'searchbox-design-after':''" class="searchbox-design">
                                <form (ngSubmit)="onSubmit()" class="" [formGroup]="searchForm" >
                                    <span class="search-colse search-popup" (click)="searchBoxShow()">
                                        <img src="/assets/icons/cross_popup.svg" alt="">
                                    </span>
                                    <div class="d-flex align-items-center gap-2 input-search-filed mb-2 mb-md-3">
                                        <img class="search-header-icon" src="/assets/icons/search.svg" alt="search" />
                                        <input id="search-input-field" type="search" matInput
                                            [placeholder]="'Search from products' | translate" formControlName="q"
                                            class="search-input" #searchInput
                                            [ngClass]="searchInput.value?'':'search-size'"
                                            (input)="onSearchKeyup($event)">
                                        <span (click)="clearSearchInput()" *ngIf="searchInput.value != ''"
                                            class="search-colses">
                                            <!-- <img class="" src="/assets/icons/close.svg" alt=""> -->
                                            {{'Clear' | translate}}
                                        </span>
                                    </div>

                                    <div [displayWith]="displayFn" *ngIf="searchBoxHasData == true">
                                        <!-- <div *ngIf="isLoading" class="is-loading">
                                                <mat-spinner [diameter]="20"></mat-spinner>
                                            </div> -->
                                        <!-- *ngIf="productSuggestions.length && !isLoading && searchInput.value" -->
                                        <div class="search-menu" *ngIf="productSuggestions?.length > 0">
                                            <div class="sub-heading-search  d-flex align-items-center">
                                                <span *ngIf="isPopularSearch" class="">{{'Most Loved Products'|translate | uppercase}}
                                                </span>
                                                <span *ngIf="!isPopularSearch" class="">{{'Products' |translate |
                                                    uppercase}}
                                                </span>
                                                <!-- <a (click)="onSubmit()" class="">{{'View All' | translate}}</a> -->

                                            </div>
                                            <div class="product-sect">
                                                <div class="search-product">
                                                    <div class="search-product-card"
                                                        *ngFor="let suggestion of productSuggestions">
                                                        <a class="" (click)="routeToDetails(suggestion)">
                                                            <figure>
                                                                <div class="search-product-image">
                                                                    <a href="/product/detail/{{ this.isPopularSearch ? suggestion?.type_id : suggestion?.id }}" (click)="$event.preventDefault()" >
                                                                        <img *ngIf="suggestion?.image" [src]="suggestion?.image" [alt]="suggestion?.name" class="carousel-imgs1 product-normal-image">
                                                                        <img [src]="suggestion?.hover_image" [alt]="suggestion?.name" class="carousel-imgs1 product-hovered-image">
                                                                    </a>
                                                                </div>
                                                                <figcaption>
                                                                    <div class="search-brand-name uppercase"
                                                                        *ngIf="suggestion?.brand">
                                                                        {{suggestion?.brand}}
                                                                    </div>
                                                                    <span class="search-product-name"
                                                                        *ngIf="suggestion?.name">
                                                                        {{suggestion?.name}}
                                                                    </span>
                                                                    <span class="search-product-price">
                                                                        <span class=""
                                                                            [ngClass]="(suggestion.regular_price != suggestion.final_price) ? 'final-price' : 'regular-price'">
                                                                            {{currency}} {{suggestion.final_price |
                                                                            number:'1.3-3'}}
                                                                        </span>
                                                                        <span class="font-light crossed"
                                                                            *ngIf="(suggestion.regular_price != suggestion.final_price)">
                                                                            
                                                                                {{currency}}
                                                                                {{suggestion.regular_price |
                                                                                number:'1.3-3'}}
                                                                        </span>

                                                                    </span>
                                                                    <span class="best-seller">
                                                                        {{setProductBadge(suggestion)}}
                                                                    </span>
                                                                </figcaption>
                                                            </figure>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-grid search-bottom-sec">
                                            <!-- *ngIf="brandsSuggestions.length && !isLoading && searchInput.value" -->
                                            <div class="brand-sec-search d-flex justify-content-between flex-column align-items-start h-100"
                                                *ngIf="brandsSuggestions?.length > 0">
                                                <div>
                                                    <div class="sub-heading-search  d-flex align-items-center">
                                                        <span *ngIf="isPopularSearch" class="">{{'POPULAR BRANDS' |
                                                            translate}}</span>
                                                        <span *ngIf="!isPopularSearch" class="">{{'BRANDS' |
                                                            translate}}</span>
                                                        <!-- <a (click)="onSubmit()" class="">{{'View All' | translate}}</a> -->
                                                    </div>
                                                    <div class="option-brand" *ngFor="let brands of brandsSuggestions"
                                                        (click)="optionSelected(brands,'B')" [value]="brands">
                                                        <!-- <img src="/assets/icons/brand-search.svg" alt=""> -->
                                                        <span class="">{{brands.name}}</span>
                                                    </div>
                                                </div>
                                                <div class="see-all">
                                                    <span>{{'SEE ALL BRANDS' | translate}}</span>
                                                </div>
                                            </div>
                                            <!-- *ngIf="suggestions.length && !isLoading && searchInput.value" -->
                                            <div class="brand-sec-search d-flex justify-content-between flex-column align-items-start h-100"
                                                *ngIf="suggestions?.length > 0">
                                                <div>
                                                    <div class="sub-heading-search  d-flex align-items-center">
                                                        <span *ngIf="isPopularSearch" class="">{{'POPULAR CATEGORIES' |
                                                            translate | uppercase}}</span>
                                                        <span *ngIf="!isPopularSearch" class="">{{'Category' | translate
                                                            | uppercase}}</span>
                                                        <!-- <a (click)="onSubmit()" class="">{{'View All' | translate}}</a> -->
                                                    </div>
                                                    <div class="option-brand" *ngFor="let suggestion of suggestions"
                                                        (click)="optionSelected(suggestion,'C')" [value]="suggestion">
                                                        <!-- <img src="/assets/icons/brand-search.svg" alt=""> -->
                                                        <span class="">{{suggestion.name}}</span>
                                                    </div>
                                                </div>
                                                <div class="see-all">
                                                    <span>{{'SEE ALL CATEGORIES' | translate}}</span>
                                                </div>
                                            </div>
                                        </div>



                                    </div>
                                    <div *ngIf="searchBoxHasData == false" [ngClass]="{'d-none': this.searchString?.length == 0}">
                                        <div class="bag-overflow ">
                                            <div class="Emptybag">
                                              <p class="no-item-text">{{'We have found no result for' | translate}} "{{searchInput?.value}}" </p>
                                              <h3 class="add-items-text">{{'Please try again' | translate}} </h3>
                                            </div>
                                          </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div (click)="searchBoxShow()" *ngIf="searchBox ==true" class="search-overly"></div>
                        <app-header-user-actions class="" *ngIf="navigationStatus" 
                            #ChildCmp>
                        </app-header-user-actions>
                        <!-- <app-header-user-actions class="" *ngIf="navigationStatus" (openSearch)="openSearch($event)"
                            #ChildCmp>
                        </app-header-user-actions> -->
                    </span>
                </li>
            </ul>
        </div>
    </div>


    <!-- <ul *ngIf="headreTopMenuMobileEnable"
        class="headerbottom-small d-flex w-100  d-lg-none justify-content-start align-content-center">
        <li class=" w-50 d-flex py-2 justify-content-center align-items-center" *ngFor="let category of categories">
            <button class="border-0" type="button"
                [ngClass]="(category.id == top_selected_category)?'headerbottom-small-active':''"
                (click)="setCategory(category)">
                {{category.name}}
            </button>
        </li>
    </ul> -->

    <!-- mega menu  -->
    <div class="d-none d-lg-block header-bottom" *ngIf="isEnableHeaderBottom && navigationStatus && showNavitems">
        <div class="container-fluid">
            <div class="bottom-Menu-main">
            <ul class="bottom-Menu">
                
                <li class="active bottom-menus">
                    <a class="bottom-menus-list mouse-active" [routerLink]="'/home'">{{'Home' | translate}}</a>
                </li>
                <li class="mega-drop-down megadropdown bottom-menus" 
                    *ngFor="let categoryLvl2 of category?.subcategories; let i= index;">
                    <a  class="mouse-active bottom-menus-list" (mouseover)="openBigMenu()" (mouseenter)="onHover(categoryLvl2)">
                        <span (click)="routeWithOutSubCat(categoryLvl2)">{{categoryLvl2.name}}</span>
                    </a>

                    <div class="mega-menu mega-menu-list fadeIntop" *ngIf="categoryLvl2.has_subcategory == 'Yes' && manageBigMenu">
                        <div class="row  justify-content-start list-container">
                            <div class="col-lg-6 subSubCategories px-0">
                                <ul class="row mx-0 py-4 p-0 sideborder-main">
                                    <li class="col-6 my-megamenu" *ngFor="let categoryLvl3 of categoryLvl2.subcategories;">
                                        <a (click)="closeBigMenu()" [routerLink]="['/category/',configSettings.cleanUrl(category.name), configSettings.cleanUrl(categoryLvl2.name), configSettings.cleanUrl(categoryLvl3.name), categoryLvl3.id]"
                                            [ngStyle]="(categoryLvl3.has_color != 0) ? {'color': categoryLvl3.category_color} : {'color': '#0a0a0a'}">{{categoryLvl3.name}}</a>
                                    </li>
                                </ul>
                            </div>
                            <!-- {{categoryLvl2.subcategories.navigation_image | json}} -->
                            
                                <div *ngIf="megaMenuBannerImage[0] || megaMenuBannerImage[1]" class=" col-6 d-flex gap-3 justify-content-center align-items-center mx-0 py-4 px-3 subSubCategoryImages">
                                    <!-- <div class="w-1/2" *ngIf="megaMenuBannerImage[0]">
                                        <div class="mega-image">
                                            <img src="{{megaMenuBannerImage[0]}}" alt="">
                                        </div>
                                        <div class="callToAction"
                                            [routerLink]="['/category/',configSettings.cleanUrl(category.name), configSettings.cleanUrl(categoryLvl2.name), configSettings.cleanUrl(megaMenuBannerText[0]), megaMenuBannerId[0]]">
                                            <p>{{megaMenuBannerText[0]}}</p>
                                            <span>Shop now</span>
                                        </div>
                                    </div> -->
                                    <div (click)="closeBigMenu()" [routerLink]="['/category/',configSettings.cleanUrl(category.name), configSettings.cleanUrl(categoryLvl2.name), configSettings.cleanUrl(megaMenuBannerText[0]), megaMenuBannerId[0]]" class="image-width-manage" *ngIf="megaMenuBannerImage[0]">
                                        <div class="mega-image">
                                            <img src="{{megaMenuBannerImage[0]}}" alt="">
                                        </div>
                                        <div class="callToAction"
                                            >
                                            <!-- <p>{{megaMenuBannerText[0]}}</p> -->
                                            <span  class="d-flex align-items-center justify-content-center"><span>{{'Shop now' | translate}}</span>
                                                <img src="../../assets/icons/right-arrow.svg" alt="">
                                            </span>
                                        </div>
                                    </div>
                                </div>
                       
                        </div>
                    </div>
                    <div class="mega-menu-overly"></div>
                </li>
                <li class="bottom-menus boutique">
                    <a [routerLink]="['/brands']" class="mouse-active bottom-menus-list">
                        <!-- {{"Boutique’s/Brands" | translate}} -->
                        {{"Brands" | translate}}</a>
                </li>
            </ul>
            </div>
        </div>
    </div>

    <!-- side-bar -->

    <div class="d-block d-lg-none sidemenu" [ngClass]="isSideMenuOpen == true?'sidebar-width':''">
       
            <div *ngIf="isSideMenuOpen == true" class="sidemenu-header">
                <ul class="sidemenu-header d-flex justify-content-end align-items-center py-1 px-3">
                    <li>
                        <img (click)="openNav()" src="/assets/icons/cross_popup.svg" alt="close" class="sidemenu-close">
                    </li>
                </ul>
                <ul class="pb-2">
                    <li class="tabs-button d-flex py-3 w-100" *ngIf="userSession?.id">
                        <a [routerLink]="['/my-account']" (click)="openNav()"
                            class="font-black maincategorymobile my-account-mbl d-flex justify-content-between align-items-center w-100">
                            <div>
                                <p class="user-name">
                                    {{userSession.first_name + ' ' +userSession.last_name}}
                                </p>
                                <p>
                                    {{userSession.email}}
                                </p>
                            </div>
                            <img src="../../assets/images/arrow-left-5-svgrepo-com.svg" alt="">
                        </a>
                    </li>
    
                    <li class="tabs-button d-flex py-3 w-100" *ngIf="!userSession?.id">
                        <a [routerLink]="['/login']" class="font-black maincategorymobile d-flex justify-content-between align-items-center w-100" (click)="openNav()">
                            <p>
                                Sign in
                            </p>
                            <img src="../../assets/images/arrow-left-5-svgrepo-com.svg" alt="">
                        </a>
                    </li>
                </ul>
            </div>
            <ul *ngIf="isSideMenuOpen == true" class="main-nav" id="accordion-ul">
                
                <li class="nav-height-notlogin" [ngClass]="userSession?.id?'nav-height-manage':''">
                    <ul class="main-nav">
                        <li class="tabs-sec w-100 position-relative">
                            <ul class="header-tabs header-tabs-btn w-100 py-3" id="myTab" role="tablist">
                                <li class="header-tabs-items w-100" *ngFor="let category of categories">
                                    <span *ngIf="allCatData.apply_default_category==1">
                                        <button data-bs-toggle="collapse" data-bs-target="#collapse" aria-expanded="false"
                                        aria-controls="collapse" class="tabs-button tabs-button-name side-tabs w-100"
                                        type="button" [ngClass]="(category.id == allCatData.default_root_category)?'active':''"
                                        (click)="setCategory(category)">
                                        {{category.name}}
                                    </button>
                                    </span>
                                    <span *ngIf="allCatData.apply_default_category!=1">
                                        <button data-bs-toggle="collapse" data-bs-target="#collapse" aria-expanded="false"
                                        aria-controls="collapse" class="tabs-button tabs-button-name side-tabs w-100"
                                        type="button" [ngClass]="(category.id == top_selected_category)?'active':''"
                                        (click)="setCategory(category)">
                                        {{category.name}}
                                    </button>
                                    </span>
                                  
                                    <!--category.id and subcategories index 4 accordion-added  -->


                                </li>
                            </ul>
                            <!-- <span class="position-absolute cross-close">
                    <img (click)="openNav()" src="/assets/icons/cross_popup.svg" alt="close"
                        class="sidemenu-close">
                </span> -->
                            <ul class="bottom-Menu w-100 menu-height-befor" [id]="'accordionExample'+category.id"
                                [ngClass]="category.id == top_selected_category?'menu-hide':''">
                                <li class="w-100 accordion-item  px-4"
                                    *ngFor="let categoryLvl2 of category.subcategories; let i= index;"
                                    [class.dropdown]="categoryLvl2.has_subcategory == 'Yes'"
                                    id="category-{{categoryLvl2.id}}">
                                    <div [id]="'headingOne'+category.id+i">
                                        <button  class="accordion-button accordion-my-button collapsed" 
                                            type="button"
                                            data-bs-toggle="collapse"
                                            [attr.data-bs-target]="'#collapseOne'+category.id+i" 
                                            aria-expanded="false"
                                            [attr.aria-controls]="'collapseOne'+category.id+i"
                                            [class.accordion-button]="categoryLvl2.has_subcategory == 'Yes'"
                                            (click)="openSideMenu('category-'+categoryLvl2.id)"
                                            [routerLink]="(categoryLvl2.has_subcategory != 'Yes')?['/category/',configSettings.cleanUrl(categoryLvl2.name), categoryLvl2.id]:[]">
                                            <span>{{categoryLvl2.name}}</span>
                                        </button>
                                    </div>

                                    <div [id]="'collapseOne'+category.id+i" class="accordion-collapse collapse"
                                        [attr.aria-labelledby]="'headingOne'+category.id+i"
                                        [attr.data-bs-parent]="'#accordionExample'+category.id">
                                        <div class="px-3 pb-2">
                                            <ul class="" *ngIf="categoryLvl2.has_subcategory == 'Yes'"
                                                [id]="'accordion2'+category.id+categoryLvl2.id">
                                                <!-- <li>
                                                    <a class="menusubcategory" (click)="setCategory(categoryLvl2)"
                                                        [ngStyle]="(categoryLvl2.has_color != 0) ? {'color': categoryLvl2.category_color} : {'color': '#0a0a0a'}">
                                                        All {{categoryLvl2.name}}
                                                    </a>
                                                </li> -->
                                                <li class="borderbottom"
                                                    *ngFor="let categoryLvl3 of categoryLvl2.subcategories; let i = index;"
                                                    [class.dropdown]="categoryLvl3.has_subcategory == 'Yes'"
                                                    id="category-{{categoryLvl3.id}}">

                                                    <div [id]="'headingTwo'+categoryLvl2.id+i">
                                                        <button
                                                            class="accordion-button accordion-custom-button collapsed"
                                                            type="button" data-bs-toggle="collapse"
                                                            [attr.data-bs-target]="'#collapseTwo'+categoryLvl2.id+i"
                                                            aria-expanded="false"
                                                            [attr.aria-controls]="'collapseTwo'+categoryLvl2.id+i"
                                                            [class.accordion-button]="categoryLvl3.has_subcategory == 'Yes'"
                                                            [routerLink]="(categoryLvl3.has_subcategory != 'Yes')?['/category/', configSettings.cleanUrl(categoryLvl2.name), configSettings.cleanUrl(categoryLvl3.name), categoryLvl3.id]:[]"
                                                            (click)="openSideMenu('category-'+categoryLvl3.id)">
                                                            {{categoryLvl3.name}}
                                                        </button>
                                                    </div>

                                                    <div [id]="'collapseTwo'+categoryLvl2.id+i"
                                                        class="accordion-collapse collapse"
                                                        [attr.aria-labelledby]="'headingTwo'+categoryLvl2.id+i"
                                                        [attr.data-bs-parent]="'#accordion2'+category.id+categoryLvl2.id">

                                                        <ul *ngIf="categoryLvl3.has_subcategory == 'Yes'"
                                                            [id]="'accordion2'+categoryLvl2.id+categoryLvl3.id">

                                                            <li *ngFor="let categoryLvl4 of categoryLvl3.subcategories;"
                                                                id="category-{{categoryLvl4.id}}"
                                                                [class.dropdown]="categoryLvl4.has_subcategory == 'Yes'">

                                                                <div [id]="'headingThree'+categoryLvl3.id+i">
                                                                    <button class=""
                                                                        class="accordion-button accordion-custom-button collapsed"
                                                                        type="button" data-bs-toggle="collapse"
                                                                        [attr.data-bs-target]="'#collapseThree'+categoryLvl3.id+i"
                                                                        aria-expanded="false"
                                                                        [attr.aria-controls]="'collapseThree'+categoryLvl3.id+i"
                                                                        [ngStyle]="(categoryLvl4.has_color != 0) ? {'color': categoryLvl4.category_color} : {'color': '#0a0a0a'}"
                                                                        [routerLink]="(categoryLvl4.has_subcategory != 'Yes')?['/category/', configSettings.cleanUrl(categoryLvl2.name), configSettings.cleanUrl(categoryLvl3.name), configSettings.cleanUrl(categoryLvl4.name), categoryLvl4.id]:[]"
                                                                        [class.accordion-button]="categoryLvl4.has_subcategory == 'Yes'"
                                                                        (click)="openSideMenu('category-'+categoryLvl4.id)">{{categoryLvl4.name}}
                                                                    </button>
                                                                </div>

                                                                <div [id]="'collapseThree'+categoryLvl3.id+i"
                                                                    class="accordion-collapse collapse"
                                                                    [attr.aria-labelledby]="'headingThree'+categoryLvl3.id+i"
                                                                    [attr.data-bs-parent]="'#accordion3'+categoryLvl2.id+categoryLvl3.id">

                                                                    <ul *ngIf="categoryLvl4.has_subcategory == 'Yes'">

                                                                        <li *ngFor="let categoryLvl5 of categoryLvl4.subcategories;"
                                                                            id="category-{{categoryLvl5.id}}"
                                                                            [class.dropdown]="categoryLvl5.has_subcategory == 'Yes'">

                                                                            <a [routerLink]="(categoryLvl5.has_subcategory != 'Yes')?['/category/', configSettings.cleanUrl(categoryLvl2.name), configSettings.cleanUrl(categoryLvl3.name), configSettings.cleanUrl(categoryLvl4.name), configSettings.cleanUrl(categoryLvl5.name), categoryLvl5.id]:[]"
                                                                                [ngStyle]="(categoryLvl5.has_color != 0) ? {'color': categoryLvl5.category_color} : {'color': '#0a0a0a'}"
                                                                                (click)="openSideMenu('category-'+categoryLvl5.id)">{{categoryLvl5.name}}
                                                                            </a>

                                                                            <ul
                                                                                *ngIf="categoryLvl5.has_subcategory == 'Yes'">
                                                                                <li
                                                                                    *ngFor="let categoryLvl6 of categoryLvl5.subcategories;">
                                                                                    <a
                                                                                        [routerLink]="['/category/', configSettings.cleanUrl(categoryLvl2.name), configSettings.cleanUrl(categoryLvl3.name), configSettings.cleanUrl(categoryLvl4.name), configSettings.cleanUrl(categoryLvl5.name), configSettings.cleanUrl(categoryLvl5.name), categoryLvl6.id]">{{categoryLvl6.name}}
                                                                                    </a>
                                                                                </li>
                                                                            </ul>

                                                                        </li>
                                                                    </ul>

                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>

                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                </li>
                            </ul>
                        </li>
                        <li class="w-100 tabs-button d-flex py-3 boutique">
                            <a [routerLink]="'/brands'" class="font-black maincategorymobile" (click)="openNav()">
                                <!-- {{'Boutique’s/Brands' | translate}} -->
                                {{'Brands' | translate}}
                            </a>
                        </li>
                        <li class="w-100 d-flex tabs-button d-flex py-3 w-100">
                            <ul class="side-menu-top align-items-center d-flex flex-column w-100">
                                <li class="top-header-menu dropdown store-dropdown">
                                    <a href="/" id="storeDropDown"
                                        class="d-flex  justify-content-start align-content-center gap-3"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <img *ngIf="store" src="{{store.flag}}" alt="flag">
                                        <div class="heading-small-menu">
                                            <p>{{'Shiping To' | translate}}</p>
                                            <span *ngIf="lang=='en'" class="store-name">{{store?.name_en}}</span>
                                            <span *ngIf="lang=='ar'" class="store-name">{{store?.name_ar}}</span>
                                            <span class="expand-toggle"></span>

                                        </div>
                                    </a>
                                    <div class="dropdown-menu" aria-labelledby="storeDropDown">
                                        <span class="position-absolute cross-close-dropdown">
                                            <img src="/assets/icons/cross_popup.svg" alt="close" class="sidemenu-close">
                                        </span>


                                        <ul class="container-fluid p-0 pt-5">
                                            <li class="counter-select-text">
                                                <p class="">
                                                   {{'To get the best of THE WISHLIST, make sure you select your location  from the list below' | translate}}
                                                </p>
                                            </li>
                                            <li *ngIf="store">
                                                <a *ngFor="let store of stores" [attr.data-currency]="store.currency_en"
                                                    (click)="changeStore($event, store.id)">
                                                    <img class="FlagTop" [src]="store.flag" alt="flag">
                                                    <span class="store-name font-small">
                                                        <span *ngIf="lang=='en'"
                                                            class="store-name">{{store?.name_en}}</span>
                                                        <span *ngIf="lang=='ar'"
                                                            class="store-name">{{store?.name_ar}}</span>
                                                    </span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>

                                </li>
                            </ul>
                        </li>

                        <li class="top-header-menu tabs-button d-flex py-3 w-100">
                            <a class="d-flex justify-content-start align-content-center gap-3 language-change lang-padding"
                                href="javascript:;" (click)="OpenChangePopup()">
                                <img alt="language" src="../../assets/icons/language-black.png">
                                <div class="heading-small-menu">
                                    <p>{{'Change Language' | translate}}</p>
                                    <span>
                                        {{(lang == 'ar') ? 'English' : 'العربية'}}
                                    </span>
                                </div>
                            </a>
                        </li>

                    </ul>
                </li>

                <div class="change-popup-overly" *ngIf="ChangePopup" (click)="OpenChangePopup()"></div>
                <div class="change-popup" *ngIf="ChangePopup">
                    <p class="pb-3">{{'Are you sure you want to change your language to Arabic?' | translate}}</p>
                    <div class="d-flex gap-2  m-0">
                        <div class="p-0 w-50">
                            <button type="button" class="custom-button btn btn-bgnone btn-block"
                                (click)="OpenChangePopup()">{{'Cancel' |
                                translate}}</button>
                        </div>
                        <div class="p-0 w-50">
                            <button type="button" class="custom-button btn btn-bg btn-block"
                                (click)="changeLanguage($event)">{{'OK' |
                                translate}}</button>
                        </div>
                    </div>
                </div>
                <!-- <li class="tabs-button d-flex py-3 w-100" *ngIf="userSession?.id">
                    <a [routerLink]="['/my-account']" (click)="openNav()" class="font-black maincategorymobile px-2">
                        {{'My Account'|translate}}
                    </a>
                </li> -->
                <!-- <li class="myaccount-side tabs-button d-flex">
                <app-side-user-actions class="px-2"></app-side-user-actions>
            </li> -->
            </ul>
    </div>
    <div (click)="closeNav()" *ngIf="isSideMenuOpen == true" class="d-block d-lg-none overly--sidebar-div"></div>
</nav>