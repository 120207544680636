<div class="position-div">
  <ul class="cart-right-Top d-flex">
    <li class="header-right-icon" *ngIf="userSession.id">
      <a class="position-relative" [routerLink]="['/wishlist']">
        <img src="../../assets/icons/wishlist.svg" class="header-right-cartIcon position-relative" alt="heart-icon" />
        <span *ngIf="wishlist_count > 0" class="cart-badges position-absolute">{{wishlist_count}}</span>
      </a>
    </li>
    <li class="header-right-icon" [routerLink]="['/login']" *ngIf="!userSession.id">
      <a class="position-relative">
        <img src="../../assets/icons/wishlist.svg" class="header-right-cartIcon" alt="heart-icon" />
      </a>
    </li>
    <li class="header-right-icon">

      <a (click)="toggleSidenav()" class="position-relative">
        <img src="../../assets/icons/bag.svg" class="header-right-cartIcon" alt="cartIcon" />
        <span *ngIf="cartCount > 0" class="cart-badges position-absolute">{{cartCount}}</span>
      </a>
    </li>
    <!-- <li class="search-trigger d-block d-sm-block d-md-block d-xl-none ">
    <a (click)="openSearchClick()" id="search-trigger">
      <img src="/assets/ArtWork/Search@2x.png" class="mobileSearch" alt="mobile">
    </a>
  </li> -->
  </ul>



  <!-- Modal -->
  <!-- my-bag-overly -->
  <div (click)="toggleSidenav()" *ngIf="sidenavCLosed" class="overly--cart-div"></div>
  <!-- my-bag -->
  <div id="mySidenavshop" #mySidenavshop [ngClass]="sidenavCLosed == true?'cart-sidebarOpen':''" class="mySidenavshop">
    <div *ngIf="sidenavCLosed == true" class="position-relative d-flex flex-column h-100">
      <div class="SHOPPING-heading w-100 mb-3" [ngStyle]="{'border-bottom': cart?.items.length > 0 ? '': 'unset'}">
        <div class="cart-colse">
          <a href="javascript:void(0)" class="closebtn" (click)="toggleSidenav()">
            <img src="../../assets/icons/cross_popup.svg" alt="close">
          </a>
        </div>
        <p *ngIf="cart?.items.length > 0 && !skeletonLoader">{{"You have"| translate}} <span class="cart">{{cart?.items.length}}</span> {{"items in your Shopping Bag"| translate}}</p>
      </div>
      <!-- <div class="col-2 close-arabic" *ngIf="lang == 'ar' && !isMobile"><a href="javascript:void(0)" class="closebtn"
        (click)="toggleSidenav()">&times;</a>
    </div> -->
    <!-- skeleton loader -->
    <div class="bagover w-100" *ngIf="skeletonLoader">
      <div class="bag-overflow" >
        <div class="cart-mainbag" *ngFor="let item of [1,2]; let i = index;">
          <div class="d-grid bag-item-box">
            <div class="bag-image items-height-skeleton">
              <ngx-skeleton-loader class="height-fix"></ngx-skeleton-loader>   

              <!-- <figure>
                <div class="image-wrapper">
                  <img src="{{item?.image}}">
                </div>
              </figure> -->
              <!-- <div class="pre-order-badge mb-0" *ngIf="(item.is_preorder == 1)">{{'Pre Order'}}</div> -->
            </div>

            <div class="bag-items-details ">
              <div class="w-75">
              <ngx-skeleton-loader count="2" ></ngx-skeleton-loader>  
              </div>
              <div class="d-flex gap-1 align-items-center">
                <ngx-skeleton-loader class="w-25"  ></ngx-skeleton-loader> 
                <ngx-skeleton-loader class="w-50"  ></ngx-skeleton-loader>   
              </div>
              <!-- <div class="d-flex gap-4 align-items-center">
                <ngx-skeleton-loader class="w-25"  ></ngx-skeleton-loader> 
                <ngx-skeleton-loader class="w-25"  ></ngx-skeleton-loader>   
              </div> -->

              <div class="d-flex gap-4 align-items-center">
                <ngx-skeleton-loader class="w-25"  ></ngx-skeleton-loader> 
                <ngx-skeleton-loader class="w-25"  ></ngx-skeleton-loader>   
              </div>
              

              <div class="d-flex gap-4 align-items-center">
              <p class="item-save" class="w-50">
                <!-- {{'MOVE TO WISHLIST' | translate}} -->
              <ngx-skeleton-loader ></ngx-skeleton-loader>  

              </p>
              <p class="item-delete" class="w-25">
                <!-- {{'DELETE' | translate}} -->
              <ngx-skeleton-loader ></ngx-skeleton-loader>  

              </p>
            </div>

            </div>

          </div>
          <!-- <div class="row">
        <div class="col-3">
          <div class="BAG-IMAGE">
            <img src="{{item.image}}">
            <div class="pre-order-badge mb-0" *ngIf="(item.is_preorder == 1)">{{'Pre Order'}}</div>
          </div>
        </div>
        <div class="col-6">
          <div class="bag-details">
            <h4>{{item.brand_name | uppercase}}</h4>
            <p>{{item.name}}</p>
            <p class="color-top" *ngIf="item.configurable_option">
              <span *ngFor="let option of item.configurable_option; let j = index;" class="configOptions">
                <span *ngIf="option" class="">
                  <span class="font-weight-bold">{{option.type}}: </span>
                  <span class=""
                    [hidden]="showOptionSelect[i] && j === (item.configurable_option.length - 1)">{{option.attributes[0].value}}</span>
                </span>
              </span>
            </p>

          </div>
        </div>
        <div class="col-3 quantityPrice">
          <div class="bag-price">
            <h3>{{currency}} {{item.final_price | number:'1.3-3'}}</h3>
            <div class="bag-select">
              <select class="select-input" (change)="onChangeQuantity($event.target, i)"
                [(ngModel)]="selectedQuantity[i]">
                <option value="{{option.value}}" *ngFor="let option of quantities[i]; let z = index;">
                  {{option.value}}</option>
              </select>
            </div>
            <span class="Remove" (click)="deleteFromCart(item, i)"><img src="/assets/ArtWork/delete.png"> {{'Remove'
              | translate}}</span>
          </div>
        </div>
      </div> -->
        </div>
      </div>    
    </div>
    <div class="bag-bottom bag-bottom-skeleton w-100" *ngIf="skeletonLoader">
      <div class="bag-bottom-details " >
        <!-- <ul class="d-flex justify-content-between align-items-center pb-1" *ngIf="shippingCost > 0">
          <li class="CHARGE">{{'ITEM TOTAL' | translate}}
          </li>
          <li class="TOTALmainprice CHARGE">{{currency}} {{cartValue | number:'1.3-3'}}</li>
        </ul>
        <ul class="d-flex justify-content-between align-items-center pb-1" *ngIf="shippingCost > 0">
          <li class="CHARGE">{{'DELIVERY' | translate}}</li>
          <li class="TOTALmainprice CHARGE">{{currency}} {{shippingCost | number:'1.3-3'}}</li>
        </ul>
        <ul class="d-flex justify-content-between align-items-center pb-1">
          <li>{{'TOTAL' | translate}} </li>
          <li class="TOTALmainprice">{{currency}} {{((+cartValue) + (+shippingCost)) | number:'1.3-3'}}</li>
        </ul>
        <p class="pb-3">VAT INCLUDED</p> -->
        <ul class="d-flex justify-content-between align-items-center pb-1">
          <ngx-skeleton-loader class="w-25 gap-3"  ></ngx-skeleton-loader>   
          <ngx-skeleton-loader class="w-25 "  ></ngx-skeleton-loader>   

          <!-- <li>{{'TOTAL' | translate}} </li>
          <li class="TOTALmainprice">{{currency}} {{((+cartValue) + (+shippingCost)) | number:'1.3-3'}}</li> -->
        </ul>
        <div class="button-height">
          <ngx-skeleton-loader class="mange-button" count="2" ></ngx-skeleton-loader>   
        </div>

        <!-- <p class="pb-3">VAT INCLUDED</p> -->
        <!-- <button type="button" class="go-bag" (click)="toggleSidenav()" [routerLink]="['/cart']">
            {{'GO TO BAG' | translate}}
        </button> -->
        <!-- <button type="button" class="go-checkout" *ngIf="!userSession.id" data-toggle="modal" data-target="#loginModal"
          (click)="loginFromCheckout()">{{'CHECKOUT' | translate}}
        </button>
        <button type="button" class="go-checkout" [routerLink]="['/checkout']" *ngIf="userSession.id">
          {{'CHECKOUT' | translate}} -->
        <!-- </button> -->
      </div>
    </div>
    <!-- skeleton loader -->

      <div class="bagover w-100" [ngClass]="cart.items.length == 0||cart == undefined?'bagempty':''" *ngIf="cart && !skeletonLoader">
        <div class="bag-overflow" *ngIf="cart.items.length > 0">
          <div class="cart-mainbag" *ngFor="let item of cart.items; let i = index;">
            <div class="d-grid bag-item-box">
              <div class="bag-image">
                <figure>
                  <div class="image-wrapper">
                    <img src="{{item?.image}}">
                  </div>
                </figure>
                <div class="pre-order-badge mb-0" *ngIf="(item.is_preorder == 1)">{{'Pre Order'}}</div>
              </div>

              <div class="bag-items-details">
                <div>
                  <p class="brand-name">{{item.brand_name | uppercase}}</p>
                  <p class="item-name">{{item?.name}}</p>
                </div>
                <p class="item-price">{{currency}} {{item?.final_price | number:'1.3-3'}}</p>
                <!-- <ul class="item-color-size d-flex " *ngIf="item.configurable_option">
                  <li *ngFor="let option of item.configurable_option; let j = index;" class="configOptions">

                    <span *ngIf="option" class=""
                      [hidden]="showOptionSelect[i] && j === (item.configurable_option.length - 1)">{{option.attributes[0].value}}
                    </span>
                  </li>
                </ul> -->
                <p class="item-color-size d-flex gap-2  align-items-center" *ngIf="userId!=undefined">
                  <span>{{item?.configurable_option[1]?.attributes[0]?.value}} </span>
                  <span *ngIf="item?.configurable_option[0]?.attributes[0]?.value"> | </span>
                  <span>{{ item?.configurable_option[0]?.attributes[0]?.value }}</span>
                </p>

                <p class="item-color-size d-flex gap-2  align-items-center" *ngIf="userId==undefined">
                  <span>{{item?.configurable_option[1]?.attributes[0]?.value}} </span>
                  <span *ngIf="item?.configurable_option[0]?.attributes[0]?.value"> | </span>
                  <span>{{ item?.configurable_option[0]?.attributes[0]?.value }}</span>
               </p>
                <!-- <div class="bag-select">
              <select class="select-input" (change)="onChangeQuantity($event.target, i)"
                [(ngModel)]="selectedQuantity[i]">
                <option value="{{option.value}}" *ngFor="let option of quantities[i]; let z = index;">
                  {{option.value}}</option>
              </select>
            </div> -->
                <div class="quntity-sec">
                  <button class="cursor-pointer  quntity-btn" name="minus" type="button" [ngClass]="selectedQuantity[i]>1 ? ' ':'disableClick' " 
                    (click)="updateCart(-1,i,item?.remaining_quantity)">
                    <img src="../../assets/icons/Minus-new.svg" alt="" class="">
                  </button>

                  <span class="quantity-input font-medium text-center px-4">{{selectedQuantity[i]}}</span>
                  <button class="cursor-pointer  quntity-btn" name="plus" type="button" (click)="updateCart(1,i,item?.remaining_quantity)">
                    <img src="../../assets/icons/Plus-new.svg" alt="" class="">
                  </button>
                </div>
                <p class="low-stock" *ngIf="item?.remaining_quantity?.length <=4">{{'Low Stock, only'|translate}} {{ item?.remaining_quantity }} {{'left'|translate}}</p>
                <div class="d-flex gap-4 align-items-center">
                <p class="item-save" (click)="saveForLater(item, i)">
                  {{'MOVE TO WISHLIST' | translate}}
                </p>
                <p class="item-delete" (click)="deleteFromCart(item, i)">
                  {{'DELETE' | translate}}
                </p>
              </div>

              </div>

            </div>
            <!-- <div class="row">
          <div class="col-3">
            <div class="BAG-IMAGE">
              <img src="{{item.image}}">
              <div class="pre-order-badge mb-0" *ngIf="(item.is_preorder == 1)">{{'Pre Order'}}</div>
            </div>
          </div>
          <div class="col-6">
            <div class="bag-details">
              <h4>{{item.brand_name | uppercase}}</h4>
              <p>{{item.name}}</p>
              <p class="color-top" *ngIf="item.configurable_option">
                <span *ngFor="let option of item.configurable_option; let j = index;" class="configOptions">
                  <span *ngIf="option" class="">
                    <span class="font-weight-bold">{{option.type}}: </span>
                    <span class=""
                      [hidden]="showOptionSelect[i] && j === (item.configurable_option.length - 1)">{{option.attributes[0].value}}</span>
                  </span>
                </span>
              </p>

            </div>
          </div>
          <div class="col-3 quantityPrice">
            <div class="bag-price">
              <h3>{{currency}} {{item.final_price | number:'1.3-3'}}</h3>
              <div class="bag-select">
                <select class="select-input" (change)="onChangeQuantity($event.target, i)"
                  [(ngModel)]="selectedQuantity[i]">
                  <option value="{{option.value}}" *ngFor="let option of quantities[i]; let z = index;">
                    {{option.value}}</option>
                </select>
              </div>
              <span class="Remove" (click)="deleteFromCart(item, i)"><img src="/assets/ArtWork/delete.png"> {{'Remove'
                | translate}}</span>
            </div>
          </div>
        </div> -->
          </div>
        </div>
        <div class="bag-overflow " *ngIf="cart.items.length == 0 && !skeleton">
          <div class="Emptybag ">
            <!-- <img src="assets/ArtWork/emptymincart.png"> -->
            <p class="no-item-text">{{'Your Bag is Empty' | translate}} </p>
            <h3 class="add-items-text">{{'Items added to your bag will show up here' | translate}} </h3>
            <p class="add-item-route" routerLink="/" >{{'Shop Now' | translate}} </p>
          </div>
        </div>
      </div>
      <div *ngIf="(cart == undefined) && !skeletonLoader">
        <div class="bag-overflow ">
          <div class="Emptybag ">
            <!-- <img src="assets/ArtWork/emptymincart.png"> -->
            <p class="no-item-text">{{'Your Bag is Empty' | translate}} </p>
            <h3 class="add-items-text">{{'Items added to your bag will show up here' | translate}} </h3>
            <p class="add-item-route" routerLink="/" >{{'Shop Now' | translate}} </p>
          </div>
        </div>
      </div>
      <div class="bag-bottom w-100" *ngIf="cart && sidenavCLosed && !skeletonLoader">
        <div class="bag-bottom-details" *ngIf="cart.items.length > 0">
          <ul class="d-flex justify-content-between align-items-center pb-1">
            <li>{{'TOTAL' | translate}} </li>
            <li class="TOTALmainprice">{{currency}} {{(cartValue) | number:'1.3-3'}}</li>
          </ul>
          <button type="button" class="go-bag button_hover" (click)="toggleSidenav()" [routerLink]="['/cart']">
              {{'GO TO BAG' | translate}}
          </button>
          <button type="button" class="go-checkout white_button_hover" *ngIf="!userSession?.id" data-toggle="modal" data-target="#loginModal"
            (click)="loginFromCheckout()">{{'CHECKOUT' | translate}}
          </button>
          <button type="button" class="go-checkout white_button_hover" (click)="proceedToShipping()" *ngIf="userSession?.id" >
            {{'CHECKOUT' | translate}}
          </button>
        </div>
      </div>
    </div>
  </div>
  
</div>