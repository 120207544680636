import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Brands } from "../models/brands";
import { Category } from "../models/category";
import { ConfigService } from "../config/config.service";
import { first } from "rxjs/operators";
import { ConfigSettings } from "../config/config.settings";
import { FooterService } from '../config/footer.service';
import { listStagger } from '../animations';
import { AnalyticsService } from '../services/analytics.service';
import { NavbarService } from '../config/navbar.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ChangeMetaService } from '../services/change-meta-service.service';
import { ToastrService } from 'ngx-toastr';
import { WishlistService } from '../config/wishlist.service';
import { UserService } from '../config/user.service';
import { User } from '../models/user';

@Component({
  selector: 'app-my-brand-list',
  templateUrl: './my-brand-list.component.html',
  styleUrls: ['./my-brand-list.component.scss'],
  animations: [listStagger]
})
export class MyBrandListComponent {
  brands: Brands[] = [];
  lang: string;
  groups: string[] = [];
  divider: number;
  dataAvailable = false;
  isMobile: boolean = false
  headerheight: any;
  title: any;
  alpha: any;
  toggleAlphabet: any;
  type: "B" | "D" = "D";
  search: any;
  name: string;
  toSearch: boolean = true;
  alphaCheck: boolean;
  catCheck: boolean;
  searchCheck: boolean;
  serviceCategory: any = [];
  category: any;
  categories: any = []
  user: User = new User();
  originalBrands: any[]; // to add a property to store the original brands data

  numbersCount = Array
  englishNumberArr: Array<string> = []
  englishAlphabetArr: Array<string> = []
  categoryId: any
  subCategoryType: 1 | 2 | 3 = 1
  searchData: any

  numbersArr: any = [];
  alphabetFirstChars: any = [];
  numberFirstChars: any = [];
  brandsDataWithAlphabets: any = [];
  brandsDataWithNumbers: any = [];
  yOffset: number
  selectedCategory: any;
  skeltonTheme:any;
  skeltonThemeSide:any;
  skeletonLoader:boolean=true;

  @ViewChild('scrollContainer') scrollContainer: ElementRef;

  constructor(
    private configService: ConfigService,
    public configSettings: ConfigSettings,
    protected footerService: FooterService,
    private navbarService: NavbarService,
    private changeMeta: ChangeMetaService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private wishlistService: WishlistService,
    private toastr: ToastrService,
    private userService: UserService,
  ) {
    this.isMobile = window.innerWidth < 600;
  }

  ngOnInit() {
    this.skeletonLoader=true;
    this.lang = this.configSettings.getLang();
    this.navbarService.isEnableHeaderBottomMobileAction(false);
    this.userService.getUserSession().then(response => {      
      if (response !== false) {
        this.user = response;
        this.categoryId = 1
        this.brandType('D');    
      }
    });
    this.yOffset = this.isMobile ? 80 : 200;
    this.skeltonTheme = this.setThemeLogic();
    this.skeltonThemeSide = this.setThemeLogicSide();
  }

  setTheme(heightMobile, heightDesktop) {
    const commonTheme = {
        'border-radius': '5px',
        'animation-duration': '2s'
    };
    const specificTheme = {
        // 'height': this.isMobile ? heightMobile : heightDesktop
    };
    return {...commonTheme, ...specificTheme};
}

setThemeLogic() {
  return this.setTheme('150px', '150px');
}

setThemeLogicSide() {
    return this.setTheme('30px', '30px');
}

  ngAfterViewInit() {
    // this.configSettings.load.subscribe(loading => {
    //   if (loading == false) {
    //     this.changeMeta.updateMetaTags('Brands', "Everything on your Wish List!' Discover and shop a variety of pieces ranging from clothes, bags, shoes, to accessories and more.")
    //   }
    // });

    // to set default category
    this.selectedCategory = {
      category_brand_id: '',
      name: 'All'
    }

  }


  getBrands() {
    this.brands = []
    const params = {
      lang: this.lang,
      type: this.type,
      user_id: this.user.id,
      category_id: this.categoryId ? this.categoryId : ''
    };
    this.configService.readRequest('all-brands', params)
      .subscribe(response => {
        if (response.body.data) {
         
          // this.brands = <Brands[]>response.body.data.brands;
          this.brands = <Brands[]>response.body.data.brands.filter(brands => brands.is_in_wishlist === 1);          
          this.brands.sort((b1, b2): number => {
            if (b1.name.toLowerCase() < b2.name.toLowerCase()) return -1;
            if (b1.name > b2.name) return 1;
          });
          

          this.categories = response.body.data.categories
          this.sortBrandData();

          this.generateAlphabet()
          this.setNumbersToAlphabetArr()

          // if (this.subCategoryType == 2) {
          this.groups = [...new Set(this.alphabetFirstChars)] as string[];
          this.brands = this.brandsDataWithAlphabets
          // }
          this.originalBrands = [...this.brands];
          this.cdr.detectChanges()
        }
        this.dataAvailable = true;
        if(this.brands.length!=0){
          this.skeletonLoader=false;
        }
        else if(this.brands.length==0){
          this.skeletonLoader=false;
        }
        setTimeout(() => { this.footerService.toggleFooter(true) }, 300);
      },
        error => {
          console.log(error);
        },
      );
  }
  sortBrandData() {
    const alphabetFirstChars = [];
    const numberFirstChars = [];
    const brandsDataWithAlphabets = [];
    const brandsDataWithNumbers = [];

    this.brands.forEach(brand => {
      let firstChar = brand.name.charAt(0);

      // to check if the firstChar is an alphabet
      if ((firstChar >= 'A' && firstChar <= 'Z') || (firstChar >= 'a' && firstChar <= 'z')) {
        if (!alphabetFirstChars.includes(firstChar)) {
          alphabetFirstChars.push(firstChar);
        }
        brandsDataWithAlphabets.push(brand);
      }

      // to check if the firstChar is a number
      if (firstChar >= '0' && firstChar <= '9') {
        if (!numberFirstChars.includes(firstChar)) {
          numberFirstChars.push(firstChar);
        }
        brandsDataWithNumbers.push(brand);
      }
    });

    this.alphabetFirstChars = alphabetFirstChars.sort()
    this.brandsDataWithAlphabets = brandsDataWithAlphabets
    this.numberFirstChars = numberFirstChars.sort()
    this.brandsDataWithNumbers = brandsDataWithNumbers
  }

  changeChar() {
    this.toggleAlphabet = !this.toggleAlphabet
    if (this.toggleAlphabet) {
      this.englishAlphabetArr = []
      this.setNumbersToAlphabetArr()
      this.groups = [...new Set(this.numberFirstChars)] as string[];
      this.brands = this.brandsDataWithNumbers

    } else {
      this.numbersArr = []
      this.generateAlphabet()
      this.groups = [...new Set(this.alphabetFirstChars)] as string[];
      this.brands = this.brandsDataWithAlphabets
    }
  }

  // to clear all the arrays and set brand type
  brandType(value: any) {
    this.skeletonLoader=true;
    if (this.type == 'B') {
      this.removeDesignersHighlights()
      this.sortBrandData()
      this.groups = [...new Set(this.alphabetFirstChars)] as string[];
    }
    this.removeBrandHighlights()
    this.alphabetFirstChars = [];
    this.numberFirstChars = [];
    this.brandsDataWithAlphabets = [];
    this.brandsDataWithNumbers = [];
    this.type = value
    this.categoryId = ''
    this.searchData = []
    // this.toSearch = !this.search
     // to set default category
     this.selectedCategory = {
      category_brand_id: '',
      name: 'All'
    }
    this.name = ''
    this.getBrands()
  }

  // to filter data as per brand name
  searchByBrandName(searchText) {
    if (!searchText) {
      this.brands = [...this.originalBrands]; // to reset the brands array to original data
    } else {
      // to filter brands based on all possible substrings of the search text
      this.brands = this.originalBrands.filter(
        (brand: { name: string }) =>
          brand.name.toLowerCase().includes(searchText.toLowerCase())
      );
    }
    if (this.type == 'B') {
      this.sortBrandData()
      this.groups = [...new Set(this.alphabetFirstChars)] as string[];
    }
  }


  subCategory(type: any) {
    this.removeBrandHighlights()
    this.removeDesignersHighlights()
    this.alphabetFirstChars = [];
    this.numberFirstChars = [];
    this.brandsDataWithAlphabets = [];
    this.brandsDataWithNumbers = [];
    this.subCategoryType = type
    this.categoryId = ''
    this.name = ''
     // to set default category
     this.selectedCategory = {
      category_brand_id: '',
      name: 'All'
    }
    // console.log(JSON.stringify({
    //   alphabetFirstChars: this.alphabetFirstChars,
    //   numberFirstChars: this.numberFirstChars,
    //   brandsDataWithAlphabets: this.brandsDataWithAlphabets,
    //   brandsDataWithNumbers: this.brandsDataWithNumbers,
    //   subCategoryType: this.subCategoryType,
    //   categoryId: this.categoryId,
    //   name: this.name
    // }, null, 2));

    this.getBrands()
  }

  // to sort data based on selected category
  searchByCategory(category: any) {
    this.selectedCategory = category
    this.categoryId = category.category_brand_id
    this.getBrands()
  }

  // to route as per tier id
  // routeAsPerTier(value: any) {
  //   const { tier_id, name, id } = value;
  //   const brandName = this.configSettings.cleanUrl(name);
  //   switch (tier_id) {
  //     case 1:
  //       this.router.navigate(['home', brandName, id, tier_id]);
  //       break;
  //     case 2:
  //       this.router.navigate(['brands', brandName, id, tier_id]);
  //       break;
  //     case 3:
  //     default:
  //       this.router.navigate(['brands', brandName, id, tier_id]);
  //       break;
  //   }
  // }

  routeAsPerTier(event: MouseEvent, value: any) {
    const { tier_id, name, id } = value;
    const brandName = this.configSettings.cleanUrl(name);
    const route = this.generateRoute(tier_id, brandName, id);

    if (event.button === 2) {
      // Right-click
      this.openInNewTab(route);
    } else {
      // Left-click
      this.navigateTo(route);
    }

    event.preventDefault(); // Prevent the default click action
  }

  generateRoute(tier_id: number, brandName: string, id: any): string {
    switch (tier_id) {
      case 1:
        return `home/${brandName}/${id}/${tier_id}`;
      case 2:
        return `brands/${brandName}/${id}/${tier_id}`;
      case 3:
      default:
        return `brands/${brandName}/${id}/${tier_id}`;
    }
  }
  url: any;
  openInNewTab(route: string) {
    const url = `${window.location.origin}/${route}`;
    window.open(url, '_blank');
    this.url = ""
  }

  navigateTo(route: string) {
    const url = `${window.location.origin}/${route}`;
    window.location.href = url; // Navigate to the specified URL in the same tab
  }

  // to generate english alphabet
  generateAlphabet() {
    if (this.lang == 'en') {
      for (let index = 65; index <= 90; index++) {
        let alphabet = String.fromCharCode(index)
        
        if (!this.englishAlphabetArr.includes(alphabet)) {
          this.englishAlphabetArr.push(alphabet)
        }
      }
    }
  }

  // to set numbers to alphabet array
  setNumbersToAlphabetArr() {
    for (let index = 0; index <= 9; index++) {
      let listNumbers = index.toString()
      if (!this.englishNumberArr.includes(listNumbers)) {
        this.englishNumberArr.push(listNumbers)
      }
    }
  }

  // to clear brand categories and call brand listing based on type
  clearCategories() {
    this.categories = []
    this.categoryId = ''
    this.getBrands()
  }

  // to clear search section and reset brands data
  clearSearch() {
    this.name = ''
    this.brands = [...this.originalBrands];
    if (this.type == 'B') {
      this.sortBrandData()
      this.groups = [...new Set(this.alphabetFirstChars)] as string[];
    }
  }


  // to scroll to selected boutique or brand based on selected alphabet
  scrollTo(elementId: string): void {
    if (this.type == 'B') {
      const element = document.getElementById(elementId);
      if (element) {
        const yOffset = -this.yOffset;
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });
        this.highlightSelectedBrands(elementId)
      }
    } else {
      const designers = this.brands.filter(design => design.name.toLowerCase().startsWith(elementId));
      if (designers.length > 0) {
        const designerElement = document.querySelector(`[data-letter='${elementId}']`) as HTMLElement;
        if (designerElement) {
          const yOffset = -this.yOffset;
          const y = designerElement.getBoundingClientRect().top + window.pageYOffset + yOffset;
          window.scrollTo({ top: y, behavior: 'smooth' });
        }
      }
      this.highlightSelectedBoutique(elementId)
    }
  }


  // to highlight boutique based on selected alphabet
  highlightSelectedBoutique(target: string): void {
    const designerElements = document.querySelectorAll('.image-box-designer');

    designerElements.forEach((designerElement) => {
      const firstLetter = designerElement.getAttribute('data-letter');

      if (firstLetter && firstLetter.toLowerCase() != target) {
        designerElement.querySelector('.designer-image')?.classList.add('disable-designer-image');
        designerElement.querySelector('p')?.classList.add('disable-designer-name');
      } else {
        designerElement.querySelector('.designer-image')?.classList.remove('disable-designer-image');
        designerElement.querySelector('p')?.classList.remove('disable-designer-name');
      }
    });
  }

  // to remove (disable) classes from boutique
  removeDesignersHighlights(): void {
    const designerElements = document.querySelectorAll('.image-box-designer');

    designerElements.forEach((designerElement) => {
      designerElement.querySelector('.designer-image')?.classList.remove('disable-designer-image');
      designerElement.querySelector('p')?.classList.remove('disable-designer-name');
    });
  }



  // to highlight brand based on selected alphabet
  highlightSelectedBrands(target) {

    // to highlight selected brand letter
    let alphabetLetters = document.getElementsByClassName('brand-name-letter');
    for (let index = 0; index < alphabetLetters.length; index++) {
      const element = alphabetLetters[index];
      element.classList.remove('disabled-alphabet')
      if (element.textContent.toLowerCase() != target) {
        element.classList.add('disabled-alphabet')
      }
    }
    // to highlight selected brand name
    let listAlphabets = document.getElementsByClassName('brand-list-name');
    for (let index = 0; index < listAlphabets.length; index++) {
      const element = listAlphabets[index] as HTMLElement
      element.classList.remove('disabled-alphabet')
      if (element.id != `list-name${target}`) {
        element.classList.add('disabled-alphabet')
      }
    }
  }

  // to remove highlighted brands
  removeBrandHighlights() {
    let alphabetLetters = document.getElementsByClassName('brand-name-letter');
    let listAlphabets = document.getElementsByClassName('brand-list-name');

    // to remove highlight class from all brand letters
    for (let index = 0; index < alphabetLetters.length; index++) {
      const element = alphabetLetters[index];
      element.classList.remove('disabled-alphabet');
    }

    // to remove highlight class from all brand names
    for (let index = 0; index < listAlphabets.length; index++) {
      const element = listAlphabets[index];
      element.classList.remove('disabled-alphabet');
    }
  }

  // to toggle brand as favorite
  toggleBrandAsFavorite(brandId: number) {
    const getParams = {

    }
    const postParams = {
      user_id: this.user.id,
      brand_id: brandId
    }
    if (brandId == null) {
      this.toastr.warning('Invalid Brand ID')
      return
    }
    this.wishlistService.toggleBrandAsFavorite(getParams, postParams).subscribe(response => {
      if (response.status == 200) {
        this.toastr.success(response.message)
        this.getBrands()
      } else {
        this.toastr.error(response.message)
      }
    })
  }

  //navigate to my favorites 
  navigateToFav(type){
    // this.router.navigate(['/search'], { queryParams: pageType:type })    
    this.router.navigate(['/search'], { queryParams: { pt: type } })
  }

    // get query params based on latestId
  getCollectionQueryParams(type: any): any {
    // return {
    //   // q: '',
    //   // lang: this.lang,
    //   // category_id: '',
    //   // price_range: '',
    //   // in_stock: '',
    //   // typepage: '1',
    //   // per_page: '20',
    //   // is_featured: '',
    //   // latest: 0,
    //   // best_selling: '',
    //   // sort_by: '1',
    //   // store: '',
    //   // flash_sale_id: '',
    //   // shop_type_id: '',
    //   // user_id: this.user.id,
    //   // is_favourite: 1,
    //   // pageType: type
    // };
  }

}
