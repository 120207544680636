import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Event } from '@angular/router';
import { response } from 'express';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { CheckoutService } from 'src/app/config/checkout.service';
import { ConfigSettings } from 'src/app/config/config.settings';
import { UserService } from 'src/app/config/user.service';
import { Store } from 'src/app/models/store';
import { User } from 'src/app/models/user';
import { AddressApiService } from 'src/app/services/address-api.service';
import { AddressHelpersService } from 'src/app/services/address-helpers.service';

@Component({
  selector: 'app-new-address-popup',
  templateUrl: './new-address-popup.component.html',
  styleUrls: ['./new-address-popup.component.scss']
})
export class NewAddressPopupComponent implements OnInit {

  // general variables
  currency: any
  lang: string = ''
  store: Store
  user: User

  // address form variables
  addressForm: FormGroup;
  addressInfo: any
  isPopup: boolean = false
  isEditAddress: boolean = false
  selectedCountry: any
  countryCode: string = '+965';
  private addressSubscription: Subscription;

  // location arrays
  countriesArr: Array<any> = []
  governorateArr: Array<any> = []
  areasArr: Array<any> = []
  blocksArr: Array<any> = []

  // output events
  @Output() displayPopupEvent: EventEmitter<boolean> = new EventEmitter<boolean>()
  @Output() resetAddressList: EventEmitter<null> = new EventEmitter<null>()
  constructor(
    private addressHelpersService: AddressHelpersService,
    private formBuilder: FormBuilder,
    private configSettings: ConfigSettings,
    private checkoutService: CheckoutService,
    private addressApiService: AddressApiService,
    private userService: UserService,
    private toastr: ToastrService,
    private cdRef: ChangeDetectorRef
  ) {

  }
  
  ngOnInit() {
    this.userService.getUserSession().then(user => {
      if (user !== false) {
        this.user = user;
      }
    });

    this.addressHelpersService.getAddressInfo().subscribe((response => {
      this.isEditAddress = response.isEditAddress
      this.addressInfo = response.addressInfo
      this.isPopup = response.isPopup
      this.initiateAddress()
      this.getCountries()
      this.getStore()
      if (this.isEditAddress == true) {
        this.patchAddress()
      }
    }))
  }

  // to get store data 
  getStore() {
    this.configSettings.getStoreObject().then((result) => {
      this.store = <Store>result;
      this.currency = result['currency_' + this.lang];
      this.countryCode = `+${result.phonecode}`
      
    });
  }

  // to set default country while add address
  setDefaultCountry(){    
    this.selectedCountry = this.countriesArr.filter( (country: any) => {
      if (country.phonecode == this.store.phonecode) {
        this.selectedCountry = country
        if (this.isEditAddress == false) {
         this.addressForm.get('country_id').setValue(this.selectedCountry.id);
         this.loadStates(this.selectedCountry.id)
        }
      }
    })
  }

  // to initiate add address form
  initiateAddress() {
    this.addressForm = this.formBuilder.group({
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      mobile_number: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(10)]],
      country_id: [undefined, Validators.required],
      state_id: [undefined, Validators.required],
      area_id: [undefined, Validators.required],
      block_id: [undefined],
      block_name: [''],
      street: ['', Validators.required],
      avenue: [''],
      addressline_1: [''],
      floor: [''],
      flat: [''],
      landmark: [''],
      id_number: [''],
      notes: [''],
      is_default:[0]
    });
  }

  // patch addressInfo data to addressForm while edit address
  patchAddress() {
    if (this.addressInfo) {
      this.addressForm.patchValue(this.addressInfo);
      this.setStateWhileEditAddress()
      this.setAreaWhileEditAddress()
      this.setIsDefaultWhileEditAddress()
    }
  }

  // based on isEditAddress toggle add and edit address post
  saveAddress() {
    this.addressHelpersService.clearAddressInfo()
    if (this.selectedCountry?.is_landmark_required == 1) {
      if (this.addressForm.get('landmarkd').value == '') {
        this.toastr.warning('Please add Landmark')
        return
      }
    }
    if (this.selectedCountry?.is_id_mandatory == 1) {
      if (this.addressForm.get('id_number').value == '') {
        this.toastr.warning('Please add Id Number')
        return
      }
    }

    if (this.isEditAddress == false) {
      this.addAddress()
    } else {
      this.editAddress()
    }
  }

  // to post add address from
  addAddress() {
    const getParams = {
      lang: this.configSettings.getLang(),
      store: this.store.iso_code
    };

    // to set user_id to addressForm
    this.addressForm.addControl('user_id', this.formBuilder.control(''));
    this.addressForm.get('user_id').setValue(this.user.id);

    // when we are comming form checkout page for 1st time we are passing is default 1 else it is passing 0
    this.isPopup == false ? this.addressForm.get('is_default').setValue(1) : this.addressForm.get('is_default');
    const postParams = this.addressForm.value
    this.addressSubscription = this.addressApiService.postAddAddress(getParams, postParams).subscribe((response: any) => {
      if (response.status == 200) {
        this.toastr.success(response.message);
        this.resetAddressList.emit()
        this.closePopup()
      } else {
        this.toastr.error(response.message);
        this.closePopup()
      }

    })

  }

  // to post edit address form
  editAddress() {
    const getParams = {
      lang: this.configSettings.getLang(),
      store: this.store.iso_code
    };

    // to set user_id and shipping_address_id to addressForm form
    this.addressForm.addControl('user_id', this.formBuilder.control(''));
    this.addressForm.get('user_id').setValue(this.user.id);
    this.addressForm.addControl('shipping_address_id', this.formBuilder.control(''));
    this.addressForm.get('shipping_address_id').setValue(this.addressInfo.address_id);

    // alt_phone_number - this is a key in api but not used in form
    // location_type  - this is a key in api but not used in form

    const postParams = this.addressForm.value
    
    this.addressApiService.postEditAddress(getParams, postParams).subscribe((response: any) => {
      if (response.status == 200) {
        this.toastr.success(response.message);
        this.resetAddressList.emit(null)
        this.closePopup()
      } else {
        this.toastr.error(response.message);
        this.closePopup()
      }
    })
  }


  // to get state to address from (currently userAddress api has state id as 'governorate_id' and edit api take 'state_id )
  setStateWhileEditAddress() {
    this.addressForm.removeControl('governorate_id')
    this.addressForm.addControl('state_id', this.formBuilder.control(''));
    this.addressForm.get('state_id').setValue(this.addressInfo.governorate_id);
    this.loadStates(this.addressInfo.country_id)
  }

  // to set area_id to addressForm (NgSelect dose not reflect data when value is string so it is converted and stored)
  setAreaWhileEditAddress() {
    this.addressForm.get('area_id').setValue(Number(this.addressInfo.area_id));
    this.loadAreas(this.addressInfo.governorate_id)
  }

  // to set is_default because from api instead of 0 or 1 there is Yes and No
  setIsDefaultWhileEditAddress(){
   let isDefault = this.addressForm.get('is_default').value == 'Yes' ? 1 : 0
   this.addressForm.get('is_default').setValue(isDefault)
  }

  // to convert and set check box value (true, false) to (1,0) to pass in api
  updateIsDefault() {
    var checkbox = document.getElementById("default") as HTMLInputElement;
    var value = checkbox.checked ? 1 : 0;
    this.addressForm.get('is_default').setValue(value)
  }

  // emit event to close popup
  closePopup() {
    this.displayPopupEvent.emit(false)
  }


  // validation code
  validateIdNumber(event: KeyboardEvent) {
    let target = event.target as HTMLInputElement;
    let value = target.value;
    const key = event.key;

    // to allow numeric values
    if (key !== 'Backspace' && (key < '0' || key > '9')) {
      event.preventDefault();
      return;
    }

    // to limit the ID number to 16 characters
    if (value.length >= 16 && key !== 'Backspace') {
      event.preventDefault();
      return;
    }
  }
  
  validatePhone(event: any) {
     const key = event.key;
    // to allow numeric values
    if (key !== 'Backspace' && (key < '0' || key > '9')) {
      event.preventDefault();
      return;
    }
  } 

  
  
  
  // to get location information

  // to get countries
  getCountries() {
    this.configSettings.toggleLoading(true);

    this.checkoutService.getCountries().then(
      (data) => {
        this.countriesArr = data.body.data;
        this.setDefaultCountry()
      },
      (error) => {
        console.error(error);
      }
    ).finally(() => {
      this.configSettings.toggleLoading(false);
    });
  }

  // to get states
  loadStates(countryId) {
    this.configSettings.toggleLoading(true);

    this.checkoutService.getStates(countryId).then(
      (data) => {
        this.governorateArr = data.body.data;
      },
      (error) => {
        console.error(error);
      }
    ).finally(() => {
      this.configSettings.toggleLoading(false);
    });
  }

  // to get states if country has states
  getStates($country) {
    this.selectedCountry = $country;

    if ($country.has_states === '1') {
      this.loadStates($country.id);
      this.countryCode = '+' + $country.phonecode;
    } else {
      this.governorateArr = [];
    }

    this.addressInfo.governorate_id = null;
    this.addressInfo.area_id = null;
    this.addressInfo.block_id = null;
  }

  // to get areas
  loadAreas(stateId) {
    this.configSettings.toggleLoading(true);

    this.checkoutService.getAreas(stateId).then(
      (data) => {
        this.areasArr = data.body.data;        
      },
      (error) => {
        console.error(error);
      }
    ).finally(() => {
      this.configSettings.toggleLoading(false);
    })
  }

  // to get areas if state has areas
  getAreas($state) {

    if ($state.has_areas === '1') {
      this.loadAreas($state.id);
    } else {
      this.areasArr = [];
    }

    this.addressInfo.area_id = null;
    this.addressInfo.block_id = null;
  }

  // to load block
  loadBlocks(cityId) {
    this.configSettings.toggleLoading(true);

    this.checkoutService.getBlocks(cityId).then(
      (data) => {
        this.blocksArr = data.body.data;
      },
      (error) => {
        console.error(error);
      }
    ).finally(() => {
      this.configSettings.toggleLoading(false);
    });
  }

  // to get blocks if areas has blocks
  getBlocks($city) {
    if ($city.has_blocks === '1') {
      this.loadBlocks($city.id);
    } else {
      this.blocksArr = [];
    }

    this.addressInfo.block_id = null;
  }

  // to reset the area, block_id, and block name selections when the country changes.
  whenCountryChanges() {
    this.addressForm.get('state_id').reset();
    this.addressForm.get('area_id').reset();
    this.addressForm.get('block_id').reset();
    this.addressForm.get('block_name').reset();
  }

  // to reset the area, block_id, and block name selections when the state changes.
  whenStateChanges() {
    this.addressForm.get('area_id').reset();
    this.addressForm.get('block_id').reset();
    this.addressForm.get('block_name').reset();
  }

  // to reset the block_id and block name selections when the area changes.
  whenAreaChanges() {
    this.addressForm.get('block_id').reset();
    this.addressForm.get('block_name').reset();
  }

  ngOnDestroy() {
    if (this.addressSubscription) {
      this.addressSubscription.unsubscribe();
    }
    this.addressForm.reset()

  }

}
