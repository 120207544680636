import { Injectable } from '@angular/core';
import {Observable, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CheckSearchBoxActiveService {

  constructor() { 
    this.configSubject = new Subject();
    this.isSearchBoxActive = this.configSubject.asObservable();
  }

  private configSubject: Subject<boolean>;
  cartData: boolean

  public isSearchBoxActive = new Observable((observer) => {
    // observable execution
    observer.next();
    observer.complete();
  });


  setSearchBoxActive(val: boolean) {
    this.configSubject.next(val); 
  }
}
