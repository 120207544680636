<!-- Large modal -->
<div id="reviewModal" class="modal modal-overflow fade" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog"
    aria-labelledby="myLargeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content model-content-center reviewPopup">

            <div class="container-fluid">
                <div class="cart_data">
                    <button type="button" class="d-flex Close-btn justify-content-end" data-dismiss="modal"
                        aria-label="Close">
                        <!-- <span aria-hidden="true">&times;</span> -->
                        <img src="../../../assets/icons/cross_popup.svg" alt="" srcset="">
                    </button>
                    <p class="reviewTitle">{{'YOUR ITEM DETAILS'|translate}}</p>
                    <div class="cart_product my-3 ">
                        <div
                            class="d-flex flex-column w-full flex-lg-row align-items-center align-items-lg-start gap-3 gap-md-5">
                            <div class="product-colum">
                                <div class="cart_product_data border_cart"
                                    *ngFor="let item of products; let i = index;">
                                    <div class="d-flex  gap-3">
                                        <div>
                                            <div class="product_image">
                                                <figure>
                                                    <div class="image-wrapper">
                                                        <img src="{{item.image}}" class="product-img">
                                                    </div>
                                                </figure>
                                            </div>
                                        </div>
                                        <div class="w-100 position-relative">
                                            <div class="">
                                                <div
                                                    class="d-flex product-colum-div justify-content-between gap-2 gap-md-4">
                                                    <div class="w-100">
                                                        <div class="product_details">
                                                            <h1>{{item.brand_name | uppercase}}</h1>
                                                            <h5>{{item.name | titlecase}}</h5>
                                                            <p>{{currency}} {{item.final_price | number:'1.3-3'}}</p>
                                                            <p> <span>   {{item?.configurable_option[1].attributes[0].value}} </span>
                                                                <span>  | </span> 
                                                                <span>{{item?.configurable_option[0].attributes[0].value}} </span> 
                                                             </p>
                                                            <p class="d-flex gap-3 align-items-center">x {{item.quantity}} <span *ngIf="item?.loyalty_saving>0" class="speicalDiscount">
                                                                {{'Special Savings' | translate }} {{item?.loyalty_saving}}</span>
                                                            </p>
                                                            
                                                            <span class="d-none d-md-block text-red"
                                                                *ngIf="remaining_quantity?.length <=4">
                                                               {{'Low Stock, only'|translate}} {{ item?.remaining_quantity }} {{'left'|translate}}.
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <span class="d-block d-md-none text-red pb-2"
                                                    *ngIf="remaining_quantity?.length <=4">
                                                    {{'Low Stock, only'|translate}} {{ item?.remaining_quantity }} {{'left'|translate}}.
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>