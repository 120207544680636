<ng-container *ngIf="dataAvailable">
<section id="faq" class="page-wrapper {{lang == 'ar' ? 'text-right' : ''}}" @listStagger>
  <div class="container">
    <h3 class="page-title font-black">{{'FAQs' | translate}}</h3>
    <div class="accordion custom-accordion" id="faqAccordion" *ngIf="faq.length > 0">
      <div class="card faq-item col-12 col-sm-12 faq-item mb-3" *ngFor="let row of faq; let i = index">
        <div class="card-header faq-header">
          <h3 class="faq-title font-bold m-0 collapsed d-flex justify-content-between align-items-center" data-toggle="collapse" [attr.data-target]="'#faqSection_' + i" [attr.aria-controls]="'faqSection_' + i" [attr.aria-expanded]="i == 0">
            {{row.question}}
            <i class="fas icon"></i>
          </h3>
        </div>

        <div id="faqSection_{{i}}" [ngClass]="'collapse faq-body'" data-parent="#faqAccordion">
          <div class="card-body">
            {{row.answer}}
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
</ng-container>
