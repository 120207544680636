import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ConfigService } from "../config/config.service";
import { Store } from "../models/store";
import { ConfigSettings } from "../config/config.settings";
import { UserService } from "../config/user.service";
import { User } from "../models/user";
import { NotificationService } from "../config/notification.service";
import { Notification } from "../models/notification";
import { HomeService } from "../services/home.service";
import { CollectionGroup } from "../models/collectionGroup";
import { Collection } from "../models/collection";
import { FooterService } from "../config/footer.service";
import { NavbarService } from "../config/navbar.service";
import { Meta, Title } from '@angular/platform-browser';
import { AnalyticsService } from '../services/analytics.service';
import { DragScrollComponent } from 'ngx-drag-scroll';
import { Subscription } from 'rxjs';
import { AddToCartModalService } from '../config/add-to-cart-modal.service';


@Component({
  selector: 'app-home-v2',
  templateUrl: './home-v2.component.html',
  styleUrls: ['./home-v2.component.css']
})
export class HomeV2Component implements OnInit {

  lang: string;
  currency: string;
  store: Store;
  user: User = new User();
  notifications: Notification[] = [];
  popularSearches: any[] = [];
  notifyText: string;
  supportPhone: string;
  supportEmail: string;
  bannerHeight: number;
  walletBalance: string;
  collectionGroups: CollectionGroup[];
  response: any;
  offsetWidth: number;

  slideConfig: any = {
    variableWidth: false,
    infinite: true,
    speed: 600,
    slidesToShow: 4,
    slidesToScroll: 4,
    dots: false,
    prevArrow: '<span class="slick-arrow slick-prev"><i class="fas fa-chevron-left"></i></span>',
    nextArrow: '<span class="slick-arrow slick-next"><i class="fas fa-chevron-right"></i></span>',
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          arrows: true,
        }
      }
    ]
  };

  slideConfigNoArrow: any = {
    variableWidth: false,
    infinite: true,
    speed: 600,
    slidesToShow: 4,
    slidesToScroll: 4,
    dots: true,
    prevArrow: false,
    nextArrow: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          arrows: true,
        }
      }
    ]
  };

  slideConfigAr: any = {
    variableWidth: false,
    infinite: true,
    speed: 600,
    slidesToShow: 4,
    slidesToScroll: -4,
    rtl: false,
    dots: true,
    prevArrow: '<span class="slick-arrow slick-prev"><i class="fas fa-chevron-left"></i></span>',
    nextArrow: '<span class="slick-arrow slick-next"><i class="fas fa-chevron-right"></i></span>',
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      }
    ]
  };

  slideConfigFinal: any;
  $subs: Subscription;
  showShopPopup: any = false;
  popupProductDetails: any;
  innerWidth: any;

  @ViewChild('advertisement', { static: true }) advertisement: ElementRef;
  @ViewChild('closeShopTheLook', { static: true }) closeShopTheLook: ElementRef;

  constructor(
    private configService: ConfigService,
    public configSettings: ConfigSettings,
    private meta: Meta,
    private title: Title,
    private userService: UserService,
    private notificationService: NotificationService,
    private homeService: HomeService,
    protected footerService: FooterService,
    private addToCartModalService: AddToCartModalService,
    private analytics: AnalyticsService,
    private navbarService: NavbarService
  ) {
    this.meta.addTags([
      { name: 'description', content: 'The Wish List is a Kuwait based E-commerce platform that connects fashion designers to customers. Discover and shop a variety of pieces ranging from clothes, bags, and shoes to accessories and more.' },
      { name: 'author', content: 'wishlist' },
      { name: 'keywords', content: 'The Wish List,e-commerce,Fashion,Outlet,The,Wish,List,TWL,discount,sale,clothes shoes,kids,accessories,namshi,boutiqaat,bazma,nalbes,riva' }
    ]);
    this.setTitle('Home Page');
  }

  public setTitle(newTitle: string) {
    this.title.setTitle(newTitle);
  }

  ngOnInit() {
    this.configSettings.toggleLoading(true);
    this.navbarService.toggleMainNavigation(true);
    this.navbarService.isEnableHeaderBottomAction(true);
    this.navbarService.isEnableHeaderBottomMobileAction(true);
    this.footerService.toggleFooter(true);
    const homeContainer = document.getElementById('home-container');
    const offsetWidth = homeContainer.offsetWidth;

    if (offsetWidth >= 767) {
      this.offsetWidth = offsetWidth - 0;
    }
    else {
      this.offsetWidth = offsetWidth - 0;
    }

    this.notificationService.currentNotification.subscribe(data => this.notifications = data);
    this.lang = this.configSettings.getLang();
    this.landingPageAd();
    this.navbarService.changeCategory.subscribe(selectedCategory => {
      localStorage.setItem('top_selected_category', selectedCategory.toString());
      this.readHomeApi();
    });

    /**
     * getting store from config
     *  */
    this.configSettings.getStoreObject().then((result) => {
      this.store = <Store>result;
      this.currency = result['currency_' + this.lang];
      /**
       * Getting user session data
       */
      this.userService.getUserSession().then(response => {
        if (response !== false) {
          this.user = response;
        }
        this.readHomeApi();
      }, error => { 
        // this.configSettings.toggleLoading(false); 
      });
    });
    // this.analytics.pageView(
    //   String("Home")
    // );
    this.$subs = this.addToCartModalService.receivedItem().subscribe((d) => {
      this.popupProductDetails = d;
      this.showShopPopup = true;
      setTimeout(() => {
        this.showShopPopup = false;
      }, 5000);
    });
  }

  getScaleSize(n: number): number {
    const containerSize = this.offsetWidth;
    const scale = containerSize / 320;
    // return Math.ceil(n * scale);
    return (n * scale);
  }

  getCollectionGroupStyle(collectionGroup: CollectionGroup): any {

    let style: any = {};

    style.marginTop = this.getScaleSize(collectionGroup.margin_top) + 'px';
    style.marginBottom = this.getScaleSize(collectionGroup.margin_bottom) + 'px';

    return style;
  }

  getImageStyle(collection: Collection, collectionGroup: CollectionGroup, i: number): any {

    let style: any = {};

    const lastIndex = (collectionGroup.collection_list.length) - 1;

    if (collectionGroup.collection_list.length > 1) {
      // if (i != lastIndex && this.lang != 'ar') {
      //   style.marginRight = this.getScaleSize(collectionGroup.image_margin) + 'px';
      // }
      // if (i != lastIndex && this.lang == 'ar') {
      //   style.marginLeft = this.getScaleSize(collectionGroup.image_margin) + 'px';
      // }
      if (this.lang == 'ar') {
        style.marginRight = this.getScaleSize(collectionGroup.image_margin) + 'px';
      }
      if (this.lang != 'ar') {
        style.marginLeft = this.getScaleSize(collectionGroup.image_margin) + 'px';
      }
    }

    return style;
  }

  home_banner: any = '';
  readHomeApi(): void {
    this.configSettings.toggleLoading(true);
    let categoryId = this.getCategoryLocalStorage();
    if (categoryId === 0) {
      categoryId = this.configSettings.defaultCategory;
    }

    if (this.user.id == undefined) {
      var user_id = '';
    } else {
      var user_id = this.user.id;
    }

    const getParam = {
      lang: this.lang,
      user_id: user_id,
      category_id: categoryId,
      store: this.store.iso_code,
      device_type: 'W'
    };
    this.homeService.getHome(getParam).subscribe((response) => {
      if (response.status === 200 && response.body.status === 200) {
        const data = response.body.data;
        this.home_banner = response.body.data.home_banner;
        this.configSettings.setIsVip(data.is_vip);
        this.configSettings.setIsReferralCode(JSON.stringify(data.referral_code));
        this.collectionGroups = data.collectionGroups as CollectionGroup[];
        var supportDetails = [];
        supportDetails['support_phone'] = response.body.data.support_phone;
        supportDetails['support_email'] = response.body.data.support_email;
        this.navbarService.setSupportDetails(supportDetails);
        this.configSettings.toggleLoading(false);
      }
    });
  }

  @ViewChild('mySidenav1', { static: true }) mySidenav1: ElementRef;

  shopTheLookItems: any;
  myHeight: any = 'height0';
  toggleSidenav1(action: any) {
    this.shopTheLookItems = this.allStories[this.currentStoryStatus].products;
    if (action == 'close') {
      this.timeLineReleased();
    } else {
      this.timeLinePressed();
    }
    if (this.myHeight == 'height0') {
      this.myHeight = 'height30';
    } else {
      this.myHeight = 'height0';
    }
  }
  landingAds: any;
  landingPageAd(): void {
    const getParam = {
      lang: this.lang,
      type: 'W',
      device_type: 'W'
    };
    this.homeService.landingPageAds(getParam).subscribe((response) => {
      this.landingAds = response.body.data;
      if (this.configSettings.getLandingAds() != '1' && response.body.data != undefined && response.body.data != '') {
        this.advertisement.nativeElement.click();
      }
    });
  }

  getCategoryLocalStorage(): number {
    return +localStorage.getItem('top_selected_category');
  }

  getCollectionRouterLink(collection: any): any {
    const type = collection.type;
    if (type === 'B' || type === 'BR') {
      return ['/brand', this.getUrlName(collection.title), collection.type_id];
    }
    else if (type === 'C') {
      return ['/category', this.getUrlName(collection.title), collection.type_id];
    }
    else if (type === 'P') {
      return ['/product/detail', collection.type_id];
    }
    else if (type === 'FP') {
      return ['/product/detail', collection.type_id];
    }
    else if (type === 'L') {
      return collection.link;
    }
    else if (type === 'S') {
      return ['/shop', this.getUrlName(collection.title), collection.type_id];
    }
    else if (type === 'F') {
      return ['/search'];
    }
    return '';
  }

  landingAdsUrl(landingAds: any) {
    if (landingAds != '' && landingAds != undefined && landingAds != 'undefined') {
      if (landingAds.link_type === 'B' || landingAds.link_type === 'BR') {
        return ['/brand', this.getUrlName(landingAds.link_name), landingAds.link_id];
      }
      else if (landingAds.link_type === 'C') {
        return ['/category', this.getUrlName(landingAds.link_name), landingAds.link_id];
      }
      else if (landingAds.link_type === 'P') {
        return ['/product/detail', landingAds.link_id];
      }
      else if (landingAds.link_type === 'FP') {
        return ['/product/detail', landingAds.link_id];
      }
      else if (landingAds.link_type === 'L') {
        return landingAds.link_id;
      }
      else if (landingAds.link_type === 'S') {
        return ['/shop', this.getUrlName(landingAds.link_name), landingAds.link_id];
      }
    }
    return '';
  }

  closed() {
    this.configSettings.setLandingAds();
  }

  getCollectionQueryParams(collection: any): any {
    const type = collection.type;
    if (collection.type == 'F') {
      return {
        lang: 'en',
        price_range: '',
        in_stock: '',
        page: '1',
        per_page: '20',
        is_featured: '',
        latest: '',
        best_selling: '',
        sort_by: '1',
        store: '',
        flash_sale_id: collection.type_id,
        shop_type_id: ''
      };
    }
    return {};
  }

  getUrlName(productName: string): string {
    var prodN = productName ? (productName.toLowerCase().replace(/[^A-Z0-9]+/ig, "-")) : '';
    return prodN;
  }

  @ViewChild('nav', {
    read: DragScrollComponent
}) nav!: DragScrollComponent;


  @ViewChild('myProgress') myProgress: any;
  @ViewChild('myBar') myBar: any;

  moveLeft() {
    this.nav.moveLeft();
    this.initValue = 0;
    this.width = 0;
  }

  moveRight() {
    this.nav.moveRight();
    this.initValue = 0;
    this.width = 0;
  }

  initValue: any = 0;
  width: any = 1;
  id: any;
  rightBound: any = false;
  allStories: any;
  currentStoryStatus: any = 0;
  selectedCheckbox: any = 0;
  isMousePressed: any = false;
  timeLinePressed() {
    this.isMousePressed = true;
  }

  openCart(id:any){
    console.log('opencart triggered')
    this.toggleSidenav1('close');
    // this.toggleSidenav();
    document.getElementById('storiesCLoseButton2').click();
    this.addToCartModalService.sendItemOpen('open');
    // this.closeShopTheLook.nativeElement.click();
    this.myHeight == 'height0'
  }

  toggleSidenav(){

  }

  timeLineReleased() {
    this.isMousePressed = false;
  }

  timeLineClicked(num: any, collection: any) {
    this.currentStoryStatus = (num + 1);
    this.selectedCheckbox = (num + 1);
    var coll = { stories: collection };
    this.startLoadingTimeline(coll);
    clearInterval(this.id);
  }

  startLoadingTimelineFromZero(collection: any) {
    this.initValue = 0;
    this.currentStoryStatus = 0;
    this.selectedCheckbox = 0;
    clearInterval(this.id);
    this.startLoadingTimeline(collection);
    if (document.getElementById('image00')) {
      document.getElementById('image00').click();
    }
  }

  startLoadingTimeline(collection: any) {
    this.allStories = collection.stories;
    if (this.initValue == 0) {
      this.width = 1;
      this.id = setInterval(() => {
        if (!this.isMousePressed) {
          this.frame(collection);
        }
      }, 60);
    }
  }

  modalClose() {
    clearInterval(this.id);
    this.id = undefined;
    this.initValue = 0;
    this.currentStoryStatus = 0;
    this.selectedCheckbox = 0;
    this.width = 1;
  }

  frame(collection: any) {
    if (this.width >= 100) {
      if (this.currentStoryStatus == (collection.stories.length - 1)) {
        this.width = 100;
        clearInterval(this.id);
        document.getElementById('image00').click();
        document.getElementById('storiesCLoseButton2').click();
        return;
      } else {
        clearInterval(this.id);
        this.slideRight();
        this.initValue = 0;
        this.width = 0;
        this.currentStoryStatus++;
        this.selectedCheckbox++;
        this.startLoadingTimeline(collection);
      }
    } else {
      this.width++;
    }
  }

  slideRight() {
    if (this.selectedCheckbox < (this.allStories.length - 1)) {
      document.getElementById('image' + this.selectedCheckbox).click();
    } else if (this.selectedCheckbox == (this.allStories.length - 1)) {
      this.currentStoryStatus = 0;
      this.selectedCheckbox = 0;
      this.initValue = 0;
      clearInterval(this.id);
      document.getElementById('storiesCLoseButton2').click();
      document.getElementById('image00').click();
    }
  }

  leftMove() {
    if (this.currentStoryStatus != 0) {
      this.currentStoryStatus--;
      this.selectedCheckbox--;
      this.initValue = 0;
      this.width = 0;
      if (this.selectedCheckbox == 0) {
        var num = this.selectedCheckbox;
        document.getElementById('image00').click();
      } else {
        var num2 = this.selectedCheckbox - 1;
        document.getElementById('image' + num2).click();
      }
    }

  }
  rightMove() {
    if ((this.allStories.length - 1) > this.selectedCheckbox) {
      var num = this.selectedCheckbox;
      document.getElementById('image' + num).click();
      this.currentStoryStatus++;
      this.selectedCheckbox++;
      this.initValue = 0;
      this.width = 0;
    }
  }

  closeStory() {
    document.getElementById('storiesCLoseButton2').click();
  }

  rightBoundStat(reachesRightBound: boolean) {
    if (reachesRightBound) {
      this.rightBound = true;
    }
  }
}
