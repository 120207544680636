<div class="order-background">
    <div class="row">
        <div class="col-12">
            <div class="ORDER-TOTAL">
                <div class="Total">
                    <ul *ngIf="shippingCost > 0">
                        <li class="Total-right">{{'Item Total' | translate}}</li>
                        <li class="colorBlack">{{currency}} {{cartValue | number:'1.3-3'}}</li>
                    </ul>
                </div>
                <div class="Total" *ngIf="(discount > 0)">
                    <ul>
                        <li class="Total-right">{{'Discount' | translate}}</li>
                        <li class="Total-pricecolor Discount">- {{currency}} {{discount | number:'1.3-3'}}</li>
                    </ul>
                </div>
                <div class="Total" *ngIf="shippingCost > 0">
                    <ul>
                        <li class="Total-right">{{'Delivery' | translate}} </li>
                        <li class="colorBlack">{{currency}} {{shippingCost | number:'1.3-3'}}</li>
                    </ul>
                </div>
                <div class="Total" *ngIf="is_free_delivery">
                    <ul>
                        <li class="Total-right">{{'Delivery' | translate}} </li>
                        <li class="Total-pricecolor">{{'FREE' | translate}}</li>
                    </ul>
                </div>
                <div class="Total" *ngIf="checkItemStockData">
                    <ul *ngIf="(checkItemStockData.custom_duty_charges > 0)">
                        <li class="Total-right">{{'Custom Charges' | translate}} </li>
                        <li class="colorBlack">{{currency}} {{checkItemStockData.custom_duty_charges | number:'1.3-3'}}
                        </li>
                    </ul>
                </div>
                <div class="Total" *ngIf="codCost > 0">
                    <ul>
                        <li class="Total-right">{{'COD Cost' | translate}} </li>
                        <li class="colorBlack">{{currency}} {{codCost | number:'1.3-3'}}</li>
                    </ul>
                </div>
                <!--new 3-->
                <div class="Total" *ngIf="checkItemStockData">
                    <ul *ngIf="(checkItemStockData.wallet.wallet_amount_applied > 0)">
                        <li class="Total-right">{{'Wallet' | translate}}</li>
                        <li class="Total-pricecolor">- {{currency}}
                            <span>{{checkItemStockData.wallet.wallet_amount_applied | number:'1.3-3'}}</span>
                        </li>
                    </ul>
                </div>
                <div class="Total" *ngIf="checkItemStockData">
                    <ul>
                        <li class="Total-right">{{'Grand Total' | translate}}</li>
                        <li class="colorBlack">{{currency}} {{checkItemStockData.total | number:'1.3-3'}}</li>
                    </ul>
                </div>
            </div>

            <div class="PLACE-ORDER">
                <button type="button" (click)="placeOrder()">{{'PLACE ORDER' | translate}}</button>
            </div>

            <div class="checkout-note">
                <p>Our checkout is safe and secure. Your personal and payment information is securely transmitted via
                    128-bit encryption.
                    We do not store any payment card information on our system.</p>
            </div>
        </div>
    </div>
</div>