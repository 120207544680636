<div class="PRODUCT-ITEM-DETAILS" *ngIf="mainItem">
    <div class="PRODUCT-ITEM-DETAILS-MAIN">
        <div class="PRODUCT-ITEM-LEFT" [routerLink]="['/product/detail/', mainItem.id]">
            <img src="{{mainItem.image}}" alt="mainItem.name" (click)="imageClicked()">
        </div>
        <div class="PRODUCT-ITEM-RIGHT">
            <div class="ITEM-DATA">
                <h3>{{mainItem.brand}}{{mainItem.brand_name}}</h3>
                <p>{{mainItem.name}}</p>
                <h5>{{currency}} {{mainItem.final_price | number:'1.3-3'}}
                    <span *ngIf="mainItem.final_price != mainItem.regular_price">
                        <del> {{currency}} {{mainItem.regular_price | number:'1.3-3'}}</del>
                    </span>
                </h5>
            </div>
            <div class="PRODUCT-SIZE">
                <ng-container>
                    <div class="col-12 col-sm-12 col-md-6 mb-2 mb-md-0 p-0" *ngFor="let option of mainItem.configurable_option; let i = index; let last = last;">
                        <ng-select
                                id="option-{{option.attribute_id}}"
                                [items]="option.attributes"
                                [disabled]="option.attributes.length == 1"
                                [class.hide-arrow]="option.attributes.length == 1"
                                [addTag]="true"
                                bindLabel="value"
                                bindValue="option_id"
                                [clearable]=false
                                [searchable]=false
                                [placeholder]="((lang == 'en')? ('Choose your' | translate) : '') +' '+ option.type"
                                (change)="onOptionSelect(option, $event)"
                                [(ngModel)]="selectedAttribute[i]">
                        </ng-select>
                    </div>
                </ng-container>
            </div>
            <div class="PRODUCT-BUTTON">
                 <button type="button" *ngIf="(addToBagText != 'Go to Bag' && addToBagText != 'Out of stock')" (click)="addToBag()" >
                    <i class="fa fa-plus mrfs" aria-hidden="true" *ngIf="(addToBagText != '✓')"></i>{{addToBagText | translate}}</button>
                <button type="button" *ngIf="(addToBagText == 'Go to Bag')" (click)="viewBag()">{{addToBagText | translate}}<img class="ml-3 diw7" src="../../assets/ArtWork/BagW@2x.png"><span class="arrowText">→</span></button>
                <button *ngIf="addToBagText == 'Out of stock'" disabled>{{addToBagText | translate}}</button>
            </div>
        </div>
    </div>
</div>