<div id="wrapper" class="" [ngClass]="{'overflow-hidden': isFilterActive}">
  <div class="header-navs">
    <app-header [stores]="stores" [store]="store"></app-header>
  </div>
    <main class="px-0 px-sm-0 minHeight" >
        <router-outlet (activate)="onActivate($event)"></router-outlet>
    </main>
    
    <app-footer *ngIf="isFilterActive == false"></app-footer>
    <!-- <app-loading></app-loading> -->

        <!-- subscribePush section starts here  -->
        <section class="section">
          <div [ngClass]="showPopup==true ? 'popup-container-subscribe' : 'd-none' " id="subscribePush" tabindex="-1" aria-labelledby="subscribePushLabel" >
            <div id="modal-dialog" [ngClass]="showPopup==true ? 'popup-container-subscribe' : 'd-none' ">
              <div class="popup-content-subscribe" id="subscribe-content">
        
                <div class="py-3">
                  <div class="col-12 text-right">
                    <button type="button" class="close btn text-center close-button closeBtn" aria-label="Close">
                      <!-- <span class="closeBtnBorder" >
                                  <span id="closeBtnOtp" class="new-close" data-bs-dismiss="modal" aria-label="Close">
                                    <!-- C:\angular\wishlistweb-new-design\src\assets\ArtWork\close@2x.png -->
                      <!-- <img rel="preload"  src="assets/ArtWork/close@2x.png"></span>
                                </span> -->
                    </button>
                  </div>
                  <div class="col-12 text-center">
                    <h5 class="text-desc" id="staticBackdropInsuranceLabel">
                      <span class="restOfTitle"><img class="" alt="Logo" id="wishlistImg"
                          src="assets/images/old-logo.png"></span>
                    </h5>
                    <h5 class="text-desc mb-0" id="staticBackdropInsuranceLabel" *ngIf="lang != 'ar'">
                      <span class="firstWord">By allowing us to send you push notification you will not miss the latest styles
                        from us </span>
                    </h5>
                    <h5 class="text-desc mb-0" id="staticBackdropInsuranceLabel" *ngIf="lang == 'ar'">
                      <span class="firstWord"> By allowing us to send you push notification you will not miss the latest styles
                        from us </span>
                    </h5>
                  </div>
                </div>
                <div class="text-center" id="subscribe-btns">
                  <div class="pb-3" id="subscribe-btns2">
                    <div class="col-12" id="subscribe-btns3">
                      <button class="updateBtn" (click)="subscribe()" > {{'Subscribe' | translate}}
                      </button>
                      <button class="updateBtn" (click)="unsubscribe()">
                        {{'Cancel' | translate}}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <!-- subscribePush section ends here  -->
</div>
