import { Component, OnInit } from '@angular/core';
import {Form, NgForm} from '@angular/forms';
import {AuthenticationService} from '../config/authentication.service';
import {first} from 'rxjs/operators';
import {ConfigSettings} from '../config/config.settings';
import { listStagger } from '../animations';
import { FooterService } from '../config/footer.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  animations: [ listStagger ]
})
export class ForgotPasswordComponent implements OnInit {

  submitted = false;
  hasError = true;
  message: string;
  email: string;
  lang: string;
  isEmailValid: boolean = false

  constructor(
      private authenticationService: AuthenticationService,
      private configSettings: ConfigSettings,
      protected footerService: FooterService,
      private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.lang = this.configSettings.getLang();
    setTimeout(() => {this.footerService.toggleFooter(true)}, 300);
  }



  doForgotPassword(forgotPasswordForm: NgForm) { 
      this.authenticationService.forgotPassword(this.email)
          .pipe(first())
          .subscribe(
              response => {
                  if (response.status === 200) {
                      this.hasError = false;
                      this.toastr.success(response.message)
                      this.email = ''
                      forgotPasswordForm.reset()
                  } else {
                      this.hasError = false;
                      if (response.status === 401) {
                          this.toastr.warning(response.message) // user not found: 401
                      } else if (response.status === 404) {
                          this.toastr.error(response.message) 
                      } else if (response.status === 500) {
                          this.toastr.warning(response.message) // request not process: 500
                      } else {
                          this.hasError = false;
                          this.message = response.message;
                      }
                  }
                  this.submitted = true;
              }
          );
  }

  
  validateEmail(event: any) {
    const value = event.target.value;
    if (value.length > 1 ) {
        const validEmailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        this.isEmailValid = validEmailRegEx.test(value);
    }
  }


  
  scrollToTop() {
    window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
    });
}

}
