// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
let hostName = window.location.hostname;
let substring = "staging.shop-twl.com";
let url = 'https://dev.shop-twl.com/api/ver9api/';
let substringStg = "localhost";
if (hostName.includes(substringStg)) {
  url = 'https://admin-cp.thewishlist.com/api/ver3api/';
  // url = 'https://dev.shop-twl.com/api/ver9api/';
} else {
  url = 'https://admin-cp.thewishlist.com/api/ver3api/';
}
  // key_live_fqlaWdSfMZEym477yFUUfhefEEegJQwK //live
  // key_test_lFmkZhJjUZEvk74YruOLmeadvsciSHD2 //test
export const environment = {
  production: false,
  apiUrl: url,
  branchIoTest: 'key_test_lFmkZhJjUZEvk74YruOLmeadvsciSHD2',
  branchIoLive: 'key_live_fqlaWdSfMZEym477yFUUfhefEEegJQwK',
};
/*
 * In development mode, for easier debugging, you can ignore zone related error
 * stack frames such as `zone.run`/`zoneDelegate.invokeTask` by importing the
 * below file. Don't forget to comment it out in production mode
 * because it will have a performance impact when errors are thrown
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
