import { Injectable } from '@angular/core';
import { BehaviorSubject,Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AddToBagService {

  private product: Subject<any> = new BehaviorSubject([]);

  constructor() { }
  setBag(item:any){
    this.product.next(item);
  }
  getBag(){
    return this.product.asObservable();
  }

}
