import { ChangeDetectorRef, Component, Input, OnInit,ViewChild } from "@angular/core";
import { Store } from "../models/store";
import { ConfigSettings } from "../config/config.settings";
import { UserService } from "../config/user.service";
import { Product } from "../models/product";
import { AddToBagService } from "../services/add-to-bag.service";
import { ConfigService } from "../config/config.service";
import { ConfigurableOptions } from "../models/configurable-options";
import { LocalStorage } from "@ngx-pwa/local-storage";
import { ToastrService } from "ngx-toastr";
import { AnalyticsService } from "../services/analytics.service";
import { CryptService } from "../config/crypt.service";
import { HandleCartService } from "../services/handle-cart.service";
import { CartService } from "../config/cart.service";
import { reject } from 'q';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { WishlistService } from "../config/wishlist.service";
import { first } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CallWishlistService } from "../services/call-wishlist.service";
import { UpdateWishlistCountService } from '../services/update-wishlist-count.service';
// import { WishlistComponent } from "../wishlist/wishlist.component";
@Component({
  selector: "app-add-to-bag-popup",
  templateUrl: "./add-to-bag-popup.component.html",
  styleUrls: ["./add-to-bag-popup.component.scss"],
})
export class AddToBagPopupComponent implements OnInit {
  lang: any;
  store: Store;
  userId: any;
  product: Product;
  entityId: string;
  quantity: number = 1;
  selectedAttribute: any[] = [];
  selectedOption: Number[] = [];
  productSizeObj: any;
  productColorObj: any;
  selectedColorText: any;
  productAgeObj: any;
  isMobile: any = false;
  isVip: any = this.configSettings.getisVip();
  countryCode = '+965';
  selectedColorArr: Array<number> = [];
  selectedSizeArr: Array<number> = [];
  selectedFilters: ConfigurableOptions[] = [];
  notifyMeForm: UntypedFormGroup;
  addToCartText: string;
  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
  phonePatters = "^[0-9]+$";
  dataTransfer$: any;
  productGet$: any;
  @Input() fromWishlist:any
  // @ViewChild(WishlistComponent) WishlistComponent: WishlistComponent;
  constructor(
    private configSettings: ConfigSettings,
    private configService: ConfigService,
    private userService: UserService,
    private addToBagSer: AddToBagService,
    private cdr: ChangeDetectorRef,
    protected localStorage: LocalStorage,
    private toaster: ToastrService,
    private analytics: AnalyticsService,
    private cryptService: CryptService,
    private handleCartService: HandleCartService,
    private cartService: CartService,
    private fb: UntypedFormBuilder,
    private wishlistService: WishlistService,
    private CallWishlistService:CallWishlistService,
    private updateWishlistCountService: UpdateWishlistCountService
  ) { }


  ngOnInit() {
    this.isMobile = window.innerWidth < 600;
    this.lang = this.configSettings.getLang();
    this.getUserData();
    // notify me form group
    this.notifyMeForm = this.fb.group({
      user_id: [''],
      product_id: [''],
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
      phone: ['', [Validators.required, Validators.minLength(8), Validators.pattern(this.phonePatters)]]
    });
    // get user data
    this.userService.getUserSession().then(user => {
      if (user !== false) {
        this.userId = user.id;
        this.notifyMeForm.patchValue({
          name: user.first_name + ' ' + user.last_name,
          email: user.email,
          mobile: user.phone
        });
      }
    }, error => { this.configSettings.toggleLoading(false); });
    // to get phone code
    this.localStorage.getItem<any>('store_object').subscribe((result) => {
      if (result) {
        this.countryCode = '+' + (result as any).phonecode;
      }
    });
    this.productGet$=this.addToBagSer.getBag().pipe(takeUntil(this.unsubscribe$)).subscribe((res) => {    
      if(res.length!=0){
        this.product = res;
        this.getProduct();
      }
    });
  }
  private unsubscribe$ = new Subject<void>();
  ngAfterViewInit(){
  
  }
  getUserData() {
    this.userService.getUserSession().then(user => {
      if (user !== false) {
        this.userId = user.id;
      }
    });
    this.configSettings.getStoreObject().then((result) => {
      this.store = <Store>result;
    });
  }

  getProduct() {    
    if (this.product.configurable_option?.length) {
      for (let index = 0; index < this.product.configurable_option.length; index++) {
        const option = this.product.configurable_option[index];
        switch (option.attribute_code) {
          case 'Color':
            this.productColorObj = option;
            this.selectedColorText = this.productColorObj.attributes[0].value;
            ////console.log(this.productColorObj.attributes[0].option_id);
            setTimeout(() => {
              const colorBoxes = document.getElementById('color' + this.productColorObj.attributes[0].option_id) as HTMLElement;
              colorBoxes.classList.add('circle-active');
            }, 1000);
            break;
          case 'Size':
            this.productSizeObj = option;
            ////console.log(this.productSizeObj,'productSizeObj');            
            break;
          case 'Age':
            this.productAgeObj = option;
            break;
          default:
            break;
        }
      }
      this.onOptionSelectV2(this.product?.configurable_option[0], this.product?.configurable_option[0]?.attributes[0], 0, null);
      // this.cdr.detectChanges();
    }
  }

  // to get data based on selected configurable options
  onOptionSelectV2(attribute, option, i, $event) {
    this.clearColorFilter();
    this.clearSizeFilter();
    this.selectedAttribute[i];
    this.selectedAttribute[i] = attribute.attribute_id;
    this.selectedOption[i] = option.option_id;
    this.filterOptionsV2();
    this.addToCartText = 'ADD TO BAG';
  }
  clearColorFilter() {
    const colorBoxes = document.getElementsByClassName("circle-main");
    for (let i = 0; i < colorBoxes.length; i++) {
      colorBoxes[i].classList.remove("circle-active");
    }
    this.selectedColorArr = [];
  }
  clearSizeFilter() {
    const sizeBoxes = document.getElementsByClassName("size-box");
    for (let i = 0; i < sizeBoxes.length; i++) {
      sizeBoxes[i].classList.remove("active-size");
    }
    this.selectedSizeArr = [];
  }

  // to get configurable options
  filterOptionsV2() {
    this.configSettings.toggleLoading(true);
    const getParams = {
      lang: this.lang,
      store: this.store?.iso_code
    };

    const postParams = {
      product_id: this.product.id,
      attribute_id: (this.selectedAttribute.length) ? this.selectedAttribute.join(',') : '',
      option_id: (this.selectedOption.length) ? this.selectedOption.join(',') : ''
    };

    this.configService.postRequest('configurable-options', getParams, postParams)
      .subscribe(response => {
        if (response.data[0].images.length == 1) {
          this.product.images = response.data[0].images;
          this.product.images.unshift(this.product.images[0]);
        } else {
          this.product.images = response.data[0].images;
        }
        this.entityId = response.data[0].entity_id;

        if (response.data[0].attributes.length) {
          switch (response.data[0].type) {
            case 'Color':
            case 'اللون':
              this.productColorObj = response.data[0];
              this.selectedColorText = this.productColorObj.attributes[0].value;
              setTimeout(() => {
                const colorBoxes = document.getElementById('color' + this.productColorObj.attributes[0].option_id) as HTMLElement;
                colorBoxes.classList.add('circle-active');
              }, 1000);
              break;
            case 'Size':
            case 'القياس':
              this.productSizeObj = response.data[0];
              setTimeout(() => {
                if (this.productSizeObj.attributes[0].is_available != 0) {
                  const sizeBoxes = document.getElementById('size' + this.productSizeObj.attributes[0].option_id) as HTMLElement;
                  sizeBoxes.classList.add('active-size');
                }
              }, 1000);
              break;
            case 'Age':
            case 'العمر':
              this.productAgeObj = response.data[0];
              setTimeout(() => {
                if (this.productAgeObj.attributes[0].is_available != 0) {
                  const ageBoxes = document.getElementById('age' + this.productAgeObj.attributes[0].option_id) as HTMLElement;
                  ageBoxes.classList.add('active-age');
                }
              }, 1000);
              break;
            default:
              break;
          }
          ////console.log(response.data[0], 'response.data[0]');


          // this.quantityArr = [];
        } else {
          this.product.final_price = response.data[0].final_price;
          this.product.regular_price = response.data[0].regular_price;
          this.product.SKU = response.data[0].sku;
          this.product.remaining_quantity = (response.data[0].remaining_quantity) ? response.data[0].remaining_quantity : 0;
         //console.log(response.data[0].entity_id, 'response.data[0].entity_id');

          this.entityId = response.data[0].entity_id;

          if (this.product.remaining_quantity > 0) {
            // this.setQuantityDropdown(this.product.remaining_quantity);
          } else {
            // this.quantityArr = [];
          }
        }
        this.cdr.detectChanges();
        this.configSettings.toggleLoading(false);
      }, error => { this.configSettings.toggleLoading(false); });
    this.cdr.detectChanges();
  }
  // to configuration option - color
  setColor(color: any, colorObj: any) {
    let selectedColorId: any;
    this.clearColorFilter();
    const colorBoxes = document.getElementById('color' + color.option_id) as HTMLElement;
    colorBoxes.classList.add('circle-active');
    selectedColorId = `C${color.option_id}`;
    this.selectedColorText = color.value;
    if (!this.selectedAttribute.includes(colorObj.attribute_id)) {
      this.selectedAttribute.push(colorObj.attribute_id);
    }
    this.updateArrayValue(selectedColorId);
  }

  // to configuration option - size
  setSize(size: any, sizeObj: any) {
    let selectedSizeId: any;
    this.clearSizeFilter();
    const sizeBoxes = document.getElementById('size' + size.option_id) as HTMLElement;
    sizeBoxes.classList.add('active-size');
    selectedSizeId = `S${size.option_id}`;
    if (!this.selectedAttribute.includes(sizeObj.attribute_id)) {
      this.selectedAttribute.push(sizeObj.attribute_id);
    }
    this.updateArrayValue(selectedSizeId);
  }

  updateArrayValue(newValue) {
    let tempOptionArray: Array<any> = [];
    const newFirstCharacter = newValue.charAt(0); // Get the first character of the new value

    let updated = false; // Flag to check if the update has been made

    // Loop through the array to check for a match
    for (let i = 0; i < tempOptionArray.length; i++) {
      const existingValue = tempOptionArray[i];
      const existingFirstCharacter = existingValue.charAt(0); // Get the first character of the existing value

      if (existingFirstCharacter === newFirstCharacter) {
        // Replace the existing value with the new value
        tempOptionArray[i] = newValue;
        updated = true;
        break; // No need to continue searching since we already updated
      }
    }

    if (!updated) {
      // If no match was found, add the new value to the array
      tempOptionArray.push(newValue);
    }
    this.selectedOption = this.removeFirstCharacterFromArray(tempOptionArray);
    this.filterOptionsV2();
  }
  removeFirstCharacterFromArray(arr: string[]): any[] {
    return arr.map((str) => str.slice(1));
  }
  // to google analytics events
  private sendGTagEvent(event, params) {
    this.configService.sendGTagEvent(event, params);
  }
  // set default configuration when add to bag
  setDefaultConfiguration() {
    const checkTypeExistence = roleParam => this.selectedFilters.some(({ type }) => type == roleParam);

    if (this.selectedFilters.length != this.product.configurable_option.length) {
      if (this.product.configurable_option.length == 1) {
        if (this.product.configurable_option[0].attributes.length == 1) {
          if (!checkTypeExistence("Color")) {
            this.selectedFilters.push(this.product.configurable_option[0]);
          }
        }
      }
      if (this.product.configurable_option.length == 2) {
        if (this.product.configurable_option[0].attributes.length == 1) {
          if (!checkTypeExistence("Color")) {
            this.selectedFilters.push(this.product.configurable_option[0]);
          }
        }
        if (this.product.configurable_option[1].attributes.length == 1) {
          if (!checkTypeExistence("Size")) {
            this.selectedFilters.push(this.product.configurable_option[1]);
          }
        }
      }
    }
  }
  // to trigger add to bag api, add to bag analytic event and handel offline cart
  addToBag() {
    this.setDefaultConfiguration();
    this.addToCartText = '✓';
    try {
      if (this.product.is_saleable || this.product.is_preorder) {
       //console.log(1);

        // Adding event  
        try {
          this.analytics.addToCart(
            this.product.id,
            this.product.name,
            this.product.marketing_category + ' - ' + this.product.marketing_subcategory,
            this.product.brand_name,
            this.product.final_price,
            this.product.regular_price,
            this.quantity,
            this.product.configurable_option
          );
        } catch (e) {
        }
        const final_price = this.product.final_price_kwd * 3.30;
        try {
          // google analytics
          this.sendGTagEvent('add_to_cart', {
            items: [{
              id: this.product.SKU,
              name: this.product.name,
              list_name: 'Product Details',
              brand: this.product.brand_name,
              category: this.product.brand_name,
              variant: (this.product.configurable_option.length > 0) ?
                this.product.configurable_option[0].attributes[0].value : '',
              list_position: 1,
              quantity: this.quantity,
              price: final_price.toFixed(2)
            }]
          });
        } catch (e) {
        }

        if ((this.product.is_vip_product == 1) ? ((this.product.is_vip_product == this.isVip) ? true : false) : true) {
         //console.log(2);
          // this.configSettings.toggleLoading(true);
          ////console.log(this.entityId, 'this.entityId');

          if (this.entityId) {
            ////console.log(3);
            this.localStorage.getItem<any>('userInfo').subscribe((userInfo: any) => {
              if (userInfo) {
               //console.log(4);
                this.addToOnlineCart(userInfo).then((any) => {
                  this.configSettings.toggleLoading(false);
                  // const dialogRef = this.dialog.open(AddedToCartDialogComponent);
                  var itemAddedMsg = "Item added to your bag";
                  setTimeout(() => {

                    if (this.lang == 'ar') {
                      itemAddedMsg = 'العنصر يضاف إلى حقيبتك';
                    }
                    if (this.isMobile == false) {
                      this.addToCartText = 'PROCEED TO BAG';

                    }
                    if (this.isMobile == true) {
                      this.addToCartText = 'PROCEED TO PURCHASE';

                    }
                  }, 1500);
                  // this.toaster.success(itemAddedMsg);
                  this.handleCartService.sendItem(this.product);
                }, error => {

                  this.configSettings.toggleLoading(false);
                  this.addToCartText = 'ADD TO BAG';
                });
              } else {
                ////console.log(5);
                // to handel offline cart
                this.cartService.addToOfflineCart(this.entityId, this.product, this.selectedFilters, 1).then(response => {
                  this.configSettings.toggleLoading(false);
                  // const dialogRef = this.dialog.open(AddedToCartDialogComponent);
                  var itemAddedMsg = "Item added to your bag";
                  if (this.isMobile == false) {
                    this.addToCartText = 'PROCEED TO BAG';

                  }
                  if (this.isMobile == true) {
                    this.addToCartText = 'PROCEED TO PURCHASE';

                  }
                  if (this.lang == 'ar') {
                    itemAddedMsg = 'العنصر يضاف إلى حقيبتك';
                  }
                  // this.toaster.success(itemAddedMsg);
                  this.handleCartService.sendItem(this.product);
                });
              }
            }, error => {
              this.configSettings.toggleLoading(false);
              this.addToCartText = 'ADD TO BAG';
            });
          } else {
            ////console.log(6);
            // error handling
            this.configSettings.toggleLoading(false);
            let selects = document.querySelectorAll('ng-select');
            for (let i = 0; i < selects.length; i++) {
              if (this.selectedAttribute[i]) {
                selects[i].classList.remove('error');
              }
              else {
                selects[i].classList.add('error');
                this.addToCartText = 'ADD TO BAG';
              }
            }
          }
        } else {
          ////console.log(7);
          var msg = "Unfortunately, this product can't be added to your cart as it's exclusive for our Gold Members";
          if (this.lang == 'ar') {
            msg = 'ناسف، المنتج حصري للعملاء الذهبيين';
          }
          this.toaster.error(msg);
          this.addToCartText = 'ADD TO BAG';
        }
      }
    } catch (e) {
      ////console.log(8);
      this.configSettings.toggleLoading(false);
      this.addToCartText = 'ADD TO BAG';
    }
  }
  // to handel offline cart functionality
  private addToOnlineCart(user: string): Promise<any> {
    let closeBtn = document.getElementById('closeAddToBagMoal');
    return new Promise(resolve => {
      this.cryptService.doDecrypt(user).then(decryptedString => {
        const user = JSON.parse(decryptedString);

        const getParams = {
          lang: this.lang,
          store: this.store.iso_code
        };
        const postParams = {
          user_id: user.id,
          products: this.entityId,
          quantity: this.quantity
        };
        this.configService.postRequest('add-to-cart', getParams, postParams).subscribe(response => {
            if (response.status === 200) {
              closeBtn.click();
              this.configSettings.setCartId(response.data.id);
              this.cartService.changCartCount(response.data.items.length);
              this.cartService.setCartObservable(response.data);
              if(this.fromWishlist){
                this.removeFromWishlist();
                // this.CallWishlistService.setEntityId(this.product.id)
              }
              // this.count=2;
              this.CallWishlistService.setCallWishList(true);
              // this.WishlistComponent.ngOnInit();
              resolve(true);
            }
            if (response.status === 412) {
              this.product.remaining_quantity = 0;
              this.configSettings.toggleLoading(false);
              this.toaster.error(response.message);
              closeBtn.click();
              // this.count=2;
            }
            else {
              this.configSettings.toggleLoading(false);
              reject(response);
            }
          }, error => { reject(error); });
      });
    });
  }
  // notify me section - STARTS
  validatePhone(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event?.charCode);
    if (!pattern.test(inputChar)) {

      event.preventDefault();
    } else {

    }
  }
  // to handel notify me form submit
  submitNotifyMe() {
    this.notifyMeSubmitted = true;
    Object.keys(this.notifyMeForm.controls).forEach(field => {
      const control = this.notifyMeForm.get(field);
      control.markAsTouched({ onlySelf: true });
    });
    if (this.notifyMeForm.dirty && this.notifyMeForm.valid) {
      const _form = this.notifyMeForm.value;
      this.notifyMe(_form);
    }
  }
  notifyMeSubmitted: boolean = false;
  isNotifyMeLoading: boolean = false;
  notifyMe(data) {
    data.user_id = this.userId;
    data.product_id = this.product.id;
    this.isNotifyMeLoading = true;
    this.cartService.notifyMe({}, data).subscribe((response) => {
      this.isNotifyMeLoading = false;
      if (response.status === 200) {
        this.notifyMeSubmitted = false;
        this.notifyMeForm.reset();
        const message = (this.lang == 'ar') ?
          'لقد قمنا بإستلام طلبك وسنقوم بإبلاغك في حالة توفر هذا المنتج مجدادا'
          : 'We have received your request and will notify you once the product is back in stock';
        this.toaster.success(message);

        setTimeout(() => {
          let dismissNotifyModalBtn = document.getElementById('dismissNotifyModal') as HTMLButtonElement;
          ////console.log(dismissNotifyModalBtn, 'dismissNotifyModalBtn');
          dismissNotifyModalBtn.click();
        }, 100);
        // $('#notifyMeModal').modal('hide');
      }
    });
  }
  // to remove item from wishlist
  removeFromWishlist() {
    const getParams = {
      lang: this.lang,
      store: this.store.iso_code
    };

    const postParams = {
      product_id: Number(this.product.id),
      user_id: this.userId
    };

    this.wishlistService.removeFromWishlist(getParams, postParams)
      .pipe(first())
      .subscribe(
        response => {
          if (response.status === 200) {
            let items = response.data;
            this.userService.setWishlist(items);
            this.updateWishlistCountService.setWishlistCount(response?.wishlist_count)
          }
        }
      );

    return false;
  }
  closeBag(){
   let btn=document.getElementById('closeAddToBagMoal');
   btn.click();
  }
  clearAll() {
    this.unsubscribe$.complete();
    // this.count=0;
  }
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.productGet$.unsubscribe();
    // this.count=0;
  }
}
