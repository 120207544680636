let hostName = window.location.hostname;
let substring = "staging.shop-twl.com";
let url = 'https://dev.shop-twl.com/api/ver9api/';
let substringLive = "shop-twl.com";
let webWishlist="web.thewishlist.com";
let ppWishList="pp.thewishlist.com"
let newLive="thewishlist.com"
if(hostName.includes(webWishlist)){
  url='https://admin-cp.thewishlist.com/api/ver3api/';
}else if(hostName.includes(ppWishList) || hostName.includes(newLive)){
  url='https://admin-cp.thewishlist.com/api/ver3api/';
}
else{
  url = 'https://admin-cp.thewishlist.com/api/ver3api/';
}

export const environment = {
  production: true,
  apiUrl: url,
  branchIoTest: 'key_test_lFmkZhJjUZEvk74YruOLmeadvsciSHD2',
  branchIoLive: 'key_live_fqlaWdSfMZEym477yFUUfhefEEegJQwK',
  // apiUrlDev: 'https://admin.shop-twl.com/api/ver9api/',
};