<span id="topOfPage"></span>
<section *ngIf="skeletonLoader" class="container-fluid">
  <div class="mt-0 mt-md-4 mt-md-0 section-div">
    <ul class=" p-0 pt-md-4 d-flex flex-wrap justify-content-center align-items-center listing-page-routing">
    </ul>
    <div class="w-100 main-details-div pading-mange">
      <div class="slider-div">
        <div class="position-relative slick-slider-image items-height-skeleton">
          <ngx-skeleton-loader class="height-fix" [theme]="skeltonTheme"></ngx-skeleton-loader>
        </div>
      </div>
      <div class="products-detail">
        <div class="details-main-box position-relative">
          <div class="pb-3 details-top">
            <div class="product-details-name">
              <div class="d-flex justify-content-between gap-3  align-items-center">
                <ngx-skeleton-loader class="w-75"></ngx-skeleton-loader>
                <div class="d-flex w-25 gap-3 align-items-center">
                  <ngx-skeleton-loader class="w-50"></ngx-skeleton-loader>
                  <ngx-skeleton-loader class="w-50"></ngx-skeleton-loader>
                </div>
              </div>

              <p class="w-25">
                <ngx-skeleton-loader></ngx-skeleton-loader>
              </p>
            </div>

            <div class="d-flex justify-content-between gap-4 align-items-center">
              <div class="d-flex w-75 align-items-center gap-2 product-details-price">
                <ngx-skeleton-loader class="w-25"></ngx-skeleton-loader>
                <ngx-skeleton-loader class="w-25"></ngx-skeleton-loader>
              </div>
              <!-- <ngx-skeleton-loader class="w-25"></ngx-skeleton-loader> -->
            </div>
          </div>

          <div class="py-3 details-center">
            <div class="color-text d-flex align-items-center gap-2  w-25">
              <ngx-skeleton-loader class="w-50"></ngx-skeleton-loader>
              <ngx-skeleton-loader class="w-50"></ngx-skeleton-loader>
            </div>
            <div class="d-flex flex-wrap gap-2 ">
              <div class="skelton-circle-main" *ngFor="let color of [1,2,3]">
                <ngx-skeleton-loader class="skelton-circle"></ngx-skeleton-loader>
              </div>
            </div>
            <div class="pb-3 size_padding">
              <div class="product-size d-flex justify-content-between align-items-center pb-2">
                <div class="d-flex gap-2 w-50">
                  <ngx-skeleton-loader class="w-25"></ngx-skeleton-loader>
                  <ngx-skeleton-loader class="w-25"></ngx-skeleton-loader>
                </div>
                <p class="view-size w-25">
                  <ngx-skeleton-loader></ngx-skeleton-loader>
                </p>
              </div>
              <div class="size-sec">
                <div class="w-25 size-box-ngx-skeleton-loader" *ngFor="let size of [1,2,3]">
                  <ngx-skeleton-loader></ngx-skeleton-loader>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-between align-items-center gap-3 w-100">
              <div class="add-btn-div mt-3 pb-1 w-100 skelton-btn-height">
                <ngx-skeleton-loader class="w-100 height-fix"></ngx-skeleton-loader>
              </div>
            </div>
          </div>

          <div class="pt-3 details-accordion">
            <div class="accordion" id="accordions">
              <div class="accordion-item">
                <div class="d-flex justify-content-between w-100 gap-4" *ngFor="let item of [1,2,3]">
                  <ngx-skeleton-loader class="w-75  height-fix"></ngx-skeleton-loader>
                  <ngx-skeleton-loader class="w-25  height-fix"></ngx-skeleton-loader>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="d-block d-md-flex justify-content-between align-items-center py-3 gap-4 w-100">

              <div class="d-flex align-items-center gap-2 w-50">
                <!-- <span class="product-code-label"> -->
                <ngx-skeleton-loader class="w-75"></ngx-skeleton-loader>
                <!-- {{'Need more information?'|translate}} -->
                <!-- </span> -->
                <!-- <span class="product-code"> -->
                <ngx-skeleton-loader class="w-25"></ngx-skeleton-loader>
                <!-- <a [routerLink]="'/contact-us'">{{'Contact Us'|translate}}</a> -->
                <!-- </span> -->
              </div>

              <div class="d-flex align-items-center gap-2 w-50">
                <ngx-skeleton-loader class="w-50"></ngx-skeleton-loader>
                <ngx-skeleton-loader class="w-50"></ngx-skeleton-loader>
              </div>

            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</section>

<section *ngIf="dataAvailable && !skeletonLoader" class="container-fluid">
  <div class="mt-4 mt-md-0 section-div">
    <div class="pt-md-4  brad-comp-sticky">
    <ul class="d-none d-md-flex flex-wrap justify-content-center align-items-center listing-page-routing">
      <li class="routing-list" [routerLink]="['/brand', configSettings.cleanUrl(product.brand_name), product.brand_id]">
        {{product.brand_name}}</li>
      <li *ngFor="let item of breadcumb| slice:2" class="routing-list">{{item.path | titlecase}}</li>
    </ul>
  </div>
    <!-- product details -->
    <div class="custom-conatiner pading-mange">
      <div class="w-100 main-details-div">

        <div class="slider-div">
          <div class="position-relative">
            <ngx-slick-carousel (afterChange)="currentImageIndex = $event.currentSlide;" class="carousel gallery-main"
              #slickModal="slick-carousel" [config]="slideConfig">
              <div *ngFor="let image of product.images; let i = index;" class="slide">
                <div ngxSlickItem class="slick-slider-image">
                  <!-- <picture>
                    <source src="" type="">
                  </picture> -->
                  <div *ngIf="product.regular_price != product.final_price" class="new_sale">
                    <img  src="/assets/icons/SALE-Tag.svg" alt="">
                    <span>
                      <span>-</span>{{ calculateDiscountAmount(product)}}<span>%</span>
                    </span>
                  </div>
                  <img src="{{image}}" (click)="showGallery(i)" />
                </div>
              </div>
            </ngx-slick-carousel>
          </div>
          <div class="main-thumb-product d-none d-lg-block">
            <ngx-slick-carousel class="carousel gallery-thumb" [config]="thumbSlideConfig">
              <div ngxSlickItem *ngFor="let image of product.images; let i = index;" class="slide">
                <div class="thumb-imgs">
                  <img src="{{image}}" class="" />
                </div>
              </div>
            </ngx-slick-carousel>
          </div>
        </div>

        <div class="products-detail">
          <div class="details-main-box position-relative">

            <div class="pb-3 details-top">

              <div class="product-details-name">
                <div class="d-flex justify-content-between align-items-center">
                  <a [routerLink]="['/brand', configSettings.cleanUrl(product.brand_name), product.brand_id]">
                    {{product.brand_name}}
                  </a>
                  <div class="d-flex gap-3 align-items-center">
                    <img src="../../assets/icons/shear-icon.svg" alt=""  class="heart-image" (click)="share()">
                    <div  class="heart-container" (click)="toggleWishList($event)">
                      <img  *ngIf="!product.item_in_wishlist" appClickToFav src="../../assets/icons/new-Wishlist-icon.svg" alt=""
                        class="heart-image add-to-wishlist">
                      <img class="heart-image" *ngIf="product.item_in_wishlist" appClickToFav src="assets/icons/heart-filled.svg"
                        alt="">
                        <div class="big-heart">
                          <div *ngFor="let subHeart of subHearts; let i = index"  [class.fill-heart]="isHeartFilled">
                            <svg viewBox="0 0 24 22" xmlns="http://www.w3.org/2000/svg" class="sub-hearts move-heart-{{ i }}"
                              [style.top]="subHeart.moveTop + '%'" [style.left]="subHeart.moveLeft + '%'" [style.bottom]="subHeart.moveBottom + '%'" [style.right]="subHeart.moveRight + '%'">
                              <path d="M11.0661 2.81728L11.6026 3.45246L12.1391 2.81728C13.2867 1.45859 15.0479 0.600098 16.8697 0.600098C20.0815 0.600098 22.6051 3.13599 22.6051 6.38807C22.6051 8.38789 21.72 10.2564 20.0229 12.3496C18.3175 14.4529 15.8645 16.6977 12.8407 19.4622L12.826 19.4756L12.8249 19.4766L11.6008 20.6001L10.3798 19.4879L10.3783 19.4866L10.3336 19.4457C10.3336 19.4457 10.3336 19.4457 10.3336 19.4457C7.31862 16.6834 4.87347 14.4409 3.17365 12.3404C1.4821 10.25 0.600098 8.38442 0.600098 6.38807C0.600098 3.13599 3.12361 0.600098 6.33544 0.600098C8.15729 0.600098 9.91845 1.45859 11.0661 2.81728Z"
                                stroke="#333333" stroke-width="1" [style.fill]="subHeart.color" />
                            </svg>
                          </div>
                        </div>
                    </div>
                  </div>
                </div>

                <p class="w-75">{{product.name}}</p>
              </div>

              <div class="d-flex justify-content-between align-items-center">
                <div class="d-flex align-items-center gap-2 product-details-price">
                  <p class="d-flex gap-1" [ngClass]="(product.regular_price != product.final_price) ? 'discount-price' : ''">
                    <span>
                      {{currency}}
                      {{product.final_price | number:'1.3-3'}} 
                    </span>
                  </p>
                  <p *ngIf="(product.regular_price != product.final_price)">
                    <span class="d-flex gap-1 line-through">
                      <span>
                        {{currency}}
                        {{product.regular_price | number:'1.3-3'}}  
                      </span>
                    </span>
                  </p>
                </div>
                <span class="best-seller">{{setProductBadge(product)}}</span>
              </div>
            </div>

            <div class="py-3 details-center">

              <div class="color-text d-flex align-items-center gap-2 pb-2">
                <p class="color-label">{{'COLOR:'|translate}}</p>
                <p class="color-name">{{selectedColorText | uppercase}}</p>
              </div>
              <div class="d-flex flex-wrap gap-2">
                <!-- <div class="circle-main circle-active">
                <span class="circle"></span>
              </div> -->
                <div class="circle-main" (click)="setColor(color,productColorObj)" [id]="'color'+color?.option_id"
                  *ngFor="let color of productColorObj.attributes">
                  <span class="circle" [style.background]="color?.color?.toLowerCase()"></span>
                </div>
              </div>
              <!-- <button (click)="clearArr()">Clear</button> -->
              <div class="pb-3 size_padding"
              >
              <div *ngIf="product.size_guide" class="product-size d-flex justify-content-between align-items-center pb-2">
                <div class="d-flex gap-2">
                <p class="">{{'SIZE:'|translate}}</p>
                <p class="">{{selectedSize?.value}}</p>
              </div>
                <p class="view-size"><a data-toggle="modal" data-target="#sizeGuideModal">{{'View Size Guide'|translate}}</a></p>
              </div>
              <div class="size-sec" *ngIf="productSizeObj && productSizeObj.attributes && productSizeObj.attributes.length > 0">
                <div class="size-box"  [ngClass]="{ 'out-of-stock': size?.is_available == 0 }" (click)="setSize(size,productSizeObj)" [id]="'size'+size?.option_id"
                  *ngFor="let size of productSizeObj.attributes">
                  <p>{{size?.value}}</p>
                </div>
              </div>
            </div>

              <div class="pb-3"
                *ngIf="productAgeObj && productAgeObj.attributes && productAgeObj.attributes.length > 0">
                <div class="product-age d-flex justify-content-between align-items-center pb-2">
                  <div class="d-flex">
                    <p class="">{{'AGE:'|translate}}</p>
                    <p class="">{{selectedAge?.value}}</p>
                  </div>
                  <p class="view-age"><a>{{'View age guide'|translate}}</a></p>
                </div>
                <div class="size-sec">
                  <div class="age-box" title="This is a
                  multiline title" [ngClass]="{ 'out-of-stock': age?.is_available == 0 }" (click)="setAge(age, productAgeObj)" [id]="'age'+age?.option_id"
                    *ngFor="let age of productAgeObj.attributes">
                    <p>{{age?.value}}</p>
                  </div>
                </div>
              </div>


              <!-- <div class="add-btn-div my-2">
              <button class="w-100 add-btn">
                ADD TO BAG
              </button>
            </div> -->

              <div class="d-flex justify-content-between align-items-center gap-3 w-100">

                <!-- <div class="add-btn-div my-2 w-100">
                  <button type="button" class="w-100 add-btn" (click)="addToBag()"
                    *ngIf="product.remaining_quantity > 0">
                    {{'ADD TO BAG' | translate}}
                  </button>
                  <button type="button" class="w-100 add-btn text-uppercase" (click)="addToBag()"
                    *ngIf="product.is_preorder == 1 && product.remaining_quantity <= 0">
                    {{'Pre Order' | translate}}
                  </button>
                  <button type="button" class="w-100 add-btn" data-toggle="modal" data-target="#notifyMeModal"
                    *ngIf="product.is_preorder == 0 && product.remaining_quantity <= 0">
                    {{'NOTIFY ME' | translate}}
                  </button>
                </div> -->
                  <div class="add-btn-div mt-3 pb-1 w-100" *ngIf="this.addToCartText != '✓'">
                    <button type="button" class="w-100 add-btn button_hover"
                      (click)="addToBag()"
                      *ngIf="product.remaining_quantity > 0 && (addToCartText == 'ADD TO BAG' || addToCartText == '✓')"
                      [ngStyle]="{ 'font-size': addToCartText == '✓' ?   '18px': '' }" [disabled]="addToCartText == '✓'">
                      <!-- <img src="../../assets/ArtWork/plusicon.png"
                        *ngIf="addToCartText == 'ADD TO BAG'"> -->
                        {{addToCartText | translate | uppercase}}
                    </button>
                    <button type="button" class="w-100 add-btn position-relative button_hover"
                      *ngIf="product.remaining_quantity > 0 && (addToCartText == 'PROCEED TO PURCHASE' || addToCartText == 'PROCEED TO BAG')"
                      (click)="openCart()">
                      <p class="fadeInRight">
                        <img class=" bagImg" src="/assets/ArtWork/white-bag.svg">&nbsp; {{addToCartText | translate | uppercase}} &nbsp;
                        <span class="arrowText" >→</span>
                      </p>
                    </button>
                    <button type="button" class="w-100 add-btn button_hover"
                      (click)="addToBag()" *ngIf="product.is_preorder == 1 && product.remaining_quantity <= 0">
                      <!-- <img src="/assets/ArtWork/plusicon.png"> -->
                      {{'Pre Order' | translate | uppercase}}
                    </button>
                    <button type="button" class="w-100 add-btn button_hover" data-toggle="modal"
                      data-target="#notifyMeModal"
                      *ngIf="product.is_preorder == 0 && product.remaining_quantity <= 0">
                      <!-- <img src="/assets/ArtWork/plusicon.png"> -->
                      {{'Notify me' | translate | uppercase}}
                    </button>
                  </div>

                  <div class="add-btn-div mt-3 pb-1 w-100" *ngIf="this.addToCartText == '✓'">
                    <button type="button" class="w-100 add-btn button_hover">
                      <img src="../../assets/icons/add-to-bag-tick.svg">
                    </button>
                  </div>

                <!-- <div class="w-100">
                  <button type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample"
                    aria-controls="offcanvasExample" class="shop_look">Shop The Look</button>
                </div> -->

              </div>

            </div>

            <div class="pt-3 details-accordion">

              <div class="accordion" id="accordions" *ngIf="product">

                <div class="accordion-item" *ngIf="product.description">
                  <h2 id="accordionheading">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#accordion" aria-expanded="false" aria-controls="accordion">
                      {{'Description' | translate}}
                    </button>
                  </h2>
                  <div id="accordion" class="accordion-collapse collapse" aria-labelledby="accordionheading"
                    data-bs-parent="#accordions">
                    <div class="accordion-body">
                      {{product.description}}
                    </div>
                  </div>
                </div>

                <div class="accordion-item" *ngIf="product.fit_and_size">
                  <h2 id="accordionheading1">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#accordion1" aria-expanded="false" aria-controls="accordion1">
                      {{'Fit and size' | translate}}
                    </button>
                  </h2>
                  <div id="accordion1" class="accordion-collapse collapse" aria-labelledby="accordionheading1"
                    data-bs-parent="#accordions">
                    <div class="accordion-body">
                      {{product.fit_and_size}}
                    </div>
                  </div>
                </div>

                <div class="accordion-item" *ngIf="product.how_to_use">
                  <h2 id="accordionheading2">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#accordion2" aria-expanded="false" aria-controls="accordion2">
                      {{'How to use' | translate}}
                    </button>
                  </h2>
                  <div id="accordion2" class="accordion-collapse collapse" aria-labelledby="accordionheading2"
                    data-bs-parent="#accordions">
                    <div class="accordion-body">
                      {{product.how_to_use}}
                    </div>
                  </div>
                </div>

                <div class="accordion-item" *ngIf="product.if_you_like">
                  <h2 id="accordionheading4">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#accordion3" aria-expanded="false" aria-controls="accordion3">
                      {{'If you like' | translate}}
                    </button>
                  </h2>
                  <div id="accordion3" class="accordion-collapse collapse" aria-labelledby="accordionheading4"
                    data-bs-parent="#accordions">
                    <div class="accordion-body">
                      {{product.if_you_like}}
                    </div>
                  </div>
                </div>

                <div class="accordion-item" *ngIf="product.ingredients">
                  <h2 id="accordionheading5">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#accordion4" aria-expanded="false" aria-controls="accordion4">
                      {{'Ingredients' | translate}}
                    </button>
                  </h2>
                  <div id="accordion4" class="accordion-collapse collapse" aria-labelledby="accordionheading5"
                    data-bs-parent="#accordions">
                    <div class="accordion-body">
                      {{product.ingredients}}
                    </div>
                  </div>
                </div>

                <div class="accordion-item" *ngIf="product.preorder_note">
                  <h2 id="accordionheading6">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#accordion5" aria-expanded="false" aria-controls="accordion5">
                      {{'Pre order note' | translate}}
                    </button>
                  </h2>
                  <div id="accordion5" class="accordion-collapse collapse " aria-labelledby="accordionheading6"
                    data-bs-parent="#accordions">
                    <div class="accordion-body">
                      {{product.preorder_note}}
                    </div>
                  </div>
                </div>

                <div class="accordion-item" *ngIf="product.shipping_free_returns">
                  <h2 id="accordionheading7">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#accordion6" aria-expanded="false" aria-controls="accordion6">
                      {{'Shipping free returns' | translate}}
                    </button>
                  </h2>
                  <div id="accordion6" class="accordion-collapse collapse " aria-labelledby="accordionheading7"
                    data-bs-parent="#accordions">
                    <div class="accordion-body">
                      {{product.shipping_free_returns}}
                    </div>
                  </div>
                </div>

                <div class="accordion-item" *ngIf="product.short_description">
                  <h2 id="accordionheading8">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#accordion7" aria-expanded="false" aria-controls="accordion7">
                      {{'Short description' | translate}}
                    </button>
                  </h2>
                  <div id="accordion7" class="accordion-collapse collapse " aria-labelledby="accordionheading8"
                    data-bs-parent="#accordions">
                    <div class="accordion-body">
                      {{product.short_description}}
                    </div>
                  </div>
                </div>

                <div class="accordion-item" *ngIf="product.skin_type">
                  <h2 id="accordionheading9">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#accordion9" aria-expanded="false" aria-controls="accordion9">
                      {{'Skin Type' | translate}}
                    </button>
                  </h2>
                  <div id="accordion9" class="accordion-collapse collapse " aria-labelledby="accordionheading9"
                    data-bs-parent="#accordions">
                    <div class="accordion-body">
                      {{product.skin_type}}
                    </div>
                  </div>
                </div>

                <div class="accordion-item" *ngIf="product.specification">
                  <h2 id="accordionheading10">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#accordion10" aria-expanded="false" aria-controls="accordion10">
                      {{'Specification' | translate}}
                    </button>
                  </h2>
                  <div id="accordion10" class="accordion-collapse collapse " aria-labelledby="accordionheading10"
                    data-bs-parent="#accordions">
                    <div class="accordion-body">
                      {{product.specification}}
                    </div>
                  </div>
                </div>

                <div class="accordion-item" *ngIf="product.type_in_product">
                  <h2 id="accordionheading11">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#accordion11" aria-expanded="false" aria-controls="accordion11">
                      {{'Type in product' | translate}}
                    </button>
                  </h2>
                  <div id="accordion11" class="accordion-collapse collapse " aria-labelledby="headingOneDESK11"
                    data-bs-parent="#accordions">
                    <div class="accordion-body">
                      {{product.type_in_product}}
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div>
              <div class="d-block d-md-flex justify-content-between align-items-center py-3">

                <div class="d-flex align-items-center gap-2 ">
                  <span class="product-code-label">
                    {{'Need more information?'|translate}}
                  </span>
                  <span class="product-code">
                    <a [routerLink]="'/contact-us'">{{'Contact Us'|translate}}</a>
                  </span>
                </div>

                <div class="d-flex align-items-center gap-2">
                  <span class="product-code-label2">
                    {{'SKU:'|translate}}
                  </span>
                  <span class="product-code">
                    {{product?.SKU}}
                  </span>
                </div>

              </div>
            </div>
            
          </div>
        </div>

      </div>
    </div>


    <div class="section_padding custom-conatiner pb-5" *ngIf="product.match_with_category.length > 0">
      <p class="heading-also text-center">{{'Complete the Look'|translate}}</p>

      <div class="match-main-div">
        <div class="match-div-first">
          <div class="gap-4 d-flex" [routerLink]="['/product/detail/'+product.id]" routerLinkActive="router-link-active"
            *ngFor="let product of product.match_with_category; let i = index" >
            <div class="match-product">
              <figure>
                <div class="match-div-sec">
                  <img [src]="product.image" [alt]="product.name">
                </div>
              </figure>
            </div>
            <div class="">
              <div class="match-product-name">
                <p class="match-brand-name">{{product.brand_name}}</p>
                <p class="match-name">{{product.short_description}}</p>
              </div>
              <p class="match-product-price">KD {{product.final_price}}</p>
            </div>

          </div>

        </div>
        <div class="match-image-main-div">
        <div class="match-image-div">
          <figure>
            <div class="match-div-sec">
              <img [src]="product.images[0]" alt="">
            </div>
          </figure>
        </div>
      </div>
      </div>

    </div>

    <!-- YOU MAY ALSO LIKE -->
    <div class="custom-conatiner section_padding">
      <div class="also-div" *ngIf="product.related_products.length">
        <p class="heading-also">{{'YOU MAY ALSO LIKE'|translate}}</p>
        <div class="also-slider">
          <ngx-slick-carousel class="carousel" [config]="slideConfigYouMAyLike">
            <div ngxSlickItem *ngFor="let item of product.related_products" class="slide mx-2 mr-md-4 you-may-like">
              <app-product-item [lang]="lang" [item]="item" [currency]="currency" [store]="store" [userId]="userId"
                [returnUrl]="'home'" [minimal]="false">
              </app-product-item>
            </div>
          </ngx-slick-carousel>
        </div>
      </div>

    </div>


    <div class="d-flex justify-content-center align-items-center p-2 ">
      <button class="scroll-to-top-button" (click)="scrollToTop()">
        <img src="/assets/images/arrow-up.png" class="scroll-to-top-img">
        <span class="scroll-to-top-title">{{'BACK TO TOP' | translate}}</span>
      </button>
    </div>

  </div>

</section>


<app-shop-the-look-side-bar></app-shop-the-look-side-bar>


<!-- NOTIFY ME MODAL STARTS -->

<div class="modal fade" id="notifyMeModal" tabindex="-1" role="dialog" aria-labelledby="notifyMeModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      

      <div class="edit_data">
        <div class="edit_data_header">
            <div class="cross-div">
              <img class="cross_popup" src="/assets/icons/cross_popup.svg" alt="Cross" data-dismiss="modal"data-target="#notifyMeModal" id="dismissNotifyModal">
            </div>
              <div class="d-flex align-items-center justify-content-center">
                  <h1>{{ "Notify Me" | translate }}</h1>
              </div>
              <p class="edit-text">{{'Fill in the details below & you will be notified via email once the product is back in stock.'|translate}}</p>
            
        </div>
        <form [formGroup]="notifyMeForm" autocomplete="off" (ngSubmit)="submitNotifyMe()">
        <div class="edit_data_body">
          <div>
            <label >{{'Full Name' | translate}}<span>*</span></label>
            <input type="text" name="name" formControlName="name" required>
            <small class="text-danger"
              *ngIf="notifyMeSubmitted && notifyMeForm.controls['name'].errors?.required">
              {{'Please enter your full name' | translate}}</small>
          </div>
          <div>
            <label>{{'Email Address' | translate}}<span>*</span></label>
            <input type="email" name="email" formControlName="email" required>
            <small class="text-danger"
              *ngIf="notifyMeSubmitted && notifyMeForm.controls['email'].errors?.required">{{'Please enter your email' |
              translate}}</small>
            <small class="text-danger" *ngIf="notifyMeForm.controls['email'].hasError('requiredPattern')">
              {{'Please enter your email address' | translate}}</small>
          </div>

          <div>
            
            <div class="d-flex gap-3">
              <div class="w-25">
              <label>{{'Phone Code' | translate}}<span>*</span></label>
              <input  disabled placeholder={{countryCode}}>
            </div>
            <div class="w-75" >
              <label>{{'Phone Number' | translate}}<span>*</span></label>
              <input type="text" name="phone" formControlName="phone"
                maxlength="10" minlength="5" required (keypress)="key($event)">
              <small class="text-danger"
                *ngIf="notifyMeSubmitted && notifyMeForm.controls['phone'].errors?.required">
                {{'Please enter your mobile number' | translate}}</small>
              <small class="text-danger" *ngIf="notifyMeForm.controls['phone'].hasError('requiredPattern')">
                {{'Please enter a valid mobile number' | translate}}</small>
            </div>
            </div>
          </div>

        </div>

        <div class="edit_data_footer text-center">
          <button type="submit" [ngClass]="{'disable-button-styles':!notifyMeForm.valid}" [disabled]="!notifyMeForm.valid">{{'SUBMIT' | translate}}</button>
        </div>
        </form>
      </div>


      <!-- <div class="modal-body p-5">
        <button hidden data-dismiss="modal"data-target="#notifyMeModal" id="dismissNotifyModal">close</button>
        <p>{{'Fill in the details below & you will be notified via email once the product is back in stock.' |
          translate}}</p>

        <form [formGroup]="notifyMeForm" autocomplete="off" (ngSubmit)="submitNotifyMe()">

          <div class="form-group required">
            <label class="control-label w-100" [class.text-right]="lang == 'ar'">{{'Full Name' | translate}}</label>
            <input type="text" class="form-control text-input" name="name" formControlName="name" required>
            <small class="text-danger"
              *ngIf="notifyMeSubmitted && notifyMeForm.controls['name'].errors?.required">
              {{'Please enter your full name' | translate}}</small>
          </div>

          <div class="form-group required">
            <label class="control-label w-100" [class.text-right]="lang == 'ar'">{{'Email Address' | translate}}</label>
            <input type="email" class="form-control text-input" name="email" formControlName="email" required>
            <small class="text-danger"
              *ngIf="notifyMeSubmitted && notifyMeForm.controls['email'].errors?.required">{{'Please enter your email' |
              translate}}</small>
            <small class="text-danger" *ngIf="notifyMeForm.controls['email'].hasError('requiredPattern')">
              {{'Please enter your email address' | translate}}</small>
          </div>

          <div class="form-group required">
            <label class="control-label w-100" [class.text-right]="lang == 'ar'">{{'Mobile Number' | translate}}</label>
            <div class="input-field-address-mobile_number">
              <input class="form-control text-input code" disabled placeholder={{countryCode}}>
              <input type="text" class="form-control text-input phoneNumber" name="phone" formControlName="phone"
                maxlength="10" minlength="5" required>
              <small class="text-danger"
                *ngIf="notifyMeSubmitted && notifyMeForm.controls['phone'].errors?.required">
                {{'Please enter your mobile number' | translate}}</small>
              <small class="text-danger" *ngIf="notifyMeForm.controls['phone'].hasError('requiredPattern')">
                {{'Please enter a valid mobile number' | translate}}</small>
            </div>
          </div>

          <button type="submit" style="background: black;color: white; cursor: pointer;" class="btn btn-submit btn-block w-75 mx-auto mt-5"
            [disabled]="!notifyMeForm.valid">
            {{'SUBMIT' | translate}}
          </button>

        </form>

      </div> -->
      <!--                <div class="modal-footer">-->
      <!--                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>-->
      <!--                    <button type="button" class="btn btn-primary">Save changes</button>-->
      <!--                </div>-->
    </div>
  </div>
</div>
<!-- NOTIFY ME MODAL ENDS -->


<div class="modal fade" id="sizeGuideModal" tabindex="-1" role="dialog" aria-labelledby="sizeGuideModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content rounded-0 border-0">
      <div class="modal-header py-2 rounded-0">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <!-- <span aria-hidden="true">&times;</span> -->
          <img src="../../assets/icons/cross_popup.svg" alt="close">
        </button>
      </div>
      <div class="modal-body p-0" *ngIf="product">
        <img class="img-fluid" [src]="product.size_guide">
      </div>
    </div>
  </div>
</div>
<app-share-popup [data]="product" [type]="'productListing'"></app-share-popup>