<div class="checkout-progress">
  <div class="progress-meter">
    <ol class="progress-points" [attr.data-step]="steps">
      <li class="steps" [ngClass]="{'over': (steps > 0)}" data-point="1">
        <p class="progress-label">
          {{'SIGN IN' | translate}}
        </p>
      </li>
      <li class="steps" [ngClass]="{'active': (steps === 1), 'over': (steps > 1)}" data-point="2">
        <p class="progress-label">
          {{'SHIPPING' | translate}}
        </p>
      </li>
      <li class="steps" [ngClass]="{'active': (steps === 2), 'over': (steps > 2)}" data-point="3">
        <p class="progress-label">
          {{'PAYMENT' | translate}}
        </p>
      </li>
      <li class="steps" [ngClass]="{'active': (steps === 3)}" data-point="4">
        <p class="progress-label">
          {{'CONFIRMATION' | translate}}
        </p>
      </li>
    </ol>
    <div class="track">
      <span class="progress"></span>
    </div>
  </div>
</div>