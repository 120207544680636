<div class="gallery" *ngIf="data?.length > 0">
    <!-- <div class="gallery__item gallery__item--hor">
      <img src="https://source.unsplash.com/random/500x500" alt="">
    </div> -->
    <!-- <div class="gallery__item">
      <img src="https://source.unsplash.com/collection/190727/300x300" alt="">
    </div> -->
    <!-- <div class="gallery__item">
      <img src="https://source.unsplash.com/user/erondu/600x600" alt="">
    </div> -->
    <!-- <div class="gallery__item gallery__item--vert">
      <img src="https://source.unsplash.com/user/timmarshall/300x300" alt="">
    </div> -->
    <!-- <div class="gallery__item gallery__item--lg" >
      <div class="product_image">
        <figure>
          <div class="image-wrapper">
            <img (click)="routing(data[0])" [src]="isMobile ? data[0].mob_image : data[0].image" alt="">
          </div>
        </figure>
      </div>
      
    </div>
    <div class="gallery__item" (click)="routing(item)" *ngFor="let item of data; let i = index" [ngClass]="{'d-none': i == 0}">
      <div class="product_image">
        <figure>
          <div class="image-wrapper">
            <img [src]="isMobile ? item.mob_image : item.image" alt=""> 
          </div>
        </figure>
      </div>
    </div> -->

    <div class="parent">
      <div class="big-image">
        <figure>
          <div class="image-wrapper">
            <img class="side-big-image" (click)="routing(data[0])" [src]="isMobile ? data[0].mob_image : data[0].image" alt="">
          </div>
        </figure>
      </div>
      <div class="small-images-container">
        <div class="small-images" *ngFor="let chunks of smallImageArr" >
          <div class="small-single-images" (click)="routing(item)" *ngFor="let item of chunks; let i = index">
            <figure>
              <div class="image-wrapper">
                <img class="side-grid-image" [src]="isMobile ? item.mob_image : item.image" alt=""> 
              </div>
            </figure>
          </div>
        </div>
      </div>
      <!-- <div class="div3"> </div>
      <div class="div4"> </div>
      <div class="div5"> </div>
      <div class="div6"> </div>
      <div class="div7"> </div>
      <div class="div8"> </div>
      <div class="div9"> </div> -->
      </div>

    <!-- <div class="gallery__item">
      <img src="https://source.unsplash.com/user/liamsimpson/600x600" alt="">
    </div>
    <div class="gallery__item">
      <img src="https://source.unsplash.com/user/stephanvancephoto/300x300" alt="">
    </div>
    <div class="gallery__item">
      <img src="https://source.unsplash.com/user/beataratuszniak/300x300" alt="">
    </div>
    <div class="gallery__item">
      <img src="https://source.unsplash.com/user/tonill/300x300" alt="">
    </div>
    <div class="gallery__item">
        <img src="https://source.unsplash.com/user/tonill/300x300" alt="">
      </div>
      <div class="gallery__item">
        <img src="https://source.unsplash.com/user/tonill/300x300" alt="">
      </div>
      <div class="gallery__item">
        <img src="https://source.unsplash.com/user/tonill/300x300" alt="">
      </div>
      <div class="gallery__item">
        <img src="https://source.unsplash.com/user/tonill/300x300" alt="">
      </div>
      <div class="gallery__item">
        <img src="https://source.unsplash.com/user/tonill/300x300" alt="">
      </div>
      <div class="gallery__item">
        <img src="https://source.unsplash.com/user/tonill/300x300" alt="">
      </div> -->
  </div>
