<div class="modal fade" id="modelterms" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="model-box">
                <div class="close">
                    <img src="../../assets/icons/cross_popup.svg" alt="" data-bs-dismiss="modal" aria-label="Close">
                </div>
                <h2>Terms & Conditions</h2>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. In impedit magni nisi aspernatur? Vitae rem maxime delectus natus doloremque, tenetur in omnis? Fugiat laudantium error et. Reprehenderit nisi eum nam minus, consectetur provident earum odio. Aperiam nam illo placeat cum asperiores repellendus reprehenderit totam, officia magni sed voluptatibus enim voluptatum odio dolores earum. Voluptatum sint dolorem expedita doloremque eligendi maxime ad similique cumque alias eius ullam quia numquam pariatur, corrupti quod illo architecto fugiat necessitatibus et doloribus? Eaque amet dolore ab iste delectus aliquam beatae accusamus. Aut, vitae? Repudiandae consequatur ipsa consequuntur magnam soluta ullam possimus neque, maxime deserunt similique explicabo molestiae ipsam est impedit laboriosam earum inventore, recusandae delectus omnis ut a dicta optio? Nemo, ullam? Architecto obcaecati, nihil et tempore mollitia voluptatibus porro dolores unde repellat doloremque deserunt, perferendis nesciunt voluptatem! Reprehenderit ab, quasi molestiae nisi magnam tenetur quas autem sint minima harum rem neque dolorem assumenda, quo perferendis non eveniet sed veniam? Cumque veniam, ipsa voluptates laudantium facere suscipit aut deleniti reiciendis id laborum dicta expedita consequuntur atque doloribus accusamus ut labore dolores rem adipisci obcaecati neque facilis debitis? Facere, minus quos temporibus eveniet nihil laborum laudantium animi perferendis eligendi tenetur vero ab nesciunt dolorem totam saepe.</p>
            </div>
        </div>
    </div>
</div>