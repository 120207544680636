<div class="brand-info-container brand-skeleton-container" *ngIf="skeletonLoader">
    <div class="brand-box items-height-skeleton">
        <ngx-skeleton-loader class="height-fix"></ngx-skeleton-loader>
    </div>
    <div class="banner-info py-2 banner-info-skeleton-container">
        <h1 class="skeleton-fav button-height">
            <ngx-skeleton-loader class="brand-name mange-button"></ngx-skeleton-loader>
        </h1>
        <p class="brand-info-des brand-info-des-skeleton">
            <ngx-skeleton-loader count="2"></ngx-skeleton-loader>
        </p>
        <div class="skeleton-fav d-flex gap-3 mb-0">
            <div class="my-div">
              <ngx-skeleton-loader ></ngx-skeleton-loader>
            </div>
            <div class="w-75">
                <ngx-skeleton-loader ></ngx-skeleton-loader>
            </div>
        </div>
    </div>
</div>
<div class="brand-info-container" *ngIf="!skeletonLoader">

    <div class="brand-image">
        <img [src]="brandInfo?.banner" [alt]="brandInfo?.name">
    </div>
    <div class="banner-info">
        <h1 class="brand-name">{{brandInfo?.name}}</h1>
        <p class="brand-info-des">{{brandInfo?.description}}</p>

        <div class="add-to-fav-section" *ngIf="brandInfo?.id && userId" appClickToFavBrands (click)="toggleBrandAsFavorite(brandInfo?.id)">
           <img id="fav-star" src="assets/icons/fav-brand-star.svg" alt="fav-star" *ngIf="brandInfo?.is_in_wishlist == 0"  > <p class="add-to-fav" id="toggle-fav" *ngIf="brandInfo?.is_in_wishlist == 0" >{{'Add to Favourites' | translate}}</p>
           <img id="fav-star" src="assets/icons/fav-brand-star-filled.svg" alt="fav-star" *ngIf="brandInfo?.is_in_wishlist == 1"  > <p class="add-to-fav" id="toggle-fav" *ngIf="brandInfo?.is_in_wishlist == 1">{{'Remove from Favourites' | translate}}</p>
        </div>

        <a *ngIf="brandInfo?.id && !userId" [routerLink]="['/login']" appClickToFavBrands>
            <div class="add-to-fav-section" >
                <img id="fav-star" src="assets/icons/fav-brand-star.svg" alt="fav-star" > <p class="add-to-fav">{{'Add to Favourites' | translate}} </p>
            </div>
        </a>

    </div>
    
</div>