import { Component, OnInit } from '@angular/core';
import {Store} from "../models/store";
import {UserService} from "../config/user.service";
import {ConfigSettings} from "../config/config.settings";
import {Product} from "../models/product";
import {ConfigService} from "../config/config.service";
import {Router} from "@angular/router";
import { WishlistService } from '../config/wishlist.service';
import { CallWishlistService } from '../services/call-wishlist.service';

@Component({
  selector: 'app-my-wishlist',
  templateUrl: './my-wishlist.component.html',
  styleUrls: ['./my-wishlist.component.scss']
})
export class MyWishlistComponent implements OnInit{

  lang: string;
  store: Store;
  userId: string;
  didLoad: boolean = false;
  products: Product[] = [];
  currency: string;
  dataAvailable = false;
  skeletonLoader:boolean=true;
  isMobile: boolean = false;
  skeltonTheme:any;
  constructor(
      private userService: UserService,
      private configSettings: ConfigSettings,
      private configService: ConfigService,
      private router: Router,
      private wishlistService: WishlistService,
      private callWishList:CallWishlistService
  ) {
      this.configSettings.toggleLoading(true);
  }

  ngOnInit() {
    this.isMobile = window.innerWidth < 600;
      this.lang = this.configSettings.getLang();
      this.currency = this.configSettings.getCurrency();     
      this.userService.getUserSession().then(user => {
          if(user !== false) {
              this.userId = user.id;
          }

          /* getting store from config*/
          this.configSettings.getStoreObject().then((result) => {
              this.store = <Store> result;
              this.currency = result['currency_' + this.lang];
              this.getWishlist();
          });
      }, error => { this.configSettings.toggleLoading(false); });

      this.userService.wishlistOutput.subscribe(items => {this.products = items;});
    this.skeltonTheme=this.setThemeLogic();
    this.callWishList.getCallWishList().subscribe(res=>{
      if(res==true){
        this.getWishlist();
      }
    })
  }
      // logic for skelton loader theme for big and small device
  setThemeLogic() {
    return {
      'border-radius': '5px',
      'height': '40px',
      'animation-duration': '2s',
    };
  }

  getWishlist() {
      const params = {
          user_id: this.userId,
          lang: this.lang,
          store: this.store.iso_code
      };

      this.configService.readRequest('user-wishlist', params)
          .subscribe(response => {
              this.products = response.body.data;
              this.didLoad = true;
              this.skeletonLoader=false;
              this.configSettings.toggleLoading(false);
              this.wishlistService.changWishlistCount(response.body.data.length);
              this.dataAvailable = true;
          }, error => { this.configSettings.toggleLoading(false); });
  }

  scrollTop() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

}
