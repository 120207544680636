import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import {FooterService} from '../config/footer.service';
import {ConfigSettings} from '../config/config.settings';
import {ActivatedRoute} from '@angular/router';
import {UserService} from '../config/user.service';
import {User} from '../models/user';
import {listStagger} from '../animations';
import {ConfigService} from '../config/config.service';
import {Store} from '../models/store';
import {Item} from '../models/item';
import {DialogComponent} from '../dialog/dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-request-return',
  templateUrl: './request-return.component.html',
  styleUrls: ['./request-return.component.css'],
  animations: [listStagger]
})
export class RequestReturnComponent implements OnInit {
    orderId: number;
    dataAvailable = false;
    lang: string;
    currency: string;
    user: User;
    store: Store;
    order_number: string;
    items: Item[] = [];
    dialogRef: MatDialogRef<DialogComponent>;
    returnItems = [];

  constructor(
        protected footerService: FooterService,
        private userService: UserService,
        private configService: ConfigService,
        private dialog: MatDialog,
        private route: ActivatedRoute,
        private configSettings: ConfigSettings,
        private _location: Location
  ) { }

  ngOnInit() {
      this.lang = this.configSettings.getLang();
      this.orderId = +this.route.snapshot.paramMap.get('id');
      /* getting store from config*/
      this.configSettings.getStoreObject().then((result) => {
          this.store = <Store> result;
          this.currency = result['currency_' + this.lang];
          this.userService.getUserSession().then(user => {
              if (user !== false) {
                  this.user = <User> user;
                  this.getAvailableItems();
              }
          });
      });
  }
  selectItem(item) {
    item.requestReturn = !item.requestReturn;
    if (item.requestReturn === true) {
        this.dialogRef = this.dialog.open(DialogComponent, {
            data: {returnReason: true},
        });
        this.dialogRef.componentInstance.maxReturnQuantity = item.available_return_quantity;
        this.dialogRef.afterClosed().subscribe(result => {
            if (result) {
                item.returnReason = result.data.reason;
                item.returnQuantity = result.data.quantity;
            }
            this.returnItems = [];
            this.items.map(obj => {
                if (obj.requestReturn === true) {
                    this.returnItems.push({
                        id: obj.id,
                        quantity: obj.returnQuantity,
                        reason: obj.returnReason
                    });
                }
            });

        });

    } else {
        item.returnReason = null;
        item.returnQuantity = null;
    }
  }
  

  onSubmit(returnForm: any) {
      try {
          if (this.returnItems.length > 0) {
              this.configSettings.toggleLoading(true);
              const getParams = {
                  lang: this.lang,
                  store: this.store.iso_code
              };
              const postParams = {
                  order_id: this.orderId,
                  user_id: this.user.id,
                  use_order_delivery_address: 1,
                  items: this.returnItems
              };
              this.configService.postRequest('add-return-request', getParams, postParams)
                  .subscribe(response => {
                      if (response.status === 200) {
                          this._location.back();
                      } else {
                      }
                      this.configSettings.toggleLoading(false);
                  }, error => { this.configSettings.toggleLoading(false); });
          }
      } catch(e) {
          console.log(e);
          this.configSettings.toggleLoading(false);
      }
  }
  getAvailableItems() {
    this.configSettings.toggleLoading(true);
    const params = {
        order_id: this.orderId,
        user_id: this.user.id,
        lang: this.lang,
        store: this.store.iso_code
    };

    this.configService.readRequest('available-return-items', params)
        .subscribe(response => {
            if (response.status === 200) {
              this.items = <Item[]> response.body.data.items;
            }
            this.configSettings.toggleLoading(false);
            this.dataAvailable = true;
            setTimeout(() => { this.footerService.toggleFooter(true); }, 300);
        }, error => { this.configSettings.toggleLoading(false); });
}



}
