import { Component, OnInit } from '@angular/core';
import { User } from '../models/user';
import {UserService} from "../config/user.service";
import { SocialAuthService , SocialUser } from "@abacritt/angularx-social-login";
import {LocalStorage} from '@ngx-pwa/local-storage';
import { WishlistService } from '../config/wishlist.service';
import { CartService } from '../config/cart.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../dialog/dialog.component';

@Component({
  selector: 'app-side-user-actions',
  templateUrl: './side-user-actions.component.html',
  styleUrls: ['./side-user-actions.component.scss']
})
export class SideUserActionsComponent implements OnInit {
  user: User;
  validUserSession = false;
  dataAvailable = false;
  showNow:any = false;

  constructor(
    private userService: UserService,
    private authService: SocialAuthService ,
    protected localStorage: LocalStorage,
    private wishlistService: WishlistService,
    private cartService: CartService,
    private dialog: MatDialog
  ) {
  }

  ngOnInit() {
    this.userService.getUserSession().then(user => {
      if(user !== false) {
          this.user = <User> user;
          this.validUserSession = true;
      } 
      this.dataAvailable = true;
    });
  }

  // logout() {
  //   if(this.user.is_social_register){
  //     this.authService.signOut();
  //   }
  //   this.localStorage.removeItem('userInfo').subscribe(() => {});
  //   this.wishlistService.changWishlistCount(0);
  //   this.cartService.changCartCount(0);
  //   window.location.href = '/';    
  // }

  showLogoutModal() {
    let dialogRef = this.dialog.open(DialogComponent, {
        data: {logout: true, user: this.user },
  });
}

toggleShowNow(){
    this.showNow = !this.showNow;
}

}
