import {Injectable} from '@angular/core'

import {Observable, Subject,BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CheckUserLoggedInService {

  constructor() {
    this.configSubject = new Subject();
    this.isUserLoggedIn = this.configSubject.asObservable();

  }

  private loggedIn: Subject<any> = new BehaviorSubject(Object);

  public isUserLoggedIn = new Observable((observer) => {
    // observable execution
    observer.next();
    observer.complete();
  });
    private configSubject: Subject<string>;


    setUserLoginStatus(val: any) {
    this.configSubject.next(val); 
    this.loggedIn.next(val);
  }
  getsetUserLoginStatus(): Observable<any> {
   return this.loggedIn.asObservable();
  }
}
