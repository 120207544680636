<div class="my_account_data" *ngIf="skeletonLoader">
  <div class="container-fluid">
    <section class="main-profile" id="my-orders" >
      <div class="account_heading pt-3 pb-5">
        <ul class="d-flex flex-wrap align-items-center justify-content-center w-100 listing-page-routing">
          <li class="routing-list w-25">
            <ngx-skeleton-loader></ngx-skeleton-loader>   
          </li>
        </ul>
      </div>

      <div >
        <div class="d-flex w-100  justify-content-center w-100">
          <div class="user-left-menu d-none d-lg-block w-25">
            <div class="w-50" >
            <ngx-skeleton-loader count="8"></ngx-skeleton-loader>  
            </div>
          </div>
          <div class="user-profile-content w-100 w-lg-75">
            <div class="breadcrumb_heading">
              <h3 class="w-25">
                <ngx-skeleton-loader ></ngx-skeleton-loader>   
              </h3>
            </div>
            <div >
              <div class="order_data">
                <div class="order_data_border" *ngFor="let item of [1,2,3,4]; let i = index" id="order-{{i}}" style="cursor: pointer" >
                  <div class="d-flex gap-3 w-100">
                    
                    <div class="order_product_data items-height-skeleton">
                      <ngx-skeleton-loader class="height-fix"></ngx-skeleton-loader>   
                    </div>
                    <div class="w-100 position-relative">
                      <div class="d-flex gap-3 w-100 justify-content-between">
                        <div class="product_order_data w-50">
                          <ngx-skeleton-loader></ngx-skeleton-loader>                        
                            <ngx-skeleton-loader></ngx-skeleton-loader>
                            <ngx-skeleton-loader></ngx-skeleton-loader>
                          <h5>
                            <ngx-skeleton-loader></ngx-skeleton-loader>                          
                          </h5>
                        </div>
                        <div class="order_status order_status-border w-25">
                            <ngx-skeleton-loader></ngx-skeleton-loader>                             
                        </div>
                      </div>
                      <div class="position-absolute bottom-0">
                      <ngx-skeleton-loader></ngx-skeleton-loader>  
                      </div>
                    </div>
                  </div>
                </div>                  
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>
<ng-container *ngIf="dataAvailable && !skeletonLoader">
  <div class="my_account_data">
    <div class="container-fluid">
      <section class="main-profile" id="return-request-listing" @listStagger>
        <div class="account_heading pt-3 pb-5">
          <ul
            class="d-flex flex-wrap align-items-center justify-content-center w-100 listing-page-routing"
          >
            <li class="routing-list">
              <a [routerLink]="'/my-account'">
                {{ "my account" | translate }}
              </a>
            </li>
            <li class="routing-list">
              <a>
                {{ "My Returns" | translate }}
              </a>
            </li>
          </ul>
        </div>

        <div class="pb-5" *ngIf="requests">
          <div class="d-flex w-100 justify-content-center w-100">
            <div class="user-left-menu d-none d-lg-block w-25">
              <app-user-account-menu [page]="3"></app-user-account-menu>
            </div>
            <div class="user-profile-content w-100 w-lg-75">
              <div class="breadcrumb_heading">
                <h3>
                  <a [routerLink]="'/my-account'">
                    <img src="/assets/icons/right-arrow.svg" alt="arrow"
                  /></a>
                  {{ "My Returns" | translate }}
                </h3>
              </div>

              <div class="order_data" *ngIf="requests">
                <div
                  *ngFor="let request of requests; let i = index"
                  class="order_data_border"
                  id="order-{{ request.id }}"
                  style="cursor: pointer"
                >
                  <div
                    class="d-flex gap-3 w-100"
                    (click)="setReturnRequest(request)"
                  >
                    <div class="order_product_data">
                      <div *ngFor="let item of request.items; let i = index">
                        <div class="product_image" *ngIf="i == 0">
                          <!-- <img src="{{item.image}}" class="img-fluid order-listing-image" height = "80px"> -->
                          <figure>
                            <div class="image-wrapper">
                              <img class="loaded" src="{{ item.image }}" />
                            </div>
                          </figure>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex gap-3 w-100">
                      <div class="w-100 position-relative product_order_data">
                        <h4 class="order-number font-medium font-black mb-2">
                          {{ request?.request_number }}
                        </h4>
                        <h5 class="order-date font-small font-regular mb-2">
                          {{ "Requested On:" | translate }}
                          {{ request?.request_date | date : "dd MMM yyyy" }}
                        </h5>
                        <p class="order-items font-small font-bold m-0">
                          # {{ request?.order_number }}
                        </p>
                      </div>
                      <div class="" *ngIf="request?.status">
                        <p class="order_status" [ngStyle]="{ color: request.status_color }">
                          {{ request?.status  }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="!requests.length" class="empty-cart">
                <div class="empty-cart-content white-container">
                  <h4 class="page-title font-bold text-center">
                    {{ "You currently have no returns" | translate }}
                  </h4>
                  <p class="font-regular text-center">
                    {{
                      "Here you'll find information related to your returns."
                        | translate
                    }}
                  </p>

                  <button type="button" [routerLink]="'/home'">
                    {{ "CONTINUE SHOPPING" | translate }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</ng-container>
