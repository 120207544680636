import { Component, HostListener, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ConfigSettings } from '../config/config.settings';
import { ConfigService } from '../config/config.service';
import { Category } from '../models/category';
import { NavbarService } from '../config/navbar.service';
import { HeaderToggleService } from '../services/header-toggle.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FooterService } from '../config/footer.service';
import { Subscription } from 'rxjs';
import { ChangeMetaService } from '../services/change-meta-service.service';
import { HomeService } from '../services/home.service';
import { ShareBrandInfoService } from '../services/share-brand-info.service';
import { ShareHomeInfoService } from '../services/share-home-info.service';
import { UserService } from '../config/user.service';
import { Store } from "../models/store";
import { callContactNo } from '../services/callContactNo';

@Component({
  selector: 'app-home-v3',
  templateUrl: './home-v3.component.html',
  styleUrls: ['./home-v3.component.scss']
})
export class HomeV3Component implements OnInit {

  headerToggleServiceSubscription: Subscription;
  screenWidth = screen.width;
  isMobile: boolean = false;
  showPopup: boolean = false
  breadcumb: any[] = [];
  lang: any;
  langForSlider = this.configSettings.getLang()
  categories: Category[] = [];
  unselectedCategoryList: Category[] = [];
  top_selected_category: number;
  top_selected_category_name: string;
  category: Category;
  isCategoryLoading = false;
  shopByCategory: Array<any> = []
  bannerHeight: any;
  homeBanner: string = '';
  // isCookieAccepted: boolean = false
  subCategoryPage: boolean = false
  sections: any = []

  landingAds: any;
  skeletonLoader:boolean=true;
  skeltonTheme:any;
  skeltonThemeLanding:any;
  counter = Array;
  brand: any
  currency: string;
  store: Store;
  @ViewChild('advertisement', { static: true }) advertisement: ElementRef;

  constructor(
    public configSettings: ConfigSettings,
    private configService: ConfigService,
    private navbarService: NavbarService,
    private headerToggleService: HeaderToggleService,
    private router: Router,
    private footerService: FooterService,
    private changeMeta: ChangeMetaService,
    private homeService: HomeService,
    private route: ActivatedRoute,
    private shareBrandInfoService : ShareBrandInfoService,
    private shareHomeInfoService : ShareHomeInfoService,
    private userService : UserService,
    private callContactNo:callContactNo
  ) {
    this.getUserData()
  }
  ngOnInit(): void {
    this.isMobile = window.innerWidth < 600;
    this.lang = this.configSettings.getLang()
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.getRouterData()
    this.updateBreadCrumb();
    this.footerService.toggleFooter(true);
    this.readAllCategories();
    // let cookie = this.getCookie('userSelectedHomePage')
    // if (cookie == null) {
    //   this.showPopup = true
    //   this.isCookieAccepted = false
    //   this.showHomePageWithNoNav()
    // } else {
    //   this.isCookieAccepted = true
    // }
    this.sections = [];
    this.configSettings.getStoreObject().then((result) => {
      this.store = <Store>result;
      this.currency = result['currency_' + this.lang];
    });
    this.headerToggleServiceSubscription = this.headerToggleService.getNavItems().subscribe(res => {

      this.subCategoryPage = res
      
      if (this.subCategoryPage === false) {
        this.subCategoryPage = this.configSettings.getNavBarStatus()
      }
      if (this.subCategoryPage) {
        this.userService.getUserSession().then(user => {
          if (user !== false) {
            this.userId = user.id;
          }
          this.categoryDetails(JSON.parse(localStorage.getItem('top_selected_category')));
        });
      } else {
        this.configSettings.toggleLoading(false);
        this.getBanner();
      }
    })
    this.configSettings.removeLandingBanner();   
    this.skeltonTheme=this.setThemeLogic();
    this.skeltonThemeLanding=this.setThemeLogicLanding();
  }
  setThemeLogicLanding(){
    return {
      'border-radius': '5px',
      'height': this.isMobile ? '135px' : '505px',
      'animation-duration': '2s'
    };
  }
  setThemeLogic(){
    return {
      'border-radius': '5px',
      'height': this.isMobile ? '400px' : '700px',
      'animation-duration': '2s'
    };
  }

  ngAfterViewInit() {
    //to manage sub category tabs in mobile...
    var subCat = this.configSettings.getNavBarStatus();
    if (subCat == true) {
      this.navbarService.isEnableHeaderBottomMobileAction(true);
    } else {
      this.navbarService.isEnableHeaderBottomMobileAction(false);
    }
    // to update meta tags 
    this.changeMeta.updateMetaTags('The Wishlist | Discover fashion online!', "Everything on your Wish List!' Discover and shop a variety of pieces ranging from clothes, bags, shoes, to accessories and more.")

    this.configSettings.load.subscribe(loading => {
      if (loading == false) {
        setTimeout(() => {
          this.skeletonLoader=false;     
          this.getUserData()     
        }, 3000);
      }
    });

  }


  brandId: any
  tierId: any
  brandData: {
    brandId: null,
    tierId: null
  }
  isBrandHomePage: boolean = false
  getRouterData() {
    this.route.params.subscribe(params => {
      if (params.tierId) {
        this.isBrandHomePage = true;
        this.brandId = params.id;
        this.tierId = params.tierId;
        this.brandData = { // reassign the entire object
          brandId: params?.id,
          tierId: params?.tierId
        };
      }
    });
  }
  

  // this function is to toggle cookies popup, used on main home page
  // togglePopup(showPopup: boolean, isCookieAccepted: boolean) {
  //   this.showPopup = showPopup;
  //   this.isCookieAccepted = isCookieAccepted;
  //   let bannerCount = this.configSettings.getLandingBannerCount();
  //   // until user dose not select category 'default' is set to 'userSelectedHomePage'
  //   if (isCookieAccepted) {
  //     this.setCookie('userSelectedHomePage', 'default', 7)
  //     if (bannerCount != '2') {
  //       this.landingPageAd();
  //     }
  //   } else {
  //     this.setCookie('userSelectedHomePage', 'tempCookie', 0.0007)
  //     if (bannerCount != '2') {
  //       this.landingPageAd();
  //     }
  //   }

  // }

  // this is used to get main categories and their sub categories, used in main home page
  readAllCategories() {
    const params = {
      lang: this.lang
    };
    this.configService.readRequest('all-categories-web', params)
      .subscribe(response => {
        if (response.body.data) {
          this.categories = <Category[]>response.body.data;          
          this.categories.map(category => {
            category.name = category.name.toLowerCase()

            category.name = category.name.charAt(0).toUpperCase() + category.name.slice(1);
            this.shopByCategory.push(category.subcategories)
          });
          if (this.subCategoryPage == false) {
            this.setCategory(this.categories[0])
          }
          this.isCategoryLoading = false;
        }
      });
  }


  // used to set selected categories and display the data to categories home page respectively
  // selected category name is saved in cookies and name and id in localStorage
  setCategory(category: Category): void {
    this.sections = [] // sections is cleared here because when the user selects another category old data was visible till the api loads  
    // if (this.isCookieAccepted == true) {

    //   // this.setCookie('userSelectedHomePage', category.name, 7)
    // }
    this.setCategoryLocalStorage(category.id);
    this.headerToggleService.setCategories(category)
    this.subCategoryPage = true
    this.headerToggleService.setNavItems(this.subCategoryPage)
    this.configSettings.setNavBarStatus(true)
    this.top_selected_category = category.id;
    this.top_selected_category_name = category.name;
    this.setCategoryNameToLocalStorage(this.top_selected_category_name)
    this.unselectedCategoryList = [];
    this.categories.map(category => {
      if (+category.id !== +this.top_selected_category) {
        this.unselectedCategoryList.push(category);
      }
    });
    this.configSettings.toggleLoading(true);
    this.navbarService.isEnableHeaderBottomMobileAction(true);
    this.activateHeaderFromBanners();
  }



  // these functions used to save selected category data in local storage
  setCategoryLocalStorage(categoryId: number): void {
    localStorage.setItem('top_selected_category', categoryId.toString());
  }
  setCategoryNameToLocalStorage(categoryName: string) {
    localStorage.setItem('userSelectedCategory', categoryName);
  }

  // home screen carousal 
  slideConfig: any = {
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 7,
    dots: false,
    swipeToSlide: true,
    rtl: this.langForSlider == 'en' ? false : true,
    // fade: true,
    prevArrow: '<span class="slick-arrow slick-prev custom-container-carousal-img"><img class="custom-carousal-arrows" src="../../assets/ArtWork/prev-carrousal.png" alt=""></span>',
    nextArrow: '<span class="slick-arrow slick-next custom-container-carousal-img"><img class="custom-carousal-arrows" src="../../assets/ArtWork/next-carrousal.png" alt=""></span>',
    responsive: [
      {
        breakpoint: 768,
        settings: {
          variableWidth: true,
          slidesToShow: 2,
          slidesToScroll: 20,
          arrows: false,
          // swipeToSlide: true,

        }
      },
      {
        breakpoint: 913,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          arrows: true,
        }
      },
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          arrows: true,
        }
      },
      {
        breakpoint: 1281,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          arrows: true,
        }
      },
    ]
  };

 
  // to get categories details as per selected categories
  categoryDetails(id: any) {
    this.skeletonLoader=true;
    this.getRouterData()
      
    const params = {
      lang: this.lang,
      root_category_id: this.isBrandHomePage ? '' : id,
      brand_id: this.brandId ? this.brandId : '',
      user_id: this.userId ? this.userId : '',
      store: this.store.iso_code,
    };
    
    this.configService.readRequest('category-landing-page', params)
      .subscribe(response => {
        this.sections = response.body.data.data;
        this.bannerHeight = response.body.data.banner_height;
        this.homeBanner = response.body.data.home_banner;
        let brand = response.body.data.brand 
        this.callContactNo.setContactNo(response.body.data.support_phone)
        let homeInfo = {
          popular_boutiques: response.body.data?.popular_boutiques,
          popular_categories: response.body.data?.popular_categories,
          popular_products: response.body.data?.popular_products,
        }
        this.setBrandInfo(brand)
        this.setHomeInfo(homeInfo)
        this.configSettings.toggleLoading(false);
      });
  }

  // to get home page banners
  getBanner() {
    const params = {
      lang: this.lang,
      user_id: '',
      category_id: '',
      device_type: 'W'
    };
    this.configService.readRequest('home', params).subscribe(response => {            
      this.bannerHeight = response.body.data.banner_height;
      this.homeBanner = response.body.data.home_banner;
    });
  }

  // to remove spaces in url string with "-"
  getUrlName(productName: string): string {
    var prodN = productName ? (productName.toLowerCase().replace(/[^A-Z0-9]+/ig, "-")) : '';
    return prodN;
  }

  // to route to product details
  routingToDetails(productId) {
    // if (this.isMobile) {
    //   this.routeToDetailsSmallDevice(productId)
    // } else {
    this.routeToDetails(productId)
    // }

  }

  // to navigate to product details
  routeToDetailsSmallDevice(productId) {
    this.router.navigate(['/product/detail/', productId])
  }

  // to navigate to product details in new tab
  routeToDetails(productId) {
    // const url = `/product/detail/${productId}`;
    // window.open(url, '_blank');
    this.router.navigate(['/product/detail/', productId])

  }

  // to route based on B: banner, P: product
  bannerRouting(value: any, type: 'B' | 'P') {     
    if (this.isBrandHomePage) {
      this.routingIfPageIsBrand(value,type)
      return
    } else {
      this.routingSmallDevice(value,type)
      return
    }
  }

  // for routing of banners
  routingSmallDevice(value: any, dataType: 'B' | 'P') {
    const type = value.link_type;
    if (type === 'B' || type === 'BR') {
      let brandName = dataType == 'B' ? value.name : value.set_title      
      dataType == 'B' ? this.routeAsPerTier(brandName,value?.tier_id,value?.link_id) : this.router.navigate(['/brand', this.getUrlName(brandName), value.link_id])
      return
    }
    else if (type === 'C' || type === 'FC') {
      let categoryName = dataType == 'B' ? value.name : value.set_title
      this.router.navigate(['/category', this.getUrlName(categoryName), value.link_id])
      return
    }
    else if (type === 'P') {
      this.router.navigate(['/product/detail', value.link_id])
      return;
    }
    else if (type === 'FP') {
      this.router.navigate(['/product/detail', value.link_id])
      return;
    }
    else if (type === 'L') {
      this.router.navigateByUrl(value.link)
      return value.link;
    }
    else if (type === 'S') {
      let shopName = dataType == 'B' ? value.name : value.set_title
      this.router.navigate(['/shop', this.getUrlName(shopName), value.link_id])
      return;
    }
    else if (type === 'F') {
      this.router.navigate(['/search'], { queryParams: this.getCollectionQueryParams(value, 0) })
      return;
    } else if (type === 'N') {
      this.router.navigate(['/shop/new-arrivals'], { queryParams: this.getCollectionQueryParams(value, 1) })
      return;
    }
    return '';
  }

    // for routing of banners
    routingIfPageIsBrand(value: any, dataType: 'B' | 'P') {
      const type = value.link_type;     
      let queryParams = { ...this.brandData };
      if (type === 'B' || type === 'BR') {        
        let brandName = dataType == 'B' ? value.name : value.set_title
        dataType == 'B' ? this.routeAsPerTier(brandName,value?.tier_id,value?.link_id) : this.router.navigate(['/brand', this.getUrlName(brandName), value.link_id])
        return;
      } else if (type === 'C' || type === 'FC') {
        let categoryName = dataType == 'B' ? value.name : value.set_title
        this.router.navigate(['/category', this.getUrlName(categoryName), value.link_id], { queryParams });
        return;
      } else if (type === 'P' || type === 'FP') {
        this.router.navigate(['/product/detail', value.link_id], { queryParams });
        return;
      } else if (type === 'L') {
        this.router.navigateByUrl(value.link);
        return value.link;
      } else if (type === 'S') {
        let shopName = dataType == 'B' ? value.name : value.set_title
        queryParams = { ...queryParams, ...this.getCollectionQueryParams(value, 0) };
        this.router.navigate(['/shop', this.getUrlName(shopName), value.link_id], { queryParams });
        return;
      } else if (type === 'F') {
        queryParams = { ...queryParams, ...this.getCollectionQueryParams(value, 0) };
        this.router.navigate(['/search'], { queryParams });
        return;
      } else if (type === 'N') {
        queryParams = { ...queryParams, ...this.getCollectionQueryParams(value, 1) };
        this.router.navigate(['/shop/new-arrivals'], { queryParams });
        return;
      }
      return '';
    }
    

  // for routing of banners on new tab, currently not in used but kept for future use
  routing(value: any) {
    const type = value.link_type;
    switch (type) {
      case 'B':
      case 'BR':
        this.openNewTab(`/brand/${this.getUrlName(value.name)}/${value.link_id}`);
        break;
      case 'C':
      case 'FC':
        this.openNewTab(`/category/${this.getUrlName(value.name)}/${value.link_id}`);
        break;
      case 'P':
      case 'FP':
        this.openNewTab(`/product/detail/${value.link_id}`);
        break;
      case 'L':
        this.openNewTab(value.link);
        break;
      case 'S':
        this.openNewTab(`/shop/${this.getUrlName(value.name)}/${value.link_id}`);
        break;
      case 'F':
        this.openNewTabWithQueryParams('/search', this.getCollectionQueryParams(value, 0));
        break;
      case 'N':
        this.openNewTabWithQueryParams('/shop/new-arrivals', this.getCollectionQueryParams(value, 1));
        break;
      default:
        break;
    }
  }
  

  // to open , currently not in used but kept for future use
  openNewTab(url: string): void {
    window.open(url, '_blank');
  }

  // to open url with query params in new tab, currently not in used but kept for future use
  openNewTabWithQueryParams(url: string, queryParams: any): void {
    const queryString = Object.keys(queryParams).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`).join('&');
    const fullUrl = url + '?' + queryString;
    window.open(fullUrl, '_blank');
  }

  // get query params based on latestId
  getCollectionQueryParams(collection: any, latestId: any): any {
    if (latestId == 0) {
      return {
        q: '',
        lang: 'en',
        price_range: '',
        in_stock: '',
        typepage: '1',
        per_page: '20',
        is_featured: '',
        latest: latestId,
        best_selling: '',
        sort_by: '1',
        store: '',
        flash_sale_id: collection.link_id,
        shop_type_id: ''
      };
    } else {
      return {
        lang: 'en',
        price_range: '',
        in_stock: '',
        typepage: '1',
        per_page: '20',
        is_featured: '',
        latest: latestId,
        best_selling: '',
        sort_by: '1',
        store: '',
        flash_sale_id: '',
        shop_type_id: ''
      };
    }

  }

    // to route as per tier id
    routeAsPerTier(name: string, tier_id: any, id: number) {
      const brandName = this.configSettings.cleanUrl(name);
      let urlParams: any[] = [];
      if (tier_id === 1) {
        urlParams = ['home', brandName, id, tier_id];
      } else {
        urlParams = ['brands', brandName, id];
        if (tier_id) {
          urlParams.push(tier_id);
        }
      }
    
      this.router.navigate(urlParams);
    }
    

  // function was used to display card title based on keys, currently not in use kept for future use
  // setCardTitle(type: string) {
  //   const titles = {
  //     'B': { en: 'DISCOVER BRANDS', ar: 'اكتشف الماركات' },
  //     'BR': { en: 'DISCOVER BRANDS', ar: 'اكتشف الماركات' },
  //     'C': { en: '', ar: '' },
  //     'FC': { en: '', ar: '' },
  //     'P': { en: 'DISCOVER PRODUCTS', ar: 'اكتشف المنتجات' },
  //     'FP': { en: '', ar: '' },
  //     'L': { en: '', ar: '' },
  //     'S': { en: 'DISCOVER SHOPS', ar: 'اكتشف المتاجر' },
  //     'F': { en: 'DISCOVER FLASH SALE', ar: 'اكتشاف بيع فلاش' },
  //     'N': { en: 'DISCOVER THE LATEST ARRIVALS', ar: 'اكتشف أحدث الوافدين' },
  //   };

  //   return type && titles[type] ? titles[type][this.lang] || '' : '';
  // }


  // handel cookies functionality section   - STARTS
  // setCookie(name, value, days) {
  //   var expires = "";
  //   if (days) {
  //     var date = new Date();
  //     date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
  //     expires = "; expires=" + date.toUTCString();
  //   }
  //   document.cookie = name + "=" + (value || "") + expires + "; path=/";

  // }
  // getCookie(name) {
  //   var nameEQ = name + "=";
  //   var ca = document.cookie.split(';');
  //   for (var i = 0; i < ca.length; i++) {
  //     var c = ca[i];
  //     while (c.charAt(0) == ' ') c = c.substring(1, c.length);
  //     if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  //   }
  //   return null;
  // }
  // eraseCookie(name) {
  //   document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  // }
  // handel cookies functionality section   - ENDS

  // toggle navbar on home page
  showHomePageWithNoNav() {
    this.configSettings.setNavBarStatus(false)
    this.headerToggleService.setNavItems(false)
  }


  // to remove items in local storage before reload
  // @HostListener("window:onbeforeunload", ["$event"])
  // clearLocalStorage(event) {
  //   if (this.isCookieAccepted == false) {
  //     localStorage.removeItem('top_selected_category')
  //     localStorage.removeItem('showPopup')
  //   }
  // }

  // to set banner size based on data from api
  getBannerStyle(data) {
    const style = {};
    const height = data.small_device_zoom ? parseFloat(data.small_device_zoom) : 0;
    if (this.screenWidth <= 767 && height) {
      style['height'] = `${height}px`;
    }

    return style;
  }

  // to set screenWidth based on window resize
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenWidth = event.target.innerWidth;
  }

  setBannerZoom(zoomValue: any) {
    if (zoomValue != '0.000')  {
      return zoomValue ? Math.round(zoomValue) : zoomValue
    }
  }

  // to get landing page ad
  landingPageAd(): void {
    const getParam = {
      lang: this.lang,
      type: 'W',
      device_type: 'W'
    };
    this.homeService.landingPageAds(getParam).subscribe((response) => {
      this.landingAds = response.body.data;
      if (this.configSettings.getLandingAds() != '1' && response.body.data != undefined && response.body.data != '') {
        this.advertisement.nativeElement.click();
      }
    });
  }


  // routing on landing page ads
  landingAdsUrl(landingAds: any) {
    this.configSettings.setLandingBanner(1);
    if (landingAds != '' && landingAds != undefined && landingAds != 'undefined') {
      this.activateHeaderFromBanners();
      if (landingAds.link_type === 'B' || landingAds.link_type === 'BR') {
        this.router.navigate(['/brand', this.getUrlName(landingAds.link_name), landingAds.link_id])
        return
        // return ['/brand', this.getUrlName(landingAds.link_name), landingAds.link_id];
      }
      else if (landingAds.link_type === 'C') {
        this.router.navigate(['/category', this.getUrlName(landingAds.name), landingAds.link_id])
        return
        // return ['/category', this.getUrlName(landingAds.link_name), landingAds.link_id];
      }
      else if (landingAds.link_type === 'P') {
        this.router.navigate(['/product/detail', landingAds.link_id])
        return
        // return ['/product/detail', landingAds.link_id];
      }
      else if (landingAds.link_type === 'FP') {
        this.router.navigate(['/product/detail', landingAds.link_id])
        return
        // return ['/product/detail', landingAds.link_id];
      }
      else if (landingAds.link_type === 'L') {
        return landingAds.link_id;
      }
      else if (landingAds.link_type === 'S') {
        this.router.navigate(['/shop', this.getUrlName(landingAds.name), landingAds.link_id])
        return;
        // return ['/shop', this.getUrlName(landingAds.link_name), landingAds.link_id];
      }
      else if (landingAds.link_type === 'F') {
        this.router.navigate(['/search'], { queryParams: this.getCollectionQueryParams(landingAds, 0) })
        return;
        // return ['/search',this.getCollectionQueryParams(landingAds,0)];
      } else if (landingAds.link_type === 'N') {
        this.router.navigate(['/shop/new-arrival'], { queryParams: this.getCollectionQueryParams(landingAds, 1) })
        return;
      }
    }
    return '';
  }


  // toggle header and nav bars
  activateHeaderFromBanners() {
    this.navbarService.isEnableHeaderBottomAction(true);
    this.navbarService.isEnableHeaderBottomMobileAction(true);
    this.configSettings.setNavBarStatus(true)
    this.headerToggleService.setNavItems(this.subCategoryPage)
  }

  // to close landing ad popups
  closed() {
    this.configSettings.setLandingAds();
  }

  scrollTop() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }


  ngOnDestroy() {
    this.navbarService.isEnableHeaderBottomMobileAction(false);
    this.headerToggleServiceSubscription.unsubscribe();
  }



  // shopLookConfig: any = {
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 3,
  //   slidesToScroll: 4,
  //   dots: false,
  //   swipeToSlide: true,
  //   rtl: this.langForSlider == 'en' ? false : true,
  //   // fade: true,
  //   prevArrow: '<span class="slick-arrow slick-prev custom-container-carousal-img"><img class="custom-carousal-arrows" src="../../assets/ArtWork/prev-carrousal.png" alt=""></span>',
  //   nextArrow: '<span class="slick-arrow slick-next custom-container-carousal-img"><img class="custom-carousal-arrows" src="../../assets/ArtWork/next-carrousal.png" alt=""></span>',
  //   responsive: [
  //     {
  //       breakpoint: 767,
  //       settings: {
  //         variableWidth: true,
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //         arrows: false,
  //         // swipeToSlide: true,

  //       }
  //     },
  //     {
  //       breakpoint: 913,
  //       settings: {
  //         variableWidth: true,
  //         slidesToShow: 2,
  //         slidesToScroll: 1,
  //         arrows: false,
  //         // swipeToSlide: true,

  //       }
  //     },
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: 3,
  //         slidesToScroll: 1,
  //         arrows: true,
  //       }
  //     },
  //   ]
  // };



  eventTesting: any = {
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    dots: false,
    swipeToSlide: true,
    rtl: this.langForSlider == 'en' ? false : true,
    // fade: true,
    // prevArrow: '<span class="slick-arrow slick-prev custom-container-carousal-img"><img class="custom-carousal-arrows" src="../../assets/ArtWork/prev-carrousal.png" alt=""></span>',
    // nextArrow: '<span class="slick-arrow slick-next custom-container-carousal-img"><img class="custom-carousal-arrows" src="../../assets/ArtWork/next-carrousal.png" alt=""></span>',
    responsive: [
      {
        breakpoint: 767,
        settings: {
          variableWidth: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          // swipeToSlide: true,

        }
      },
      {
        breakpoint: 913,
        settings: {
          variableWidth: true,
          slidesToShow: 3,
          slidesToScroll: 1,
          arrows: false,
          // swipeToSlide: true,

        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          arrows: true,
        }
      },
    ]
  };

  setBrandInfo(brand: any) {
    this.configSettings.removeBrandInfo()      
    if (brand && Object.keys(brand).length > 0) { // Check if the brand object has data
      this.brand = brand;
  
      const brandData = {
        data: this.brand,
        isBrandHomePage: this.isBrandHomePage
      };
      this.configSettings.setBrandInfo(brandData)      
      this.shareBrandInfoService.setBrandInfo(brandData);
    } else {
      const brandData = {
        data: {},
        isBrandHomePage: ''
      };
      this.shareBrandInfoService.setBrandInfo(brandData);
      console.log('Brand object is empty.');
    }
  }

  setHomeInfo(homeInfo: any) {    
    if (homeInfo ) {   
      this.shareHomeInfoService.setHomeInfo(homeInfo);
    } else {
      console.log('Home Info object is empty.');
    }
  }

  updateBreadCrumb() {
    this.breadcumb = this.route.snapshot.url;
  }


  userId: any

    getUserData() {
    this.userService.getUserSession().then(user => {
      if (user !== false) {
        this.userId = user.id;
      }
    });
  }


  setProductBadge(productObj: any): string {
    let txt: string = '';
    if (productObj.is_saleable == 0) {
      txt = this.lang == 'en' ? 'SOLD OUT' : 'تم البيع';
    } else if (productObj.is_preorder == 1) {
      txt = this.lang == 'en' ? 'PRE ORDER' : 'الطلب المسبق';
    } else if (productObj.is_trending == 1) {
      txt = this.lang == 'en' ? 'NEW' : 'جديد';
    } else if (productObj.is_featured == 1) {
      txt = this.lang == 'en' ? 'BEST SELLER' : 'الأكثر مبيعًا';
    }
    
    return txt;
  }

    // DiscountAmount calculate
    calculateDiscountAmount(product): number {
      let discountPercentage = ((product.regular_price - product.final_price) / product.regular_price) * 100;
      let discountPercentageValue=Math.round(discountPercentage)
      return discountPercentageValue;
    }

    // onResizesection(zoomValue: any) {
    //   const tempWidth = document.getElementById('takeMyWidth');
    //   if (window.innerWidth > 767) {
    //     const actuallDivWidth = tempWidth.offsetWidth;
    //     const widthReducePercentange = zoomValue / 100;
    //     const widthToReduce = actuallDivWidth * widthReducePercentange;
    //     const finalDivWidth = actuallDivWidth - widthToReduce;
    //     return finalDivWidth + "px";
    //   } else {
    //     return tempWidth + "px";
    //   }
    // }

}
