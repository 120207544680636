import { Component, Input } from '@angular/core';
import { ConfigSettings } from 'src/app/config/config.settings';
import { Store } from 'src/app/models/store';

@Component({
  selector: 'app-review-popup',
  templateUrl: './review-popup.component.html',
  styleUrls: ['./review-popup.component.scss']
})

export class ReviewPopupComponent {
  currency: string = '';
  lang: string;
  store: Store;
  @Input() products: any;
  constructor(private configSettings: ConfigSettings,){}
  


  ngOnInit() {
    this.lang = this.configSettings.getLang();
    this.configSettings.getStoreObject().then((result) => {
        this.store = <Store>result;
        this.currency = result['currency_' + this.lang];
    });

  }

}
