<!-- MAIN HOME PAGE -->
<!-- <ul class="d-flex flex-wrap justify-content-center align-items-center listing-page-routing">
    <li *ngFor="let item of breadcumb" class="routing-list">{{item.path | titlecase}}</li>
</ul> -->

<!-- SUB CATEGORY PAGE WHEH SECTION WAS CLICKED -->
<div class="home-padding">

    <div class="carousel-container" *ngIf="isBrandHomePage">
        <app-brand-info [brandInfo]="brand" [skeletonLoader]="skeletonLoader"></app-brand-info>
    </div>

    <div class="home-padding-section" [hidden]="subCategoryPage==false ">
        <div class="campaign-promo-simple" *ngIf="homeBanner!='' && homeBanner!=undefined">
            {{homeBanner | json}}
            <figure>
                <a href=""><img [src]="homeBanner" alt="" id="banner_img"></a>
            </figure>

        </div>
        <!-- skeleton loader  starts -->
        <div class="hero-section  skelton-btn-height" *ngIf="skeletonLoader">
            <ngx-skeleton-loader  class="height-fix"></ngx-skeleton-loader>
        </div>
        <!-- 1st -->
        <div class="hero-section  margin-bottom-25px mt-3" *ngIf="skeletonLoader">
            <a *ngFor="let banner of counter(1);">
                <div  class="first-banner">
                    <div class="hero-section-imgs skelton-btn-height">
                     <ngx-skeleton-loader class="height-fix"></ngx-skeleton-loader>
                    </div>
                </div>
                <div class="skeleton-width-name mx-auto">
                    <ngx-skeleton-loader  class="height-fix"></ngx-skeleton-loader>
                </div>
                <div class="w-25 mx-auto">
                    <ngx-skeleton-loader  class="height-fix"></ngx-skeleton-loader>
                </div>
                <div class="button-width skelton-btn-height mx-auto">
                    <ngx-skeleton-loader  class="height-fix"></ngx-skeleton-loader>
                </div>
            </a>
        </div>
        <div *ngIf="skeletonLoader" class="carousel-container margin-bottom-50px ">
            <!-- desktop device slider  -->

            <div class="desktopslider">
                <ngx-slick-carousel class="carousel-home customHeight" #slickModal="slick-carousel"
                    [config]="slideConfig">
                    <div class="carousal-information" ngxSlickItem
                        *ngFor="let product of counter(10); let i=index; let last = last; let c = count"
                        class="slide m-2 carousal-stylingg ">
                        <div class="mobile-data-style  skeltonbox">
                            <ngx-skeleton-loader class="height-fix"></ngx-skeleton-loader>
                        </div>
                    </div>
                    <div ngxSlickItem class="last-carousal-card slide m-2 carousal-stylingg">
                        <a class="flex gap20px items-center ">
                            <div class="flex flex-col">
                                <p class="new-items-info">{{'More Styles Waiting' | translate}}</p>
                                <a class="shop-now" >
                                    <span class="show-now-special">{{'SHOP NOW' | translate}}
                                        <!-- <img class="mb-1" src="../../assets/images/arrow.svg"> -->
                                    </span>

                                </a>
                            </div>
                        </a>
                    </div>
                </ngx-slick-carousel>
            </div>

            <!-- small device slider  -->
            <div class="mobileslider">
                <div class="slider-container ">

                    <div *ngFor="let product of counter(6); let i=index"
                        class="carousal-information carousal-stylingg box">
                        <div class="mobile-data-style  skeltonbox">
                            <ngx-skeleton-loader class="height-fix"></ngx-skeleton-loader>
                        </div>
                    </div>

                    <div class="last-carousal-card carousal-information carousal-stylingg box">
                        <div (click)="bannerRouting(section,'P')">
                            <a class="flex gap20px items-center ">
                                <div class="flex flex-col">
                                    <p class="new-items-info">{{'More Styles' | translate}} <br> {{'Waiting' |
                                        translate}}
                                    </p>
                                    <a class="shop-now" >
                                        <span class="show-now-special">{{'SHOP NOW' | translate}}
                                            <!-- <img class="mb-1" src="../../assets/images/arrow.svg"> -->
                                        </span>

                                    </a>
                                </div>
                            </a>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!-- 2nd -->
        <div class="hero-section  margin-bottom-25px mt-3" *ngIf="skeletonLoader">
            <a class="skeleton-boxs" *ngFor="let banner of counter(3);">
                <div class=" skelton-btn-height">
                    <ngx-skeleton-loader class="height-fix"></ngx-skeleton-loader>
                </div>
            </a>
        </div>
        <div *ngIf="skeletonLoader" class="carousel-container margin-bottom-50px ">
            <!-- desktop device slider  -->

            <div class="desktopslider">
                <ngx-slick-carousel class="carousel-home customHeight" #slickModal="slick-carousel"
                    [config]="slideConfig">
                    <div class="carousal-information" ngxSlickItem
                        *ngFor="let product of counter(10); let i=index; let last = last; let c = count"
                        class="slide m-2 carousal-stylingg ">
                        <div class="mobile-data-style  skeltonbox">
                            <ngx-skeleton-loader class="height-fix"></ngx-skeleton-loader>
                        </div>
                    </div>
                    <div ngxSlickItem class="last-carousal-card slide m-2 carousal-stylingg">
                        <a class="flex gap20px items-center ">
                            <div class="flex flex-col">
                                <p class="new-items-info">{{'More Styles Waiting' | translate}}</p>
                                <a class="shop-now" >
                                    <span class="show-now-special">{{'SHOP NOW' | translate}}
                                        <!-- <img class="mb-1" src="../../assets/images/arrow.svg"> -->
                                    </span>

                                </a>
                            </div>
                        </a>
                    </div>
                </ngx-slick-carousel>
            </div>

            <!-- small device slider  -->
            <div class="mobileslider">
                <div class="slider-container ">

                    <div *ngFor="let product of counter(6); let i=index"
                        class="carousal-information carousal-stylingg box">
                        <div class="mobile-data-style  skeltonbox">
                            <ngx-skeleton-loader class="height-fix"></ngx-skeleton-loader>
                        </div>
                    </div>

                    <div class="last-carousal-card carousal-information carousal-stylingg box">
                        <div (click)="bannerRouting(section,'P')">
                            <a class="flex gap20px items-center ">
                                <div class="flex flex-col">
                                    <p class="new-items-info">{{'More Styles' | translate}} <br> {{'Waiting' |
                                        translate}}
                                    </p>
                                    <a class="shop-now" >
                                        <span class="show-now-special">{{'SHOP NOW' | translate}}
                                            <!-- <img class="mb-1" src="../../assets/images/arrow.svg"> -->
                                        </span>

                                    </a>
                                </div>
                            </a>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!-- 3rd -->
        <div class="hero-section  margin-bottom-25px mt-3" *ngIf="skeletonLoader">
            <a class="" *ngFor="let banner of counter(2);">
                <div  class="first-banner">
                    <div class="hero-section-imgs skelton-btn-height">
                     <ngx-skeleton-loader class="height-fix"></ngx-skeleton-loader>
                    </div>
                </div>
                <div class="skeleton-width-name mx-auto">
                    <ngx-skeleton-loader  class="height-fix"></ngx-skeleton-loader>
                </div>
                <div class="w-25 mx-auto">
                    <ngx-skeleton-loader  class="height-fix"></ngx-skeleton-loader>
                </div>
                <div class="button-width skelton-btn-height mx-auto">
                    <ngx-skeleton-loader  class="height-fix"></ngx-skeleton-loader>
                </div>
            </a>
        </div>
        <div *ngFor="let product of counter(3); let i=index; let last = last; let c = count">
        <div *ngIf="skeletonLoader" class="carousel-container margin-bottom-50px ">
            <!-- desktop device slider  -->

            <div class="desktopslider">
                <ngx-slick-carousel class="carousel-home customHeight" #slickModal="slick-carousel"
                    [config]="slideConfig">
                    <div class="carousal-information" ngxSlickItem
                        *ngFor="let product of counter(10); let i=index; let last = last; let c = count"
                        class="slide m-2 carousal-stylingg ">
                        <div class="mobile-data-style  skeltonbox">
                            <ngx-skeleton-loader class="height-fix"></ngx-skeleton-loader>
                        </div>
                    </div>
                    <div ngxSlickItem class="last-carousal-card slide m-2 carousal-stylingg">
                        <a class="flex gap20px items-center ">
                            <div class="flex flex-col">
                                <p class="new-items-info">{{'More Styles Waiting' | translate}}</p>
                                <a class="shop-now" >
                                    <span class="show-now-special">{{'SHOP NOW' | translate}}
                                        <!-- <img class="mb-1" src="../../assets/images/arrow.svg"> -->
                                    </span>

                                </a>
                            </div>
                        </a>
                    </div>
                </ngx-slick-carousel>
            </div>

            <!-- small device slider  -->
            <div class="mobileslider">
                <div class="slider-container ">

                    <div *ngFor="let product of counter(6); let i=index"
                        class="carousal-information carousal-stylingg box">
                        <div class="mobile-data-style  skeltonbox">
                            <ngx-skeleton-loader class="height-fix"></ngx-skeleton-loader>
                        </div>
                    </div>

                    <div class="last-carousal-card carousal-information carousal-stylingg box">
                        <div (click)="bannerRouting(section,'P')">
                            <a class="flex gap20px items-center ">
                                <div class="flex flex-col">
                                    <p class="new-items-info">{{'More Styles' | translate}} <br> {{'Waiting' |
                                        translate}}
                                    </p>
                                    <a class="shop-now" >
                                        <span class="show-now-special">{{'SHOP NOW' | translate}}
                                            <!-- <img class="mb-1" src="../../assets/images/arrow.svg"> -->
                                        </span>

                                    </a>
                                </div>
                            </a>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        </div>
        
        <!-- skeleton loader ends-->
        <div class="section-div" *ngFor="let section of sections; " [ngStyle]="{ 'background': section?.bg_color  }">
            <div class="information" *ngIf="!skeletonLoader" [ngStyle]="{'text-align': section?.text_alignment}">
                <p class="herocard-title"   [ngClass]="section?.set_sub_title != '' && section?.hide_category_title == 0?'':'section-titles'" *ngIf="section?.hide_category_title === 0 && section?.link_type !== 'SL' && section?.link_type !== 'CE'">{{section.set_title}} </p>
                <p class="herocard-go-to-title"
                    *ngIf="section?.set_sub_title != '' && section?.hide_category_title == 0">{{section.set_sub_title}}
                </p>
            </div>
            <div class="desktop-view" *ngIf="!skeletonLoader && section.display_type == 'D'">
            <div class="hero-section  margin-bottom-25px" [ngClass]="{'mange-section-small': section.is_scroll == 1}" *ngIf="!skeletonLoader && section.display_type == 'D'">
                <a [ngStyle]="section.banners_in_rows > 1 && { 'width.%': setBannerZoom(section?.small_device_zoom )}"   [ngClass]="section.display_type == 'D' && section.banners_in_rows == 1 ? 'manage-width':'' " (click)="bannerRouting(banner,'B')" *ngFor="let banner of section.banners">
                   <span  *ngIf="section.banners_in_rows > 1" id="takeMyWidth"  >
                   
                       <img  [src]="isMobile ? banner.mob_image : banner.image" class="hero-section-imgs" [id]="'banner'+i"
                         [ngClass]="{'shipping-banner': section.banners_in_rows == 1}" >
                       <!-- [title]="banner.name" -->
                       <div class="information" [ngStyle]="{'text-align': section?.text_alignment}">
                           <div class="div-marging-mange">
                           <p class="herocard-title title-mange" *ngIf="section?.hide_banner_title == 0">{{banner.name}} </p>
                           <p class="herocard-go-to-title" *ngIf="banner?.sub_title != '' && section?.hide_banner_title == 0">
                               {{banner.sub_title}} </p>
                           </div>
                           <div class="shop-now gap6px" [ngClass]="{'justify-content': section?.text_alignment}"
                               *ngIf="section?.hide_banner_title == 0">
                               <span *ngIf="banner.button_text == ''">{{'SHOP NOW' | translate}}
                                   <!-- <img src="../../assets/images/arrow.svg"> -->
                               </span>
                               <span *ngIf="banner.button_text != ''">{{banner.button_text}}
                                   <!-- <img  src="../../assets/images/arrow.svg"> -->
                               </span>
                           </div>
                       </div>
                       <!-- deval sir need white space ing background -->
                       <div class="background-color-manage d-block d-md-none" *ngIf="section?.is_scroll == 0"></div>
                   </span>

                   <span *ngIf="section.banners_in_rows ==1">
                    <img  [src]="isMobile ? banner.mob_image : banner.image" class="hero-section-imgs"
                    [ngClass]="{'shipping-banner': section.banners_in_rows == 1}" >
                  <!-- [title]="banner.name" -->
                  <div class="information" [ngStyle]="{'text-align': section?.text_alignment}">
                      <div class="div-marging-mange">
                      <p class="herocard-title title-mange" *ngIf="section?.hide_banner_title == 0">{{banner.name}} </p>
                      <p class="herocard-go-to-title" *ngIf="banner?.sub_title != '' && section?.hide_banner_title == 0">
                          {{banner.sub_title}} </p>
                      </div>
                      <div class="shop-now gap6px" [ngClass]="{'justify-content': section?.text_alignment}"
                          *ngIf="section?.hide_banner_title == 0">
                          <span *ngIf="banner.button_text == ''">{{'SHOP NOW' | translate}}
                              <!-- <img src="../../assets/images/arrow.svg"> -->
                          </span>
                          <span *ngIf="banner.button_text != ''">{{banner.button_text}}
                              <!-- <img  src="../../assets/images/arrow.svg"> -->
                          </span>
                      </div>
                  </div>
                  <!-- deval sir need white space ing background -->
                  <div class="background-color-manage d-block d-md-none" *ngIf="section?.is_scroll == 0"></div>
                   </span>
                </a>
            </div>
            </div>

            <!-- Shop the look section -->
            <div class="shop-look-conatiner" *ngIf="section.shop_the_look?.length > 0 && !skeletonLoader">
                <div class="look-container">
                    <p class="shop-look-header" [ngStyle]="{'text-align': section?.text_alignment}" *ngIf="section?.hide_category_title == 0 && !skeletonLoader">{{section.set_title}} </p>
                    <div class="carousel container-bottom" >
                        <div class="shop-flex" *ngFor="let product of section.shop_the_look">
                            <app-shop-look [product]="product"></app-shop-look>
                          </div>                          
                    </div>
                </div>
            </div>
            <!-- Shop the look section -->

            <!-- Event Edit Section -->
            <div class="shop-look-conatiner" *ngIf="section?.category_edit.length > 0 && !skeletonLoader">
                <div class="look-container container-bottom">
                    <p class="shop-look-header  event px-2" *ngIf="section?.hide_category_title == 0">{{section.set_title}} </p>
                    <app-event-edit [eventData]="section?.category_edit"></app-event-edit>
                </div>
            </div>
            <!-- Event Edit Section -->

            <!-- Grid Section -->
            <div class="container-fluid" *ngIf="section.display_type == 'G' && !skeletonLoader">
                <div class="grid_slider mt-3 mb-5">
                    <app-grid-view-slider [data]="section.banners"></app-grid-view-slider>
                </div>
            </div>
            <!-- Grid Section -->

            <!-- Center Mode Slider -->
            <div class="event-div dots-div position-relative" *ngIf="section.display_type == 'C' && !skeletonLoader">
                <div class="position-absolute more-position">
                    <p  (click)="bannerRouting(section,'P')">{{'More' | translate}} +</p>
                </div>
                <app-center-mode-slider [data]="section.products"></app-center-mode-slider>
            </div>
            <!-- Center Mode Slider -->


            <div *ngIf="(section.products?.length > 1 && !skeletonLoader) && section.display_type != 'C'" class="carousel-container event-div">
                <!-- small device slider  -->
                <div class="mobileslider">
                    <div class="slider-container ">

                        <div *ngFor="let product of section.products; let i=index"
                            class="carousal-information carousal-stylingg box">
                            <div *ngIf="skeletonLoader" class="mobile-data-style">
                                <ngx-skeleton-loader [theme]="{
                                'border-radius': '5px',
                                height: '100px',
                                'animation-duration': '2s'
                              }"></ngx-skeleton-loader>
                            </div>
                            <div class="" *ngIf="!skeletonLoader">
                                
                                <a class="position-relative" (click)="routingToDetails(product?.id)">
                                    <div *ngIf="product.regular_price != product.final_price" class="new_sale">
                                        <img  src="/assets/icons/SALE-Tag.svg" alt="">
                                        <span>
                                            <span>-</span>{{ calculateDiscountAmount(product)}}<span>%</span>
                                        </span>
                                    </div>
                                    <figure>
                                        <div class="testnew">
                                            <img [src]="product.image" class="carousel-imgs1">
                                        </div>
                                        <figcaption *ngIf="section?.hide_product_details == 0">
                                            <div class="carousal-title-brand uppercase">{{product.brand_name}}</div>
                                            <div class="carousal-title uppercase">{{product.name}}</div>
                                            <div class=" mt-2 d-flex gap-3 justify-content-center align-items-center">
                                                <span class="final-price carousal-title-price uppercase" [ngClass]="(product.regular_price != product.final_price) ? 'red' : ''">{{currency}} {{product.final_price | number:'1.3-3'}}</span>
                                                <span class="crossed crossed-price carousal-title-price uppercase" *ngIf="(product.regular_price != product.final_price)"><span class="line-price">{{currency}} {{product.regular_price | number:'1.3-3'}}</span></span>
                                            </div>
                                            <div class="carousal-title-badge uppercase">{{setProductBadge(product)}}</div>
                                        </figcaption>
                                        <figcaption *ngIf="section?.hide_product_details == 1">
                                            <div class="carousal-title-brand uppercase">{{product.name}}</div>
                                        </figcaption>
                                    </figure>
                                </a>
                            </div>
                        </div>

                        <div class="last-carousal-card carousal-information carousal-stylingg box">
                            <div (click)="bannerRouting(section,'P')">
                                <a class="flex gap20px items-center ">
                                    <div class="flex flex-col">
                                        <p class="new-items-info">{{'More Styles' | translate}} <br> {{'Waiting' |
                                            translate}}
                                        </p>
                                        <a class="shop-now" [ngClass]="{'justify-content': section?.text_alignment}">
                                            <span class="show-now-special">{{'SHOP NOW' | translate}}
                                                <!-- <img class="mb-1" src="../../assets/images/arrow.svg"> -->
                                            </span>

                                        </a>
                                    </div>
                                </a>
                            </div>
                        </div>

                    </div>
                </div>

                <!-- desktop device slider  -->
                <div class="desktopslider" *ngIf="section.products?.length > 1 && !skeletonLoader">

                    <ngx-slick-carousel class="carousel-home customHeight" #slickModal="slick-carousel"
                        [config]="slideConfig">
                        <div class="carousal-information" ngxSlickItem
                            *ngFor="let product of section.products; let i=index; let last = last; let c = count"
                            class="slide m-2 carousal-stylingg ">
                            <div>
                                <div *ngIf="product.regular_price != product.final_price" class="new_sale">
                                    <img  src="/assets/icons/SALE-Tag.svg" alt="">
                                    <span>
                                        <span>-</span>{{ calculateDiscountAmount(product)}}<span>%</span>
                                    </span>
                                </div>
                                <a class="mobile-data-style" (click)="routingToDetails(product?.id)">
                                    <figure>
                                        <div class="testnew">
                                            <a href="/product/detail/{{ product?.id }}" (click)="$event.preventDefault()">
                                                <img [src]="product.image" class="carousel-imgs1 product-normal-image" [alt]="product?.name">
                                                <img [src]="product.hover_image" class="carousel-imgs1 product-hovered-image" [alt]="product?.name">
                                            </a>
                                        </div>
                                        <figcaption *ngIf="section?.hide_product_details == 0">
                                            <div class="carousal-title-brand uppercase">{{product.brand_name}}</div>
                                            <div class="carousal-title uppercase">{{product.name}}</div>
                                            <div class=" mt-2 d-flex gap-3 justify-content-center align-items-center">
                                                <span class="final-price carousal-title-price uppercase" [ngClass]="(product.regular_price != product.final_price) ? 'red' : ''">{{currency}} {{product.final_price | number:'1.3-3'}}</span>
                                                <span class="crossed crossed-price carousal-title-price uppercase" *ngIf="(product.regular_price != product.final_price)"><span class="line-price">{{currency}} {{product.regular_price | number:'1.3-3'}}</span></span>
                                            </div>
                                            <div class="carousal-title-badge uppercase">{{setProductBadge(product)}}</div>
                                        </figcaption>
                                        <figcaption *ngIf="section?.hide_product_details == 1">
                                            <div class="carousal-title-brand uppercase">{{product.name}}</div>
                                        </figcaption>
                                    </figure>
                                </a>
                            </div>
                        </div>
                        <div ngxSlickItem class="last-carousal-card slide m-2 carousal-stylingg"
                            (click)="bannerRouting(section,'P')">
                            <a class="flex gap20px items-center ">
                                <div class="flex flex-col">
                                    <p class="new-items-info">{{'More Styles Waiting' | translate}}</p>
                                    <a class="shop-now" [ngClass]="{'justify-content': section?.text_alignment}">
                                        <span class="show-now-special">{{'SHOP NOW' | translate}}
                                            <!-- <img class="mb-1" src="../../assets/images/arrow.svg"> -->
                                        </span>

                                    </a>
                                </div>
                            </a>
                        </div>


                    </ngx-slick-carousel>
                </div>



            </div>
        </div>
        <button *ngIf="sections?.length > 0 && !skeletonLoader" class="scroll-to-top-button pb-2" (click)="scrollTop()">
            <img src="../../assets/images/arrow-up.png" class="scroll-to-top-img">
            <span class="scroll-to-top-title">{{'BACK TO TOP' | translate}}</span>
        </button>
    </div>


</div>
<!-- 
<div class="popup-container" *ngIf="showPopup">
    <div class="popup-content">
        <p class="pb-3">{{"By clicking ‘Accept Cookies’, you agree to the storing of cookies on your device to enhance
            website navigation, analyse website usage." | translate}}</p>
        <div class="button-section">
            <button class="cancel-cookies popup-button" (click)="togglePopup(false,false)">{{'Close' |
                translate}}</button>
            <button class="accept-cookies popup-button" (click)="togglePopup(false,true)">{{'Accept Cookies' |
                translate}}</button>
        </div>
    </div>
</div> -->

<!-- Button trigger modal -->
<!-- <button type="button" class="d-none" data-toggle="modal" data-target="#advertisement" #advertisement>
</button> -->

<!-- Modal -->
<div class="modal fade" id="advertisement" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content content-background">
            <div>
                <div class="modal-background">
                    <img class="brand-img" [src]="[landingAds?.image]" alt="">
                    <button type="button" class="close flashButton" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true" (click)="closed()"><img src="../../assets/images/100.png" alt=""
                                class="crossIcon"></span>
                    </button>
                    <div class="adButtonsGroup">
                        <button type="button" class="adButtons-Enter" data-dismiss="modal" aria-label="Close"
                            (click)="landingAdsUrl(landingAds)">
                            {{'View' | translate}}
                        </button>
                        <button type="button" class="adButtons-Skip" data-dismiss="modal" aria-label="Close"
                            (click)="closed()">
                            {{'Skip' | translate}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>