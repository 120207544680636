// import {EncryptionService} from 'angular-encryption-service';
import {ConfigSettings} from './config.settings';
import {Injectable} from '@angular/core';
import * as CryptoTS from 'crypto-ts';

@Injectable({
    providedIn: 'root'
})
export class CryptService {
    constructor(
        // public encryptionService: EncryptionService
        ) {}

    doEncrypt(plainText: string): Promise<string> {
 // Encrypt
        return new Promise((resolve, reject) => {
            try {
                const ciphertext = CryptoTS.AES.encrypt(plainText, ConfigSettings.ENCRYPT_KEY);
                resolve(ciphertext.toString());
            } catch (error) {
                reject(error);
            }
        });
    }

    doDecrypt(encryptedText: string): Promise<string> {
        // Decrypt
        return new Promise((resolve, reject) => {
            try {
                const bytes = CryptoTS.AES.decrypt(encryptedText, ConfigSettings.ENCRYPT_KEY);
                const plaintext = bytes.toString(CryptoTS.enc.Utf8);
                resolve(plaintext);
            } catch (error) {
                reject(error);
            }
        });
    }
}
