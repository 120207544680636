<div class="compo-div" [ngClass]="isPopup ? 'model-div' : ''">
  <div class="edit_data_header">
    <div class="d-flex justify-content-between w-100 align-items-center" *ngIf="isPopup">
      <div class="w-100">
        <div class="d-flex align-items-center justify-content-center gap-3">
          <!-- <div>
                    <img
                      class="edit_profile"
                      src="/assets/icons/Location.svg"
                      alt="Edit Profile"
                    />
                  </div> -->
          <div class="w-100 text-center" *ngIf="isEditAddress == false">
            <h1>{{ "Add Address" | translate }}</h1>
          </div>
          <div class="w-100 text-center" *ngIf="isEditAddress == true">
            <h1>{{ "Edit Address" | translate }}</h1>
          </div>
        </div>
      </div>
      <div>
        <img class="cross_popup" src="/assets/icons/cross_popup.svg" alt="Cross" (click)="closePopup()">
      </div>
    </div>
    <!-- <div class="shipping_to">
              <p>Shipping to <img src="../../assets/icons/kuwait_flag.svg" alt="flag"> Kuwait</p>
            </div> -->
  </div>
  <form [formGroup]="addressForm" (ngSubmit)="saveAddress()">
    <div class="edit_data_body">
      <div>
        <label for="">{{'First Name' | translate}}<span>*</span></label>
        <input type="text" id="first_name" name="address-first_name" aria-describedby="firstNameHelp"
          formControlName="first_name" required>
        <small id="firstNameHelp" class="form-text form-validator_error"
          *ngIf="addressForm.controls['first_name'].invalid && (addressForm.controls['first_name'].dirty || addressForm.controls['first_name'].touched)">
          {{ 'Please enter your Name' | translate }}
        </small>
      </div>
      <div>
        <label for="">{{'Last Name' | translate}}<span>*</span></label>
        <input type="text" id="last_name" name="address-last_name" aria-describedby="lastNameHelp"
          formControlName="last_name" required>
        <small id="lastNameHelp" class="form-text form-validator_error"
          *ngIf="addressForm.controls['last_name'].invalid && (addressForm.controls['last_name'].dirty || addressForm.controls['last_name'].touched)">
          {{'Please enter your Name' | translate}}
        </small>
      </div>
      <div class="edit_data_body-cols">
        <label for="">{{'Mobile' | translate}}<span>*</span></label>
        <div class="d-flex gap-3 w-100 align-items-baseline">
          <div class="country-code">

            <input disabled value={{countryCode}}>
          </div>
          <div class="">
            <input id="mobile_number" name="address-mobile_number" (keydown)="validatePhone($event)"
              aria-describedby="mobileNumberHelp" formControlName="mobile_number" inputmode="tel" maxlength="10" minlength="5" required>
            <small id="mobileNumberHelp" class="form-text form-validator_error"
              *ngIf="addressForm.controls['mobile_number'].invalid && (addressForm.controls['mobile_number'].dirty || addressForm.controls['mobile_number'].touched)">
              {{'Please enter a valid mobile number' | translate}}
            </small>
          </div>

        </div>


      </div>
      <div class="edit_data_body-cols">
        <label>{{'Country' | translate}}<span>*</span></label>
        <ng-select [items]="countriesArr" [addTag]="true" bindLabel="name" bindValue="id" id="country_id"
          name="address-country_id" aria-describedby="countryHelp" formControlName="country_id" [clearable]=false
          [searchable]=false [placeholder]="'Select One' | translate" (change)="whenCountryChanges();getStates($event)"
          class="select-input add-ship" required>
          <ng-option [value]="items.id" *ngFor="let items of countriesArr">
            {{items.name |titlecase}}
          </ng-option>
        </ng-select>
        <small id="countryHelp" class="form-text form-validator_error"
          *ngIf="addressForm.controls['country_id'].invalid && (addressForm.controls['country_id'].dirty || addressForm.controls['country_id'].touched)">
          {{'Please select your country' | translate}}
        </small>
      </div>
      <div>
        <label>{{'Governorate' | translate}}<span>*</span></label>
        <ng-select [items]="governorateArr" [addTag]="true" bindLabel="name" bindValue="id" id="state_id" name="address-state_id"
          aria-describedby="governorateHelp" formControlName="state_id" [clearable]=false [searchable]=false
          [placeholder]="'Select One' | translate" (change)="whenStateChanges();getAreas($event)"
          class="select-input add-ship" required>
          <ng-option [value]="items.id" *ngFor="let items of governorateArr">
            {{items.name |titlecase}}
          </ng-option>
        </ng-select>
        <small id="governorateHelp" class="form-text form-validator_error"
          *ngIf="addressForm.controls['state_id'].invalid && (addressForm.controls['state_id'].dirty || addressForm.controls['state_id'].touched)">
          {{'Please select your state/prov./region' | translate}}
        </small>
      </div>
      <div>
        <label>{{'Town / City' | translate}}<span>*</span></label>
        <ng-select [items]="areasArr" [addTag]="true" bindLabel="name" bindValue="id" id="area_id"
          name="address-area_id" aria-describedby="areaHelp" formControlName="area_id" [clearable]=false
          [searchable]=false [placeholder]="'Select One' | translate" (change)="whenAreaChanges();getBlocks($event)"
          class="select-input add-ship" required>
          <ng-option [value]="items.id" *ngFor="let items of areasArr">
            {{items.name |titlecase}}
          </ng-option>
        </ng-select>
        <small id="areaHelp" class="form-text form-validator_error"
          *ngIf="addressForm.controls['area_id'].invalid && (addressForm.controls['area_id'].dirty || addressForm.controls['area_id'].touched)">
          {{'Please select your city/area' | translate}}
        </small>
      </div>
      <div *ngIf="blocksArr?.length > 0">
        <label>{{'Block' | translate}}</label>
        <ng-select [items]="blocksArr" [addTag]="true" bindLabel="name" bindValue="id" id="block_id"
          name="address-block_id" formControlName="block_id" [clearable]=false [searchable]=false
          [placeholder]="'Select One' | translate" class="select-input add-ship">
          <ng-option [value]="items.id" *ngFor="let items of blocksArr">
            {{items.name |titlecase}}
          </ng-option>
        </ng-select>
      </div>
      <div *ngIf="blocksArr?.length == 0">
        <label>{{'Block' | translate}}</label>
        <input class="form-control text-input" id="block_name" name="address-block_name" aria-describedby="blockHelp"
          formControlName="block_name" maxlength="100">
        <!-- <small id="streetHelp" class="form-text form-validator_error"
                  *ngIf="addressForm.controls['block_id'].invalid && (addressForm.controls['block_id'].dirty || addressForm.controls['block_id'].touched)">
                  {{'Please enter street' | translate}}
                </small> -->
      </div>
      <div>
        <label>{{'Street' | translate}}<span>*</span></label>
        <input class="form-control text-input" id="street" name="address-street" aria-describedby="streetHelp"
          formControlName="street" maxlength="100" required>
        <small id="streetHelp" class="form-text form-validator_error"
          *ngIf="addressForm.controls['street'].invalid && (addressForm.controls['street'].dirty || addressForm.controls['street'].touched)">
          {{'Please enter street' | translate}}
        </small>
      </div>
      <div>
        <label>{{'Avenue' | translate}}</label>
        <input formControlName="avenue" class="form-control text-input" id="avenue" name="address-avenue">
      </div>

      <div>
        <label>{{'House / Building' | translate}}</label>
        <input formControlName="addressline_1" class="form-control text-input" id="addressline_1"
          name="address-addressline_1">
      </div>
      <div>
        <label>{{'Floor' | translate}}</label>
        <input formControlName="floor" class="form-control text-input" id="floor" name="address-floor">
      </div>
      <div>
        <label>{{'Flat' | translate}}</label>
        <input formControlName="flat" class="form-control text-input" id="flat" name="address-flat"
          aria-describedby="flatHelp">
        <small id="flatHelp" class="form-text form-validator_error"
          *ngIf="addressForm.controls['flat'].invalid && (addressForm.controls['flat'].dirty || addressForm.controls['flat'].touched)">
          {{'Please enter your House/Flat' | translate}}
        </small>
      </div>

      <div>
        <label>{{'Landmark' | translate}}<span *ngIf="(selectedCountry?.is_landmark_required == 1)">*</span></label>
        <input formControlName="landmark" class="form-control text-input" id="landmark" name="address-landmark"
          aria-describedby="landmarkHelp" *ngIf="(selectedCountry?.is_landmark_required != 1)">
        <input formControlName="landmark" class="form-control text-input" id="landmark" name="address-landmark"
          aria-describedby="landmarkHelp" *ngIf="(selectedCountry?.is_landmark_required == 1)" required>
        <small id="landmarkHelp" class="form-text form-validator_error"
          *ngIf="addressForm.controls['landmark'].invalid && (addressForm.controls['landmark'].dirty || addressForm.controls['landmark'].touched)">
          {{'Please enter your landmark' | translate}}
        </small>
      </div>

      <div>
        <label>{{ 'ID Number' | translate }}<span *ngIf="selectedCountry?.is_id_mandatory === 1">*</span></label>
        <input class="form-control text-input" id="id_number" (keydown)="validateIdNumber($event)"
          name="address-id_number" aria-describedby="idNumberHelp" formControlName="id_number"
          *ngIf="selectedCountry?.is_id_mandatory !== 1">
        <input class="form-control text-input" id="id_number" (keydown)="validateIdNumber($event)"
          name="address-id_number" aria-describedby="idNumberHelp" formControlName="id_number" required
          *ngIf="selectedCountry?.is_id_mandatory === 1">
      </div>

    </div>

    <div>
      <div class="edit_note">
        <label for="">{{'Notes (Landmark or delivery instructions)' | translate}}</label>
        <textarea formControlName="notes" id="notes" name="address-notes"></textarea>
      </div>
    </div>

    <div class="">
      <label class="custom-checkbox-filter" for="default">
        <input type="checkbox" class="filter-checkbox-input" formControlName="is_default" id="default"
          (change)="updateIsDefault()" />
        <b></b>
        <span>{{'Make as default address'|translate}}</span>
      </label>
    </div>

    <div class="edit_data_footer text-center">
      <button [ngClass]="{'disable-button-styles': !addressForm.valid}" [disabled]="!addressForm.valid"
        type="submit">{{'CONTINUE'|translate}}</button>
    </div>
  </form>
</div>