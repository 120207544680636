<div class="user-actions-side" *ngIf="dataAvailable">
  <p class="side-menu-header borderLeft expand-toggle" (click)="toggleShowNow()" *ngIf="validUserSession" [ngClass]="{'side-menu-header': validUserSession, 'text-center':!validUserSession}">{{'My account' | translate}}</p>
  <ng-container *ngIf="validUserSession && showNow">
    <div class="side-menu">
      <div class="side-menu-item">
        <a [routerLink]="'/my-account'">{{user.first_name +' '+ user.last_name}}</a>
      </div>
      <div class="side-menu-item">
        <a [routerLink]="'/my-orders'">{{'My orders' | translate}}</a>
      </div>
      <div class="side-menu-item">
          <a [routerLink]="'/return-request-list'">{{'My returns' | translate}}</a>
        </div>
      <div class="side-menu-item">
        <a [routerLink]="'/my-address'">{{'Address book' | translate}}</a>
      </div>
      <div class="side-menu-item">
          <a [routerLink]="'/my-wishlist'">{{'Wishlist' | translate}}</a>
      </div>
      <div class="side-menu-item">
        <a [routerLink]="'/refer-a-friend'">{{'Refer A Friend' | translate}}</a>
      </div>
      <div class="side-menu-item">
        <a [routerLink]="'/wallet-credit'">{{'My Credit' | translate}}</a>
      </div>
      <div class="side-menu-item">
        <a [routerLink]="'/loyalty-point'">{{'Loyalty Point' | translate}}</a>
      </div>
      <div class="side-menu-item">
        <a href="javascript:;" (click)="showLogoutModal()">{{'Logout' | translate}}</a>
      </div>
    </div>
  </ng-container>
</div>
