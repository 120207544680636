<ng-container *ngIf="dataAvailable">
<section class="{{lang == 'ar' ? 'text-right' : ''}}" id="" @listStagger>
  <div class="maintitle-Container">
    <h4 class="page-title text-center font-black font-condensed">{{'ADD ADDRESS' | translate}}</h4>
  </div>

  <div class="container-fluid">
    <section class="content-section">
      <div class="user-left-menu d-none d-lg-block w-25">
        <app-user-account-menu [page]="3"></app-user-account-menu>
      </div>

      <div class="white-background w-75">
        <p class="required mb-1 text-right">* {{'Required fields' | translate}}</p>
        <form #shippingAddressForm="ngForm" (ngSubmit)="saveAddress(shippingAddressForm)">
        <div class="row mb-3 pt-3">
          <div class="col-12 form-group field-address-first_name required">
            <label for="address-first_name" class="control-label">{{'Full Name' | translate}}</label>
            <input [(ngModel)]="address.first_name" class="form-control text-input" id="address-first_name"
                   name="address-first_name" aria-describedby="firstNameHelp" #paramFirstName="ngModel" required>
            <small id="firstNameHelp" class="form-text form-validator_error" [hidden]="paramFirstName.valid || paramFirstName.pristine">
                {{'Please enter your Name' | translate}}
            </small>
          </div>

          <div class="col-12 col-md-6 form-group field-address-country_id required">
            <label for="address-country_id" class="control-label">{{'Country' | translate}}</label>
            <ng-select [items]="countries" [addTag]="true" bindLabel="name" bindValue="id" id="address-country_id" name="address-country_id"
                       aria-describedby="countryHelp" #paramCountry="ngModel"
                       [clearable]=false [searchable]=false [placeholder]="'Select One' | translate" (change)="getStates($event)" [(ngModel)]="address.country_id" required></ng-select>
            <small id="countryHelp" class="form-text form-validator_error" [hidden]="paramCountry.valid || paramCountry.pristine">
                {{'Please select your country' | translate}}
            </small>
          </div>

          <div class="col-12 col-md-6 form-group field-address-governorate_id required">
            <label for="address-governorate_id" class="control-label">{{'Governorate' | translate}}</label>
            <ng-select [items]="governorates" [addTag]="true" bindLabel="name" bindValue="id" id="address-governorate_id" name="address-governorate_id"
                       aria-describedby="governorateHelp" #paramGovernorate="ngModel"
                       [clearable]=false [searchable]=false [placeholder]="'Select One' | translate" (change)="getAreas($event)" [(ngModel)]="address.governorate_id" required></ng-select>
            <small id="governorateHelp" class="form-text form-validator_error" [hidden]="paramGovernorate.valid || paramGovernorate.pristine">
                {{'Please select your state/prov./region' | translate}}
            </small>
          </div>

          <div class="col-12 col-md-6 form-group field-address-area_id  {{(areas.length) > 0 ? 'required' : ''}}" *ngIf="areas.length">
            <label for="address-area_id" class="control-label">{{'City' | translate}}</label>
            <ng-select [items]="areas" [addTag]="true" bindLabel="name" bindValue="id" id="address-area_id" name="address-area_id"
                       aria-describedby="areaHelp" #paramArea="ngModel"
                       [clearable]=false [searchable]=false [placeholder]="'Select One' | translate" (change)="getBlocks($event)" [(ngModel)]="address.area_id" [required]="(areas.length) > 0 ? true : false"></ng-select>
            <small id="areaHelp" class="form-text form-validator_error" [hidden]="paramArea.valid || paramArea.pristine">
                {{'Please select your city/area' | translate}}
            </small>
          </div>

          <div class="col-12 col-md-6 form-group field-address-block_id required" *ngIf="sectors.length">
            <label for="address-block_id" class="control-label">{{'Block' | translate}}</label>
            <ng-select [items]="sectors" [addTag]="true" bindLabel="name" bindValue="id" id="address-block_id" name="address-block_id"
                       #paramBlock="ngModel"
                       [clearable]=false [searchable]=false [placeholder]="'Select One' | translate" [(ngModel)]="address.block_id"></ng-select>
            <small id="sectorHelp" class="form-text form-validator_error" [hidden]="paramBlock.valid || paramBlock.pristine">
              {{'Please select your block' | translate}}
            </small>
          </div>

          <div class="clearfix w-100"></div>

          <div class="col-12 col-md-6 form-group field-address-street required">
            <label for="address-street" class="control-label">{{'Street' | translate}}</label>
            <input [(ngModel)]="address.street" class="form-control text-input" id="address-street"
                   name="address-street" aria-describedby="streetHelp" #paramStreet="ngModel" required>
            <small id="streetHelp" class="form-text form-validator_error" [hidden]="paramStreet.valid || paramStreet.pristine">
                {{'Please enter street' | translate}}
            </small>
          </div>

          <div class="col-12 col-md-6 form-group field-address-addressline_1">
            <label for="address-avenue" class="control-label">{{'Avenue' | translate}}</label>
            <input [(ngModel)]="address.avenue" class="form-control text-input" id="address-avenue" name="address-avenue">
          </div>

          <div class="clearfix w-100"></div>

          <div class="col-12 col-md-6 form-group field-address-addressline_1">
            <label for="address-addressline_1" class="control-label">{{'House/Apartment' | translate}}</label>
            <input [(ngModel)]="address.addressline_1" class="form-control text-input" id="address-addressline_1" name="address-addressline_1">
          </div>

          <div class="col-12 col-md-6 form-group field-address-addressline_1">
            <label for="address-floor" class="control-label">{{'Floor' | translate}}</label>
            <input [(ngModel)]="address.floor" class="form-control text-input" id="address-floor" name="address-floor">
          </div>

          <div class="clearfix w-100"></div>

          <div class="col-12 col-md-6 form-group field-address-addressline_1 required">
            <label for="address-flat" class="control-label">{{'House/Flat' | translate}}</label>
<!--            <input [(ngModel)]="address.flat" class="form-control text-input" id="address-flat" name="address-flat">-->
            <input [(ngModel)]="address.flat" class="form-control text-input" id="address-flat"
                   name="address-flat" aria-describedby="flatHelp" #paramFlat="ngModel" required>
            <small id="flatHelp" class="form-text form-validator_error" [hidden]="paramFlat.valid || paramFlat.pristine">
              {{'Please enter your House/Flat' | translate}}
            </small>
          </div>

          <div class="col-12 col-md-6 form-group field-address-addressline_1 required">
            <label for="address-landmark" class="control-label">{{'Landmark' | translate}}</label>
            <input [(ngModel)]="address.landmark" class="form-control text-input" id="address-landmark"
                   name="address-landmark" aria-describedby="flatHelp" #paramFlat="ngModel" required>
            <small id="flatHelp" class="form-text form-validator_error" [hidden]="paramFlat.valid || paramFlat.pristine">
              {{'Please enter your landmark' | translate}}
            </small>
          </div>

          <div class="col-12 col-md-6 form-group field-address-mobile_number required">
            <label for="address-mobile_number" class="control-label">{{'Mobile Number' | translate}}</label>
            <div class="input-field-address-mobile_number">
              <input class="form-control text-input code" disabled placeholder={{countryCode}}>
              <input [(ngModel)]="address.mobile_number" class="form-control text-input" id="address-mobile_number"
                    name="address-mobile_number" aria-describedby="mobileNumberHelp" #paramMobileNumber="ngModel" 
                    pattern="[0-9]+" maxlength="10" minlength="5" required>
            </div>
            <small id="mobileNumberHelp" class="form-text form-validator_error" [hidden]="paramMobileNumber.valid || paramMobileNumber.pristine">
                {{'Please enter a valid mobile number' | translate}}
            </small>
          </div>

          <div class="clearfix w-100"></div>

          <ng-container *ngIf="address.country_id === 174 || address.country_id === 187">
            <div class="col-12 col-md-6 form-group field-address-mobile_number required">
              <label for="address-id_number" class="control-label">{{'ID Number' | translate}}</label>
              <input [(ngModel)]="address.id_number" class="form-control text-input" id="address-id_number"
                     name="address-id_number" aria-describedby="idNumberHelp" #idNumberNumber="ngModel" required>
              <small id="idNumberHelp" class="form-text form-validator_error" [hidden]="idNumberNumber.valid || idNumberNumber.pristine">
                {{'Please enter a your ID Number' | translate}}
              </small>
            </div>
            <div class="clearfix w-100"></div>
          </ng-container>

          <div class="col-12 col-md-12 form-group field-address-addressline_1">
            <label for="address-notes" class="control-label">{{'Notes' | translate}}</label>
            <input [(ngModel)]="address.notes" class="form-control text-input" id="address-notes" name="address-notes">
          </div>

          <div class="clearfix w-100"></div>
          <div class="col-12 col-md-3">
            <button type="submit" class="btn btn-dark button-large btn-block" [disabled]="!shippingAddressForm.form.valid">
              {{'Save' | translate}}
            </button>
          </div>

        </div>
        </form>
      </div>
    </section>
  </div>
</section>
</ng-container>
