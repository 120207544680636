import { Component, OnInit } from '@angular/core';
import { listStagger } from '../animations';
import { ConfigSettings } from '../config/config.settings';
import { ToastrService } from 'ngx-toastr';
import { User } from '../models/user';
import { AuthenticationService } from '../config/authentication.service';
import { CryptService } from '../config/crypt.service';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { CartService } from '../config/cart.service';
import { first } from 'rxjs/operators';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { UserService } from '../config/user.service';
import { FooterService } from '../config/footer.service';
// import { MatDialog } from '@angular/material';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../dialog/dialog.component';
// import { Password } from '../models/password';


@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss'],
  animations: [ listStagger ]
})
export class MyAccountComponent implements OnInit {
  user:User;
  // password:Password;
  newPassword:string=''
  currentPassword:string=''
  confirmPassword:string=''
  // password: {
  //   old_password: string,
  //   password: string,
  //   confirm_password: string,
  // } = {
  //   old_password: '',
  //   password: '',
  //   confirm_password: '',
  // };
  userDetails:any;
  skeletonLoader:boolean=true;
  counter = Array;
  referralCode:any;
  message:string;
  hasError = false;
  submitted=false;
  allow_password_change = false;
  dataAvailable = false;
  user2:any
  constructor(
    private configSettings:ConfigSettings,
    private toastr:ToastrService,
    private authenticationService:AuthenticationService,
    private cryptService:CryptService,
    private localStorage:LocalStorage,
    private cartService:CartService,
    private userService: UserService,
    private footerService:FooterService,
    private dialog: MatDialog,

  ) { 
    
  }
  

  ngOnInit() {
    this.userService.getUserSession().then(response => {
      if (response !== false) {
        this.user = response;
        if (!this.user.is_social_register) {
          this.allow_password_change = true;
        }
        this.dataAvailable = true;
        setTimeout(() => { this.footerService.toggleFooter(true); }, 300);
      }
    });
    this.referralCode = this.configSettings.getisReferralCode();
    this.userDetails = this.configSettings.getLogInUserData();
    setTimeout(() => {
      this.skeletonLoader = false;
    }, 1500);
    // this.doUpdateProfile()
  }
  //  @param updateProfileForm
  // editProfile = new FormGroup({
  //   userNameParam: new FormControl('', Validators.compose([Validators.required]) ),
  //   userLastName: new FormControl('', Validators.compose([Validators.required])),
  //   nameEmail: new FormControl('', Validators.compose([Validators.required])),
  //   password: new FormControl(''),
  //   NewPassword: new FormControl(''),
  //   confirmpassword: new FormControl(''),
  //   phone: new FormControl('', [Validators.required,Validators.pattern("^[0-9]{8,10}$"),Validators.minLength(8), Validators.maxLength(10)]),
    
  // });
  onSubmit(updateProfileForm: NgForm) {
    // console.log(this.user.first_name);
    this.configSettings.toggleLoading(true);
    if(this.newPassword==this.confirmPassword){
      this.doChangePassword()
    }
    
    this.doUpdateProfile();
    updateProfileForm.controls['newPassword'].reset()
    updateProfileForm.controls['currentPassword'].reset()
    updateProfileForm.controls['confirmPassword'].reset()
    // updateProfileForm.reset();
}
 
  doUpdateProfile(){
  
    try {
      let dob = this.addDays( this.user.dob,1)
      this.configSettings.toggleLoading(true);
      const params = {
        user_id: this.user.id,
        first_name: this.user.first_name,
        last_name:this.user.last_name,
        dob: dob,
        
        // confirm_password:this.password?.confirm_password
        
        
      };  
      console.log(params);  
    

      if(this.newPassword==this.confirmPassword){
      this.authenticationService.editProfile(params)
            .pipe(first())
            .subscribe(
                response => {
                    this.message = response.message;
                    if (response.status === 200) {
                      this.hasError = false;
                      this.cryptService.doEncrypt(JSON.stringify(response.data)).then(encryptedString => {
                        this.localStorage.setItem('userInfo', encryptedString).subscribe(() => {
                            this.cartService.uploadOfflineCart(response.data.id).then(() => {});
                            // this.user2=this.userDetails(response.data)
                        });
                    });
                    // this.password.controls['password'].reset();
                    // this.editProfile.controls['NewPassword'].reset();
                    // this.editProfile.controls['confirmpassword'].reset();
                    } else {
                      this.hasError = true;
                    }
                    this.submitted = true;
                    this.configSettings.toggleLoading(false);
                }, error => { this.configSettings.toggleLoading(false); }
            );
              }
            }
            
     catch (e) {
      this.configSettings.toggleLoading(false);
      console.log(e);
    }
  
}
addDays(date, days) {
   
  // Function to add Days
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}
  copyCode(){
    navigator.clipboard.writeText(document.getElementById('ref').innerHTML);
    // alert('copied to Clipboard');
    this.toastr.success('copied to clipboard');
    
  }
  doChangePassword() {
    try {
      this.configSettings.toggleLoading(true);
      const params = {
        user_id: this.user.id,
        old_password: this.currentPassword,
        new_password: this.newPassword
      };
      this.authenticationService.changePassword(params)
            .pipe(first())
            .subscribe(
                response => {
                    this.message = response.message;
                    if (response.status === 200) {
                      this.hasError = false;
                    } else {
                      this.hasError = true;
                      if(response.status === 201){
                        this.message = 'Current password did not match';
                        return
                      }
                    }
                    this.submitted = true;
                    this.configSettings.toggleLoading(false);
                }, error => { this.configSettings.toggleLoading(false); }
            );
    } catch (e) {
      this.configSettings.toggleLoading(false);
      console.log(e);
    }
  }

  showLogoutModal() {
    let dialogRef = this.dialog.open(DialogComponent, {
        data: {logout: true, user: this.user },
  });
}

scrollToTop() {
  window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
  });
}
 
}
