import { Directive, ElementRef, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[appSetActiveAlphabets]'
})
export class SetActiveAlphabetsDirective implements OnInit, OnChanges {
  @Input() words: string[] = [];

  constructor(private el: ElementRef) {}

  ngOnInit() {
    this.applyDirective();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.words) {
      this.applyDirective();
    }
  }

  private applyDirective() {
    setTimeout(() => {
      let alphabets = this.el.nativeElement.children[0].innerHTML;      
      if (this.words.includes(alphabets) || alphabets == 'All') {
        this.el.nativeElement.classList.add('letter-with-value');
      } else {
        this.el.nativeElement.classList.remove('letter-with-value');
      }
    }, 1000);
  }


}
