<div class="my_account_data" *ngIf="skeletonLoader">
    <div class="container-fluid">
        <section class="user-account main-profile" id="my-profile">
            <div class="account_heading pt-3 pb-5">
                <ul class="d-flex flex-wrap align-items-center justify-content-center w-100 listing-page-routing">
                    <li class="routing-list w-25">
                        <ngx-skeleton-loader></ngx-skeleton-loader>
                    </li>
                </ul>
            </div>
            <div class="pb-5">
                <div class="d-flex w-100 justify-content-center w-100">
                    <div class="user-left-menu d-none d-lg-block w-25">
                        <div class="w-50">
                            <ngx-skeleton-loader count="8"></ngx-skeleton-loader>
                        </div>
                    </div>
                    <div class="white-background user-profile-content w-100 w-lg-75">
                        <div class="breadcrumb_heading">
                            <h3 class="w-25">
                                <ngx-skeleton-loader></ngx-skeleton-loader>
                            </h3>
                        </div>
                        <div class="">
                            <div class="">
                                <div class="redeem1 ">
                                    <h4 class="credit-balance-row d-flex gap-2 align-items-center w-100">
                                        <ngx-skeleton-loader class="w-50"></ngx-skeleton-loader>
                                    </h4>
                                    <ul class="nav nav-tabs wallet-credit-tabs" id="walletTab" role="tablist">
                                        <li class="nav-item btn-skeleton items-height-skeleton">
                                            <ngx-skeleton-loader class="w-50 height-fix"></ngx-skeleton-loader>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="mt-4">
                            <div class="">
                                <div class="tab-content" id="walletTabContent">
                                    <div id="menu1" class="tab-pane fade show active" [attr.role]="'tabpanel'"
                                        [attr.aria-labelledby]="'menu1-tab'">
                                        <div class="container px-0 px-md-3 py-5">
                                            <div class="w-25">
                                                <ngx-skeleton-loader></ngx-skeleton-loader>
                                            </div>
                                            <div class="row">
                                                <div class="col-12">
                                                    <table class="table table-hover transaction-table">
                                                        <tr class="gap-4">
                                                            <th class="w-25 table-skeleton"
                                                                *ngFor="let item of [1,2,3]">
                                                                <ngx-skeleton-loader></ngx-skeleton-loader>
                                                            </th>
                                                        </tr>
                                                        <ng-container>
                                                            <tr *ngFor="let transaction of [1,2,3]">
                                                                <td class="redeem table-skeleton">
                                                                    <ngx-skeleton-loader></ngx-skeleton-loader>
                                                                </td>
                                                                <td class="grey table-skeleton">
                                                                    <ngx-skeleton-loader></ngx-skeleton-loader>
                                                                </td>
                                                                <td class="amount_price table-skeleton"
                                                                    class="{{ (transaction.transaction_type === 'C') ? '' : ''}}">
                                                                    <ngx-skeleton-loader></ngx-skeleton-loader>
                                                                </td>
                                                            </tr>
                                                        </ng-container>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div id="menu2" class="tab-pane fade" [attr.role]="'tabpanel'" *ngIf="popup"
                                        [attr.aria-labelledby]="'menu2-tab'">
                                        <div class="container px-0 px-md-3 py-5">
                                            <h5 class="tab-content-title">{{'Redeem voucher code below:' | translate}}
                                            </h5>
                                            <form [formGroup]="redeemVoucherForm"
                                                (ngSubmit)="onRedeemVoucherSubmit($event)">
                                                <div class="form-group required">
                                                    <div [hidden]="!submitted">
                                                        <p class="col-12 d-block text-center "
                                                            [ngClass]="[hasError ? 'alert alert-danger' : 'alert alert-success']">
                                                            {{message}}
                                                        </p>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-12 col-md-4">
                                                            <label for="redeemVoucherCode"
                                                                class="control-label">{{'Please
                                                                enter your voucher code
                                                                here:' | translate}}</label>
                                                        </div>
                                                        <div class="col-12 col-md-8">
                                                            <input type="text" class="form-control text-input"
                                                                name="voucherCode" formControlName="voucherCode"
                                                                id="redeemVoucherCode"
                                                                aria-describedby="redeemVoucherCodeHelp" required>
                                                            <small id="redeemVoucherCodeHelp"
                                                                class="form-text form-validator_error"
                                                                *ngIf="redeemVoucherForm.controls['voucherCode'].touched && redeemVoucherForm.controls['voucherCode'].hasError('required')">
                                                                {{'Please enter voucher code' | translate}}
                                                            </small>
                                                        </div>
                                                    </div>
                                                </div>

                                                <button type="submit" class="btn btn-black btn-submit"
                                                    [ngClass]="[(lang === 'en') ? 'float-right' : 'float-left']"
                                                    [disabled]="!redeemVoucherForm.valid">
                                                    {{'REDEEM VOUCHER' | translate}}
                                                </button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>
<ng-container *ngIf="dataAvailable && !skeletonLoader">

    <div class="my_account_data">
        <div class="container-fluid">

            <section class="user-account main-profile" id="my-profile" @listStagger>

                <div class="account_heading pt-3 pb-5">
                    <ul class="d-flex flex-wrap align-items-center justify-content-center w-100 listing-page-routing">
                        <li class="routing-list">
                            <a [routerLink]="'/my-account'">
                                {{ "my account" | translate }}
                            </a>
                        </li>
                        <li class="routing-list">{{ "Store Credit" | translate }}</li>
                    </ul>
                </div>
        
        
        
                        <div class="pb-5">
                            <div class="d-flex w-100 justify-content-center w-100">
                                <div class="user-left-menu d-none d-lg-block w-25">
                                    <app-user-account-menu [page]="6"></app-user-account-menu>
                                </div>
                                <div class="white-background user-profile-content w-100 w-lg-75">

                                    <div class="breadcrumb_heading">
                                        <h3><a [routerLink]="'/my-account'"> <img src="/assets/icons/right-arrow.svg" alt="arrow"></a> {{'Store Credit' | translate}}</h3>
                                    </div>

                                    <ng-container *ngIf="paymentRespose.Result">

                                        <ng-container *ngIf="paymentRespose.Result === 'CAPTURED'">
                                            <h5 class="text-success">{{'THANK YOU, YOUR PAYMENT WAS SUCCESSFUL!' | translate}}
                                            </h5>
                                            <p class="content-description page-subtitle font-regular">
                                                {{"You will receive an e-mail shortly with full details of your purchase. Hope you enjoyed the shopping experience with Wishlist. For any queries please email us on support_email" | translate:{'support_email':'support@furni-shop.com'} }}
                                            </p>
                                        </ng-container>
        
                                        <ng-container *ngIf="paymentRespose.Result !== 'CAPTURED'">
                                            <h5 class="text-danger">{{'THERE WAS AN ERROR PROCESSING YOUR PAYMENT!' | translate}}</h5>
                                        </ng-container>

                                        <div class="row">
                                            <div class="col-12 col-md-6">
                                                <table class="table table-bordered table-striped payment-table">
                                                    <tr>
                                                        <th>{{'Result' | translate}}</th>
                                                        <td>{{paymentRespose.Result}}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>{{'Auth' | translate}}</th>
                                                        <td>{{paymentRespose.Auth}}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>{{'Payment ID' | translate}}</th>
                                                        <td>{{paymentRespose.PaymentID}}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>{{'Reference Id' | translate}}</th>
                                                        <td>{{paymentRespose.Ref}}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>{{'Track ID' | translate}}</th>
                                                        <td>{{paymentRespose.TrackID}}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>{{'Transaction ID' | translate}}</th>
                                                        <td>{{paymentRespose.TranID}}</td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                        <hr>
                                    </ng-container>
        
                                    <div class="">
                                        <div class="">

                                            <div class="redeem1">
                                                <h4 class="credit-balance-row d-flex gap-2 align-items-center">{{'Credit' | translate}}: <span
                                                        class="credit-balance"> {{currency}} {{user.wallet_amount | number:'1.3-3'}}</span></h4>
                                                <!-- <p>{{'Top up your account credit with money and spend it when placing orders. Select Wishlist Credit payment option in checkout screen.' | translate}}</p> -->
                                                <ul class="nav nav-tabs wallet-credit-tabs" id="walletTab" role="tablist">
                                                    <li class="nav-item" data-toggle="modal" data-target="#myModal">
                                                        <a class="nav-link active button_hover" href="#menu1" id="menu1-tab"
                                                            [attr.data-toggle]="'tab'" [attr.role]="'tab'"
                                                            [attr.aria-controls]="'menu1'" [attr.aria-selected]="true"
                                                            (click)="getWalletTransactions()">
                                                            {{'REDEEM' | translate}}
                                                            <span class="relative right-[20px]">+</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
        
                                    <div class="mt-4">
                                        <div class="">
        
                                            <div class="tab-content" id="walletTabContent" >
                                                <div id="menu1" class="tab-pane fade show active" [attr.role]="'tabpanel'"
                                                    [attr.aria-labelledby]="'menu1-tab'">
                                                    <div class="container px-0 px-md-3 py-5" *ngIf="walletTransactions.length > 0">
                                                        <h5 class="tab-content-title">{{'TRANSACTIONS' | translate}}</h5>
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <table class="table table-hover transaction-table">
                                                                    <tr>
                                                                        <th>{{'ACTION' |translate}}</th>
                                                                        <th>{{'DATE'|translate}}</th>
                                                                        <th>{{'AMOUNT'|translate}}</th>
                                                                    </tr>
                                                                    <ng-container *ngIf="walletTransactions.length > 0">
                                                                        <tr *ngFor="let transaction of walletTransactions">
                                                                            <td class="redeem">{{transaction.type}}</td>
                                                                            <td class="grey">{{(transaction.created_at)}}</td>
                                                                            <td class="amount_price" class="{{ (transaction.transaction_type === 'C') ? '' : ''}}"> {{(transaction.transaction_type === 'C')? '+' : '-'}} {{currency}} {{transaction.amount | number:'1.3-3'}}</td>
                                                                        </tr>
                                                                    </ng-container>
                                                                    <ng-container *ngIf="walletTransactions.length == 0">
                                                                        <tr class="text-center">
                                                                            <td colspan="3">{{'No data found' | translate}}</td>
                                                                        </tr>
                                                                    </ng-container>
                                                                   
                                                                    <!-- <tr>
                                                                        <td class="redeem">Redeemed In Order #103939303</td>
                                                                        <td class="grey">July 15th 2023</td>
                                                                        <td class="font"><span class="plus">+</span>KD 150</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td class="redeem">Redeemed Voucher</td>
                                                                        <td class="grey">July 19th 2023</td>
                                                                        <td class="font"><span class="plus">+</span>KD 30</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td class="redeem">Redeemed In Order #103939244</td>
                                                                        <td class="grey">June 25th 2023</td>
                                                                        <td class="font"><span class="minus">-</span>KD 30</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td class="redeem">Redeemed Voucher</td>
                                                                        <td class="grey">June 19th 2023</td>
                                                                        <td class="font"><span class="plus">+</span>KD 30</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td class="redeem">Redeemed Voucher</td>
                                                                        <td class="grey">June 20th 2023</td>
                                                                        <td class="font"><span class="plus">+</span>KD 30</td>
                                                                    </tr> -->
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <div id="menu2" class="tab-pane fade" [attr.role]="'tabpanel'" *ngIf="popup"
                                                    [attr.aria-labelledby]="'menu2-tab'">
                                                    <div class="container px-0 px-md-3 py-5">
                                                        <h5 class="tab-content-title">{{'Redeem voucher code below:' | translate}}</h5>
                                                        <form [formGroup]="redeemVoucherForm"
                                                            (ngSubmit)="onRedeemVoucherSubmit($event)">
                                                            <div class="form-group required">
                                                                <div [hidden]="!submitted">
                                                                    <p class="col-12 d-block text-center "
                                                                        [ngClass]="[hasError ? 'alert alert-danger' : 'alert alert-success']">
                                                                        {{message}}
                                                                    </p>
                                                                </div>
                                                                <div class="row">
                                                                    <div class="col-12 col-md-4">
                                                                        <label for="redeemVoucherCode"
                                                                            class="control-label">{{'Please enter your voucher code here:' | translate}}</label>
                                                                    </div>
                                                                    <div class="col-12 col-md-8">
                                                                        <input type="text" class="form-control text-input"
                                                                            name="voucherCode" formControlName="voucherCode"
                                                                            id="redeemVoucherCode"
                                                                            aria-describedby="redeemVoucherCodeHelp" required>
                                                                        <small id="redeemVoucherCodeHelp"
                                                                            class="form-text form-validator_error"
                                                                            *ngIf="redeemVoucherForm.controls['voucherCode'].touched && redeemVoucherForm.controls['voucherCode'].hasError('required')">
                                                                            {{'Please enter voucher code' | translate}}
                                                                        </small>
                                                                    </div>
                                                                </div>
                                                            </div>
        
                                                            <button type="submit" class="btn btn-black btn-submit"
                                                                [ngClass]="[(lang === 'en') ? 'float-right' : 'float-left']"
                                                                [disabled]="!redeemVoucherForm.valid">
                                                                {{'REDEEM VOUCHER' | translate}}
                                                            </button>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
        
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>


              
            </section>

        </div>
    </div>

   <div *ngIf="popup">
    <div id="myModal" class="modal fade" role="dialog" >
        <div class="modal-dialog modal-dialog-centered">

            <!-- Modal content-->
            <div class="modal-content model-width">
                <div class="modal-header">

                    <h4 class="modal-title">{{'Redeem voucher' | translate}}</h4>
                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                </div>
                <div class="modal-body" >
                    <form [formGroup]="redeemVoucherForm">
                        <div [hidden]="!submitted">
                            <p class="col-12 d-block text-center "
                                [ngClass]="[hasError ? 'alert alert-danger' : 'alert alert-success']">
                                {{message}}
                            </p>
                        </div>
                        <label for="" class="block">{{'Please enter your voucher code here:' | translate}}</label>
                        <input type="text" class="input" formControlName="voucherCode">
                        <small id="redeemVoucherCodeHelp" class="form-text form-validator_error"
                            *ngIf="redeemVoucherForm.controls['voucherCode'].touched && redeemVoucherForm.controls['voucherCode'].hasError('required')">
                            {{'Please enter voucher code' | translate}}
                        </small>
                    </form>
                </div>

                <div class="button">
                    <button class="btn button_hover" [disabled]="!redeemVoucherForm.valid" 
                        (click)="onRedeemVoucherSubmit($event)">{{'REDEEM VOUCHER' | translate}}</button>
                </div>
                <!-- <div class="modal-footer">
              <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
            </div> -->
            </div>

        </div>
    </div>
    </div>
</ng-container>


<!-- <ng-container *ngIf="dataAvailable">
    <section class="user-account main-profile" id="my-profile" @listStagger>

        <section class="container">
            <div class="row m-sm-0 justify-content-center">

                <div class="col-12 col-sm-12 col-md-9 p-md-3 white-background user-profile-content">
                    <ng-container *ngIf="paymentRespose.Result">
                        <ng-container *ngIf="paymentRespose.Result === 'CAPTURED'">
                            <h5 class="text-success">{{'THANK YOU, YOUR PAYMENT WAS SUCCESSFUL!' | translate}}</h5>
                            <p class="content-description page-subtitle font-regular">
                                {{"You will receive an e-mail shortly with full details of your purchase. Hope you enjoyed the shopping experience with Wishglist. For any queries please email us on support_email" | translate:{'support_email':'support@furni-shop.com'} }}
                            </p>
                        </ng-container>

                        <ng-container *ngIf="paymentRespose.Result !== 'CAPTURED'">
                            <h5 class="text-danger">{{'THERE WAS AN ERROR PROCESSING YOUR PAYMENT!' | translate}}</h5>
                        </ng-container>
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <table class="table table-bordered table-striped payment-table">
                                    <tr>
                                        <th>{{'Result' | translate}}</th>
                                        <td>{{paymentRespose.Result}}</td>
                                    </tr>
                                    <tr>
                                        <th>{{'Auth' | translate}}</th>
                                        <td>{{paymentRespose.Auth}}</td>
                                    </tr>
                                    <tr>
                                        <th>{{'Payment ID' | translate}}</th>
                                        <td>{{paymentRespose.PaymentID}}</td>
                                    </tr>
                                    <tr>
                                        <th>{{'Reference Id' | translate}}</th>
                                        <td>{{paymentRespose.Ref}}</td>
                                    </tr>
                                    <tr>
                                        <th>{{'Track ID' | translate}}</th>
                                        <td>{{paymentRespose.TrackID}}</td>
                                    </tr>
                                    <tr>
                                        <th>{{'Transaction ID' | translate}}</th>
                                        <td>{{paymentRespose.TranID}}</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <hr>
                    </ng-container>

                    <div class="row m-0 mb-3 p-0 font-small font-regular">
                        <div class="col-12 px-0 px-md-3">
                            <h4 class="credit-balance-row">{{'Available Balance' | translate}} <span
                                    class="credit-balance">{{currency}} {{user.wallet_amount | number:'1.3-3'}}</span>
                            </h4>
                            <p>{{'Top up your account credit with money and spend it when placing orders. Select Wishlist Credit payment option in checkout screen.' | translate}}</p>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">

                            <ul class="nav nav-tabs wallet-credit-tabs" id="walletTab" role="tablist">
                                <li class="nav-item">
                                    <a class="nav-link active" href="#menu1"
                                       id="menu1-tab" [attr.data-toggle]="'tab'" [attr.role]="'tab'" [attr.aria-controls]="'menu1'" [attr.aria-selected]="true"
                                       (click)="getWalletTransactions()">
                                        {{'VIEW STATEMENT' | translate}}
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" data-toggle="tab" href="#menu2"
                                       id="menu2-tab" [attr.data-toggle]="'tab'" [attr.role]="'tab'" [attr.aria-controls]="'menu2'" [attr.aria-selected]="false">
                                        {{'REDEEM CREDIT CODE' | translate}}
                                    </a>
                                </li>
                            </ul>

                            <div class="tab-content" id="walletTabContent">
                                <div id="menu1" class="tab-pane fade show active" [attr.role]="'tabpanel'" [attr.aria-labelledby]="'menu1-tab'">
                                    <div class="container px-0 px-md-3 py-5">
                                        <h5 class="tab-content-title">{{'Wishlist Credit statement history' | translate}}</h5>
                                        <div class="row">
                                            <div class="col-12">
                                                <table class="table table-hover transaction-table">
                                                   <thead>
                                                   <tr class="text-center">
                                                       <th>{{'Date' | translate}}</th>
                                                       <th>{{'Time' | translate}}</th>
                                                       <th>{{'Amount' | translate}}</th>
                                                   </tr>
                                                   </thead>
                                                    <tbody>
                                                    <ng-container *ngIf="walletTransactions.length > 0">
                                                        <tr *ngFor="let transaction of walletTransactions">
                                                            <td>
                                                                <div class="transaction-type">{{transaction.type}}</div>
                                                                <div class="text-secondary">{{(transaction.created_at)}}</div>
                                                            </td>
                                                            <td class="{{ (transaction.transaction_type === 'C') ? 'text-success' : 'text-danger'}}" [class.text-right]="lang === 'en'" [class.text-left]="lang === 'ar'" >
                                                                {{(transaction.transaction_type === 'C')? '+' : '-'}} {{currency}} {{transaction.amount | number:'1.3-3'}}
                                                            </td>
                                                        </tr>
                                                    </ng-container>
                                                    <ng-container *ngIf="walletTransactions.length == 0">
                                                        <tr class="text-center">
                                                            <td colspan="3">{{'No data found' | translate}}</td>
                                                        </tr>
                                                    </ng-container>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="menu2" class="tab-pane fade"
                                     [attr.role]="'tabpanel'" [attr.aria-labelledby]="'menu2-tab'">
                                    <div class="container px-0 px-md-3 py-5">
                                        <h5 class="tab-content-title">{{'Redeem voucher code below:' | translate}}</h5>
                                        <form [formGroup]="redeemVoucherForm" (ngSubmit)="onRedeemVoucherSubmit($event)">
                                            <div class="form-group required">
                                                <div [hidden]="!submitted">
                                                    <p class="col-12 d-block text-center " [ngClass]="[hasError ? 'alert alert-danger' : 'alert alert-success']">
                                                        {{message}}
                                                    </p>
                                                </div>
                                                <div class="row">
                                                    <div class="col-12 col-md-4">
                                                        <label for="redeemVoucherCode"
                                                               class="control-label">{{'Please enter your voucher code here:' | translate}}</label>
                                                    </div>
                                                    <div class="col-12 col-md-8">
                                                        <input type="text" class="form-control text-input"
                                                               name="voucherCode" formControlName="voucherCode"
                                                               id="redeemVoucherCode"
                                                               aria-describedby="redeemVoucherCodeHelp"
                                                               required>
                                                        <small id="redeemVoucherCodeHelp"
                                                               class="form-text form-validator_error"
                                                               *ngIf="redeemVoucherForm.controls['voucherCode'].touched && redeemVoucherForm.controls['voucherCode'].hasError('required')">
                                                            {{'Please enter voucher code' | translate}}
                                                        </small>
                                                    </div>
                                                </div>
                                            </div>

                                            <button type="submit" class="btn btn-black btn-submit" [ngClass]="[(lang === 'en') ? 'float-right' : 'float-left']"
                                                    [disabled]="!redeemVoucherForm.valid">
                                                {{'REDEEM VOUCHER' | translate}}
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    </section>
</ng-container> -->
