<div class="container home-container desktop-container" id="home-container">
    <div class="top140">
        <img src="{{home_banner}}" alt="" class="" width="100%">
    </div>
    <div class="collection-group" [ngStyle]="getCollectionGroupStyle(group)" *ngFor="let group of collectionGroups">
        <section class="home-content-section flex-column">
            <div class="ALSO-home home-collection-group-header white-container mt-0 {{(lang == 'ar') ? 'text-right' : ''}}">
                <h2 class="title font-black font-regular no-span mt-3" *ngIf="group.hide_title === 0">{{group.title}}</h2>
                <span class="viewAll" *ngIf="(group.group_type === 'C')" [routerLink]="['/category', group.title, group.group_type_id]">{{'View all' | translate}}</span>
                <span class="viewAll" *ngIf="(group.group_type === 'N')" [routerLink]="'/shop/new-arrivals'">{{'View all' | translate}}</span>
                <span class="viewAll" *ngIf="(group.group_type === 'B')" [routerLink]="['/brand', group.title, group.group_type_id]">{{'View all' | translate}}</span>
                <span class="viewAll" *ngIf="(group.group_type === 'S')" [routerLink]="['/shop', group.title, group.group_type_id]">{{'View all' | translate}}</span>
                <span class="viewAll" *ngIf="(group.group_type === 'F')" [routerLink]="['/sale', group.group_type_id]">{{'View all' | translate}}</span>
                <p class="winter" *ngIf="group.hide_sub_title === 0">{{group.subtitle}}</p>
            </div>

        
            <!-- Brands Starts -->
            <ng-container *ngIf="group.group_type === 'T'">
                <div class="products white-container">
                    <ngx-slick-carousel class="carousel"   #slickModal="slick-carousel"  [config]="slideConfigNoArrow">
                        <div ngxSlickItem *ngFor="let item of group.collection_list" class="slide mx-2">
                            <app-product-item
                                    [lang]="lang"
                                    [item]="item"
                                    [currency]="currency"
                                    [store]="store"
                                    [userId]="user.id"
                                    [returnUrl]="'home'"
                                    [minimal]="false">
                            </app-product-item>
                        </div>
                    </ngx-slick-carousel>
                </div>
            </ng-container>
            <!-- Brands Ends -->

    
            <!-- New Arrival Starts -->
            <ng-container *ngIf="group.group_type === 'N'">
                <div class="products white-container">
                    <ngx-slick-carousel class="carousel"   #slickModal="slick-carousel"  [config]="slideConfig">
                        <div ngxSlickItem *ngFor="let item of group.collection_list" class="slide mx-2">
                            <app-prodct-item-v2
                                    [lang]="lang"
                                    [item]="item"
                                    [mainItem]="item"
                                    [currency]="currency"
                                    [store]="store"
                                    [userId]="user.id"
                                    [returnUrl]="'home'"
                                    [backdropHeight]="79"
                                    [minimal]="false">
                            </app-prodct-item-v2>
                        </div>
                    </ngx-slick-carousel>
                </div>
            </ng-container>
            <!-- New Arrival Ends -->

            <!-- Brands Starts -->
            <ng-container *ngIf="group.group_type === 'B'">
                <div class="products white-container">
                    <ngx-slick-carousel class="carousel"   #slickModal="slick-carousel"  [config]="slideConfig">
                        <div ngxSlickItem *ngFor="let item of group.collection_list" class="slide mx-2">
                            <app-prodct-item-v2
                                    [lang]="lang"
                                    [item]="item"
                                    [mainItem]="item"
                                    [currency]="currency"
                                    [store]="store"
                                    [userId]="user.id"
                                    [returnUrl]="'home'"
                                    [backdropHeight]="79"
                                    [minimal]="false">
                            </app-prodct-item-v2>
                        </div>
                    </ngx-slick-carousel>
                </div>
            </ng-container>
            <!-- Brands Ends -->

            <!-- Brands Starts -->

            
            <ng-container *ngIf="group.group_type === 'C' || group.group_type === 'F'">
                <div class="products white-container">
                    <ngx-slick-carousel class="carousel"   #slickModal="slick-carousel"  [config]="slideConfig">
                        <div ngxSlickItem *ngFor="let item of group.collection_list" class="slide mx-2">
                            
                            <app-product-item
                                    [lang]="lang"
                                    [item]="item"
                                    [currency]="currency"
                                    [store]="store"
                                    [userId]="user.id"
                                    [returnUrl]="'home'"
                                    [minimal]="false">
                            </app-product-item>
                        </div>
                    </ngx-slick-carousel>
                </div>
            </ng-container>
            <!-- Brands Ends -->

            <!-- Flash Sale Starts -->
            <ng-container *ngIf="group?.group_type === 'G'">
                <div [ngClass]="{'overflow-x-scroll': (group?.collection_list.length > 1)}" [style]="{width: offsetWidth}" class="HOME-Banner">
                    <div class="" [ngClass]="{'d-inline-block': (group?.collection_list.length > 1)}" *ngFor="let collection of group?.collection_list; let i = index">
                        <a [routerLink]="getCollectionRouterLink(collection)" [queryParams]="getCollectionQueryParams(collection, group)" *ngIf="collection.type != 'T'  && (collection.image != '')">
                            <img [src]="collection.image" [ngStyle]="getImageStyle(collection, group, i)" class="max100 HOME-BannerH" [width]="getScaleSize(group?.image_width)" [height]="getScaleSize(group?.image_height)">
                        </a>
                        <a data-toggle="modal" data-target="#timeline" (click)="startLoadingTimelineFromZero(collection)" *ngIf="collection.type == 'T'  && (collection.image != '')">
                          <img [src]="collection.image" [ngStyle]="getImageStyle(collection, group, i)" class="max100 HOME-BannerH" [width]="getScaleSize(group?.image_width)" [height]="getScaleSize(group?.image_height)">
                        </a>           
                        <h3 class="collection-list-tile text-center" *ngIf="!((group.hide_collection_title == 1) && (group.hide_collection_sub_title == 1))">
                            <span *ngIf="(group.hide_collection_title != 1)">
                                {{collection.title}}
                            </span>
                            <span *ngIf="(group.hide_collection_title == 1)">
                                &nbsp;
                            </span>
                        </h3>
                        <p class="collection-list-subtitle text-center" *ngIf="!((group.hide_collection_title == 1) && (group.hide_collection_sub_title == 1))">
                            <span *ngIf="(group.hide_collection_sub_title != 1)">
                                {{collection.sub_title}}
                            </span>
                            <span *ngIf="!(group.hide_collection_sub_title == 1)">
                                &nbsp;
                            </span>
                        </p>
                        <button type="button" class="SHOPNOW" *ngIf="collection.button_text" [routerLink]="collection.link">
                            {{collection.button_text}}
                        </button>
                    </div>
                </div>
            </ng-container>
            <!-- Flash Sale Ends -->


           

        </section>

    </div>

    <!-- Button trigger modal -->
    <button type="button" class="d-none" data-toggle="modal" data-target="#advertisement" #advertisement>
    </button>


  
  <!-- Modal -->
  <div class="modal fade" id="advertisement" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-body" [style.background]="'url(' + landingAds?.image + ')'">
          <button type="button" class="close flashButton" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true" (click)="closed()"><img src="../../assets/images/100.png" alt="" class="crossIcon"></span>
          </button>
          <p class="para"></p>
          <div class="row adButtonsGroup">
                <button type="button" class="btn btn-lg col-5 adButtons" data-dismiss="modal" aria-label="Close" [routerLink]="landingAdsUrl(landingAds)">
                Enter
              </button>
              <span class="col-2">

              </span>
              <button type="button" class="btn btn-lg col-5 adButtons" data-dismiss="modal" aria-label="Close" (click)="closed()">
                Skip
              </button>
          </div>
        </div>
      </div>
    </div>
  </div>


  <!-- Modal -->
    <div class="modal fade modal-background" id="timeline" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <span aria-hidden="true"><img src="../../assets/images/100.png" alt="" data-dismiss="modal" aria-label="Close" id="storiesCLoseButton2" (click)="modalClose()" class="crossIcon"></span>
        <button type="button" class="close flashButton" style="border:0px; background:none;">
            <span aria-hidden="true" style="display: none;"><img src="../../assets/images/100.png" alt="" data-dismiss="modal" aria-label="Close" id="storiesCLoseButton2" (click)="modalClose()" class="crossIcon"></span>
        </button>
        <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
            
            <div class="modal-content" style="background: none;">
                
                <div class="modal-body">
                
                  <div>

                    <div class="slider" *ngIf="allStories">
                        
                        <input type="radio" name="testimonial" value="0" id="t-1" checked/>
                        <input type="radio" name="testimonial" value="{{i}}" id="t-{{i+2}}" *ngFor="let story of allStories | slice:1; let i = index;"/>


                            <div class="myProgressNew" [style.width]="(30/allStories.length)+'%'" *ngFor="let story of allStories; let i = index;">
                                <div class="myBarNew" id="myBar" #myBar [style.width]="((selectedCheckbox > i)?100:((selectedCheckbox == i)?width:0))+'%'"></div>
                            </div>
                        

                        <div class="testimonials mb-8">
                            
                            

                            <button  class="Left mobile-arrow" (click)="(lang == 'ar')?rightMove():leftMove()"><img src="assets/images/left.svg"></button>
                            <button  class="Right mobile-arrow" (click)="(lang == 'ar')?leftMove():rightMove()"><img src="assets/images/right.svg"></button>

                          <label class="item" for="t-1">
                            <div class="mycard">
                              <div id="image00" #image00>
                                <img src="{{allStories[0]?.image}}" alt="nivel5" *ngIf="(selectedCheckbox == 0)" class="cardimg" (mousedown)="timeLinePressed()"  (mouseup)="timeLineReleased()"/>
                                <img src="{{allStories[0]?.image}}" alt="nivel5" *ngIf="(selectedCheckbox != 0)" class="cardimg" (click)="timeLineClicked(-1, allStories)" id="#img00" #img00 />
                              </div>
                            </div>
                          </label>
                          <label class="item" for="t-{{i+2}}" *ngFor="let story of allStories | slice:1; let i = index;">
                            <div class="mycard">
                              <div id="image{{i}}" #image{{i}}>
                                <img src="{{story.image}}" alt="nivel5" *ngIf="(selectedCheckbox == (i+1))" class="cardimg" (mousedown)="timeLinePressed()" (mouseup)="timeLineReleased()"/>
                                <img src="{{story.image}}" alt="nivel5" *ngIf="(selectedCheckbox != (i+1))" class="cardimg" (click)="timeLineClicked(i, allStories)" (keypress)="timeLinePressed($event)"/>
                              </div>
                            </div>
                          </label>
                        </div>
                        
                        <div class="SHOP-BUTTON">
                            <button type="button" (click)="toggleSidenav1('open')">{{'Shop The Look' | translate}}</button>
                        </div>
                        <div class="dots">
                          <label for="t-1"></label>
                          <label for="t-{{i+2}}" *ngFor="let story of allStories | slice:1; let i = index;"></label>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
        </div>
    </div>
  

</div>

<div id="mySidenav1" class="sidenav1" #mySidenav1 [ngClass]="myHeight">
    
    <div  class="container">
        <div class="Shop-heading">
            <h2>{{'SHOP THE LOOK' | translate}}</h2>
        </div>
        <a href="javascript:void(0)"  class="closebtn closebtn-Shop" id="closeShopTheLook" #closeShopTheLook>
            <img src="../../assets/images/100.png" alt="" class="crossIcon">
        </a>
        <ngx-slick-carousel class="carousel"   #slickModal="slick-carousel"  [config]="slideConfig" class="DESKTOP">
            <div ngxSlickItem *ngFor="let item of shopTheLookItems" class="slide mx-2">
                <app-prodct-item-v2 [storyItems]="shopTheLookItems" [mainItem]="item" (openCart)="openCart($event)" (triggerTimelineModel)="closeStory($event)" [backdropHeight]="95">

                </app-prodct-item-v2>
            </div>
            <div *ngIf="shopTheLookItems == undefined || shopTheLookItems == '' || shopTheLookItems == 'undefined'">
                <div class="empty-cart">
                    <div class="empty-cart-content white-container">
                        <img src="../../assets/ArtWork/emptybox.png" alt="" class="emptyCart">
                        <h4 class="page-title font-bold text-center">{{'Currently no products available' | translate}}</h4>
                        <div class="text-center">
                        </div>
                    </div>
                </div>
            </div>
        </ngx-slick-carousel>
        <div class="row MOBILE">
            <div class="col-md-3" *ngFor="let item of shopTheLookItems">
                <app-mobile-shop-the-look [storyItems]="shopTheLookItems" [backdropHeight]="79" [mainItem]="item" (openCart)="openCart()" (triggerTimelineModel)="closeStory($event)">

                </app-mobile-shop-the-look>
            </div>
        </div>
      
    </div>
  </div>