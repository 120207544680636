import { Directive, ElementRef, EventEmitter, HostListener, Input, Output, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appClickToFav]',
})
export class ClickToFavDirective {
  private renderer: Renderer2;
  constructor(private el: ElementRef) {}

  @HostListener('click', ['$event']) onClick(e: any) {
    var index = e.target.src.lastIndexOf('/');
    var first = e.target.src.substr(0, index);
    var last = e.target.src.substr(index + 1);

    if (last == 'wishlist.svg') {
      e.target.src = 'assets/icons/heart-filled.svg';
    }
    if (last == 'heart-filled.svg') {
      e.target.src = 'assets/icons/wishlist.svg';
    }
  }
}