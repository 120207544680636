<footer @listStagger>

    <div class="footer">

        <div class="email_preferences">
            <div class="container-fluid">
                <div class="footer-padding">
                    <div class="d-flex align-items-center w-100">

                        <div class="footer_email">
                            <div class="d-flex align-items-start gap-3 w-100">
                                <div class="w-100">
                                    <form #emailForm="ngForm" (ngSubmit)="onSubmit(emailForm)">
                                    <div class="d-flex footer_email_border">
                                        <input type="email" placeholder="{{'Sign up to our emails' | translate}}"
                                            [(ngModel)]="email" name="email" #spy id="inputEmail"
                                            aria-describedby="emailHelp" #nameEmail="ngModel" required email
                                            (ngModelChange)="onChange($event)">
                                        <button class="button_hover" type="submit" [disabled]="!validEmail">{{'SIGN UP' |
                                            translate}}</button>
                                    </div>
                                    <small class="text-success {{(lang == 'ar') ? 'd-block text-right': ''}}"
                                        *ngIf="mailchimpSubmitted">
                                        {{'Thank you for subscribing with us!' | translate}}
                                    </small>
                                    <small id="emailHelp" class="form-text form-validator_error"
                                        *ngIf="!mailchimpSubmitted && (nameEmail.touched && !nameEmail.valid)">
                                        {{'Please enter your email address' | translate}}
                                    </small>
                                    </form>
                                </div>
                                <div class="w-100 d-none email-pad d-md-block">
                                    <p>{{'Email Preferences' | translate}}</p>
                                </div>
                            </div>
                        </div>

                        <div class="footer_social d-none d-md-block">
                            <div class="d-flex gap-2 align-items-center justify-content-end">
                                <a href="{{facebookLink}}" target="_blank"><img src="/assets/icons/facebook.svg"
                                        alt="Facebook"></a>
                                <a href="{{instagramLink}}" target="_blank"><img src="/assets/icons/instagram.svg"
                                        alt="Instagram"></a>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <div class="footer_data">
            <div class="container-fluid">
                <div class="footer-padding">

                    <div class="pt-3 d-none d-md-block">
                        <div class="d-flex justify-content-between">

                        <!-- <div class="help_wishlist"> -->
                            
                            <div class="heading">
                                <h4>{{'WISHLIST' | translate}}</h4>
                                <ul>
                                    <li><a [routerLink]="'/about-us'">{{'About Us' | translate}}</a></li>
                                    <li><a [routerLink]="['/affiliates']">{{'Affiliates' | translate}}</a></li>
                                    <!-- <li><a [routerLink]="'/contact-us'">{{'Contact Us' | translate}}</a></li> -->
                                </ul>
                            </div>


                            <div class="heading">
                                <h4>{{'Help' | translate}}</h4>
                                <ul>
                                    <!-- <li><a [routerLink]="'/faq'">{{'FAQS' | translate}}</a></li> -->
                                    <li><a [routerLink]="'/terms-and-conditions'">{{'Terms & Conditions' |
                                            translate}}</a></li>
                                    <li><a [routerLink]="'/privacy-policy'">{{'Privacy Policy' | translate}}</a>
                                    </li>
                                    <li><a [routerLink]="'/cookie-policy'">{{'Cookie Policy' | translate}}</a></li>
                                    <li><a [routerLink]="['/modern-slavery-act']">{{'Modern Slavery Act' | translate}}</a></li>
                                </ul>
                            </div>

                        <!-- </div> -->



                            <div class="heading">
                                <h4>{{'Legal' | translate}}</h4>
                                <ul>
                                    <li><a [routerLink]="'/faq'">{{'FAQS' | translate}}</a></li>
                                    <li><a [routerLink]="'/contact-us'">{{'Contact Us' | translate}}</a></li>
                                    <li><a [routerLink]="['/shipping-information']">{{'Delivery' | translate}}</a></li>
                                    <li><a [routerLink]="['/return-policy']">{{'Returns' | translate}}</a></li>
                                </ul>
                            </div>


                            <div class="heading">
                                <h4>{{'Visit Us' | translate}}</h4>
                                <ul>
                                    <li><a [routerLink]="'/headquarters'">{{'Headquarters' | translate}}</a></li>
                                </ul>
                            </div>


                            <div class="heading">
                                <h4>{{'Our Apps' | translate}}</h4>
                                <ul>
                                    <li><a href="https://apps.apple.com/us/app/the-wish-list-offical/id1462076096?ls=1"
                                            target="_blank">{{'Download our iOS app' | translate}}<br class="pb-1"><img class=""
                                                src="/assets/icons/App_Store_Badge.svg" alt="App Icon"></a></li>
                                    <li class="mt-2"><a
                                            href="https://play.google.com/store/apps/details?id=com.leza.wishlist"
                                            target="_blank">{{'Download our Android app' | translate}}<br><img class=""
                                                src="/assets/icons/google-app.svg" alt="App Icon"></a></li>
                                </ul>
                            </div>


                        </div>
                    </div>

                    <div class="pt-3 d-block d-md-none">
                        <div class="accordion" id="accordionExample">
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingOne">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                        {{'WISHLIST' | translate}}
                                    </button>
                                </h2>
                                <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne"
                                    data-bs-parent="#accordionExample">
                                    <div class="px-3 pb-2">
                                        <ul>
                                            <li><a [routerLink]="'/about-us'">{{'About Us' | translate}}</a></li>
                                            <li><a href="">{{'Affiliates' | translate}}</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingTwo">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        {{'Help' | translate}}
                                    </button>
                                </h2>
                                <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                                    data-bs-parent="#accordionExample">
                                    <div class="px-3 pb-2">
                                        <ul>
                                            <li><a [routerLink]="'/terms-and-conditions'">{{'Terms & Conditions' |
                                                    translate}}</a></li>
                                            <li><a [routerLink]="'/privacy-policy'">{{'Privacy Policy' | translate}}</a>
                                            </li>
                                            <li><a href="">{{'Cookie Policy' | translate}}</a></li>
                                            <li><a href="">{{'Modern Slavery Act' | translate}}</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingThree">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseThree" aria-expanded="false"
                                        aria-controls="collapseThree">
                                        {{'Legal' | translate}}
                                    </button>
                                </h2>
                                <div id="collapseThree" class="accordion-collapse collapse"
                                    aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                    <div class="px-3 pb-2">
                                        <ul>
                                            <li><a [routerLink]="'/faq'">{{'FAQS' | translate}}</a></li>
                                            <li><a [routerLink]="'/contact-us'">{{'Contact Us' | translate}}</a></li>
                                            <li><a href="">{{'Delivery' | translate}}</a></li>
                                            <li><a href="">{{'Returns' | translate}}</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingFour">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseFour" aria-expanded="false"
                                        aria-controls="collapseFour">
                                        {{'Visit Us' | translate}}
                                    </button>
                                </h2>
                                <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour"
                                    data-bs-parent="#accordionExample">
                                    <div class="px-3 pb-2">
                                        <ul>
                                            <li><a href="">{{'Headquarters' | translate}}</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>


                    <div class="pt-3 d-block d-md-none">
                        <div class="w-100">
                            <div class="heading">
                                <h4 class="text-center">{{'Our Apps' | translate}}</h4>
                                <ul class="d-flex justify-content-center w-100">
                                    <li><a href="https://apps.apple.com/us/app/the-wish-list-offical/id1462076096?ls=1"
                                            target="_blank"><img src="/assets/icons/App_Store_Badge.svg"
                                                alt="App Icon"></a></li>
                                    <li><a href="https://play.google.com/store/apps/details?id=com.leza.wishlist"
                                            target="_blank"><img src="/assets/icons/google-app.svg" alt="App Icon"></a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div class="footer_social footer_social_icon mt-3">
                            <div class="d-flex gap-2 align-items-center justify-content-center">
                                <a href="{{facebookLink}}" target="_blank"><img src="/assets/icons/facebook.svg"
                                        alt="Facebook"></a>
                                <a href="{{instagramLink}}" target="_blank"><img src="/assets/icons/instagram.svg"
                                        alt="Instagram"></a>
                            </div>
                        </div>

                    </div>

                    <div>
                        <div class="footer-shoping">
                            <div class="d-sm-block d-md-flex footer_div_gap">
                                <div>
                                    <div class="d-flex gap-2 align-items-center">
                                        <div class="footer_shipping">
                                            {{'Shipping from' | translate}}
                                        </div>
                                        <div class="footer_shipping_city">
                                            {{'Kuwait' | translate}}
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div class="d-flex gap-2 align-items-center ">
                                        <div class="footer_shipping">
                                            {{'Customer Services' | translate}}
                                        </div>
                                        <div class="footer_shipping_city d-flex gap-2 gap-md-3">
                                            <a href="tel:{{contactNo}}"> t: {{contactNo}}</a> 
                                            <!-- <a href="tel:{{contactNo}}"> t: {{contactNo}}</a> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div class="footer_copyright">
                            <p>&#169; {{'Copyright 2023 The Wishlist' | translate}}</p>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    </div>

</footer>