import { Component, Input } from '@angular/core';
import { environment } from 'src/environments/environment';

declare var branch: any;

@Component({
  selector: 'app-share-popup',
  templateUrl: './share-popup.component.html',
  styleUrls: ['./share-popup.component.scss']
})
export class SharePopupComponent {
  hideShare:boolean=false;
  @Input() data: any;
  @Input() type: any;
  liveKey = environment?.branchIoLive
  devKey =  environment?.branchIoTest
  urlHost = window.location.hostname;
  branchKey: string='';
  branchIoLink: any;
  constructor() { 
    if (this.urlHost.includes('staging') || this.urlHost.includes('web') || this.urlHost.includes('localhost')) {
      this.branchKey = this.devKey
    } else {
      this.branchKey = this.liveKey
    }
  }
  ngOnInit() {
    this.registerBranch();
    branch.init(this.branchKey, function (err, data) { // key_test_ndzZ2665S95j2JJpWDtCAhikurjUHvrz
    });
  }


  share() { 
    this.hideShare=!this.hideShare;
    this.generateDeepLink();
  }
  //generate deep link for ServiceDetails like doctor ,hospital,pharmacy etc..
  //to generate deep link for for product details...
  generateDeepLink(): void {
    var options = { no_journeys: true };
    branch.init(this.branchKey, function (err, data) {
    });  
    var linkData = {
      campaign: 'content 123',
      channel: 'facebook',
      feature: 'share',
      stage: 'new user',
      tags: ['tag1', 'tag2', 'tag3'],
      alias: '',
      data: {
        'custom_bool': true,
        'custom_int': Date.now(),
        'custom_string': 'hello',
        '$og_title': this.data.name,
        '$og_description': this.data.description,
        '$og_image_url': this.data.image,       
        'target': 'P',
        'target_id': String(this.data.id),
        'secondaryID': this.data.brand_id,
        'title':this.data.name
      }
    };
    branch.link(linkData, (err, link: any) => {
      this.branchIoLink = new String(link);      
    });
  }

  registerBranch() {
    this.registerBranchSource(window, document, "script", "branch", function (b, r) { b[r] = function () { b._q.push([r, arguments]); }; }, { _q: [], _v: 1 }, "addListener applyCode autoAppIndex banner closeBanner closeJourney creditHistory credits data deepview deepviewCta first getCode init link logout redeem referrals removeListener sendSMS setBranchViewData setIdentity track validateCode trackCommerceEvent logEvent disableTracking".split(" "), 0);
  }

  registerBranchSource(b, r, a, n, c, h, _, s) {
    if (!b[n] || !b[n]._q) {
      for (; s < _.length;)
        c(h, _[s++]);
      let d = r.createElement(a);
      d.async = 1;
      d.src = "https://cdn.branch.io/branch-latest.min.js";
      let k = r.getElementsByTagName(a)[0];
      k.parentNode.insertBefore(d, k);
      b[n] = h;
    }
  }
}
