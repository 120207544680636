import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NavbarService } from '../config/navbar.service';

@Injectable({
  providedIn: 'root'
})
export class HeaderToggleService {

  constructor(
    private navbarService:NavbarService
  ) { }


  private navItems = new BehaviorSubject<any>(false)

  setNavItems(data: any) {
    this.navItems.next(data);
    //to manage sub category tabs in mobile...
    if(data==false){
      this.navbarService.isEnableHeaderBottomMobileAction(false);
    }
  }

  getNavItems() {
    return this.navItems.asObservable()
  }

  category = new BehaviorSubject<any>([])

  setCategories(data: any) {
    this.category.next(data)    
  }
  getCategories() {
    return this.category.asObservable()
  }

}
