<div class="account_heading pt-4">
  <ul class="d-flex flex-wrap align-items-center justify-content-center w-100 listing-page-routing">
    <li class="routing-list">
      <a>
        <!-- {{ "Boutique’s/Brands" | translate }} -->
        {{ "Brands" | translate }}
      </a>
    </li>
  </ul>
</div>

<!--  
<section  class="main-profile" id="brand-list">
  <div class="container-fluid">
    <div class="mx-auto px-0 mt-5" *ngIf="skeletonLoader">
      <ngx-skeleton-loader count="3"></ngx-skeleton-loader>
    </div>


    <div class="designer-name-group" *ngIf="skeletonLoader">
      <div class="designer-sec" >
        <div class="d-flex image-box-designer" *ngFor="let item of [1,2,3,4,5,6,7,8,9,10,11,12]">
          <div class="designer-image">
            <ngx-skeleton-loader class="skeleton-image" count="" [theme]="setThemeLogic"></ngx-skeleton-loader>
            <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
          </div>
          <p></p>
        </div>
      
      </div>
    </div>


    <div class="brand-box"  *ngIf="skeletonLoader || type == 'B'">
      <div *ngFor="let item of [1,2,3,4,5,6,7,8,9,10,11,12]"  class="brand-name-group mb-2">
        <div class="brand-name-letter">
          <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
        </div>

        <div class="brand-company-name ">
          <div *ngFor="let item of [1,2,3,4,5,6,7,8,9,10,11,12]">
            <div class="add-to-fav-section" >
              <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
           </div>
          </div>
        </div>
      </div>
    </div>
    
  </div>
</section> -->

<section *ngIf="dataAvailable && !skeletonLoader" class="main-profile" id="brand-list" @listStagger>
  <div class="container-fluid"  *ngIf="brands.length > 0">
    <div class="mx-auto px-0">
      <ul class="d-flex justify-content-center tab-main  align-items-center nav nav-tabs" 
        role="tablist">
        <!-- <li class=" tabs nav-item" (click)="brandType('D')" [ngClass]="{'active': type == 'D'}">
          <a class="px-4 py-2 tab-item" id="home-tab-designer"  
             >{{'BOUTIQUE’S' | translate}}</a>
        </li> -->
        <li class=" tabs nav-item" (click)="brandType('B')" [ngClass]="{'active': type == 'B'}">
          <a class=" px-4 py-2 tab-item brand-item" id="profile-tab"  
            >{{'BRANDS' | translate}}</a>
        </li>
      </ul>
      <ul class="d-flex justify-content-center tab-main-mid align-items-center  nav nav-tabs mt-2 mb-2" >
        <li class=" tabs nav-item" (click)="subCategory(1)" >
          <a class="px-2 py-2 tab-item" *ngIf="brands.length"  id="home-tab-alphabet" [ngClass]="{'active': subCategoryType == 1}"  >
            <span class="d-none d-md-block">
              {{'Find By A-Z' | translate}}
            </span>
            <span class="d-block d-md-none">
              {{'A-Z' | translate}}
            </span>
          </a>
        </li>
        <li class=" tabs nav-item" (click)="subCategory(2)" id="categoryTab" >
          <a class="px-2 py-2 tab-item"   aria-controls="brand" [ngClass]="{'active': subCategoryType == 2}"
            >
            <span class="d-none d-md-block">
              {{'Filter by Category' | translate}}
            </span>
            <span class="d-block d-md-none">
              {{'Category' | translate}}
            </span>
          </a>
        </li>
        <li class=" tabs nav-item" (click)="subCategory(3)" >
          <a class="px-2 py-2 tab-item"   aria-controls="brand" [ngClass]="{'active': subCategoryType == 3}"
            >
            <!-- <span class="d-none d-md-block" *ngIf="type == 'D'">
              {{"Search for a Boutique’s" | translate }}
            </span> -->
            <span class="d-none d-md-block" *ngIf="type == 'B'">
              {{'Search for a Brand' | translate}}
            </span>
            <span class="d-block d-md-none">
              {{'Search' | translate}}
            </span>
          </a>
        </li>
      </ul>

      <!-- search by alphabets section -->
      <ul class="d-flex flex-wrap align-items-center justify-content-center brand-alphabet "
        *ngIf="subCategoryType == 1">
        <li class="col all-text">
          <div [ngClass]="{'letter-active': selectedAlphabet == 'All'}" appSetActiveAlphabets (click)="removeHighlights()"
            class="text-centerpy-1 py-md-2 brand-letter all">
            <a>{{'All' | translate}}</a>
          </div>
        </li>
        <!-- Alphabets -->
        <li *ngFor="let alphabet of englishAlphabetArr" class="col">
          <div [ngClass]="{'letter-active': selectedAlphabet==alphabet.toLowerCase()}" appSetActiveAlphabets
            [words]="alphabetFirstChars" class="text-center py-1 py-md-2 brand-letter"
            (click)="scrollTo(alphabet.toLowerCase());">
            <a>{{alphabet}}</a>
          </div>
        </li>

        <li *ngIf="numberFirstChars.length > 0" [ngClass]="{'hidden': toggleAlphabet}">
          <div class="text-center py-1 py-md-2 brand-letter brand-number" (click)="changeChar()">
            <a>0-9</a>
          </div>
        </li>
        <!-- Numbers -->
        <li *ngFor="let number of numbersCount(9) ; let i = index" class="col" [ngClass]="{'hidden': !toggleAlphabet }">
          <div [ngClass]="{'letter-active': alpha==i}" appSetActiveAlphabets [words]="numberFirstChars"
            class="text-center py-1 py-md-2 brand-letter" (click)="scrollTo($event, i); ">
            <a>{{i}}</a>
          </div>
          <div class="text-center py-1 py-md-2 brand-letter" *ngIf="">
          </div>
        </li>
        <li *ngIf="alphabetFirstChars.length > 0" [ngClass]="{'hidden': !toggleAlphabet}">
          <div class="text-center py-1 py-md-2 brand-letter brand-number" (click)="changeChar()">
            <a>A-Z</a>
          </div>
        </li>
      </ul>
      <!-- search by alphabets section -->

      <!-- search by categories section -->
      <div>
        <ul class="d-flex flex-wrap align-items-center justify-content-between justify-content-md-center categries-alphabet"
          *ngIf="subCategoryType == 2">
          <li class="" (click)="searchByCategory(category)">
            <div [ngClass]="{'letter-active': selectedCategory?.name == 'All'}"
              class="text-center tabs-color-categries py-2 cursor-pointer ">
              <a (click)="clearCategories()">{{'All'|translate}}</a>
            </div>
          </li>
          <li class="" *ngFor="let category of categories" (click)="searchByCategory(category)">
            <div [ngClass]="{'letter-active': selectedCategory?.name == category.name}"
              class="text-center tabs-color-categries py-2 cursor-pointer ">
              <a> {{category.name}}</a>
            </div>
          </li>
        </ul>
      </div>
      <!-- search by categories section -->

      <!-- search by brand name section -->
      <div class="d-flex flex-wrap align-items-center justify-content-start justify-content-md-center search-box search-brand gap-2 py-2 position-relative"
        *ngIf="subCategoryType == 3">
        <img src="../../assets/icons/new-search-icon.svg" alt="" class="search-icon">
        <input type="text" [placeholder]="'Search by Brand'|translate" type="text" [(ngModel)]="name"
          (input)="searchByBrandName(name)">
        <span class="position-absolute close-search" (click)="clearSearch()">
          <img src="../../assets/icons/cross_popup.svg" alt="">
        </span>
      </div>
      <!-- search by brand name section -->

    </div>
  </div>
  <div class="brand-company-name searchGrid brand-box container-fluid" *ngIf="!toSearch">
    <div *ngFor="let brand of searchData" class="mt-4">
      <p class="brand-name" (click)="routeAsPerTier(brand)">{{brand.name}}</p>
      <!-- {{brand.tier_id}},{{brand.tier_name}} -->
    </div>
  </div>
  <!-- {{groups | json}}, {{brands?.length | json}} {{brands | json}} -->
  <div class="tab-content" id="myTabContent" *ngIf="toSearch">
    <div class="tab-pane " id="brand" [ngClass]="{'d-block': type == 'B'}">
      <section class="container-fluid brand-group-main" *ngIf="brands.length">
        <div class="brand-box">
          <div *ngFor="let alphabet of groups" id="{{alphabet.toLowerCase()}}" class="brand-name-group">
            <div class="brand-name-letter">
              <a name="{{alphabet}}">{{alphabet}}</a>
            </div>

            <!-- don't remove the class 'brand-list-name' it is used in typeScript -->
            <div class="brand-company-name ">
              <div *ngFor="let brand of brands"
                [ngClass]="{'hidden': brand?.name?.charAt(0).toLowerCase() != alphabet.toLowerCase()}">
                <div class="add-to-fav-section" >
                  <img *ngIf="!user?.id" routerLink="/login" src="/assets/icons/add-to-fav-start.svg" alt="fav-star">
                  <img [appClickToFavBrandsListing]="brand.is_in_wishlist" (click)="toggleBrandAsFavorite(brand.id)" src="assets/icons/fav-brand-star{{brand.is_in_wishlist ? '-filled' : ''}}.svg" alt="fav-star" *ngIf="user?.id">
                  <a [routerLink]="url? url : brand.tier_id == 1 ? ['/home',brand.name, brand.id, brand.tier_id] : ['/brands',brand.name, brand.id, brand.tier_id]" target="_blank" class="brand-name brand-list-name" [id]="'list-name'+alphabet.toLowerCase()" (click)="routeAsPerTier($event, brand)">{{brand.name | titlecase }}</a>
               </div>
                <!-- {{brand.tier_id}},{{brand.tier_name}} -->
              </div>
            </div>
          </div>
        </div>



        <!-- <div class="col-12 col-md-4">
        <ul *ngFor="let alphabet of groups | slice:divider:divider*2;" id="{{alphabet.toLowerCase()}}"
          class="brand-group mb-2">
          <li class="">
            <h2 class="font-xxx-large font-bold mb-0"><a name="{{alphabet}}">{{alphabet}}</a></h2>
          </li>
          <ng-container *ngFor="let brand of brands">
            <li *ngIf="brand.name.charAt(0).toLowerCase() === alphabet.toLowerCase()" class="font-bold font-small">
              <a [routerLink]="['/brands/', configSettings.cleanUrl(brand.name), brand.id]">{{brand.name}}</a>
            </li>
          </ng-container>
        </ul>
      </div>
      <div class="col-12 col-md-4">
        <ul *ngFor="let alphabet of groups | slice:divider*2:divider*3;" id="{{alphabet.toLowerCase()}}"
          class="brand-group mb-2">
          <li class="">
            <h2 class="font-xxx-large font-bold mb-0"><a name="{{alphabet}}">{{alphabet}}</a></h2>
          </li>
          <ng-container *ngFor="let brand of brands">
            <li *ngIf="brand.name.charAt(0).toLowerCase() === alphabet.toLowerCase()" class="font-bold font-small">
              <a [routerLink]="['/brands/', configSettings.cleanUrl(brand.name), brand.id]">{{brand.name}}</a>
            </li>
          </ng-container>
        </ul>
      </div> -->
        <!-- </div> -->
        <!-- <div class="row" *ngIf="isMobile">
      <div class="col-12 col-md-4 ">
        <ul *ngFor="let alphabet of groups;" id="{{alphabet.toLowerCase()}}" class="brand-group mb-2">
          <li class="">
            <h2 class="font-xxx-large mb-0"><a name="{{alphabet}}">{{alphabet}}</a></h2>
          </li>
          <ng-container *ngFor="let brand of brands">
            <li *ngIf="brand.name.charAt(0).toLowerCase() === alphabet.toLowerCase()" class="font-bold font-small">
              <a [routerLink]="['/brands/', configSettings.cleanUrl(brand.name), brand.id]">{{brand.name}}</a>
            </li>
          </ng-container>
        </ul>
      </div>
    </div> -->
      </section>
    </div>
    <div class="tab-pane" id="designer" [ngClass]="{'d-block': type == 'D'}">
      <section class="container-fluid">
        <div class="designer-name-group">
          <div class="designer-sec" id="designerSection" *ngIf="brands?.length > 0">
            <div class="d-flex image-box-designer" *ngFor="let design of brands" [attr.data-letter]="design?.name[0].toLowerCase()" (click)="routeAsPerTier(design)">
              <div class="designer-image">
                <a [href]="generateRouteUrl(design)" (click)="preventDefault($event)">
                  <img [src]="design?.logo" alt="" (mouseover)="updateHref($event, design)">
                </a>
              </div>
              <p>{{design?.name}}</p>
            </div>
          </div>
        </div>
        
      </section>
    </div>
  </div>


</section>


<section  class="main-profile" id="brand-list">
  <div class="container-fluid">
    <div class="mx-auto px-0 mt-5" *ngIf="brands.length == 0 && type == 'B' ">
      <div class="w-25 mx-auto items-height-skeleton">
          <ngx-skeleton-loader class="height-fix"></ngx-skeleton-loader>
      </div>
        <div class="w-50 mx-auto items-height-skeleton">
        <ngx-skeleton-loader class="height-fix"></ngx-skeleton-loader>
      </div>
      <div class="mx-auto items-height-skeleton width-mange-skeletons">
        <ngx-skeleton-loader class="height-fix"></ngx-skeleton-loader>
      </div>
    </div>
    <!-- brand skeleton -->
    <div class="brand-box mt-4"  *ngIf="brands.length == 0 && type == 'B' ">
      <div *ngFor="let item of [1,2,3,4,5,6,7,8,9,10,11,12]"  class="brand-name-group">
        <div class="brand-name-letter">
          <div class="skelton-circle-main">
            <ngx-skeleton-loader class="skelton-circle"  count="1"></ngx-skeleton-loader>
          </div>
        </div>

        <div class="brand-company-name ">
          <div *ngFor="let item of [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15]">
            <div class="add-to-fav-section">
              <ngx-skeleton-loader class="mange-star"  count="1"></ngx-skeleton-loader>
              <ngx-skeleton-loader class="w-50"  count="1"></ngx-skeleton-loader>
           </div>
          </div>
        </div>
      </div>
    </div>
    
  </div>
</section>


<section  class="main-profile" id="brand-list">
  <div class="container-fluid">
    <div class="mx-auto px-0 mt-5" *ngIf="brands.length == 0 && type == 'D' ">
      <div class="w-25 mx-auto items-height-skeleton">
        <ngx-skeleton-loader class="height-fix"></ngx-skeleton-loader>
    </div>
      <div class="w-50 mx-auto items-height-skeleton">
      <ngx-skeleton-loader class="height-fix"></ngx-skeleton-loader>
    </div>
    <div class="mx-auto items-height-skeleton width-mange-skeletons">
      <ngx-skeleton-loader class="height-fix"></ngx-skeleton-loader>
    </div>
    </div>
    <div class="designer-name-group" *ngIf="brands.length == 0 && type == 'D' ">
      <div class="designer-sec" >
        <div class="d-flex image-box-designer" *ngFor="let item of [1,2,3,4,5,6,7,8,9,10,11,12]">
          <div class="designer-image">
            <ngx-skeleton-loader class="skeleton-image" count="" [theme]="setThemeLogic"></ngx-skeleton-loader>
            <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
          </div>
        </div>
      
      </div>
    </div>    
  </div>
</section>




<!-- <ul class="nav nav-tabs" id="myTab" role="tablist">
  <li class="nav-item">
    <a class="nav-link active" id="home-tab"  href="#home"  aria-controls="home" aria-selected="true">Home</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" id="profile-tab"  href="#profile"  aria-controls="profile" aria-selected="false">Profile</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" id="contact-tab"  href="#contact"  aria-controls="contact" aria-selected="false">Contact</a>
  </li>
</ul> -->
<!-- <div class="tab-content" id="myTabContent">
  <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">...</div>
  <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">dvdvsv</div>

</div> -->