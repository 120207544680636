import { Injectable } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AddToCartModalService {

  constructor() { }

  private subject = new BehaviorSubject<any>([]);
  private handleOpenClose = new BehaviorSubject<any>([]);

  sendItem(message: any) {
    this.subject.next(message);
  }

  receivedItem(): Observable<any> {
    return this.subject.asObservable();
  }

  sendItemOpen(message: any) {
    this.handleOpenClose.next(message);
  }

  receivedItemOpen(): Observable<any> {
    return this.handleOpenClose.asObservable();
  }
}
