import { Component, OnInit } from '@angular/core';
import {LocalStorage} from '@ngx-pwa/local-storage';
import {SupportDetails} from '../models/support-details';

@Component({
  selector: 'app-top-help',
  templateUrl: './top-help.component.html',
  styleUrls: ['./top-help.component.css']
})
export class TopHelpComponent implements OnInit {
  support_email = '';
  support_phone = '';

  constructor(
    protected localStorage: LocalStorage
  ) { }

  ngOnInit() {
    this.localStorage.getItem<any>('support_details').subscribe((result) => {
      if (result) {
        const SupportDetails = <SupportDetails> result;
        this.support_email = SupportDetails.support_email;
        this.support_phone = SupportDetails.support_phone;
      }
    });
  }

}
