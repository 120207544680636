import { NgModule } from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ProductDetailComponent} from './product-detail/product-detail.component';
import {ProductListComponent} from './product-list/product-list.component';
import {CartComponent} from "./cart/cart.component";
import {AuthGuard} from "./_guard/auth.guard";
import {CmsComponent} from './cms/cms.component';
import {FaqComponent} from './faq/faq.component';
import {OrderConfirmationComponent} from "./order-confirmation/order-confirmation.component";
import {MyAccountComponent} from "./my-account/my-account.component";
import {ChangePasswordComponent} from "./change-password/change-password.component";
import {MyOrdersComponent} from "./my-orders/my-orders.component";
import {ForgotPasswordComponent} from './forgot-password/forgot-password.component';
import {OrderDetailComponent} from "./order-detail/order-detail.component";
import {MyAddressComponent} from "./my-address/my-address.component";
import {AddressComponent} from "./address/address.component";
import {WishlistComponent} from "./wishlist/wishlist.component";
import {BrandListComponent} from "./brand-list/brand-list.component";
import {DeepLinkComponent} from "./deep-link/deep-link.component";
import {ReturnRequestListComponent} from './return-request-list/return-request-list.component';
import {RequestReturnComponent} from './request-return/request-return.component';
import {ReturnRequestDetailComponent} from './return-request-detail/return-request-detail.component';
import {AccountComponent} from "./account/account.component";
import {ShopListComponent} from './shop-list/shop-list.component';
import {WalletCreditComponent} from "./wallet-credit/wallet-credit.component";
import {ReferAFriendComponent} from "./refer-a-friend/refer-a-friend.component";
import {PaymentV2Component} from "./payment-v2/payment-v2.component";
import { AddressbookComponent } from './addressbook/addressbook.component';
import { HomeV3Component } from './home-v3/home-v3.component';
import { LoginComponent } from './login/login.component';
import { MyDetailsComponent } from './my-details/my-details.component';
import { MyWishlistComponent } from './my-wishlist/my-wishlist.component';
import { LoyaltyPointComponent } from './loyalty-point/loyalty-point.component';
import { MyBrandListComponent } from './my-brand-list/my-brand-list.component';

const routes: Routes = [
    // { path: 'home', component: HomeComponent},
    { 
    path: 'home',
    data: {
      title: 'The Wish List',
      descrption: 'The Wish List is a Kuwait based Ecommerce platform that connects fashion designers to customers. Discover and shop a variety of pieces ranging from clothes, bags, and shoes to accessories and more.',
      ogTitle: 'Home Page',
      keywords: 'The Wish List,ecommerce,Fashion,Outlet,The,Wish,List,TWL,discount,sale,clothes shoes,kids,accessories,namshi,boutiqaat,bazma,nalbes,riva',
      viewport: 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0'
    }, 
    component: HomeV3Component},
    { path: '', redirectTo: '/home', pathMatch: 'full'},
    { path: 'home/:name/:id/:tierId', component: HomeV3Component },
    { path: 'product/detail/:id', component: ProductDetailComponent },
    { path: 'product', component: ProductListComponent },
    // { path: 'shop/featured', component: ProductListComponent },
    { path: 'shop/:type', component: ProductListComponent },
    // { path: 'shop/best-sellers', component: ProductListComponent },
    { path: 'shop/collection/:id', component: ProductListComponent },
    { path: 'category/:name/:id', component: ProductListComponent },
    { path: 'category/:name/:name_2/:id', component: ProductListComponent },
    { path: 'category/:name/:name_2/:name_3/:id', component: ProductListComponent },
    { path: 'category/:name/:name_2/:name_3/:name_4/:id', component: ProductListComponent },
    { path: 'search', component: ProductListComponent },
    { path: 'checkout', component: PaymentV2Component },
    { path: 'cart', component: CartComponent},
    { path: 'addressbook', component: AddressbookComponent },
    { path: 'payment', component: PaymentV2Component, canActivate: [AuthGuard]},
    { path: 'payment-v2', component: PaymentV2Component, canActivate: [AuthGuard]},
    { path: 'order-confirmation', component: OrderConfirmationComponent, canActivate: [AuthGuard]},
    { path: 'about-us', component: CmsComponent, data: {id: 1} },
    { path: 'terms-and-conditions', component: CmsComponent, data: {id: 2} },
    { path: 'cookie-policy', component: CmsComponent, data: {id: 3} },
    { path: 'return-policy', component: CmsComponent, data: {id: 4} },
    { path: 'privacy-policy', component: CmsComponent, data: {id: 5} },
    { path: 'shipping-information', component: CmsComponent, data: {id: 6} },
    { path: 'contact-us', component: CmsComponent, data: {id: 7} },
    { path: 'affiliates', component: CmsComponent, data: {id: 8} },
    { path: 'headquarters', component: CmsComponent, data: {id: 9} },
    { path: 'modern-slavery-act', component: CmsComponent, data: {id: 10} },
    { path: 'faq', component: FaqComponent },
    { path: 'my-account', component: MyAccountComponent, canActivate: [AuthGuard]},
    { path: 'account', component: AccountComponent, canActivate: [AuthGuard]},
    { path: 'my-orders', component: MyOrdersComponent, canActivate: [AuthGuard]},
    { path: 'order/detail/:id', component: OrderDetailComponent, canActivate: [AuthGuard]},
    { path: 'my-address', component: MyAddressComponent, canActivate: [AuthGuard]},
    { path: 'my-details', component: MyDetailsComponent, canActivate: [AuthGuard]},
    { path: 'change-password', component: ChangePasswordComponent, canActivate: [AuthGuard]},
    // { path: 'forgot-password', component: ForgotPasswordComponent},
    { path: 'address/add', component: AddressComponent, canActivate: [AuthGuard]},
    { path: 'address/edit/:id', component: AddressComponent, canActivate: [AuthGuard]},
    { path: 'wishlist', component: WishlistComponent, canActivate: [AuthGuard]},
    { path: 'my-wishlist', component: MyWishlistComponent},
    { path: 'my-brand-list', component: MyBrandListComponent},
    { path: 'brands', component: BrandListComponent},
    { path: 'brand/:name/:id', component: ProductListComponent },
    { path: 'brands/:name/:id', component: ProductListComponent },
    { path: 'brand/:name/:id/:tierId', component: ProductListComponent },
    { path: 'brands/:name/:id/:tierId', component: ProductListComponent },
    { path: 'sale/:id', component: ProductListComponent },
    { path: 'shops', component: ShopListComponent},
    { path: 'shop/:name/:shop_id', component: ProductListComponent },
    { path: 'deep-link', component: DeepLinkComponent },
    { path: 'return-request-list', component: ReturnRequestListComponent, canActivate: [AuthGuard] },
    { path: 'return-request/detail/:id', component: ReturnRequestDetailComponent, canActivate: [AuthGuard]},
    { path: 'request-return/:id', component: RequestReturnComponent, canActivate: [AuthGuard] },
    { path: 'wallet-credit', component: WalletCreditComponent },
    { path: 'refer-a-friend', component: ReferAFriendComponent },
    { path: 'login', component: LoginComponent },
    { path: 'login/:type', component: LoginComponent },
    { path: 'forgot-password', component: ForgotPasswordComponent },
    { path: 'loyalty-point', component: LoyaltyPointComponent }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top'
    })
  ],
  exports: [
      RouterModule
  ]
})
export class AppRoutingModule { }
