<div class="shop-image-card">
    <div class="shop-image-main">
        <!-- <img width="401" height="323" [src]="product.image" alt="" class="shop-image" #imageElement> -->
        <!-- <canvas #canvasElement *ngFor="let tag of product.shop_look_product" (click)="expand(tag)"></canvas> -->
        <canvas #canvasElement></canvas>
    </div>

    <div  class="image-inner-div" *ngIf="(selectedProduct | keyvalue)?.length">
        <div #panelPro class="main-div-hidde" [id]="'tag'+selectedProduct?.id" [style.left.px]="panelX"
            [style.top.px]="panelY">

            
            <div [ngClass]="{'right-shop-product-details': (isMobile && (product.image_width * 2/3 - 170 < panelX)) || (!isMobile && (product.image_width - 170 < panelX)),
                            'bottom-shop-product-details':(isMobile && (product.image_height * 2/3 - 100 < panelY)) || (!isMobile && (product.image_height - 100 < panelY))}"
                 class="shop-product-details" 
                    (click)="routeToDetails(selectedProduct?.id)">
                <div class="image-details">
                    <div class="inener-image">
                        <img [src]="selectedProduct?.image" alt="">
                    </div>
                    <div  class="inner-product-details">
                        <div>
                            <p class="brand-name">{{selectedProduct?.brand_name}}</p>
                            <p class="product-name">{{selectedProduct?.name}}</p>
                        </div>
                        <p class="price">KD {{selectedProduct?.regular_price}}</p>
                    </div>
                </div>
                <div class="shop-product-right">
                    <img src="/assets/icons/right-arrow.svg" alt="" (click)="routeToDetails(selectedProduct?.id)">
                </div>
            </div>
        </div>
    </div>
</div>