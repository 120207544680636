<div class="my_account_data" *ngIf="skeletonLoader">
  <div class="container-fluid">
    <section class="main-profile" id="my-orders">
      <div>
        <div class="account_heading pt-3 pb-5">
          <ul class="d-flex flex-wrap align-items-center justify-content-center w-100 listing-page-routing">
            <li class="routing-list w-25">
              <ngx-skeleton-loader ></ngx-skeleton-loader>
              <!-- <a [routerLink]="'/my-account'">
                {{ "my account" | translate }}
              </a> -->
            </li>
            <!-- <li class="routing-list">
              <a [routerLink]="'/my-orders'">
                {{ "TRACK AN ORDER" | translate }}
              </a>
            </li>
            <li class="routing-list">#{{ order.order_number }}</li> -->
          </ul>
        </div>

        <div class="pb-5">
          <div class="d-flex w-100 justify-content-center w-100">
            <div class="user-left-menu d-none d-lg-block w-25">
              <!-- <app-user-account-menu [page]="2"></app-user-account-menu> -->
              <div class="w-50">
                <ngx-skeleton-loader count="8"></ngx-skeleton-loader>
              </div>

            </div>
            <div class="user-profile-content w-100 w-lg-75">
              <div class="breadcrumb_heading">
                <h3 class="w-25">
                  <!-- <a [routerLink]="'/my-account'"> <img src="/assets/icons/right-arrow.svg" alt="arrow"></a>{{ "Order Details" | translate }} -->
                  <ngx-skeleton-loader  count="1"></ngx-skeleton-loader>
                </h3>
              </div>

              <div >
                <div class="pt-2 pb-4">
                  <div class="">
                    <!-- <ngx-skeleton-loader count="1"></ngx-skeleton-loader> -->
                  </div>
                  <div class="order_prgress"  >
                    <div class="skelton-circle-main" *ngFor="let item of [1,2,3,4]">
                        <ngx-skeleton-loader class="skelton-circle" count="1"></ngx-skeleton-loader>
                    </div>
                  </div>
                </div>

                <div class="order_details_data">
                  <div class="details_data_box">
                    <div class="d-flex gap-4">
                      <div >
                        <div class="shipping_icon skelton-circle-main">
                          <!-- <img src="/assets/icons/Location.svg" alt="Location" /> -->
                        <ngx-skeleton-loader class="skelton-circle" count="1"></ngx-skeleton-loader>                          

                        </div>
                      </div>
                      <div class="w-100">
                        <div class="shipping_address_data">
                          <h2 class="w-25">
                            <!-- {{ "SHIPPING ADDRESS" | translate }} -->
                            <ngx-skeleton-loader></ngx-skeleton-loader>                          
                          </h2>
                          <div  class="address_data">
                            <h4 class="w-25">
                              <!-- {{ order?.shipping_address?.first_name }} {{order?.shipping_address?.last_name}} -->
                              <ngx-skeleton-loader></ngx-skeleton-loader>                 
                            </h4>

                            <p class="d-flex gap-2 w-50">
                              <span class="skelton-address"><ngx-skeleton-loader></ngx-skeleton-loader> </span> 
                              <span class="skelton-address"><ngx-skeleton-loader></ngx-skeleton-loader> </span> 
                            </p>

                            <p class="d-flex gap-2 w-50">
                              <span class="skelton-address"><ngx-skeleton-loader></ngx-skeleton-loader> </span> 
                              <span class="skelton-address"><ngx-skeleton-loader></ngx-skeleton-loader> </span> 
                            </p>
                            <p class="d-flex gap-2 w-50">
                              <span class="skelton-address"><ngx-skeleton-loader></ngx-skeleton-loader> </span> 
                              <span class="skelton-address"><ngx-skeleton-loader></ngx-skeleton-loader> </span> 
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="details_data_box mt-4">
                    <div class="d-flex gap-4">
                      <div>
                        <div class="shipping_icon skelton-circle-main">
                          <ngx-skeleton-loader class="skelton-circle" count="1"></ngx-skeleton-loader>
                        </div>
                      </div>
                      <div class="w-100">
                        <!-- <ngx-skeleton-loader *ngIf="skeletonLoader" [theme]="skeltonTheme"></ngx-skeleton-loader> -->
                        <div class="shipping_address_data" >
                          <h2 class="w-25">
                            <!-- {{ "ORDER DETAILS" | translate }} -->
                            <ngx-skeleton-loader ></ngx-skeleton-loader>
                          </h2>
                          <div class="details_order">
                            <h2 class="w-25">
                              <!-- {{ "ORDER DETAILS" | translate }} -->
                              <ngx-skeleton-loader ></ngx-skeleton-loader>
                            </h2>
                            <p class="w-25">
                              <!-- {{ order.items.length }} -->
                              <ngx-skeleton-loader ></ngx-skeleton-loader>
                              <span >
                              <!-- <ngx-skeleton-loader ></ngx-skeleton-loader> -->
                                <!-- {{ "Item Ordered" | translate }} -->
                              </span>
                            </p>
                          </div>
                          <div class="order_payment w-25">
                            <p>
                              <ngx-skeleton-loader ></ngx-skeleton-loader>
                              <!-- {{'Placed On' | translate}}: -->
                              <!-- <span>{{
                                order.created_date | date : "dd MMM yyyy"
                                }}</span> -->
                            </p>
                            <p>
                              <ngx-skeleton-loader ></ngx-skeleton-loader>
                              <!-- {{'Payment Mode' | translate}}: <span> {{ paymentMethod }}</span> -->
                            </p>
                          </div>

                          <div class="w-100">
                            <div class="details_order_product">
                              <div class="order_product_box " *ngFor="let item of [1,2]">
                                <div class="product_data items-height-skeleton">
                                  <ngx-skeleton-loader class="height-fix w-100"></ngx-skeleton-loader>
                                  <!-- <div>
                                    <div class="product_image">
                                      <figure>
                                        <div class="image-wrapper">
                                          <img src="{{ item.image }}" alt="Product Image" />
                                        </div>
                                      </figure>
                                    </div>
                                  </div> -->
                                  <div class="position-relative w-100">
                                    <div class="details_product">
                                      <h2 class="w-25">
                                        <!-- {{ item.name }} -->
                                        <ngx-skeleton-loader ></ngx-skeleton-loader>
                                      </h2>
                                      <p class="w-25">
                                        <ngx-skeleton-loader ></ngx-skeleton-loader>
                                        <!-- {{'Blazer' | translate}} + {{'Cami Dress-Set' | translate}} -->
                                      </p>
                                      <div class="d-flex gap-2">
                                        <ngx-skeleton-loader class="w-50" ></ngx-skeleton-loader>
                                        <!-- <span *ngFor="
                                              let option of item.configurable_option
                                            ">{{ option.type }}:
                                          {{
                                          option.attributes[0].value
                                          }}</span> -->
                                      </div>
                                    </div>

                                    <div class="details_product_price d-flex justify-content-between align-items-center">
                                     
                                          <div class="d-flex gap-2 align-items-center  w-50">
                                            <!-- <h2 class="price_normal"> -->
                                              <ngx-skeleton-loader class="w-25"></ngx-skeleton-loader>
                                              <ngx-skeleton-loader class="w-50"></ngx-skeleton-loader>
                                              <!-- {{ currency }}
                                              {{
                                              item.final_price
                                              | number : "1.3-3"
                                              }} -->
                                            <!-- </h2> -->
                                          </div>

                                        <div class="w-25">
                                            <ngx-skeleton-loader ></ngx-skeleton-loader>
                                            <!-- x{{ item.quantity }} -->
                                        </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="order_summary">
                            <h2 class="w-25">
                              <!-- {{'Payment Summary' | translate}} -->
                              <ngx-skeleton-loader ></ngx-skeleton-loader>
                            </h2>
                            <span class="d-flex gap-3 align-items-center">
                              <div class="d-flex align-items-center w-100 gap-2">
                                <!-- <div class="shipping_icon skelton-circle-main circle">
                                  <ngx-skeleton-loader class="skelton-circle" count="1"></ngx-skeleton-loader>
                                </div> -->
                                  <ngx-skeleton-loader class="w-25" ></ngx-skeleton-loader>
                               
                                
                              </div>

                              <!-- <ngx-skeleton-loader ></ngx-skeleton-loader> -->
                              <!-- <img src="{{order.paymode_icon}}"
                                alt="" />{{paymentMethod}}-->
                              </span> 
                            <p >
                              <!-- {{'Sub - Total' | translate}}: {{ currency }} {{order.sub_total | number:'1.3-3'}} -->
                              <!-- <ngx-skeleton-loader ></ngx-skeleton-loader> -->
                            </p>
                            <p class="w-25">
                              <ngx-skeleton-loader count="3"></ngx-skeleton-loader> 
                              <!-- {{'Delivery' | translate}}: {{ currency }}
                              {{order.delivery_charges}} -->
                            </p>
                            <p >
                              <!-- {{'Wallet' | translate}}: {{ currency }}
                              {{order.wallet_amount}} -->
                              <!-- <ngx-skeleton-loader ></ngx-skeleton-loader>  -->
                            </p>
                            <p class="discount_order w-25" >
                              <!-- <ngx-skeleton-loader ></ngx-skeleton-loader>  -->

                              <!-- {{'Discount' | translate}}: {{
                              currency }} {{order.discount_price}} -->
                            </p>
                            <h6 class="w-25">
                              <!-- {{'Total' | translate}}: {{ currency }} {{order.total | number:'1.3-3'}} -->
                              <ngx-skeleton-loader ></ngx-skeleton-loader> 
                            </h6>
                          </div>

                          <!-- <div class="request_return_button w-25 justify-content-center text-center align-items-center">
                            <ngx-skeleton-loader ></ngx-skeleton-loader> 
                            <button type="button" (click)="onreturn()" *ngIf="order.is_return_active==1">
                              {{ "REQUEST A RETURN" | translate }}
                            </button>
                          </div> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>
  </div>
</div>
<ng-container *ngIf="dataAvailable && !skeletonLoader">
  <div class="my_account_data">
    <div class="container-fluid">
      <section class="main-profile" id="my-orders" @listStagger>
        <div *ngIf="order">
          <div class="account_heading pt-3 pb-5">
            <ul
              class="d-flex flex-wrap align-items-center justify-content-center w-100 listing-page-routing">
              <li class="routing-list">
                <a [routerLink]="'/my-account'">
                  {{ "my account" | translate }}
                </a>
              </li>
              <li class="routing-list">
                <a [routerLink]="'/my-orders'">
                  {{ "TRACK AN ORDER" | translate }}
                </a>
              </li>
              <li class="routing-list">#{{ order.order_number }}</li>
            </ul>
          </div>

          <div class="pb-5">
            <div class="d-flex w-100 justify-content-center w-100">
              <div class="user-left-menu d-none d-lg-block w-25">
                <app-user-account-menu [page]="2"></app-user-account-menu>
              </div>
              <div class="user-profile-content w-100 w-lg-75">
                <div class="breadcrumb_heading">
                  <h3><a [routerLink]="'/my-account'"> <img src="/assets/icons/right-arrow.svg" alt="arrow"></a>{{ "Order Details" | translate }}</h3>
                </div>

                <div *ngIf="ReadMore">
                  <div class="pt-2 pb-4">
                    <div class="" *ngIf="skeletonLoader">
                      <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
                    </div>
                    <div class="order_prgress" *ngIf="!skeletonLoader">
                      <div>
                        <span class="box active"
                          ><img
                            class="notepad"
                            src="/assets/icons/order_notepad.svg"
                            alt="notepad"
                        /></span>
                        <p>{{'Ordered' | translate}}</p>
                      </div>
                      <div>
                        <div class="line"></div>
                      </div>
                      <div>
                        <span class="box" [ngClass]="order?.status_id >=2 || order?.status_id == 8  ? 'active':''"><img src="/assets/icons/order_box.svg" alt="box" /></span>
                        <p>{{'Packed' | translate}}</p>
                      </div>
                      <div>
                        <div class="line"></div>
                      </div>
                      <div>
                        <span class="box" [ngClass]="order?.status_id > 3  ? 'active':''"><img src="/assets/icons/order_truck.svg" alt="truck" /></span>
                        <p>{{'In Transit' | translate}}</p>
                      </div>
                      <div>
                        <div class="line"></div>
                      </div>
                      <div *ngIf="order?.status_id != 6">
                        <span class="box" [ngClass]="order?.status_id == 5  ?'active':''"><img src="/assets/icons/order_home.svg" alt="home" /></span>
                        <p>{{'Delivered' | translate}}</p>
                      </div>
                      <div *ngIf="order?.status_id == 6">
                        <span class="box" [ngClass]="order?.status_id == 6  ?'active':''"><img src="/assets/icons/cancel-order.svg" alt="home" /></span>
                        <p>{{'Canceled' | translate}}</p>
                      </div>
                    </div>
                  </div>

                  <div class="order_details_data">
                    <div class="details_data_box">
                      <div class="d-flex gap-4">
                        <div *ngIf="!skeletonLoader">
                          <div class="shipping_icon">
                            <img
                              src="/assets/icons/Location.svg"
                              alt="Location"
                            />
                          </div>
                        </div>
                        <div class="w-100">
                          <div class="shipping_address_data">
                            <ngx-skeleton-loader
                              *ngIf="skeletonLoader"
                              [theme]="skeltonTheme"
                            ></ngx-skeleton-loader>
                            <h2 *ngIf="!skeletonLoader">
                              {{ "SHIPPING ADDRESS" | translate }}
                            </h2>
                            <div *ngIf="!skeletonLoader" class="address_data">
                              <h4>{{ order?.shipping_address?.first_name }} {{order?.shipping_address?.last_name}}</h4>
                              
                              <p class="d-flex gap-2">
                                <span *ngIf="order?.shipping_address?.country_name!=''">{{ order.shipping_address?.country_name }},</span> <span *ngIf="order?.shipping_address?.governorate_name!=''">{{ order?.shipping_address?.governorate_name }}</span>                                                              
                              </p>

                              <p class="d-flex gap-2"><span *ngIf="order?.shipping_address?.area_name!=''">{{ order?.shipping_address?.area_name }},</span> <span *ngIf="order?.shipping_address?.block_name!=''">{{ order?.shipping_address?.block_name }}</span></p>
                              <p class="d-flex gap-2"><span class="" *ngIf="order?.shipping_address?.street!=''"> {{ order?.shipping_address?.street }},</span>  <span class="" *ngIf="order?.shipping_address?.avenue!=''"> {{ order?.shipping_address?.avenue }},</span> <span class="" *ngIf="order?.shipping_address?.flat!=''"> {{ order?.shipping_address?.flat }},</span> <span class="" *ngIf="order?.shipping_address?.building!=''">{{ order?.shipping_address?.building }},</span> <span class="" *ngIf="order?.shipping_address?.floor!=''">{{ order?.shipping_address?.floor }}</span> </p>                              

                              <p class="d-flex gap-2"><span class="p-0 " *ngIf="order?.shipping_address?.landmark!=''">{{ order?.shipping_address?.landmark }},</span> <span class="p-0 " *ngIf="order?.shipping_address?.id_number!=''">{{ order?.shipping_address?.id_number }}</span></p>
                              
                              <h6 class="my-2" *ngIf="order?.shipping_address?.notes!=''">{{'Note:' | translate}} {{ order?.shipping_address?.notes }}.</h6>

                              <p class="" *ngIf="order?.shipping_address?.mobile_number!=''">+{{order?.shipping_address?.phonecode}} {{ order?.shipping_address?.mobile_number }}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="details_data_box mt-4">
                      <div class="d-flex gap-4">
                        <div *ngIf="!skeletonLoader">
                          <div class="shipping_icon">
                            <img
                              src="/assets/icons/order-details.png"
                              alt="Location"
                            />
                          </div>
                        </div>
                        <div class="w-100">
                          <ngx-skeleton-loader
                            *ngIf="skeletonLoader"
                            [theme]="skeltonTheme"
                          ></ngx-skeleton-loader>
                          <div
                            class="shipping_address_data"
                            *ngIf="!skeletonLoader"
                          >
                            <h2>{{ "ORDER DETAILS" | translate }}</h2>
                            <div class="details_order">
                              <h2>#{{ order.order_number }}</h2>
                              <p>
                                {{ order.items.length }}
                                <span *ngIf="order.items.length == 1">
                                  {{ "Item Ordered" | translate }}
                                </span>
                                <span *ngIf="order.items.length > 1">
                                  {{ "Items Ordered" | translate }}
                                </span>
                              </p>
                            </div>
                            <div class="order_payment">
                              <p>
                                {{'Placed On' | translate}}:
                                <span>{{
                                  order.created_date | date : "dd MMM yyyy"
                                }}</span>
                              </p>
                              <p>
                                {{'Payment Mode' | translate}}: <span> {{ paymentMethod }}</span>
                              </p>
                            </div>

                            <div class="w-100">
                              <div class="details_order_product">
                                <div
                                  class="order_product_box"
                                  *ngFor="let item of order.items"
                                >
                                  <div class="product_data">
                                    <div>
                                      <div class="product_image">
                                        <figure>
                                          <div class="image-wrapper">
                                            <img
                                              src="{{ item.image }}"
                                              alt="Product Image"
                                            />
                                          </div>
                                        </figure>
                                      </div>
                                    </div>
                                    <div class="position-relative w-100">
                                      <div class="details_product">
                                        <h2>{{ item.name }}</h2>
                                        <p>{{'Blazer' | translate}} + {{'Cami Dress-Set' | translate}}</p>
                                        <div class="d-flex gap-2">
                                          <span
                                            *ngFor="
                                              let option of item.configurable_option">{{ option.type }}:{{ option.attributes[0].value }}</span>
                                        </div>
                                      </div>

                                      <div class="details_product_price">
                                        <div
                                          class="d-flex justify-content-between w-100 align-items-center">
                                          <div>
                                            <div
                                              class="d-flex gap-2 align-items-center"
                                            >
                                              <h2 class="price_normal">
                                                {{ currency }}
                                                {{
                                                  item.final_price
                                                    | number : "1.3-3"
                                                }}
                                              </h2>
                                            </div>
                                          </div>
                                          <div>
                                            <span class="product_total"
                                              >x{{ item.quantity }}</span
                                            >
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div>
                              <div class="data_details">
                                <div class="data_details_box" *ngIf="order.gift_details?.is_gift">
                                  <div class="data_update">
                                    <h2>{{'Gift Details' | translate}}</h2>

                                    <p>
                                      Receiver’s Name: <span>{{order.gift_details.receiver_name}}</span>
                                    </p>
                                    <p>
                                      Receiver’s Phone:
                                      <span>{{order.gift_details.receiver_phone}}</span>
                                    </p>
                                    <p>
                                      {{'Message' | translate}}:
                                      <span>
                                        {{order.gift_details.message}}
                                      </span>
                                    </p>
                                    <p>
                                      {{'Package Sticker' | translate}}:
                                      <span>{{order.gift_details.package_on_sticker}}</span>
                                    </p>
                                    <p>{{'Invoice Hidden' | translate}}: <span>{{order.gift_details?.hide_invoice==0?'No':'Yes'}}</span></p>
                                  </div>
                                </div>

                                <div class="data_details_box" *ngIf="order.payment_mode != 'C' && order.payment_mode != 'W'">
                                  <div class="data_update">
                                    <h2>{{'Transaction Details' | translate}}</h2>

                                    <p>{{'Payment ID' | translate}}: <span>{{order.payment_details.payment_id}}</span></p>
                                    <p>{{'Reference ID' | translate}}: <span> {{order.payment_details.ref}}</span></p>
                                    <p>
                                      {{'Payment Date' | translate}}:
                                      <span> {{order.payment_details.payment_date |  date : "dd MMM yyyy"}} </span>
                                    </p>
                                    <p>{{'Result' | translate}}: <span>{{order.payment_details.result}}</span></p>
                                    <p>{{'Track ID' | translate}}: <span>{{order.payment_details.track_id}}</span></p>
                                    <p>
                                      {{'Transaction Id' | translate}}: <span>{{order.payment_details.transaction_id}}</span>
                                    </p>
                                  </div>
                                </div>

                              </div>
                            </div>

                            <div class="order_summary">
                              <h2>{{'Payment Summary' | translate}}</h2>
                              <span class="d-flex gap-3 align-items-center"
                                ><img
                                  src="{{order.paymode_icon}}"
                                  alt=""
                                />{{paymentMethod}}</span
                              >
                              <p>{{'Sub - Total' | translate}}: {{ currency }} {{order.sub_total | number:'1.3-3'}}</p>
                              <p *ngIf="order.delivery_charges > 0">{{'Delivery' | translate}}: {{ currency }} {{order.delivery_charges | number:'1.3-3'}}</p>
                              <p *ngIf="order.wallet_amount > 0">{{'Wallet' | translate}}: {{ currency }} {{order.wallet_amount | number:'1.3-3'}}</p>
                              <p class="discount_order" *ngIf="order.discount_price > 0">{{'Discount' | translate}}: {{ currency }} {{order.discount_price | number:'1.3-3'}}</p>
                              <h6>{{'Total' | translate}}: {{ currency }} {{order.total | number:'1.3-3'}}</h6>
                            </div>

                            <div class="request_return_button">
                              <button type="button" (click)="onreturn()" *ngIf="order.is_return_active==1">
                                {{ "REQUEST A RETURN" | translate }}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
<!-- this is for the order selection which we want to return  starts-->
                <div *ngIf="visible">
                  <div class="order_details_data" *ngIf="!orderReturned">
                    <div class="details_data_box p-0 m-0" *ngIf="items.length>0">
                      <div class="shipping_address_data">
                        <div class="details_order_product details_order_return" >
                          <div
                            class="order_product_box order_return"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#productReturn"
                            aria-controls="productReturn"
                            *ngFor="let item of items;index as i" (click)="selectItemForReturn(item)">
                            <label  [for]="'html+' + i"  >
                              <div class="product_data" >
                                <div class="d-flex align-items-center">
                                  <input type="radio"  [id]="'html' + i" [name]="'uniqueGroupName'"/>
                                </div>
                                <div>
                                  <div class="product_image">
                                    <figure>
                                      <div class="image-wrapper">
                                        <img
                                          src="{{ item?.image }}"
                                          alt="Product Image"
                                        />
                                      </div>
                                    </figure>
                                  </div>
                                </div>
                                <div class="position-relative w-100">
                                  <div class="details_product">
                                    <h2>{{item?.brand_name}}</h2>
                                    <p>{{ item?.name }}</p>
                                    <!-- <p>{{item?.brand_name}}</p> -->
                                    <div class="pt-1">
                                      <span>{{item?.configurable_option[0]?.attributes[0]?.value}} | {{item?.configurable_option[1]?.attributes[0]?.value}}</span>
                                    </div>
                                  </div>
                                  <div>
                                    <h2 class="price_normal">
                                      {{ currency }}
                                      {{ item.final_price | number : "1.3-3" }}
                                    </h2>
                                  </div>
                                  <div class="details_product_price">
                                    <div class="w-100">
                                      <div>
                                        <span class="product_total"
                                          >x{{ item.quantity }}</span
                                        >
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </label>
                          </div>

                        </div>                     
                      </div>
                    </div>  
                    <div class="details_order_produt details_order_return text-center details-height">
                      <div>
                      <h3>{{'No Items To Return' | translate}}</h3>
                      <p>
                        {{'Your return orderlist is currently empty!' | translate}}
                      </p>
                    </div>
                    </div>              
                  </div>


                  <div class="return_order_confirmation" *ngIf="orderReturned">
                    <div class="return_order_confirmation_data">
                      <img src="/assets/icons/tick-circle-black.svg" alt="">
                      <h2>{{'Request Submitted' | translate }}</h2>
                      <p>{{'Your return request has been submitted successfully' | translate }}</p>
                      <button type="button" [routerLink]="['/return-request-list']">{{'DONE' | translate }}</button>
                    </div>
                  </div>


                </div>
<!-- this is for the order selection which we want to return ends-->
              </div>
            </div>
          </div>
          
        </div>
      </section>
    </div>
  </div>
</ng-container>

<!-- side bar order selection which we want to return starts-->

<div class="offcanvas offcanvas-end" tabindex="-1" id="productReturn" aria-labelledby="offcanvasRightLabel">
  <div class="order_return_data">
    <div class="order_return_product">
      <h4>{{'Return Request Details' | translate}}</h4>

      <div class="address_product_body">

        <div class="product_data">
          <div>
            <div class="product_image">
              <figure>
                <div class="image-wrapper">
                  <img
                    src="{{orderReturn?.image}}"
                    alt="Product Image"
                  />
                </div>
              </figure>
            </div>
          </div>
          <div class="position-relative w-100">
            <div class="details_product">
              <h2>{{orderReturn?.brand_name}}</h2>
               <p>{{ orderReturn?.name }}</p>
              <h3>{{currency}} {{orderReturn?.final_price}}</h3>
              <span>{{orderReturn?.configurable_option[0]?.attributes[0]?.value}} | {{orderReturn?.configurable_option[1]?.attributes[0]?.value}}</span>
            </div>

            <div class="details_product_price">
                <div>
                  <span class="product_total">x {{orderReturn?.quantity}}</span>
                </div>
            </div>
          </div>
        </div>

        <div class="product_qty">
          <div class="d-flex gap-3 align-items-center">
            <div>
              <p>{{'Qty' | translate}}:</p>
            </div>
            <div>
              <div class="d-flex justify-content-between align-items-center justify-content-center gap-3">
                <div>
                  <img src="/assets/icons/minus.svg" alt="" (click)="updateCart(0)">
                </div>
                <div>
                  <span>{{orderReturn?.quantity}}</span>
                </div>
                <div>
                  <img src="/assets/icons/plus.svg" alt="" (click)="updateCart(1)">
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="return_question">
          <h5>{{'Why are you returning this?' | translate}}</h5>

          <div class="returning">
            <label style="cursor: pointer;" (click)="selectReason('dg')">
            <div class="d-flex gap-3 align-items-center" style="cursor: pointer;">
               <input type="radio" name="radio" /><p id="dg">{{'Damaged goods' | translate}}</p>
            </div>
          </label>
          </div>

          <div class="returning">
            <label style="cursor: pointer;" (click)="selectReason('wc')">
            <div class="d-flex gap-3 align-items-center">
              <div> <input type="radio" name="radio" /></div>
              <div>
                <p id="wc">{{'Wrong Color' | translate}}</p>
              </div>
            </div>
          </label>
          </div>

          <div class="returning">
            <label style="cursor: pointer;" (click)="selectReason('ws')">
            <div class="d-flex gap-3 align-items-center">
              <div> <input type="radio" name="radio" /></div>
              <div>
                <p id="ws">{{'Wrong Size' | translate}}</p>
              </div>
            </div>
          </label>
          </div>

          <div class="returning">
            <label  style="cursor: pointer;" (click)="selectReason('nad')">
            <div class="d-flex gap-3 align-items-center">
              <div> <input type="radio" name="radio" /></div>
              <div>
                <p id="nad">{{'Not as described' | translate}}</p>
              </div>
            </div>
          </label>
          </div>

          <div class="returning">
            <label style="cursor: pointer;" (click)="selectReason('cmd')">
            <div class="d-flex gap-3 align-items-center">
              <div> <input type="radio" name="radio" /></div>
              <div>
                <p id="cmd">{{'Changed my mind' | translate}}</p>
              </div>
            </div>
          </label>
          </div>

          <textarea name="" id="txtArea" placeholder="{{'Specify reason for return' | translate }}" [(ngModel)]="textReason" [ngModelOptions]="{standalone: true}"></textarea>
          
          <button type="button" (click)="submitReturn()">{{'SUBMIT' | translate }}</button>
          <button data-bs-dismiss="offcanvas" aria-label="Close" id="closeCanvas" hidden></button>
        </div>

       

      </div>

    </div>
  </div>
</div>
<!-- side bar order selection which we want to return ends-->

<!-- <ng-container *ngIf="dataAvailable">
  <section
    class="main-profile"
    class="{{ lang == 'ar' ? 'text-right' : '' }}"
    id=""
    @listStagger
  >
    <div class="maintitle-Container">
      <h4
        class="page-title text-center font-black font-condensed"
        *ngIf="order"
      >
        {{ "ORDER DETAILS" | translate }}
      </h4>
    </div>

    <div class="container" *ngIf="order">
      <section class="content-section">
        <div
          class="col-12 col-sm-12 col-md-3 p-0 user-left-menu d-none d-md-block"
        >
          <app-user-account-menu [page]="2"></app-user-account-menu>
        </div>
        <div class="col-12 col-sm-12 col-md-9 commonContentWhite">
          <div class="OrderDetails">
            <div class="row">
              <div class="col-12 col-md-7 p-xs-0">
                <div
                  class="col-12 white-container pt-3 pb-3 mb-2 font-regular font-small"
                >
                  <h4 class="font-medium font-medium">
                    {{ "ORDER" | translate }}
                    <span [ngStyle]="{ color: order.status_color }">{{
                      order.status
                    }}</span>
                  </h4>
                  <div class="row pt-2">
                    <div class="col-6">{{ "Order" | translate }}#</div>
                    <div
                      class="col-6 {{
                        lang == 'ar' ? 'text-left' : 'text-right'
                      }}"
                    >
                      {{ order.order_number }}
                    </div>
                  </div>
                  <div class="row pt-1">
                    <div class="col-6">{{ "Placed On" | translate }}</div>
                    <div
                      class="col-6 {{
                        lang == 'ar' ? 'text-left' : 'text-right'
                      }}"
                    >
                      {{ order.created_date | date : "dd MMM yyyy" }}
                    </div>
                  </div>
                  <div class="row pt-1">
                    <div class="col-6">{{ "Payment Method" | translate }}</div>
                    <div
                      class="col-6 {{
                        lang == 'ar' ? 'text-left' : 'text-right'
                      }}"
                    >
                      {{ paymentMethod }}
                    </div>
                  </div>
                </div>
                <div
                  class="col-12 white-container pt-3 pb-3 mb-2 font-regular font-small"
                >
                  <h4 class="font-medium font-medium">
                    {{ "DELIVERY ADDRESS" | translate }}
                  </h4>
                  <div class="row pt-2">
                    <div class="col-12">
                      {{ order.shipping_address.first_name }}<br />
                      {{ order.shipping_address.addressline_1 }},
                      {{ order.shipping_address.street }},
                      {{ order.shipping_address.block_name }}<br />
                      {{ order.shipping_address.area_name }},
                      {{ order.shipping_address.governorate_name }}<br />
                      {{ order.shipping_address.country_name }}<br />
                      {{ "Mobile:" | translate }}
                      <b>{{ order.shipping_address.mobile_number }}</b>
                    </div>
                  </div>
                </div>
                <ng-container *ngIf="order.payment_mode !== 'C'">
                  <div
                    class="col-12 white-container pt-3 pb-3 mb-2 font-regular font-small"
                  >
                    <h4 class="font-medium font-medium">
                      {{ "PAYMENT DETAILS" | translate }}
                    </h4>
                    <div class="row pt-1">
                      <div class="col-4">{{ "Payment ID" | translate }}</div>
                      <div
                        class="col-8 {{
                          lang == 'ar' ? 'text-left' : 'text-right'
                        }}"
                      >
                        {{ order.payment_details.payment_id }}
                      </div>
                    </div>

                    <div class="row pt-1">
                      <div class="col-4">{{ "Reference ID" | translate }}</div>
                      <div
                        class="col-8 {{
                          lang == 'ar' ? 'text-left' : 'text-right'
                        }}"
                      >
                        {{ order.payment_details.ref }}
                      </div>
                    </div>

                    <div class="row pt-1">
                      <div class="col-4">{{ "Result" | translate }}</div>
                      <div
                        class="col-8 {{
                          lang == 'ar' ? 'text-left' : 'text-right'
                        }}"
                      >
                        {{ order.payment_details.result }}
                      </div>
                    </div>

                    <div class="row pt-1">
                      <div class="col-4">{{ "Track ID" | translate }}</div>
                      <div
                        class="col-8 {{
                          lang == 'ar' ? 'text-left' : 'text-right'
                        }}"
                      >
                        {{ order.payment_details.track_id }}
                      </div>
                    </div>

                    <div class="row pt-1">
                      <div class="col-4">
                        {{ "Transaction ID" | translate }}
                      </div>
                      <div
                        class="col-8 {{
                          lang == 'ar' ? 'text-left' : 'text-right'
                        }}"
                      >
                        {{ order.payment_details.transaction_id }}
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
              <div class="col-12 col-md-5 px-0">
                <div
                  class="col-12 white-container OrderSummary pt-3 pb-3 mb-2 font-regular font-small"
                >
                  <h4 class="font-medium font-medium">
                    {{ "ORDER TOTAL" | translate }}
                  </h4>
                  <app-cart-summary
                    [currency]="currency"
                    [cartValue]="order.sub_total"
                    [shippingCost]="order.delivery_charges"
                    [codCost]="order.cod_cost"
                    [vatCharges]="order.vat_charges"
                    [discount]="order.discount_price"
                    [deliveryText]=""
                    [total]="order.total"
                    [custom_duty_charges]="order.customDutyCharges"
                  ></app-cart-summary>

                  <button
                    type="button"
                    class="btn btn-black font-black btn-block mt-3 position-relative"
                    *ngIf="
                      order.status_id !== 6 &&
                      order.status_id !== 5 &&
                      order.status_id !== 7
                    "
                    (click)="showCancelModal()"
                  >
                    {{ "CANCEL ORDER" | translate }}
                    <i class="fas fa-angle-right absoluteBtn"></i>
                  </button>
                  <button
                    type="button"
                    class="btn btn-black font-black btn-block mt-3 position-relative"
                    *ngIf="order.status_id === 5"
                    (click)="requestReturn()"
                  >
                    {{ "REQUEST RETURN" | translate }}
                    <i class="fas fa-angle-right absoluteBtn"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="OrderedItems">
            <div class="row">
              <div
                class="col-12 white-container pt-3 pb-3 mb-2 font-regular font-small"
              >
                <h4 class="font-medium font-medium pb-1">
                  {{ order.items.length }} {{ "ITEM(S) ORDERED" | translate }}
                </h4>
                <div class="row pt-1 mb-3" *ngFor="let item of order.items">
                  <div class="col-2">
                    <img src="{{ item.image }}" class="img-fluid" />
                    <div class="pre-order-badge" *ngIf="item.is_preorder">
                      {{ "Pre Order" }}
                    </div>
                  </div>
                  <div class="col-9">
                    <span class="font-medium d-block">{{ item.name }}</span>
                    <span
                      class="d-block"
                      *ngFor="let option of item.configurable_option"
                      >{{ option.type }}: {{ option.attributes[0].value }}</span
                    >
                    <span class="d-block pt-2 bidi-override"
                      ><span class="bidi-override dir-rtl">{{ currency }}</span>
                      {{ item.final_price | number : "1.3-3" }}</span
                    >
                  </div>
                  <div class="col-1 font-medium item-quantity p-0">
                    <span>x{{ item.quantity }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </section>
</ng-container> -->
