<section class="" id="cart-list">
  <div class="maintitle-Container">
    <h4 class="page-title text-center font-black font-condensed" *ngIf="categories">CATEGORY</h4>
  </div>

  <div class="container" *ngIf="categories">
    <section class="content-section">
      <div class="col-12">
        <mat-tab-group class="w-100">
          <mat-tab *ngFor="let category of categories" label="{{category.name | uppercase}}">
            <div class="col-12 col-md-7 mt-3 mb-3">
              <p class="font-regular font-medium text-justify color-black">{{category.name}}</p>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </section>
  </div>
</section>
