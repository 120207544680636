import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PopularSearchService {
    private popularSource = new BehaviorSubject([]);
    popularSearch = this.popularSource.asObservable();

    constructor() { }

    changePopularSearch(notification: any) {
        this.popularSource.next(notification);
    }
}
