<div class="container-fluid">
  <div class="address_book_data">
    <div class="address_add_data">
      <div class="address_add_box">
        <div class="address_listing_data" *ngIf="addresses.length > 0">
          <div class="address_listing_box">

            <div
              class="listing_box"
              *ngFor="let address of addresses; let i = index"
            >
              <div class="d-flex justify-content-between gap-2 w-100">
                <label
                  for="{{ address.address_id }}"
                  (click)="selectAddress('address_' + i, i)"
                >
                  <div class="address_data_added">
                    <h2>{{ address.first_name + " " + address.last_name }}</h2>
                    <div class="address_data_filed">
                      <p>+{{ address.mobile_number }}</p>
                      <p>{{ address.country_name.toLowerCase() }}</p>
                      <p>{{ address.governorate_name }}</p>
                      <p>
                        {{ address.block_name
                        }}<span *ngIf="address?.block_name">,</span>
                        {{ address.street }}
                      </p>
                      <p>{{ address.avenue }}</p>
                      <p>
                        {{ address.addressline_1
                        }}<span *ngIf="address?.addressline_1">,</span>
                        {{ address.building
                        }}<span *ngIf="address?.building">,</span>
                        {{ address.floor
                        }}<span *ngIf="address?.floor">,</span> {{ address.flat
                        }}<span *ngIf="address?.flat">,</span>
                        {{ address.landmark }}
                      </p>
                    </div>
                    <div class="address_data_button">
                      <div
                        class="d-flex align-items-center gap-4"
                        (click)="editAddress(address)"
                      >
                        <a>
                          <span>{{ "Edit" | translate }}</span>
                        </a>
                        <a (click)="deleteAddress(address.address_id)">
                          <span>{{ "Remove" | translate }}</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </label>
                <input
                  type="radio"
                  id="{{ address.address_id }}"
                  name="featured"
                  *ngIf="address.is_default == 'Yes'"
                  checked
                />
                <input
                  type="radio"
                  id="{{ address.address_id }}"
                  name="featured"
                  *ngIf="address.is_default != 'Yes'"
                />
              </div>
            </div>

            <div
            class="listing_box listing_address_box"
          >
            <div
              class="d-flex align-content-center align-items-center w-100 h-100"
            >
              <div class="add_new_add w-100" (click)="showAddAddress()">
                <img src="/assets/icons/plus.svg" alt="Plus" />
                <p>{{ "Add an Address" | translate }}</p>
              </div>
            </div>
          </div>
            
          </div>
         
          <div class="continue py-4">
            <button
              type="button"
              class="btn btn-dark text-center w-50"
              (click)="proceedToPayment()"
            >
              {{ "continue" | translate }}
            </button>
          </div>
        </div>

        <div class="edit_data" *ngIf="showForm">
          <div class="edit_data_header">
            <div
              class="d-flex justify-content-between w-100 align-items-center"
            >
              <div>
                <div class="d-flex align-items-center gap-3">
                  <div>
                    <img
                      class="edit_profile"
                      src="/assets/icons/Location.svg"
                      alt="Edit Profile"
                    />
                  </div>
                  <div>
                    <h1>{{ "SHIPPING ADDRESS" | translate }}</h1>
                  </div>
                </div>
              </div>
            </div>
            <div class="shipping_to">
              <p>
                {{ "Shipping to" | translate }}
                <img src="../../assets/icons/kuwait_flag.svg" alt="flag" />
                {{ "Kuwait" | translate }}
              </p>
            </div>
          </div>
          <form [formGroup]="userAddress">
            <div class="edit_data_body">
              <div>
                <label for="">{{ "First Name" | translate }}</label>
                <input type="text" formControlName="first_name" />
                <div
                  *ngIf="
                    userAddress.get('first_name')?.invalid &&
                    userAddress.get('first_name')?.touched
                  "
                >
                  <p
                    *ngIf="
                      userAddress.get('first_name')?.errors &&
                      userAddress.get('first_name')?.hasError('required')
                    "
                    class="form-text form-validator_error"
                  >
                    {{ "Please enter your first name" | translate }}
                  </p>
                </div>
              </div>
              <div>
                <label for="">{{ "Last Name" | translate }}</label>
                <input type="text" formControlName="last_name" />
                <div
                  *ngIf="
                    userAddress.get('last_name')?.invalid &&
                    userAddress.get('last_name')?.touched
                  "
                >
                  <p
                    *ngIf="
                      userAddress.get('last_name')?.errors &&
                      userAddress.get('last_name')?.hasError('required')
                    "
                    class="form-text form-validator_error"
                  >
                    {{ "Please enter your last name" | translate }}
                  </p>
                </div>
              </div>
              <div>
                <label for="">{{ "Mobile" | translate }}</label>
                <div class="d-flex gap-3 w-100 align-items-baseline">
                  <div class="">
                    <input
                      type="text"
                      formControlName="mobile_number"
                      pattern="[0-9]{8,10}"
                      maxlength="10"
                    />
                    <div
                      *ngIf="
                        userAddress.get('mobile_number')?.invalid &&
                        userAddress.get('mobile_number')?.touched
                      "
                    >
                      <small
                        *ngIf="
                          (userAddress.get('mobile_number')?.errors &&
                            userAddress
                              .get('mobile_number')
                              ?.hasError('pattern')) ||
                          (userAddress.get('mobile_number')?.invalid &&
                            userAddress.get('mobile_number')?.touched)
                        "
                        class="form-text form-validator_error"
                        >{{
                          "Valid phone number is required" | translate
                        }}</small
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <label>{{ "Country" | translate }}</label>
                <br />
                <select
                  formControlName="country_id"
                  id="country"
                  class="countryCls"
                  (click)="getCountries()"
                >
                  <!-- <option value="'114'" class="text-themeBlue text-sm Arial bg-themeYellow " [selected]="'Kuwait'">{{"Kuwait" | translate}}</option> -->
                  <option
                    [ngValue]="country.id"
                    *ngFor="let country of countries"
                  >
                    {{ country.name }}
                  </option>
                </select>
                <span
                  class="form-text form-validator_error"
                  *ngIf="
                    userAddress.controls.country_id.value == '' &&
                    userAddress.controls.country_id.touched
                  "
                  >{{ "Please select country first" | translate }}</span
                >
                <!-- <ng-select [items]="countries" [addTag]="true" bindLabel="name" bindValue="id"
                              id="address-country_id" name="address-country_id" aria-describedby="countryHelp"
                              #paramCountry="ngModel" [clearable]=false [searchable]=false
                              [placeholder]="'Select One' | translate" (change)="getStates($event)"
                              [(ngModel)]="addressf.country_id" class="select-input add-ship" required></ng-select>
                            <small id="countryHelp" class="form-text form-validator_error"
                              [hidden]="paramCountry.valid || paramCountry.pristine">
                              {{'Please select your country' | translate}}
                            </small> -->
              </div>
              <div>
                <label>{{ "Governorate" | translate }}</label>
                <br />
                <select
                  formControlName="governorate_id"
                  id="governorate"
                  class="countryCls"
                  (click)="
                    getStates($event, userAddress.controls.country_id.value)
                  "
                >
                  <!-- <option value="'114'" class="text-themeBlue text-sm Arial bg-themeYellow " [selected]="'Kuwait'">{{"Kuwait" | translate}}</option> -->
                  <option [ngValue]="area.id" *ngFor="let area of governorates">
                    {{ area.name }}
                  </option>
                </select>
                <span
                  class="form-text form-validator_error"
                  *ngIf="
                    userAddress.controls.governorate_id.value == '' &&
                    userAddress.controls.governorate_id.touched
                  "
                  >{{ "Please select governorate first" | translate }}</span
                >
                <!-- <ng-select [items]="governorates" [addTag]="true" bindLabel="name" bindValue="id"
                      id="address-governorate_id" name="address-governorate_id" aria-describedby="governorateHelp"
                      #paramGovernorate="ngModel" [clearable]=false [searchable]=false
                      [placeholder]="'Select One' | translate" (change)="getAreas($event)"
                      [(ngModel)]="addressf.governorate_id" class="select-input add-ship" required></ng-select>
                    <small id="governorateHelp" class="form-text form-validator_error"
                      [hidden]="paramGovernorate.valid || paramGovernorate.pristine">
                      {{'Please select your state/prov./region' | translate}}
                    </small> -->
              </div>
              <div>
                <label>{{ "Town / City" | translate }}</label>
                <br />
                <select
                  formControlName="area_id"
                  id="area"
                  class="countryCls"
                  (click)="getAreas(userAddress.controls.country_id.value)"
                >
                  <!-- <option value="'114'" class="text-themeBlue text-sm Arial bg-themeYellow " [selected]="'Kuwait'">{{"Kuwait" | translate}}</option> -->
                  <option [ngValue]="city.id" *ngFor="let city of areas">
                    {{ city.name }}
                  </option>
                </select>
                <span
                  class="form-text form-validator_error"
                  *ngIf="
                    userAddress.controls.area_id.value == '' &&
                    userAddress.controls.area_id.touched
                  "
                  >{{ "Please select city first" | translate }}</span
                >
                <!-- <ng-select [items]="areas" [addTag]="true" bindLabel="name" bindValue="id" id="address-area_id"
                              name="address-area_id" aria-describedby="areaHelp" #paramArea="ngModel" [clearable]=false
                              [searchable]=false [placeholder]="'Select One' | translate" (change)="getBlocks($event)"
                              [(ngModel)]="addressf.area_id" class="select-input add-ship"
                              [required]="(areas.length) > 0 ? true : false"></ng-select>
                            <small id="areaHelp" class="form-text form-validator_error"
                              [hidden]="paramArea.valid || paramArea.pristine">
                              {{'Please select your city/area' | translate}}
                            </small> -->
              </div>
              <div>
                <label>{{ "Block" | translate }}</label>
                <br />
                <select
                  formControlName="block_id"
                  id="block"
                  class="countryCls"
                  (click)="getBlocks(userAddress.controls.governorate_id.value)"
                >
                  <!-- <option value="'114'" class="text-themeBlue text-sm Arial bg-themeYellow " [selected]="'Kuwait'">{{"Kuwait" | translate}}</option> -->
                  <option [ngValue]="block.id" *ngFor="let block of sectors">
                    {{ block.name }}
                  </option>
                </select>
                <span
                  class="form-text form-validator_error"
                  *ngIf="
                    userAddress.controls.block_id.value == '' &&
                    userAddress.controls.block_id.touched
                  "
                  >{{ "Please select block first" | translate }}</span
                >
                <!-- <ng-select [items]="sectors" [addTag]="true" bindLabel="name" bindValue="id" id="address-block_id"
                      name="address-block_id" #paramBlock="ngModel" [clearable]=false [searchable]=false
                      [placeholder]="'Select One' | translate" [(ngModel)]="addressf.block_id" class="select-input add-ship">
                    </ng-select> -->
              </div>
              <div>
                <label for="">{{ "Street" | translate }}</label>
                <input type="text" formControlName="street" />
                <div
                  *ngIf="
                    userAddress.get('street')?.invalid &&
                    userAddress.get('street')?.touched
                  "
                >
                  <p
                    *ngIf="
                      userAddress.get('street')?.errors &&
                      userAddress.get('street')?.hasError('required')
                    "
                    class="form-text form-validator_error"
                  >
                    {{ "Please enter your street" | translate }}
                  </p>
                </div>
              </div>
              <div>
                <label for="">{{ "Avenue" | translate }}</label>
                <input type="text" formControlName="avenue" />
                <div
                  *ngIf="
                    userAddress.get('avenue')?.invalid &&
                    userAddress.get('avenue')?.touched
                  "
                >
                  <p
                    *ngIf="
                      userAddress.get('avenue')?.errors &&
                      userAddress.get('avenue')?.hasError('required')
                    "
                    class="form-text form-validator_error"
                  >
                    {{ "Please enter your avenue" | translate }}
                  </p>
                </div>
              </div>
              <div>
                <label for="">{{ "Landmark" | translate }}</label>
                <input type="text" formControlName="landMark" />
                <div
                  *ngIf="
                    userAddress.get('landMark')?.invalid &&
                    userAddress.get('landMark')?.touched
                  "
                >
                  <p
                    *ngIf="
                      userAddress.get('landMark')?.errors &&
                      userAddress.get('landMark')?.hasError('required')
                    "
                    class="form-text form-validator_error"
                  >
                    {{ "Please enter your landmark" | translate }}
                  </p>
                </div>
              </div>
              <!-- <div>
                <label>{{ "Landmark" | translate }}</label>
                <input type="text" formControlName="landmark" />
                <div
                *ngIf="userAddress.get('landmark')?.invalid && userAddress.get('landmark')?.touched">
                <p *ngIf="userAddress.get('landmark')?.errors && 
                userAddress.get('landmark')?.hasError('landmark')" class="form-text form-validator_error">
                    {{"Please enter Landmark" | translate}} </p>
            </div>
              </div> -->
              <div>
                <label for="">{{ "House / Building" | translate }}</label>
                <input type="text" formControlName="building" />
                <div
                  *ngIf="
                    userAddress.get('building')?.invalid &&
                    userAddress.get('building')?.touched
                  "
                >
                  <p
                    *ngIf="
                      userAddress.get('building')?.errors &&
                      userAddress.get('building')?.hasError('required')
                    "
                    class="form-text form-validator_error"
                  >
                    {{ "Please enter Building" | translate }}
                  </p>
                </div>
              </div>
              <div>
                <label for="">{{ "Floor" | translate }}</label>
                <input type="text" formControlName="floor" />
                <div
                  *ngIf="
                    userAddress.get('floor')?.invalid &&
                    userAddress.get('floor')?.touched
                  "
                >
                  <p
                    *ngIf="
                      userAddress.get('floor')?.errors &&
                      userAddress.get('floor')?.hasError('required')
                    "
                    class="form-text form-validator_error"
                  >
                    {{ "Please enter floor" | translate }}
                  </p>
                </div>
              </div>
              <div>
                <label for="">{{ "Flat" | translate }}</label>
                <input type="text" formControlName="flat" />
                <div
                  *ngIf="
                    userAddress.get('flat')?.invalid &&
                    userAddress.get('flat')?.touched
                  "
                >
                  <p
                    *ngIf="
                      userAddress.get('flat')?.errors &&
                      userAddress.get('flat')?.hasError('required')
                    "
                    class="form-text form-validator_error"
                  >
                    {{ "Please enter flat" | translate }}
                  </p>
                </div>
              </div>

              <div>
                <label for="">{{ "ID Number" | translate }}</label>

                <input type="text" formControlName="id_number" />
                <div
                  *ngIf="
                    userAddress.get('id_number')?.invalid &&
                    userAddress.get('id_number')?.touched
                  "
                >
                  <small
                    *ngIf="
                      (userAddress.get('id_number')?.errors &&
                        userAddress.get('id_number')?.hasError('pattern')) ||
                      (userAddress.get('id_number')?.invalid &&
                        userAddress.get('id_number')?.touched)
                    "
                    class="form-text form-validator_error"
                    >{{ "Please enter your id number" | translate }}</small
                  >
                </div>
              </div>
            </div>

            <div>
              <div class="edit_note">
                <label for="">{{
                  "Notes (Landmark or delivery instructions)" | translate
                }}</label>
                <textarea formControlName="notes"></textarea>
                <div
                  *ngIf="
                    userAddress.get('notes')?.invalid &&
                    userAddress.get('notes')?.touched
                  "
                >
                  <p
                    *ngIf="
                      userAddress.get('notes')?.errors &&
                      userAddress.get('notes')?.hasError('notes')
                    "
                    class="form-text form-validator_error"
                  >
                    {{ "Please enter note" | translate }}
                  </p>
                </div>
              </div>
            </div>

            <div class="edit_data_footer text-center">
              <button type="submit" (click)="saveAddress(userAddress)">
                {{ "CONTINUE" | translate }}
              </button>
            </div>
          </form>
        </div>
      </div>
      <!-- <app-add-shipping-address (triggerChangeAddress)="selectLastAddress()"></app-add-shipping-address> -->

      <div class="address_product_box">
        <div class="address_product_data">
          <div class="address_product_header">
            <div class="d-flex justify-content-between align-content-center">
              <p>2 items in your shopping bag</p>
              <h2>KD 84.00</h2>
            </div>
          </div>
          <div class="address_product_body">
            <div class="product_data">
              <div>
                <div class="product_image">
                  <figure>
                    <div class="image-wrapper">
                      <img
                        src="https://dev.shop-twl.com/uploads/1683809617645ce551196260.88993521.jpg"
                        alt="Product Image"
                      />
                    </div>
                  </figure>
                </div>
              </div>
              <div class="position-relative w-100">
                <div class="details_product">
                  <h2>LISTED</h2>
                  <p>Blazer + Cami Dress-Set</p>
                  <span>XL | WHITE</span>
                </div>

                <div class="details_product_price">
                  <div
                    class="d-flex justify-content-between w-100 align-items-center"
                  >
                    <div>
                      <div class="d-flex gap-2 align-items-center">
                        <h2 class="price_discount">KD 23.00</h2>
                        <p>KD 43.00</p>
                      </div>
                    </div>
                    <div>
                      <span class="product_total">x 2</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="product_data">
              <div>
                <div class="product_image">
                  <figure>
                    <div class="image-wrapper">
                      <img
                        src="https://dev.shop-twl.com/uploads/1683809617645ce551196260.88993521.jpg"
                        alt="Product Image"
                      />
                    </div>
                  </figure>
                </div>
              </div>
              <div class="position-relative w-100">
                <div class="details_product">
                  <h2>LISTED</h2>
                  <p>Blazer + Cami Dress-Set</p>
                  <span>XL | WHITE</span>
                </div>

                <div class="details_product_price">
                  <div
                    class="d-flex justify-content-between w-100 align-items-center"
                  >
                    <div>
                      <div class="d-flex gap-2 align-items-center">
                        <h2 class="price_normal">KD 23.00</h2>
                      </div>
                    </div>
                    <div>
                      <span class="product_total">x 2</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- <ng-container>
    <section class="user-account padding-top-section">
      <div class="container">
        <div class="MAIN-ADDRESS-Book">
          <div class="row">
            <div class="col-md-9">
              <div>
                <div class="ADD-BUTTON BUTTON-addressbook">
                  <button
                    id="addDelivery"
                    #addDelivery
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                    type="button"
                    (click)="addDeliveryAddress()"
                  >
                    <span *ngIf="lang == 'en' || isMobile"
                      ><img src="assets/ArtWork/plus.png"
                    /></span>
                    {{ "ADD DELIVERY ADDRESS" | translate }}
                    <span *ngIf="lang == 'ar' && !isMobile"
                      ><img src="assets/ArtWork/plus.png"
                    /></span>
                  </button>
                </div>
              </div>

              <div
                class="address-full"
                *ngFor="let address of addresses; let i = index"
              >
                <div class="row">
                  <div class="col-7">
                    <div class="custom_radio">
                                        <input type="radio" id="{{address.address_id}}" name="featured" *ngIf="address.is_default == 'Yes'" checked>
                                        <input type="radio" id="{{address.address_id}}" name="featured" *ngIf="address.is_default != 'Yes'">
                                        <label for="{{address.address_id}}" (click)="selectAddress('address_'+i, i)">
                                            <div class="p-0 wordBreak">
                                                <span class="font-bold font-medium mb-1">
                                                  {{address.first_name + ' ' + address.last_name}}
                                                </span><br>
                                                <span *ngIf="address.governorate_name">{{address.governorate_name}}</span>
                                                <span *ngIf="address.area_name">{{address.area_name}},</span>
                                                <span *ngIf="address.country_name">{{address.country_name}},</span><br>
                                                <span *ngIf="address.avenue">{{address.avenue}},</span>
                                                <span><span *ngIf="address.street">{{address.street}},</span></span>
                                                <span><span *ngIf="address.floor">{{address.floor}},</span> <span *ngIf="address.flat">{{address.flat}},</span></span>
                                                <span *ngIf="address.notes">{{address.notes}}</span>
                                                <span class="mobile-number" *ngIf="address.mobile_number">{{'Mobile' | translate}} {{address.mobile_number}}</span>
                                                <span class="mobile-number" *ngIf="address.id_number">{{'ID Number' | translate}} {{address.id_number}}</span>
                                            </div>
                                        </label>
                                      </div>
                  </div>
                  <div class="col-5">
                    <div class="EDIT-REMOVE">
                      <ul [ngClass]="lang == 'ar' ? 'reverseAr' : ''">
                        <li
                          class="Remove-COLOR"
                          *ngIf="address.is_default != 'Yes'"
                        >
                          <span (click)="deleteAddress(address.address_id)">
                            {{ "REMOVE" | translate }}
                          </span>
                        </li>
                        <li
                          class="EDIT-COlor"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          (click)="editAddress(address)"
                        >
                          <span>{{ "EDIT" | translate }}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="addressbook">
                <button type="button" (click)="proceedToPayment()">
                  {{ "Continue" | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </ng-container> -->
</div>
