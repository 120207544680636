import { Component, OnInit } from '@angular/core';
import { listStagger } from '../animations';
import { FooterService } from '../config/footer.service';
import { ConfigSettings } from '../config/config.settings';
import { NgForm } from '@angular/forms';
import { AuthenticationService } from '../config/authentication.service';
import { first } from 'rxjs/operators';
import { UserService } from '../config/user.service';
import { User } from '../models/user';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css'],
  animations: [ listStagger ]
})
export class ChangePasswordComponent implements OnInit {
  lang: string;
  currentPassword: string;
  newPassword: string;
  confirmPassword;
  submitted = false;
  hasError = false;
  message: string;
  user: User;
  dataAvailable = false;
  
  constructor( 
    protected footerService: FooterService, 
    protected configSettings: ConfigSettings,
    protected authenticationService: AuthenticationService,
    private userService: UserService,
  ) { }

  ngOnInit() {
    this.configSettings.toggleLoading(true);
    this.lang = this.configSettings.getLang();
    this.userService.getUserSession().then(response => {
      if (response !== false) {
          this.user = response;
          this.dataAvailable = true;
          setTimeout(() => {this.footerService.toggleFooter(true)}, 300);
          this.configSettings.toggleLoading(false);
      }
    }, error => { this.configSettings.toggleLoading(false); });
  }

  onChangePassword() {

  }

  /**
     * After user submit form
     * @param changePasswordForm 
     */
    onSubmit(changePasswordForm: NgForm) {
      this.configSettings.toggleLoading(true);
      this.doChangePassword();
      changePasswordForm.reset();
  }

  /**
   * Change user password
   */
  doChangePassword() {
    try {
      this.configSettings.toggleLoading(true);
      const params = {
        user_id: this.user.id,
        old_password: this.currentPassword,
        new_password: this.newPassword
      };
      this.authenticationService.changePassword(params)
            .pipe(first())
            .subscribe(
                response => {
                    this.message = response.message;
                    if (response.status === 200) {
                      this.hasError = false;
                    } else {
                      this.hasError = true;
                      if(response.status === 201){
                        this.message = 'Current password did not match';
                      }
                    }
                    this.submitted = true;
                    this.configSettings.toggleLoading(false);
                }, error => { this.configSettings.toggleLoading(false); }
            );
    } catch (e) {
      this.configSettings.toggleLoading(false);
      console.log(e);
    }
  }

}
