import { EventEmitter, Injectable, Output, Directive } from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {LocalStorage} from '@ngx-pwa/local-storage';
import { Address } from '../models/address';

@Directive()
@Injectable({
    providedIn: "root"
})
export class NavbarService {
  navigationStatus = true;
  isEnableHeaderBottom = true;
  isEnableHeaderBottomMobile = false;
  enabledCategory:any = 1;
  addAddress:any = true;
  @Output() change: EventEmitter<boolean> = new EventEmitter<boolean>();

  website_header_text = '';
  showGuestLogin = false;
  @Output() getWebsiteHeaderText: EventEmitter<string> = new EventEmitter<string>();
  @Output() isEnableHeaderBottomTrigger: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() headreTopMenuMobileEnableTrigger: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() isAddAddress: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() changeCategory: EventEmitter<any> = new EventEmitter<any>();
  @Output() changeShowGuestLogin: EventEmitter<any> = new EventEmitter<any>();

  private support_details = new Subject<any>();
  currentSupportDetails = this.support_details.asObservable();

  constructor(
    protected localStorage: LocalStorage,
  ) { }

  toggleMainNavigation(key: boolean) {
    this.navigationStatus = key;
    this.change.emit(this.navigationStatus)
  }

  setWebsiteHeaderText(key: string) {
    this.website_header_text = key;
    this.getWebsiteHeaderText.emit(this.website_header_text);
  }

  setSupportDetails(data: any) {
    this.support_details.next(data);
    this.localStorage.setItem('support_details', data).subscribe(() => {});
  }

  isEnableHeaderBottomAction(key: boolean) {
    this.isEnableHeaderBottom = key;
    this.isEnableHeaderBottomTrigger.emit(this.isEnableHeaderBottom);
  }

  isEnableHeaderBottomMobileAction(key: boolean) {
    this.isEnableHeaderBottomMobile = key;
    this.headreTopMenuMobileEnableTrigger.emit(this.isEnableHeaderBottomMobile);
  }
 
  triggerCategoryChange(key:any){
    this.enabledCategory = key;
    this.changeCategory.emit(this.enabledCategory);
  }
  triggerChangeShowGuestLogin(key:any){
    this.showGuestLogin = key;
    this.changeShowGuestLogin.emit(this.showGuestLogin);
  }

  triggerAddress(address:any, key:any){
    this.addAddress = key;
    this.isAddAddress.emit(address);
  }
}
