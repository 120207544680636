import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AddressHelpersService {
  private addressInfo: BehaviorSubject<any> = new BehaviorSubject<any>(null)
  constructor() { }

  setAddressInfo(value: any) {
    this.addressInfo.next(value)
  }

  getAddressInfo(): Observable<any> {
    return this.addressInfo.asObservable()
  }

  clearAddressInfo(){
    this.addressInfo.next(null);
  }

}
