import { Component, Input, OnInit,Output,EventEmitter } from '@angular/core';
import { NavbarService } from "../config/navbar.service";
import { UserService } from "../config/user.service";
import { CartService } from "../config/cart.service";
import { ConfigSettings } from "../config/config.settings";
import { Cart } from "../models/cart";
import { Address } from "../models/address";
import { CheckoutService } from "../config/checkout.service";
import { NavigationStart, Router } from "@angular/router";
import { Store } from '../models/store';
import { listStagger } from '../animations';
import { FooterService } from '../config/footer.service';
import { ConfigService } from '../config/config.service';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-addressbook',
  templateUrl: './addressbook.component.html',
  styleUrls: ['./addressbook.component.scss']
})
export class AddressbookComponent implements OnInit {

  cart: Cart = null;
  addresses: Address[] = [];
  // address: Address = null;
  currency: string = '';
  cartValue: number = 0;
  shippingCost: number = 0;
  vatCharges: string = "";
  lang: string;
  store: Store;
  dataAvailable = false;
  isMobile = false;
  @Input() address: Address = {
    address_id: null,
    first_name: null,
    last_name: null,
    area_id: null,
    area_name: null,
    governorate_id: null,
    governorate_name: null,
    country_id: 114, // default country Kuwait
    country_name: null,
    vat_pct: null,
    vat_charges: null,
    block_id: null,
    block_name: null,
    shipping_cost: null,
    express_shipping_cost: null,
    address_store_code: null,
    iso: null,
    cod_cost: null,
    is_cod_enable: null,
    phonecode: null,
    street: null,
    addressline_1: null,
    mobile_number: null,
    alt_phone_number: null,
    location_type: null,
    notes: null,
    is_default: null,
    delivery_options: null,
    avenue: null,
    flat: null,
    floor: null,
    id_number: null,
    landmark: null
};

  @Input() addDelivery: any;
  addressf: any;
  sectors: any;
  submitted: boolean;
  areas: any[];
  governorates: any;
  countryCode: string;
  countries: any;
  showForm: boolean=true;
  userId: any;

  constructor(
    protected footerService: FooterService,
    private navbarService: NavbarService,
    private userService: UserService,
    private cartService: CartService,
    private configSettings: ConfigSettings,
    private checkoutService: CheckoutService,
    private configService: ConfigService,
    private router: Router,
    private toastr:ToastrService
  ) {
    router.events.subscribe((event: NavigationStart) => {
      if (event.navigationTrigger === 'popstate') {
        this.cancelCheckout();
      }
    });
  }

  ngOnInit() {
    this.isMobile = window.innerWidth < 600;
    this.navbarService.toggleMainNavigation(true);
    this.navbarService.isEnableHeaderBottomAction(false);
    this.configSettings.toggleLoading(true);
    this.checkoutService.setStep(1);
    this.navbarService.isEnableHeaderBottomMobileAction(false);

    this.lang = this.configSettings.getLang();
    this.configSettings.getStoreObject().then((result) => {
      this.store = <Store>result;
      this.currency = result['currency_' + this.lang];

      this.getUserAddress();
  this.selectLastAddress()
      setTimeout(() => {
        this.footerService.toggleFooter(true);
      }, 300);
    }, error => { this.configSettings.toggleLoading(false); });
    
    this.userService.getUserSession().then(user => {
      if(user !== false) {
          this.userId = user.id;
      }
  });
  if (this.addresses) {
    this.showForm=false
  }
  
  }
  arrayCheck(){
    this.address.first_name=String(this.userAddress.controls.first_name.value)
    this.address.last_name=String(this.userAddress.controls.last_name.value)
    this.address.country_id=Number(this.userAddress.controls.country_id.value)
    this.address.governorate_id=Number(this.userAddress.controls.governorate_id.value)
    this.address.area_id=Number(this.userAddress.controls.area_id.value)
    this.address.block_id=Number(this.userAddress.controls.block_id.value)
    this.address.street=String(this.userAddress.controls.street.value)
    this.address.flat=String(this.userAddress.controls.flat.value)
    this.address.floor=String(this.userAddress.controls.floor.value)
    this.address.avenue=String(this.userAddress.controls.avenue.value)
    this.address.landmark=String(this.userAddress.controls.landMark.value)
    this.address.mobile_number=String(this.userAddress.controls.mobile_number.value)
    this.address.notes = String(this.userAddress.controls.notes.value)
  }

  cancelCheckout() {
    const params = {
      order_id: this.configSettings.getCartId(),
      user_id: this.userId
    };
    this.configService.readRequest('cancel-checkout', params)
      .subscribe(response => {
        if (response.status === 200) {
          console.log('cancel checkout completed')
        } else {
          this.configSettings.toggleLoading(false);
        }
      }, error => { this.configSettings.toggleLoading(false); });
  }

  deleteAddress(id) {
    this.configSettings.toggleLoading(true);
    const getParams = {
      lang: this.lang
    };

    const postParams = {
      id: id,
      user_id: this.userId
    };

    this.configService.postRequest('delete-address', getParams, postParams)
      .subscribe(response => {
        if (response.status === 200) {
          this.addresses = response.data;
          this.userAddress.reset()
        }
        this.configSettings.toggleLoading(false);
      }, error => { this.configSettings.toggleLoading(false); });

    return false;
  }

  getUserAddress() {
    this.configSettings.toggleLoading(true);
    this.userService.getUserSession().then(response => {
      if (response !== false) {
        this.userService.getUserAddress(response.id, this.lang, this.store.iso_code).then(data => {
          if (data.length > 0) {
            this.addresses = data;            
            this.addresses.map(address => {
              if (address.is_default === 'Yes') {
                this.address = address;
                this.shippingCost = +this.address.delivery_options[0].price;
                this.vatCharges = address.vat_charges;
              }
            });
          } else {
            //   this.router.navigate(['shipping-address/add']);
            // console.log('No Item Found')
            // setTimeout(() => {
            //   document.getElementById('addDelivery').click();
            //   console.log('No Item Found')
            // }, 100);
          }
          this.configSettings.toggleLoading(false);
          this.dataAvailable = true;
          //   this.footerService.toggleFooter(true);
        }, error => { this.configSettings.toggleLoading(false); });
      }
    }, error => { this.configSettings.toggleLoading(false); });
  }

  getUserAddressV2() {
    this.configSettings.toggleLoading(true);
    this.userService.getUserSession().then(response => {
      if (response !== false) {
        this.userService.getUserAddress(response.id, this.lang, this.store.iso_code).then(data => {
          if (data.length > 0) {
            this.addresses = data;
            this.addresses.map(address => {
              if (address.is_default === 'Yes') {
                this.address = address;
                this.shippingCost = +this.address.delivery_options[0].price;
                this.vatCharges = address.vat_charges;
              }
            });
            this.selectAddressV2('address_' + this.addresses.length, this.addresses.length);
          } else {
            // console.log('No Item Found')
            // setTimeout(() => {
            //   document.getElementById('addDelivery').click();
            //   console.log('No Item Found')
            // }, 100);
          }
          this.configSettings.toggleLoading(false);
          this.dataAvailable = true;
        }, error => { this.configSettings.toggleLoading(false); });
      }
    }, error => { this.configSettings.toggleLoading(false); });
  }

  selectAddress(address, index) {
    this.address = this.addresses[index];
    this.vatCharges = this.address.vat_charges;
    this.shippingCost = +this.address.delivery_options[0].price;
    this.configSettings.setShippingAddress(this.address);
  }

  selectAddressV2(address, index) {
    this.address = this.addresses[index - 1];
    this.vatCharges = this.addresses[index - 1].vat_charges;
    this.shippingCost = +this.addresses[index - 1].delivery_options[0].price;
    this.configSettings.setShippingAddress(this.addresses[index - 1]);
  }

  selectLastAddress() {
    this.getUserAddressV2();
  }

  proceedToPayment() {
    this.checkoutService.setAddress(this.address);
    this.router.navigate(['checkout']);
  }

  editAddress(address: any) {
    this.userAddress.patchValue({
      first_name: address.first_name,
      last_name: address.last_name,
      country_id: address.country_id,
      governorate_id: address.state_id,
      area_id: address.area_id,
      block_id: address.block,
      street: address.street,
      flat: address.flat,
      floor: address.floor,
      building: address.building,
      avenue: address.avenue,
      landMark: address.landMark,
      mobile_number: address.mobile_number,
      id_number:address.id_number,
      notes:address.notes
    })
    this.navbarService.triggerAddress(address, false);
    this.showForm=!this.showForm
  }

  addDeliveryAddress() {
    this.navbarService.triggerAddress(null, true);
  }
  ngOnDestroy(){
    this.navbarService.toggleMainNavigation(true);
    // let doc=document.getElementById('closeModalButton');
    // doc.click();
  }

  userAddress = new FormGroup({
    first_name: new FormControl('', Validators.compose([Validators.required])),
    last_name: new FormControl('', Validators.compose([Validators.required])),
    floor: new FormControl('', Validators.compose([Validators.required])),
    mobile_number: new FormControl('',  [Validators.required,Validators.pattern("^[0-9]{8,10}$"),Validators.minLength(8), Validators.maxLength(10)]),
    country_id: new FormControl('', Validators.compose([Validators.required])),
    governorate_id: new FormControl('', Validators.compose([Validators.required])),
    area_id: new FormControl('', Validators.compose([])),
    block_id: new FormControl('', Validators.compose([Validators.required])),
    street: new FormControl('', Validators.compose([Validators.required])),
    avenue: new FormControl('', Validators.compose([Validators.required])),
    landMark: new FormControl('', Validators.compose([Validators.required])),
    building: new FormControl('', Validators.compose([Validators.required])),
    flat: new FormControl('', Validators.compose([Validators.required])),
    notes: new FormControl('', Validators.compose([Validators.required])),
    id_number: new FormControl('',[Validators.required,Validators.pattern("^[0-9]{8,10}$"),Validators.minLength(8), Validators.maxLength(10)]),
  })
  @Output() triggerChangeAddress: EventEmitter<Number> = new EventEmitter<Number>();
  //new design issue
  saveAddress(shippingAddressForm:any) {    
this.arrayCheck()   
    this.configSettings.toggleLoading(true);
    this.userService.getUserSession().then(response => {      
        if (response !== false) {
            const id = this.address.address_id;
            if (this.address.block_id === 0) {
                this.address.block_id = null;
            }
            if (this.address.landmark == null) {
                this.address.landmark = '';
            }
            if (id)
            
            {
                this.checkoutService.updateAddress(response.id,this.address, this.store.iso_code).then(response => {
                    if (response.status == 200) {
                        this.address = response.data;
                        // this.router.navigate(['/shipping-address']);
                        this.checkoutService.setAddress(this.address);
                        this.checkoutService.setCart(this.cart);
                        this.proceedToPayment()
                        // document.getElementById('closeModalButton').click();
                        // this.router.navigate(['checkout']);
                    }
                    this.configSettings.toggleLoading(false);
                });
            }
         
            
            else {
                this.checkoutService.addAddress(response.id, this.address, this.store.iso_code).then(data => {
                    this.address = data;
                    this.configSettings.toggleLoading(false);
                    // this.router.navigate(['/shipping-address']);
                    this.checkoutService.setAddress(this.address);
                    this.checkoutService.setCart(this.cart);
                    // document.getElementById('closeModalButton').click();
                    this.triggerChangeAddress.emit(data.id);
                    shippingAddressForm.reset();
                    this.showForm=!this.showForm;
                    this.getUserAddress();
                    // this.router.navigate(['checkout']);
                });
            }
        }
    }, error => { this.configSettings.toggleLoading(false); });
}


getCountries() {
  this.configSettings.toggleLoading(true);
  this.checkoutService.getCountries().then(data => {
      this.countries = data.body.data;
      this.selectedCountry = this.countries[1];
      this.configSettings.toggleLoading(false);
  }, error => { this.configSettings.toggleLoading(false); });

}
selectedCountry: any;

getStates($country,value:any) {
  this.selectedCountry = $country;
  this.loadStates(value)
  if ($country.has_states === '1') {
    
      this.countryCode = '+' + $country.phonecode;
  } else {
      this.governorates = [];
  }
  // this.address.governorate_id = null;
  // this.address.area_id = null;
  // this.address.block_id = null;
}

loadStates($country) {
  this.configSettings.toggleLoading(true);
  this.checkoutService.getStates($country).then(data => {
      this.governorates = data.body.data;
      this.configSettings.toggleLoading(false);
  }, error => { this.configSettings.toggleLoading(false); });
}

getAreas($state) {
  this.loadAreas($state)
  if ($state.has_areas === '1') {
 
  } else {
      this.areas = [];
  }
  // this.address.area_id = null;
  // this.address.block_id = null;
}

loadAreas($state) {
  this.configSettings.toggleLoading(true);
  this.checkoutService.getAreas($state).then(data => {
      this.areas = data.body.data;      
      this.configSettings.toggleLoading(false);
  }, error => { this.configSettings.toggleLoading(false); });
}

getBlocks($city) {
  this.loadBlocks($city)
  if ($city.has_blocks === '1') {
     
  } else {
      this.sectors = [];
  }
  // this.address.block_id = null;
}

loadBlocks($city) {
  this.configSettings.toggleLoading(true);
  this.checkoutService.getBlocks($city).then(data => {
      this.sectors = data.body.data;
      this.configSettings.toggleLoading(false);
  }, error => { this.configSettings.toggleLoading(false); });
}
showAddAddress(){
  this.showForm=!this.showForm;
  this.address.address_id=null;
}





}
