<ng-container *ngIf="dataAvailable">
<section id="my-profile" class="user-account {{lang == 'ar' ? 'text-right' : ''}}" @listStagger>
  <div class="maintitle-Container">
    <h4 class="page-title text-center font-black font-condensed">{{'CHANGE PASSWORD' | translate}}</h4>
  </div>
  <section class="container">
    <div class="row m-sm-0">
      <div class="col-12 col-sm-12 col-md-3 user-left-menu d-none d-lg-block">
        <app-user-account-menu [page]="1"></app-user-account-menu>
      </div>
      <div class="col-12 col-sm-12 col-md-9 p-2 p-md-3 white-background">
        <p class="required">*{{'Required fields' | translate}}</p>

        <div [hidden]="!submitted">
            <p class="alert alert-success" [ngClass]="[hasError ? 'alert alert-danger' : 'alert alert-success']">
                {{message}}
            </p>
        </div>
        <form #changePasswordForm="ngForm" (ngSubmit)="onSubmit(changePasswordForm)">
          <div class="col-12 col-md-8 px-0">
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="form-group required">
                    <label for="currentInputPassword" class="control-label">{{'Current Password' | translate}}</label>
                    <input type="password" class="form-control text-input" [(ngModel)]="currentPassword" name="currentPassword" #currentPasswordParam="ngModel" id="currentInputPassword" required>
                    <small id="currentPasswordHelp" class="form-text form-validator_error" *ngIf="currentPasswordParam.touched && !currentPasswordParam.valid">
                        {{'Please enter your current password' | translate}}
                    </small>
                </div>
              </div>
              <div class="clearfix w-100"></div>
              <div class="col-12 col-md-6">
                <div class="form-group required">
                    <label for="newInputPassword" class="control-label">{{'New Password' | translate}}</label>
                    <input type="password" class="form-control text-input" [(ngModel)]="newPassword" name="newPassword" #newPasswordParam="ngModel" id="newInputPassword" required>
                    <small id="newPasswordHelp" class="form-text form-validator_error" *ngIf="newPasswordParam.touched && !newPasswordParam.valid">
                        {{'Please enter new password' | translate}}
                    </small>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group required">
                    <label for="confirmInputPassword" class="control-label">{{'Confirm Password' | translate}}</label>
                    <input type="password" class="form-control text-input" [(ngModel)]="confirmPassword" name="confirmPassword" #confirmPasswordParam="ngModel" id="confirmInputPassword" appConfirmPasswordValidator="newPassword" required>
                    <small id="confirmPasswordHelp" class="form-text form-validator_error" *ngIf="confirmPasswordParam.touched && !confirmPasswordParam.valid">
                        {{'Confirm password does not match new password' | translate}}
                    </small>
                    <small id="confirmPasswordHelp" class="form-text form-validator_error" *ngIf="confirmPasswordParam==''">
                      {{'Confirm password cannot be blank' | translate}}
                  </small>
                </div>
              </div>
            </div>
            <button class="btn btn-submit btn-dark button-large position-relative" [disabled]="!changePasswordForm.form.valid">
                {{'UPDATE PROFILE' | translate}}
                <i class="fas fa-lock icon btn-lock-icon"></i>
            </button>
          </div>
        </form>
      </div>
    </div>
  </section>
</section>
</ng-container>