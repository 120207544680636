import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HandleCartService {

  constructor() { }

  private subject = new BehaviorSubject<any>([]);

  sendItem(message: any) {
    this.subject.next(message);
  }

  receivedItem(): Observable<any> {
    return this.subject.asObservable();
  }
}
