<div class="my_account_data" *ngIf="skeletonLoader">
    <div class="container-fluid">
      <section class="main-profile" id="my-orders" >
        <div class="account_heading pt-3 pb-5">
          <ul class="d-flex flex-wrap align-items-center justify-content-center w-100 listing-page-routing">
            <li class="routing-list w-25">
              <ngx-skeleton-loader></ngx-skeleton-loader>   
            </li>
          </ul>
        </div>

        <div >
          <div class="d-flex w-100  justify-content-center w-100">
            <div class="user-left-menu d-none d-lg-block w-25">
              <div class="w-50" >
              <ngx-skeleton-loader count="8"></ngx-skeleton-loader>  
              </div>
            </div>
            <div class="user-profile-content w-100 w-lg-75">
              <div class="breadcrumb_heading">
                <h3 class="w-25">
                  <ngx-skeleton-loader ></ngx-skeleton-loader>   
                </h3>
              </div>
              <div >
                <div class="order_data">
                  <div class="order_data_border" *ngFor="let item of [1,2,3,4]; let i = index" id="order-{{i}}" style="cursor: pointer" >
                    <div class="d-flex gap-3 w-100">                      
                      <div class="order_product_data items-height-skeleton">
                        <ngx-skeleton-loader class="height-fix"></ngx-skeleton-loader>   
                      </div>
                      <div class="w-100 position-relative">
                        <div class="d-flex gap-3 w-100 justify-content-between">
                          <div class="product_order_data w-50">
                            <ngx-skeleton-loader></ngx-skeleton-loader>                        
                              <ngx-skeleton-loader></ngx-skeleton-loader>
                              <ngx-skeleton-loader></ngx-skeleton-loader>
                            <h5>
                              <ngx-skeleton-loader></ngx-skeleton-loader>                            
                            </h5>
                          </div>
                          <div class="order_status order_status-border w-25">
                              <ngx-skeleton-loader></ngx-skeleton-loader>                             
                          </div>
                        </div>
                        <div class="position-absolute bottom-0">
                        <ngx-skeleton-loader></ngx-skeleton-loader>  
                        </div>
                      </div>
                    </div>
                  </div>                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
<ng-container *ngIf="!skeletonLoader">
  <div class="my_account_data">
    <div class="container-fluid">
      <section class="main-profile" id="my-orders" @listStagger>
        <div class="account_heading pt-3 pb-5">
          <ul
            class="d-flex flex-wrap align-items-center justify-content-center w-100 listing-page-routing"
          >
            <li class="routing-list">
              <a [routerLink]="'/my-account'">
                {{ "my account" | translate }}
              </a>
            </li>
            <li class="routing-list">{{ "Track An Order" | translate }}</li>
          </ul>
        </div>

        <div *ngIf="orders">
          <div class="d-flex w-100 justify-content-center w-100">
            <div class="user-left-menu d-none d-lg-block w-25">
              <app-user-account-menu [page]="2"></app-user-account-menu>
            </div>
            <div class="user-profile-content w-100 w-lg-75">
              <div class="breadcrumb_heading">
                <h3><a [routerLink]="'/my-account'"> <img src="/assets/icons/right-arrow.svg" alt="arrow"></a> {{'Track An Order' | translate}}</h3>
              </div>
              <div *ngIf="!skeletonLoader">
                <div class="order_data">
                  <div class="order_data_border" *ngFor="let order of orders; let i = index" id="order-{{ order.id }}" style="cursor: pointer"
                  >
                    <div
                      class="d-flex gap-3 w-100"
                      (click)="goToDetails(order)"
                    >
                      <div class="order_product_data">
                        <div>
                          <div class="product_image">
                            <figure>
                              <div class="image-wrapper">
                                <img
                                  class="loaded"
                                  src="{{ order.items[0].image }}"
                                />
                              </div>
                            </figure>
                          </div>
                        </div>
                      </div>
                      <div class="w-100 position-relative">
                        <div class="d-flex gap-3 w-100 justify-content-between">
                          <div class="product_order_data">
                            <h4>#{{ order.order_number }}</h4>

                            <p>
                              <span>{{
                                currency
                              }}</span>
                              {{ order.total | number : "1.3-3" }}
                            </p>

                            <h5>
                              <!-- {{ "Order date:" | translate }} -->
                              {{ order.created_date | date : "dd MMM yyyy" }}
                            </h5>
                            <!-- <p>
                              {{ "Items:" | translate }}
                              {{ order.items.length }}
                            </p> -->
                          </div>
                          <div>
                            <p
                              class="order_status"
                              [ngStyle]="{ color: order.status_color }"
                            >
                              {{ order.status }}
                            </p>
                          
                          </div>
                        </div>

                        <div class="position-absolute bottom-0">
                          <span class="reorder" *ngIf="isQuantityAvailable(order)" (click)="reorder(order.id)">{{'Reorder' | translate}}</span>
                          <span class="out_of_stock" *ngIf="!isQuantityAvailable(order)">{{'
                            ' | translate}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                </div>

              </div>
              <!-- <div *ngIf="!orders.length" class="empty-cart">
                <div class="empty-cart-content white-container">
                  <h4 class="page-title font-bold text-center">
                    {{ "You currently have no orders" | translate }}
                  </h4>
                  <p class="font-regular text-center">
                    {{
                      "Here you'll find information related to your orders."
                        | translate
                    }}
                  </p>
                  <div class="text-center">
                    <a [routerLink]="'/home'" class="button_hover">{{
                      "CONTINUE SHOPPING" | translate
                    }}</a>
                  </div>
                </div>
              </div> -->
              <div *ngIf="!orders.length" class="empty-cart">
                <div class="empty-cart-content white-container">
                  <h4 class="page-title font-bold text-center">
                    {{ "You currently have no orders" | translate }}
                  </h4>
                  <p class="font-regular text-center">
                    {{"Here you'll find information related to your orders." | translate}}
                  </p>

                  <button type="button" class="button_hover" [routerLink]="'/home'">
                    {{ "CONTINUE SHOPPING" | translate }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</ng-container>
