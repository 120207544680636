import { Component, Input, OnInit } from '@angular/core';
import { Address } from "../models/address";
import { DeliveryOptions } from "../models/delivery-options";
import { CheckoutService } from "../config/checkout.service";
import { NavbarService } from "../config/navbar.service";
import { UserService } from "../config/user.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Order } from "../models/order";
import { PaymentOptions } from "../models/payment-options";
import { ConfigSettings } from "../config/config.settings";
import { Store } from "../models/store";
import { ConfigService } from "../config/config.service";
import { CartService } from '../config/cart.service';
import { listStagger } from '../animations';
import { FooterService } from '../config/footer.service';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { SupportDetails } from '../models/support-details';
import { AnalyticsService } from '../services/analytics.service';
import { User } from '../models/user'
import { TranslateService } from '@ngx-translate/core';
import { HeaderToggleService } from '../services/header-toggle.service';
import { ChangeMetaService } from '../services/change-meta-service.service';
declare var ackoo;

@Component({
  selector: 'app-order-confirmation',
  templateUrl: './order-confirmation.component.html',
  styleUrls: ['./order-confirmation.component.scss'],
  animations: [listStagger]
})
export class OrderConfirmationComponent implements OnInit {
  @Input() order: Order;
  @Input() payment: PaymentOptions;
  currency: string = '';
  lang: string;
  userId: string;
  store: Store;
  paymentMethod: string;
  paymentID: string;
  refId: string;
  paymentResult: string;
  trackId: string;
  transId: string;
  showPaymentDetails = false;
  dataAvailable = false;
  calledPurchaseEvent = false;
  support_email = '';
  support_phone = '';
  selectedAddress: any;
  orderId: any;
  userSession = new User();
  isMobile: any = false;
  skeltonTheme:any;
  skeletonLoader=true;
  subCategoryPage:boolean=false;
  constructor(
    private checkoutService: CheckoutService,
    private navbarService: NavbarService,
    private userService: UserService,
    private router: Router,
    private configSettings: ConfigSettings,
    private route: ActivatedRoute,
    private configService: ConfigService,
    private cartService: CartService,
    protected footerService: FooterService,
    protected localStorage: LocalStorage,
    private analytics: AnalyticsService,
    private translate:TranslateService,
    private headerToggleService: HeaderToggleService,
    private changeMeta: ChangeMetaService,


  ) { }

  ngOnInit() {
    this.isMobile = window.innerWidth < 600;
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

    this.lang = this.configSettings.getLang();
    this.navbarService.toggleMainNavigation(false);
    this.checkoutService.setStep(3);
    this.cartService.changCartCount(0);
    this.selectedAddress = this.configSettings.getShippingAddress();
    /* getting store from config*/
    this.configSettings.getStoreObject().then((result) => {
      this.store = <Store>result;
      // this.currency = result['currency_' + this.lang];
    });

    this.localStorage.getItem<any>('support_details').subscribe((result) => {
      if (result) {
        const SupportDetails = <SupportDetails>result;
        this.support_email = SupportDetails.support_email;
        this.support_phone = SupportDetails.support_phone;
      }
    });

    if (this.checkoutService.order) {
      this.order = this.checkoutService.order;
      this.orderId = this.order.order_id;
      console.log(this.orderId, 'order_id');
      this.payment = this.checkoutService.payment;
      this.currency = this.order.baseCurrencyName;
      this.dataAvailable = true;
      this.skeletonLoader=false;

      // console.log(this.checkoutService.order)

      ackoo.trackCheckout({
        orderId: this.checkoutService.order.order_number.toString(),
        amount: Number(this.checkoutService.order.grand_total_kwd),
        currency: "kwd",
      });

      const productIDs = [];
      this.order.items.forEach((item) => {
        productIDs.push(item.id.toString());
      });
      var itemArray = [];
      var googleItem = [];
      var itemIds = [];
      var itemNames = [];
      var itemCatgeories = [];
      var brandNames = [];
      var totalItemQuantity = 0;
      this.order.items.forEach(element => {
        itemArray.push({ 'id': element.id, 'name': element.name, 'quantity': element.quantity, 'sku': element.SKU });
        totalItemQuantity = totalItemQuantity + element.quantity;
        itemIds.push(element.id);
        brandNames.push(element.brand_name);
        itemCatgeories.push(element.marketing_category + ' - ' + element.marketing_subcategory)
        itemNames.push(element.name);
        googleItem.push({ 'item_id': element.id, 'item_name': element.name, 'quantity': element.quantity, 'price': element.final_price });
        //totalItemQuantity = totalItemQuantity+element.quantity;
      });

      if (!this.calledPurchaseEvent) {
        this.calledPurchaseEvent = true;
        this.analytics.purchase(
          productIDs,
          this.order.created_date,
          this.order.order_number.toString(),
          totalItemQuantity,
          JSON.stringify(itemArray),
          JSON.stringify(googleItem),
          String(this.transId),
          this.order.sub_total,
          this.order.delivery_charges,
          itemArray.length,
          itemIds,
          itemNames,
          itemCatgeories,
          brandNames,
          this.order.payment_mode,
          this.order
        );
      } 
      if (this.checkoutService.payment.code == 'C') {
        this.translate.get('Cash on delivery').subscribe((res: string) => {this.paymentMethod=res});
      }
      this.sendGTagEvent('purchase', this.order.items);
      setTimeout(() => { this.footerService.toggleFooter(true) }, 300);
    } else {
      
      this.route.queryParams.subscribe(params => {
        let id = (params['UDF1']) ? params['UDF1'] : '';
        this.paymentID = (params['PaymentID']) ? params['PaymentID'] : '';
        this.paymentResult = (params['Result']) ? params['Result'] : '';
        this.transId = (params['TranID']) ? params['TranID'] : '';
        this.refId = (params['Ref']) ? params['Ref'] : '';
        this.trackId = (params['TrackID']) ? params['TrackID'] : '';

        if (this.paymentID !== "") {
          this.showPaymentDetails = true;
        }

        if (id) {
          this.configSettings.toggleLoading(true);
          this.userService.getUserSession().then(user => {
            if (user !== false) {
              this.userId = user.id;
            }
            this.getOrderDetails(id);
          }, error => { this.configSettings.toggleLoading(false); });
        } else {
          this.router.navigate(['/home']);
        }
      });
    }
    this.checkUserSession();
    this.skeltonTheme = this.setThemeLogic();
    this.changeMeta.updateMetaTags('Order Confirmation', "Everything on your Wish List!' Discover and shop a variety of pieces ranging from clothes, bags, shoes, to accessories and more.")
  }
  setThemeLogic() {
    let mobTheme = {
      'border-radius': '5px',
      'height': '100px',
      'animation-duration': '2s',
      'background-color': '#F1F1F1'
    };
    let deskTheme = {
      'border-radius': '5px',
      'height': '300px',
      'animation-duration': '2s',
      'background-color': '#F1F1F1'
    };
    if (this.isMobile) {
      return mobTheme;
    } else {
      return deskTheme;
    }
  }
  checkUserSession() {
    this.userService.getUserSession().then(response => {
      if (response) {
        this.userSession.id = response.id;
        this.userId = response.id;
        this.userSession.first_name = response.first_name;
        this.userSession.last_name = response.last_name;
        this.userSession.email = response.email;

        ackoo.identify(response.id, {
          name: response.first_name,
          email: response.email,
        });

      }
    });
  }

  getOrderDetails(id) {
    const params = {
      id: id,
      user_id: this.userId,
      lang: this.lang,
      store: this.store.iso_code
    };
    this.configService.readRequest('order-details', params)
      .subscribe(response => {
        this.order = response.body.data;
        this.orderId = this.order.id;
        this.skeletonLoader=false;
        ackoo.trackCheckout({
          orderId: this.order.order_number.toString(),
          amount: Number(this.order.grand_total_kwd),
          currency: "kwd",
        });
        console.log(this.checkoutService,'checkoutService');      
        console.log(this.order,'order');      
        this.currency=response.body.data.address_currency_code;
        if (this.order.payment_mode == 'C') {
          this.translate.get('Cash on delivery').subscribe((res: string) => {this.paymentMethod=res});
        } else if (this.order.payment_mode == 'K') {
          this.translate.get('Knet').subscribe((res: string) => {this.paymentMethod=res});
        } else if (this.order.payment_mode == 'CC') {
          this.translate.get('Visa/MasterCard').subscribe((res: string) => {this.paymentMethod=res});
        } else if (this.order.payment_mode == 'M') {
          this.translate.get('My Fatoorah').subscribe((res: string) => {this.paymentMethod=res});
        } else if (this.order.payment_mode == 'S') {
          this.translate.get('Sadad').subscribe((res: string) => {this.paymentMethod=res});
        } else if (this.order.payment_mode == 'NP') {
          this.translate.get('Qatar Debit Cards').subscribe((res: string) => {this.paymentMethod=res});
        } else if (this.order.payment_mode == 'B') {
          this.translate.get('Benefit').subscribe((res: string) => {this.paymentMethod=res});
        } else if (this.order.payment_mode == 'UAECC') {
          this.translate.get('Uae Debit Cards').subscribe((res: string) => {this.paymentMethod=res});
        } else if (this.order.payment_mode == 'W') {
          this.paymentMethod = '';
        } else if (this.order.payment_mode == 'TP1') {
          if (this.store.id == 1) {
          this.translate.get('Buy Now & Pay within 14 Days: No Fees').subscribe((res: string) => {this.paymentMethod=res});
          } else {
          this.translate.get('Buy Now & Pay within 14 Days: No Fees').subscribe((res: string) => {this.paymentMethod=res});

          }
        } else if (this.order.payment_mode == 'TP2') {
          if (this.store.id == 1) {
          this.translate.get('Pay in 3 Installments: No Fees').subscribe((res: string) => {this.paymentMethod=res});

          } else {
          this.translate.get('Pay in 3 Installments: No Fees').subscribe((res: string) => {this.paymentMethod=res});

          }
        } else if (this.order.payment_mode == 'TP3') {
          if (this.store.id == 1) {
          this.translate.get('Pay in 4 Installments: No Fees ').subscribe((res: string) => {this.paymentMethod=res});

          } else {
          this.translate.get('Pay in 4 Installments: No Fees').subscribe((res: string) => {this.paymentMethod=res});

          }
        } else if (this.order.payment_mode == 'TP4') {
          if (this.store.id == 1) {
          this.translate.get('Pay in 5 Installments: No Fees ').subscribe((res: string) => {this.paymentMethod=res});

          } else {
          this.translate.get('Pay in 5 Installments: No Fees ').subscribe((res: string) => {this.paymentMethod=res});

          }
        }

        this.configSettings.toggleLoading(false);
        this.dataAvailable = true;
        if (this.paymentResult === 'CAPTURED') {
          const productIDs = [];
          this.order.items.forEach((item) => {
            productIDs.push(item.id.toString());
          });

          var itemArray = [];
          var googleItem = [];
          var itemIds = [];
          var itemNames = [];
          var itemCatgeories = [];
          var brandNames = [];
          var totalItemQuantity = 0;
          this.order.items.forEach(element => {
            itemArray.push({ 'id': element.id, 'name': element.name, 'quantity': element.quantity, 'sku': element.SKU });
            totalItemQuantity = totalItemQuantity + element.quantity;
            itemIds.push(element.id);
            brandNames.push(element.brand_name);
            itemCatgeories.push(element.marketing_category + ' - ' + element.marketing_subcategory)
            itemNames.push(element.name);
            googleItem.push({ 'item_id': element.id, 'item_name': element.name, 'quantity': element.quantity, 'price': element.final_price });
            //totalItemQuantity = totalItemQuantity+element.quantity;
          });
          if (!this.calledPurchaseEvent) {
            this.calledPurchaseEvent = true;
            this.analytics.purchase(
              productIDs,
              this.order.created_date,
              this.order.order_number.toString(),
              totalItemQuantity,
              JSON.stringify(itemArray),
              JSON.stringify(googleItem),
              String(this.transId),
              this.order.sub_total,
              this.order.delivery_charges,
              itemArray.length,
              itemIds,
              itemNames,
              itemCatgeories,
              brandNames,
              this.order.payment_mode,
              this.order
            );
          }
          this.sendGTagEvent('purchase', this.order.items);
        }
        setTimeout(() => { this.footerService.toggleFooter(true) }, 300);
      });
  }

  sendGTagEvent(event, items: any) {
    const tmp = [];
    items.forEach(function (item, key) {
      const final_price = item.final_price_kwd * 3.30;
      tmp.push({
        id: item.SKU,
        name: item.name,
        list_name: "Product Details",
        brand: item.brand_name,
        category: item.brand_name,
        variant: "",
        list_position: 1,
        quantity: item.quantity,
        price: final_price.toFixed(2).toString()
      });
    });

    /*this.winRef.nativeWindow.dataLayer.push({
        'event': 'checkout',
        'ecommerce': {
            'checkout': {
                'actionField': {'step': 1, 'option': 'Visa'},
                'products': tmp
            }
        }
    });*/
    let grand_total_kwd = +this.order.grand_total_kwd;
    grand_total_kwd = grand_total_kwd * 3.30;
    this.configService.sendGTagEvent(event, {
      "transaction_id": this.order.order_number.toString(),
      "affiliation": "Online store",
      "value": parseFloat(grand_total_kwd.toFixed(2)),
      // "value": 0,
      'currency': 'USD',
      'tax': +this.order.vat_charges,
      'shipping': +this.order.delivery_charges,
      items: tmp
    });
  }

  navigateHome() {
    this.navbarService.toggleMainNavigation(true);
    this.router.navigate(['/home'])
  }

  scrollToTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

    // toggle header and nav bars
    activateHeaderFromBanners() {
      this.navbarService.isEnableHeaderBottomAction(true);
      this.navbarService.isEnableHeaderBottomMobileAction(true);
      this.navbarService.toggleMainNavigation(true);
      this.configSettings.setNavBarStatus(true)
      this.headerToggleService.setNavItems(true)
      this.router.navigate(['/home'])
    }
    ngOnDestroy() {
      this.changeMeta.updateMetaTags('The Wishlist | Discover fashion online!', "Everything on your Wish List!' Discover and shop a variety of pieces ranging from clothes, bags, shoes, to accessories and more.")
    }

}
