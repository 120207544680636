<div class="sharePopup" *ngIf="hideShare">
    <div class="Popup-Share">
      <div class="card text-center">
        <div class="Share-Heading">
          <!-- <h2>{{'Share' | translate}}</h2> -->
          <span class="Share-Close" (click)="share()">
            <img rel="preload"  src="/assets/icons/cross_popup.svg" alt="close button icon">
          </span>
        </div>
        <div class="card-body">
          <share-buttons [theme]="'circles-dark'" [include]="['copy','facebook','twitter','telegram','messenger','whatsapp','sms','email']" [show]="8"
            [size]="2" [showText]="false" [url]="branchIoLink" [image]="this.data?.image" [autoSetMeta]="false">
          </share-buttons>
        </div>
      </div>
    </div>
  
  </div>