import { BrowserModule, Meta } from '@angular/platform-browser';
import  { NgModule } from "@angular/core";
import { SafeHtmlPipe } from './pipes/SafeHtmlPipe';
import { AppComponent } from './app.component';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientJsonpModule, HttpClientModule} from '@angular/common/http';
import { LoginComponent } from './login/login.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { AppRoutingModule } from './/app-routing.module';
import { HeaderUserActionsComponent } from './header-user-actions/header-user-actions.component';
import {ConfigSettings} from './config/config.settings';
import { HeaderComponent } from './header/header.component';
import { SideUserActionsComponent } from './side-user-actions/side-user-actions.component';
import {DragScrollModule} from  'ngx-drag-scroll';
import { ProductListComponent } from './product-list/product-list.component';
import { ProductDetailComponent } from './product-detail/product-detail.component';
import { CartComponent } from './cart/cart.component';
import { CategoryListComponent } from './category-list/category-list.component';
import { BrandListComponent } from './brand-list/brand-list.component';
import { WishlistComponent } from './wishlist/wishlist.component';
import { MyOrdersComponent } from './my-orders/my-orders.component';
import { OrderListComponent } from './order-list/order-list.component';
import { MyAccountComponent } from './my-account/my-account.component';
import { MyAddressComponent } from './my-address/my-address.component';
import { RegisterComponent } from './register/register.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FooterComponent } from './footer/footer.component';
import { CheckoutProgressComponent } from './checkout-progress/checkout-progress.component';
import { CmsComponent } from './cms/cms.component';
import { FaqComponent } from './faq/faq.component';
import { ProductItemComponent } from './product-item/product-item.component';
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import {  MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatTabsModule } from "@angular/material/tabs";
import { AddedToCartDialogComponent } from './added-to-cart-dialog/added-to-cart-dialog.component';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import { NgxPopper } from 'angular-popper';
import { OrderConfirmationComponent } from './order-confirmation/order-confirmation.component';
import { UserAccountMenuComponent } from './user-account-menu/user-account-menu.component';
import { ClickOutsideModule } from 'ng-click-outside';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { LoadingComponent } from './loading/loading.component';
import { DialogComponent } from './dialog/dialog.component';
import { CartSummaryComponent } from './cart-summary/cart-summary.component';
import { ReferAFriendComponent } from './refer-a-friend/refer-a-friend.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import {ConfirmPasswordValidatorDirective} from './shared/confirm-password-validator.directive';
import { OrderDetailComponent } from './order-detail/order-detail.component';
import { SocialLoginModule, SocialAuthServiceConfig  } from "@abacritt/angularx-social-login";
import { FacebookLoginProvider,GoogleLoginProvider } from "@abacritt/angularx-social-login";
import {AddressComponent} from "./address/address.component";
import { DeepLinkComponent } from './deep-link/deep-link.component';
import { ReturnRequestListComponent } from './return-request-list/return-request-list.component';
import { RequestReturnComponent } from './request-return/request-return.component';
import { ReturnRequestDetailComponent } from './return-request-detail/return-request-detail.component';
import { TopHelpComponent } from './top-help/top-help.component';
import {NgxPaginationModule} from "ngx-pagination";
import { AccountComponent } from './account/account.component';
import { ShopListComponent } from './shop-list/shop-list.component';
import { StorageModule } from '@ngx-pwa/local-storage';
import { NguiInViewComponent } from './ngui-in-view.component';
import {GtagModule} from 'angular-gtag';
import { HomeV2Component } from './home-v2/home-v2.component';
import { WalletCreditComponent } from './wallet-credit/wallet-credit.component';
import { PaymentV2Component } from './payment-v2/payment-v2.component';
import {ToastrModule} from "ngx-toastr";
import {MyHttpInterceptor} from "./interceptors/MyHttpInterceptor";
import { AddressbookComponent } from './addressbook/addressbook.component';
import { ProductitemComponent } from './productitem/productitem.component';
import { CartSummaryV2Component } from './cart-summary-v2/cart-summary-v2.component';
import { ProdctItemV2Component } from './prodct-item-v2/prodct-item-v2.component';
import { AddShippingAddressComponent } from './add-shipping-address/add-shipping-address.component';
import { MobileShopTheLookModule } from './components/mobile-shop-the-look/mobile-shop-the-look.module';
import { MobileShopTheLookComponent } from './components/mobile-shop-the-look/mobile-shop-the-look.component';
import { HomeV3Component } from './home-v3/home-v3.component';
import { CustomPipesModule } from './pipes/custom-pipes.module';
import { APP_BASE_HREF } from '@angular/common';
import { ClickToFavDirective } from './directives/click-to-fav.directive';
import { RouterModule } from '@angular/router';
import { ShowPassDirective } from './show-pass.directive';
import { MyDetailsComponent } from './my-details/my-details.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
// import { MatSliderModule, MatTooltipModule } from '@angular/material';
import { MatSliderModule } from '@angular/material/slider'
import {MatTooltipModule} from '@angular/material/tooltip'
import { CommonModule } from '@angular/common';
// import { SlickCarouselModule } from 'ngx-slick-carousel-carousel';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { AddToBagPopupComponent } from './add-to-bag-popup/add-to-bag-popup.component';
import { ShopLookComponent } from './shop-look/shop-look.component';
import { ShopTheLookSideBarComponent } from './shop-the-look-side-bar/shop-the-look-side-bar.component';
import { EventEditComponent } from './components/event-edit/event-edit.component';
import { CenterModeSliderComponent } from './components/center-mode-slider/center-mode-slider.component';
import { BrandInfoComponent } from './components/brand-info/brand-info.component';
import { GridViewSliderComponent } from './grid-view-slider/grid-view-slider.component';
import { SetActiveAlphabetsDirective } from './directives/set-active-alphabets.directive';
import { ClickToFavBrandsDirective } from './directives/click-to-fav-brands.directive';
import { MyWishlistComponent } from './my-wishlist/my-wishlist.component';
import { ReviewPopupComponent } from './payment-v2/review-popup/review-popup.component';
import { LoyaltyPointComponent } from './loyalty-point/loyalty-point.component';
import { TermsConditionsPopupComponent } from './terms-conditions-popup/terms-conditions-popup.component';
import { NewAddressPopupComponent } from './components/new-address-popup/new-address-popup.component';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { SharePopupComponent } from './share-popup/share-popup.component';
import { MyBrandListComponent } from './my-brand-list/my-brand-list.component';
import { ClickToFavBrandsListingDirective } from './directives/click-to-fav-brands-listing.directive';
let lang =(localStorage.getItem('lang')!)
var url = location.href;
let str = url;

if (str.includes("/en")) {
  localStorage.removeItem('lang')
  lang = 'en'
  localStorage.setItem('lang',lang)
}
if (str.includes("/ar")) {
  localStorage.removeItem('lang')
  lang = 'ar'
  localStorage.setItem('lang',lang)
}
if (lang == null || lang == undefined) {
  localStorage.removeItem('lang')
  lang = 'en'
  localStorage.setItem('lang',lang)
}

// const AppCryptConfigProvider: CryptConfigProvider = {
//     getSalt(): Promise<string> {
//         return Promise.resolve(ConfigSettings.ENCRYPT_KEY);
//     }
// };

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

// let config = new AuthServiceConfig([
//     {
//       id: FacebookLoginProvider.PROVIDER_ID,
//       provider: new FacebookLoginProvider("2167102230242088") //202012753892439
//     },
// ]);

// export function provideConfig() {
//     return config;
// }

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        HeaderUserActionsComponent,
        HeaderComponent,
        SideUserActionsComponent,
        ProductListComponent,
        ProductDetailComponent,
        CartComponent,
        CategoryListComponent,
        BrandListComponent,
        WishlistComponent,
        MyOrdersComponent,
        OrderListComponent,
        MobileShopTheLookComponent,
        MyAccountComponent,
        MyAddressComponent,
        RegisterComponent,
        FooterComponent,
        CmsComponent,
        SafeHtmlPipe,
        FaqComponent,
        CheckoutProgressComponent,
        AddShippingAddressComponent,
        ProductItemComponent,
        AddedToCartDialogComponent,
        OrderConfirmationComponent,
        UserAccountMenuComponent,
        ChangePasswordComponent,
        LoadingComponent,
        DialogComponent,
        //CartSummaryComponent,
        ForgotPasswordComponent,
        ConfirmPasswordValidatorDirective,
        ClickToFavDirective,
        ClickToFavBrandsListingDirective,
        EventEditComponent,
        CenterModeSliderComponent,
        BrandInfoComponent,
        CartSummaryComponent,
        ProdctItemV2Component,
        OrderDetailComponent,
        AddressComponent,
        DeepLinkComponent,
        ProductitemComponent,
        ReturnRequestListComponent,
        RequestReturnComponent,
        ReturnRequestDetailComponent,
        TopHelpComponent,
        AccountComponent,
        ShopListComponent,
        NguiInViewComponent,
        HomeV2Component,
        WalletCreditComponent,
        PaymentV2Component,
        ReferAFriendComponent,
        AddressbookComponent,
        CartSummaryV2Component,
        HomeV3Component,
        ShowPassDirective,
        MyDetailsComponent,
        AddToBagPopupComponent,
        TermsConditionsPopupComponent,
        NewAddressPopupComponent,
        ShopLookComponent,
        ShopTheLookSideBarComponent,
        GridViewSliderComponent,
        SetActiveAlphabetsDirective,
        ClickToFavBrandsDirective,
        MyWishlistComponent,
        ReviewPopupComponent,
        LoyaltyPointComponent,
        SharePopupComponent,
        MyBrandListComponent
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'serverApp' }),
        CommonModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        HttpClientModule,
        HttpClientJsonpModule,
        AppRoutingModule,
        DragScrollModule,
        NgSelectModule,
        MatDialogModule,
        MobileShopTheLookModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatAutocompleteModule,
        MatProgressSpinnerModule,
        MatFormFieldModule,
        MatTooltipModule,
        MatSliderModule,
        MatTabsModule,
        MatSnackBarModule,
        BrowserAnimationsModule,
        MatInputModule,
        CustomPipesModule,
        //BrowserAnimationsModule,
        //NoopAnimationsModule,
        FormsModule,
        NgxPopper,
        ClickOutsideModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        SocialLoginModule,
        SlickCarouselModule,
        NgxPaginationModule,
        StorageModule.forRoot({ IDBNoWrap: false }),
        GtagModule.forRoot({ trackingId: 'UA-151209487-1', trackPageviews: true }),
        ToastrModule.forRoot(),
        NgxSkeletonLoaderModule,
        ShareButtonsModule,
        ShareIconsModule,
    ],
    providers: [
        Meta,
        // {provide: CRYPT_CONFIG_PROVIDER, useValue: AppCryptConfigProvider},
        { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: true } },
        {
            // provide: AuthServiceConfig,
            // useFactory: provideConfig    
            provide: 'SocialAuthServiceConfig',
            useValue: {
                autoLogin: false,
                providers: [
                    {
                      id: GoogleLoginProvider.PROVIDER_ID,
                      provider: new GoogleLoginProvider('661971138133-6bj5hpb8r817u4aqkq543p5mm7j3d8sd.apps.googleusercontent.com')
                    },
                    {
                        id: FacebookLoginProvider.PROVIDER_ID,
                        provider: new FacebookLoginProvider('1304958190387163') 
                    }
                ],onError: (err) => {
                    console.error(err);
                  }
            } as SocialAuthServiceConfig,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MyHttpInterceptor,
            multi: true
        },
        {
            provide: APP_BASE_HREF,
            useValue: '/' + lang
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }

