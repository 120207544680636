import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import {Observable} from 'rxjs';
import CryptoJS from 'crypto-js'
import { ConfigSettings } from '../config/config.settings';
import { finalize, tap } from 'rxjs/operators';
@Injectable()
export class MyHttpInterceptor implements HttpInterceptor {

  activeRequests: number = 0;
  skippUrls = [
    '/suggestions',
  ];

  constructor(
    private configSettings: ConfigSettings,
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {    
    const key = this.getHeaderKey();
    const authReq = req.clone({
      headers: req.headers
          .set('Content-Type', 'application/json')
          .set('authtoken', key),
      // setHeaders: {
      //   "Permissions-Policy": "camera=*,geolocation=*,microphone=*,autoplay=*,fullscreen=*,picture-in-picture=*,sync-xhr=*,encrypted-media=*,oversized-images=*",
      //   "Strict-Transport-Security": "max-age=31536000; includeSubdomains",
      //   "X-Frame-Options": "SAMEORIGIN",
      //   "X-Content-Type-Options": "nosniff",
      //   "X-Xss-Protection": "1; mode=block",
      //   "Content-Security-Policy": "script-src https: 'unsafe-inline' 'unsafe-eval';style-src https: 'unsafe-inline' 'unsafe-eval';img-src https: data:;font-src https: data:;"
      // }
    });
    // return next.handle(authReq) as any;
    let displayLoadingScreen = true;    
    for (const skippUrl of this.skippUrls) {
      if (new RegExp(skippUrl).test(authReq.url)) {
        displayLoadingScreen = false;
        return next.handle(authReq);
        // break;
      }
    }
    if(req.url.includes('/add-to-cart') || req.url.includes('/cart-items') && this.configSettings.disableLoader>1){
      displayLoadingScreen = false;
      return next.handle(authReq);
      // break;
    }else{
      displayLoadingScreen = true;
      this.configSettings.disableLoader=1;
    }
  
    if (displayLoadingScreen) {     
      if (this.activeRequests === 0) {
        this.configSettings.startLoading();
      }
      this.activeRequests++;
      return next.handle(authReq).pipe(tap(() => {
      }, (err: any) => {
        console.log('Error:', err);
      }),
        finalize(() => {
          this.activeRequests--;
          if(req.url.includes('/checkout') && req.body.pay_mode!='C'){
            setTimeout(() => {
              this.configSettings.stopLoading();              
            }, 3000);
          }else if (this.activeRequests === 0) {
            this.configSettings.stopLoading();
          }
        })
      )
    } else {
      return next.handle(authReq);
    }
  }

  getHeaderKey() {
    let timeStamp = Math.floor(((Date.now()) / 1000) + (17 * 60));
    const authToken = '89c7c430241a4b0a6583b5558caf12cd399006617031690838de23ad78a91326';
    const deviceType = 'WEB';
    const key = timeStamp + '###' + authToken + '###' + deviceType;
    return this.aesEncrypt(key);
  }

  aesEncrypt(data) {
    let iv = '6498hfvuyr82623a';
    let key = 'vz178pldcutk2ez4dzo3askdfbak32re';
    let cipher = CryptoJS.AES.encrypt(data, CryptoJS.enc.Utf8.parse(key), {
      iv: CryptoJS.enc.Utf8.parse(iv),
        padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC
    });
    return cipher.toString();
  }

}
