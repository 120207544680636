import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ConfigService } from '../config/config.service';

@Injectable({
  providedIn: 'root'
})
export class LoyaltyService {

  constructor(
    private configService: ConfigService
  ) { }

  getHistory(url,params){
    return this.configService.readRequest('users-points-history',params)
    .pipe(map(response => {
        return response;
    }));
  }

}
