
        <!-- <p class="event-edit-header">The Event Edit</p> -->
        <div class="event-main-image" #eventMainImage (scroll)="onEventMainImageScroll($event)">
            <div class="event-product" (click)="routing(item)" *ngFor="let item of eventData">
                <figure>
                    <div class="event-div-sec">
                        <img [src]="item?.image" [alt]="item?.name">
                    </div>
                    <figcaption>
                        <p class="event-name">{{item?.name}}</p>
                    </figcaption>
                </figure>
            </div>
        </div>
        <div class="range-bar-section" *ngIf="isRangeBarVisible">
            <input class="range-bar" #rangeBar type="range" max="100" value="0">
        </div>
