<div class="modal fade" id="modelAddToBag" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="header-added-to">
                <p class="header-text">
                    {{'Select Size / Color' | translate}}
                </p>
                <img src="../../assets/icons/cross_popup.svg" alt="" data-bs-dismiss="modal" aria-label="Close"
                    id="closeAddToBagMoal" (click)="clearAll()">
            </div>
            <div class="model-body d-grid popup-section">
                <div class="product-image-bag">
                    <figure>
                        <div class="image-wrapper">
                            <img [src]="product?.image" alt="">
                        </div>
                    </figure>

                </div>
                <div class="product-details">
                    <div class="pb-1 pb-md-2">
                        <p class="brand-name">{{product?.brand_name}}</p>
                        <p class="product-name"> {{product?.name}} </p>
                    </div>
                    <div>
                        <div class="color-text d-flex gap-2 pb-1 pb-md-2">
                            <p class="color-label">{{'COLOR' | translate}}:</p>
                            <p class="color-name">{{selectedColorText | uppercase}}</p>
                        </div>
                        <div class="d-flex gap-2 pb-1 pb-md-2">
                            <div class="circle-main" (click)="setColor(color,productColorObj)"
                                [id]="'color'+color?.option_id" *ngFor="let color of productColorObj?.attributes">
                                <span class="circle" [style.background]="color?.value?.toLowerCase()"></span>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="d-flex justify-content-between align-items-center size-EX pb-1 pb-md-2">
                            <div class="d-flex align-items-center gap-2 ">
                                <p class="">{{'SIZE:' | translate}}</p>
                                <!-- <span class="size-conf">S</span> -->
                            </div>
                            <!-- <p class="view-size"><a>{{'size guide' | translate}}</a></p> -->
                        </div>
                        <div class="d-flex flex-wrap size-sec mb-1 mb-md-2"
                            *ngIf="productSizeObj && productSizeObj.attributes && productSizeObj.attributes.length > 0">
                            <div class="size-box" *ngFor="let size of productSizeObj.attributes"
                                [ngClass]="{ 'out-of-stock': size?.is_available == 0 }"
                                (click)="setSize(size,productSizeObj)" [id]="'size'+size?.option_id">
                                <p>{{size?.value}}</p>
                            </div>
                        </div>
                    </div>
                    <!-- <div >
                        <div class="d-flex justify-content-between align-items-center size-EX pb-2">
                            <div class="d-flex align-items-center gap-2 ">
                                <p class="">Age:</p>
                                <span class="size-conf"></span>
                            </div>
                            <p class="view-size"><a>age guide</a></p>
                        </div>
                        <div class="d-grid size-sec mb-2"
                            *ngIf="productSizeObj && productSizeObj.attributes && productSizeObj.attributes.length > 0">
                            <div class="age-box" title="This is a
                                        multiline title" [ngClass]="{ 'out-of-stock': age?.is_available == 0 }"
                                 [id]="'age'+age?.option_id"
                                *ngFor="let age of productAgeObj.attributes">
                                <p>{{age?.value}}</p>
                            </div>
                        </div>
                    </div> -->

                    <div class="pt-md-2">
                        <button type="button" class="custom-button btn btn-black btn-block" (click)="addToBag()"
                            *ngIf="product?.remaining_quantity > 0">
                           {{'ADD TO BAG' | translate | uppercase}}
                        </button>
                        <button type="button" class="custom-button btn btn-black btn-block" data-toggle="modal"
                            data-target="#notifyMeModal1" (click)="closeBag()"
                            *ngIf="product?.is_preorder == 0 && product?.remaining_quantity <= 0">
                            <img src="">{{'Notify me' | translate | uppercase}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- NOTIFY ME MODAL STARTS -->

<div class="modal fade" id="notifyMeModal1" tabindex="-1" role="dialog" aria-labelledby="notifyMeModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content model-design">
            <div class="edit_data">
                <div class="edit_data_header">
                    <div class="cross-div">
                        <img class="cross_popup" src="/assets/icons/cross_popup.svg" alt="Cross" data-dismiss="modal"
                            data-target="#notifyMeModal1" id="dismissNotifyModal">
                    </div>
                    <div class="d-flex align-items-center justify-content-center">
                        <h1>{{ "Notify Me" | translate }}</h1>
                    </div>
                    <p class="edit-text">{{'Fill in the details below & you will be notified via email once the product
                        is back in stock' |translate}}.</p>

                </div>
                <form [formGroup]="notifyMeForm" autocomplete="off" (ngSubmit)="submitNotifyMe()">
                    <div class="edit_data_body">
                        <div>
                            <label [class.text-right]="lang == 'ar'">{{'Full Name' | translate}}<span>*</span></label>
                            <input type="text" name="name" formControlName="name" required>
                            <small class="text-danger"
                                *ngIf="notifyMeSubmitted && notifyMeForm.controls['name'].errors?.required">
                                {{'Please enter your full name' | translate}}</small>
                        </div>
                        <div>
                            <label [class.text-right]="lang == 'ar'">{{'Email Address' |
                                translate}}<span>*</span></label>
                            <input type="email" name="email" formControlName="email" required>
                            <small class="text-danger"
                                *ngIf="notifyMeSubmitted && notifyMeForm.controls['email'].errors?.required">{{'Please
                                enter your email' | translate}}</small>
                            <small class="text-danger"
                                *ngIf="notifyMeForm.controls['email'].hasError('requiredPattern')">
                                {{'Please enter your email address' | translate}}</small>
                        </div>
                        <div>
                            <label [class.text-right]="lang == 'ar'">{{'Mobile Number' |
                                translate}}<span>*</span></label>
                            <div class="d-flex gap-3">
                                <input class="w-25" disabled placeholder={{countryCode}}>
                                <input type="text" class="w-75" name="phone" formControlName="phone" maxlength="10"
                                    minlength="5" required (keypress)="validatePhone($event)">
                                <small class="text-danger"
                                    *ngIf="notifyMeSubmitted && notifyMeForm.controls['phone'].errors?.required">
                                    {{'Please enter your mobile number' | translate}}</small>
                                <small class="text-danger"
                                    *ngIf="notifyMeForm.controls['phone'].hasError('requiredPattern')">
                                    {{'Please enter a valid mobile number' | translate}}</small>
                            </div>
                        </div>
                    </div>
                    <div class="edit_data_footer text-center">
                        <button type="submit" [ngClass]="{'disable-button-styles':!notifyMeForm.valid}"
                            [disabled]="!notifyMeForm.valid">{{'SUBMIT' | translate}}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<!-- NOTIFY ME MODAL ENDS -->