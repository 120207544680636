<img src="{{data.value}}" *ngIf="data.image" class="img-fluid" />
<div class="dilog-div row justify-content-center h-100 position-relative align-items-center my-popup m-0" *ngIf="data.images">
    <div class="d-none d-md-block col-md-2 col-lg-1 thumb slider-thumb-popup">
        <div *ngFor="let image of data.images;let i = index;">
            <img src="{{image}}" class="img-fluid">
        </div>
    </div>
    <div class="col-12 col-md-8 col-lg-4 col-xl-4 slider-gallery slick-gallery float-right">
        <figure *ngFor="let image of data.images;let i = index;">
            <div class="image-wrapper" >
                <img  src="{{image}}" class="">
            </div>
        </figure> 
    </div>
    <div class="close-popup" (click)="closeModal()">
        
        <img src="../../assets/icons/cross_popup.svg" alt="">
    </div>
</div>
<div class="dilog-div" *ngIf="data.cancelOrder" id="cancel-order-dialog">
    <h4 class="font-medium font-bold text-center mb-2">{{'CANCELLATION REASON' | translate}}</h4>
    <span class="d-block font-regular font-small mb-1 custom-radio">
        <input type="radio" [(ngModel)]="reaon" value="Wrong address" id="radio_1">
        <label for="radio_1"><i class="radio-icon"></i> {{'Wrong address' | translate}}</label>
    </span>
    <span class="d-block font-regular font-small mb-2 custom-radio">
        <input type="radio" [(ngModel)]="reaon" value="Wrong quantity" id="radio_2">
        <label for="radio_2"><i class="radio-icon"></i> {{'Wrong quantity' | translate}}</label>
    </span>
    <span class="d-block font-regular font-small mb-2 custom-radio">
        <input type="radio" [(ngModel)]="reaon" value="Payment method is not suitable for me" id="radio_3">
        <label for="radio_3"><i class="radio-icon"></i> {{'Payment method is not suitable for me' | translate}}</label>
    </span>
    <span class="d-block font-regular font-small mb-2 custom-radio">
        <input type="radio" [(ngModel)]="reaon" value="I don't want this item anymore" id="radio_4">
        <label for="radio_4"><i class="radio-icon"></i> {{"I don't want this item anymore" | translate}}</label>
    </span>
    <span class="d-block font-regular font-small mb-3 custom-radio">
        <input type="radio" [(ngModel)]="reaon" value="OR" id="radio_5">
        <label for="radio_5"><i class="radio-icon"></i> {{'Other reasons' | translate}}</label>
    </span>
    <div class="mt-4 mb-4 returnInfo" *ngIf="reaon === 'OR'">
        <textarea class="form-control" rows="4" placeholder="{{'Reason for cancellation*' | translate}}"
            [(ngModel)]="requestReasonText" name="cancel-reason"></textarea>
    </div>
    <div class="d-flex gap-3">
        <div class="w-50">
            <button type="button" class="custom-button btn btn-dark btn-block " (click)="closeDialog()">{{'Cancel' |
                translate}}</button>
        </div>
        <div class="w-50">
            <button type="button" id="btn_order_ok" class="custom-button btn btn-dark btn-block button_hover"
                [disabled]="(!reaon || disableCancelSubmit)" [ngClass]="{'disabled': (!reaon)}"
                (click)="cancelOrder()">{{'OK' | translate}}</button>
        </div>
    </div>
</div>

<div class=" dilog-div" *ngIf="data.logout" id="logout-dialog">
    <h4 class="font-medium font-bold text-center mb-2">{{'Hello' | translate}} {{user?.first_name}}</h4>
    <p class="pb-3">{{'Are you sure you want to logout?' | translate}}</p>
    <div class="d-flex gap-3">
        <div class="w-50">
            <button type="button" class="custom-button btn btn-bgnone btn-block" (click)="closeDialog()">{{'Cancel' |
                translate}}</button>
        </div>
        <div class="w-50">
            <button type="button" class="custom-button btn btn-bg btn-block button_hover" (click)="logout()">{{'OK' |
                translate}}</button>
        </div>
    </div>
</div>

<div class="dilog-div" *ngIf="data.confirmDialog" id="remove-cart-confirm-dialog">
    <h4 class="font-medium font-bold text-center mb-2">{{'Confirm' | translate}}</h4>
    <p class="pb-3">{{confirmMessage}}</p>
    <div class="d-flex gap-3">
        <div class="w-50">
            <button type="button" class="custom-button btn btn-bgnone btn-block"
                (click)="dialogRef.close(false)">{{'CANCEL' | translate}}</button>
        </div>
        <div class="w-50">
            <button type="button" class="custom-button btn btn-bg  btn-block button_hover"
                (click)="dialogRef.close(true); logout()">{{'OK' | translate}}</button>
        </div>
    </div>
</div>


<div class="{{lang == 'ar' ? 'text-right' : ''}} dilog-div" *ngIf="data.returnReason" id="return-reason-dialog">
    <div class="dilogueHead">
        <div class="modal-close" (click)="closeModal()">x</div>
        <h4 class="font-medium font-bold text-center mb-2">{{'Enter Information' | translate}}</h4>
    </div>

    <h5 class="font-medium FontInner font-regular mb-2">{{'Quantity:' | translate}}</h5>
    <ng-select [items]="quantityOptions" [placeholder]="'Select quantity' | translate" [addTag]="true" bindLabel="label"
        bindValue="value" [clearable]="false" [searchable]=false [(ngModel)]="returnQuantity"></ng-select>

    <h5 class="font-medium FontInner font-regular mt-3 mb-2">{{'Reason for return :' | translate}}</h5>
    <span class="d-block font-regular font-small mb-1 custom-radio">
        <input type="radio" name="request-reason" [(ngModel)]="requestReason" value="Damaged Goods" id="rradio_1">
        <label for="rradio_1"><i class="radio-icon"></i> {{'Damaged Goods' | translate}}</label>
    </span>
    <span class="d-block font-regular font-small mb-2 custom-radio">
        <input type="radio" name="request-reason" [(ngModel)]="requestReason" value="Color is faded" id="rradio_2">
        <label for="rradio_2"><i class="radio-icon"></i> {{'Color is faded' | translate}}</label>
    </span>
    <span class="d-block font-regular font-small mb-2 custom-radio">
        <input type="radio" name="request-reason" [(ngModel)]="requestReason" value="Size is not matching"
            id="rradio_3">
        <label for="rradio_3"><i class="radio-icon"></i> {{'Size is not matching' | translate}}</label>
    </span>
    <span class="d-block font-regular font-small mb-2 custom-radio">
        <input type="radio" name="request-reason" [(ngModel)]="requestReason" value="Order mismatch" id="rradio_4">
        <label for="rradio_4"><i class="radio-icon"></i> {{"Order mismatch" | translate}}</label>
    </span>
    <span class="d-block font-regular font-small mb-2 custom-radio">
        <input type="radio" name="request-reason" value="Other reason" id="rradio_5">
        <label for="rradio_5"><i class="radio-icon"></i> {{'Other reason' | translate}}</label>
    </span>
    <div class="mt-4 mb-4 returnInfo">
        <textarea rows="4" placeholder="{{'Reason for return*' | translate}}" [(ngModel)]="requestReasonText"
            name="request-reason"></textarea>
    </div>
    <div class="row m-0">
        <div class="col-12 p-0">
            <button type="button" id="btn_reason_ok" class="custom-button btn btn-dark btn-block button_hover"
                [ngClass]="{'disabled': (!requestReason && !requestReasonText)}"
                (click)="submitReturnReason()">{{'SUBMIT' | translate}}</button>
        </div>
    </div>
</div>