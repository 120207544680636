import { Component, OnInit } from "@angular/core";
import { LoyaltyService } from "../services/loyalty.service";
import { ConfigService } from "../config/config.service";
import { ConfigSettings } from "../config/config.settings";
import { UserService } from "../config/user.service";

@Component({
  selector: "app-loyalty-point",
  templateUrl: "./loyalty-point.component.html",
  styleUrls: ["./loyalty-point.component.scss"],
})
export class LoyaltyPointComponent implements OnInit {
  loyaltyPoint: any;
  userId: string;
  tierTab = 1;
  selectedTier: any;
  skeletonLoader:boolean=true;
  userTierId: number = 1
  constructor(
    private loyaltyService: LoyaltyService,
    private configService: ConfigService,
    private configSettings: ConfigSettings,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.userService.getUserSession().then(
      (user) => {
        if (user !== false) {
          this.userId = user.id;
          this.getData();
        }
      },
      (error) => {
        this.configSettings.toggleLoading(false);
      }
    );
  }

  getData() {
    const params = {
      user_id: this.userId,
    };
    this.loyaltyService.getHistory("", params).subscribe((response) => {
      if (response.status === 200) {
        this.loyaltyPoint = response.body.data;      
        // currently this.loyaltyPoint.user_tier_id is coming as emptyString if user has 0 loyalty points , to fix this set userTierId to 1 if user has 0 loyalty points  
        this.userTierId = Number(this.loyaltyPoint.user_tier_id) == 0 ? 1 : Number(this.loyaltyPoint.user_tier_id) 
        this.onTierId(this.userTierId);
        this.skeletonLoader=false;
      } else {
        this.loyaltyPoint;
        this.skeletonLoader=false;
      }
    });
  }

  onTierId(id: number) {
    console.log(id,'id');
    
    this.tierTab = id;
    const data: any[] | undefined = this.loyaltyPoint.loyalty_tier;
    const response = data.find((Item) => {
      if (Item.tier_id == id) {
        return Item;
      }
    });
    this.selectedTier = response;

    // console.log(response);
  }
}
