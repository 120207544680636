import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ConfigService} from "../config/config.service";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class CreateVipService {

  constructor(
    private http: HttpClient,
    private configService: ConfigService
  ) { }

  createVip(getParams?: any, postParams?: any) {
    return this.configService.postRequest('vip-type-register', getParams, postParams)
      .pipe(map(response => {
        return response;
      }));
  }

  login(getParams?: any, postParams?: any) {
    return this.configService.postRequest('login', getParams, postParams)
      .pipe(map(response => {
        return response;
      }));
  }

}