<div class="my_account_side_menu">
  <div>
    <a [routerLink]="'/my-details'" class="" [ngClass]="{'active': (page === 1)}">{{'Account details' | translate}}</a>
    <!-- <p class="font-small">{{'Review your personal information' | translate}}</p> -->
  </div>
  <div>
    <a [routerLink]="'/my-orders'" class="" [ngClass]="{'active': (page === 2)}">{{'Orders' | translate}}</a>
    <!-- <p class="font-small">{{"Check your orders'status and history or print the returns form." | translate}}</p> -->
  </div>
  <div>
    <a [routerLink]="'/return-request-list'" class="" [ngClass]="{'active': (page === 3)}">{{'Returns' | translate}}</a>
    <!-- <p class="font-small">{{'Query the different options for returning your items.' | translate}}</p> -->
  </div>
  <div>
    <a [routerLink]="'/my-address'" class="" [ngClass]="{'active': (page === 4)}">{{'Addresses' | translate}}</a>
    <!-- <p class="font-small">{{'Check the options to manage your shipping addresses.' | translate}}</p> -->
  </div>
  <div>
    <a [routerLink]="'/my-wishlist'" class="" [ngClass]="{'active': (page === 5)}">{{'Wish List' | translate}}</a>
    <!-- <p class="font-small">{{"Access your Wishlist so you don't lose sight of the articles you like most." | translate}}</p> -->
  </div>  
  <div>
    <a [routerLink]="'/wallet-credit'" class="" [ngClass]="{'active': (page === 6)}">{{'Store Credit' | translate}}</a>
    <!-- <p class="font-small">{{'Top up your account credit with money and spend it when placing orders.' | translate}}</p> -->
  </div>
  <div>
    <a [routerLink]="'/refer-a-friend'" class="" [ngClass]="{'active': (page === 7)}">{{'Refer A Friend' | translate}}</a>
    <!-- <p class="font-small">{{"Refer and earn on every referral." | translate}}</p> -->
  </div>
  <div>
    <a [routerLink]="'/loyalty-point'" class="" [ngClass]="{'active': (page === 8)}">{{'Loyalty Points' | translate}}</a>
    <!-- <p class="font-small">{{'Top up your account credit with money and spend it when placing orders.' | translate}}</p> -->
  </div> 
  <div>
    <a [routerLink]="'/my-brand-list'" class="" [ngClass]="{'active': (page === 9)}">{{'Boutique’s/Brands' | translate}}</a>
    <!-- <p class="font-small">{{"Access your Wishlist so you don't lose sight of the articles you like most." | translate}}</p> -->
  </div>  
  <div>
    <a href="javascript:;" class="" (click)="showLogoutModal()">{{'Logout' | translate}}</a>
  </div>
</div>
