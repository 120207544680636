import { Component } from '@angular/core';

@Component({
  selector: 'app-shop-the-look-side-bar',
  templateUrl: './shop-the-look-side-bar.component.html',
  styleUrls: ['./shop-the-look-side-bar.component.scss']
})
export class ShopTheLookSideBarComponent {

}
