import { Injectable } from '@angular/core';
import { BehaviorSubject,Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class hideCartService {
    private hideCart: Subject<any> = new BehaviorSubject([]);
    constructor() { }
    setHideCart(item:any){
      this.hideCart.next(item);
    }
    getHideCart(){
      return this.hideCart.asObservable();
    }
}