import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ConfigService} from '../config/config.service';
import { listStagger } from '../animations';
import { FooterService } from '../config/footer.service';
import { ConfigSettings } from '../config/config.settings';
import {AnalyticsService} from '../services/analytics.service';
import { NavbarService } from '../config/navbar.service';

@Component({
  selector: 'app-cms',
  templateUrl: './cms.component.html',
  styleUrls: ['./cms.component.scss'],
  animations: [ listStagger ]
})
export class CmsComponent implements OnInit {
  id: number;
  lang: string;
  page: string;
  content: string;
  dataAvailable = false;
  isMobile: any = false;
  skeletonLoader=true;
  constructor(
      private configService: ConfigService,
      private route: ActivatedRoute,
      protected footerService: FooterService,
      private configSettings: ConfigSettings,  
      private navbarService:NavbarService,   
      private analytics: AnalyticsService
  ) { }

  ngOnInit() {
      this.configSettings.toggleLoading(true);
      this.navbarService.isEnableHeaderBottomMobileAction(false);
      this.id = this.route.snapshot.data['id'];
      this.lang = this.configSettings.getLang();
      this.gatData();
  }

  gatData() {
      const params = {
          page: this.id,
          lang: this.lang
      };
      this.configService.readRequest('cms', params)
          .subscribe(response => {
              this.page = response.body.data.page;
              this.content = response.body.data.content;
              this.dataAvailable = true;
              this.configSettings.toggleLoading(false);
              this.skeletonLoader=false;
              // this.analytics.pageView(
              //   String(response.body.data.page)
              // );
              setTimeout(() => {this.footerService.toggleFooter(true)}, 300);
          });
  }


}
