<div class="cart-summary-item">
  <span class="summary-title">{{'Item Total' | translate}}</span><span class="taxes">{{inclusive_of_taxes}}</span>
  <span class="summary-value bidi-override"><span class="currency-symbol bidi-override dir-rtl">{{currency}}</span> {{cartValue | number:'1.3-3'}}</span>
</div>
<div class="cart-summary-item text-success" *ngIf="wallet > 0">
  <span class="summary-title">{{'Wallet' | translate}}</span>
  <span class="summary-value bidi-override">
    -
    <span class="currency-symbol bidi-override dir-rtl">{{currency}}</span>
    {{wallet | number:'1.3-3'}}
  </span>
</div>
<div class="cart-summary-item" *ngIf="discount > 0">
  <span class="summary-title class-green">{{'Discount' | translate}}</span>
  <span class="summary-value bidi-override"><span class="currency-symbol bidi-override dir-rtl">-{{currency}}</span> {{discount | number:'1.3-3'}}</span>
</div>
<div class="cart-summary-item" *ngIf="shippingCost > 0">
  <span class="summary-title">{{'Delivery' | translate}}</span>
    <span class="summary-value bidi-override">
      <span class="currency-symbol bidi-override dir-rtl">{{currency}}</span> {{shippingCost | number:'1.3-3'}}
    </span>
</div>
<div class="cart-summary-item" *ngIf="(shippingCost == 0)">
  <span class="summary-title">{{'Delivery' | translate}}</span>
    <span class="summary-value">
      {{'FREE' | translate}}
    </span>
</div>
<div class="cart-summary-item" *ngIf="custom_duty_charges">
  <span class="summary-title">{{'Custom Charges' | translate}}</span>
    <span class="summary-value">
      {{currency}} {{custom_duty_charges | number:'1.3-3'}}
    </span>
</div>
<div class="cart-summary-item" *ngIf="codCost > 0">
  <span class="summary-title">{{'COD Cost' | translate}}</span>
  <span class="summary-value bidi-override"><span class="currency-symbol bidi-override dir-rtl">{{currency}}</span> {{codCost | number:'1.3-3'}}</span>
</div>
<hr />
<div class="cart-summary-item">
  <span class="summary-title">{{'Total' | translate}}</span>
    <span class="summary-value bidi-override">
      <span class="currency-symbol bidi-override dir-rtl">{{currency}}</span>&nbsp;<span class="font-black">{{total | number:'1.3-3'}}</span></span>
</div>



























































