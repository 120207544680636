<ng-container *ngIf="dataAvailable">

  <div class="my_account_data">
    <div class="container-fluid">

      <section class=" main-profile" id="return-request-details" @listStagger>

        <div class="account_heading pt-3 pb-5">
          <ul
            class="d-flex flex-wrap align-items-center justify-content-center w-100 listing-page-routing"
          >
            <li class="routing-list">
              <a [routerLink]="'/my-account'">
                {{ "my account" | translate }}
              </a>
            </li>
            <li class="routing-list">
              <a [routerLink]="'/return-request-list'">
                {{'My Returns' | translate}}
              </a>
            </li>
            <li class="routing-list">
              <a>
                {{'REQUEST DETAILS' | translate}}
              </a>
            </li>
          </ul>
        </div>

    
        <div class="pb-5" *ngIf="request">
            <div class="d-flex w-100 justify-content-center w-100">
                <div class="user-left-menu d-none d-lg-block w-25">
                  <app-user-account-menu [page]="3"></app-user-account-menu>
                </div>
                <div class="user-profile-content w-100 w-lg-75">

                  <div class="breadcrumb_heading">
                    <h3><a [routerLink]="'/my-account'"> <img src="/assets/icons/right-arrow.svg" alt="arrow"></a> {{'My Returns Request' | translate}}</h3>
                  </div>


                  


                  <div class="details_data_box">
                    <div class="d-flex gap-4">
                      <div *ngIf="!skeletonLoader">
                        <div class="shipping_icon">
                          <img
                            src="/assets/icons/Location.svg"
                            alt="Location"
                          />
                        </div>
                      </div>
                      <div class="w-100">
                        <div class="shipping_address_data">
                          <h2 >
                            {{ "YOUR ADDRESS" | translate }}
                          </h2>
                          <div  class="address_data">
                            <h4>{{ request.address?.first_name }} {{request.address?.last_name}}</h4>
                            
                            <p class="d-flex gap-2">
                              <span *ngIf="request.address?.country_name">{{ request.address?.country_name }},</span> <span *ngIf="request.address?.governorate_name">{{ request.address?.governorate_name }}</span>                                                              
                            </p>
        
                            <p class="d-flex gap-2"><span *ngIf="request.address?.area_name">{{ request.address?.area_name }},</span> <span *ngIf="request.address?.block_name">{{ request.address?.block_name }}</span></p>
                            <p class="d-flex gap-2"><span class="" *ngIf="request.address?.street"> {{ request.address?.street }},</span>  <span class="" *ngIf="request.address?.avenue"> {{ request.address?.avenue }},</span> <span class="" *ngIf="request.address?.flat"> {{ request.address?.flat }},</span> <span class="" *ngIf="request.address?.building">{{ request.address?.building }},</span> <span class="" *ngIf="request.address?.floor">{{ request.address?.floor }}</span> </p>                              
        
                            <p class="d-flex gap-2"><span class="p-0 " *ngIf="request.address?.landmark">{{ request.address?.landmark }},</span> <span class="p-0 " *ngIf="request.address?.id_number">{{ request.address?.id_number }}</span></p>
                            
                            <h6 class="my-2" *ngIf="request.address?.notes">{{'Note:' | translate}} {{ request.address?.notes }}.</h6>
        
                            <p class="" *ngIf="request.address?.mobile_number">+{{request.address?.phonecode}} {{ request.address?.mobile_number }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="details_data_box mt-4">
                    <h4 class="item-details">{{' REQUESTED Details' | translate}}</h4>
                  <div class="d-flex gap-3">
                    
                    <div class="order-img-wrapper w-75">
                      <h4 class="order-number ">{{request.request_number}}</h4>
                      <h5 class="order-date font-small font-regular mb-2">{{'Requested On:' | translate}} {{request.request_date | date:'dd MMM yyyy'}}</h5>
                      <p class="order-items font-small font-bold m-0">{{'Order No:' | translate}} {{request.order_number}}</p>
                    </div>
                    <div class="order-details-wrapper w-25">
                      <p class="order-status font-small font-black mb-1 mb-md-2">{{request.status}}</p>
                    </div>
                  </div>
                </div>
                  <!-- <div class="col-12 white-container pt-3 pb-3 mb-2 font-regular font-small">
                    <h4 class="font-medium font-bold">{{'DELIVERY ADDRESS' | translate}}</h4>
                    <div class="row pt-2">
                      <div class="col-12">
                        {{request.address.first_name}}<br/>
                        {{request.address.addressline_1}}, {{request.address.street}}, {{request.address.block_name}}<br/>
                        {{request.address.area_name}}, {{request.address.governorate_name}}<br/>
                        {{request.address.country_name}}<br/>
                        {{'Mobile:' | translate}} <b>{{request.address.mobile_number}}</b>
                      </div>
                    </div>
                  </div> -->
                  <div class="details_data_box mt-4 requestItemList">

                    <h4 class="item-details">{{request.items.length}} {{'ITEM(S) REQUESTED' | translate}}</h4>
                    <div class="details_order_product">
                    <div class="d-flex gap-4 pt-1 mb-3" *ngFor="let item of request.items">
                      <!-- <div class="">
                        <img src="{{item.image}}" class="img-fluid" />
                      </div> -->
                      <div class="product_image">
                        <figure>
                          <div class="image-wrapper">
                            <img class="loaded" src="{{ item.image }}" />
                          </div>
                        </figure>
                      </div>
                      <!-- <div class="flex flex-column align-items-start h-100">
                      <div class="">
                        <span class="font-bold d-block">{{item.name}}</span>
                        <span class="d-block" *ngFor="let option of item.configurable_option">{{option.type}}: {{option.attributes[0].value}}</span>
                        <span class="d-block pt-2 bidi-override"><span class="bidi-override dir-rtl">{{currency}}</span> {{item.final_price | number:'1.3-3'}}</span>
                      </div>
                      <div class=" font-bold item-quantity">
                        <span>x{{item.return_quantity}}</span>
                      </div>
                    </div> -->
                    
                    <div class="position-relative w-100">
                      <div class="details_product">
                        <h2>{{ item.name }}</h2>
                        <!-- <p>{{'Blazer' | translate}} + {{'Cami Dress-Set' | translate}}</p> -->
                        <div class="d-flex gap-2">
                          <span
                              *ngFor="let option of item.configurable_option"
                            >{{option.type}}: {{option.attributes[0].value}}</span
                          >
                        </div>
                      </div>

                      <div class="details_product_price">
                        <div
                          class="d-flex justify-content-between w-100 align-items-center"
                        >
                          <div>
                            <div
                              class="d-flex gap-2 align-items-center"
                            >
                              <h2 class="price_normal">
                                {{ currency }}
                                {{item.final_price | number:'1.3-3'}}
                              </h2>
                            </div>
                          </div>
                          <div>
                            <span class="product_total"
                              >x{{item.return_quantity}}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    </div>
                  </div>


                </div>
                </div>
            </div>
        </div>
      </section>

      </div>
      </div>

 
</ng-container>
