import {AfterViewInit, Component, OnInit, Renderer2, ElementRef,HostListener } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ConfigSettings} from './config/config.settings';
import { Title, Meta, MetaDefinition} from '@angular/platform-browser';
import { FooterService } from './config/footer.service';
import {Store} from "./models/store";
import { Router, NavigationEnd, ActivatedRoute, Event, NavigationStart } from '@angular/router';
import {ConfigService} from "./config/config.service";
import {LocalStorage} from "@ngx-pwa/local-storage";
import SmartBanner from 'smart-app-banner';
import { filter, map } from 'rxjs/operators';
import {AnalyticsService} from './services/analytics.service';
import { IsFilterOnService } from './services/is-filter-on.service';
import { UserService } from './config/user.service';
declare var Pushwoosh;


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  lang: string;
  footerStatus: boolean = true;
  stores: Store[] = [];
  store: Store;
  pushSubscribe:boolean=false;
  notificationPermission:boolean=false;
  dontSubscribe=false;
  showPopup=false;
  public constructor(
      private translate: TranslateService,
      private configSettings: ConfigSettings,
      private configService: ConfigService,
      private footerService: FooterService,
      private router: Router,
      private activatedRoute: ActivatedRoute,
      protected localStorage: LocalStorage,
      private titleService: Title,      
      private analytics: AnalyticsService,
      private metaService: Meta,
      private isFilterOnService: IsFilterOnService,
      private renderer: Renderer2,
      private elementRef: ElementRef,
      private userService:UserService,
  ) {
    this.readAllStores();
    translate.addLangs(['en', 'ar']);
    translate.setDefaultLang('en');
    const lang = this.configSettings.getLang();
    if (lang) {
      localStorage.removeItem('lang')
      localStorage.setItem('lang',lang)
        translate.use(lang);
        this.reloadIndexJS()
    } else {
      localStorage.removeItem('lang')
      localStorage.setItem('en',lang)
        translate.use('en');
        this.reloadIndexJS()
    }
    
    this.lang = translate.currentLang;
    this.configSettings.setLang(translate.currentLang);
    this.addTag();
    this.footerService.footerStatus.subscribe(data => this.footerStatus = data);

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
      }
  });
  }

  reloadIndexJS() {
    const scriptElement = this.renderer.createElement('script');
    scriptElement.src = 'index.js';
    
    const appRoot = this.elementRef.nativeElement;
    this.renderer.appendChild(appRoot, scriptElement);
  }

  onActivate(event:any)
  {
    let url=window.location.pathname;
    if(url.includes('/home') && !this.pushSubscribe && this.notificationPermission &&!this.dontSubscribe){
      this.showPopup = true;
    }    
  }

  addTag() {
    this.metaService.addTag({ name: 'description', content: 'Article Description' });
    this.metaService.addTag({ name: 'robots', content: 'index,follow' });
    this.metaService.addTag({ property: 'og:title', content: 'Content Title for social media' });
  }
  ngAfterViewInit() {
    // new SmartBanner({
    //   daysHidden: 15,
    //   daysReminder: 90,
    //   appStoreLanguage: 'us',
    //   title: 'The Wish List',
    //   author: 'SHOP-TWL.COM',
    //   button: 'VIEW',
    //   store: {
    //     ios: 'On the App Store',
    //     android: 'In Google Play',
    //     windows: 'In Windows store'
    //   },
    //   price: {
    //     ios: 'FREE',
    //     android: 'FREE',
    //     windows: 'FREE'
    //   },
    // });
  }

  isFilterActive: boolean = false
  ngOnInit() {
    this.isFilterOnService.isFilterActive.subscribe((res:any)=>{
      this.isFilterActive = res   
      console.log(this.isFilterActive,'this.isFilterActive');
        
    })
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    ).subscribe(() => {
 
        var rt = this.getChild(this.activatedRoute)
 
        rt.data.subscribe(data => {
          this.titleService.setTitle(data.title)
 
          if (data.descrption) {
            this.metaService.updateTag({ name: 'description', content: data.descrption })
          } else {
            this.metaService.removeTag("name='description'")
          }
 
          if (data.robots) {
            this.metaService.updateTag({ name: 'robots', content: data.robots })
          } else {
            this.metaService.updateTag({ name: 'robots', content: "follow,index" })
          }
 
          if (data.ogUrl) {
            this.metaService.updateTag({ property: 'og:url', content: data.ogUrl })
          } else {
            this.metaService.updateTag({ property: 'og:url', content: this.router.url })
          }
 
          if (data.ogTitle) {
            this.metaService.updateTag({ property: 'og:title', content: data.ogTitle })
          } else {
            this.metaService.removeTag("property='og:title'")
          }
 
          if (data.ogDescription) {
            this.metaService.updateTag({ property: 'og:description', content: data.ogDescription })
          } else {
            this.metaService.removeTag("property='og:description'")
          }
 
          if (data.ogImage) {
            this.metaService.updateTag({ property: 'og:image', content: data.ogImage })
          } else {
            this.metaService.removeTag("property='og:image'")
          }
 
 
        })
 
      })
    this.pushwooshInit();

    this.router.events.subscribe(event => {
      this.userService.getUserSession().then(response => {
        if (response !== false) {
          if (event instanceof NavigationStart && event.url.includes('/login')) {
            this.router.navigate([this.configSettings.getPreviousUrl()]);
          }
        } else {
          if (event instanceof NavigationStart && !event.url.includes('/login')) {
            this.configSettings.setPreviousUrl(event.url);
          }
        }
      });
      // this.scrollToTop();
    });
  }
  scrollToTop() {    
    window.onload = function () {
      window.scroll(0, 0);
    };
  }
//   checkUserSession() {
//     this.userService.getUserSession().then(response => {
//         if (response !== false) {
//             this.userSession.id = response.id;
//             this.userSession.first_name = response.first_name;
//             this.userSession.last_name = response.last_name;
//             this.userSession.email = response.email;
//             // this.cartService.getCartObservable().subscribe((cart) => {
//             //     //console.log(cart);
//             //     if (cart && cart.hasOwnProperty('id')) {
//             //         this.cart = cart;
//             //         this.populateCartItems();
//             //     } else {
//             //         this.getCartItems();
//             //     }
//             // })

//         }

//         this.userStatus = true;
//     });
//     this.configSettings.toggleLoading(false);
// }

  readAllStores() {    
        this.configService.readRequest('stores', {})
            .subscribe(response => {
                if (response.body.data) {
                    this.stores =  <Store[]> response.body.data;

                    this.localStorage.getItem<any>('store_object').subscribe((store_object) => {
                        /*
                        * If Local Storage is set
                        * then set store object
                        * else
                        * Set kuwait as default store
                        * */
                        if(store_object){
                            this.store = <Store> store_object;
                            this.configSettings.setStore(this.store);
                            this.setLocalStore(this.store);
                        } else {
                            //this.findCountry().then(response => {
                                let isoCode = "KW";
                                //if(response.countryCode) {
                                //    isoCode = response.countryCode;
                                //}
                                this.getCountryDetails(isoCode).then(store => {
                                    if(store.status === 200) {
                                        var selectedStore = store.data;
                                        this.setLocalStore(selectedStore);
                                        this.store = <Store> selectedStore;
                                        this.configSettings.setStore(this.store);
                                    } else {
                                        let index = this.stores.findIndex(store => store.iso_code === "KW");
                                        let selectedStore = this.stores[index];
                                        this.setLocalStore(selectedStore);
                                        this.store = <Store> selectedStore;
                                        this.configSettings.setStore(this.store);
                                    }
                                }); 
                            //});
                        }
                        /* Removing selected store from sto  res array*/
                        //const index = this.stores.findIndex(store => store.id === this.store.id);
                        //this.stores.splice(index, 1);
                    });
                }
            });
    }

    findCountry(): Promise<any> {
        let promise = new Promise<any>((resolve, reject) => {
            this.configService.readRequest('ip-info', {})
                .subscribe(response => {
                    if (response.body.data) {
                        resolve(response.body.data);   
                    }  
                });
        });

        return promise;
    }

    getCountryDetails(iso_code): Promise<any> {
        let promise = new Promise<any>((resolve, reject) => {
            const params = {
                iso_code: iso_code
            };
            this.configService.readRequest('country-details', params)
                .subscribe(response => {
                    if (response.body.data) {
                        resolve(response.body);        
                    }
                });
        });

        return promise;
    }

    setLocalStore (selectedStore: any) { 
        this.localStorage.setItem('store_object', selectedStore).subscribe(() => {});
    }

    getChild(activatedRoute: ActivatedRoute) {
        if (activatedRoute.firstChild) {
          // var page_name = JSON.parse(JSON.stringify(activatedRoute.firstChild.url))._value[0].path;
          // this.analytics.pageView(
          //     String(page_name)
          // );
          return this.getChild(activatedRoute.firstChild);
        } else {
          return activatedRoute;
        }
     
      }

  pushwooshInit() {
    if ("Notification" in window) {      
      Notification.requestPermission();
    }
    Pushwoosh.push(['onLoad', (api) => {
      console.log('Pushwoosh load!');
    }]);
    Pushwoosh.push((api) => {
      console.log('Pushwoosh ready!');
      Pushwoosh.isSubscribed().then((isSubscribed) => {
        console.log('isSubscribed', isSubscribed);
        this.pushSubscribe = isSubscribed;
        this.showPopup =!this.pushSubscribe && this.notificationPermission &&!this.dontSubscribe;
      });      
    });

    Pushwoosh.push(['onLoad', (api) => {
      Pushwoosh.addEventHandler('subscribe', (payload) => {
        console.log('Triggered event: subscribe');
      });
    }]);

    Pushwoosh.push(['onLoad', (api) => {
      // Executed during the SDK initialization if 'autoSubscribe: false' or/and if a user ignores a push notification prompt.
      Pushwoosh.addEventHandler('permission-default', (payload) => {
        console.log('Triggered event: permission-default');
      });

      // Executed during the SDK initialization if notifications are blocked or once a user blocks push notifications.
      Pushwoosh.addEventHandler('permission-denied', (payload) => {
        console.log('Triggered event: permission-denied');
      });
      // Executed during the SDK initialization if notifications are allowed or once a user allows push notifications.
      Pushwoosh.addEventHandler('permission-granted', (payload) => {        
        console.log('Triggered event: permission-granted');
        this.notificationPermission=true;      
      });
    }]);

    Pushwoosh.push(['onLoad', (api) => {
      // Executed when a push notification is displayed.
      Pushwoosh.addEventHandler('receive-push', (payload) => {
        console.log('Triggered event: receive-push', payload);
      });
    }]);

    Pushwoosh.push(['onLoad', (api) => {
      // Executed when a user clicks on notification.
      Pushwoosh.addEventHandler('open-notification', (payload) => {
        console.log('Triggered event: open-notification', payload);
        // this.readNavigation.navigateAfterReading(payload,'PushNotification');
        // let link = this.generatePushRedirectLink(payload);
        // window.location.href = link;
      });
    }]);
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: any): void {
    let divElement = event.srcElement.id;
    // console.log(divElement);
    const excludedElements = ['staticBackdropInsuranceLabel', 'wishlistImg', 'subscribe-content', 'subscribe-btns', 'subscribe-btns2', 'subscribe-btns3'];
    if (!excludedElements.includes(divElement)) {
      this.showPopup = false;
    }
  }
  subscribe() {
    Pushwoosh.subscribe();
    this.pushSubscribe=true;
  }
  unsubscribe() {
    Pushwoosh.unsubscribe();
    this.dontSubscribe=true;    
  }

}
