import { Component, OnInit } from '@angular/core';
import {Faq} from '../models/faq';
import {ConfigService} from '../config/config.service';
import { listStagger } from '../animations';
import { FooterService } from '../config/footer.service';
import { ConfigSettings } from '../config/config.settings';
import {AnalyticsService} from '../services/analytics.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css'],
  animations: [ listStagger ]
})
export class FaqComponent implements OnInit {
    lang: string;
    faq: Faq[] = [];
    dataAvailable = false;

  constructor(
      private configService: ConfigService,
      protected footerService: FooterService,
      private configSettings: ConfigSettings,      
      private analytics: AnalyticsService
  ) { }

  ngOnInit() {
    this.configSettings.toggleLoading(true);
    this.lang = this.configSettings.getLang();
      this.gatData();
      // this.analytics.pageView(
      //  String("FAQ");
      // );
  }

  gatData() {
      const params = {
         lang: this.lang
      };
      this.configService.readRequest('faq', params)
          .subscribe(response => {
            if (response.body.data) {
                this.faq =  <Faq[]> response.body.data;
            }
            this.dataAvailable = true;
            this.configSettings.toggleLoading(false);
            setTimeout(() => {this.footerService.toggleFooter(true)}, 300);
          });
  }

}
