<ng-container *ngIf="dataAvailable">
  <section class="main-profile" id="" @listStagger>
    <div class="maintitle-Container">
      <h4 class="page-title text-center font-black font-condensed">{{'Request New Return' | translate}}</h4>
    </div>

    <section class="container">
      <div class="row m-0">
        <div class="col-12 col-sm-12 col-md-3 user-left-menu d-none d-lg-block">
          <app-user-account-menu [page]="2"></app-user-account-menu>
        </div>
        <div class="col-12 col-sm-12 col-lg-9 p-0 user-profile-content commonContentWhite">
          <form #returnForm="ngForm" (ngSubmit)="onSubmit(returnForm)">
            <div class="row m-0" *ngIf="items.length > 0">
              <article *ngFor="let item of items; let i = index;" class="white-container OrderSelect p-2 px-md-4" id="order-{{item.id}}" style="cursor: pointer;">
                <div class="row align-items-center custom-radio checkbox">
                  <input type="checkbox" name="request-item" value="" id="{{item.id}}" [checked]="item.requestReturn" (change)="selectItem(item)" required>
                  <label for="{{item.id}}" class="row-return-item">
                    <div class="item-img-wrapper col-5 col-md-2">
                      <i class="radio-icon"></i>
                      <img src="{{item.image}}" class="img-fluid order-listing-image">
                    </div>
                    <div class="order-details-wrapper col-7 col-md-10">
                      <div class="row">
                        <div class="col-12 col-md-5">
                          <h4 class="product-brand font-black">{{item.brand_name | uppercase}}</h4>
                          <h5 class="product-name font-medium">{{item.name}}</h5>
                          <p class="product-sku font-regular">SKU: {{item.SKU}}</p>
                          <p *ngIf="item.returnReason" class="mb-1">{{'Reason:' | translate}} <span class="return-reason">{{item.returnReason}}</span></p>
                          <p *ngIf="item.returnQuantity">{{'Quantity to return:' | translate}} <span class="return-reason">{{item.returnQuantity}}</span></p>
                        </div>
                        <div class="col-12 col-md-5">
                          <p class="product-price bidi-override"><span class="bidi-override dir-rtl">{{currency}}</span> {{item.final_price | number:'1.3-3'}}</p>
                        </div>
                        <div class="col-12 col-md-2">
                          <p><span class="cart-item-attribute">{{'Quantity' | translate}}</span>: {{item.available_return_quantity}}</p>
                        </div>
                      </div>
                    </div>

                  </label>
                </div>
              </article>

              <div class="col-12 p-0 mt-2 mb-3 {{lang == 'en' ? 'text-right' : 'text-left'}}">
                <button class="btn btn-dark btn-sm-block button-large" type="submit" [disabled]="!returnItems.length">{{'SUBMIT' | translate}}</button>
              </div>

            </div>
          </form>
          
          <div *ngIf="!items.length" class="empty-cart">
            <div class="empty-cart-content white-container">
              <h4 class="page-title font-bold text-center">{{'You have no item to return' | translate}}</h4>
              <div class="text-center">
                <a [routerLink]="'/order/detail/'+orderId" class="btn btn-dark">{{'Go Back' | translate}}</a>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>
  </section>
</ng-container>
