import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {ConfigSettings} from "./config.settings";
import { ConfigService } from './config.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class WishlistService {
  private wishlistCountSource = new BehaviorSubject(0);
  currentWishlistCount = this.wishlistCountSource.asObservable();  

  constructor(
    private configSettings: ConfigSettings,
    private configService: ConfigService 
  ) {
    const wishlistCount = this.configSettings.getWishlistCount();
    this.changWishlistCount(wishlistCount);
  }

  changWishlistCount(count: any) {
    this.configSettings.setWishlistCount(count);
    this.wishlistCountSource.next(count);
  }

  removeFromWishlist(getParams: any, postParams:any) {
    return this.configService.postRequest('remove-wishlist', getParams, postParams)
        .pipe(map(response => {
            this.changWishlistCount(response.data.length);
            return response;
        }));
  }

  addToWishlist(getParams: any, postParams:any) {
    return this.configService.postRequest('add-wishlist', getParams, postParams)
        .pipe(map(response => {
            this.changWishlistCount(response.data.length);
            return response;
        }));
  }


  toggleBrandAsFavorite(getParams: any, postParams:any) {
    return this.configService.postRequest('toggle-brands-favourites', getParams, postParams)
        .pipe(map(response => {
            this.changWishlistCount(response.data.length);
            return response;
        }));
  }
  


}
