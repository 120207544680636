import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import {ConfigService} from "../config/config.service";
import {Router} from "@angular/router";
import {ConfigSettings} from "../config/config.settings";
import { CartService } from '../config/cart.service';
import { WishlistService } from '../config/wishlist.service';
import { SocialAuthService  } from '@abacritt/angularx-social-login';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { User } from '../models/user';
import { CheckUserLoggedInService } from '../services/check-user-loggedin.service';
import { UpdateWishlistCountService } from '../services/update-wishlist-count.service';

declare var $: any;

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {
  reaon: string;
  requestReason: string;
  requestReasonText: string;
  lang: string;
  user: User;
  confirmMessage:string;
  maxReturnQuantity: number;
  returnQuantity: number;
  quantityOptions: any[] = [];
  disableCancelSubmit = false;
  
  constructor(
      @Inject(MAT_DIALOG_DATA) public data: any,
      private dialogRef: MatDialogRef<DialogComponent>,
      private configService: ConfigService,
      private router: Router,
      private snackBarRef: MatSnackBar,
      private configSettings: ConfigSettings,
      private authService: SocialAuthService ,
      private wishlistService: WishlistService,
      private cartService: CartService,
      protected localStorage: LocalStorage,
      private checkUserLoggedInService: CheckUserLoggedInService,
      private updateWishlistCountService : UpdateWishlistCountService
  ) { }

  ngOnInit() {
    this.lang = this.configSettings.getLang();
    if(this.data.images) {
        let imageCount = this.data.images.length;
        $(document).ready(function() {
            $('.slider-gallery').slick({
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: true,
                dots: false,
                fade: true,
                responsive: true,
                asNavFor: '.thumb',
                prevArrow: '<span class="slick-prev" style=""><svg width="16px" height="70px" viewBox="0 0 16 70"><g transform="translate(1 1)" stroke="#444141" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="square"><path transform="matrix(-1 0 0 1 14 0)" d="M0.5 0.5L13.5 33.5"></path><path transform="rotate(180 7 50.5)" d="M0.5 33.5L13.5 67.5"></path></g></svg></span>',
                nextArrow: '<span class="slick-next" style=""><svg width="16px" height="70px" viewBox="0 0 16 70"><g transform="translate(1 1)" stroke="#444141" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="square"><path d="M0.5 0.5L13.5 33.5"></path><path transform="matrix(1 0 0 -1 0 100)" d="M0.5 33.5L13.5 66.5"></path></g></svg></span>'
            });
            $('.thumb').slick({
                infinite: false,
                slidesToShow: imageCount,
                slidesToScroll: 6,
                asNavFor: '.slider-gallery',
                arrows: true,
                dots: false,
                vertical: true,
                focusOnSelect: true
            });
        });
    }

    if(this.data.user){
        this.user = this.data.user;
    }

    /*
    * Order item return quantity
    * */
    if(this.maxReturnQuantity) {
        this.returnQuantity = this.maxReturnQuantity;
        for (let i = 1; i <= this.returnQuantity; i++) {
            this.quantityOptions.push({value: i, label: i});
        }
    }
  }

  closeDialog() {
      this.dialogRef.close();
  }

    cancelOrder() {
      this.disableCancelSubmit = true;
        this.configSettings.toggleLoading(true);
        const getParams = {
            id: this.data.id,
            user: this.data.user
        };

        const reasonMessage = (this.reaon == 'OR')? this.requestReasonText : this.reaon;

        const postParams = {
            reason: reasonMessage
        };

        this.configService.postRequest('cancel-order', getParams, postParams)
            .subscribe(response => {
                this.configSettings.toggleLoading(false);
                this.dialogRef.close();
                if (response.status !== 200) {
                    let snackBarRef = this.snackBarRef.open(response.message, null, {
                        duration: 5000
                    });
                } else {
                    this.router.navigate(['my-orders']);
                }

            }, error => { this.configSettings.toggleLoading(false); });
    }

    closeModal() {
      this.dialogRef.close();
    }

    logout() {
        this.configSettings.removeLoginUserData()
        if(this.user.is_social_register){
            this.authService.signOut();
        }
        this.wishlistService.changWishlistCount(0);
        this.cartService.changCartCount(0);
        this.updateWishlistCountService.removeFromWishlist();
        window.localStorage.removeItem('selected_shipping_address');
        this.localStorage.removeItem('userInfo').subscribe(() => {
            // window.location.href = '/';
            // this.router.navigate(['/']);
            // window.location.reload();
            this.router.navigate(['/home']).then(() => {
                // Reload the current page
                window.location.reload();
            });

        });
        this.checkUserLoggedInService.setUserLoginStatus(false)
        this.closeDialog();
        
    }

    submitReturnReason() {
      const reason = (this.requestReasonText) ? this.requestReasonText : this.requestReason;
        this.dialogRef.close({ data: {reason: reason, quantity: this.returnQuantity} });
    }

}
