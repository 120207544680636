import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {Product} from '../models/product';
import {Store} from '../models/store';
import {ConfigSettings} from '../config/config.settings';
import {ConfigService} from '../config/config.service';
import {UserService} from '../config/user.service';
import {WishlistService} from '../config/wishlist.service';
import {first} from 'rxjs/operators';
import {AnalyticsService} from '../services/analytics.service';
import { ConfigurableOptions } from '../models/configurable-options';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { FooterService } from '../config/footer.service';
// import { MatDialog } from '@angular/material';
import { MatDialog } from '@angular/material/dialog';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { CreateVipService } from '../services/create-vip.service';
import { CartService } from '../config/cart.service';
import { CryptService } from '../config/crypt.service';
import { ActivatedRoute } from '@angular/router';
import { AddedToCartDialogComponent } from '../added-to-cart-dialog/added-to-cart-dialog.component';
import { DialogComponent } from '../dialog/dialog.component';
import { AddToCartModalService } from '../config/add-to-cart-modal.service';
import { HandleCartService } from '../services/handle-cart.service';
declare var $: any;
@Component({
  selector: 'app-prodct-item-v2',
  templateUrl: './prodct-item-v2.component.html',
  styleUrls: ['./prodct-item-v2.component.css']
})
export class ProdctItemV2Component implements OnInit {

  @Input() item: Product;
  @Input() currency: string;
  @Input() enableRemoveWishlist: boolean = false;
  @Input() lang: string;
  @Input() store: Store;
  @Input() userId: number;
  @Input() returnUrl: string;
  @Input() minimal = false;
  @Input() showWishlistIcon = false;
  @Input() storyItems: any;
  @Input() mainItem:any;
  @Output() triggerTimelineModel: EventEmitter<Number> = new EventEmitter<Number>();
  @Output() openCart: EventEmitter<Number> = new EventEmitter<Number>();
  @Input() backdropHeight:any;
  @Input() page: string

  in_wishlist = false;
  imgSrc: string;
  isMobile = false;
  

  addToBagText:any = 'Add to Bag';


    product: Product;
    id: number;
    breadcumb: any[] = [];
    selectedAttribute: any[] = [];
    selectedOption: Number[] = [];
    selectedFilters: ConfigurableOptions[] = [];
    entityId: string;
    quantity: number = 1;
    quantityArr: any[] = [];
    dataAvailable = false;
    support_email = '';
    support_phone = '';
    english: boolean = true;
    isVip: any = this.configSettings.getisVip();
    sizeGuide_txt: string = "Size Guide";
    slideConfig: any = {
        variableWidth: false,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        prevArrow: '<span class="slick-prev" style=""><span class="slick-prev-icon"></span></span>',
        nextArrow: '<span class="slick-next" style=""><span class="slick-next-icon"></span></span>',
        asNavFor: '.gallery-thumb'
    };
    thumbSlideConfig: any = {
        variableWidth: false,
        infinite: false,
        speed: 300,
        slidesToShow: 4,
        slidesToScroll: 1,
        dots: false,
        arrows: false,
        asNavFor: '.gallery-main',
        vertical: true,
        focusOnSelect: true

    };
    branchIoLink: string;
    showAttribute = false;

    notifyMeForm: UntypedFormGroup;
    notifyMeSubmitted = false;
    emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
    phonePatters = "^[0-9]+$";
    isNotifyMeLoading = true;

    
    countryCode = '+965';


  constructor(
    private configSettings: ConfigSettings,
    private configService: ConfigService,
    private userService: UserService,
    private wishlistService: WishlistService,
    private analytics: AnalyticsService,

    private route: ActivatedRoute,
    private cryptService: CryptService,
    private cartService: CartService,
    public createVipService:CreateVipService,
    protected localStorage: LocalStorage,
    private dialog: MatDialog,
    protected footerService: FooterService,
    private fb: UntypedFormBuilder,
    private addToCartModalService:AddToCartModalService,
    private handleCartService:HandleCartService,
    private toastr: ToastrService
  ) {
  }

  ngOnInit() {
    this.isMobile = window.innerWidth < 600;
    this.in_wishlist = ((this.item)?(this.item.item_in_wishlist === 1):false);
    this.product = this.mainItem;
    if(this.product){
        if(this.product.configurable_option){
            this.product.configurable_option.forEach((option, index) => {
                if(index > 0 && option.attributes.length > 1){

                }else{
                    this.selectedAttribute[index] = option.attributes[0].option_id;
                    this.selectedOption[index] = +option.attribute_id;
                }
            });
        }
    }
    this.lang = this.configSettings.getLang();
    // this.imgSrc = this.item.image;
    // if (this.item.hover_image === undefined) {
    //   this.item.hover_image = this.item.image;
    // }
    /* getting store from config*/
    this.configSettings.getStoreObject().then((result) => {
        this.store = <Store>result;
        this.currency = result['currency_' + this.lang];
    });

    if(window.innerWidth < 768){
        var width = 79;
        if(this.backdropHeight == 79)
        {
            switch(this.backdropHeight == 79) {
                case (window.innerWidth < 361):
                  width = 144;
                  break;
                case (window.innerWidth < 376):
                  width = 153;
                  break;
                case (window.innerWidth < 391):
                  width = 157;
                  break;
                case (window.innerWidth < 394):
                  width = 158;
                  break;
                case (window.innerWidth < 413):
                  width = 167;
                  break;
                case (window.innerWidth < 415):
                  width = 168;
                  break;
                default:
            }
            this.backdropHeight = width;
        }else{
            this.backdropHeight = (this.backdropHeight == 93)?'unset':this.backdropHeight;
        }
    }
  }

  imageClicked(){
      this.triggerTimelineModel.emit(0)
  }

  toggleWishlist() {
    if (!this.userId) {
      if (this.returnUrl) {
        window.location.href = '/login?returnUrl=' + this.returnUrl;
      } else {
        window.location.href = '/login';
      }
      return false;
    }
    if (this.in_wishlist) {
      //remove from wishlist
      this.removeFromWishlist();
    } else {
      //add to wishlist
      this.addToWishlist();
    }
  }

  removeFromWishlist() {
    //this.configSettings.toggleLoading(true);
    const getParams = {
      lang: this.lang,
      store: this.store.iso_code
    };

    const postParams = {
      product_id: this.item.id,
      user_id: this.userId
    };

    this.wishlistService.removeFromWishlist(getParams, postParams)
      .pipe(first())
      .subscribe(
        response => {
          if (response.status === 200) {
            let items = response.data;
            this.userService.setWishlist(items);
            this.in_wishlist = !this.in_wishlist;
          }

          //this.configSettings.toggleLoading(false);
        }
      );

    return false;
  }

  addToWishlist() {
    // this.configSettings.toggleLoading(true);
    const getParams = {
      lang: this.lang,
      store: this.store.iso_code
    };
    const postParams = {
      product_id: this.item.id,
      user_id: this.userId
    };
    this.wishlistService.addToWishlist(getParams, postParams)
      .pipe(first())
      .subscribe(
        response => {
          if (response.status === 200) {
            this.analytics.addToWishlist(
                'Browse',
                this.item.id,
                this.item.name,
                this.item.marketing_category+' - '+this.item.marketing_subcategory,
                this.item.brand_name,
                this.item.final_price,
                this.item.regular_price,
            );
            let items = response.data;
            this.userService.setWishlist(items);
            this.in_wishlist = !this.in_wishlist;
          }
          // this.configSettings.toggleLoading(false);
        }
      );

    return false;
  }

  isFlipped:any = false;
  flipTheBox(){
    if(this.isFlipped){
      this.isFlipped = false;
    }else{
      this.isFlipped = true;
    }
  }

  onMouseOver(event){
    this.imgSrc = (this.item.hover_image)?this.item.hover_image:this.item.image;
  }

  onMouseOut(event){
    this.imgSrc = this.item.image;
  }
  redirectToUrl(id){
    window.location.href="/product/detail/"+id;
  }


  onOptionSelect(option, $event) {
      document.getElementById('option-' + option.attribute_id).classList.remove('error');
      this.clearFilters(option, $event).then(() => {
          this.filterOptions();
      });
      this.addToBagText = 'Add to Bag';
  }

  clearFilters(configurable_option: ConfigurableOptions, $event): Promise<any> {
    let promise = new Promise<any>((resolve, reject) => {
        this.product.configurable_option.map((option, index) => {
            if (option.attribute_id === configurable_option.attribute_id) {
                if (index == 0) {
                    this.selectedAttribute.splice(1);
                    this.selectedOption.splice(1);
                    this.selectedFilters.splice(1);
                }

                this.selectedAttribute[index] = $event.option_id;
                this.selectedOption[index] = +configurable_option.attribute_id;
                this.selectedFilters[index] = {
                    type: configurable_option.type,
                    attribute_id: configurable_option.attribute_id,
                    attribute_code: configurable_option.attribute_code,
                    attributes: [$event]
                };
            }
        });

        resolve(true);
    });

    return promise;

}

onOptionSelectV2(option, attribute, i, $event) {
    this.selectedAttribute[i] = attribute.option_id;
    this.clearFiltersV2(option, attribute, i, $event).then(() => {
        this.filterOptionsV2();
    });
    this.addToBagText = 'Add to Bag';
}

clearFiltersV2(configurable_option: ConfigurableOptions, attribute:any, i, $event): Promise<any> {
    let promise = new Promise<any>((resolve, reject) => {
        this.product.configurable_option.map((option, index) => {
            if (option.attribute_id === configurable_option.attribute_id) {
                if (index == 0) {
                    this.selectedAttribute.splice(1);
                    this.selectedOption.splice(1);
                    this.selectedFilters.splice(1);
                }

                this.selectedAttribute[index] = attribute.option_id;
                this.selectedOption[index] = +configurable_option.attribute_id;
                this.selectedFilters[index] = {
                    type: configurable_option.type,
                    attribute_id: configurable_option.attribute_id,
                    attribute_code: configurable_option.attribute_code,
                    attributes: [attribute]
                };
            }
        });

        resolve(true);
    });
    return promise;
}

filterOptionsV2() {
    this.configSettings.toggleLoading(true);

    const getParams = {
        lang: this.lang,
        store: this.store.iso_code
    };

    const postParams = {
        product_id: this.product.id,
        option_id: (this.selectedAttribute.length) ? this.selectedAttribute.join(',') : '',
        attribute_id: (this.selectedOption.length) ? this.selectedOption.join(',') : ''
    };

    this.configService.postRequest('configurable-options', getParams, postParams)
        .subscribe(response => {


            this.updateStockForAttribute(response.data);

            // let configurable_option = this.product.configurable_option;

            if (response.data[0].attributes.length) {
                this.product.configurable_option.forEach((option, index) => {
                    if(option.type == response.data[0].type){
                        this.product.configurable_option[index] = response.data[0];
                    }
                });
                // this.product.configurable_option.map( option => {
                //     if (option.attribute_id === response.data[0].attribute_id) {
                //         option.attributes = [];
                //         option.attributes = response.data[0].attributes;
                //         option.attributes.sort((a, b) => a.value.toLowerCase().localeCompare(b.value.toLowerCase()));
                //     }
                // });
                console.log('issue triggered');

                this.product.images = response.data[0].images;
                this.quantityArr = [];
            } else {
                // this.product.currency_code = response.data[0].currency_code;
                this.product.final_price = response.data[0].final_price;
                this.product.regular_price = response.data[0].regular_price;
                this.product.image = response.data[0].image_url;
                this.product.images = response.data[0].images;
                // this.product.regular_price = response.data[0].regular_price;
                this.product.SKU = response.data[0].sku;
                this.product.remaining_quantity = (response.data[0].remaining_quantity) ? response.data[0].remaining_quantity : 0;
                this.entityId = response.data[0].entity_id;

                if (this.product.remaining_quantity > 0) {
                    this.setQuantityDropdown(this.product.remaining_quantity);
                } else {
                    this.quantityArr = [];
                }
            }

            this.configSettings.toggleLoading(false);
        }, error => { this.configSettings.toggleLoading(false); });
}

addToBag() {
    this.addToBagText = '✓';
    if(this.selectedAttribute[0] == undefined || this.selectedAttribute[0] == ''){
        this.toastr.warning('Please select color first');
        this.addToBagText = 'Add to Bag';
        return;
    }
    if(this.product.configurable_option.length > 1){
        if(this.selectedAttribute[1] == undefined || this.selectedAttribute[1] == ''){
            this.toastr.warning('Please select size');
            this.addToBagText = 'Add to Bag';
            return;
        }
    }
    if(this.selectedFilters.length == 0){
        this.entityId = this.product.id;
    }
    this.addToCartModalService.sendItem(this.product);
    // this.isFlipped = false;
    try {
        if (this.product.is_saleable || this.product.is_preorder) {
            // Adding event  
            try{
                this.analytics.addToCart(
                    this.product.id,
                    this.product.name,
                    this.product.marketing_category+' - '+this.product.marketing_subcategory,
                    this.product.brand_name,
                    this.product.final_price,
                    this.product.regular_price,
                    this.quantity,
                    this.product.configurable_option
                );
                }  catch(e) {
                }                          
            
           const final_price = this.product.final_price_kwd * 3.30;
           try{
            this.sendGTagEvent('add_to_cart', {
                items: [{
                    id: this.product.SKU,
                    name: this.product.name,
                    list_name: 'Product Details',
                    brand: this.product.brand_name,
                    category: this.product.brand_name,
                    variant: (this.product.configurable_option.length > 0) ?
                        this.product.configurable_option[0].attributes[0].value : '',
                    list_position: 1,
                    quantity: this.quantity,
                    price: final_price.toFixed(2)
                }]
            });
           }catch(e){
            this.addToBagText = 'Add to Bag';
           }
           console.log('dnvdfjkn');
            if((this.product.is_vip_product == 1)?((this.product.is_vip_product == this.isVip)?true:false):true){
                console.log('dnvdfjkn');
                console.log(this.entityId);
                this.configSettings.toggleLoading(true);
                if (this.entityId) {
                    console.log('dnvdfjkn');
                    this.localStorage.getItem<any>('userInfo').subscribe((userInfo: any) => {
                        console.log('dnvdfjkn');
                        if (userInfo) {
                            console.log('dnvdfjkn');
                            this.addToOnlineCart(userInfo).then((any) => {                                
                                this.configSettings.toggleLoading(false);
                                //const dialogRef = this.dialog.open(AddedToCartDialogComponent);
                                setTimeout(() => {
                                if (this.isMobile == false) {
                                    this.addToBagText = 'PROCEED TO BAG';
                                    
                                }
                                if (this.isMobile == true) {
                                    this.addToBagText = 'Go to Bag';
                                    
                                }
                            },1500)
                                
                                this.handleCartService.sendItem(this.product);
                            }, error => {
                                this.configSettings.toggleLoading(false);
                            });
                        } else {                               
                            this.cartService.addToOfflineCart(this.entityId, this.product, this.selectedFilters, 1).then(response => {
                                this.configSettings.toggleLoading(false);
                                //const dialogRef = this.dialog.open(AddedToCartDialogComponent);
                                if (this.isMobile == false) {
                                    this.addToBagText = 'PROCEED TO BAG';
                                    
                                }
                                if (this.isMobile == true) {
                                    this.addToBagText = 'Go to Bag';
                                    
                                }
                                this.handleCartService.sendItem(this.product);
                            });
                        }
                    }, error => {
                        this.configSettings.toggleLoading(false);
                    });
                } else {
                    this.configSettings.toggleLoading(false);
                    let selects = document.querySelectorAll('ng-select');
                    for (let i = 0; i < selects.length; i++) {
                        if (this.selectedAttribute[i]) {
                            selects[i].classList.remove('error');
                            if (this.isMobile == false) {
                                this.addToBagText = 'PROCEED TO BAG';
                            }
                            if (this.isMobile == true) {
                                this.addToBagText = 'Go to Bag';
                            }
                        }
                        else {
                            selects[i].classList.add('error');
                            this.addToBagText = 'Add to Bag';
                        }
                    }
                }
            }else{
                var msg = "Unfortunately, this product can't be added to your cart as it's exclusive for our Gold Members";
                this.addToBagText = 'Add to Bag';
                if(this.lang == 'ar')
                {
                    msg = 'ناسف، المنتج حصري للعملاء الذهبيين';
                }
                this.toastr.error(msg);
            }
        }
    } catch (e) {
        this.addToBagText = 'Add to Bag';
        this.configSettings.toggleLoading(false);
        console.log(e);
    }
}

private addToOnlineCart(user: string): Promise<any> {
    return new Promise(resolve => {
        this.cryptService.doDecrypt(user).then(decryptedString => {
            const user = JSON.parse(decryptedString);

            const getParams = {
                lang: this.lang,
                store: this.store
            };
            const postParams = {
                user_id: user.id,
                products: this.entityId,
                quantity: this.quantity
            };
            this.configService.postRequest('add-to-cart', getParams, postParams)
                .subscribe(response => {
                    if (response.status === 200) {
                        this.configSettings.setCartId(response.data.id);
                        this.cartService.changCartCount(response.data.items.length);
                        resolve(true);
                    }
                    if (response.status === 412) {
                        this.product.remaining_quantity = 0;
                        this.configSettings.toggleLoading(false);
                    }
                    else {
                        this.configSettings.toggleLoading(false);
                        console.log(response);
                    }
                }, error => { console.log(error); });
        });
    });
  }

  filterOptions() {
    this.configSettings.toggleLoading(true);

    const getParams = {
        lang: this.lang,
        store: this.store.iso_code
    };

    const postParams = {
        product_id: this.product.id,
        option_id: (this.selectedAttribute.length) ? this.selectedAttribute.join(',') : '',
        attribute_id: (this.selectedOption.length) ? this.selectedOption.join(',') : ''
    };

    this.configService.postRequest('configurable-options', getParams, postParams)
        .subscribe(response => {


            this.updateStockForAttribute(response.data);

            // let configurable_option = this.product.configurable_option;

            if (response.data[0].attributes.length) {
                // this.product.configurable_option.map( option => {
                //     if (option.attribute_id === response.data[0].attribute_id) {
                //         option.attributes = [];
                //         option.attributes = response.data[0].attributes;
                //         option.attributes.sort((a, b) => a.value.toLowerCase().localeCompare(b.value.toLowerCase()));
                //     }
                // });


                this.product.images = response.data[0].images;
                this.quantityArr = [];
            } else {
                // this.product.currency_code = response.data[0].currency_code;
                this.product.final_price = response.data[0].final_price;
                this.product.regular_price = response.data[0].regular_price;
                this.product.image = response.data[0].image_url;
                this.product.images = response.data[0].images;
                // this.product.regular_price = response.data[0].regular_price;
                this.product.SKU = response.data[0].sku;
                this.product.remaining_quantity = (response.data[0].remaining_quantity) ? response.data[0].remaining_quantity : 0;
                this.entityId = response.data[0].entity_id;

                if (this.product.remaining_quantity > 0) {
                    this.setQuantityDropdown(this.product.remaining_quantity);
                } else {
                    this.quantityArr = [];
                }
            }

            this.configSettings.toggleLoading(false);
        }, error => { this.configSettings.toggleLoading(false); });
}

  updateStockForAttribute(data: any) {
    for (let configOption of data) {
        for (let productConfigOption of this.product.configurable_option) {
            if (productConfigOption.type === configOption.type) {
                for (let productAttr of productConfigOption.attributes) {
                    const productOptionId = productAttr.option_id;
                    let optionFound = false;
                    for (let configAttribute of configOption.attributes) {
                        if (configAttribute.option_id == productOptionId) {
                            optionFound = true;
                            break;
                        }
                    }
                    if (!optionFound) {
                        productAttr.value = (this.lang === 'en') ?
                            productAttr.value + " (OUT OF STOCK)"
                            : "(إنتهى من المخزن) " + productAttr.value;
                    }
                }
            }
        }
    }
    this.showAttribute = true;
  }

  showSizeGuide() {
    let dialogRef = this.dialog.open(DialogComponent, {
        data: { value: this.product.size_guide, image: true },
        height: '50%',
        width: '50%',
    });
    return false;
}

showGallery(index) {
    let dialogRef = this.dialog.open(DialogComponent, {
        data: { images: this.product.images, gallery: true },
        height: '95%',
        width: '95%',
    });
}

setQuantityDropdown(remaining_quantity: number) {
    this.quantityArr = [];
    const maxDropdownQuantity = (this.product.remaining_quantity > 10) ? 10 : this.product.remaining_quantity;
    for (let i = 1; i <= maxDropdownQuantity; i++) {
        this.quantityArr.push({ value: i, label: i });
    }
    this.quantity = 1;
}

registerBranch() {
    this.registerBranchSource(window, document, "script", "branch", function (b, r) { b[r] = function () { b._q.push([r, arguments]); }; }, { _q: [], _v: 1 }, "addListener applyCode autoAppIndex banner closeBanner closeJourney creditHistory credits data deepview deepviewCta first getCode init link logout redeem referrals removeListener sendSMS setBranchViewData setIdentity track validateCode trackCommerceEvent logEvent disableTracking".split(" "), 0);
}

registerBranchSource(b, r, a, n, c, h, _, s) {
    if (!b[n] || !b[n]._q) {
        for (; s < _.length;)
            c(h, _[s++]);
        let d = r.createElement(a);
        d.async = 1;
        d.src = "https://cdn.branch.io/branch-latest.min.js";
        let k = r.getElementsByTagName(a)[0];
        k.parentNode.insertBefore(d, k);
        b[n] = h;
    }
}

private sendGTagEvent(event, params) {
    this.configService.sendGTagEvent(event, params);
}

submitNotifyMe() {
    this.notifyMeSubmitted = true;
    Object.keys(this.notifyMeForm.controls).forEach(field => {
        const control = this.notifyMeForm.get(field);
        control.markAsTouched({ onlySelf: true });
    });
    if (this.notifyMeForm.dirty && this.notifyMeForm.valid) {
        const _form = this.notifyMeForm.value;
        this.notifyMe(_form);
    }
}

notifyMe(data) {
    data.user_id = this.userId;
    data.product_id = this.product.id;
    this.isNotifyMeLoading = true;
    this.cartService.notifyMe({}, data).subscribe((response) => {
        this.isNotifyMeLoading = false;
        if (response.status === 200) {
            this.notifyMeSubmitted = false;
            this.notifyMeForm.reset();
            $('#notifyMeModal').modal('hide');
            const message = (this.lang == 'ar') ?
                'لقد قمنا بإستلام طلبك وسنقوم بإبلاغك في حالة توفر هذا المنتج مجدادا'
                : 'We have received your request and will notify you once the product is back in stock';
            this.toastr.success(message);
        }
    });
}

vipRegister() 
{ 
    if(this.id)
    {
        this.userService.getUserSession().then(response => {                
            if (response !== false) {
              if(response.id)
                {      
                    const getParams = {
                        lang: this.configSettings.getLang()
                    }
                    
                    const postParams = {
                        user_id: response.id,
                        vip_type_id: this.id
                    }
                    
                    this.createVipService.createVip(getParams, postParams).subscribe(response => {
                        
                    });
                }   
            }
          });   
    }          
  }

  viewBag(){
    this.openCart.emit(0);
  }

}
