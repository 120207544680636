
    <!-- <p class="shop-look-header">Center image</p> -->
    <div class="container-fluid">
        <div class="shop-look-conatiner">
        <ngx-slick-carousel class="slider-mange-arbic" [ngClass]="data.length > 5 || isMobile?'carouselcenter':'slider-custom'" [config]="centerModeSlider">
            <a  ngxSlickItem class="slide" (click)="routing(item)" *ngFor="let item of data">
                <!-- <div class="w-100">
                    <figure class="">
                        <div class="testnew">
                            <img [src]="isMobile ? item.mob_image : item.image" class="carousel-imgs1">
                        </div>
                        <figcaption>
                            <div class="carousal-title uppercase">{{item?.name}}</div>
                        </figcaption>
                    </figure>
                </div> -->
                
                <app-product-item class="w-100" [hideWishlistIcon]="true" [lang]="lang" [item]="item" [currency]="currency" [store]="store" [userId]="userId"
                            [returnUrl]="'home'" [minimal]="false">
                </app-product-item>
            </a>
        </ngx-slick-carousel>

    </div>

    
</div>