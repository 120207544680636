import { AfterViewInit, Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { NavbarService } from "../config/navbar.service";
import { Category } from '../models/category';
import { ConfigSettings } from '../config/config.settings';
import { ConfigService } from '../config/config.service';
import { Faq } from '../models/faq';
// import { User } from '../models/user';
import { DialogComponent } from '../dialog/dialog.component';
// import {forEach} from '@angular/router/src/utils/collection';
import { Store } from '../models/store';
import { LocalStorage } from "@ngx-pwa/local-storage";
import { NotificationService } from '../config/notification.service';
import { Notification } from '../models/notification';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Brands } from "../models/brands";
import { UntypedFormBuilder, UntypedFormGroup, NgForm } from "@angular/forms";
import { debounceTime, expand, filter, finalize, first, map, startWith, switchMap, tap, throttleTime } from "rxjs/operators";
import { CartService } from "../config/cart.service";
import { Suggestion } from "../models/suggestion";
import { listStagger } from '../animations';
import { Subject } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";
import { PopularSearchService } from '../config/popular-search.service';
import { SupportDetails } from '../models/support-details';
import { UserService } from "../config/user.service";
import { CreateVipService } from "../services/create-vip.service";
import { User } from '../models/user';
import { AuthenticationService } from "../config/authentication.service";
import { CryptService } from "../config/crypt.service";
import { SocialAuthService , FacebookLoginProvider } from "@abacritt/angularx-social-login";
import { AnalyticsService } from '../services/analytics.service';
import { HandleCartService } from '../services/handle-cart.service';
import { HeaderUserActionsComponent } from '../header-user-actions/header-user-actions.component';
import { JwtHelperService } from '@auth0/angular-jwt';
import { SideUserActionsComponent } from '../side-user-actions/side-user-actions.component';
import { environment } from 'src/environments/environment.prod';
import { HeaderToggleService } from '../services/header-toggle.service';
import { CheckSearchBoxActiveService } from '../services/check-search-box-active.service';
import { Location } from '@angular/common';
import { CheckUserLoggedInService } from '../services/check-user-loggedin.service';
import { ShareBrandInfoService } from '../services/share-brand-info.service';
import { response } from 'express';
// import { MatDialog } from '@angular/material';
import { MatDialog } from '@angular/material/dialog';
import { WishlistService } from '../config/wishlist.service';
import { ShareHomeInfoService } from '../services/share-home-info.service';


declare var $: any;
declare var ackoo;


@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    animations: [listStagger]
})
export class HeaderComponent implements OnInit {
    user: User;
    email: string;
    password: string;
    rememberMe: boolean = false;
    submitted: boolean = false;
    hasError: boolean = false;
    message: string;
    store_code: string;
    returnUrl: string = '/home';
    device: string;
    signInText: string = 'SIGN IN';
    target: any;
    target_id: any;
    isVip: any = this.configSettings.getisVip();
    isVipText: string = '';
    userId: any;

    /* Fields for Facebook login*/
    fbHasError: boolean = false;
    guest_register: boolean = false;

    userStatus = false;
    userSession = new User();
    overlayHeight = '0px';
    sideMenuWidth = '0px';
    isSideMenuOpen = false;
    isStoreSwitcherOpen = false;
    navigationStatus: boolean = true;
    website_header_text: string;
    categories: Category[] = [];
    mainmenu: any = [];
    submenu: any = [];
    @Input() stores: Store[] = [];
    @Input() store: Store;
    hiddenOverlay: boolean = true;
    lang: string;
    q: string;
    isLoading: boolean = false;
    searchForm: UntypedFormGroup;
    suggestions: Suggestion[] = [];
    productSuggestions: any[] = [];
    brandsSuggestions: any[] = [];
    recentSearches: Suggestion[] = [];
    popularSearches: Suggestion[] = [];
    resize = new Subject();
    top_selected_category: number;
    top_selected_category_name: string;
    support_email = '';
    support_phone = '';
    showDropdown=false;
    storeDropDown=false;
    category: Category;
    unselectedCategoryList: Category[] = [];
    isCategoryLoading = false;
    showStoreNav = false;
    isEnableHeaderBottom: any = true;
    showGuestLogin: any=false;
    currency: any;
    userDetails:any;
    searchString: string = ''
    searchBoxHasData: boolean = false
    @ViewChild(HeaderUserActionsComponent) private timerComponent: HeaderUserActionsComponent;
    @ViewChild(SideUserActionsComponent) private SideUserActionsComponent: SideUserActionsComponent;
    jwtHelper = new JwtHelperService();
    
    showNavitems: boolean = false
    navbarsticky:boolean = true;
    @ViewChild('wishlistImg') wishlistImg: any; 
    isBrandHomePage: boolean = false

    storName: any;
    constructor(
        private navbarService: NavbarService,
        private dialog: MatDialog,
        private wishlistService: WishlistService,
        private configService: ConfigService,
        public configSettings: ConfigSettings,
        private notificationService: NotificationService,
        private popularSearchService: PopularSearchService,
        protected localStorage: LocalStorage,
        private translate: TranslateService,
        private route: ActivatedRoute,
        public router: Router,
        public createVipService: CreateVipService,
        private formBuilder: UntypedFormBuilder,
        private cartService: CartService,
        private userService: UserService,
        private http: HttpClient,
        private handleCartService: HandleCartService,
        private authenticationService: AuthenticationService,
        private authService: SocialAuthService,
        private cryptService: CryptService,
        private activatedRoute: ActivatedRoute,
        private analytics: AnalyticsService,
        private headerToggleService: HeaderToggleService,
        private checkSearchBoxActiveService: CheckSearchBoxActiveService,
        private location: Location,
        private checkUserLoggedInService: CheckUserLoggedInService,
        private shareBrandInfoService : ShareBrandInfoService,
        private shareHomeInfoService : ShareHomeInfoService
    ) {
        this.lang = this.configSettings.getLang();
        this.activatedRoute.queryParams.subscribe(params => {            
            this.target = params.target ? params.target : "";
            this.target_id = params.target_id ? params.target_id : "";
        });
        this.activatedRoute.params.subscribe(params => {
        });        

    }

    ngOnInit() {    
        if (this.isVip == 1) {
            this.isVipText = 'GOLD';
        } else {
            this.isVipText = '';
            this.vipRegister();
        }
        this.listenToRouteChange()
        //localStorage.setItem('selectedStore', JSON.stringify("{'id': 1,'name_en': 'Kuwait','name_ar': 'الكويت','currency_en': 'KD','currency_ar': 'د.ك','flag': 'https://d2aevxzf0n8ovr.cloudfront.net/images/15690401795d85a73342fc37.49011157.png','phonecode': '965','iso_code': 'KW','iso': 'KW'}"));
        this.checkUserSession();
        this.navbarService.getWebsiteHeaderText.subscribe(website_header_text => {
            this.website_header_text = website_header_text;
        });
        this.navbarService.isEnableHeaderBottomTrigger.subscribe(headerBottom => {     
            this.isEnableHeaderBottom = headerBottom;
        });
        this.navbarService.headreTopMenuMobileEnableTrigger.subscribe(headerBottom => {
            this.headreTopMenuMobileEnable = headerBottom;
        });
        this.navbarService.changeShowGuestLogin.subscribe(isGuestLogin => {      
            this.showGuestLogin = isGuestLogin;
        });  this.configSettings.getStoreObject().then((result) => {
            this.store_code = result.iso_code;
            this.currency = result['currency_' + this.lang];

            
        });
        this.navbarService.change.subscribe(status => {
            this.navigationStatus = status;
        });
        this.checkUserLoggedInService.getsetUserLoginStatus().subscribe(res=>{
            if (res == false) {
                this.userSession = new User();
                this.userId='';
                this.timerComponent.userSession=new User();
                this.timerComponent.userId='';
                // window.location.href="/";
            }else{
                this.checkUserSession();
                this.timerComponent?.checkUserSession();
                 this.SideUserActionsComponent?.ngOnInit();
                 this.user = res;
            }
        })
        this.getRecentSearch();
        // this.readAllCategories();
        this.readPopularSearch();
        // this.readMainMenu();
        this.searchForm = this.formBuilder.group({
            q: null
        });
        
        // this.searchForm.get('q').valueChanges.pipe(
        //     startWith(''), // Emit an initial value of an empty string
        //     debounceTime(300),
        //     tap(() => this.isLoading = true),
        //     filter(value => value !== null && value !== undefined), // Filter out null or undefined values
        //     switchMap(value =>
        //       this.cartService.search({ q: value ? value.toString() : '' }).pipe(
        //         finalize(() => this.isLoading = false),
        //       )
        //     )
        //   ).subscribe(results => {                            
        //     if (this.searchForm.get('q').value == '') {
        //         document.body.style.overflowY = "unset";
        //         this.searchString = ''
        //     } else {
        //        this.searchString = this.searchForm.get('q').value
               
        //     }
                            
        //     this.suggestions = [];
        //     this.productSuggestions = [];
        //     if (results.body.status === 200) {
        //         this.configSettings.getStoreObject().then((result) => {
        //             this.store_code = result.iso_code;
        //             this.currency = result['currency_' + this.lang];
        //         });
        //         if (results.body.data.categories.length) {
        //             this.suggestions = results.body.data.categories;
        //         }
                
        //         if (results.body.data.products.length) {
        //             this.productSuggestions = results.body.data.products;
        //             let overlay = document.querySelector('.cdk-overlay-connected-position-bounding-box') as HTMLElement;
        //             overlay.style.backgroundColor = 'rgba(0, 0, 0, .5)';
        //             document.body.style.overflowY = "hidden";

        //         } if (results.body.data.products.length == 0) {
        //             let overlay = document.querySelector('.cdk-overlay-connected-position-bounding-box') as HTMLElement;
        //             overlay.style.backgroundColor = '';
        //             document.body.style.overflowY = "unset";
        //         }

        //     } else {
        //         // let overlay = document.querySelector('.cdk-overlay-connected-position-bounding-box') as HTMLElement;
        //         // overlay.style.backgroundColor = '';
        //     }
        // });

        // let selectedCategoryId = this.getCategoryLocalStorage();
        // if (selectedCategoryId !== 0) {
        //     this.top_selected_category = selectedCategoryId;
        //     this.clickedCategory = selectedCategoryId;
        // }
        // else {
        //     this.top_selected_category = this.configSettings.defaultCategory;
        //     this.clickedCategory = this.configSettings.defaultCategory;
        //     this.setCategoryLocalStorage(this.top_selected_category);
        // }

        // this.selectNavBar()

        this.navbarService.currentSupportDetails.subscribe((data) => {
            if (data) {
                this.support_email = data.support_email;
                this.support_phone = data.support_phone;
            }
        });
        this.localStorage.getItem<any>('support_details').subscribe((result) => {
            if (result) {
                const SupportDetails = <SupportDetails>result;
                this.support_email = SupportDetails.support_email;
                this.support_phone = SupportDetails.support_phone;
            }
        });

        this.handleCartService.receivedItem().subscribe((message) => {
            // this.showAddedToCart(message);
        })


        this.headerToggleService.getNavItems().subscribe(res => {
            this.showNavitems = res
            if (this.showNavitems === false) {
                this.showNavitems = this.configSettings.getNavBarStatus()
            }
            this.selectNavBar()
        })

        this.headerToggleService.getCategories().subscribe(res => {
            this.readAllCategories();
            
        })
        this.getHomeInfo()
        this.getBrandInfo()
    }
    private debounceTimeout: any;
    onSearchKeyup(event: Event): void {
        const searchInputValue = (event.target as HTMLInputElement).value;
        this.debounceSearch(searchInputValue);
      }
    
      private debounceSearch(value: string): void {
        if (this.debounceTimeout) {
          clearTimeout(this.debounceTimeout);
        }
    
        this.debounceTimeout = setTimeout(() => {
          this.search(value);
        }, 300);
      }
    
      private search(value: string): void {      
        if(!location.href.includes('brands') ){
            this.brandIdForSearch=''
        }
        this.cartService.search({ q: value ? value.toString() : ''},1,this.brandIdForSearch)
          .subscribe(results => {                            
            if (this.searchForm.get('q').value == '') {
                // document.body.style.overflowY = "unset";
                this.searchString = ''

                // to show popular search if 'searchString' is empty
                this.isPopularSearch = true
                setTimeout(() => {
                    this.getHomeInfo()
                }, 1000);
            } else {
               this.searchString = this.searchForm.get('q').value
               
            }
                            
            this.suggestions = [];
            this.productSuggestions = [];
            this.brandsSuggestions = []
            if (results.body.status === 200) {
                this.isPopularSearch = false
                this.configSettings.getStoreObject().then((result) => {
                    this.store_code = result.iso_code;
                    this.currency = result['currency_' + this.lang];
                });
                if (results.body.data.categories.length) {
                    this.suggestions = results.body.data.categories;
                }
                if (results.body.data.brands.length) {
                    this.brandsSuggestions = results.body.data.brands;
                }
                
                if (results.body.data.products.length) {
                    this.productSuggestions = results.body.data.products;
                    // let overlay = document.querySelector('.cdk-overlay-connected-position-bounding-box') as HTMLElement;
                    // overlay.style.backgroundColor = 'rgba(0, 0, 0, .5)';
                    // document.body.style.overflowY = "hidden";

                } 
                if (results.body.data.products.length == 0) {
                    // let overlay = document.querySelector('.cdk-overlay-connected-position-bounding-box') as HTMLElement;
                    // overlay.style.backgroundColor = '';
                    // document.body.style.overflowY = "unset";
                }
                this.searchBoxHasData = this.suggestions.length > 0 || this.brandsSuggestions.length > 0 || this.productSuggestions.length > 0 ?  true : false

            } else {
                // let overlay = document.querySelector('.cdk-overlay-connected-position-bounding-box') as HTMLElement;
                // overlay.style.backgroundColor = '';
            }
        });
      }

    activateCategoryAccordion(){
      let accordion = document.getElementById('accordion-ul')
      let collection = accordion.children
        for (let i = 0; i < collection.length; i++) {

            if (`category-${this.clickedCategory}` == collection[i].id) {
                let selectedCategory = collection[i] as HTMLElement
                selectedCategory.classList.add('expand')
            } else {
                let selectedCategory = collection[i] as HTMLElement
                selectedCategory.classList.remove('expand')
            }
        }
    }

    toggleNavStore() {
        this.showStoreNav = !this.showStoreNav;
        return this.showStoreNav;
    }

    displayFn(query: Suggestion) {
        if (query) {
            return query.name
        }
    }



    optionSelected($event, type: string) {
        if ($event) {
            this.searchForm.patchValue({
                q: '',
            });
            // this.doBlur();
            const body = document.body
            // this.closeSearch();
            /**
             * Add selected option to local recent_search
             */
            const idToNavigate = this.isPopularSearch ? $event.type_id : $event.id;
            this.localStorage.getItem<any>('recent_search').subscribe((recent_search: any) => {
                let recentSearchArr = [];
                if (recent_search) {
                    const index = recent_search.findIndex(recent_search => recent_search.id === idToNavigate);
                    recentSearchArr = recent_search;
                    if (index < 0) {
                        recentSearchArr.push($event);
                    }
                } else {
                    recentSearchArr = [$event];
                }
                this.localStorage.setItem('recent_search', recentSearchArr).subscribe((result) => {
                    this.recentSearches = <Suggestion[]>recentSearchArr;
                    if (type === "C") {
                        this.router.navigate(['/category/', this.configSettings.cleanUrl($event.name), idToNavigate]);
                        // window.location.href = '/category/' + this.configSettings.cleanUrl($event.name) + '/' + idToNavigate;
                    } else if (type === "P") {
                        this.router.navigate(['/product/detail/', idToNavigate]);
                        // window.location.href = '/product/detail/' + idToNavigate;
                    } else if (type === "B" || type === "BR") {
                        this.router.navigate(['/brand/', this.configSettings.cleanUrl($event.name), idToNavigate]);
                        // window.location.href = '/brand/' + this.configSettings.cleanUrl($event.name) + '/' + idToNavigate;
                    } else {
                        this.router.navigate(['/product/detail/', idToNavigate]);
                    }
                    this.searchBox = false
                    body.style.overflow = 'unset'
                });

            });
        }
    }

    getRecentSearch() {
        this.localStorage.getItem<any>('recent_search').subscribe((recent_search) => {

            if (recent_search) {
                this.recentSearches = <Suggestion[]>recent_search;
            }
        });
    }

    loginButtonClicked() {
        this.showGuestLogin = false;
        this.isGuestLogin = '0';
        this.configSettings.disableLoader=1;  
    }
    closeLogInPopup(){
        this.showGuestLogin=false;        
    }

    changeLanguage(event: any) {
        let setLang = (this.lang === 'ar') ? 'en' : 'ar';
        this.configSettings.setLang(setLang);
        var url = window.location.href;
        let reloadUrl = url.replace("/" + this.lang, "/" + setLang);
        history.pushState({}, '', reloadUrl);
        /* Removing notification stored in local storage */
        this.localStorage.removeItem('notifications').subscribe(() => {
            window.location.reload()
        });
    }


    // changeLanguage() {
    //     let setLang = (this.lang === 'ar') ? 'en' : 'ar';
    //      this.configSettings.setLang(setLang);
    //      var url = window.location.href;
    //      let reloadUrl = url.replace("/" + this.lang, "/" + setLang);
    //      history.pushState({}, '', reloadUrl);
    //      location.reload();
    //    }

    doFocus() {
        document.querySelector('nav.header-nav').classList.add('search-active');
    }

    doBlur() {
        document.querySelector('nav.header-nav').classList.remove('search-active');
    }

    hideSearch(event: any) {
        document.querySelector('nav.header-nav').classList.remove('search-active');
        /* Do not hide  if user has clicked inside the dropdown */
        /*var element = document.getElementById('mat-autocomplete-1');
        if (element) {
            if(!element.contains(event.target)) {
                document.querySelector('nav.header-nav').classList.remove('search-active');
            }
        }*/
    }

    clearRecentSearchHistory() {
        this.isLoading = true;
        this.localStorage.removeItem('recent_search').subscribe(() => {
            this.recentSearches = [];
            this.isLoading = false;
        }, error => { this.isLoading = false; });
    }

    // openSearch() {
    //     this.checkSearchBoxActiveService.setSearchBoxActive(true)
    //     this.closeNav();
    //     this.overlayHeight = 'auto';
    //     document.body.style.overflowY = "hidden";
    //     return false;
        
    // }

    // closeSearch() {                
    //     document.body.style.overflowY = "unset";
    //     this.checkSearchBoxActiveService.setSearchBoxActive(false)
    //     this.overlayHeight = '0px';
    //     return false;
    // }

    openNav() {
        // this.closeSearch();
        if (!this.isSideMenuOpen) {
            this.isSideMenuOpen = true;
            // this.sideMenuWidth = '100%';
            document.body.style.overflowY = "hidden";
            // document.querySelector('body').classList.add('menu-open');
        } else {
            this.isSideMenuOpen = false;
            // this.sideMenuWidth = '0px';
            document.body.style.overflowY = "auto";
            // document.querySelector('body').classList.remove('menu-open');
        }
        
        // this.hiddenOverlay1 = true
        this.activateCategoryAccordion()
        return false;
    }


    hidenave: any = true;
    hiddenOverlay1: boolean = true;


    toggleOverlay1(event: any) {
        this.hiddenOverlay1 = !this.hiddenOverlay1;
        if (this.hidenave == true) {
            this.hidenave = false;
            // this.closeNav();

        } else {
            // this.closeNav();
            this.hidenave = true;


        }
    }



    hideOverlay1(event: any) {
        if (this.searchString != '') {
            document.body.style.overflowY = "unset";
        }
        /* Do not hide overlay if user has clicked inside the dropdown*/
        var element = document.getElementById('side-menu');
        if (!element.contains(event.target)) {
            this.hiddenOverlay1 = true;
            this.closeNav();
        }
        this.hidenave = true;
    }

    isGuestLogin: any = '0';


    closeNav() {
        if (this.searchString != '') {
        document.body.style.overflowY = "unset";
        }
        this.isSideMenuOpen = false;
        this.sideMenuWidth = '0px';
        // document.querySelector('body').classList.remove('menu-open');
        // this.hiddenOverlay1 = true;
    }

    openStoreSwitcher() {
        if (!this.isStoreSwitcherOpen) {
            this.isStoreSwitcherOpen = true;
            document.querySelector('body').classList.add('store-switcher-open');
            /**
             * Removing sidemenu
             */
            this.isSideMenuOpen = false;
            this.sideMenuWidth = '0px';
            document.querySelector('body').classList.remove('menu-open');
        } else {
            this.isStoreSwitcherOpen = false;
            document.querySelector('body').classList.remove('store-switcher-open');
        }
        return false;
    }

    toggleMenu(event: any) {
        $('#menus ul.main-nav').addClass('d-none');
        $('#' + event.target.target).removeClass('d-none');
        $('.top-category-menu .active').removeClass('active');
        $('#' + event.target.id).addClass('active');

        window.localStorage.setItem('top-category', event.target.id);
        this.top_selected_category = event.target.id;
        if (this.top_selected_category == 47) {
            $('.brand-menu-item').each(function (i) {
                $(this).addClass('d-none');
            });
        } else {
            $('.brand-menu-item').each(function (i) {
                $(this).removeClass('d-none');
            });
        }
        this.categories.map(category => {
            if (+category.id === +this.top_selected_category) {
                this.top_selected_category_name = category.name;
            }
        });
    }
    readMainMenu() {
        const params = {
            lang: this.lang
        };
        this.configService.readRequest('root-categories', params)
            .subscribe(response => {
                if (response.body.data) {
                    this.mainmenu = response.body.data;
                }
            });
    }

    setCategory(category: Category): void {
        //this apply category we are making 0 bcuz  after selecting we need to make the other tabs of header active..
        this.allCatData.apply_default_category=0;
        this.allCatData.default_root_category=0;
        //this apply category we are making 0 bcuz  after selecting we need to make the other tabs of header active..
        this.setCategoryLocalStorage(category.id);
        this.category = category;
        this.top_selected_category = category.id;
        this.top_selected_category_name = category.name;
        this.setCategoryNameToLocalStorage(this.top_selected_category_name)
        this.unselectedCategoryList = [];
        this.categories.map(category => {
            if (+category.id !== +this.top_selected_category) {
                this.unselectedCategoryList.push(category);
            }
        });
        this.navbarService.triggerCategoryChange(category.id);
        this.configSettings.toggleLoading(true);
        let url = window.location.pathname;
        if(url.includes('/home')){
            this.router.navigate(['/']);
        }
        
        // this.updateUrl()
        // this.closeNav()
        
    }

    setCategoryLocalStorage(categoryId: number): void {
        localStorage.setItem('top_selected_category', categoryId.toString());
        this.headerToggleService.setNavItems(true)
    }

    setCategoryNameToLocalStorage(categoryName:string){    
        localStorage.setItem('userSelectedCategory', categoryName);
    }

    getCategoryLocalStorage(): number {
        return +localStorage.getItem('top_selected_category');
    }

    allCatData:any;
    readAllCategories() {
        const params = {
            lang: this.lang
        };
        this.isCategoryLoading = true;
        this.configService.readRequest('all-categories', params)
            .subscribe(response => {
                if (response.body.data) {
                    this.allCatData=response.body;
                    this.categories = <Category[]>response.body.data;
                    ///this line of code is added to select and show the menus of default root categories 
                    if (this.allCatData.apply_default_category === 1) {
                        const defaultCategory = this.categories.find(ele => ele.id === this.allCatData.default_root_category);
                        if (defaultCategory) {
                          this.setCategory(defaultCategory);
                        }
                      }
                    ///this line of code is added to select and show the menus of default root categories 

                    this.unselectedCategoryList = [];
                    this.categories.map(category => {

                        category.name = category.name.toLowerCase()
                        category.name = category.name.charAt(0).toUpperCase() + category.name.slice(1);

                        if (+category.id === +this.top_selected_category) {
                            this.category = category;
                            this.top_selected_category_name = category.name;
                        }
                        else {
                            this.unselectedCategoryList.push(category);
                        }
                    });
                    
                    if (typeof this.top_selected_category === "undefined") {
                        
                        this.category = this.categories[0];
                        this.top_selected_category = this.categories[0].id;
                        this.top_selected_category_name = this.categories[0].name;

                        this.unselectedCategoryList = [];
                        this.categories.map(category => {
                            if (+category.id !== +this.top_selected_category) {
                                this.unselectedCategoryList.push(category);
                            }
                        });
                    }
                    this.isCategoryLoading = false;
                }
            });
    }

    readPopularSearch() {
        // this.popularSearchService.popularSearch.subscribe(data => this.popularSearches = data);
        this.localStorage.getItem<any>('popular_searches').subscribe((popular_searches) => {
            if (popular_searches) {
                this.popularSearches = <Suggestion[]>popular_searches;
            } else {
                this.popularSearchService.popularSearch.subscribe(data => this.popularSearches = data);
            }
        });
    }

    toggleOverlay(event: any) {
        this.hiddenOverlay = !this.hiddenOverlay;
    }

    hideOverlay(event: any) {
        /* Do not hide overlay if user has clicked inside the dropdown*/
        var element = document.getElementById('store-change-dropdown');
        if (!element.contains(event.target)) {
            this.hiddenOverlay = true;
        }
    }

    changeStore(event: any, id: number) {
        const index = this.stores.findIndex(store => store.id === id);
        var selectedStore = this.stores[index];
        this.setLocalStore(selectedStore);
        localStorage.setItem('selectedStore', JSON.stringify(selectedStore));
        window.location.reload();
    }

    onSubmit() {
        const q = this.searchForm.value.q;
        if (q) {
            // window.location.href = '/search?q=' + this.q;
            this.router.navigate(['/search'], { queryParams: { q: q} })
            // this.closeSearch();
            this.searchBox = false;
            const body = document.body
            body.style.overflow = 'unset'
          

        }
       
    }

    /**
     * After user submit login form
     * @param loginForm
     */
    onSubmitLogin(loginForm: NgForm) {
        this.configSettings.toggleLoading(true);
        this.signInText = 'Loading...';
        this.doLogin();
        loginForm.reset();
        
    }

    /**
     * Login user
     */
    doLogin() {
        this.authenticationService.login(this.email, this.password)
            .pipe(first())
            .subscribe(
                response => {
                    if (response.status === 200) {
                        this.cryptService.doEncrypt(JSON.stringify(response.data)).then(encryptedString => {
                            this.localStorage.setItem('userInfo', encryptedString).subscribe(() => {
                                this.configSettings.disableLoader=2  
                                this.configService.sendGTagEvent('login', {
                                    user_id: response.data.id,
                                    user_firstname: response.data.first_name,
                                    user_lastname: response.data.last_name,
                                    email: response.data.email
                                });                                
                                setTimeout(() => {
                                    this.configSettings.setLoginUserData(response.data)
                                    //after login manage without reload
                                    this.checkUserSession();
                                    this.timerComponent.checkUserSession();
                                    // this.timerComponent.getCartItems();
                                    this.configSettings.setIsReferralCode(response.data.referral_code)
                                    this.SideUserActionsComponent.ngOnInit();
                                    this.configSettings.toggleLoading(false);
                                    this.checkUserLoggedInService.setUserLoginStatus(true)
                                }, 800);
                                this.cartService.uploadOfflineCart(response.data.id).then(() => {
                                    this.cartService.clearStorage('cart');
                                    // this.redirectTo();
                                    document.getElementById('closeLoginModal').click();
                                });
                            });
                        });
                      
                    } else {
                        this.submitted = true;
                        this.hasError = true;
                        this.message = 'You have entered an invalid email or password';
                        this.configSettings.toggleLoading(false);
                        this.signInText = 'Sign in Now';
                    }
                }, error => {
                    this.configSettings.toggleLoading(false);
                    this.signInText = 'Sign in Now';
                }
            );
    }

    redirectTo() {
        //this.router.navigate([this.returnUrl]);
        // window.location.href = this.returnUrl;
        window.location.reload();
    }

    clickedCategory: any;
    openSideMenu(id) {
        this.clickedCategory = id.substring(9);
        if (document.getElementById(id).classList.contains('expand')) {
            document.getElementById(id).classList.remove('expand');

            let children = document.getElementById(id).querySelectorAll('.expand');
            for (let i = 0; i < children.length; i++) {
                children[i].classList.remove('expand');
            }
        } else {
            let siblings = document.getElementById(id).parentElement.parentElement.querySelectorAll('.expand');
            for (let i = 0; i < siblings.length; i++) {
                siblings[i].classList.remove('expand');
            }
            document.getElementById(id).classList.add('expand');

            let siblingUl = document.getElementById(id).querySelectorAll('ul');
            if (!siblingUl.length) {
                this.openNav();                
                this.hiddenOverlay1 = true;
                return true;
            }
        }

        return false;
    }

    openSubNav(id, $event) {
        let nav = document.querySelectorAll('ul.main-nav');
        for (let i = 0; i < nav.length; i++) {
            nav[i].classList.remove('top-active');
        }
        document.getElementById(id).classList.add('top-active');
        if (id === 'category-47') {
            $('.brand-menu-item').each(function (i) {
                $(this).addClass('d-none');
            });
        } else {
            $('.brand-menu-item').each(function (i) {
                $(this).removeClass('d-none');
            });
        }

        document.querySelector('.side-menu-top-category').querySelector('.active').classList.remove('active');
        $event.target.classList.add('active');
        return false;
    }

    @HostListener('window:resize', ['$event.target'])
    onresize(target) {
        this.resize.next(target.outerWidth);
    }

    setLocalStore(selectedStore: any) {
        this.localStorage.setItem('store_object', selectedStore).subscribe(() => { });
    }
    checkUserSession() {
        this.userService.getUserSession().then(response => {
            if (response) {
                this.userSession.id = response.id;
                this.userId = response.id;
                this.userSession.first_name = response.first_name;
                this.userSession.last_name = response.last_name;
                this.userSession.email = response.email;

                ackoo.identify(response.id, {
                    name: response.first_name,
                    email: response.email,
                });

            }

            this.userStatus = true;
        });
    }
    toggleDropdown() {
        this.showDropdown = !this.showDropdown;
        // this.storeDropDown=true
      }
    /**
     * Sign in using facebook
     */
    signInWithFB(): void {
        this.configSettings.toggleLoading(true);
        try {
            this.authService.signIn(FacebookLoginProvider.PROVIDER_ID).then(
                (userData) => {
                    if (!userData.email) {
                        this.message = 'You have problem in your facebook account. Please try again later.';
                        this.fbHasError = true;
                        this.configSettings.toggleLoading(false);
                        return false;
                    }

                    const param = {
                        facebook_id: userData.id,
                        first_name: userData.firstName,
                        last_name: userData.lastName,
                        gender: '',
                        dob: '',
                        email: userData.email,
                        social_register_type: 'F',
                        device_token: '',
                        device_type: 'W',
                        device_model: (this.device) ? this.device : '',
                        app_version: (this.device) ? this.device : '',
                        store: (this.store_code == '' || this.store_code == undefined) ? 'KW' : this.store_code,
                        os_version: (window.navigator.platform) ? window.navigator.platform : ''
                    };
                    $('#loginModal').modal('hide');
                    this.configSettings.toggleLoading(true);
                    this.authenticationService.socialRegister(param)
                        .pipe(first())
                        .subscribe(
                            response => {
                                if (response.status === 200) {
                                    const user = response.data as User;
                                    this.checkUserLoggedInService.setUserLoginStatus(true)
                                    this.analytics.completeRegistration(
                                        +user.id,
                                        (user.first_name + ' ' + user.last_name).trim(),
                                        user.email,
                                        'Social Login',
                                    );
                                    this.cryptService.doEncrypt(JSON.stringify(response.data)).then(encryptedString => {
                                        this.localStorage.setItem('userInfo', encryptedString).subscribe(() => {
                                            this.cartService.uploadOfflineCart(response.data.id).then(() => {
                                                this.configSettings.disableLoader=2  
                                                this.cartService.clearStorage('cart');
                                                // this.redirectTo();
                                                document.getElementById('closeLoginModal').click();
                                                setTimeout(() => {
                                                    this.configSettings.setLoginUserData(response.data)
                                                    //after login manage without reload
                                                    this.checkUserSession();
                                                    this.timerComponent.checkUserSession();
                                                    this.checkUserLoggedInService.setUserLoginStatus(true)
                                                    // this.timerComponent.getCartItems();
                                                    this.SideUserActionsComponent.ngOnInit();
                                                    this.configSettings.toggleLoading(false);
                                                }, 800);
                                            });
                                        });
                                    });
                                } else {
                                    this.fbHasError = true;
                                    this.message = response.message;
                                    this.configSettings.toggleLoading(false);
                                }
                            }, error => { this.configSettings.toggleLoading(false); }
                        );
                }, error => { this.configSettings.toggleLoading(false); }
            );
        } catch (e) {
            console.log(e);
            this.configSettings.toggleLoading(false);
        }
    }

    vipRegister() {
        if (this.target_id) {
            this.userService.getUserSession().then(response => {
                if (response !== false) {
                    if (response.id) {
                        const getParams = {
                            lang: this.configSettings.getLang()
                        }

                        const postParams = {
                            user_id: response.id,
                            vip_type_id: this.target_id
                        }

                        this.createVipService.createVip(getParams, postParams).subscribe(response => {
                            this.isVipText = 'GOLD';
                        });
                    }
                }
            });
        }
    }

    //REGISTER LOGICS

    first_name: string = '';
    last_name: string = '';
    phone: string = '';
    confirm_password: string = '';
    referred_by_code: string = '';
    registerSubmitted = false;
    registerHasError = false;
    registerMessage: string;
    passwordValid: boolean = true;
    btnDisabled: boolean = false;
    phoneValidationPattern = "\\d{8,}";
    isEmailValid = true;
    phoneErr: boolean = false
    isPasswordValid: boolean = true
    countryCode = '+965'
    isFormValid: boolean = true
    fullNameErr: boolean = false

    onSubmitRegister(registerForm: NgForm) {
        this.registerSubmitted = true;
        let isFormValid: boolean = true

        this.isFormValid = this.validationsForRegister()
                if (this.first_name.split(" ")[1] == undefined) {
                  this.fullNameErr = true  
                } else if(this.first_name.split(" ")[1] == ''){
                    this.fullNameErr = false  
                }
        if ( isFormValid && this.fullNameErr == false) {
            this.configSettings.toggleLoading(true);
            this.doRegister(registerForm);
        } else {
          
            setTimeout(() => {
                // this.registerSubmitted = false;
                // this.registerHasError = false;
                // this.registerMessage = '';
                // this.hasError = false;
            }, 3000);
        }
    }

    clearFullNameErr(event:any){
        if (event.target.value.length > 2) {
            this.fullNameErr = false
        }
    }

    clearRegister(){
        this.first_name = '';
        this.last_name = '';
        this.phone = '';
        this.confirm_password = '';
        this.referred_by_code = '';
        this.registerSubmitted = false;
        this.registerHasError = false;
        this.registerMessage = '';
        this.passwordValid = true;
        this.btnDisabled = false;
        this.phoneValidationPattern = "\\d{8,}";
        this.isEmailValid = true;
        this.phoneErr = false
        this.isPasswordValid = true
        this.countryCode = '+965'
        this.isFormValid = true
    }

    validationsForRegister(){
        this.registerSubmitted = true;
        this.registerHasError = true;
        let isFirstNameValid: boolean = true;
        let arePasswordSame: boolean = true;

        this.registerMessage = (this.lang === 'en') ? 'Please fill all the required fields!' : 'يرجى ملء جميع الحقول المطلوبة!';
        if (this.first_name == undefined || this.last_name == undefined || this.email == undefined) {
            isFirstNameValid = false
            this.registerMessage = (this.lang === 'en') ? 'Please fill all the required fields!' : 'يرجى ملء جميع الحقول المطلوبة!';
        }
        if (this.password) {
            this.isPasswordValid = this.validatePassword(this.password)
            this.registerMessage = (this.lang === 'en') ? 'Password should be at least 8 characters, min 1 Uppercase, 1 Lowercase, 1 Number, 1 special character' : 'يجب أن تتكون كلمة المرور من 6 أحرف على الأقل';
        }
        // if (this.password != this.confirm_password) {
        //     arePasswordSame = false
        //     this.registerMessage = (this.lang === 'en') ? 'Your password and confirmation password do not match.' : 'كلمة المرور وتأكيد كلمة المرور غير متطابقين.';
        // }
        if (this.email && this.email != '') {
            this.isEmailValid =  this.validateEmail(this.email)
        }
    
        // console.log(this.phoneErr,'this.phoneErr');
        // console.log(isFirstNameValid,'isFirstNameValid');
        // console.log(this.isEmailValid,'this.isEmailValid');
        // console.log(this.isPasswordValid,'this.isPasswordValid');
        // console.log(arePasswordSame,'arePasswordSame');
        
        
        // if all are true and phoneErr is false the form is invalid
        if (this.phoneErr == false|| isFirstNameValid || this.isEmailValid || this.isPasswordValid || arePasswordSame) {
            this.registerMessage = ''
            return false
        } else {
            return true
        }
       
    }

    // to validate password
    validatePassword(password: any) {

            
            var regex = /(?=[A-Za-z0-9@#$%^&+!=]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+!=])(?=.{8,}).*$/;
    
            if (regex.test(password)) {
                this.registerMessage = ''
                return true
            }
            else {
                return false
            }
    }

    // validate email
    validateEmail(email: any) {
        const re =
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/;
        //return re.test(email);
        if (re.test(email)) {
            return true;
        } else {
            return false;
        }
    }

    // validate phone
    validatePhone(event: any) {
        const pattern = /[0-9\+\-\ ]/;
        let inputChar = String.fromCharCode(event.charCode);
        
        if (event.keyCode != 7 && !pattern.test(inputChar)) {
            this.phoneErr = true
            event.preventDefault()
        } else {
            this.phoneErr = false

        }
    }   

    doRegister(registerForm:NgForm) {        
        try {
            const param = {
                first_name: this.first_name.split(" ")[0],
                last_name: this.first_name.split(" ")[1],
                email: this.email,
                phone: this.phone,
                password: this.password,
                referred_by_code: this.referred_by_code,
                newsletter_subscribed: "0",
                device_type: 'W',
                store: (this.store_code == '' || this.store_code == undefined) ? 'KW' : this.store_code,
                device_model: this.device,
                app_version: this.device,
                os_version: window.navigator.platform
            };            
            this.authenticationService.register(param)
                .pipe(first())
                .subscribe(
                    async response => {
                        if (response.status === 200) {
                            const user = response.data as User;
                            this.analytics.completeRegistration(
                                +user.id,
                                (user.first_name + ' ' + user.last_name).trim(),
                                user.email,
                                'Email',
                            );
                            this.cryptService.doEncrypt(JSON.stringify(response.data)).then(encryptedString => {
                                this.localStorage.setItem('userInfo', encryptedString).subscribe(() => {
                                    this.configSettings.disableLoader=2  
                                    this.configService.sendGTagEvent('register', {
                                        user_id: response.data.id,
                                        user_firstname: response.data.first_name,
                                        user_lastname: response.data.last_name,
                                        email: response.data.email
                                    });
                                    setTimeout(() => {
                                        this.configSettings.setLoginUserData(response.data)
                                        //after login manage without reload
                                        this.checkUserSession();
                                        this.configSettings.setIsReferralCode(response.data.referral_code)
                                        this.timerComponent.checkUserSession();
                                        this.checkUserLoggedInService.setUserLoginStatus(true)
                                        // this.timerComponent.getCartItems();
                                        this.SideUserActionsComponent.ngOnInit();
                                        this.configSettings.toggleLoading(false);
                                        registerForm.reset();
                                        document.getElementById('closeLoginModal').click();
                                    });
                                    this.cartService.uploadOfflineCart(response.data.id).then(() => {
                                        this.cartService.clearStorage('cart');
                                        //   this.redirectTo();
                                        document.getElementById('closeLoginModal').click();
                                    });
                                });
                            });
                        } else {
                            this.registerSubmitted = true;
                            this.registerHasError = true;
                            if (response.status === 406) {
                                this.hasError = true;
                                this.registerMessage = (this.lang === 'en') ? 'User with same email already exists.' : 'المستخدم بنفس البريد الإلكتروني موجود بالفعل.';
                            } else if (response.status === 500) {
                                this.hasError = true;
                                this.registerMessage = (this.lang === 'en') ? 'There was an error processing the request. Please try again later.' : 'كان هناك خطأ في معالجة الطلب. الرجاء معاودة المحاولة في وقت لاحق.';
                            }
                            await setTimeout(() => {
                                this.registerSubmitted = false;
                                // this.registerHasError = false;
                                // this.registerMessage = '';
                            }, 3000);
                            /*this.registerSubmitted = true;
                            this.registerHasError = true;
                            this.registerMessage = response.message;*/
                            this.configSettings.toggleLoading(false);
                        }
                    }, error => { this.configSettings.toggleLoading(false); }
                );
        } catch (e) {
            console.log(e);
            this.configSettings.toggleLoading(false);
        }
    }

    animateLabel($event) {

        if ($event.type === 'focus') {
            $event.target.parentElement.classList.add("focused");
        } else {
            if ($event.target.value === "") {
                $event.target.parentElement.classList.remove("focused");
            }
        }
    }

    checkPasswordLength($event) {
        if ($event.target.value.length >= 6) {
            this.passwordValid = true;
            this.btnDisabled = true;
        } else {
            this.passwordValid = false;
        }
    }

    //GUEST LOGIN
    /*Fields for Register form*/
    registerName: string;
    registerFullname: string;
    registerEmail: string;
    registerPassword: string;
    registerConfirmPassword: string;
    
    registerUrl: string = "/register";
    onGuestRegisterSubmit(guestRegisterForm: NgForm) {
        this.configSettings.toggleLoading(true);
        this.doGuestRegister();
        guestRegisterForm.reset();
    }

    /**
     * Register guest user
     */
    guestContinue: any = 'CONTINUE';
    doGuestRegister() {
        this.guestContinue = 'LOADING...'
        var fullNameArray = this.registerFullname.split(' ');
        var firstName = fullNameArray[0];
        var lastName = fullNameArray[1] ? fullNameArray[1] : '';
        try {
            const param = {
                first_name: firstName,
                last_name: lastName,
                email: this.registerEmail,
                device_type: 'W',
                device_model: this.device,
                app_version: this.device,
                store: (this.store_code == '' || this.store_code == undefined) ? 'KW' : this.store_code,
                os_version: window.navigator.platform
            };
            this.authenticationService.guestRegister(param)
                .pipe(first())
                .subscribe(
                    response => {
                        if (response.status === 200) {
                            this.cryptService.doEncrypt(JSON.stringify(response.data)).then(encryptedString => {
                                this.localStorage.setItem('userInfo', encryptedString).subscribe(() => {
                                    this.configSettings.disableLoader=2  
                                    setTimeout(() => {
                                        this.configSettings.setLoginUserData(response.data)
                                        //after login manage without reload
                                        this.checkUserSession();
                                        this.checkUserLoggedInService.setUserLoginStatus(true)
                                        this.timerComponent.checkUserSession();
                                        // this.timerComponent.getCartItems();
                                        this.SideUserActionsComponent.ngOnInit();
                                        this.configSettings.toggleLoading(false);
                                    }, 1000);
                                    this.cartService.uploadOfflineCart(response.data.id).then(() => {
                                        this.cartService.clearStorage('cart');
                                        this.guestContinue = 'CONTINUE';
                                        // this.redirectTo();
                                        document.getElementById('closeLoginModal').click();

                                    });
                                });
                            });
                            this.analytics.login(
                                String("Guest")
                            );
                        } else {
                            this.registerSubmitted = true;
                            this.registerHasError = true;
                            this.registerMessage = response.message;
                            this.configSettings.toggleLoading(false);
                            this.guestContinue = 'CONTINUE';
                            setTimeout(function () {
                                this.registerSubmitted = false;
                                this.registerHasError = false;
                                this.registerMessage = '';
                            }, 5000);
                        }
                    }, error => { this.configSettings.toggleLoading(false); }
                );
        } catch (e) {
            console.log(e);
            this.guestContinue = 'CONTINUE';
            this.configSettings.toggleLoading(false);
        }
    }

    loginClicked() {
        document.getElementById('nav-profile-tab').click();
    }

    registerClicked() {
        document.getElementById('nav-home-tab').click();
    }

    itemAdded: any = false;
    cartItemAdded: any;
    showAddedToCart(product: any) {
        if (product.id != undefined) {
            this.itemAdded = true;
            setTimeout(() => {
                this.itemAdded = false;
            }, 5000);
            this.cartItemAdded = product;
        }
    }
    headreTopMenuMobileEnable: any = false;


    megaMenuBannerImage: any = [];
    megaMenuBannerText: any = [];
    megaMenuBannerId: any = [];

    onHover(category: any) {        
        this.megaMenuBannerImage = [];
        this.megaMenuBannerText = [];
        this.megaMenuBannerId = [];
        category.subcategories.forEach(subCat => {
            if (subCat.include_in_navigation_menu == 1) {
                if (subCat.navigation_image != '') {
                    this.megaMenuBannerImage.push(subCat.navigation_image);
                    this.megaMenuBannerText.push(subCat.navigation_text);
                    this.megaMenuBannerId.push(subCat.id);
                }
            }
        });
    }


    signInWithApple() {
        const CLIENT_ID = "com.leza.Wishlist.backend"
        const REDIRECT_API_URL = environment.apiUrl + 'apple-social-auth'
        window.open(
            `https://appleid.apple.com/auth/authorize?client_id=${CLIENT_ID}&redirect_uri=${encodeURIComponent(REDIRECT_API_URL)}&response_type=code id_token&scope=name email&response_mode=form_post`,
            '_blank'
        );

        window.addEventListener('message', async event => {
            // console.log(event);
            const decodedToken = this.jwtHelper.decodeToken(event.data.id_token);
            // console.log(decodedToken);

            let requestData: any = {}
            if (event.data.user) {
                const userName = JSON.parse(event.data.user);
                requestData = {
                    email: decodedToken.email,
                    name: `${userName.name.firstName} ${userName.name.lastName}`,
                    socialId: decodedToken.sub,
                };
            } else {
                requestData = {
                    email: decodedToken.email,
                    socialId: decodedToken.sub,
                };
            }
            // console.log(requestData);
            let firstName = undefined;
            let lastName = undefined;
            if (requestData.name) {
                const name = requestData.name;
                if (name.indexOf(' ') != -1) {
                    firstName = name.substring(0, name.indexOf(' '));
                    lastName = name.substring(name.indexOf(' ') + 1);
                } else {
                    firstName = name;
                }
            }
            const param = {
                apple_id: requestData.socialId,
                first_name: firstName,
                last_name: lastName,
                email: requestData.email,
                social_register_type: 'A',
                device_type: 'W',
                device_model: this.device,
                app_version: this.device,
                os_version: window.navigator.platform,
                store: (this.store_code == '' || this.store_code == undefined) ? 'KW' : this.store_code,
            };

            // console.log(param);


            this.authenticationService.socialRegister(param)
                .pipe(first())
                .subscribe(
                    response => {
                        if (response.status === 200) {
                            this.cryptService.doEncrypt(JSON.stringify(response.data)).then(encryptedString => {
                                this.localStorage.setItem('userInfo', encryptedString).subscribe(() => {
                                    this.cartService.uploadOfflineCart(response.data.id).then(() => {
                                        this.cartService.clearStorage('cart');
                                        // this.redirectTo();
                                        document.getElementById('closeLoginModal').click();
                                        this.configSettings.disableLoader=2  
                                        setTimeout(() => {
                                            //after login manage without reload
                                            this.checkUserSession();
                                            this.timerComponent.checkUserSession();
                                            // this.timerComponent.getCartItems();
                                            this.SideUserActionsComponent.ngOnInit();
                                            this.configSettings.toggleLoading(false);
                                        }, 1000);
                                    });
                                });
                            });
                        } else {
                            this.fbHasError = true;
                            this.message = response.message;
                            this.configSettings.toggleLoading(false);
                        }
                    }, error => { this.configSettings.toggleLoading(false); }
                );
        });
    }


    //  openNav1() {
    //     document.getElementById("mySidenav1").style.width = "250px";
    //   }

    //    closeNav2() {
    //     document.getElementById("mySidenav1").style.width = "0";
    //   }


    selectNavBar() {
        let selectedCategoryId = this.getCategoryLocalStorage();
        if (selectedCategoryId !== 0) {
            this.top_selected_category = selectedCategoryId;
            this.clickedCategory = selectedCategoryId;
        }
        // this.router.navigate(['/home']);
        // else {
        //     this.top_selected_category = this.configSettings.defaultCategory;
        //     this.clickedCategory = this.configSettings.defaultCategory;
        //     this.setCategoryLocalStorage(this.top_selected_category);
        // }

        
    }
    
    showLogoutModal() {
        console.log(this.userSession.first_name,"in logout");
        
        let dialogRef = this.dialog.open(DialogComponent, {

            data: {logout: true, user: this.userSession },
            
        });
    
      }
    showHomePageWithNoNav() {
        var href = window.location.pathname
        var notHome=href.includes('/home')
        if(notHome==false){
            this.navigationStatus=true;
            this.isEnableHeaderBottom=true;
        }
        this.router.navigate(['/home']);  
        this.searchForm.reset()
        var landingBanner=this.configSettings.getLandingBanner();
        if(landingBanner=='1'){
            this.navigationStatus=true;
            this.isEnableHeaderBottom=false;
            this.eraseCookie('userSelectedHomePage')
            this.configSettings.setLandingBannerCount(2)
        }
        // this.configSettings.setNavBarStatus(false)
        // this.headerToggleService.setNavItems(false)
        // localStorage.removeItem('userSelectedCategory')
        // this.updateUrl()
    }
    eraseCookie(name) {
        document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      }
    updateUrl() {
        //Build URL Tree
        let currentPage = this.top_selected_category_name != 'null' ? this.top_selected_category_name : undefined;
        if (currentPage == undefined) {
          localStorage.removeItem('userSelectedHomePage')
        } 

        // console.log(this.top_selected_category_name,'this.top_selected_category_name');
        
        let queryParams = {
            page: currentPage
        }
        let urlString
    
        urlString = `/home`
    
        const urlTree = this.router.createUrlTree([urlString], {
          relativeTo: this.route,
        //   queryParams: queryParams,
          queryParamsHandling: 'merge'
        });
    
        //Update the URL 
        this.location.go(urlTree.toString());
      }

    @HostListener('window:scroll', ['$event'])
    onscroll() {
        let wishlistImg = document.getElementById('wishlistImg') as HTMLElement
        if (wishlistImg) {
            if (window.scrollY > 50) {
              this.navbarsticky = false;
              wishlistImg.classList.add('scale-logo')
            }
            else {
              this.navbarsticky = true;
              wishlistImg.classList.remove('scale-logo')
            }
        }
    }

    routeWithOutSubCat(categoryLvl2: any) {
        if (categoryLvl2.subcategories.length == 0) {
            this.router.navigate(['/category', this.configSettings.cleanUrl(categoryLvl2.name), this.configSettings.cleanUrl(categoryLvl2.name), categoryLvl2.id]);
        }
    }

    setNavigationImage(value){
        // console.log(value.subcategories,'value');
        
    }


    searchBox : boolean = false;
    searchBoxShow() {
        if(this.searchForm.get('q').value!=null){ this.searchForm.reset({q:null}), this.getHomeInfo()}
        this.searchBox = !this.searchBox
        const body = document.body
        if (this.searchBox) {
            // timeout is added because in firefox when input is focused, the popup dose not scroll till top it stops at input field
            setTimeout(() => { 
                let searchInput = document.getElementById('search-input-field') as HTMLInputElement
                body.style.overflowY = 'hidden'
                searchInput.focus()
            }, 1000);
        } else {
            body.style.overflowY = 'unset'
        }     
    }

    routeToDetails(product) {
        const idToNavigate = this.isPopularSearch ? product.type_id : product.id;
        this.router.navigate([`/product/detail/${idToNavigate}`]);
        this.searchBox = false;
        const body = document.body
        body.style.overflowY = 'unset'
    }


      // to listen change in url and on based of tierId toggle 'isBrandHomePage' to display brand logo
      brandIdForSearch:any;
      listenToRouteChange(){
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
          ).subscribe(() => {
              const snapshot = this.router.routerState.snapshot;
              const tierId = +snapshot.root.firstChild?.params.tierId;    
              this.brandIdForSearch=snapshot.root.firstChild?.params.id
              console.log(snapshot.root.firstChild?.params.id,'this.router');            
                  
            if (tierId !== 1) {
              this.isBrandHomePage = false;
            } else {
              this.isBrandHomePage = true;
            }
          });
      }

      brandInfo : any
      getBrandInfo() {  
        
        // const brandResponse = this.configSettings.getBrandInfo()
        // this.brandInfo = brandResponse.data
        
        this.shareBrandInfoService.getBrandInfo().subscribe((response) => {
          if (response !== null && response !== undefined) {
            this.brandInfo = response.data;            
          } else {
            console.log('Response is empty or undefined.');
          }
        });
      }

      homeInfo: any 
      isPopularSearch : boolean = false

      // to display popular search
      getHomeInfo(){
        this.shareHomeInfoService.getHomeInfo().subscribe((response) => {
            if (response !== null && response !== undefined) {                
                this.isPopularSearch = true
                this.homeInfo = response;
                
                this.productSuggestions = response.popular_products
                this.brandsSuggestions = response.popular_boutiques
                this.suggestions = response.popular_categories
                this.searchBoxHasData = this.suggestions.length > 0 || this.brandsSuggestions.length > 0 || this.productSuggestions.length > 0 ?  true : false

              } else {
                this.isPopularSearch = false
                console.log('Response is empty or undefined.');
              }
        })

      }

      // to set product badge based upon condition
  setProductBadge(productObj: any): string {
    let txt: string = '';
    // this console log is for testing purpose
    // console.log(
    //   `productObj.name : ${productObj.name}, `,
    //   `productObj.is_saleable : ${productObj.is_saleable}, `,
    //   `productObj.is_preorder : ${productObj.is_preorder}, `,
    //   `productObj.is_trending : ${productObj.is_trending}, `,
    //   `productObj.is_featured : ${productObj.is_featured}, `
    // );
    
    if (productObj.is_saleable == 0) {
      txt = this.lang == 'en' ? 'SOLD OUT' : 'تم البيع';
    } else if (productObj.is_preorder == 1) {
      txt = this.lang == 'en' ? 'PRE ORDER' : 'الطلب المسبق';
    } else if (productObj.is_trending == 1) {
      txt = this.lang == 'en' ? 'NEW' : 'جديد';
    } else if (productObj.is_featured == 1) {
      txt = this.lang == 'en' ? 'BEST SELLER' : 'الأكثر مبيعًا';
    }
    
    return txt;
  }

    
   clearSearchInput(): void {
    this.searchForm.get('q').setValue('');
    this.getHomeInfo()
  }


  ChangePopup:boolean = false;

  OpenChangePopup(){
    this.ChangePopup = !this.ChangePopup;
  }
//   megamenu-mange
  manageBigMenu:boolean=false;
  openBigMenu(){
    this.manageBigMenu=  true; 
  }
  closeBigMenu(){
    this.manageBigMenu=  false; 
  }
      
}
