import { Component, OnInit } from '@angular/core';
import {Order} from "../models/order";
import {ConfigSettings} from "../config/config.settings";
import {Store} from "../models/store";
import {UserService} from "../config/user.service";
import {ConfigService} from "../config/config.service";
import {Router} from "@angular/router";
import { listStagger } from '../animations';
import { FooterService } from '../config/footer.service';

@Component({
  selector: 'app-my-orders',
  templateUrl: './my-orders.component.html',
  styleUrls: ['./my-orders.component.scss'],
  animations: [ listStagger ]
})
export class MyOrdersComponent implements OnInit {
  orders: Order[] = [];
  lang: string;
  store: Store;
  userId: string;
  didLoad: boolean = false;
  dataAvailable = false;
  currency: string;
  skeltonTheme:any;
  skeletonLoader:boolean=true;
  isMobile:boolean=true;
  count=Array;
  constructor(
      private configSettings: ConfigSettings,
      private userService: UserService,
      private configService: ConfigService,
      private router: Router,
      protected footerService: FooterService
  ) {
      this.configSettings.toggleLoading(true);
  }

  ngOnInit() {
    this.isMobile = window.innerWidth < 600;
      this.lang = this.configSettings.getLang();
      this.userService.getUserSession().then(user => {
          if(user !== false) {
              this.userId = user.id;
          }

          /* getting store from config*/
          this.configSettings.getStoreObject().then((result) => {
              this.store = <Store> result;
              this.currency = result['currency_' + this.lang];
              this.getOrders();
          });
      }, error => { this.configSettings.toggleLoading(false); });
  }
  goToDetails(order) {
    this.userService.setOrder(order);
    this.userService.setUserId(this.userId);
    this.router.navigate(['order/detail/' + order.id]);
}
  reorder(id) {
      const params = {
          order: id,
          user: this.userId,
          lang: this.lang,
          store: this.store.iso_code
      };

      this.configService.readRequest('reorder', params)
          .subscribe(response => {
              this.router.navigate(['cart']);
          });
  }
   getOrders() {
      const params = {
          user_id: this.userId,
          lang: this.lang,
          store: this.store.iso_code
      };

      this.configService.readRequest('user-orders', params)
          .subscribe(response => {
              this.orders = response.body.data;
              this.didLoad = true;
              this.configSettings.toggleLoading(false);
              this.dataAvailable = true;
              setTimeout(() => {this.footerService.toggleFooter(true)}, 300);
              this.skeletonLoader=false
          });
  }

    isQuantityAvailable(order: Order): boolean {
        let isQuantityAvailable = true;
        for (let item of order.items) {
            isQuantityAvailable = (item.remaining_quantity > 0);
            if (!isQuantityAvailable) {
                break;
            }
        }
        return isQuantityAvailable;
    }
}
