import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigSettings } from 'src/app/config/config.settings';
import { Store } from 'src/app/models/store';

@Component({
  selector: 'app-center-mode-slider',
  templateUrl: './center-mode-slider.component.html',
  styleUrls: ['./center-mode-slider.component.scss']
})
export class CenterModeSliderComponent {
  isMobile: boolean;
  @Input() data : any 
  store: any
  currency: any
  lang: string = ''
  langForSlider = this.configSettings.getLang()

  constructor(public configSettings: ConfigSettings, private router : Router){
    this.isMobile = window.innerWidth < 1024;
    this.lang = this.configSettings.getLang();
    this.configSettings.getStoreObject().then((result) => {
      this.store = <Store>result;
      this.currency = result['currency_' + this.lang];
    });
    
  }


  centerModeSlider: any = {
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    dots: false,
    centerMode: true,
    // adaptiveHeight: true,
    centerPadding: '20px', 
    swipeToSlide: true,
    // variableWidth: true,
    rtl: this.langForSlider == 'en' ? false : true,
    // fade: true,
    prevArrow: '<span class="slick-arrow slick-prev custom-container-carousal-img  center-slider-prev"><img class="custom-carousal-arrows" src="assets/ArtWork/prev-carrousal.png" alt=""></span>',
    nextArrow: '<span class="slick-arrow slick-next custom-container-carousal-img center-slider-next"><img class="custom-carousal-arrows" src="assets/ArtWork/next-carrousal.png" alt=""></span>',
    responsive: [
      {
        breakpoint: 767,
        settings: {
          // variableWidth: true, // Set variableWidth to true
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          dots: false,
          centerPadding: '75px', 
        }
      },
      {
        breakpoint: 913,
        settings: {
          // variableWidth: true, // Set variableWidth to true
          slidesToShow: 3,
          slidesToScroll: 1,
          arrows: true,
        }
      },
      {
        breakpoint: 1025,
        settings: {
          centerPadding: '0px', 
          slidesToShow: 5,
          slidesToScroll: 1,
          arrows: true,
        }
      },
    ]
  };


    // for routing of banners
    routing(value: any) {

      const type = value.link_type;
      if (type === 'B' || type === 'BR') {
        this.router.navigate(['/brand', this.getUrlName(value.name), value.link_id])
        return
      }
      else if (type === 'C' || type === 'FC') {
        this.router.navigate(['/category', this.getUrlName(value.name), value.link_id])
        return
      }
      else if (type === 'P') {
        this.router.navigate(['/product/detail', value.link_id])
        return;
      }
      else if (type === 'FP') {
        this.router.navigate(['/product/detail', value.link_id])
        return;
      }
      else if (type === 'L') {
        this.router.navigateByUrl(value.link)
        return value.link;
      }
      else if (type === 'S') {
        this.router.navigate(['/shop', this.getUrlName(value.name), value.link_id])
        return;
      }
      else if (type === 'F') {
        this.router.navigate(['/search'], { queryParams: this.getCollectionQueryParams(value, 0) })
        return;
      } else if (type === 'N') {
        this.router.navigate(['/shop/new-arrivals'], { queryParams: this.getCollectionQueryParams(value, 1) })
        return;
      }
      return '';
    }
  
    // to remove spaces in url string with "-"
    getUrlName(productName: string): string {
      var prodN = productName ? (productName.toLowerCase().replace(/[^A-Z0-9]+/ig, "-")) : '';
      return prodN;
    }
  
    // get query params based on latestId
    getCollectionQueryParams(collection: any, latestId: any): any {
      if (latestId == 0) {
        return {
          q: '',
          lang: 'en',
          price_range: '',
          in_stock: '',
          typepage: '1',
          per_page: '20',
          is_featured: '',
          latest: latestId,
          best_selling: '',
          sort_by: '1',
          store: '',
          flash_sale_id: collection.link_id,
          shop_type_id: ''
        };
      } else {
        return {
          lang: 'en',
          price_range: '',
          in_stock: '',
          typepage: '1',
          per_page: '20',
          is_featured: '',
          latest: latestId,
          best_selling: '',
          sort_by: '1',
          store: '',
          flash_sale_id: '',
          shop_type_id: ''
        };
      }
  
    }
  

}
