import { Component, Input, OnInit } from '@angular/core';
import { Cart } from "../models/cart";
import { Address } from "../models/address";
import { DeliveryOptions } from "../models/delivery-options";
import { PaymentOptions } from "../models/payment-options";
import { Order } from "../models/order";
import { Store } from "../models/store";
import { User } from "../models/user";
import { CheckoutService } from "../config/checkout.service";
import { NavigationStart, Router } from "@angular/router";
import { NavbarService } from "../config/navbar.service";
import { ConfigSettings } from "../config/config.settings";
import { UserService } from "../config/user.service";
import { FooterService } from "../config/footer.service";
import { ConfigService } from "../config/config.service";
import { first } from "rxjs/operators";
import { AnalyticsService } from '../services/analytics.service';
import { ToastrService } from 'ngx-toastr';
import { PlatformLocation } from '@angular/common';
import { CartService } from '../config/cart.service';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators,AbstractControl  } from '@angular/forms';
import { AddressHelpersService } from '../services/address-helpers.service';
import { TranslateService } from '@ngx-translate/core';

declare var ackoo;

@Component({
  selector: 'app-payment-v2',
  templateUrl: './payment-v2.component.html',
  styleUrls: ['./payment-v2.component.scss']
})
export class PaymentV2Component implements OnInit {

  cart: Cart;
  receiverGift:string=''
  receiver_giftTouched:boolean=false;
  receiverPhone: string = '';
  receiverSticker:string=''
  receiver_stickerTouched:boolean=false;
  receiverName:string='';
  receiver_nameTouched: boolean = false;
  receiver_phoneTouched: boolean = false;
  // @Input() address: Address;
  isMobile = false;
  currency: string = '';
  cartValue: number = 0;
  shippingCost: any = '';
  discount: number = 0;
  selectedDelivery: DeliveryOptions;
  selectedPayment: PaymentOptions;
  deliveryOptions: DeliveryOptions[] = [];
  paymentOptions: PaymentOptions[] = [];
  order: Order;
  vatCharges: any = 0;
  codCost: number = 0;
  lang: string;
  store: Store;
  promo_code: string = '';
  user: User;
  promoValidationMessage: string;
  promotionApplied: boolean = false;
  coupon: any;
  showPaymentError = false;
  is_free_delivery = false;
  deliveryText = '';
  delivery_note = ''

  is_gift: boolean = false;
  giftForm = {
    receiver_name: '',
    receiver_phone: '',
    message: '',
    hide_invoice: false,
  };
  receiver_name: string = '';
  receiver_phone: string = '';
  message: string = '';
  on_package_sticker: string = '';
  giftSubmit = false
  hide_invoice: boolean = false;
  walletUsed: boolean = false;
  wallet_balance: number = 0;
  walletValidationMessage: string;
  wallet: {
    wallet_amount_applied: "0",
    wallet_amount_total:"0",
    wallet_remaining_amount:"0"
  }
  messageCount = 0;
  international_buyers = false;
  international_buyers_invalid = false;

  isLoading = false;
  addresses: Address[] = [];
  dataAvailable = false;
  selectedCountry: any;
  countries:any;
  governorates:any;
  countrycode:any='965';
  areas:any=[];
  sectors:any=[];
  showAddressForm:boolean=false;
  formSubmitAttempt:boolean=false;
  hide1: boolean = false;
  hide2: boolean = true;
  hide3: boolean = true;
  showTick1: boolean = false;
  showTick2: boolean = false;
  showTick3: boolean = false;
  orderReview: boolean = false;
  counter = Array;
  skeletonLoader:boolean=true;
  promotionToasterMsg:string;
  checkItemStockData: any;
  total:any;
  form: FormGroup;
  @Input() selectedAddress: Address = {
    address_id: null,
    first_name: null,
    last_name: null,
    area_id: null,
    area_name: null,
    governorate_id: null,
    governorate_name: null,
    country_id: 114, // default country Kuwait
    country_name: null,
    vat_pct: null,
    vat_charges: null,
    block_id: null,
    block_name: null,
    shipping_cost: null,
    express_shipping_cost: null,
    address_store_code: null,
    iso: 'KW',
    
    cod_cost: null,
    is_cod_enable: null,
    phonecode: null,
    street: null,
    addressline_1: null,
    mobile_number: null,
    alt_phone_number: null,
    location_type: null,
    notes: null,
    is_default: null,
    delivery_options: null,
    avenue: null,
    flat: null,
    floor: null,
    id_number: null,
    landmark: null
};
  constructor(
    private checkoutService: CheckoutService,
    private router: Router,
    private navbarService: NavbarService,
    private configSettings: ConfigSettings,
    private userService: UserService,
    protected footerService: FooterService,
    public configService: ConfigService,
    private toastr: ToastrService,
    private location: PlatformLocation,
    private analytics: AnalyticsService,
    private cartService: CartService,
    private formBuilder: FormBuilder,
    private addressHelpersService : AddressHelpersService,
    private translateService:TranslateService
  ) {
    // this.location.onPopState(() => {
    //   console.log('back pressed');
    // });
    router.events.subscribe((event: NavigationStart) => {
      if (event.navigationTrigger === 'popstate') {
        this.cancelCheckout();
      }
    });
    this.form = new FormGroup({
      receiverName: new FormControl('', Validators.required),
      receiverPhone: new FormControl('', [Validators.required, Validators.maxLength(10), this.numberOnly]),
      receiverSticker: new FormControl('', Validators.required),
      receiverGift: new FormControl('', [Validators.required, Validators.maxLength(140)]),
      hideInvoice: new FormControl(false),
      hideGiftPhone: new FormControl(false)
    });
  }

  ngOnInit() {
    this.isMobile = window.innerWidth < 600;
    this.promo_code = '';
    this.promotionApplied=false;
    this.navbarService.toggleMainNavigation(false);
    this.checkoutService.setStep(2);
    this.lang = this.configSettings.getLang();
    this.configSettings.getStoreObject().then((result) => {
      this.store = <Store>result;
      this.currency = result['currency_' + this.lang];
    });

    this.userService.getUserSession().then(response => {      
      if (response !== false) {
        this.user = <User>response;
        this.cartService.readCartItems(response.id, this.lang, this.getStore()).then(data => {
          this.cart = data.cart;
          this.getUserAddress();
        })
      } else {
        this.configSettings.toggleLoading(false);
        this.isLoading = false;
        setTimeout(() => { this.footerService.toggleFooter(true) }, 300);
      }
    })

  }
  getStore(){
    let storeCode=this.selectedAddress.address_id!=null?this.selectedAddress.iso: this.store.iso_code;
    return storeCode;
  }
  getUserAddress() {
    this.configSettings.toggleLoading(true);
    this.userService.getUserSession().then(response => {
      if (response !== false) {
        this.userService.getUserAddress(response.id, this.lang, this.store.iso_code).then(data => {
          if (data.length > 0) {
            this.addresses = data;
            this.showAddressForm=false;
            this.addresses.map((address, index) => {
              if (address.is_default === 'Yes' || (address.is_default === 'No' && index === 0)) {
                this.selectedAddress = address;                                 
                // this.shippingCost = +this.selectedAddress.delivery_options[0].price;
                this.vatCharges = address.vat_charges;
                // this.deliveryText = this.selectedAddress.delivery_options[0].name;
                // this.selectedDelivery = this.selectedAddress.delivery_options[0];
                // this.deliveryOptions = this.selectedAddress.delivery_options;
              }
            });
            this.checkItemStock(response);
          } else {
            this.showAddress();
            this.checkItemStock(response);
          }
          this.configSettings.toggleLoading(false);
          this.dataAvailable = true;
          //   this.footerService.toggleFooter(true);
        }, error => { this.configSettings.toggleLoading(false); });
      }
    }, error => { this.configSettings.toggleLoading(false); });
  }



  showAddress(){
    this.showAddressForm=!this.showAddressForm;
   if (this.showAddressForm == true) {
     this.addFirstDeliveryAddress()
   }
    
  }

    displayPopup: boolean = false
    addDeliveryAddress() {
      this.navbarService.triggerAddress('', true);
      this.displayPopup = true
      const addressInfo = {
        displayPopup: this.displayPopup,
        isEditAddress: false,
        addressInfo: undefined,
        isPopup: true
      }
      console.log(addressInfo,'add');

      this.addressHelpersService.setAddressInfo(addressInfo)
    }

    addFirstDeliveryAddress() {
      this.navbarService.triggerAddress('', true);
      this.displayPopup = false
      const addressInfo = {
        displayPopup: this.displayPopup,
        isEditAddress: false,
        addressInfo: undefined,
        isPopup: false
      }
      this.addressHelpersService.setAddressInfo(addressInfo)
    }
    editAddress(address: any) {
      this.navbarService.triggerAddress(address, false);
      this.displayPopup = true
      const addressInfo = {
        displayPopup: this.displayPopup,
        isEditAddress: true,
        addressInfo: address,
        isPopup: true
        }

        console.log(addressInfo,'edit');
        
      this.addressHelpersService.setAddressInfo(addressInfo)
    }

    closePopup(value : boolean){
      this.displayPopup = value
     }

    selectAddress(address, index) {
      this.selectedAddress = this.addresses[index];
      this.vatCharges = this.selectedAddress.vat_charges;
      this.shippingCost = +this.selectedAddress.delivery_options[0].price;
      this.configSettings.setShippingAddress(this.selectedAddress);
        this.deliveryText = this.selectedAddress.delivery_options[0].name;        
        this.selectedDelivery = this.selectedAddress.delivery_options[0];          
      this.userService.getUserSession().then(response => {
        if (response !== false) {
          this.checkItemStock(response);
        }
      })
    }
   

    showTabs(no:number){      
      switch (no) {       
        case 1:  
        if(this.selectedAddress.address_id==null) {
          this.translateService.get("please select an address").subscribe(res => {
            this.toastr.error('', res);
          })
          return;
        }       
          if(this.showTick2==false && this.selectedAddress.address_id!=''){                    
            this.hide1 = true;
            this.hide2 = false;
            this.hide3 = true;
            this.showTick1 = true;
          }else{        
            this.hide1 = true;
            this.showTick1 = true;          
          }
          // this.scrollToTop();          
          break;
        case 2:
          if(this.showTick3==false){
            this.hide1 = true;
            this.hide2 = true;
            this.showTick2 = true;
              this.total=this.showGiftDetails?Number(this.checkItemStockData.total)+Number(this.checkItemStockData.gift_charges):Number(this.checkItemStockData.total);
            if (this.checkItemStockData.total == 0) {
              this.hide3 = true;
              this.showTick3 = true;
              this.reviewOrder();
            } else {
              this.hide3 = false;
            }
          }else{
            this.hide2=true;
            this.showTick2 = true;
            if (this.checkItemStockData.total == 0) {
              this.hide3 = true;
              this.showTick3 = true;
              this.reviewOrder();
            } 
          }
          // this.scrollToTop(); 
          break;
        default:
          this.hide1 = false;
          this.hide2 = true;
          this.hide3 = true;
          this.showTick1 = false;
          this.showTick2 = false;
          this.showTick3 = false;
          this.orderReview = false;
          let doc = document.getElementById('BUYNOW');
          doc.classList.remove('buyNow');
          break;
      }
    }
  editData(type) {
    if (type == 'add' && this.showTick1) {
      this.hide1 = false;
      this.hide2 = true;
      this.hide3 = true;
      this.showTick1 = false;
      this.showTick2=false;
      this.showTick3=false;
      this.orderReview=false;
    } else if (type == 'review' && this.showTick2) {
      this.hide1 = true;
      this.hide2 = false;
      this.hide3 = true;
      this.showTick2 = false;
      this.showTick3=false;
      this.orderReview=false;
    } else if (type == 'pay' && this.showTick3) {
      this.hide1 = true;
      this.hide2 = true;
      this.hide3 = false;
      this.showTick3 = false;
      this.orderReview=false;
    }
    let doc=document.getElementById('BUYNOW')
    doc.classList.remove('buyNow')
  }
    reviewOrder(){            
      if(this.selectedPayment!=undefined || this.checkItemStockData.total==0){
        this.orderReview=true;
        this.hide3=true;
        this.showTick3=true;
        let doc=document.getElementById('BUYNOW')
        doc.classList.add('buyNow')
        if (this.selectedPayment == undefined && this.checkItemStockData.total==0) {
          this.selectedPayment = { type: this.lang == 'en' ? 'Wallet' : 'محفظة', code: "W", success_url: "", fail_url: "", icon: "", is_enable: "" };
        }  
      }else{
        this.translateService.get("Please Select a payment method").subscribe(res => {
          this.toastr.error('', res);
        })
      }
      if(!this.isMobile){
        this.scrollToTop();
      }
    }

  cancelCheckout() {
    const params = {
      order_id: this.configSettings.getCartId(),
      user_id: this.user.id
    };
    this.configService.readRequest('cancel-checkout', params)
      .subscribe(response => {
        if (response.status === 200) {
          console.log('cancel checkout completed')
        } else {
          this.configSettings.toggleLoading(false);
        }
      }, error => { this.configSettings.toggleLoading(false); });
  }

  checkItemStock(response) {   
    this.footerService.toggleFooter(false);
    if (response.wallet_balance != response.wallet_amount) {
      this.wallet_balance = response.wallet_amount;
    } else {
      this.wallet_balance = response.wallet_balance;
    }
    if (this.cart.items && this.cart.items.length > 0) {      
      this.checkoutService.checkItemStock(response.id, this.cart.items, this.getStore(), this.selectedAddress.address_id).then(data => {
        this.skeletonLoader=false;
        this.checkItemStockData = data;
        this.wallet = data.wallet;
        this.currency = data.baseCurrencyName;
        this.walletUsed = (+this.wallet.wallet_amount_applied > 0);
        this.delivery_note = data.delivery_note;
        if (data.is_coupon_applied === 1) {
          this.coupon = data.coupon;
          this.promotionApplied = true;
          this.discount = data.discount_price;
        } else {
          this.promotionApplied = false;
        }
        this.cart = data.cart;
        this.cartValue = data.sub_total;
        this.deliveryOptions = <DeliveryOptions[]>data.delivery_options;
        this.shippingCost = data.delivery_charges;
        this.total=Number(this.checkItemStockData.total);
        if (this.shippingCost == '0') {
          this.is_free_delivery = true;
        } else {
          this.is_free_delivery = false;
        }
        this.paymentOptions = data.payment_types;
        
        this.paymentOptions.map(payment => {
          if (payment.code === 'C') {
            payment.is_enable = this.selectedAddress?.is_cod_enable?.toString();
          }
        });

        this.sendGTagEvent('begin_checkout', this.cart.items, null);

        this.isLoading = false;
        this.configSettings.toggleLoading(false);
        setTimeout(() => { this.footerService.toggleFooter(true) }, 300);
      }, error => {
        this.configSettings.toggleLoading(false);
        this.isLoading = false;
        setTimeout(() => { this.footerService.toggleFooter(true) }, 300);
      });
    } else {
      this.isLoading = false;
      this.configSettings.toggleLoading(false);
    }
  }

  doPromotion(): Promise<any> {
    const promise = new Promise<any>((resolve, reject) => {
      const getParams = {
        lang: this.lang,
        store: this.getStore()
      };
      const postParams = {
        user_id: this.user.id,
        order_id: this.configSettings.getCartId(),
        coupon_code: this.promo_code,
        shipping_address_id: this.selectedAddress.address_id,
        device: 'W',
      };
      this.checkoutService.redeemCoupon(getParams, postParams)
        .pipe(first())
        .subscribe(
          response => {
            if (response.status === 200) {
              // const responseData = response.data;
              // this.coupon = responseData.coupon;
              // this.discount = responseData.discount_price;
              // let shippingCostOld = this.shippingCost;
              // this.shippingCost = responseData.delivery_charges.toString();
              // if (this.shippingCost == '0') {
              //   this.is_free_delivery = true;
              //   this.discount = +shippingCostOld;
              // } else {
              //   this.is_free_delivery = false;
              // }
              // this.cartValue = responseData.sub_total;
              // this.deliveryOptions = <DeliveryOptions[]>responseData.delivery_options;
              // resolve(true);
              const responseData = response.data;
              this.checkItemStockData = response.data;
              this.coupon = responseData.coupon;
              this.discount = responseData.discount_price;
              this.shippingCost = responseData.delivery_charges;
              this.promotionToasterMsg=response.message
              this.total=Number(this.checkItemStockData.total);
              if (this.shippingCost == '0') {
                this.is_free_delivery = true;
              } else {
                this.is_free_delivery = false;
              }
              this.cartValue = responseData.sub_total;
              this.deliveryOptions = <DeliveryOptions[]>responseData.delivery_options;
              // if(this.checkItemStockData.total==0) {
              //   this.reviewOrder();
              // }else{
              //   this.showTabs(4);
              // }
              resolve(true);
            } else {
              this.promotionToasterMsg=response.message
              this.promoValidationMessage = (this.lang === 'en') ? 'UNFORTUNATELY THIS PROMO CODE IS INVALID. PLEASE CHECK AND TRY AGAIN.' : 'لا شك أن هذا الرمز الترويجي غير صالح. يرجى مراجعة وحاول مرة أخرى.';
              setTimeout(() => { this.promoValidationMessage = ''; }, 5000);
              this.configSettings.toggleLoading(false);
              resolve(false);
            }
          }, error => { this.configSettings.toggleLoading(false); }
        );

    });
    return promise;
  }

  applyPromotion() {
    if(this.promo_code!='' && this.promo_code!=undefined){
      this.doPromotion().then((result) => {      
        if (result) {
          this.promotionApplied = true;
          this.toastr.success(this.promotionToasterMsg);
        }else{
          this.toastr.error(this.promotionToasterMsg);
        }
      });
    } 
  }

  removePromotion() {
    this.promo_code = '';
    this.doPromotion().then((result) => {
      if (result) {
        this.promotionApplied = false;
        console.log(this.promotionToasterMsg);        
        this.toastr.error(this.promotionToasterMsg);
      }
    });
  }


  selectDelivery(delivery, index) {
    this.selectedDelivery = this.deliveryOptions[index];
    this.shippingCost = this.deliveryOptions[index].price;
    this.deliveryText = this.selectedAddress.delivery_options[index].name;
  }

  selectpayment($payment, index) {
    this.selectedPayment = this.paymentOptions[index];
    if (this.selectedPayment.code === "C") {
      this.codCost = +this.selectedAddress.cod_cost;
      this.total=Number(this.checkItemStockData.total)+Number(this.selectedAddress.cod_cost);
    } else {
      this.codCost = 0;
      if(this.total>Number(this.checkItemStockData.total)){
        this.total=Number(this.total)-Number(this.selectedAddress.cod_cost);
      }
    }
    this.showPaymentError = false;
  }
//to check number validations only 
  numberOnly(control: AbstractControl) {
    const isNumber = /^\d+$/.test(control.value);
    return isNumber ? null : { numberOnly: true };
  }
  showGiftDetails:boolean=false;
  anonymousdelivery:boolean=false;
  sendGift() {
    this.is_gift = !this.is_gift;
  }
  hideInvoice() {
    this.hide_invoice = !this.hide_invoice;
  }
  isAnonymousdelivery(){
    this.anonymousdelivery=!this.anonymousdelivery
  }
  editGift(){
    this.showGiftDetails=false;
    this.is_gift=false;
  } 
  submitGift(){   
    if(this.form.valid){
      this.showGiftDetails=true;
      this.is_gift=false;      
    }
  }  
  proceedToCheckoutTrigger() {
    this.proceedToCheckout();
  }
showLoading:boolean=false;
  proceedToCheckout() {
    this.showLoading=true;
    if (this.is_gift) {
      this.giftSubmit = true;
      if (this.receiver_name.length === 0 || this.receiver_phone.length === 0) {
        return false;
      }
      else if (this.message.length > 140) {
        return false;
      }
    }

    if (!this.selectedPayment) {
      this.showPaymentError = true;
      return false;
    }

    // if (!this.international_buyers && this.store.iso_code != 'KW') {
    //   this.international_buyers_invalid = true;
    //   setTimeout(() => {
    //     this.international_buyers_invalid = false;
    //   }, 4000)
    //   return false;
    // }

    this.checkoutService.setAddress(this.selectedAddress);
    this.checkoutService.setCart(this.cart);
    this.checkoutService.setDelivery(this.selectedDelivery);
    this.checkoutService.setPayment(this.selectedPayment);

    const productIDs = [];
    var cartItems = [];
    var productNames = [];
    this.cart.items.forEach((item) => {
      productIDs.push(item.id.toString());
      cartItems.push(item);
      productNames.push(item.name.toString());

    });
    // this.analytics.initiateCheckout(
    //   productIDs,
    //   productNames,
    //   cartItems,
    //   this.cart.items.length,
    //   this.cartValue.toString(),
    // );

    this.configService.sendGTagEvent('set_checkout_option', {
      "checkout_step": 2,
      "checkout_option": "delivery method",
      "value": this.selectedDelivery?.name
    });

    this.configService.sendGTagEvent('set_checkout_option', {
      "checkout_step": 2,
      "checkout_option": "payment method",
      "value": this.selectedPayment.type
    });
    this.sendGTagEvent('checkout_progress', this.cart.items, null);
    this.doCheckout();
  }

  doCheckout() {
    var payMode = this.selectedPayment.code;
    if (this.checkItemStockData.total == 0) {
      payMode = 'W';
    }
    this.configSettings.toggleLoading(true);
    this.userService.getUserSession().then(response => {
      if (response !== false) {
        this.checkoutService.checkout(response.id, this.selectedAddress.address_id, 1, payMode,
          this.selectedAddress.iso, this.showGiftDetails, this.form.value.receiverName, this.form.value.receiverPhone, this.form.value.receiverGift,
          this.hide_invoice, this.anonymousdelivery,this.form.value.receiverSticker).then(data => {
            // if(data = []){
            //   window.location.reload();
            //   return;
            // }


            // ackoo.trackCheckout({
            //   orderId: data.order_details.order_number.toString(),
            //   amount: Number(data.grand_total_kwd),
            //   currency: "KWD",
            // });

            
            // console.log(data.order_details.order_id,data.grand_total_kwd)

            if (data.payment_url) {
              // this.configSettings.toggleLoading(false);
              window.location.href = data.payment_url;
            } else {

              

              this.order = {
                items: ((data.cart) ? (data.cart.items) : ''),
                cod_cost: data.cod_cost,
                baseCurrencyName: data.baseCurrencyName,
                delivery_charges: data.delivery_charges,
                created_date: (data.order_details) ? (data.order_details.order_date) : '',
                order_number: (data.order_details) ? (data.order_details.order_number) : '',
                order_id: (data.order_details) ? (data.order_details.order_id) : '',
                status: (data.order_details) ? (data.order_details.order_status) : '',
                shipping_address: data.shipping_address,
                status_color: (data.order_details) ? (data.order_details.status_color) : '',
                sub_total: data.sub_total,
                total: data.total,
                vat_charges: data.vat_charges,
                custom_duty_charges: data.custom_duty_charges,
                vat_pct: data.vat_pct,
                discount_price: (data.discount_price > 0) ? data.discount_price : 0,
                grand_total_kwd: data.grand_total_kwd,
                payment_details: []
              };
              this.cartService.setCartObservable({});
              this.checkoutService.setOrderDetails(this.order);
              this.checkoutService.setPayment(this.selectedPayment);
              this.configSettings.toggleLoading(false);
              this.router.navigate(['order-confirmation']);
            }
          });
      } else {
        this.configSettings.toggleLoading(false);
      }
    }, error => { this.configSettings.toggleLoading(false); });
  }

  sendGTagEvent(event, items: any, additional_params: any) {
    const tmp = [];
    items.forEach(function (item, key) {
      const final_price = item.final_price_kwd * 3.30;
      tmp.push({
        id: item.SKU,
        name: item.name,
        list_name: "Product Details",
        brand: item.brand_name,
        category: item.brand_name,
        variant: "",
        list_position: 1,
        quantity: item.quantity,
        price: final_price.toFixed(2)
      });
    });

    this.configService.sendGTagEvent(event, {
      // additional_params,
      items: tmp,
      coupon: ''
    });
  }



  applyWallet() {
    this.useWallet().then((result) => {
      if (result) {
        this.walletUsed = true;
        this.toastr.success(this.walletValidationMessage);
      }else{
        this.toastr.error(this.walletValidationMessage);
      }
    });
  }
  removeWallet() {
    this.removeWalletCode().then((result) => {
      if (result) {
        this.walletUsed = false;
        this.toastr.error(this.walletValidationMessage);
      }
    });
  }

  useWallet(): Promise<any> {
    const promise = new Promise<any>((resolve, reject) => {
      const getParams = {
        lang: this.lang,
        store: this.getStore()
      };
      const postParams = {
        user_id: this.user.id,
        order_id: this.configSettings.getCartId(),
      };
      this.checkoutService.redeemWallet(getParams, postParams)
        .pipe(first())
        .subscribe(
          response => {
            if (response.status === 200) {
              const responseData = response.data;
              this.checkItemStockData = response.data;
              this.coupon = responseData.coupon;
              this.discount = responseData.discount_price;
              this.shippingCost = responseData.delivery_charges;
              this.walletValidationMessage=response.message;
              this.total=Number(this.checkItemStockData.total);
              if (this.shippingCost == '0') {
                this.is_free_delivery = true;
              } else {
                this.is_free_delivery = false;
              }
              this.cartValue = responseData.sub_total;
              this.deliveryOptions = <DeliveryOptions[]>responseData.delivery_options;
              this.wallet = responseData.wallet;
              // if(this.checkItemStockData.total==0) {
              //   this.reviewOrder();
              // }            
              resolve(true);
            } else {
              this.walletValidationMessage = (this.lang === 'en') ? 'UNFORTUNATELY THIS PROMO CODE IS INVALID. PLEASE CHECK AND TRY AGAIN.' : 'لا شك أن هذا الرمز الترويجي غير صالح. يرجى مراجعة وحاول مرة أخرى.';
              this.walletValidationMessage=response.message;
              setTimeout(() => { this.walletValidationMessage = ''; }, 5000);
              this.configSettings.toggleLoading(false);
              resolve(false);
            }
          }, error => { this.configSettings.toggleLoading(false); }
        );

    });
    return promise;
  }

  removeWalletCode(): Promise<any> {
    const promise = new Promise<any>((resolve, reject) => {
      const getParams = {
        lang: this.lang,
        store: this.store.iso_code
      };
      const postParams = {
        user_id: this.user.id,
        order_id: this.configSettings.getCartId(),
      };
      this.checkoutService.removeWallet(getParams, postParams)
        .pipe(first())
        .subscribe(
          response => {
            if (response.status === 200) {
              const responseData = response.data;
              this.checkItemStockData = response.data;
              this.coupon = responseData.coupon;
              this.discount = responseData.discount_price;
              this.shippingCost = responseData.delivery_charges;
              this.walletValidationMessage = response.message;
              if (this.shippingCost == '0') {
                this.is_free_delivery = true;
              } else {
                this.is_free_delivery = false;
              }
              this.cartValue = responseData.sub_total;
              this.deliveryOptions = <DeliveryOptions[]>responseData.delivery_options;
              this.wallet = responseData.wallet;
              // this.showTabs(4);
              resolve(true);
            } else {
              this.walletValidationMessage = (this.lang === 'en') ? 'UNFORTUNATELY THIS PROMO CODE IS INVALID. PLEASE CHECK AND TRY AGAIN.' : 'لا شك أن هذا الرمز الترويجي غير صالح. يرجى مراجعة وحاول مرة أخرى.';
              this.walletValidationMessage = response.message;
              setTimeout(() => { this.walletValidationMessage = ''; }, 5000);
              this.configSettings.toggleLoading(false);
              resolve(false);
            }
          }, error => { this.configSettings.toggleLoading(false); }
        );

    });
    return promise;
  }

  acceptInternational() {
    this.international_buyers = true;
  }

  scrollToTop() {
    window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
    });
}
  ngOnDestroy() {    
    this.navbarService.toggleMainNavigation(true);
    if (this.walletUsed) {
      this.removeWalletCode();
    }
  }
}
