<div class="container-fluid">
  <div class="forgot_width mx-auto">
    <div class="forgot_data">

      <h3>{{'Forgot your password?' | translate}}</h3>
      <p>{{"Enter your email address and we'll send you a new password to login" | translate}}</p>

      <div class="pt-3">
        <form  #forgotPasswordForm="ngForm"  >
          <div class="password_data">
            <label>{{'Email address' | translate}}</label>
            <input [(ngModel)]="email" name="email" #spy id="inputEmail" aria-describedby="emailHelp" #nameEmail="ngModel"
            required (input)="validateEmail($event)" type="email">
            <small id="emailHelp" class="form-text form-validator_error" *ngIf="(nameEmail.touched && !nameEmail.valid)">
              {{'Please enter your email address' | translate}}
            </small>
          </div>

          <div class="forgot_button">
            <button type="button" [disabled]="!isEmailValid" [ngClass]="{'disable-button-styles': !isEmailValid}" (click)="doForgotPassword(forgotPasswordForm)">{{'RESET PASSWORD' | translate}}</button>
          </div>

          <div class="back_button">
            <a href="" [routerLink]="'/login'">{{'Back to Sign In' | translate}}</a>
          </div>

        </form>
      </div>

    </div>
  </div>
</div>


<div class="d-flex justify-content-center align-items-center p-2 ">
  <button class="scroll-to-top-button" (click)="scrollToTop()">
      <img src="/assets/images/arrow-up.png" class="scroll-to-top-img">
      <span class="scroll-to-top-title">{{'BACK TO TOP' | translate}}</span>
  </button>
</div>