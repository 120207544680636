 <div class="smallDeviceMarginB">    
      <div class="product-item">
        <a [routerLink]="['/product/detail/', mainItem.id]" data-dismiss="modal" aria-label="Close" id="storiesCLoseButton">
          <div class="ratio-image-container product-main-img">
            <div class="product-img-dummy"></div>
            <a [routerLink]="['/product/detail/', mainItem.id]">
              <img src="{{mainItem.image}}" alt="mainItem.name" (click)="imageClicked()">
            </a>
          </div>

          
        </a>

        <div id="f1_container" [ngClass]="(isFlipped)?'':'d-none'">
          <div id="f1_card" class="" [ngClass]="(isFlipped)?'flipped':''">
          <div class="back face center main-flip mainflip-data" [style.height.%]="backdropHeight">
        <div>
        <span><img src="assets/ArtWork/close.png" (click)="flipTheBox()"></span>
        <div class="size-product" *ngIf="mainItem">
          <div class="col-12">
            <div class="">
                <div class="Color-Box" *ngFor="let option of mainItem.configurable_option; let i = index;" [ngClass]="(option.attribute_id=='1')?'':'d-none'">
                  <h2>{{'Color' | translate}}</h2>
                      <div class="div-overflow">
                        <div class="">
                          <div class="small-size" *ngFor="let attribute of option.attributes" (click)="onOptionSelectV2(option, attribute, i, $event)" [ngClass]="(selectedAttribute[0] == attribute.option_id)?'activeConfigColorOption':''">
                            {{attribute.value}}
                          </div>
                        </div>
                      </div>
                    
                </div>
            </div>
          </div>

          <div class="col-12">
                <div class="mb-2 mb-md-0 scollFix" *ngFor="let option of mainItem.configurable_option; let i = index; let last = last;"  [ngClass]="(option.attribute_id=='2')?'':'d-none'">
                  <h2>{{'Size' | translate}}</h2>
                  <div class="div-overflow">
                    <div class="div-overflow-color">
                      <div class="small-size" *ngFor="let attribute of option.attributes" (click)="onOptionSelectV2(option, attribute, i, $event)" [ngClass]="(selectedAttribute[1] == attribute.option_id)?'activeConfigColorOption':''">
                        {{attribute.value}}
                      </div>
                      </div>
                      </div>
                </div>
        </div>

        </div>
        <div class="Add-to-Bag"> 
          <button type="button" *ngIf="(addToBagText != 'PROCEED TO BAG')" (click)="addToBag()" [ngStyle]="{ 'font-size': addToBagText == '✓' ?   '15px': '' }">
            <i class="fa fa-plus" aria-hidden="true" *ngIf="(addToBagText != '✓')"></i> {{addToBagText | translate}}</button>
          <button type="button" *ngIf="(addToBagText == 'PROCEED TO BAG')" (click)="viewBag()"> <img class="ml-3 diw7" src="../../assets/ArtWork/white-bag.svg">&nbsp; {{addToBagText | translate}} &nbsp;<span class="arrowText">→</span></button>
        </div>
      </div>
      </div>
      </div>
      </div>
       
    <span class="Image-Bag front bagFix d-none" [ngClass]="(isFlipped == true)?'d-none':''">
         <div [ngClass]="(lang == 'ar')?'front-backAr':'front-back'">
             <img src="assets/ArtWork/addtocart3x.png" (click)="flipTheBox()" [ngClass]="(page == 'product-details')?'d-none':''" >
         </div>
        </span>
      
        <a class="product-details-wrapper-link" [routerLink]="['/product/detail/', mainItem.id]" data-dismiss="modal" aria-label="Close" id="storiesCLoseButton">
  
          <div class="row m-0 w-100">
  
            <div class="col-12 col-md-12 p-0 product-details-name">
              <h4 class="product-brand font-black mb-0">
                {{mainItem.brand}}{{mainItem.brand_name}}
              </h4>
              <ng-container>
                <div class="col-12 col-md-12 p-0 product-price-details">
                  <div class="product-price main-price">
                    <p>
                      <span *ngIf="mainItem.final_price < mainItem.regular_price" class=" font12 pl-0">{{currency}} {{mainItem.final_price | number:'1.3-3'}} </span>
                      <span *ngIf="!(mainItem.final_price < mainItem.regular_price)" class="  font12 pl-0">{{currency}} {{mainItem.final_price | number:'1.3-3'}} </span>
                      <span *ngIf="mainItem.final_price < mainItem.regular_price" class="fontred font12"><del>{{currency}} {{mainItem.regular_price | number:'1.3-3'}}</del></span></p>
                  </div>
  
                </div>
              </ng-container>
            </div>
          </div>
        </a>

       
      <div class="pre-order-badge" *ngIf="(mainItem.is_preorder == 1)">{{' Pre Order' | translate}}</div>
      <div class="pre-order-badge" *ngIf="mainItem.is_preorder == 0 && mainItem.remaining_quantity <= 0">{{'Sold Out' | translate}}</div>
      <span class="close-icon" *ngIf="enableRemoveWishlist" (click)="removeFromWishlist()"><i class="fas fa-times"></i></span>
      </div>
    </div>


      
    